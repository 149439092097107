/**
 * WARNING: Be careful when importing this helper because it uses Redux store and some other imports that can cause
 * circular dependencies!
 */

import {v4} from "uuid";
import reduxStore from "../store";
import {MESSAGE_STYLE} from "../components/global/Message/const";
import {
	icon_font_error_symbol,
	icon_font_help_symbol,
	icon_font_info_symbol, icon_font_success_symbol, icon_font_warning_symbol,
	messages_default_auto_hide_after
} from "../../config";
import * as actions from "../store/actions/global";

/**
 * Renders a new global message
 *
 * @param {string} [GUIID] - Message unique GUI ID. If not specified, a unique guid will be generated and used.
 * @param {React.Component|JSX.Element|string} content - Message content. Can be a React component or string (HTML is supported).
 * @param {string} [icon=''] - Message content icon symbol name (depending on font icon set used).
 * @param {string} [style] - Message style (keyof MESSAGE_STYLE). If not specified MESSAGE_STYLE.DEFAULT will be used.
 * @param {number} [autoHideAfter] - Number of seconds for the message to be visible. If -1, message will be visible
 * indefinitely or until its closed manually. Default value is retrieved from app config ('/src/config/app.js' file).
 * @param {string} [customClass=''] - Custom CSS class used instead of style class if style is MESSAGE_STYLE_CUSTOM. Use
 * this option if you want to render a custom style message.
 * @param {boolean} [canClose=true] - Flag that determines if message can be manually closed.
 * @param {boolean} [showCloseButton=true] - Flag that determines if close button will be rendered. This flag will be
 * ignored if 'canClose' prop is false.
 * @param {boolean} [closeOnClick=false] - Flag that determines if clicking anywhere inside the message will close it.
 * This flag will be ignored if 'canClose' prop is false.
 * @return {string} GUIID of the message.
 */
export const addMessage = (
	GUIID = v4(), content, icon = '', style = MESSAGE_STYLE.DEFAULT, autoHideAfter = messages_default_auto_hide_after,
	customClass = '', canClose = true, showCloseButton = true, closeOnClick = false
) => actions.addMessageAction(
	GUIID, content, icon, style, autoHideAfter, customClass, canClose, showCloseButton, closeOnClick
)(reduxStore.dispatch);
/**
 * Renders a new global message
 * @alias addMessage
 */
export const showMessage = addMessage;

/**
 * Renders a new default global message
 *
 * @param {React.Component|JSX.Element|string} content - Message content. Can be a React component or string (HTML is supported).
 * @param {string} [icon=''] - Message content icon symbol name (depending on font icon set used).
 * @param {number} [autoHideAfter] - Number of seconds for the message to be visible. If -1, message will be visible
 * indefinitely or until its closed manually. Default value is retrieved from app config ('/src/config/app.js' file).
 * @param {boolean} [canClose=true] - Flag that determines if message can be manually closed.
 * @param {boolean} [showCloseButton=true] - Flag that determines if close button will be rendered. This flag will be
 * ignored if 'canClose' prop is false.
 * @param {boolean} [closeOnClick=false] - Flag that determines if clicking anywhere inside the message will close it.
 * This flag will be ignored if 'canClose' prop is false.
 * @return {string} GUIID of the message.
 */
export const addDefaultMessage = (
	content, icon = '', autoHideAfter = messages_default_auto_hide_after, canClose = true, showCloseButton = true,
	closeOnClick = false
) => addMessage(
	v4(), content, icon, MESSAGE_STYLE.DEFAULT, autoHideAfter, '', canClose, showCloseButton, closeOnClick
);
/**
 * Renders a new default global message
 * @alias addDefaultMessage
 */
export const showDefaultMessage = addDefaultMessage;

/**
 * Renders a new info global message
 *
 * @param {React.Component|JSX.Element|string} content - Message content. Can be a React component or string (HTML is supported).
 * @param {number} [autoHideAfter] - Number of seconds for the message to be visible. If -1, message will be visible
 * @param {string} [icon=''] - Message content icon symbol name (depending on font icon set used).
 * indefinitely or until its closed manually. Default value is retrieved from app config ('/src/config/app.js' file).
 * @param {boolean} [canClose=true] - Flag that determines if message can be manually closed.
 * @param {boolean} [showCloseButton=true] - Flag that determines if close button will be rendered. This flag will be
 * ignored if 'canClose' prop is false.
 * @param {boolean} [closeOnClick=false] - Flag that determines if clicking anywhere inside the message will close it.
 * This flag will be ignored if 'canClose' prop is false.
 * @return {string} GUIID of the message.
 */
export const addInfoMessage = (
	content, autoHideAfter = messages_default_auto_hide_after, icon = icon_font_info_symbol, canClose = true,
	showCloseButton = true, closeOnClick = false
) => addMessage(
	v4(), content, icon, MESSAGE_STYLE.INFO, autoHideAfter, '', canClose, showCloseButton, closeOnClick
);
/**
 * Renders a new info global message
 * @alias addInfoMessage
 */
export const showInfoMessage = addInfoMessage;

/**
 * Renders a new help global message
 *
 * @param {React.Component|JSX.Element|string} content - Message content. Can be a React component or string (HTML is supported).
 * @param {number} [autoHideAfter] - Number of seconds for the message to be visible. If -1, message will be visible
 * @param {string} [icon=''] - Message content icon symbol name (depending on font icon set used).
 * indefinitely or until its closed manually. Default value is retrieved from app config ('/src/config/app.js' file).
 * @param {boolean} [canClose=true] - Flag that determines if message can be manually closed.
 * @param {boolean} [showCloseButton=true] - Flag that determines if close button will be rendered. This flag will be
 * ignored if 'canClose' prop is false.
 * @param {boolean} [closeOnClick=false] - Flag that determines if clicking anywhere inside the message will close it.
 * This flag will be ignored if 'canClose' prop is false.
 * @return {string} GUIID of the message.
 */
export const addHelpMessage = (
	content, autoHideAfter = messages_default_auto_hide_after, icon = icon_font_help_symbol, canClose = true,
	showCloseButton = true, closeOnClick = false
) => addMessage(
	v4(), content, icon, MESSAGE_STYLE.HELP, autoHideAfter, '', canClose, showCloseButton, closeOnClick
);
/**
 * Renders a new help global message
 * @alias addHelpMessage
 */
export const showHelpMessage = addHelpMessage;
/**
 * Renders a new success global message
 *
 * @param {React.Component|JSX.Element|string} content - Message content. Can be a React component or string (HTML is supported).
 * @param {number} [autoHideAfter] - Number of seconds for the message to be visible. If -1, message will be visible
 * @param {string} [icon=''] - Message content icon symbol name (depending on font icon set used).
 * indefinitely or until its closed manually. Default value is retrieved from app config ('/src/config/app.js' file).
 * @param {boolean} [canClose=true] - Flag that determines if message can be manually closed.
 * @param {boolean} [showCloseButton=true] - Flag that determines if close button will be rendered. This flag will be
 * ignored if 'canClose' prop is false.
 * @param {boolean} [closeOnClick=false] - Flag that determines if clicking anywhere inside the message will close it.
 * This flag will be ignored if 'canClose' prop is false.
 * @return {string} GUIID of the message.
 */
export const addSuccessMessage = (
	content, autoHideAfter = messages_default_auto_hide_after, icon = icon_font_success_symbol, canClose = true,
	showCloseButton = true, closeOnClick = false
) => addMessage(
	v4(), content, icon, MESSAGE_STYLE.SUCCESS, autoHideAfter, '', canClose, showCloseButton, closeOnClick
);
/**
 * Renders a new success global message
 * @alias addSuccessMessage
 */
export const showSuccessMessage = addSuccessMessage;

/**
 * Renders a new warning global message
 *
 * @param {React.Component|JSX.Element|string} content - Message content. Can be a React component or string (HTML is
 * supported).
 * @param {number} [autoHideAfter] - Number of seconds for the message to be visible. If -1, message will be visible
 * @param {string} [icon=''] - Message content icon symbol name (depending on font icon set used).
 * indefinitely or until its closed manually. Default value is retrieved from app config ('/src/config/app.js' file).
 * @param {boolean} [canClose=true] - Flag that determines if message can be manually closed.
 * @param {boolean} [showCloseButton=true] - Flag that determines if close button will be rendered. This flag will be
 * ignored if 'canClose' prop is false.
 * @param {boolean} [closeOnClick=false] - Flag that determines if clicking anywhere inside the message will close it.
 * This flag will be ignored if 'canClose' prop is false.
 * @return {string} GUIID of the message.
 */
export const addWarningMessage = (
	content, autoHideAfter = messages_default_auto_hide_after, icon = icon_font_warning_symbol, canClose = true,
	showCloseButton = true, closeOnClick = false
) => addMessage(
	v4(), content, icon, MESSAGE_STYLE.WARNING, autoHideAfter, '', canClose, showCloseButton, closeOnClick
);
/**
 * Renders a new warning global message
 * @alias addWarningMessage
 */
export const showWarningMessage = addWarningMessage;

/**
 * Renders a new error global message
 *
 * @param {React.Component|JSX.Element|string} content - Message content. Can be a React component or string (HTML is
 * supported).
 * @param {number} [autoHideAfter] - Number of seconds for the message to be visible. If -1, message will be visible
 * @param {string} [icon=''] - Message content icon symbol name (depending on font icon set used).
 * indefinitely or until its closed manually. Default value is retrieved from app config ('/src/config/app.js' file).
 * @param {boolean} [canClose=true] - Flag that determines if message can be manually closed.
 * @param {boolean} [showCloseButton=true] - Flag that determines if close button will be rendered. This flag will be
 * ignored if 'canClose' prop is false.
 * @param {boolean} [closeOnClick=false] - Flag that determines if clicking anywhere inside the message will close it.
 * This flag will be ignored if 'canClose' prop is false.
 * @return {string} GUIID of the message.
 */
export const addErrorMessage = (
	content, autoHideAfter = messages_default_auto_hide_after, icon = icon_font_error_symbol, canClose = true,
	showCloseButton = true, closeOnClick = false
) => addMessage(
	v4(), content, icon, MESSAGE_STYLE.ERROR, autoHideAfter, '', canClose, showCloseButton, closeOnClick
);
/**
 * Renders a new error global message
 * @alias addWarningMessage
 */
export const showErrorMessage = addErrorMessage;

/**
 * Renders a new custom global message
 *
 * @param {React.Component|JSX.Element|string} content - Message content. Can be a React component or string (HTML is supported).
 * @param {string} [icon=''] - Message content icon symbol name (depending on font icon set used).
 * @param {string} customClass - Custom CSS class used instead of style class if style is MESSAGE_STYLE_CUSTOM. Use
 * this option if you want to render a custom style message.
 * @param {number} [autoHideAfter] - Number of seconds for the message to be visible. If -1, message will be visible
 * indefinitely or until its closed manually. Default value is retrieved from app config ('/src/config/app.js' file).
 * @param {boolean} [canClose=true] - Flag that determines if message can be manually closed.
 * @param {boolean} [showCloseButton=true] - Flag that determines if close button will be rendered. This flag will be
 * ignored if 'canClose' prop is false.
 * @param {boolean} [closeOnClick=false] - Flag that determines if clicking anywhere inside the message will close it.
 * This flag will be ignored if 'canClose' prop is false.
 * @return {string} GUIID of the message.
 */
export const addCustomMessage = (
	content, icon = '', customClass, autoHideAfter = messages_default_auto_hide_after, canClose = true, showCloseButton = true,
	closeOnClick = false
) => addMessage(
	v4(), content, icon, MESSAGE_STYLE.CUSTOM, autoHideAfter, customClass, canClose, showCloseButton, closeOnClick
);
/**
 * Renders a new custom global message
 * @alias addCustomMessage
 */
export const showCustomMessage = addCustomMessage;

/**
 * Removes previously rendered global message
 *
 * @param {string} GUIID - Message unique GUI ID.
 */
export const removeMessage = GUIID => { actions.removeMessageAction(GUIID)(reduxStore.dispatch) };
/**
 * Removes previously rendered global message
 * @alias removeMessage
 */
export const hideMessage = removeMessage;

/**
 * Clears global messages
 *
 * @param {string} [style=''] - If specified, only messages with the specific style (keyof MESSAGE_STYLE) will be
 * removed.
 */
export const clearMessages = (style = '') => { actions.clearMessagesAction(style)(reduxStore.dispatch) };