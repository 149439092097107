/** @typedef {string} TextInputLabelPosition */
export const TEXT_INPUT_LABEL_POSITION_LEFT = 'left';
export const TEXT_INPUT_LABEL_POSITION_RIGHT = 'right';
/** @enum {TextInputLabelPosition} */
export const TEXT_INPUT_LABEL_POSITION = {
	LEFT: TEXT_INPUT_LABEL_POSITION_LEFT,
	RIGHT: TEXT_INPUT_LABEL_POSITION_RIGHT
};
/** @type {TextInputLabelPosition[]} */
export const TEXT_INPUT_LABEL_POSITIONS = [TEXT_INPUT_LABEL_POSITION_LEFT, TEXT_INPUT_LABEL_POSITION_RIGHT];

/** @typedef {string} TextInputToolbarPosition */
export const TEXT_INPUT_TOOLBAR_POSITION_LEFT = 'left';
export const TEXT_INPUT_TOOLBAR_POSITION_RIGHT = 'right';
/** @enum {TextInputToolbarPosition} */
export const TEXT_INPUT_TOOLBAR_POSITION = {
	LEFT: TEXT_INPUT_TOOLBAR_POSITION_LEFT,
	RIGHT: TEXT_INPUT_TOOLBAR_POSITION_RIGHT
};
/** @type {TextInputToolbarPosition[]} */
export const TEXT_INPUT_TOOLBAR_POSITIONS = [TEXT_INPUT_TOOLBAR_POSITION_LEFT, TEXT_INPUT_TOOLBAR_POSITION_RIGHT];

export * from "./preview/Date/const";