import {cloneDeep} from "lodash";
import {getString, resetObjectValues} from "Core/helpers/data";
import {TherapyTypesItemDataObject, TherapyTypesListItemDataObject} from "../dataObjects";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {TherapyTypesItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new TherapyTypesItemDataObject(
		getString(result, 'id'),
		getString(result, 'name'),
	);

	return result;
}

/**
 * Get output data from input data
 * @param {TherapyTypesItemDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep({
		id: inputData.id,
		name: inputData.name,
	});

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}

/**
 * Get input data from list item
 * @description Use this function to convert list item data object into item data object. This is done because there is
 * no 'fetch-by-id' API endpoint for the item so list item will be used since it has all necessary properties.
 * @param {TherapyTypesListItemDataObject} listItemData - List item data already parsed by 'listItem' data map.
 * @return {TherapyTypesItemDataObject}
 */
export const inputListItem = listItemData => new TherapyTypesItemDataObject(listItemData.id, listItemData.name);

/**
 * Get output list item data from item data
 * @param {TherapyTypesItemDataObject} inputData - Item data.
 * @return {TherapyTypesListItemDataObject}
 */
export const outputListItem = inputData => new TherapyTypesListItemDataObject(inputData.id, inputData.name);