import CoreAuth from "../core/auth";

/**
 * App auth class
 * @note This class must extend CoreAuth class, but it does not have to implement any changes if no custom functionality
 * is needed.
 */
class Auth extends CoreAuth {
	
}

export default Auth;