import React from "react"; 
import DialogComponent, {DIALOG_TYPE, executeComponentCallback} from "Core/components/DialogComponent";
import Icon from "Core/components/display/Icon";
import Label from "Core/components/display/Label";
import {APPOINTMENT_PATIENT_TYPE} from "Const/patient";
import PropTypes from "prop-types";
import FormWrapper, {FormField} from "Core/components/advanced/FormWrapper";
import PatientSelectInput from "Components/input/PatientSelectInput";
import {FORM_FIELD_LABEL_POSITION} from "Core/components/advanced/FormWrapper/FormField";
import {BUTTON_STYLE} from "Core/components/display/Button";
import {icon_font_close_symbol} from "Config/app";

class AppointmentPatientTypeDialog extends DialogComponent {
	constructor(props) {
		super(props, {
			translationPath: 'AppointmentPatientTypeDialog',
			dialogType: DIALOG_TYPE.CUSTOM,
		});

		// Initiate component's state
		this.state = {
			// Flag that specifies if patient select should be displayed
			// @note This will happen when user selects the regular user option.
			showPatientSelect: false,
		};
		
		// Action methods
		this.selectOption = this.selectOption.bind(this);
		
		// Render methods
		this.renderPatientSelect = this.renderPatientSelect.bind(this);
		this.getCustomActionButtons = this.getCustomActionButtons.bind(this);
	}
	
	async asyncComponentDidMount() {
		await super.asyncComponentDidMount();
		
		// Focus existing patient option by default
		setTimeout(() => { document.getElementById('APPOINTMENT_PATIENT_TYPE_EXISTING_PATIENT').focus(); });
	}

	/**
	 * Select patient type option
	 * @param {AppointmentPatientType} userType - Selected patient type.
	 */
	selectOption(userType) {
		const {dialogGUIID} = this.props;

		if (userType === APPOINTMENT_PATIENT_TYPE.EXISTING_PATIENT) this.setState({ showPatientSelect: true }).then();
		else executeComponentCallback(this.props.onSelectUserType, dialogGUIID, userType);
	}
	
	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render patient select form
	 * @note This will happen when user selects the regular user option.
	 * @return {JSX.Element}
	 */
	renderPatientSelect() {
		return (
			<FormWrapper className="no-margin">
				<FormField
					inputClassName="full"
					labelPosition={FORM_FIELD_LABEL_POSITION.NONE}
				>
					<PatientSelectInput
						autoFocus={true}
						onEnterKey={() => {
							const {dialogGUIID} = this.props;
							const {selectedPatient} = this.state;
							
							if (!!selectedPatient) {
								executeComponentCallback(
									this.props.onSelectUserType, dialogGUIID, APPOINTMENT_PATIENT_TYPE.EXISTING_PATIENT, selectedPatient,
								);
							}
						}}
						onChange={o => this.setState({selectedPatient: o})}
						placeholder={this.t('patientSelectPlaceholder')}
					/>
				</FormField>
				<Label 
					icon="info-circle" 
					element="small" 
					elementProps={{className: 'notice'}}
					content={this.t('patientSelectInfo')} 
				/>
			</FormWrapper>
		);
	}

	/**
	 * Get dialog action buttons
	 * @return {*} Action buttons JSX to use in the main render method.
	 */
	getCustomActionButtons() {
		const {selectedPatient} = this.state;
		let buttons = [];

		buttons.push({
			style: BUTTON_STYLE.SUCCESS,
			label: this.t('continueBtn'),
			icon: 'arrow-right',
			onClick: () => {
				const {dialogGUIID} = this.props;
				const {selectedPatient} = this.state;

				if (!!selectedPatient) {
					executeComponentCallback(
						this.props.onSelectUserType, dialogGUIID, APPOINTMENT_PATIENT_TYPE.EXISTING_PATIENT, selectedPatient,
					);
				}
			},
			disabled: !selectedPatient,
		});

		buttons.push({
			style: BUTTON_STYLE.DEFAULT,
			label: this.t('Close', 'general'),
			icon: icon_font_close_symbol,
			onClick: () => this.close(),
		});
		
		return buttons;
	}
	
	render() {
		const {showPatientSelect} = this.state;
		
		return this.renderDialog(
			this.renderTitle(showPatientSelect ? this.t('titleSelectPatient') : this.t('title')), 
			(
				showPatientSelect ?
					this.renderPatientSelect() 
					:
					<div 
						className="card-grid per-row-2" 
						onKeyDown={e => {
							const activeOption = document.activeElement;
							switch (e.key) {
								case 'ArrowLeft':
									const prevOption = activeOption?.previousSibling;
									if (!!prevOption) prevOption.focus();
									break;
								case 'ArrowRight':
									const nextOption = activeOption?.nextSibling;
									if (!!nextOption) nextOption.focus();
									break;
								// no default
							}
						}}
					>
						<div 
							id={`APPOINTMENT_PATIENT_TYPE_${APPOINTMENT_PATIENT_TYPE.EXISTING_PATIENT}`}
							className="card action-card colored color-3"
							onClick={() => this.selectOption(APPOINTMENT_PATIENT_TYPE.EXISTING_PATIENT)}
							onKeyDown={e => { 
								if (e.key === 'Enter') this.selectOption(APPOINTMENT_PATIENT_TYPE.EXISTING_PATIENT); 
							}}
							tabIndex={1}
						>
							<div className="card-content">
								<Icon symbol="user" />
								<Label element="p" content={this.t('patient')} />
							</div>
						</div>
						
						<div
							id={`APPOINTMENT_PATIENT_TYPE_${APPOINTMENT_PATIENT_TYPE.ANONYMOUS_PATIENT}`}
							className="card action-card colored color-2"
							onClick={() => this.selectOption(APPOINTMENT_PATIENT_TYPE.ANONYMOUS_PATIENT)}
							onKeyDown={e => { 
								if (e.key === 'Enter') this.selectOption(APPOINTMENT_PATIENT_TYPE.ANONYMOUS_PATIENT); 
							}}
							tabIndex={1}
						>
							<div className="card-content">
								<Icon symbol="user-secret" />
								<Label element="p" content={this.t('anonymousPatient')} />
							</div>
						</div>
					</div>
			),
			(showPatientSelect ? this.getCustomActionButtons() : undefined)
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
AppointmentPatientTypeDialog.propTypes = {
	...DialogComponent.propTypes,
	
	// Arguments: {dialogGUIID: string, userType: AppointmentPatientType, selectedPatient: PatientSelectOptionDataObject}
	onSelectUserType: PropTypes.func,
};

export default AppointmentPatientTypeDialog;