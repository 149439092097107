import React from "react";
import PageDataComponent from "Core/components/PageDataComponent";
import {connect} from "react-redux";
import {getPageActions} from "Core/helpers/redux";
import {selectors} from "Core/store/reducers";
import * as appConfig from "../../config";
import * as pageConfig from "./config";
import * as actions from "./actions";
import {getMenuSidebarShrankFromStorage} from "Layout/elements/MainSidebar/helpers";
import {reducerStoreKey} from "./reducer";
import {areAllObjectPropsEmpty, getArray, isset} from "Core/helpers/data";
import * as filterDataMap from "./dataMap/filter";
import {scrollToSelector} from "Core/helpers/dom";
import {BUTTON_STYLE} from "Core/components/display/Button";
import Label from "Core/components/display/Label";
import SimpleStaticSearch, {
	SIMPLE_STATIC_SEARCH_DISPLAY_TYPE,
	SIMPLE_STATIC_SEARCH_LAYOUT,
	SimpleStaticSearchOptionObject
} from "Core/components/advanced/SimpleStaticSearch";
import DataTable, {DATA_TABLE_CELL_TYPE} from "Core/components/advanced/DataTable";
import {PAGINATION_TYPE} from "Core/components/action/Pagination";
import Spinner from "Core/components/display/Spinner";
import Icon from "Core/components/display/Icon";
import {
	DataTableCellAnyTypeOptionsDataObject, DataTableCellDateTypeOptionsDataObject,
	DataTableCellTextTypeOptionsDataObject
} from "Core/components/advanced/DataTable/DataTableCell/dataObjects";
import {Link} from "react-router-dom";
import {getAppLocaleDateFormat} from "Core/helpers/locale";
import {LOCALE_DATE_FORMAT_NAME as LOCALE_DATE_FORMAT} from "Core/const/locale";
import {forOwn, get} from "lodash";
import {capitalize} from "Core/helpers/string";
import {AGE_TYPES} from "Pages/apps/default/search/searchPatients/const";
import {routerPath as patientRecordRouterPath} from "Pages/apps/default/patientRecord/config";
import {AsyncMountError} from "Core/errors";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	mainSidebarShrank: getMenuSidebarShrankFromStorage(selectors.mainSidebar.shrank(state)),
	mainListData: selectors[reducerStoreKey].getSearchPatientsListData(state),
	mainList: selectors[reducerStoreKey].getSearchPatientsList(state),
	mainListPagination: selectors[reducerStoreKey].getSearchPatientsListPagination(state),
	mainListSort: selectors[reducerStoreKey].getSearchPatientsListSort(state),
	mainListFilter: selectors[reducerStoreKey].getSearchPatientsListFilter(state),
});

class SearchPatientsPage extends PageDataComponent {
	constructor(props) {
		super(props, {
			data: {
				/**
				 * Flag showing if filter is loading
				 */
				filterLoading: false,
			},
			/**
			 * Recommendation codebook loaded from IO
			 * @note If undefined, codebook is not loaded yet. If null, codebook is being loaded.
			 * @type {CodebookItem[]|undefined|null}
			 */
			recommendationCodebook: undefined,
		}, {
			domPrefix: 'search-patients-page',
			translationPath: pageConfig.translationPath,
			routerPath: pageConfig.routerPath,
			checkLogin: false,
			disableLoad: true,
			renderTitle: false,
		}, 'page_title');

		// Refs
		this.mainListFilterRef = null;

		// Data methods
		this.loadPageCodebook = this.loadPageCodebook.bind(this);
		this.reloadMainList = this.reloadMainList.bind(this);
		this.loadMainListPage = this.loadMainListPage.bind(this);
		this.sortMainList = this.sortMainList.bind(this);
		this.filterMainList = this.filterMainList.bind(this);
		this.removeMainListFilter = this.removeMainListFilter.bind(this);

		// Reload main list when new patient has been created using the header button
		this.registerEventListener('onHeaderPatientCreated', () => {
			if (this.getProp('mainListData') !== null) this.reloadMainList().then();
		});
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called on component mount and should be used to load any data required by the page
	 * @return {Promise<any>}
	 * @throws {AsyncMountError}
	 */
	loadPageData() {
		const {mainListData, mainListFilter} = this.props;

		try {
			// If main list data was already loaded (user already searched for something)
			if (mainListData !== null) {
				// Reload main list with currently applied filter, sort and pagination
				this.reloadMainList().then(res => { if (!isset(res)) throw new AsyncMountError(); });
			}

			// Load all codebooks used in the applied filter
			// @description Filter select components that use codebooks load them on menu open, so they need to be 
			// preloaded for all applied filter fields in order to select them properly (usually when opening the page).
			const codebooks = {
				recommendation: 'recommendationId',
			};

			forOwn(codebooks, (filterField, codebook) => {
				if (!!get(mainListFilter, filterField) && !isset(get(this.state, `${codebook}Codebook`))) {
					let actionParams = [];
					switch (codebook) {
						case 'therapySubtype': actionParams = [get(mainListFilter, 'therapyTypeId', null)]; break;
						case 'therapySubSubtype': actionParams = [get(mainListFilter, 'therapySubTypeId', null)]; break;
						// no default
					}
					this.loadPageCodebook(codebook, ...actionParams).then();
				}
			});
		} catch (e) {
			console.error(e);
			throw new AsyncMountError();
		}
	}

	/**
	 * Reload main list using current options (page, sort, ...)
	 * @return {Promise<*>}
	 */
	reloadMainList() {
		const {loadSearchPatientsListAction, mainListPagination, mainListSort, mainListFilter} = this.props;
		const {pageNo, perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = filterDataMap.output(mainListFilter);

		return this.executeAbortableAction(loadSearchPatientsListAction, oFilter, pageNo, perPage, sortBy, sortDir)
			.then(res => {
				this.mainListFilterRef?.reload();
				return res;
			});
	}

	/**
	 * Reload main list using current options (page, sort, ...) if any
	 * @param {number} [pageNo=1] - Page number to load (starts with 1).
	 * @return {Promise<*>}
	 */
	loadMainListPage(pageNo = 1) {
		const {loadSearchPatientsListAction, mainListPagination, mainListSort, mainListFilter} = this.props;
		const {perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = filterDataMap.output(mainListFilter);

		return this.executeAbortableAction(loadSearchPatientsListAction, oFilter, pageNo, perPage, sortBy, sortDir);
	}

	/**
	 * Load a specific page codebook data into local state
	 * @note Page codebook actions are define in 'store/page/actions/codebooks' directory.
	 *
	 * @param {string} codebookName - Name of the codebook to load (for example: 'therapist').
	 * @param {any} [actionParams] - Any codebook action parameters (see the codebook definitions for reference).
	 * @return {Promise<CodebookItem[]>}
	 */
	loadPageCodebook(codebookName, ...actionParams) {
		const loadCodebookAction = get(this.props, `fetch${capitalize(codebookName)}CodebookAction`);

		if (loadCodebookAction) {
			this.setState({[`${codebookName}Codebook`]: null})
				.then(() => this.executeAbortableAction(loadCodebookAction, ...actionParams))
				.then(codebook => this.setState({[`${codebookName}Codebook`]: codebook}).then(() => codebook));
		}
		return Promise.resolve([]);
	}

	/**
	 * Sort main list
	 * @param {string} sortBy - Name of the sort column.
	 * @param {string} sortDir - Direction of the sort.
	 * @return {Promise<*>}
	 */
	sortMainList(sortBy, sortDir) {
		const {loadSearchPatientsListAction, mainListPagination, mainListFilter} = this.props;
		const {pageNo, perPage} = mainListPagination;
		const oFilter = filterDataMap.output(mainListFilter);

		return this.executeAbortableAction(loadSearchPatientsListAction, oFilter, pageNo, perPage, sortBy, sortDir);
	}

	/**
	 * Filter main list
	 * @param {Object} filter - Filter object where keys are filter field names and values are filter values.
	 * @return {Promise<*>}
	 */
	filterMainList(filter) {
		const {
			loadSearchPatientsListAction, setSearchPatientsFilterAction, mainListPagination, mainListSort,
		} = this.props;
		const {perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = filterDataMap.output(filter);
		
		// Set filter so that the change will be detected after IO
		setSearchPatientsFilterAction(oFilter);

		return this.setValue('filterLoading', true)
			.then(() => this.executeAbortableAction(loadSearchPatientsListAction, oFilter, 1, perPage, sortBy, sortDir))
			.then(() => this.setValue('filterLoading', false))
			.then(() => {
				if (!areAllObjectPropsEmpty(oFilter, true, false)) {
					scrollToSelector('#main-page-table', false, 80);
				}
			});
	}

	/**
	 * Remove main list filter
	 * @return {Promise<*>}
	 */
	removeMainListFilter() {
		const {resetSearchPatientsListAction} = this.props;
		return this.executeAbortableAction(resetSearchPatientsListAction);			
	}

	
	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {
			mainListData, mainList, mainListPagination, mainListSort, mainListFilter, mainSidebarShrank,
			toggleMainSidebarSizeAction
		} = this.props;
		const { recommendationCodebook } = this.state;
		
		return this.renderLayout((
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')}`}>
				{
					this.hasTranslation('page_short_description') && this.t('page_short_description') ?
						<div className="simple-page-description">
							<Label content={this.t('page_short_description')} supportHtml={true} />
						</div>
						: null
				}

				<SimpleStaticSearch
					styleName="compact"
					className="main-search rows-6"
					collapsable={true}
					layout={SIMPLE_STATIC_SEARCH_LAYOUT.STACKED}
					buttonProps={{
						displayStyle: BUTTON_STYLE.DEFAULT
					}}
					options={[
						new SimpleStaticSearchOptionObject(
							'patientFirstName',
							this.t('patientFirstNameField'),
						),
						new SimpleStaticSearchOptionObject(
							'patientLastName',
							this.t('patientLastNameField')
						),
						new SimpleStaticSearchOptionObject(
							'patientsMiddleName',
							this.t('middleNameField')
						),
						new SimpleStaticSearchOptionObject(
							'patientAddress',
							this.t('addressField')
						),
						new SimpleStaticSearchOptionObject(
							'patientPhone',
							this.t('telephoneField')
						),
						new SimpleStaticSearchOptionObject(
							'patientMobilePhone',
							this.t('patientMobilePhoneField')
						),
						new SimpleStaticSearchOptionObject(
							'patientEmail',
							this.t('emailField')
						),
						new SimpleStaticSearchOptionObject(
							'recommendationId',
							this.t('recommendationNameField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT,
							{
								isClearable: true,
								options: getArray(recommendationCodebook).map(i => ({label: i.name, value: i.id})),
								isLoading: (recommendationCodebook === null),
								onMenuOpen: () => {
									if (!isset(recommendationCodebook)) {
										this.loadPageCodebook('recommendation').then();
									}
								}
							}
						),
						new SimpleStaticSearchOptionObject(
							'ageFrom',
							this.t('ageFromField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.NUMBER,
							{
								intOnly: true
							}
						),
						new SimpleStaticSearchOptionObject(
							'ageTo',
							this.t('ageToField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.NUMBER,
							{
								intOnly: true
							}
						),
						new SimpleStaticSearchOptionObject(
							'typeAge',
							this.t('typeAgeField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT,
							{
								isClearable: true,
								options: getArray(AGE_TYPES).map(i => ({label: this.tt(i, 'ageTypesField'), value: i}))
							}
						)
					]}
					value={mainListFilter}
					title={(<Label icon="search" content={this.t('page_title')} />)}
					applied={mainListData !== null}
					enableToolbar={true}
					enableResetButton={false}
					onChange={this.filterMainList}
					onRemove={this.removeMainListFilter}
					ref={node => { this.mainListFilterRef = node; }}
				/>

				{mainListData !== null ?
					<DataTable
						id="main-page-table"
						responsive={true}
						responsiveBreakpointName="bp-l"
						className="standard"
						columns={[
							{
								label: this.t('patientFullNameField'),
								dataType: DATA_TABLE_CELL_TYPE.ANY,
								dataTypeOptions: new DataTableCellAnyTypeOptionsDataObject({
									content: listItem => (
										<Link to={`${patientRecordRouterPath}/item/${listItem.id}`}>
											<Label content={`${listItem.firstName} ${listItem.lastName}`} />
										</Link>
									)
								}),
								minWidth: 150,
								stopPropagation: true
							},
							{
								name: 'middleName',
								label: this.t('middleNameField'),
								defaultValue: '—',
								emptyAsDefault: true,
							},
							{
								name: 'address',
								label: this.t('addressField'),
								defaultValue: '—',
								emptyAsDefault: true,
								minWidth: 150,
							},
							{
								name: 'telephone',
								label: this.t('telephoneField'),
								dataTypeOptions: new DataTableCellTextTypeOptionsDataObject({
									whiteSpace: 'nowrap',
								}),
								defaultValue: '—',
								emptyAsDefault: true,
								width: 1,
							},
							{
								name: 'email',
								label: this.t('emailField'),
								dataTypeOptions: new DataTableCellTextTypeOptionsDataObject({
									whiteSpace: 'nowrap',
								}),
								defaultValue: '—',
								emptyAsDefault: true,
								width: 1,
							},
							{
								name: 'birthDate',
								label: this.t('birthDateField'),
								dataType: DATA_TABLE_CELL_TYPE.DATE,
								dataTypeOptions: new DataTableCellDateTypeOptionsDataObject({
									outputFormat: getAppLocaleDateFormat(LOCALE_DATE_FORMAT.SHORT),
									whiteSpace: 'nowrap'
								}),
								width: 1,
							},
							{
								name: 'recommendationName',
								label: this.t('recommendationNameField'),
								defaultValue: '—',
								emptyAsDefault: true,
								minWidth: 150,
							}
						]}
						data={mainList}
						paginationType={PAGINATION_TYPE.DYNAMIC}
						onSortByColumn={this.sortMainList}
						onPaginationClick={this.loadMainListPage}
						{...mainListPagination}
						{...mainListSort}
					/>
					: this.getValue('filterLoading') ?
						<div className="page-notice-wrapper no-select">
							<div className="page-notice-title-icon icon">
								<Spinner size={60} weight={3} />
							</div>
						</div>
					:
						<div className="page-notice-wrapper no-select">
							<Icon symbol="hand-drawn-up" symbolPrefix="icofont-" className="page-notice-title-icon" />
							<Label
								element="p"
								elementProps={{className: 'page-notice-title bold'}}
								content={this.t('search_notice_title')}
							/>
							<Label
								element="p"
								elementProps={{className: 'page-notice'}}
								content={this.t('search_notice_description')}
							/>
						</div>
				}
			</div>
		), undefined, undefined, {
			app: appConfig,
			mainSidebarShrank,
			toggleMainSidebarSizeAction,
		});
	}
}

export * from "./config";
export default connect(mapStateToProps, getPageActions(actions))(SearchPatientsPage);