import "./default.style.css";
import "./card.style.css";

import React from "react";
import DataComponent from "Core/components/DataComponent";
import Label from "Core/components/display/Label";
import FormWrapper, {FormField} from "Core/components/advanced/FormWrapper";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import {omit} from "lodash";
import PropTypes from "prop-types";
import {FORM_FIELD_LABEL_POSITION} from "Core/components/advanced/FormWrapper/FormField";
import {connect} from "react-redux";
import {getGlobalActions} from "Core/helpers/redux";
import TextInput from "Core/components/input/TextInput";
import DataValueValidation from "Core/validation";
import {getString, isset} from "Core/helpers/data";

class PasswordResetRequest extends DataComponent {
	constructor(props) {
		super(props, {
			data: {
				email: '',
			}
		}, {
			disableLoad: true,
			translationPath: 'PasswordResetRequest'
		});

		// Action method
		this.requestPassword = this.requestPassword.bind(this);
	}


	// DataValueValidation methods -----------------------------------------------------------------------------------------------
	/**
	 * Default component's data validation method
	 *
	 * @return {boolean} True if component's data validation passed successfully.
	 */
	validate() {
		const dataValidation = new DataValueValidation();
		const dataToValidate = this.getData();

		dataValidation.addRule('email', 'required', 'email');

		const validationErrors = dataValidation.run(dataToValidate);
		if (validationErrors) this.setValidationErrors('', validationErrors).then();
		else this.clearValidationErrors().then();
		return !validationErrors;
	}


	// Action method ----------------------------------------------------------------------------------------------------
	/**
	 * Request new password
	 */
	requestPassword() {
		const {action} = this.props;
		const email = this.getValue('email');

		this.clearValidationErrors()
			.then(() => {
				if (this.validate()) return action(email);
			});
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {
			styleName, className, showAppName, appName, showFormTitle, formTitle, showInputLabel, inputLabelPosition,
			submitOnEnter, buttonProps, backAction, backButtonProps, renderToTop, renderToBottom, 
		} = this.props;

		return (
			<div id={this.getDomId()} className={`password-reset-request-component ${styleName}-style ${className}`}>
				{renderToTop ? renderToTop : null}
				
				{/* Header --------------------------------------------------------------------------------------------- */}
				<div className="password-reset-request-header">
					{
						showAppName ?
							<div className="app-name">
								{isset(appName) ? appName : this.t('title', 'App')}
							</div>
							: null
					}

					{
						showFormTitle ?
							<>
								<div className="form-title">
									{isset(formTitle) ? formTitle : this.t('form_title')}
									<Label
										element="div"
										elementProps={{className: 'form-desc'}}
										content={this.t('form_description')}
										supportHtml={true}
									/>
								</div>
							</>
							: null
					}
				</div>


				{/* Content -------------------------------------------------------------------------------------------- */}
				<div className="password-reset-request-content">
					<FormWrapper>
						<FormField
							required={true}
							label={this.t('Email')}
							labelPosition={showInputLabel ? inputLabelPosition : FORM_FIELD_LABEL_POSITION.NONE}
							errorMessages={this.getValidationErrors('email')}
						>
							<TextInput
								type="email"
								name="email"
								value={this.getValue('email')}
								onChange={this.handleInputChange}
								onEnterKey={submitOnEnter ? this.requestPassword : undefined}
								placeholder={this.t('Enter your email address')}
							/>
						</FormField>
					</FormWrapper>
				</div>


				{/* Actions -------------------------------------------------------------------------------------------- */}
				<div className="password-reset-request-actions">
					<Button
						className={`send-btn ${getString(buttonProps, 'className')}`}
						label={this.t('action_btn')}
						onClick={this.requestPassword}
						{...PasswordResetRequest.defaultProps.buttonProps}
						{...omit(buttonProps, ['className', 'onClick'])}
					/>
					
					{
						backAction ?
							<Button
								className={`back-btn ${getString(backButtonProps, 'className')}`}
								label={this.t('Back', 'general')}
								onClick={backAction}
								{...PasswordResetRequest.defaultProps.backButtonProps}
								{...omit(backButtonProps, ['className', 'onClick'])}
							/>
							: null
					}
				</div>

				{renderToBottom ? renderToBottom : null}
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
PasswordResetRequest.propTypes = {
	// Component style name
	// @description Component style name is a name of the style that will be used to determine the CSS used to style the
	// component.
	styleName: PropTypes.string,
	// Password reset component wrapper element ID class attribute
	id: PropTypes.string,
	// Password reset component wrapper element class attribute
	className: PropTypes.string,

	// Flag that specifies if app name will be rendered
	showAppName: PropTypes.bool,
	// App name to render instead of default app name
	// @note If undefined, default app name will be rendered.
	appName: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	// Flag that specifies if password reset form title will be rendered
	showFormTitle: PropTypes.bool,
	// Password reset form title to render instead of default password reset form title
	// @note If undefined, default form title will be rendered.
	formTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	// Flag that specifies if password reset form input fields should have labels
	showInputLabel: PropTypes.bool,
	// Password reset component input fields label position
	// @see FORM_FIELD_LABEL_POSITION const.
	inputLabelPosition: PropTypes.string,
	// Flag that determines if form will be submitted on email input enter key press
	submitOnEnter: PropTypes.bool,
	
	// Main button component props
	buttonProps: PropTypes.object,
	// Form submit function
	action: PropTypes.func, // Arguments: email

	// Form back action
	// @note Back button will only render if this action is specified.
	backAction: PropTypes.func, // Arguments: button click event
	// Back button props
	// @see Button component props.
	backButtonProps: PropTypes.object,

	// Anything to render at the top of the login component
	renderToTop: PropTypes.element,
	// Anything to render at the bottom of the login component
	// @note Only errors will be rendered below this.
	renderToBottom: PropTypes.element
};

/**
 * Define component default values for own props
 */
PasswordResetRequest.defaultProps = {
	styleName: 'default',
	className: '',
	showAppName: false,
	appName: undefined,
	showFormTitle: true,
	formTitle: undefined,
	showInputLabel: true,
	inputLabelPosition: FORM_FIELD_LABEL_POSITION.STACKED,
	submitOnEnter: true,
	buttonProps: {
		icon: 'envelope-o',
		displayStyle: BUTTON_STYLE.ACTION,
		displayType: BUTTON_DISPLAY_TYPE.SOLID,
		big: true
	},
	backButtonProps: {
		displayStyle: BUTTON_STYLE.SUBTLE,
		displayType: BUTTON_DISPLAY_TYPE.TRANSPARENT,
		icon: 'chevron-left',
		big: true
	},

	renderToTop: null,
	renderToBottom: null,
};

export default connect(null, getGlobalActions())(PasswordResetRequest);