/**
 * Data object representing the patient record expert reports list item
 * @note This is usually used in data table as a row. Params don't have default values since this data object is used to
 * represent data that actually exists.
 */
export class PatientRecordExpertReportsListItemDataObject {
	/**
	 * @param {string} id - List item's ID.
	 * @param {Date} date - Date of the expert report.
	 * @param {string} title - Title of the expert report.
	 */
	constructor(id, date, title) {
		this.id = id;
		this.date = date;
		this.title = title;
	}
}
