import React from "react";
import DialogDataComponent from "Core/components/DialogDataComponent";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getPageActions} from "Core/helpers/redux";
import * as actions from "../../actions";
import {TherapyTypesItemDataObject} from "Pages/apps/default/therapyTypes/dataObjects";
import DataValueValidation from "Core/validation";
import FormWrapper, {FormField} from "Core/components/advanced/FormWrapper";
import TextInput from "Core/components/input/TextInput";
import {FORM_FIELD_LABEL_POSITION} from "Core/components/advanced/FormWrapper/FormField";

/**
 * Get all actions used by this component
 * @type {Object}
 */
const allActions = getPageActions({...actions});

class TherapyTypeDialog extends DialogDataComponent {
	constructor(props) {
		super(props, {
			data: props.isNew ? new TherapyTypesItemDataObject() : props.data,
		}, {
			translationPath: 'TherapyTypeDialog',
			disableLoad: true,
			autoFocusActionButtons: false,
		});
	}

	/**
	 * Default component's data validation method
	 *
	 * @return {boolean} True if component's data validation passed successfully.
	 */
	validate() {
		const dataValidation = new DataValueValidation();
		/** @type {PaymentDataObject} */
		const dataToValidate = this.getData();

		dataValidation.addRule('name', 'required');

		// Run validation
		const validationErrors = dataValidation.run(dataToValidate);

		if (validationErrors) this.setValidationErrors('', validationErrors).then();
		else this.clearValidationErrors().then();
		return !validationErrors;
	}
	
	render() {
		const {isNew, level} = this.props;
		
		return this.renderDialog(
			this.renderTitle(this.t(isNew ? `title_create${level}` : `title${level}`)), 
			(
				<FormWrapper className="dialog-form">
					<FormField
						label={`${this.t(`nameLabel${level}`)}:`}
						labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}
						errorMessages={this.getValidationErrors('name')}
					>
						<TextInput
							name="name"
							value={this.getValue('name')}
							onChange={this.handleInputChange}
							onEnterKey={() => this.save()}
							inputProps={{autoFocus: true}}
						/>
					</FormField>
				</FormWrapper>
			)
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
TherapyTypeDialog.propTypes = {
	// Flag that specifies if this is a dialog for creating a new therapy type
	// @note If true, existing therapy type data should be passed through the 'data' prop.
	isNew: PropTypes.bool,
	// Therapy type data for editing an existing therapy type
	// @note Used only if 'isNew' is false. Data will be loaded only once (one mount).
	// @type {TherapyTypesItemDataObject}
	data: PropTypes.object,

	// Event triggered if therapy type was saved successfully and dialog has already been closed
	// @param {TherapyTypesItemDataObject} therapyType, 
	// @param {string} dialogGUIID
	onSave: PropTypes.func,
	// Event triggered when dialog close method is called
	// @note Dialog will not be closed if event callback returns false.
	// @param {string} dialogGUIID
	onClose: PropTypes.func,
};

export default connect(null, allActions, null, {forwardRef: true})(TherapyTypeDialog);