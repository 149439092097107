/**
 * Data object representing the physicians list item
 * @note This is usually used in data table as a row. Params don't have default values since this data object is used to
 * represent data that actually exists.
 */
export class PhysiciansListItemDataObject {
	/**
	 * @param {string} id - List item's ID.
	 * @param {string} firstName - Physicians first name
	 * @param {string} lastName - Physicians last name.
	 * @param {string} institution - Physicians institution.
	 * @param {string} telephone - Physicians landline telephone number.
	 * @param {string} mobilePhone - Physicians mobile phone number.
	 * @param {string} email - Physicians email.
	 */
	constructor(id, firstName,	lastName, institution, telephone, mobilePhone, email) {
		this.id = id;
		this.firstName = firstName;
		this.lastName = lastName;
		this.institution = institution;
		this.telephone = telephone;
		this.mobilePhone = mobilePhone;
		this.email = email;
	}
}

/**
 * Data object representing the physicians item
 * @note This is usually used when creating or updating data table item using a popup or a dialog. Params have default 
 * values since this data object can be used for creating an item. This is why 'id' has a default value of null which 
 * represents a new item that is being created.
 */
export {PhysicianDataObject as PhysiciansItemDataObject} from "DataObjects/physician";