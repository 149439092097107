/**
 * Data object representing the search therapies list item
 * @note This is usually used in data table as a row. Params don't have default values since this data object is used to
 * represent data that actually exists.
 */
export class SearchTherapiesListItemDataObject {
	/**
	 * @param {string} id - List item's ID.
	 * @param {string} diagnosis - List item's diagnosis.
	 * @param {Date} therapyDate - Date of prescribed therapy.
	 * @param {string} medicalRecordCategory - Medical record category.
	 * @param {number} medicalRecordNumber - Patients medical record number.
	 * @param {string} sentByPhysician - Physicians full name. 
	 * @param {number} numberOfTherapies - Number of therapies prescribed.
	 * @param {string} customerFullName - Patients full name.
	 * @param {[Array]} prescribedTherapyDataList - List of prescribed therapies, sub therapies and additional info.
	 * @param {string} customerId
	 */
	constructor(
		id, diagnosis, therapyDate, medicalRecordCategory, medicalRecordNumber, sentByPhysician,
		numberOfTherapies, customerFullName, prescribedTherapyDataList, customerId
	) {
		this.id = id;
		this.diagnosis = diagnosis;
		this.therapyDate = therapyDate;
		this.medicalRecordCategory = medicalRecordCategory;
		this.medicalRecordNumber = medicalRecordNumber;
		this.sentByPhysician = sentByPhysician;
		this.numberOfTherapies = numberOfTherapies;
		this.customerFullName = customerFullName;
		this.customerId = customerId;
		this.prescribedTherapyDataList = prescribedTherapyDataList;
	}
}

/**
 * Data object representing the search therapies prescribed therapies list item.
 * @note This is usually used in data table as a row. Params don't have default values since this data object is used to
 * represent data that actually exists.
 */

export class PrescribedTherapiesItemDataObject {
	/**
	 * @param {string} id - Prescribed therapies list item ID.
	 * @param {string} prescribedTherapyType - Type of the prescribed therapy.
	 * @param {string} prescribedTherapyTypeId - Prescribed therapy database ID.
	 * @param {string} prescribedTherapySubtype - Subtype of the prescribed therapy.
	 * @param {string} prescribedTherapySubtypeId - Database ID of th prescribed therapy subtype.
	 * @param {string} prescribedTherapySubSubtype - Sub-subtype of the prescribed therapy.
	 * @param {string} prescribedTherapySubSubtypeId - Database ID of th prescribed therapy sub-subtype.
	 * @param {string} duration - How long should the therapy last.
	 * @param {string} additionalInfo - Additional information prescribed by physician or therapist.
	 * @param {boolean} active - Showing if the therapy is active.
	 */
	constructor(id, prescribedTherapyType, prescribedTherapyTypeId, prescribedTherapySubtype, 
		prescribedTherapySubtypeId, prescribedTherapySubSubtype, prescribedTherapySubSubtypeId, duration, additionalInfo, 
		active) {
		this.id = id;
		this.prescribedTherapyType = prescribedTherapyType;
		this.prescribedTherapyTypeId = prescribedTherapyTypeId;
		this.prescribedTherapySubtype = prescribedTherapySubtype;
		this.prescribedTherapySubtypeId = prescribedTherapySubtypeId;
		this.prescribedTherapySubSubtype = prescribedTherapySubSubtype;
		this.prescribedTherapySubSubtypeId = prescribedTherapySubSubtypeId;
		this.duration = duration;
		this.additionalInfo = additionalInfo;
		this.active = active;
	}
}
