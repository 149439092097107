import {forOwn, set, cloneDeep, unset} from "lodash";
import {STANDARD_DATE_TIME_FORMAT} from "Core/const/datetime";
import {getDate, getDateString} from "Core/helpers/datetime";
import {resetObjectValues} from "Core/helpers/data";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data.
 * @return {Object}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	forOwn(result, (value, key) => {
		// Convert date strings to Date objects
		if (key === 'dateFrom' || key === 'dateTo') {
			set(result, key, getDate(value, STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S));
		}
		
		switch (key) {
			case 'therapyType': set(result, 'therapyTypeId', value); unset(result, key); break;
			case 'therapySubType': set(result, 'therapySubTypeId', value); unset(result, key); break;
			case 'therapySubSubType': set(result, 'therapySubSubTypeId', value); unset(result, key); break;
			// no default
		}
	});

	return result;
}

/**
 * Get output data from input data
 * @param {Object} input - Input value.
 * @return {Object}
 */
export const output = input => {
	let result = cloneDeep(input);

	forOwn(result, (value, key) => {
		// Convert Date object to appropriate date string
		if (value instanceof Date) set(result, key, getDateString(value, STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S));
		
		switch (key) {
			case 'therapyTypeId': set(result, 'therapyType', value); unset(result, key); break;
			case 'therapySubTypeId': set(result, 'therapySubType', value); unset(result, key); break;
			case 'therapySubSubTypeId': set(result, 'therapySubSubType', value); unset(result, key); break;
			// no default
		}
	});

	// // Set the default therapy ID filter value to display all because this filter it is not used on the page
	// set(result, 'therapyId', '*');
	
	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}