import React from 'react';
import DialogDataComponent, {executeComponentCallback} from "Core/components/DialogDataComponent";
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import * as actions from "./actions";
import {getGlobalActions} from 'Core/helpers/redux';
import {selectors as patientRecordSelectors} from "Components/advanced/PatientRecord/reducer";
import DataValueValidation from "Core/validation";
import FormWrapper, {FormField} from "Core/components/advanced/FormWrapper";
import EmailInput from "Core/components/input/EmailInput";
import {FORM_FIELD_LABEL_POSITION} from "Core/components/advanced/FormWrapper/FormField";
import {getArray, getString, isset} from "Core/helpers/data";

/**
 * Get all actions used by this component
 * @type {Object}
 */
const allActions = getGlobalActions({...actions});

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	patientRecord: patientRecordSelectors.getPatientRecord(state),
});

class ExpertReportSendEmailDialog extends DialogDataComponent {
	constructor(props) {
		const patientEmail = getString(props, 'patientRecord.email');
		
		super(props, {
			data: {
				/**
				 * List of email addresses
				 * @note If defined, current patient's email will be automatically added by default.
				 * @type {string[]}
				 */
				emails: !!patientEmail ? [patientEmail] : [],
			},
		}, {
			translationPath: 'ExpertReportSendEmailDialog',
			domPrefix: 'expert-report-send-email-dialog',
			disableLoad: true,
			optimizedUpdate: true,
		});
	}

	/**
	 * Default component's data validation method
	 *
	 * @return {boolean} True if component's data validation passed successfully.
	 */
	validate() {
		const dataValidation = new DataValueValidation();
		/** @type {string[]} */
		const dataToValidate = this.getData();
		
		dataValidation.addRule('emails', 'required');

		// Run validation
		const validationErrors = dataValidation.run(dataToValidate);

		if (validationErrors) this.setValidationErrors('', validationErrors).then();
		else this.clearValidationErrors().then();
		return !validationErrors;
	}

	/**
	 * Save dialog method
	 * @note This method should be called when dialog's "save" button is clicked. This method does not actually save any
	 * data to the DB or anywhere else. That should be done by the parent component.
	 */
	async save() {
		const {
			dialogGUIID, expertReportId, expertReportName, sendExpertReportEmailAction, addSuccessMessageAction
		} = this.props;
		const emails = getArray(this.getDataToReturn(), 'emails');

		// Do the validation
		const isValid = this.validate();

		// If validation is successful
		if (isValid) {
			const saveResult = await this.executeAbortableAction(sendExpertReportEmailAction, expertReportId, emails);
			if (isset(saveResult)) {
				if (!!expertReportName) {
					addSuccessMessageAction(this.t('send_success_msg_title', '', '', {title: expertReportName}));
				} else {
					addSuccessMessageAction(this.t('send_success_msg'));
				}

				this.close();

				// Trigger component's onSave event if visit was saved successfully
				executeComponentCallback(this.props.onSave, saveResult, dialogGUIID);
			}
		}
	}

	render() {
		const {expertReportName} = this.props;
		
		return this.renderDialog(
			this.renderTitle(`${this.t('title')}${!!expertReportName ? ` "${getString(expertReportName)}"` : ''}`),
			(
				<FormWrapper className={`dialog-form ${this.getOption('domPrefix')}`}>
					<FormField
						required={true}
						label={this.t('emailsLabel')}
						labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}
						errorMessages={this.getValidationErrors('emails')}
					>
						<EmailInput
							isMulti={true}
							value={this.getValue('emails')}
							onChange={emails => this.handleValueChange('emails', emails)}
						/>
					</FormField>
				</FormWrapper>
			), {
				saveIcon: 'paper-plane-o',
				saveLabel: this.t('send'), 
			}
		);
	}
}


/**
 * Define component's own props that can be passed to it by parent components
 */
ExpertReportSendEmailDialog.propTypes = {
	expertReportId: PropTypes.string.isRequired,
	expertReportName: PropTypes.string,

	// Event triggered if expert report email was successfully sent and dialog has already been closed
	// @param {string} dialogGUIID
	onSave: PropTypes.func,
	// Event triggered when dialog close method is called
	// @note Dialog will not be closed if event callback returns false.
	// @param {string} dialogGUIID
	onClose: PropTypes.func,
};

export default connect(mapStateToProps, allActions)(ExpertReportSendEmailDialog);