/**
 * Data object representing the article groups list item
 * @note This is usually used in data table as a row. Params don't have default values since this data object is used to
 * represent data that actually exists.
 */
export class ArticleGroupsListItemDataObject {
	/**
	 * @param {string} id - List item's ID.
	 * @param {string} name - List item's name.
	 */
	constructor(id, name) {
		this.id = id;
		this.name = name;
	}
}

/**
 * Data object representing the article groups item
 * @note This is usually used when creating or updating data table item using a popup or a dialog. Params have default 
 * values since this data object can be used for creating an item. This is why 'id' has a default value of null which 
 * represents a new item that is being created.
 */
export class ArticleGroupsItemDataObject {
	/**
	 * @param {string|null} [id=null] - Item's ID.
	 * @param {string} [name=''] - Item's name.
	 */
	constructor(id = null, name = '') {
		this.id = id;
		this.name = name;
	}
}