import React from "react";
import DialogComponent, {DIALOG_TYPE} from "Core/components/DialogComponent";
import PropTypes from "prop-types";
import {getArray, getString} from "Core/helpers/data";

class InsertValueDialog extends DialogComponent {
	constructor(props) {
		super(props, {
			translationPath: 'InsertValueDialog',
			dialogType: DIALOG_TYPE.NONE,
		});

		// Action methods
		this.insert = this.insert.bind(this);
		
		// Render methods
		this.renderSections = this.renderSections.bind(this);
	}

	
	// Action methods --------------------------------------------------------------------------------------------------- 
	/**
	 * Dialog insert method
	 * @note This method is called by the child section component, and it simply triggers the 'onInsert' event and closes
	 * the dialog.
	 * @param {any} value - Value to insert.
	 */
	insert(value) {
		const {onInsert} = this.props;
		
		try {
			if (onInsert) onInsert(value);
			else console.warn('InsertValueDialog component: onInsert prop is not defined!');
		} catch (e) {
			console.error('InsertValueDialog component: Error while trying to execute onInsert callback function!');
			console.error(e);
		}

		// Close the dialog
		this.close();
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render insert sections
	 */
	renderSections() {
		/** @type {InsertValueDialogSectionDataObject[]} */
		const sections = getArray(this.getProp('sections'));

		/** @type {InsertValueDialogSectionDataObject} section */
		return sections.map(section => {
			const SectionComponent = section.component;
			return (<SectionComponent key={section.key} {...section.props} onInsert={this.insert} />);
		});
	}
	
	render() {
		const title = getString(this.props, 'title', this.t('default_title'));

		return this.renderDialog(
			this.renderTitle(title), (
				<div className={`insert-value-dialog-component`}>
					{this.renderSections()}
				</div>
		));
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
InsertValueDialog.propTypes = {
	// Unique GUI ID of the dialog
	// @note This is automatically sent by the global Dialog component.
	dialogGUIID: PropTypes.string,
	// Dialog options
	// @note This is automatically sent by the global Dialog component.
	dialogOptions: PropTypes.object,
	// Action used to close the dialog
	// @note This is automatically sent by the global Dialog component.
	dialogCloseAction: PropTypes.func,
	
	// Dialog title
	// @note If not defined default title from translation will be used.
	title: PropTypes.string,
	// Dialog section
	// @type {InsertValueDialogSectionDataObject}
	sections: PropTypes.arrayOf(PropTypes.shape({
		// Unique key used as React element key because insert value dialog can have multiple sections
		key: PropTypes.string.isRequired,
		// Insert value dialog section component
		component: PropTypes.elementType.isRequired,
		// Insert value dialog section component props
		props: PropTypes.object,
		// Function that checks if any string contain insert
		// @type {Function<string, string>}
		contains: PropTypes.func,
	})),

	// Events
	onClose: PropTypes.func,
	onInsert: PropTypes.func, // Arguments: string value to insert
};

export default InsertValueDialog;