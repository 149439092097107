import React from "react";
import PropTypes from "prop-types";
import {
	icon_font_symbol_class_prefix, icon_font_spin_class, icon_font_stack_class, icon_font_stacked_item_class
} from "../../../../config";

/**
 * Icon component
 * @note This component uses GUI config (see '/src/config/gui' file) to determine the default icon font classes.
 */
function Icon({
	symbol, spin, className, element = 'i', stackElem = 'span',
	symbolPrefix = icon_font_symbol_class_prefix, spinClassName = icon_font_spin_class, 
	stackClassName = icon_font_stack_class, stackedItemClassName = icon_font_stacked_item_class,
	stackStyle = {}, ...otherProps
}) {
	// Convert camelCase (in this case lowercase) prop name into PascalCase
	// @note This is done because react component props use camelCase (JSX attributes use camelCase) by convention, but 
	// rendered React JSX component must be capitalized (PascalCase).
	const Element = element;
	const StackElem = stackElem;
	
	return (
		Array.isArray(symbol) ?
			<StackElem className={`${stackClassName} icon-stack`} style={stackStyle}>
				{symbol.map((s, idx) =>
					<Element
						key={idx}
						className={
							`${symbolPrefix}${s} ${stackedItemClassName} stacked-${idx+1} icon` +
							(spin ? ` ${spinClassName}` : '') +
							(className ? ` ${className}` : '')
						}
						{...otherProps}
					/>
				)}
			</StackElem>
			:
			<Element
				className={
					`${symbolPrefix}${symbol} icon` +
					(spin ? ` ${spinClassName}` : '') +
					(className ? ` ${className}` : '')
				}
				{...otherProps}
			/>
	);
}

/**
 * Define component's own props that can be passed to it by parent components
 */
Icon.propTypes = {
	// Font icon symbol name
	// @note If array, icons will be stacked.
	symbol: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
	// Flag that determines if icon should spin (if supported by the icon font used)
	spin: PropTypes.bool,
	// Icon element CSS class
	className: PropTypes.string,
	// Name of the HTML element (like 'i', 'span', ...) used to render the icon.
	element: PropTypes.string,
	// Name of the HTML element (like 'i', 'span', ...) used to render the icon stack.
	// @note Icons are stacked if 'symbol' prop is an array.
	stackElem: PropTypes.string,
	// Font icon symbol prefix
	symbolPrefix: PropTypes.string,
	// Font icon class used for spinning the icon (if supported by the icon font used)
	spinClassName: PropTypes.string,
	// Font icon class used for stacking icons
	stackClassName: PropTypes.string,
	// Font icon class used to define the stacked icon
	stackedItemClassName: PropTypes.string,
	// Style object for stack wrapper element
	stackStyle: PropTypes.object,
};

export default Icon;