export const TEXT_INPUT_DATE_PREVIEW_FORMATS = {
	DATE: [
		'dd.MM.yyyy.',
		'do MMMM yyyy.',
		'EEEE, do MMMM yyyy.',
		'MM/dd/yyyy',
		'yyyy-MM-dd',
	],

	DATETIME: [
		'dd.MM.yyyy. H:mm:ss',
		'do MMMM yyyy. H:mm',
		'EEEE, do MMMM yyyy. H:mm',
		'MM/dd/yyyy H:mm:ss',
		'yyyy-MM-dd HH:mm:ss',
		'yyyy-MM-dd HH:mm',
		"yyyy-MM-dd'T'HH:mm:ss",
		"yyyy-MM-dd'T'HH:mm",
	],

	TIME: [
		'HH:mm:ss',
		'HH:mm',
		'h:mm:ss a',
		'h:mm a',
	],	
}