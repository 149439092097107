import {get, omit} from "lodash";
import {redux_storage_var} from "Config/app";

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = redux_storage_var;

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: `@${redux_storage_var}/reset`,
	SET_ITEM: `@${redux_storage_var}/set_item`,
	REMOVE_ITEM: `@${redux_storage_var}/remove_item`,
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),
	setItem: (keyName, keyValue) => ({type: REDUCER_TYPES.SET_ITEM, keyName, keyValue}),
	removeItem: keyName => ({type: REDUCER_TYPES.REMOVE_ITEM, keyName})
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET: return {...initialState};
		case REDUCER_TYPES.SET_ITEM: return {...state, [action.keyName]: action.keyValue};
		case REDUCER_TYPES.REMOVE_ITEM: return omit(state, action.keyName);
		default: return state;
	}
};

// Selectors
export const selectors = {
	getItem: (state, key) => get(state, [reducerStoreKey, key]),
};

export default reducer;