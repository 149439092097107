import {each} from "lodash";

/**
 * Check if any DateRangePicker input element (year, month, day, ...) is invalid
 *
 * @param {HTMLElement} dateRangePickerElement - Date range picker input element.
 * @param {boolean} [includeEmpty=false] - If true, empty input elements will be considered invalid.
 * @return {boolean}
 */
export const isAnyInputInvalid = (dateRangePickerElement, includeEmpty) => {
	let result = false;
	each(dateRangePickerElement.querySelectorAll(".react-daterange-picker__inputGroup input:not([name=''])"), 
		input => {
			if (!input.checkValidity() || (includeEmpty && input.value === '')) {
				result = true;
				return false;
			}
		}
	);
	return result;
};

/**
 * Get the list of all invalid DateRangePicker input elements (year, month, day, ...)
 *
 * @param {HTMLElement} dateRangePickerElement - Date range picker input element.
 * @param {boolean} [includeEmpty=false] - If true, empty input elements will be considered invalid.
 * @return {HTMLElement[]}
 */
export const getInvalidInputs = (dateRangePickerElement, includeEmpty = false) => {
	let result = [];
	each(dateRangePickerElement.querySelectorAll(".react-daterange-picker__inputGroup input:not([name=''])"), 
		input => { if (!input.checkValidity() || (includeEmpty && input.value === '')) result.push(input); }
	);
	return result;
}