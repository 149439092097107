import {cloneDeep, find, get} from "lodash";
import {getArray, getString, isset} from "Core/helpers/data";
import {StatisticsFilterDataObject} from "Components/advanced/Statistics/dataObjects";

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'officeLocationStatistics';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@officeLocationStatistics/reset',
	SET_DATA: '@officeLocationStatistics/set_data',
	CLEAR_DATA: '@officeLocationStatistics/clear_data',
	SET_FILTER: '@officeLocationStatistics/set_filter',
	CLEAR_FILTER: '@officeLocationStatistics/clear_filter',
	SET_SCOPE: '@officeLocationStatistics/set_scope',
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),

	/**
	 * @param {StatisticsDataObject} data
	 * @return {{data: StatisticsDataObject, type: string}}
	 */
	setData: data => ({type: REDUCER_TYPES.SET_DATA, data}),
	/**
	 * @param {StatisticsType|''} [statisticsType=''] - Type of statistics to clear. If not specified or empty, all 
	 * statistics data will be cleared.
	 * @return {{type: string}}
	 */
	clearData: (statisticsType = '') => ({type: REDUCER_TYPES.CLEAR_DATA, statisticsType}),

	/**
	 * @param {?StatisticsFilterDataObject} filter
	 * @return {{filter: ?StatisticsFilterDataObject, type: string}}
	 */
	setFilter: filter => ({type: REDUCER_TYPES.SET_FILTER, filter}),
	clearFilter: () => ({type: REDUCER_TYPES.CLEAR_FILTER}),

	/**
	 * @param {?OfficeLocationStatisticsScope|''} scope
	 * @return {{type: string, scope: OfficeLocationStatisticsScope}}
	 */
	setScope: scope => ({type: REDUCER_TYPES.SET_SCOPE, scope}),
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	/** @type {?StatisticsDataObject[]|undefined} */
	dataList: null,
	
	/** @type {StatisticsFilterDataObject} */
	filter: new StatisticsFilterDataObject(),
	
	/** @type {?OfficeLocationStatisticsScope|''} */
	scope: '',
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET: return { ...initialState };
		
		case REDUCER_TYPES.SET_DATA: {
			const dataList = getArray(state, 'dataList');
			const type = getString(action, 'data.type');
			
			if (!type) return state;
			
			return (
				isset(find(dataList, {type})) ?
					{...state, dataList: state.dataList.map(i => i.type === type ? cloneDeep(action.data) : i)} :
					{...state, dataList: (dataList.length > 0 ? [...state.dataList, action.data] : [action.data])}
			);
		}
		case REDUCER_TYPES.CLEAR_DATA:
			const dataList = getArray(state, 'dataList');
			const type = getString(action, 'statisticsType');
			
			// Set data list to initial value if type is empty
			if (type === '') return {...state, dataList: cloneDeep(initialState.dataList)};
			
			// Do not change anything if data list is currently empyt since there is no statistics type to clear
			if (dataList.length === 0) return state;

			// Remove a specific statistics type from the data list
			return {...state, dataList: state.dataList.filter(i => i.type !== action.statisticsType)};
			
		case REDUCER_TYPES.SET_FILTER: return {...state, filter: action.filter};
		case REDUCER_TYPES.CLEAR_FILTER: return {...state, filter: cloneDeep(initialState.filter)};
		
		case REDUCER_TYPES.SET_SCOPE: return {...state, scope: action.scope};

		default: return state;
	}
};

// Selectors
export const selectors = {
	getOfficeLocationStatisticsList: state => get(state, [reducerStoreKey, 'dataList']),
	getOfficeLocationStatistics: (type, state) => find(getArray(state, [reducerStoreKey, 'dataList']), {type}),
	getOfficeLocationStatisticsListFilter: state => get(state, [reducerStoreKey, 'filter'], null),
	getOfficeLocationStatisticsListScope: state => getString(state, [reducerStoreKey, 'scope']),
};

export default reducer;