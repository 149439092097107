import {app_password_reset_request_page_router_path} from "Config/app";

/**
 * Page router path relative to the base path of the app
 * @note Use '/' at the beginning (for example '/home').
 * @type {string}
 */
export const routerPath = app_password_reset_request_page_router_path;

/**
 * Page translation path
 * @type {string}
 */
export const translationPath = 'PasswordResetRequest';