import styles from '../index.module.css';

import React from "react";
import {components} from "react-select";
import {get} from "lodash";
import DateLabel from 'Core/components/display/DateLabel';
import Label from 'Core/components/display/Label';
import {isset} from 'Core/helpers/data';

/**
 * Select single value replacement component for patient therapy
 *
 * @param {Object} - Single value props sent by the react-select component to all the single values.
 * @return {JSX.Element}
 * @constructor
 */
const PatientTherapySingleValue = ({children, ...props}) => {
	/** @type {PatientTherapySelectOptionDataObject} */
	const data = get(props, 'data');
	
	return (
		<components.SingleValue {...props}>
			<div className={styles['item']}>
				<small>
					<DateLabel inputDate={data.therapyDate} /> - <Label content={data.sentByPhysician} />
				</small>
				<div>
					[<Label content={`${data.medicalRecordNumber} - ${data.medicalRecordCategory}`} />] - <Label
					content={
						isset(get(data, 'diagnosis')) ?
							`${data.diagnosisCode ? `${data.diagnosisCode} ` : ''} ${data.diagnosis}`
							: '—'
					}
				/>
				</div>
			</div>
		</components.SingleValue>
	)
};

export default PatientTherapySingleValue;