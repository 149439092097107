import {cloneDeep, get} from 'lodash';
import {getString} from 'Core/helpers/data';
import {PatientSelectOptionDataObject} from 'Components/input/PatientSelectInput/dataObjects';
import {getDate, STANDARD_DATE_TIME_FORMAT} from 'Core/helpers/datetime';

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {PatientSelectOptionDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new PatientSelectOptionDataObject(
		getString(result, 'id'),
		getString(result, 'firstName'),
		getString(result, 'middleName'),
		getString(result, 'lastName'),
		getString(result, 'address'),
		getString(result, 'telephone'),
		getString(result, 'mobilePhone'),
		getString(result, 'email'),
		getDate(get(result, 'birthDate'), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		getString(result, 'recommendationId'),
		getString(result, 'recommendationName'),
		getString(result, 'anamnesis'),
	);

	return result;
}

/**
 * Get patient select option data object from raw patient create response data
 * @param {Object} rawData - Raw patient create response data received from IO.
 * @return {PatientSelectOptionDataObject}
 */
export const inputFromCreate = rawData => {
	let result = cloneDeep(rawData);

	result = new PatientSelectOptionDataObject(
		getString(result, 'uniqueID'),
		getString(result, 'firstName'),
		getString(result, 'middleName'),
		getString(result, 'lastName'),
		getString(result, 'address'),
		getString(result, 'telephone'),
		getString(result, 'mobilePhone'),
		getString(result, 'email'),
		getDate(get(result, 'birthDate'), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		getString(result, 'recommendationId'),
		getString(result, 'recommendationName'),
		getString(result, 'anamnesis'),
	);

	return result;
};

// @note There is no output function since list is read only.