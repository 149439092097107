import {AclCheckDataObject} from "Core/acl";

/**
 * Section unique name
 * @type {string}
 */
export const name = 'APPS';

/**
 * Access control settings
 * @type {AclCheckDataObject}
 */
export const access = new AclCheckDataObject();

/**
 * Page router path relative to the base path of the app
 * @note Use '/' at the beginning (for example '/home').
 * @type {string}
 */
export const routerPath = '/medical-office';
/**
 * Page router options used for router matching in main sidebar menu
 * @type {{[exact]: boolean, [strict]: boolean, [sensitive]: boolean}}
 */
export const menuRouterOptions = {};

/**
 * Page translation path
 * @type {string}
 */
export const translationPath = 'AppsSection';