export class AclDataObject {
	/**
	 * @param {string[]} [permissions=[]] - User permissions.
	 * @param {string} [userType=''] - User type.
	 * @param {boolean} [isGuest=false] - Is user a guest user.
	 */
	constructor(permissions = [], userType = '', isGuest = false) {
		this.permissions = permissions;
		this.userType = userType;
		this.isGuest = isGuest;
	}
}

export class AclCheckDataObject {
	/**
	 * @param {string[]} [permissions=[]] - List of permissions required for access. Checking will be done using the
	 * 'acl_check_mode' acl config option.
	 * @param {string[]} [userTypes=[]] - List of user types that should have access.
	 * @param {boolean} [forGuest] - Flag that specifies if guest user should have access. If not defined, checking for
	 * guest will be skipped.
	 */
	constructor(permissions = [], userTypes = [], forGuest) {
		this.permissions = permissions;
		this.userTypes = userTypes;
		this.forGuest = forGuest;
	}
}