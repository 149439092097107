/** @typedef {string} AsyncSelectInputToolbarPosition */
export const ASYNC_SELECT_INPUT_TOOLBAR_POSITION_LEFT = 'left';
export const ASYNC_SELECT_INPUT_TOOLBAR_POSITION_RIGHT = 'right';
/** @enum {AsyncSelectInputToolbarPosition} */
export const ASYNC_SELECT_INPUT_TOOLBAR_POSITION = {
	LEFT: ASYNC_SELECT_INPUT_TOOLBAR_POSITION_LEFT,
	RIGHT: ASYNC_SELECT_INPUT_TOOLBAR_POSITION_RIGHT
};
/** @type {AsyncSelectInputToolbarPosition[]} */
export const ASYNC_SELECT_INPUT_TOOLBAR_POSITIONS = [
	ASYNC_SELECT_INPUT_TOOLBAR_POSITION_LEFT, 
	ASYNC_SELECT_INPUT_TOOLBAR_POSITION_RIGHT
];