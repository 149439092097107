import 'react-tippy/dist/tippy.css'
import styles from "./index.module.css";

import React from "react";
import PropTypes from "prop-types";
import {get, omit} from "lodash";
import Icon from "../Icon";
import {LABEL_ICON_POSITION, LABEL_ICON_POSITIONS} from "./const";
import {icon_font_help_circle_symbol, icon_font_stack_class, icon_font_symbol_class_prefix} from "../../../../config";
import {Tooltip} from "react-tippy";
import Html from "../Html";
import {waitingFunction} from "../../../helpers/function";

function Label({
	element = '', elementProps = {}, content = '', prefix = '', suffix = '', 
	iconSymbolPrefix = icon_font_symbol_class_prefix, icon = '', help = '', iconPosition = LABEL_ICON_POSITION.LEFT, 
	iconSpin = false, iconClassName = '', iconStackClass = '', tooltip = '', tooltipOptions = {}, 
	supportHtml = false
}) 
{
	// Convert camelCase (in this case lowercase) prop name into PascalCase
	// @note This is done because react component props use camelCase (JSX attributes use camelCase) by convention, but 
	// rendered React JSX component must be capitalized (PascalCase).
	const Element = element;
	
	// Get Icon component CSS style based on context
	let iconStyle = {};
	if (icon && content) {
		if (iconPosition === LABEL_ICON_POSITION.LEFT) iconStyle.marginRight = '0.5em'; 
		else if (iconPosition === LABEL_ICON_POSITION.RIGHT) iconStyle.marginLeft = '0.5em';
		else iconStyle.marginLeft = '';
	}
	
	// Check icon position
	const lIcon = ((iconPosition === LABEL_ICON_POSITION.LEFT || iconPosition === LABEL_ICON_POSITION.NONE) && icon);
	const rIcon = (iconPosition === LABEL_ICON_POSITION.RIGHT && icon);
	
	// Get tooltip CSS classes
	let tooltipClassName = (get(tooltipOptions, 'className') ? get(tooltipOptions, 'className') : '');
	tooltipClassName = `${tooltipClassName} ${styles['tooltip']} ${help ? `with-help ${styles['help']}` : ''}`;
	
	const renderLabelContent = () => (
		<>
			{prefix ? <span className="label-prefix">{prefix}</span> : null}
			{lIcon ? 
				<Icon 
					symbolPrefix={iconSymbolPrefix} 
					symbol={icon} 
					spin={iconSpin} 
					style={iconStackClass ? {} : iconStyle}
					className={`label-icon left ${iconClassName}`} 
					stackClassName={`${icon_font_stack_class} ${iconStackClass}`}
					stackStyle={iconStackClass ? iconStyle : {}}
				/> 
				: null
			}
			{supportHtml ?
				<>
					<Html 
						className={`label-content-html ${styles['label']}`} 
						content={(content ? content : '')}
						element="span" 
					/>{
						help ?
							<>
								&nbsp;
								<Icon 
									symbol={icon_font_help_circle_symbol} 
									className={`label-help-icon ${styles['helpIcon']}`} 
								/>
							</>
							: null
					}
				</>
				:
				(
					content ?
						<>
							{content}{help ? 
								<>
									&nbsp;
									<Icon 
										symbol={icon_font_help_circle_symbol}
										className={`label-help-icon ${styles['helpIcon']}`}
									/>
								</>
								: null
							}
						</>
					: null
				)
			}
			{rIcon ? 
				<Icon
					symbolPrefix={iconSymbolPrefix}	
					symbol={icon} 
					spin={iconSpin} 
					style={iconStyle} 
					className={`label-icon right ${iconClassName}`}
				/> 
				: null
			}
			{suffix ? <span className="label-suffix">{suffix}</span> : null}
		</>
	);
	
	return (
		Element ?
			help || tooltip ?
				<Tooltip 
					tag="span" title={help ? help : tooltip} size="small" position="top-center" arrow={true}
					className={tooltipClassName} interactive={false} {...omit(tooltipOptions, ['appendTo', 'ref'])}
					ref={node => {
						// Execute ref function if specified in 'tooltipOptions'
						const ref = get(tooltipOptions, 'ref');
						if (typeof ref === 'function') ref(node);
						
						// Add support for 'appendTo' element selector
						// @note This is added mainly to fix issues when tooltip is rendered in body element but element that 
						// triggered the tooltip is in a parent that has overflow auto or scroll. This will render the tooltip
						// that can go out of viewport bounds if located near the edges of the viewport.
						const appendTo = get(tooltipOptions, 'appendTo');
						if (appendTo) {
							// Wait 60s for append element to be rendered
							waitingFunction(() => {
								const appendToElement = document.querySelector(appendTo);
								if (appendToElement && node && node.tippy) {
									node.tippy.settings.appendTo = appendToElement;
									return true;
								}
							}, 1, 60000).then();
						}
					}}
				>
					<Element {...elementProps}>
						{renderLabelContent()}
					</Element>
				</Tooltip>
				:
				<Element {...elementProps}>{renderLabelContent()}</Element>
			:
			<>
				{help || tooltip ?
					<Tooltip 
						tag="span" title={help ? help : tooltip} size="small" position="top-center" arrow={true} 
						className={tooltipClassName} interactive={false} {...omit(tooltipOptions, ['appendTo', 'ref'])}
						ref={node => {
							// Execute ref function if specified in 'tooltipOptions'
							const ref = get(tooltipOptions, 'ref');
							if (typeof ref === 'function') ref(node);

							// Add support for 'appendTo' element selector
							// @note This is added mainly to fix issues when tooltip is rendered in body element but element 
							// that triggered the tooltip is in a parent that has overflow auto or scroll. This will render the
							// tooltip that can go out of viewport bounds if located near the edges of the viewport.
							const appendTo = get(tooltipOptions, 'appendTo');
							if (appendTo) {
								// Wait 60s for append element to be rendered
								waitingFunction(() => {
									const appendToElement = document.querySelector(appendTo);
									if (appendToElement && node && node.tippy) {
										node.tippy.settings.appendTo = appendToElement;
										return true;
									}
								}, 1, 60000).then();
							}
						}}
					>
						{renderLabelContent()}
					</Tooltip>
					:
					renderLabelContent()
				}
			</>
	);
}

/**
 * Define component's own props that can be passed to it by parent components
 */
Label.propTypes = {
	// Name of the wrapper HTML element (like 'i', 'span', ...) used to render the label
	// @note If not specified, wrapper will not be rendered.
	element: PropTypes.string,
	// Element attributes of the wrapper HTML element
	// @note If 'element' prop is not set, this value will be ignored. 
	elementProps: PropTypes.object,
	// Label content
	content: PropTypes.any,
	// Label content prefix
	// @note Usually used to add some dynamic value like ordinal or id. 
	prefix: PropTypes.any,
	// Label content suffix
	// @note Usually used to add some flags symbol like '*' for changes.
	suffix: PropTypes.any,
	// Font icon symbol prefix
	iconSymbolPrefix: PropTypes.string,
	// Icon symbol name
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	// Icon position
	iconPosition: PropTypes.oneOf(LABEL_ICON_POSITIONS),
	// If true, icon should spin.
	// @note Please note that this will work only if font icon set supports it, and it's properly configured.
	iconSpin: PropTypes.bool,
	// Additional icon component's class name
	iconClassName: PropTypes.string,
	// Additional icon component's stack class name
	iconStackClass: PropTypes.string,
	// Tooltip text for the label
	tooltip: PropTypes.string,
	// Tooltip options
	tooltipOptions: PropTypes.object,
	// If set, this value will be used instead of 'tooltip' prop to render the tooltip and a help icon wll be rendered
	// next to the label
	help: PropTypes.string,
	// Set to true to support HTML in 'content' prop.
	// @warning Be careful when using this flag because it can cause security issues. It uses 'dangerouslySetInnerHTML' 
	// to allow HTML content.
	supportHtml: PropTypes.bool
};

export default Label;
export * from "./const";