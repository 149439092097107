import {cloneDeep} from "lodash";
import {getString, resetObjectValues} from "Core/helpers/data";
import {PhysicianDataObject} from 'DataObjects/physician';

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {PhysicianDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new PhysicianDataObject(
		getString(result, 'uniqueID'),
		getString(result, 'title'),
		getString(result, 'firstName'),
		getString(result,	'lastName'),
		getString(result,	'telephone'),
		getString(result,	'mobilePhone'),
		getString(result,	'email'),
		getString(result,	'address'),
		getString(result,	'institution')
	);

	return result;
}

/**
 * Get output data from input data
 * @param {PhysicianDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep(inputData);

	result = {
		uniqueID: result.id,
		title: result.title,
		firstName: result.firstName,
		lastName: result.lastName,
		telephone: result.telephone,
		mobilePhone: result.mobilePhone,
		email: result.email,
		address: result.address,
		institution: result.institution
	};

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}