import {cloneDeep, get} from "lodash";
import {getString} from "Core/helpers/data";
import {AppointmentsListItemDataObject} from "DataObjects/appointment";
import {getDate, getTimeString, STANDARD_DATE_TIME_FORMAT} from "Core/helpers/datetime";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {AppointmentsListItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new AppointmentsListItemDataObject(
		getString(result, 'id'),
		getDate(get(result, 'appointmentDate'), STANDARD_DATE_TIME_FORMAT.ISO_DATE),
		getTimeString(getString(result, 'timeFrom'), 2),
		getTimeString(getString(result, 'timeTo'), 2),
		getString(result, 'patientId'),
		getString(result, 'patientFirstName'),
		getString(result, 'patientLastName'),
		getString(result, 'officeLocationName'),
		getString(result, 'officeLocationId'),
		getString(result, 'therapistLastName') + (
			!!getString(result, 'therapistFirstName') ? ` ${getString(result, 'therapistFirstName')}` : ''
		),
		getString(result, 'therapistId'),
		getString(result,	'therapyId', null, true),
	);

	return result;
}

// @note There is no output function since list is read only.