import {STATISTICS_CHART_TYPE, STATISTICS_TYPE} from "Components/advanced/Statistics/const";

/** @typedef {string} OfficeLocationStatisticsScope */
export const OFFICE_LOCATION_STATISTICS_SCOPE_DAILY = 'DAILY';
export const OFFICE_LOCATION_STATISTICS_SCOPE_MONTHLY = 'MONTHLY';
export const OFFICE_LOCATION_STATISTICS_SCOPE_YEARLY = 'YEARLY';
export const OFFICE_LOCATION_STATISTICS_SCOPE_LOCATIONS = 'LOCATIONS';
/** @enum {OfficeLocationStatisticsScope} */
export const OFFICE_LOCATION_STATISTICS_SCOPE = {
	DAILY: OFFICE_LOCATION_STATISTICS_SCOPE_DAILY,
	MONTHLY: OFFICE_LOCATION_STATISTICS_SCOPE_MONTHLY,
	YEARLY: OFFICE_LOCATION_STATISTICS_SCOPE_YEARLY,
	LOCATIONS: OFFICE_LOCATION_STATISTICS_SCOPE_LOCATIONS,
}
/** @type {OfficeLocationStatisticsScope[]} */
export const OFFICE_LOCATION_STATISTICS_SCOPES = [
	OFFICE_LOCATION_STATISTICS_SCOPE_DAILY,
	OFFICE_LOCATION_STATISTICS_SCOPE_MONTHLY,
	OFFICE_LOCATION_STATISTICS_SCOPE_YEARLY,
	OFFICE_LOCATION_STATISTICS_SCOPE_LOCATIONS,
];

/**
 * Map of office location statistics types for each scope
 * @type {Object<OfficeLocationStatisticsScope, StatisticsType[]>}
 */
export const OFFICE_LOCATION_STATISTICS_TYPES_BY_SCOPE = {
	[OFFICE_LOCATION_STATISTICS_SCOPE.DAILY]: [
		STATISTICS_TYPE.OFFICE_LOCATIONS_DAILY_VISITS,
		STATISTICS_TYPE.OFFICE_LOCATIONS_DAILY_PAYMENTS,
	],
	[OFFICE_LOCATION_STATISTICS_SCOPE.MONTHLY]: [
		STATISTICS_TYPE.OFFICE_LOCATIONS_MONTHLY_VISITS,
		STATISTICS_TYPE.OFFICE_LOCATIONS_MONTHLY_PAYMENTS,
	],
	[OFFICE_LOCATION_STATISTICS_SCOPE.YEARLY]: [
		STATISTICS_TYPE.OFFICE_LOCATIONS_YEARLY_VISITS,
		STATISTICS_TYPE.OFFICE_LOCATIONS_YEARLY_PAYMENTS,
	],
	[OFFICE_LOCATION_STATISTICS_SCOPE.LOCATIONS]: [
		STATISTICS_TYPE.OFFICE_LOCATIONS_LOCATIONS_PATIENTS,
		STATISTICS_TYPE.OFFICE_LOCATIONS_LOCATIONS_PAYMENTS,
	],
};

/**
 * Map of office location statistics chart types for each statistics type
 * @type {Object<StatisticsType, StatisticsChartType>}
 */
export const OFFICE_LOCATION_CHART_TYPE_BY_STATISTICS_TYPE = {
	[STATISTICS_TYPE.OFFICE_LOCATIONS_DAILY_VISITS]: STATISTICS_CHART_TYPE.LINE,
	[STATISTICS_TYPE.OFFICE_LOCATIONS_DAILY_PAYMENTS]: STATISTICS_CHART_TYPE.LINE,
	[STATISTICS_TYPE.OFFICE_LOCATIONS_MONTHLY_VISITS]: STATISTICS_CHART_TYPE.LINE,
	[STATISTICS_TYPE.OFFICE_LOCATIONS_MONTHLY_PAYMENTS]: STATISTICS_CHART_TYPE.LINE,
	[STATISTICS_TYPE.OFFICE_LOCATIONS_YEARLY_VISITS]: STATISTICS_CHART_TYPE.LINE,
	[STATISTICS_TYPE.OFFICE_LOCATIONS_YEARLY_PAYMENTS]: STATISTICS_CHART_TYPE.LINE,
	[STATISTICS_TYPE.OFFICE_LOCATIONS_LOCATIONS_PATIENTS]: STATISTICS_CHART_TYPE.PIE,
	[STATISTICS_TYPE.OFFICE_LOCATIONS_LOCATIONS_PAYMENTS]: STATISTICS_CHART_TYPE.PIE,
};