import {cloneDeep, get} from 'lodash';
import {getNumber, getString, resetObjectValues} from 'Core/helpers/data';
import {getDate, getDateString, STANDARD_DATE_TIME_FORMAT} from 'Core/helpers/datetime';
import {PaymentDataObject} from "DataObjects/payment";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {PaymentDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new PaymentDataObject(
		getString(result, 'uniqueID', null),
		getString(result, 'patientId', null),
		getString(result, 'therapyId', null),
		getDate(get(result, 'paymentDate'), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		getNumber(result, 'appointmentsNumber', 1),
		getNumber(result, 'amount', null),
		getString(result, 'note'),
		getString(result, 'officeLocationId', null),
	);

	return result;
}

/**
 * Get output data from input data
 * 
 * @param {PaymentDataObject} inputData - Input data.
 * @param {boolean} [refund] - Flag that specifies if output should be mapped for refund.
 * @return {Object}
 */
export const output = (inputData, refund) => {
	let result = cloneDeep({
		uniqueID: inputData.id,
		appointmentsNumber: (
			refund ? -Math.abs(getNumber(inputData, 'appointmentsNumber')) : getNumber(inputData, 'appointmentsNumber')
		),
		paymentDate: getDateString(inputData.date, STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		amount: (refund ? -Math.abs(getNumber(inputData, 'amount')) : getNumber(inputData, 'amount')),
		note: inputData.note,
		officeLocationId: inputData.officeLocationId,
		therapyId: inputData.therapyId,
		customerId: inputData.patientId,
	});

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}