import {auth_login_type} from "../../config";
import {LOGIN_TYPE} from "../io/const";

/**
 * Check if app uses login
 * @return {boolean}
 */
export const appHasLogin = () => auth_login_type !== LOGIN_TYPE.NONE;

/**
 * Check if login type uses redirects
 * @return {boolean}
 */
export const isLoginWithRedirect = () => auth_login_type === LOGIN_TYPE.OAUTH2_REDIRECT;

/**
 * Check if login type does not use redirects
 * @return {boolean}
 */
export const isLoginWithoutRedirect = () => auth_login_type === LOGIN_TYPE.OAUTH2_NO_REDIRECT;