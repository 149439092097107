import {get, find, cloneDeep, reject} from "lodash";

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'floatPopup';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@floatPopup/reset',
	ADD_FLOAT_POPUP: '@floatPopup/add',
	REMOVE_FLOAT_POPUP: '@floatPopup/remove',
	CLEAR_FLOAT_POPUP: '@floatPopup/clear',
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),
	openFloatPopup: (GUIID, component, props, options) => ({
		type: REDUCER_TYPES.ADD_FLOAT_POPUP, GUIID, component, props, options
	}),
	closeFloatPopup: GUIID => ({type: REDUCER_TYPES.REMOVE_FLOAT_POPUP, GUIID}),
	closeAllFloatPopups: () => ({type: REDUCER_TYPES.CLEAR_FLOAT_POPUP}),
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	openedFloatPopups: []
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET:
			return {...initialState};
			
		case REDUCER_TYPES.ADD_FLOAT_POPUP:
			// Do not add floating popup if it is already opened
			if (find(state.openedFloatPopups, { GUIID: action.GUIID })) return state;
			
			let updatedState = cloneDeep(state);
			updatedState.openedFloatPopups.push({
				GUIID: action.GUIID,
				component: action.component,
				props: action.props,
				options: action.options,
			});
			
			return updatedState;
			
		case REDUCER_TYPES.REMOVE_FLOAT_POPUP:
			return {
				...state,
				openedFloatPopups: reject(cloneDeep(state.openedFloatPopups), { GUIID: action.GUIID })
			};
			
		case REDUCER_TYPES.CLEAR_FLOAT_POPUP:
			return {
				...state,
				openedFloatPopups: initialState.openedFloatPopups
			}

		default:
			return state;
	}
};

// Selectors
export const selectors = {
	getFloatPopups: state => get(state, [reducerStoreKey, 'openedFloatPopups'], initialState.openedFloatPopups),
	getFloatPopup: (state, GUIID) => find(state[reducerStoreKey].openedFloatPopups, { GUIID })
};

export default reducer;