import {actionCreators} from "./reducer";
import {v4} from "uuid";

/**
 * Open dialog action
 * 
 * @param {string} [GUIID] - Dialog's unique GUI ID. If not specified, unique GUI ID will be generated and used.
 * @param {React.Component} component - Component to render inside the opened dialog.
 * @param {object} props - Props to send to the component rendered inside the opened dialog.
 * @param {{
 * 	id: string, className: string, closeOnEscape: boolean, closeOnClickOutside: boolean, hideCloseBtn: boolean
 *	}} [options={}] - Opened dialog options.
 *	@return Dialog's unique GUI ID.
 */
export const openDialogAction = (GUIID, component, props, options = {}) => dispatch => {
	if (!GUIID) GUIID = v4();
	dispatch(actionCreators.openDialog(GUIID, component, props, options));
	document.activeElement.blur();
	return GUIID;
};

/**
 * Close opened dialog
 *
 * @param {string} GUIID - Dialog unique GUIID.
 */
export const closeDialogAction = GUIID => dispatch => { dispatch(actionCreators.closeDialog(GUIID)) };

/**
 * Close all opened dialogs
 */
export const closeAllDialogsAction = () => dispatch => { dispatch(actionCreators.closeAllDialogs()) };