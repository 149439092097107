import {isset} from "Core/helpers/data";

export class CalendarItemDataObject {
	/**
	 * @link https://fullcalendar.io/docs/event-object
	 * 
	 * @param {AppointmentPatientType} patientType - Type of the patient.
	 * @param {boolean} [loading] - Loading flag used to display the loading spinner.
	 * @param {'auto'|'block'|'list-item'|'background'|'inverse-background'|'none'} [display='block'] - Controls which 
	 * preset rendering style events use.
	 * @param {string} [id] - A unique identifier of an event. Useful for getEventById.
	 * @param {boolean} [allDay] - Determines if the event is shown in the “all-day” section of relevant views. In 
	 * addition, if true the time text is not displayed with the event.
	 * @param {Date} [start] - Date object that obeys the current timeZone. When an event begins.
	 * @param {?Date} [end] - Date object that obeys the current timeZone. When an event ends. It could be null if an end
	 * wasn't specified. This value is exclusive. For example, an event with the end of 2018-09-03 will appear to span 
	 * through 2018-09-02 but end before the start of 2018-09-03.
	 * @param {string} [title] - The text that will appear on an event.
	 * @param {string} [url] - A URL that will be visited when this event is clicked by the user. For more information on
	 * controlling this behavior
	 * @param {?boolean} [editable] - The value overriding the editable setting for this specific event. Determines 
	 * whether the event on the calendar can be modified.
	 * @param {?boolean} [startEditable] - The value overriding the eventStartEditable setting for this specific event.
	 * @param {?boolean} [durationEditable] - The value overriding the eventDurationEditable setting for this specific 
	 * event.
	 * @param {boolean} [overlap=true] - The value overriding the eventOverlap setting for this specific event. If false, 
	 * prevents this event from being dragged/resized over other events. Also prevents other events from being 
	 * dragged/resized over this event. Does not accept a function.
	 * @param {string} [backgroundColor] - Sets the background color for the event on the calendar (#f00, #ff0000, ...).
	 * @param {string} [borderColor] - Sets the border color for the event on the calendar (#f00, #ff0000, ...).
	 * @param {string} [textColor] - Sets the text color for the event on the calendar (#f00, #ff0000, ...).
	 * @param {string[]} [classNames] - An array of strings like ['myclass1', 'myclass2']. Determines which HTML 
	 * classNames will be attached to the rendered event.
	 * @param {string} [description] - Some event description.
	 */
	constructor({
		patientType, loading = false, display = 'block', id, allDay, start, end, title, url, editable, startEditable, 
		durationEditable, overlap = true, backgroundColor, borderColor, textColor, classNames, description, 
	}) {
		this.patientType = patientType;
		this.loading = loading;
		this.display = display;
		
		if (isset(id)) this.id = id;
		if (isset(allDay)) this.allDay = allDay;
		if (isset(start)) this.start = start;
		if (isset(end)) this.end = end;
		if (isset(title)) this.title = title;
		if (isset(url)) this.url = url;
		if (isset(editable)) this.editable = editable;
		if (isset(startEditable)) this.startEditable = startEditable;
		if (isset(durationEditable)) this.durationEditable = durationEditable;
		if (isset(overlap)) this.overlap = overlap;
		if (isset(backgroundColor)) this.backgroundColor = backgroundColor;
		if (isset(borderColor)) this.borderColor = borderColor;
		if (isset(textColor)) this.textColor = textColor;
		if (isset(classNames)) this.classNames = classNames;
		if (isset(description)) this.description = description;
	}
}