import {set} from 'lodash';
import {AUTH_TOKEN_LOCATION} from './const';
import {
	add_access_token_to, access_token_body_field_name, access_token_header_field_name, access_token_header_value_prefix, 
	refreshing_tokens_storage_field_name, refreshing_tokens_wait_interval
} from "Config/auth";
import {getBoolFromTinyInt} from "Core/helpers/data";
import {getStorageValue, STORAGE_TYPE} from "Core/storage";

/**
 * Add auth token to request headers
 *
 * @param {string} token - Auth token to add to headers.
 * @param {Headers} headers - Headers to add the token to.
 */
export const addAuthTokenToHeaders = (token, headers) => {
	headers.set(access_token_header_field_name, access_token_header_value_prefix + token);
};

/**
 * Add auth token to standard request
 * @note Token will be added to the appropriate location based on app config (/src/config/app.js file). Headers might be
 * modified by this function.
 *
 * @param {string} token - Auth token to add to headers.
 * @param {Headers} headers - This argument will be used if token is being added to request headers.
 * @param {string} body - This argument will be used if token is added to request body.
 * @return {string} Updated request body. Body is returned because it is a string and returning it is the only way for
 * caller to get the updated value.
 */
export const addAuthTokenStandard = (token, headers, body) => {
	if (add_access_token_to === AUTH_TOKEN_LOCATION.HEADER) {
		headers.set(access_token_header_field_name, access_token_header_value_prefix + token);
	} else if (add_access_token_to === AUTH_TOKEN_LOCATION.BODY) {
		body = body.trim();
		if (body.length > 0) body += `&${access_token_body_field_name}=${token}`;
		else body = `${access_token_body_field_name}=${token}`;
	}
	
	// Returns updated request body. Body is returned because it is a string and returning it is the only way for caller
	// to get the updated value.
	return body;
};

/**
 * Add auth token to JSON request
 * @note Token will be added to the appropriate location based on app config (/src/config/app.js file). Headers might be
 * modified by this function.
 *
 * @param {string} token - Auth token to add to headers.
 * @param {Headers} headers - This argument will be used if token is being added to request headers.
 * @param {string} body - This argument will be used if token is added to request body.
 * @return {string} Updated request body. Body is returned because it is a string and returning it is the only way for
 * caller to get the updated value.
 */
export const addAuthTokenJson = (token, headers, body) => {
	if (add_access_token_to === AUTH_TOKEN_LOCATION.HEADER) {
		headers.set(access_token_header_field_name, access_token_header_value_prefix + token);
	} else if (add_access_token_to === AUTH_TOKEN_LOCATION.BODY) {
		let bodyJson = JSON.parse(body);
		set(bodyJson, access_token_body_field_name, token);
		body = JSON.stringify(bodyJson);
	}

	// Returns updated request body. Body is returned because it is a string and returning it is the only way for caller
	// to get the updated value.
	return body;
}

/**
 * Check if tokens are refreshing
 * @return {boolean}
 */
export const areTokensRefreshing = () => 
	getBoolFromTinyInt(getStorageValue(refreshing_tokens_storage_field_name, STORAGE_TYPE.LOCAL));

/**
 * Promise that will resolve once tokens are done refreshing
 * @return {Promise<void>}
 */
export const refreshTokensWait = () => new Promise(resolve => {
	const interval = setInterval(() => {
		if (!areTokensRefreshing()) {
			clearInterval(interval);
			resolve();
		}
	}, refreshing_tokens_wait_interval);
});