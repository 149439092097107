/** @typedef {string} ToggleInputLabelPosition */
export const TOGGLE_INPUT_LABEL_POSITION_LEFT = 'left';
export const TOGGLE_INPUT_LABEL_POSITION_RIGHT = 'right';
/** @enum {ToggleInputLabelPosition} */
export const TOGGLE_INPUT_LABEL_POSITION = {
	LEFT: TOGGLE_INPUT_LABEL_POSITION_LEFT,
	RIGHT: TOGGLE_INPUT_LABEL_POSITION_RIGHT,
};
/** @type {ToggleInputLabelPosition[]} */
export const TOGGLE_INPUT_LABEL_POSITIONS = [
	TOGGLE_INPUT_LABEL_POSITION_LEFT,
	TOGGLE_INPUT_LABEL_POSITION_RIGHT,
];