import numeral from "numeral";
import localeList from "../../../i18n/locale";

// Register all app locales in numeral
// @note Numeral locale code is a lowercase IETF BCP 47 language tag used in the app's locale object as 'locale' 
// property (ex: 'sr-latn-rs').
localeList.forEach(locale => {
	if (typeof numeral.locales[locale.locale.toLowerCase()] === 'undefined') {
		numeral.register('locale', locale.locale.toLowerCase(), {
			delimiters: locale.numbers.delimiters,
			currency: { symbol: locale.numbers.currency.symbol },
			abbreviations: locale.numbers.abbreviations,
			ordinal: (locale.numbers.ordinal ? locale.numbers.ordinal : number => {
				const b = number % 10;
				return (~~ (number % 100 / 10) === 1) ? 
					'th' :
					(b === 1) ? 'st' :
					(b === 2) ? 'nd' :
					(b === 3) ? 'rd' : 
					'th'
				;
			})
		});
	}
});

/**
 * Fix for numeral.js small number toFixed
 * @description When exponential small number are used (like 1e-7) numeral will return NaN when formatting the number.
 * @link https://github.com/adamwdraper/Numeral-js/issues/596
 */
numeral._.toFixed = function(value, maxDecimals, roundingFunction, optionals) {
	let minDecimals = maxDecimals - (optionals || 0),
		strValue = value.toString(),
		// The the start position and value of the exponent part, if the stringified number includes it.
		expIndex = strValue.indexOf('e'),
		expPos = expIndex >= 0 ? expIndex : strValue.length,
		expPrec = expIndex >= 0 ? +strValue.slice(expIndex + 1) : 0,
		// Find the decimal point in the stringified number.
		pointPos = strValue.indexOf('.'),
		// Calculate the digits after decimal point, and account for exponent (e.g. 0.1e-3
		// has 4 digits after decimal point)
		usefulPrecision = expPos - (pointPos >= 0 ? pointPos : expPos) + Math.max(0, -expPrec),
		boundedPrecision = Math.min(Math.max(usefulPrecision, minDecimals), maxDecimals),
		zeroesToStrip = boundedPrecision - minDecimals,
		power = Math.pow(10, boundedPrecision),
		output;
	// Multiply up by precision, round accurately, then divide and use native toFixed():
	output = (roundingFunction(strValue.slice(0, expPos) + 'e' + (expPrec + boundedPrecision)) / power)
		.toFixed(boundedPrecision);
	if (zeroesToStrip > 0) {
		output =
			output.slice(0, -zeroesToStrip) +
			output.slice(-zeroesToStrip).replace(/0*$/, '');
		output = output.replace(/\.$/, '');
	}
	return output;
}

export default numeral;