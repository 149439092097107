import {ioJsonAction} from "Core/store/actions/io";
import {getArray} from "Core/helpers/data";
import * as dataMap from "./dataMap";

/**
 * Quick search for diagnosis
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} query
 * @return {function(*): Promise<DiagnosisSearchOptionDataObjects[]>}
 */
export const diagnosisQuickSearchAction = (abortCallback, query) => dispatch => {
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'member/diagnosis/search',
		{searchString: query},
	)(dispatch)
		.then(res => getArray(res, 'data').map(i => dataMap.input(i)));
};