import {cloneDeep, get} from "lodash";
import {getString} from "Core/helpers/data";
import {PatientRecordVisitsListItemDataObject} from "../dataObjects";
import {getDate, getTimeString, STANDARD_DATE_TIME_FORMAT} from "Core/helpers/datetime";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {PatientRecordVisitsListItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new PatientRecordVisitsListItemDataObject(
		getString(result, 'id'),
		getDate(get(result, 'visitDate'), STANDARD_DATE_TIME_FORMAT.ISO_DATE),
		getTimeString(getString(result, 'visitTimeFrom'), 2),
		getTimeString(getString(result, 'visitTimeTo'), 2),
		getString(result, 'note'),
		getString(result, 'therapyPatientId'),
		getString(result, 'officeLocationName'),
		`${getString(result, 'therapistLastName')} ${getString(result, 'therapistFirstName')}`
	);

	return result;
}

// @note There is no output function since list is read only.