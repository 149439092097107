import {cloneDeep, get} from 'lodash';
import {getString, resetObjectValues} from "Core/helpers/data";
import {AppointmentUpdateDataObject} from 'DataObjects/appointment';
import {getDate, getDateString, STANDARD_DATE_TIME_FORMAT} from 'Core/helpers/datetime';

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {AppointmentUpdateDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);
	
	result = new AppointmentUpdateDataObject(
		getString(result,	'uniqueID'),
		getDate(get(result, 'appointmentDate'), STANDARD_DATE_TIME_FORMAT.ISO_DATE),
		getDate(get(result, 'timeFrom'), STANDARD_DATE_TIME_FORMAT.TIME_S),
		getDate(get(result, 'timeTo'), STANDARD_DATE_TIME_FORMAT.TIME_S),
		getString(result,	'officeLocationId'),
		getString(result,	'therapistId'),
		getString(result,	'therapyId', null, true),
		getString(result,	'note'),
	);

	return result;
}

/**
 * Get input data from list item data
 * @param {AppointmentsListItemDataObject} listItemData - List item data.
 * @return {AppointmentUpdateDataObject}
 */
export const inputFromList = listItemData => {
	let result = cloneDeep(listItemData);
	
	result = new AppointmentUpdateDataObject(
		getString(result,	'id'),
		getDate(get(result, 'date'), STANDARD_DATE_TIME_FORMAT.ISO_DATE),
		getDate(get(result, 'startTime'), STANDARD_DATE_TIME_FORMAT.TIME),
		getDate(get(result, 'endTime'), STANDARD_DATE_TIME_FORMAT.TIME),
		getString(result,	'officeLocationId'),
		getString(result,	'therapistId'),
		getString(result,	'therapyId', null, true),
	);

	return result;
};

/**
 * Get output data from input data
 * @param {AppointmentUpdateDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep({
		uniqueId: inputData.id,
		appointmentDate: getDateString(inputData.date, STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		timeFrom: getDateString(
			getDate(inputData.timeFrom, STANDARD_DATE_TIME_FORMAT.TIME), 
			STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S
		),
		timeTo: getDateString(
			getDate(inputData.timeTo, STANDARD_DATE_TIME_FORMAT.TIME),
			STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S
		),
		officeLocationId: inputData.officeLocationId,
		therapistId: inputData.therapistId,
		therapyId: inputData.therapyId === '*' ? null : inputData.therapyId,
		appointmentNote: inputData.note,
	});

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}