import {cloneDeep} from 'lodash';
import {resetObjectValues} from "Core/helpers/data";
import {getDate, getDateString, STANDARD_DATE_TIME_FORMAT} from 'Core/helpers/datetime';


/**
 * Get output data from input data
 * @param {AppointmentCreateDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep({
		appointmentDate: getDateString(inputData.date, STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		timeFrom: getDateString(
			getDate(inputData.timeFrom, STANDARD_DATE_TIME_FORMAT.TIME), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S
		),
		timeTo: getDateString(
			getDate(inputData.timeTo, STANDARD_DATE_TIME_FORMAT.TIME), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S
		),
		officeLocationId: inputData.officeLocationId,
		therapistId: inputData.therapistId,
		customerId: inputData.patientId,
		therapyId: inputData.therapyId === '*' ? null : inputData.therapyId,
		appointmentNumber: inputData.appointmentNumber,
		selectedDaysOfWeek: inputData.daysOfWeek,
		note: inputData.note,
	});

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}