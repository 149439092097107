import {cloneDeep} from "lodash";
import {getString} from "Core/helpers/data";
import {OfficeLocationListItemDataObject} from "../dataObjects";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {OfficeLocationListItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new OfficeLocationListItemDataObject(
		getString(result, 'uniqueID'),
		getString(result, 'name'),
		getString(result, 'address'),
		getString(result, 'phone'),
		getString(result, 'mobilePhone'),
	);

	return result;
}

// @note There is no output function since list is read only.