/**
 * Data object representing a single statistics type
 * @note Statistics page can have multiple statistics types rendered at the same time. Usually this data is saved in the
 * Redux store reducer of the statistics page that displays certain statistics type(s).
 */
export class StatisticsDataObject {
	/**
	 * @param {StatisticsType} type - Type of the statistics.
	 * @param {StatisticsValueDataObject[]} values - Number values to display above the chart.
	 * @param {StatisticsChartSeriesDataObject[]} chart - Chart data consisting of chart series.
	 */
	constructor(type, values, chart) {
		this.type = type;
		this.values = values;
		this.chart = chart;
	}
}

/**
 * Data object representing a single numeric value of a statistics type
 * @note Statistics numeric values will be displayed above the chart of the same statistics type and usually represents
 * sum values of each chart series.
 */
export class StatisticsValueDataObject {
	/**
	 * @param {string} label - Statistics numeric value label.
	 * @param {number} value - Statistics numeric value.
	 */
	constructor(label, value) {
		this.label = label;
		this.value = value;
	}
}

/**
 * Data object representing a single chart series of a statistics type chart 
 */
export class StatisticsChartSeriesDataObject {
	/**
	 * @param {string} id - ID of the chart series.
	 * @param {StatisticsChartSeriesItemDataObject[]} data - Chart series data.
	 */
	constructor(id, data) {
		this.id = id;
		this.data = data;
	}
}

/**
 * Data object representing chart data for circular charts (pie, doughnut, ...) of a statistics type chart
 * @note Circular charts do not have multiple series.
 */
export class StatisticsCircularChartDataObject {
	/**
	 * @param {string} id - ID of the chart series.
	 * @param {string} label - Label of the chart value that will be visible in the chart legend. 
	 * @param {number} value - Chart value.
	 * @param {?string} [color=null] - Color of the value (usually slice of the pie chart or similar).
	 */
	constructor(id, label, value, color = null) {
		this.id = id;
		this.label = label;
		this.value = value;
		this.color = color;
	}
}

/**
 * Data object representing a single chart series value of a statistics type chart
 */
export class StatisticsChartSeriesItemDataObject {
	/**
	 * @param {any} x - Chart x-axis value (depends on the statistics type).
	 * @param {number} y - Chart y-axis value (depends on the statistics type).
	 * @param {?string} [color=null] - Color of the value.
	 */
	constructor(x, y, color = null) {
		this.x = x;
		this.y = y;
		this.color = color;
	}
}

export class StatisticsFilterDataObject {
	/**
	 * @param {Date} [dateFrom]
	 * @param {Date} [dateTo]
	 * @param {?string} [medicalRecordCategoryId='*']
	 * @param {?string} [therapyTypeId='*']
	 * @param {?string} [therapySubTypeId='*']
	 * @param {?string} [therapySubSubTypeId='*']
	 */
	constructor(
		dateFrom, dateTo, medicalRecordCategoryId = '*', therapyTypeId = '*', therapySubTypeId = '*', 
		therapySubSubTypeId = '*', 
	) {
		this.dateFrom = dateFrom;
		this.dateTo = dateTo;
		this.medicalRecordCategoryId = medicalRecordCategoryId;
		this.therapyTypeId = therapyTypeId;
		this.therapySubTypeId = therapySubTypeId;
		this.therapySubSubTypeId = therapySubSubTypeId;
	}
}