import React from "react";
import PageComponent from "Core/components/PageComponent";
import {connect} from "react-redux";
import * as pageConfig from "./config";
import {getPageActions} from "Core/helpers/redux";
import * as appConfig from "../config";
import * as actions from "./actions"; 
import Label from 'Core/components/display/Label';
import {getMenuSidebarShrankFromStorage} from 'Layout/elements/MainSidebar/helpers';
import {selectors} from 'Core/store/reducers';
import PatientRecord, {patientRecordActions} from 'Components/advanced/PatientRecord';
import {getString, isset, trimArray} from 'Core/helpers/data';
import {reducerStoreKey as patientQuickSearchReducerStoreKey} from 'Components/advanced/PatientQuickSearch/reducer';
import * as dataMap from './dataMap';
import ConfirmDialog from 'Core/components/dialogs/ConfirmDialog';

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	mainSidebarShrank: getMenuSidebarShrankFromStorage(selectors.mainSidebar.shrank(state)),
	selectedPatient: selectors[patientQuickSearchReducerStoreKey].getSelectedPatient(state),
});

class PatientRecordPage extends PageComponent {
	constructor(props) {
		super(props, {
			domPrefix: 'patient-record-page',
			translationPath: pageConfig.translationPath,
			routerPath: pageConfig.routerPath,
			checkLogin: false,
			renderTitle: false,
		}, 'page_title');
	}
	
	componentWillUnmount() {
		super.componentWillUnmount();

		const {clearPatientAction} = this.props;
		clearPatientAction();
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }
	

	// Router methods ---------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called if current URL matches the 'item' sub-url of the page
	 * @note Item sub-url uses '/item' router path and 'id' as router path param ('/item/:id') on top of to the router
	 * path of the page (see 'options.routerPath').
	 *
	 * @param {string|number} id - Item ID.
	 * @param {Object} [prevLocation] - Previous router location.
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page is on
	 * 'item' sub-url if such component exists.
	 */
	handleItemUrl(id, prevLocation) {
		const {selectPatientAction, loadPatientRecordAction} = this.props;
		const currentPatientId = getString(this.props, 'selectedPatient.id');
		
		// Select patient if previous location is undefined (other page) or if currently selected patient is different 
		// then the one from the URL
		if (!isset(prevLocation) || currentPatientId !== id) {
			this.executeAbortableAction(loadPatientRecordAction, id)
				.then(patientRecord => {
					if (isset(patientRecord)) selectPatientAction(dataMap.mapPatientRecordToSelectOption(patientRecord));
				});
		}
	}

	/**
	 * Method that will be called if current URL matches the base URL of the page
	 *
	 * @param {Object} [prevLocation] - Previous router location.
	 * @return {string|Promise<string>} GUI ID of the component (popup, dialog, ...) that is rendered when page is on its
	 * base URL if such component exists.
	 */
	handleBaseUrl(prevLocation) {
		const {clearPatientAction} = this.props;
		clearPatientAction();
	}

	
	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {
			mainSidebarShrank, toggleMainSidebarSizeAction, openDialogAction, closeDialogAction, setPatientRecordAction,
			addSuccessMessageAction
		} = this.props;
		
		return this.renderLayout((
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')}`}>
				<PatientRecord 
					onPatientChange={newSelectedPatient => {
						if (!newSelectedPatient) {
							if (!this.isBaseUrl()) this.redirectToBase();
						} else {
							if (this.getItemUrlId() !== newSelectedPatient.id) this.redirectToItem(newSelectedPatient.id);
						}
					}}
					onPatientCreated={newPatientData => {
						const dialogGUIID = openDialogAction('', ConfirmDialog, {
							message: this.t('confirm_switch_to_new'),
							supportHtml: true,
							onYes: GUIID => {
								closeDialogAction(GUIID);
								this.redirectToItem(newPatientData.id);
							},
						}, {
							id: 'confirm-switch-to-new-patient-dialog',
							closeOnEscape: true,
							closeOnClickOutside: true,
							hideCloseBtn: true,
							maxWidth: 500
						});
						this.setOption(
							'dialogsToCloseOnUnmount',
							trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
						);
					}}
					onPatientUpdate={updatedPatientData => {
						setPatientRecordAction(updatedPatientData);
						addSuccessMessageAction(this.t('update_patient_success'));
					}}
					onPatientDelete={() => {
						this.redirectToBase();
						addSuccessMessageAction(this.t('delete_patient_success'));
					}}
				/>
				
				{
					this.hasTranslation('page_short_description') && this.t('page_short_description') ?
						<div className="simple-page-description">
							<Label content={this.t('page_short_description')} supportHtml={true} />
						</div>
						: null
				}
			</div>
		), undefined, undefined, {
			app: appConfig,
			mainSidebarShrank,
			toggleMainSidebarSizeAction,
		});
	}
}

export * from "./config";
export default connect(mapStateToProps, getPageActions({...actions, ...patientRecordActions}))(PatientRecordPage);