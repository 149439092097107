export class DataTableCellTypeCommonOptionsDataObject {
	/**
	 * @param {string} [alignContent='unset'] - Cell content CSS text-align value.
	 * @param {string} [whiteSpace='unset'] - Cell content CSS white-space value.
	 */
	constructor({alignContent = 'unset', whiteSpace = 'unset'}) {
		this.alignContent = alignContent;
		this.whiteSpace = whiteSpace;
	}
}

/**
 * This will render a trimmed string value
 */
export class DataTableCellTextTypeOptionsDataObject extends DataTableCellTypeCommonOptionsDataObject {
	/**
	 * @param {string} [translatePath] - String value will be translated using this translation path.
	 * @param {string} [trimChar] - Custom character to use for trimming the rendered value instead of the default trim.
	 * @param {boolean} [supportHtml=false] - {boolean} supportHtml - If true, rendered value will support HTML.
	 * @param {string} [alignContent='unset'] - Cell content CSS text-align value.
	 * @param {string} [whiteSpace='unset'] - Cell content CSS white-space value.
	 */
	constructor({translatePath, trimChar, supportHtml = false, alignContent = 'unset', whiteSpace = 'unset'}) {
		super({alignContent, whiteSpace});
		
		this.translatePath = translatePath;
		this.trimChar = trimChar;
		this.supportHtml = supportHtml;
	}
}

/**
 * This will render a number with optional formatting rules based on NumberLabel component
 */
export class DataTableCellNumberTypeOptionsDataObject extends DataTableCellTypeCommonOptionsDataObject {
	/**
	 * @param {number|string} [defaultNumber] - Default number that will be used if number is invalid (undefined, could 
	 * not be converted, ...). If value is string, this value must be a string representation of a number without any 
	 * locale formatting.
	 * @param {any} [prefix] - Number label number prefix. Usually used to add currency symbol.
	 * @param {any} [suffix] - Number label number suffix. Usually used to add some flag symbol like '*' for changes or 
	 * currency symbol.
	 * @param {string} [icon] - Icon symbol name.
	 * @param {NumberLabelIconPosition} [iconPosition] - Icon position.
	 * @param {boolean} [iconSpin] - If true, icon should spin. Please note that this will work only if font icon set 
	 * supports it, and it's properly configured.
	 * @param {string} [tooltip] - Tooltip text for the number label.
	 * @param {boolean} [useAppLocale] - Flag that determines if current app locale number format will be used to render 
	 * the number label.
	 * @param {boolean} [useAppLocaleCurrency] - Flag that determines if locale currency will be used if app locale is 
	 * used. This will take into account the currency position defined in app locale.
	 * @param {string} [locale] - // Locale to use for rendering the number. If specified, 'useAppLocale' prop will be 
	 * ignored.
	 * @param {string} [format] - Number format using Numeral.js. If specified, 'locale' prop will be ignored.
	 * @param {string} [alignContent='unset'] - Cell content CSS text-align value.
	 * @param {string} [whiteSpace='unset'] - Cell content CSS white-space value.
	 */
	constructor({
		defaultNumber, prefix = '', suffix, icon, iconPosition, iconSpin, tooltip, useAppLocale, useAppLocaleCurrency, 
		locale, format, alignContent = 'unset', whiteSpace = 'unset'
	}) {
		super({alignContent, whiteSpace});
		
		this.defaultNumber = defaultNumber;
		this.prefix = prefix;
		this.suffix = suffix;
		this.icon = icon;
		this.iconPosition = iconPosition;
		this.iconSpin = iconSpin;
		this.tooltip = tooltip;
		this.useAppLocale = useAppLocale;
		this.useAppLocaleCurrency = useAppLocaleCurrency;
		this.locale = locale;
		this.format = format;
	}
}

/**
 * This will render a number with optional formatting rules based on DateLabel component. Format of the string is based 
 * on Unicode Technical Standard #35 with a few additions (date-fns library).
 * @link https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
 */
export class DataTableCellDateTypeOptionsDataObject extends DataTableCellTypeCommonOptionsDataObject {
	/**
	 * @param {string|string[]} [inputFormat] - Date label input date format. This supports LOCALE_DATE_FORMAT_NAMES and 
	 * LOCALE_TIME_FORMAT_NAMES strings that use current app locale formats (@see app/i18n/locale.js) or any custom 
	 * format (like 'MM/dd/yyyy'). If value is array, first item should be date format, second item should be date-time 
	 * separator and third item should be time format.
	 * @param {string|string[]} [outputFormat] - Date label output date format used to render the label. This supports 
	 * LOCALE_DATE_FORMAT_NAMES and LOCALE_TIME_FORMAT_NAMES strings that use current app locale formats 
	 * (@see app/i18n/locale.js) or any custom format (like 'MM/dd/yyyy'). If value is array, first item should be date 
	 * format, second item should be date-time separator and third item should be time format.
	 * @param {number|string|Object} [defaultDate] - Default date used if date could not be rendered (undefined, empty, 
	 * could not be converted, ...). Can be number (milliseconds timestamp), string (any string date in which case 
	 * 'inputFormat' prop will be used to determine the format) or Object (JavaScript Date object).
	 * @param {string} [defaultOutput] - Default output string that will be rendered if date could not be rendered 
	 * (undefined, empty, could not be converted, ...). This will only be used if 'defaultDate' prop is not defined.
	 * @param {any} [prefix] - Date label output prefix.
	 * @param {any} [suffix] - Date label output suffix.
	 * @param {string} [icon] - Icon symbol name.
	 * @param {DateLabelIconPosition} [iconPosition] - Icon position.
	 * @param {boolean} [iconSpin] - If true, icon should spin. Please note that this will work only if font icon set
	 * supports it, and it's properly configured.
	 * @param {string} [tooltip] - Tooltip text for the date label. 
	 * @param {boolean} [useAppLocale] - Flag that determines if current app locale will be used for both input and 
	 * output.
	 * @param {Object} [inputLocale] - Locale to use for converting input date string into Date. If specified, 
	 * 'useAppLocale' prop will be ignored.
	 * @param {Object} [outputLocale] - Locale to use for rendering the date. If specified, 'useAppLocale' prop will be 
	 * ignored.
	 * @param {string} [alignContent='unset'] - Cell content CSS text-align value.
	 * @param {string} [whiteSpace='unset'] - Cell content CSS white-space value.
	 */
	constructor({
		inputFormat, outputFormat, defaultDate, defaultOutput, prefix, suffix, icon, iconPosition, iconSpin, tooltip,
		useAppLocale, inputLocale, outputLocale, alignContent = 'unset', whiteSpace = 'unset'
	}) {
		super({alignContent, whiteSpace});
		
		this.inputFormat = inputFormat;
		this.outputFormat = outputFormat;
		this.defaultDate = defaultDate;
		this.defaultOutput = defaultOutput;
		this.prefix = prefix;
		this.suffix = suffix;
		this.icon = icon;
		this.iconPosition = iconPosition;
		this.iconSpin = iconSpin;
		this.tooltip = tooltip;
		this.useAppLocale = useAppLocale;
		this.inputLocale = inputLocale;
		this.outputLocale = outputLocale;
	}
}

/**
 * This will render true or false value. Default values are loaded form translation file.
 */
export class DataTableCellBoolTypeOptionsDataObject  extends DataTableCellTypeCommonOptionsDataObject {
	/**
	 * 
	 * @param {string} [translatePath] - Value will be translated using this translation path.
	 * @param {string} [trueLabel] - Label rendered as true value instead of the default one.
	 * @param {string} [falseLabel] - Label rendered as false value instead of the default one.
	 * @param {boolean} [supportHtml=false] - {boolean} supportHtml - If true, rendered value will support HTML.
	 * @param {string} [alignContent='unset'] - Cell content CSS text-align value.
	 * @param {string} [whiteSpace='unset'] - Cell content CSS white-space value.
	 */
	constructor({
		translatePath, trueLabel, falseLabel, supportHtml = false, alignContent = 'unset', whiteSpace = 'unset'
	}) {
		super({alignContent, whiteSpace});

		this.translatePath = translatePath;
		this.trueLabel = trueLabel;
		this.falseLabel = falseLabel;
		this.supportHtml = supportHtml;
	}
}

/**
 * This will render a clickable text label, icon or both.
 */
export class DataTableCellActionTypeOptionsDataObject extends DataTableCellTypeCommonOptionsDataObject {
	/**
	 * @param {string} [key] - Action button key used in DataTableCellActionsTypeOptionsDataObject.
	 * @param {string} [label] - Action button label to render.
	 * @param {string} [icon] - Action button icon symbol name.
	 * @param {string} [tooltip] - Action button tooltip text for rendered content.
	 * @param {Function} [condition] - Condition function to determine if action button will be rendered. It receives the
	 * whole row data object.
	 * @param {string} [className] - Action button class name.
	 * @param {Function<Object, Event>} [onClick] - Action button click event. Function will receive row data object and 
	 * the click Event.
	 * @param {boolean} [supportHtml=false] - {boolean} supportHtml - If true, rendered value will support HTML.
	 * @param {string} [alignContent='unset'] - Cell content CSS text-align value.
	 * @param {string} [whiteSpace='unset'] - Cell content CSS white-space value.
	 * @param {number} [mobileFlex] - Cell action button CSS flex value on when DataTable is in mobile mode used in 
	 * DataTableCellActionsTypeOptionsDataObject.
	 */
	constructor({
		key, label, icon, tooltip, condition, className, onClick, supportHtml = false, alignContent = 'unset',
		whiteSpace = 'unset', mobileFlex,
	}) {
		super({alignContent, whiteSpace});

		this.key = key;
		this.label = label;
		this.icon = icon;
		this.tooltip = tooltip;
		this.condition = condition;
		this.className = className;
		this.onClick = onClick;
		this.supportHtml = supportHtml;
		this.mobileFlex = mobileFlex;
	}
}

/**
 * This is a list of actions.
 */
export class DataTableCellActionsTypeOptionsDataObject extends DataTableCellTypeCommonOptionsDataObject {
	/**
	 * @param {
	 * 	DataTableCellActionTypeOptionsDataObject[] |
	 * 	function(): DataTableCellActionTypeOptionsDataObject[]
	 * } [actions=[]] - Actions.
	 * @param {string} [alignContent='unset'] - Cell content CSS text-align value.
	 * @param {string} [whiteSpace='unset'] - Cell content CSS white-space value.
	 */
	constructor({actions = [], alignContent = 'unset', whiteSpace = 'unset'}) {
		super({alignContent, whiteSpace});

		this.actions = actions;
	}
}

/**
 * This will render a string containing any number of row fields (columns) where fields are specified like 
 * {$FIELD_NAME|[params]} where 'params' is a list of space-separated modifiers that can be applied to the field value.
 */
export class DataTableCellTemplateTypeOptionsDataObject extends DataTableCellTypeCommonOptionsDataObject {
	/**
	 * @param {string|Function} [template=''] - Template used to render the cell containing one or more row fields and 
	 * any other string. If function is passed it will be called with the row data and should return a string that will 
	 * be rendered in the cell.
	 * 	- Available field value modifier params:
	 * 		- l: lowercase (example: {$name|l})
	 * 		- u: uppercase (example: {$name|u})
	 * 		- t: trim (example: {$name|t})
	 * 		Examples:
	 * 		- Simple replacement (no params): {$name}
	 * 		- Replace with lowercase and trim: {$name|l t}
	 * @param {boolean} [nullAsEmpty=false] - Flag that determines if null values will be rendered as empty strings.
	 * @param {boolean} [supportHtml=false] - {boolean} supportHtml - If true, rendered value will support HTML.
	 * @param {string} [alignContent='unset'] - Cell content CSS text-align value.
	 * @param {string} [whiteSpace='unset'] - Cell content CSS white-space value.
	 */
	constructor({
		template = '', nullAsEmpty = false, supportHtml = false, alignContent = 'unset', whiteSpace = 'unset'
	}) {
		super({alignContent, whiteSpace});

		this.template = template;
		this.nullAsEmpty = nullAsEmpty;
		this.supportHtml = supportHtml;
	}
}

/**
 * This will render any content.
 */
export class DataTableCellAnyTypeOptionsDataObject {
	/**
	 * @param {any|Function<Object, any>} [content=null] - Any content to render. If function is passed it will be called 
	 * with the row data and label to render as arguments, and it should return content that will be rendered in the 
	 * cell.
	 * @param {boolean|Function<Object>} [standardWrapper=true] - Flag that determines if the standard cell wrapper will 
	 * be used to render the content. This can also be a function that will receive row data as the only argument, and it
	 * must return a boolean value.
	 */
	constructor({content = null, standardWrapper = true}) {
		this.content = content;
		this.standardWrapper = standardWrapper;
	}
}