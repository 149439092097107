import {hideLoadingFunction, showPageLoading} from "Core/helpers/loading";
import {ioJsonAction} from "Core/store/actions/io";
import {auth_api, auth_api_endpoints} from "Config/auth";

/**
 * Request reset password instructions action
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} email - Email address from change password form.
 * @return {function(*): *}
 */
export const requestPasswordResetAction = (abortCallback, email) => dispatch => {
	const loading = showPageLoading();
	return ioJsonAction(
		abortCallback,
		auth_api_endpoints.password_reset_request?.api ? auth_api_endpoints.password_reset_request?.api : auth_api,
		auth_api_endpoints.password_reset_request.path,
		{email},
		hideLoadingFunction(loading),
		false,
		undefined,
		auth_api_endpoints.password_reset_request?.method ? 
			auth_api_endpoints.password_reset_request?.method : 
			undefined
	)(dispatch);
}