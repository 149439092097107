import styles from "./index.module.css";
import "./default.style.css";
import "./toggle.style.css";

import React from "react";
import PropTypes from "prop-types";
import Label, {LABEL_ICON_POSITION} from "../Label";

function Separator({
	styleName = 'default', id = '', className = '', content = '', prefix = '', suffix = '', icon = '', 
	iconPosition = LABEL_ICON_POSITION.LEFT, iconSpin = false, tooltip = '', tooltipOptions = {}, supportHtml = false, 
	noBorder = false, noMargin = false, iconStackClass, iconSymbolPrefix, ...otherProps
})
{
	return (
		<Label
			element="div"
			elementProps={{
				id: id, 
				className: 
					`separator ${styles['separator']} ${className} ${styleName}-style ` + 
					`${noBorder ? styles['noBorder'] : ''} ${noMargin ? styles['noMargin'] : ''}`,
				...otherProps
			}}
			content={content}
			prefix={prefix}
			suffix={suffix}
			iconStackClass={iconStackClass}
			iconSymbolPrefix={iconSymbolPrefix}
			icon={icon}
			iconPosition={iconPosition}
			iconSpin={iconSpin}
			tooltip={tooltip}
			tooltipOptions={tooltipOptions}
			supportHtml={supportHtml}
		/>
	);
}

/**
 * Define component's own props that can be passed to it by parent components
 */
Separator.propTypes = {
	// Label style name
	// @description Component style name is a name of the style that will be used to determine the CSS used to style the
	// component.
	styleName: PropTypes.string,
	// Label element ID
	id: PropTypes.string,
	// Label element class name
	className: PropTypes.string,
	// Label content
	content: PropTypes.any,
	// Label content prefix
	// @note Usually used to add some dynamic value like ordinal or id. 
	prefix: PropTypes.any,
	// Label content suffix
	// @note Usually used to add some flag symbol like '*' for changes.
	suffix: PropTypes.any,
	// Icon component's stack class name
	iconStackClass: PropTypes.string,
	// Font icon symbol prefix
	iconSymbolPrefix: PropTypes.string,
	// Icon symbol name
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	// Icon position
	iconPosition: PropTypes.oneOf([LABEL_ICON_POSITION.NONE, LABEL_ICON_POSITION.RIGHT, LABEL_ICON_POSITION.LEFT]),
	// If true, icon should spin.
	// @note Please note that this will work only if font icon set supports it, and it's properly configured.
	iconSpin: PropTypes.bool,
	// Tooltip text for the label
	tooltip: PropTypes.string,
	// Tooltip options
	tooltipOptions: PropTypes.object,
	// Set to true to support HTML in 'content' prop.
	// @warning Be careful when using this flag because it can cause security issues. It uses 'dangerouslySetInnerHTML' 
	// to allow HTML content.
	supportHtml: PropTypes.bool,
	// Flag that determines if bottom border will be rendered
	noBorder: PropTypes.bool,
	// Flag that determines if bottom margin will be added
	noMargin: PropTypes.bool,
};

/**
 * Define component default values for own props
 */
Separator.defaultProps = {
	styleName: 'default',
	id: '',
	className: '',
	content: '',
	prefix: '',
	suffix: '',
	icon: '',
	iconPosition: LABEL_ICON_POSITION.LEFT,
	iconSpin: false,
	tooltip: '',
	tooltipOptions: {},
	supportHtml: false,
	noBorder: false,
	noMargin: false,
};

export default Separator;
export * from "../Label/const";