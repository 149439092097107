import {cloneDeep} from 'lodash';
import {getString, resetObjectValues} from 'Core/helpers/data';
import {DiagnosisDataObject} from "DataObjects/diagnosis";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {DiagnosisDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new DiagnosisDataObject(
		getString(result, 'uniqueID', null),
		getString(result, 'code'),
		getString(result, 'description'),
		getString(result, 'diagnosisLanguage'),
	);

	return result;
}

/**
 * Get output data from input data
 * @param {DiagnosisDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep({
		uniqueID: inputData.id,
		code: inputData.code,
		description: inputData.description,
		diagnosisLanguage: inputData.lang,
	});

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}