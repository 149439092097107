import styles from "./index.module.css";

import React from "react";
import BaseComponent, {executeComponentCallback} from "Core/components/BaseComponent";
import PropTypes from "prop-types";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import * as propTypes from "prop-types";
import {getBool} from "Core/helpers/data";

class Accordion extends BaseComponent {
	constructor(props) {
		super(props, {
			domPrefix: 'accordion-component',
		});

		// Initiate component's state 
		this.state = {
			open: props.openByDefault,
		};
		
		// Render methods
		this.renderContent = this.renderContent.bind(this);
	}
	
	renderContent() {
		const {cardStyle, children} = this.props;
		const {open} = this.state;
		
		return (
			children ?
				<div 
					className={`accordion-content ${cardStyle ? 'card-content' : ''} ${styles['content']}`}
					style={{display: open ? '' : 'none'}}
				>
					{children}
				</div>
				: null
		);
	}

	render() {
		const {cardStyle, className, title, unmountOnHide} = this.props;
		const {open} = this.state;
		
		return (
			<div 
				id={this.getDomId()} 
				className={`${this.getOption('domPrefix')} ${className} ${cardStyle ? 'card' : ''} ${styles['wrapper']}`}
			>
				<div 
					className={`accordion-header ${cardStyle ? 'card-header with-toolbar' : ''} ${styles['title']}`}
					onClick={() => this.setState({open: !open})
						.then(state => getBool(state, 'open') ? 
							executeComponentCallback(this.props.onAfterOpen, this.getDomElement()) :
							executeComponentCallback(this.props.onAfterClose, this.getDomElement()) 
						)
					}
				>
					{cardStyle ? <div className="card-title">{title ? title : null}</div> : (title ? title : null)} 
					<div className={`toolbar ${styles['toolbar']}`}>
						<Button 
							icon={open ? 'minus' : 'plus'}
							displayType={BUTTON_DISPLAY_TYPE.NONE}
							displayStyle={BUTTON_STYLE.SUBTLE}
						/>
					</div>
				</div>

				{
					unmountOnHide ?
						(open ? this.renderContent() : null) :
						this.renderContent()
				}
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
Accordion.propTypes = {
	cardStyle: PropTypes.bool,
	className: PropTypes.string,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	openByDefault: PropTypes.bool,
	unmountOnHide: PropTypes.bool,
	
	onAfterOpen: propTypes.func, // Arguments: DOM element of the component's wrapper element
	onAfterClose: propTypes.func, // Arguments: DOM element of the component's wrapper element
};

/**
 * Define component default values for own props
 */
Accordion.defaultProps = {
	id: '',
	className: '',
};

export default Accordion;