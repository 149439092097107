import {cloneDeep, get} from 'lodash';
import {getBool, getObject} from 'Core/helpers/data';

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'mainSidebar';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@mainSidebar/reset',
	SHOW: '@mainSidebar/show',
	HIDE: '@mainSidebar/hide',
	TOGGLE: '@mainSidebar/toggle',
	SHRINK: '@mainSidebar/shrink',
	ENLARGE: '@mainSidebar/enlarge',
	TOGGLE_SIZE: '@mainSidebar/toggle_size',
	SET_GROUPS_OPENED_STATUSES: '@mainSidebar/set_groups_opened_statuses',
	OPEN_GROUP: '@mainSidebar/open_group',
	CLOSE_GROUP: '@mainSidebar/close_group',
	TOGGLE_GROUP: '@mainSidebar/toggle_group',
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),
	show: () => ({type: REDUCER_TYPES.SHOW}),
	hide: () => ({type: REDUCER_TYPES.HIDE}),
	toggle: () => ({type: REDUCER_TYPES.TOGGLE}),
	shrink: () => ({type: REDUCER_TYPES.SHRINK}),
	enlarge: () => ({type: REDUCER_TYPES.ENLARGE}),
	toggleSize: () => ({type: REDUCER_TYPES.TOGGLE_SIZE}),
	setGroupsOpenedStatuses: statuses => ({type: REDUCER_TYPES.SET_GROUPS_OPENED_STATUSES, statuses}),
	openGroup: id => ({type: REDUCER_TYPES.OPEN_GROUP, id}),
	closeGroup: id => ({type: REDUCER_TYPES.CLOSE_GROUP, id}),
	toggleGroup: id => ({type: REDUCER_TYPES.TOGGLE_GROUP, id}),
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	visible: null,
	shrank: false,
	groupsOpenedStatuses: {},
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET:
			return {...initialState};

		case REDUCER_TYPES.SHOW:
			return {...state, visible: true};

		case REDUCER_TYPES.HIDE:
			return {...state, visible: false};

		case REDUCER_TYPES.TOGGLE:
			return {...state, visible: state.visible};

		case REDUCER_TYPES.SHRINK:
			return {...state, shrank: true};

		case REDUCER_TYPES.ENLARGE:
			return {...state, shrank: false};

		case REDUCER_TYPES.TOGGLE_SIZE:
			return {...state, shrank: !state.shrank};
			
		case REDUCER_TYPES.SET_GROUPS_OPENED_STATUSES:
			return {...state, groupsOpenedStatuses: cloneDeep(action.statuses)};
		case REDUCER_TYPES.OPEN_GROUP:
			return {
				...state,
				groupsOpenedStatuses: {
					...state.groupsOpenedStatuses,
					[action.id]: true,
				},
			};
		case REDUCER_TYPES.CLOSE_GROUP:
			return {
				...state,
				groupsOpenedStatuses: {
					...state.groupsOpenedStatuses,
					[action.id]: false,
				},
			};
		case REDUCER_TYPES.TOGGLE_GROUP:
			return {
				...state,
				groupsOpenedStatuses: {
					...state.groupsOpenedStatuses,
					[action.id]: !getBool(state.groupsOpenedStatuses, action.id),
				},
			};

		default:
			return state;
	}
};

// Selectors
export const selectors = {
	visible: state => get(state, [reducerStoreKey, 'visible'], true),
	shrank: state => get(state, [reducerStoreKey, 'shrank'], false),
	enlarged: state => !get(state, [reducerStoreKey, 'shrank'], false),
	getGroupsOpenedStatuses: state => getObject(state, [reducerStoreKey, 'groupsOpenedStatuses']),
	isGroupOpened: (state, id) => getBool(state, [reducerStoreKey, 'groupsOpenedStatuses', id]),
};

export default reducer;