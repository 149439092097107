import styles from "./index.module.css";

import React from "react";
import BaseComponent, {executeComponentCallback} from "Core/components/BaseComponent";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getPageActions} from "Core/helpers/redux";
import {selectors} from "Core/store/reducers";
import Calendar from "react-calendar";
import Button, {BUTTON_STYLE} from "Core/components/display/Button";
import {icon_font_close_symbol} from "Config/app";
import {isEqual, startOfDay} from "date-fns";
import {getLocaleCode} from "Core/helpers/locale";
import {matchPath, withRouter} from "react-router-dom";
import {rtrimChar} from "Core/helpers/string";
import {getString} from "Core/helpers/data";
import * as homePageConfig from "Pages/apps/default/home/config";
import {waitingFunction} from "Core/helpers/function";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @param {Object} ownProps - Components own props.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = (state, ownProps) => ({
	/** @type {LocaleObj} */
	appLocale: selectors.i18n.getLocale(state),
	selectedDate: (
		!!ownProps.reducerStoreKey && !!ownProps.reducerStoreGetMethod ?
			selectors[ownProps.reducerStoreKey][ownProps.reducerStoreGetMethod](state) :
			null
	),
});

/**
 * Calendar select component used to navigate other components (for example ScheduleCalendar)
 * @description This component triggers a custom window event 'onDateSelect' with the selected date that can be handled 
 * by any other component. Selected value can be retrieved from the Redux store using the combination of 
 * 'reducerStoreKey' and 'reducerStoreGetMethod' props.
 */
class SelectCalendar extends BaseComponent {
	calendarRef = null;
	
	constructor(props) {
		super(props, {
			translationPath: 'SelectCalendar',
			domPrefix: 'select-calendar-wrapper',
		});
		
		// Action method
		this.selectDate = this.selectDate.bind(this);
		
		// Render methods
		this.getRenderLocale = this.getRenderLocale.bind(this);
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		const {selectedDate} = this.props;
		
		// Move calendar to the month of the selected date when selected date changes
		if (!isEqual(selectedDate, prevProps.selectedDate) && !!selectedDate) {
			if (!!this.calendarRef) this.calendarRef.setActiveStartDate(selectedDate);
		}
	}


	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Select a specific date in the homepage calendar
	 * @note This method will not actually select the date in the select calendar. It will dispatch a custom window event
	 * that should be handled by the component that uses this component for navigation (for example ScheduleCalendar).
	 * @param {Date} [date] - Date to select.
	 */
	selectDate(date) {
		const {location, history} = this.props;
		
		const isAuthorizedHomePageUrl = !!matchPath(
			rtrimChar(getString(location, 'pathname'), '/'),
			{path: homePageConfig.routerPath, ...homePageConfig.routerOptions}
		);

		// Redirect to home page if not on it and with for calendar to load before selecting the date
		if (!isAuthorizedHomePageUrl) {
			history.push(homePageConfig.routerPath);

			waitingFunction(() => {
				const calendarElem = document.querySelector('.schedule-calendar-component .fc');
				if (!!calendarElem) {
					const dateSelectEvent = new CustomEvent('onDateSelect', {detail: {date}});
					window.dispatchEvent(dateSelectEvent);
					return true;
				}
			}, 100, 6000).then();
		}
		// Select the date if already on home page
		else {
			const dateSelectEvent = new CustomEvent('onDateSelect', {detail: {date}});
			window.dispatchEvent(dateSelectEvent);
		}
	}
	

	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Get locale used for rendering the date input element
	 * @return {string} Locale code (IETF).
	 */
	getRenderLocale() {
		const {useAppLocale, renderLocale, appLocale} = this.props;

		let result = '';
		if (renderLocale) result = renderLocale;
		else if (useAppLocale) result = getLocaleCode(appLocale);
		return result;
	}
	
	render() {
		const {showCloseButton, selectedDate} = this.props;
		
		return (
			<div className={`${styles['wrapper']} ${this.getOption('domPrefix')}`}>
				{
					showCloseButton ?
						<Button
							className={`select-calendar-close-btn ${styles['closeBtn']}`}
							icon={icon_font_close_symbol}
							onClick={() => executeComponentCallback(this.props.onClose)}
						/>
						: null
				}
				<Calendar
					className={`select-calendar ${styles['calendar']}`}
					locale={this.getRenderLocale()}
					value={selectedDate || null}
					onChange={this.selectDate}
					ref={node => { this.calendarRef = node; }}
				/>
				<div className={`select-calendar-actions ${styles['actions']}`}>
					<Button
						label={this.t('appointments_for_today')}
						displayStyle={BUTTON_STYLE.ACTION}
						onClick={() => this.selectDate(startOfDay(new Date()))}
					/>
				</div>
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
SelectCalendar.propTypes = {
	// Flag that specifies if close button will be shown
	// @note This is used in combination with 'onClose' event. Close button does not do anything on its own.
	showCloseButton: PropTypes.bool,

	// Locale code (IETF) to use for rendering the date input element
	// @note If specified, 'useAppLocale' prop will be ignored.
	renderLocale: PropTypes.string,
	// Flag that determines if current app locale will be used for both input and output
	useAppLocale: PropTypes.bool,
	
	// Reducer store key where selected date is saved
	reducerStoreKey: PropTypes.string,
	// Reducer selector method tu use on the reducer store key to get the selected date
	reducerStoreGetMethod: PropTypes.string,
	
	// Event called when close button is clicked
	// @note This is relevant only if 'showCloseButton' prop is set top true.
	onClose: PropTypes.func,
};

/**
 * Define component default values for own props
 */
SelectCalendar.defaultProps = {
	showCloseButton: false,
	useAppLocale: true,
};

export default withRouter(connect(mapStateToProps, getPageActions())(SelectCalendar));