import styles from "./index.module.css";

import React from "react";
import {isString} from "lodash";

const Spinner = ({
	id = '', className = '', size = 16, weight = 2, element = 'span', slow = false, linear = false, color, ...otherProps
}) => {
	// Convert camelCase (in this case lowercase) prop name into PascalCase
	// @note This is done because react component props use camelCase (JSX attributes use camelCase) by convention, but 
	// rendered React JSX component must be capitalized (PascalCase).
	const Element = element;
	
	return (
		<Element
			id={id}
			className={
				`spinner ${className} ${styles['spinner']} ${slow ? styles['slow'] : ''} ${linear ? styles['linear'] : ''}`
			}
			style={{
				height: isString(size) ? size : `${size.toString()}px`,
				width: isString(size) ? size : `${size.toString()}px`,
				borderWidth: isString(weight) ? weight : `${weight.toString()}px`,
				borderColor: color ? color : 'var(--spinner-color)',
				borderTopColor: 'transparent',
				borderBottomColor: 'transparent',
			}}
			{...otherProps}
		/>
	);
};

export default Spinner;