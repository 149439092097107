import {ioJsonAction, ioJsonFetchAction} from "Core/store/actions/io";
import {hideLoading, showLoading} from "Core/helpers/loading";
import {getArray} from "Core/helpers/data";
import {get} from "lodash";
import {actionCreators} from "Core/store/reducers";
import * as listItemDataMap from "./dataMap/listItem";
import * as itemFilterDataMap from "./dataMap/filter";
import {reducerStoreKey} from "./reducer";
import {isSuccessful} from "Core/helpers/io";
import * as prescribedTherapiesItemDataMap from "./dataMap/item";

/**
 * Reset search therapies list Redux state to initial state
 * @return {(function(*))|*}
 */
export const resetSearchTherapiesListAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].reset());
};

/**
 * Fetch search therapies list
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [filter] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const fetchSearchTherapiesListAction = (
	abortCallback, filter = null, pageNo = 1, perPage, sortBy, sortDir
) => dispatch => {	
	return ioJsonFetchAction(
		abortCallback,
		'defaultAuthorizedApi',
		'member/therapies/page-search',
		'',
		filter,
		null,
		pageNo,
		perPage,
		sortBy ? sortBy : null,
		sortDir
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => {
			if (isSuccessful(responseData)) {
				return ({
					...responseData,
					filter: itemFilterDataMap.input(get(responseData, 'filter')),
					data: getArray(responseData, 'data').map(i => listItemDataMap.input(i))
				});
			}
			return undefined;
		});
};

/**
 * Load search therapies list into Redux store
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [filter] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @param {boolean} [loadingOverlay=true] - Flag that specifies if loading overlay will be rendered.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const loadSearchTherapiesListAction = (
	abortCallback, filter = null, pageNo = 1, perPage, sortBy, sortDir, loadingOverlay = true
) => dispatch => {
	const loading = (loadingOverlay === true ? showLoading("#main-page-table") : null);
	return fetchSearchTherapiesListAction(abortCallback, filter, pageNo, perPage, sortBy, sortDir)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators[reducerStoreKey].setSearchTherapiesListData(responseData));
			if (loading !== null) hideLoading(loading);
			return responseData;
		});
};

/**
 * Set search therapies list filter in Redux store
 * 
 * @param {Object} filter - Filter to set.
 * @return {(function(*): void)|*}
 */
export const setSearchTherapiesFilterAction = filter => dispatch => {
	dispatch(actionCreators[reducerStoreKey].setSearchTherapiesListFilter(filter));
};

/**
 * Clear search therapies list form Redux store
 *
 * @return {(function(*): void)|*}
 */
export const clearSearchTherapiesListAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].clearSearchTherapiesListData());
}

/**
 * Fetch search therapies prescribed therapies item.
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string|number} id - DB ID of the search therapies prescribed therapies item to fetch.
 * @return {function(*=): Promise<IoJsonResponseObject>}
 */
export const fetchPrescribedTherapiesItemAction = (abortCallback, id) => dispatch => {	
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'member/therapies/get-prescribed-therapies',
		{id},
	)(dispatch)
	// Get mapped data from response data
		.then(responseData => {
			if (isSuccessful(responseData)){
				return ({
					...responseData,
					data: getArray(responseData, 'data').map(i => prescribedTherapiesItemDataMap.input(i))
				});
			}
			return undefined;
		});
};

/**
* Load search therapies prescribed therapies item.
*
* @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
* argument.
* @param {string|number} id - DB ID of the search therapies prescribed therapies item to fetch.
* @return {function(*=): Promise<IoJsonResponseObject>}
*/

export const loadPrescribedTherapiesItemAction = (abortCallback, id) => dispatch => {
	const loading = showLoading('#item-popup');
	return fetchPrescribedTherapiesItemAction(abortCallback, id)(dispatch)
	// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators[reducerStoreKey].setPrescribedTherapiesItem(responseData));
			hideLoading(loading);
			return responseData;
		});
};

/** 
 * Clear search therapies prescribed therapies item from Redux store.
 * 
 * @return {(function(*): void)|*}
 */

export const clearPrescribedTherapiesItemAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].clearPrescribedTherapiesItem())
}