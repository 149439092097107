import {cloneDeep} from 'lodash';
import {getBool, getString, resetObjectValues} from 'Core/helpers/data';
import {PatientPrescribedTherapyDataObject} from 'DataObjects/patientTherapy';

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {PatientPrescribedTherapyDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new PatientPrescribedTherapyDataObject(
		getString(result, 'id', null),
		getString(result, 'prescribedTherapyType'),
		getString(result, 'prescribedTherapyTypeId'),
		getString(result, 'prescribedTherapySubtype'),
		getString(result, 'prescribedTherapySubtypeId'),
		getString(result, 'prescribedTherapySubSubtype'),
		getString(result, 'prescribedTherapySubSubtypeId'),
		getString(result, 'duration'),
		getString(result, 'additionalInfo'),
		getBool(result, 'active'),
	);

	return result;
}

/**
 * Get output data from input data
 * @param {PatientPrescribedTherapyDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep(inputData);

	result = {
		id: result.id,
		prescribedTherapyType: result.prescribedTherapyType,
		prescribedTherapyTypeId: result.prescribedTherapyTypeId,
		prescribedTherapySubtype: result.prescribedTherapySubtype,
		prescribedTherapySubtypeId: result.prescribedTherapySubtypeId,
		prescribedTherapySubSubtype: result.prescribedTherapySubSubtype,
		prescribedTherapySubSubtypeId: result.prescribedTherapySubSubtypeId,
		duration: result.duration,
		additionalInfo: result.additionalInfo,
		active: result.active,
	};

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}