import {ioJsonAction} from "Core/store/actions/io";
import {getArray} from "Core/helpers/data";

/**
 * @typedef {Object} CodebookItem
 * @property {string} id - DB ID of the codebook item.
 * @property {string} name - Codebook item name.
 */

/**
 * Low-level function to fetch any standard codebook
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} endpoint - API endpoint of the codebook.
 * @param {Object} [data] - Any IO request data.
 * @param {string} [api='defaultAuthorizedApi'] - API to use (key of the 'io_base_urls' io config object). 
 * @return {function(*): Promise<Object[]>}
 * @private
 */
// eslint-disable-next-line no-unused-vars
const _fetchCodebookAction = (abortCallback, endpoint, data, api = 'defaultAuthorizedApi') => dispatch => {
	return ioJsonAction(abortCallback, api, endpoint, data)(dispatch)
		// Get the list of codebook items from the response
		.then (res => getArray(res, 'data'));
};


/**
 * Fetch the therapist codebook
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @return {function(*): Promise<CodebookItem[]>}
 */
export const fetchTherapistCodebookAction = abortCallback => dispatch =>
	_fetchCodebookAction(abortCallback, 'member/codebook/fetch-all-therapist-by-owner')(dispatch);

/**
 * Fetch the office location codebook
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @return {function(*): Promise<CodebookItem[]>}
 */
export const fetchOfficeLocationCodebookAction = abortCallback => dispatch =>
	_fetchCodebookAction(abortCallback, 'member/codebook/fetch-all-office-locations-by-owner')(dispatch);

/**
 * Fetch the medical record category codebook
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @return {function(*): Promise<CodebookItem[]>}
 */
export const fetchMedicalRecordCategoryCodebookAction = abortCallback => dispatch =>
	_fetchCodebookAction(abortCallback, 'member/codebook/fetch-all-medical-record-categories-by-owner')(dispatch);

/**
 * Fetch the recommendation codebook
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @return {function(*): Promise<CodebookItem[]>}
 */
export const fetchRecommendationCodebookAction = abortCallback => dispatch =>
	_fetchCodebookAction(abortCallback, 'member/codebook/fetch-all-recommendations-by-owner')(dispatch);

/**
 * Fetch the therapy type codebook
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {number} level - Therapy type level.
 * @return {function(*): Promise<CodebookItem[]>}
 */
export const fetchTherapyTypeCodebookAction = (abortCallback, level = 0) => dispatch =>
	_fetchCodebookAction(abortCallback, 'member/codebook/fetch-therapy-types', {level})(dispatch); // TODO:  Translations

/**
 * Fetch the therapy subtype codebook
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} parentTherapyTypeId - DB ID of the therapy type to get the subtypes for.
 * @param {number} level - Therapy type level.
 * @return {function(*): Promise<CodebookItem[]>}
 */
export const fetchTherapySubtypeCodebookAction = (abortCallback, parentTherapyTypeId, level = 1) => dispatch =>
	_fetchCodebookAction(abortCallback, 'member/codebook/fetch-therapy-types', {parentTherapyTypeId, level})(dispatch); // TODO: Translations

/**
 * Fetch the therapy sub-subtype codebook
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} parentTherapyTypeId - DB ID of the therapy type to get the subtypes for.
 * @param {number} level - Therapy type level.
 * @return {function(*): Promise<CodebookItem[]>}
 */
export const fetchTherapySubSubtypeCodebookAction = (abortCallback, parentTherapyTypeId, level = 2) => dispatch =>
	_fetchCodebookAction(abortCallback, 'member/codebook/fetch-therapy-types', {parentTherapyTypeId, level})(dispatch); // TODO: Translations