import styles from "./index.module.css";

import React from "react";
import DataComponent from "Core/components/DataComponent";
import PropTypes from "prop-types";
import {getNumber, getString} from "Core/helpers/data";
import {cloneDeep, isFunction} from "lodash";
import {spliceStr} from "Core/helpers/string";

/**
 * Textarea input component
 * @description Textarea input component that does not update parent component's state on each key press ('fast' 
 * component).
 * @note This component uses internal state to handle keypress updates re-rendering only itself until used hits the 
 * 'Enter' key or textarea (inputRef) looses focus. This fixes one of the main performance issues in React forms or 
 * pages with lost of input fields where each keypress rerender the whole form or page.
 */
class TextareaInput extends DataComponent {
	/**
	 * Ref used for external reference (for example for inserting values)
	 * @type {Object}
	 */
	inputRef = null;

	constructor(props, initialState = {}, options = {}) {
		const _options = {
			domPrefix: 'textarea-input-component',
			dataPropAlias: 'value',
			enableLoadOnDataPropChange: true,
			
			...(options ? cloneDeep(options) : {})
		};

		const _initialState = {
			// Text input's current value
			data: '',
			
			...(initialState ? cloneDeep(initialState) : {})
		};

		super(props, _initialState, _options);

		// Data methods
		this.insertValue = this.insertValue.bind(this);
		this.updateValue = this.updateValue.bind(this);
	}


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Get data to load into local component's state
	 * @description Create and return data that can be loaded directly into local component's state based on the raw
	 * external data (usually sent through props). In some sense this is a method that maps external data into format
	 * that component can use in its local state. This method should return data in the same format as 'getData' method.
	 * @note This method will not mutate the passed data.
	 *
	 * @param {any} rawData - External data that will be used to create local component's state compatible data.
	 * @return {any|null} Local component's state compatible data or null if data could not be loaded.
	 */
	getDataToLoad(rawData) { return getString(rawData); }

	/**
	 * Programmatically insert value into textarea DOM element at cursor position
	 * @param {string|number} value - Value to insert.
	 */
	insertValue(value) {
		const {readOnly} = this.props;

		if (this.inputRef && !readOnly) {
			const insertValue = getString(value);

			// Delete selected text first
			if (this.inputRef.selectionStart !== this.inputRef.selectionEnd) {
				const inputRefOriginalCursorStartPos = getNumber(this.inputRef,'selectionStart',this.inputRef.value.length);
				this.inputRef.value = spliceStr(
					this.inputRef?.value,
					this.inputRef.selectionStart,
					Math.abs((this.inputRef.selectionEnd - this.inputRef.selectionStart))
				);
				this.inputRef.selectionStart = inputRefOriginalCursorStartPos;
				this.inputRef.selectionEnd = inputRefOriginalCursorStartPos;
			}

			// Get text input current cursor position
			const inputRefCursorPos = getNumber(this.inputRef, 'selectionEnd', this.inputRef?.value.length);

			// Insert value an current cursor position
			this.inputRef.value =
				this.inputRef?.value.slice(0, inputRefCursorPos) +
				insertValue +
				this.inputRef?.value.slice(inputRefCursorPos)
			;

			// Trigger change
			let ev = new Event('input', { bubbles: true });
			ev.simulated = true;
			this.inputRef.dispatchEvent(ev);
			let evBlur = new Event('blur', { bubbles: true });
			evBlur.simulated = true;
			this.inputRef.dispatchEvent(evBlur);

			// Return focus to the input element (required for inputs that use blur event to trigger updates)
			const _i = setInterval(() => {
				if (document.activeElement === this.inputRef) clearInterval(_i);
				else {
					this.inputRef.focus();
					// Select the newly inserted value
					this.inputRef.selectionStart = inputRefCursorPos;
					this.inputRef.selectionEnd = inputRefCursorPos + insertValue.length;
				}
			}, 10);
		}
	}

	/**
	 * Programmatically update textarea DOM element's value
	 * @param {string|number} value - Value to set.
	 */
	updateValue(value) {
		const {readOnly} = this.props;

		if (this.inputRef && !readOnly) {
			// Update input's content
			this.inputRef.value = getString(value);
			this.state.data = getString(value);

			// Move cursor to the start of the input
			this.inputRef.setSelectionRange(0, 0);

			// Trigger change
			let ev = new Event('input', { bubbles: true });
			ev.simulated = true;
			this.inputRef.dispatchEvent(ev);
			let evBlur = new Event('blur', { bubbles: true });
			evBlur.simulated = true;
			this.inputRef.dispatchEvent(evBlur);

			// Return focus to the input element (required for inputs that use blur event to trigger updates)
			const _i = setInterval(() => {
				if (document.activeElement === this.inputRef) clearInterval(_i);
				else this.inputRef.focus();
			}, 10);
		}
	}
	
	
	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {
			id, className, name, value, readOnly, disabled, formControlStyle, showPreview, getPreview, onChange, 
			...otherProps
		} = this.props;
		const data = this.getData();
		
		return (
			<>
				<textarea
					id={id}
					className={
						`${this.getOption('domPrefix')} ${formControlStyle ? 'form-control' : ''} ${className} ` +
						`${styles['input']}`
					}
					name={name}
					value={data}
					readOnly={readOnly}
					disabled={disabled}
					onChange={!readOnly ? e => this.setData(e.target.value) : null}
					onBlur={!readOnly ? this.update : null}
					ref={node => this.inputRef = node}
					{...otherProps}
				/>
				{showPreview && isFunction(getPreview) ? getPreview(data) : null}
			</>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
TextareaInput.propTypes = {
	// Input id attribute
	id: PropTypes.string,
	// Input class attribute
	className: PropTypes.string,
	// Input name attribute
	name: PropTypes.string,
	// Input value
	value: PropTypes.string,
	// Flag specifying if textarea should be read only
	readOnly: PropTypes.bool,
	// Flag that determines if the input should be disabled
	disabled: PropTypes.bool,
	// Flag that determines if input will have a standard form control style
	formControlStyle: PropTypes.bool,
	
	// Flag that specifies if preview section will be shown
	showPreview: PropTypes.bool,
	// Function receives internal component data and should return a preview
	// @note By default it will return the current data.
	// @type {Function<string>: string}
	getPreview: PropTypes.func,

	// Events
	onChange: PropTypes.func, // Arguments: {Event} event
};

/**
 * Define component default values for own props
 */
TextareaInput.defaultProps = {
	id: '',
	className: '',
	name: '',
	value: '',
	readOnly: false,
	disabled: false,
	formControlStyle: true,
	showPreview: false,
	getPreview: value => value,
};

export default TextareaInput;