/** @typedef {string} AgeTypesForSelectInput */
export const YEAR = 'YEAR';
export const MONTH = 'MONTH';

/** @enum {AgeTypesForSelectInput} */
export const AGE_TYPE = {
	YEAR: YEAR,
	MONTH: MONTH
};
/** @type {AgeTypesForSelectInput[]} */
export const AGE_TYPES = [ YEAR, MONTH ];