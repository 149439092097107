import React from 'react';
import BaseComponent from 'Core/components/BaseComponent';
import {connect} from 'react-redux';
import SelectAsyncInput from 'Core/components/input/SelectAsyncInput';
import PropTypes from 'prop-types';
import {getGlobalActions} from 'Core/helpers/redux';
import * as actions from './actions';
import {get, omit} from 'lodash';
import {getString} from 'Core/helpers/data';
import HighlightedQueryOption from 'Core/components/input/SelectInput/options/HighlightedQueryOption';

/**
 * Get all actions used by this component
 * @type {Object}
 */
const allActions = getGlobalActions(actions);

class PatientSelectInput extends BaseComponent {
	constructor(props) {
		super(props);

		this.loadOptions = this.loadOptions.bind(this);
	}
	
	/**
	 * Load options for async select component
	 * 
	 * @param {string} query - Query string from input.
	 * @return {Promise<Object[]>}
	 */
	loadOptions(query) {
		const {minLength, patientQuickSearchAction} = this.props;
		if (query.length >= minLength) return this.executeAbortableAction(patientQuickSearchAction, query);
		return Promise.resolve([]);
	}
	
	render() {
		return (
			<SelectAsyncInput
				loadOptions={this.loadOptions}
				getOptionLabel={o => {
					const firstName = getString(o, 'firstName');
					const middleName = getString(o, 'middleName');
					const lastName = getString(o, 'lastName');
					const telephone = getString(o, 'telephone');
					const mobilePhone = getString(o, 'mobilePhone');
					
					let label = firstName;
					if (middleName.length > 0) label += `${label.length > 0 ? ' ' : ''}(${middleName})`;
					if (lastName.length > 0) label += `${label.length > 0 ? ' ' : ''}${lastName}`;
					if (telephone.length > 0) label += `${label.length > 0 ? ', ' : ''}${telephone}`;
					if (mobilePhone.length > 0) label += `${label.length > 0 ? ' ' : ''}${mobilePhone}`;
					
					return label;
				}}
				getOptionValue={o => get(o, 'id')}
				components={{Option: HighlightedQueryOption}}
				{...omit(this.props, ['minLength', 'loadOptions', ...Object.keys(allActions)])}
			/>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
PatientSelectInput.propTypes = {
	...SelectAsyncInput.propTypes,
	
	minLength: PropTypes.number,
};

/**
 * Define component default values for own props
 */
PatientSelectInput.defaultProps = {
	...SelectAsyncInput.defaultProps,
	
	minLength: 2,
};

export {PatientSelectOptionDataObject} from './dataObjects';
export {inputFromCreate as importPatientSelectOptionFromCreate} from './dataMap';
export default connect(null, allActions, null, {forwardRef: true})(PatientSelectInput);