import {reject, filter, find, cloneDeep, get} from "lodash";

/**
 * Get array from source and path (similar to lodash 'get' function)
 *
 * @param {object|any} source - Object to query or any other value.
 * @param {string|string[]} [path=''] - Path of the property to get. If not specified, 'source' will be used as value.
 * @param {array} [defaultValue=[]] - Value returned if 'path' could not be found or is undefined.
 * @returns {array}
 */
export const getArray = (source, path = '', defaultValue = []) => {
	const value = (path ? get(source, path, defaultValue) : source);
	return (typeof value === 'undefined' || value === null ? [] : value);
};

/**
 * Get string value from source and path (similar to lodash 'get' function)
 *
 * @param {object|any} source - Object to query or any other value.
 * @param {string|string[]} [path=''] - Path of the property to get. If not specified, 'source' will be used as value.
 * @param {string} [defaultValue=''] - Value returned if 'path' could not be found or is undefined.
 * @param {boolean} [nullAsDefault=false] - If true, null values will be treated as undefined so default value will be
 * returned instead of an empty string.
 * @returns {string}
 */
export const getString = (source, path = '', defaultValue = '', nullAsDefault = false) => {
	const value = (path ? get(source, path, defaultValue) : source);
	return (
		value === null ? (nullAsDefault ? defaultValue : '') : (typeof value === 'undefined' ? '' : value.toString())
	);
};

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'message';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@message/reset',
	ADD: '@message/add_message',
	REMOVE: '@message/remove_message',
	CLEAR: '@message/clear_messages'
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),
	add: (
		GUIID, style, content, autoHideAfter, customClass, canClose, showCloseButton, closeOnClick, asDialog, 
		dialogOptions, dialogProps
	) => ({
		type: REDUCER_TYPES.ADD, GUIID, style, content, autoHideAfter, customClass, canClose, showCloseButton, 
		closeOnClick, asDialog, dialogOptions, dialogProps
	}),
	remove: GUIID => ({type: REDUCER_TYPES.REMOVE, GUIID}),
	clear: (style = '') => ({type: REDUCER_TYPES.CLEAR, style})
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {{messages: MessageData[]}}
 */
export const initialState = {
	messages: []
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET:
			return {...initialState};
			
		case REDUCER_TYPES.ADD:
			return {
				...state,
				messages: [
					...state.messages,
					{
						GUIID: action.GUIID,
						style: action.style,
						content: action.content,
						autoHideAfter: action.autoHideAfter,
						customClass: action.customClass,
						canClose: action.canClose,
						showCloseButton: action.showCloseButton,
						closeOnClick: action.closeOnClick,
						asDialog: action.asDialog,
						dialogOptions: action.dialogOptions,
						dialogProps: action.dialogProps
					}
				]
			}
			
		case REDUCER_TYPES.REMOVE:
			return {
				...state,
				messages: reject(cloneDeep(state.messages), { GUIID: action.GUIID })
			}
			
		case REDUCER_TYPES.CLEAR:
			// Remove messages with specific style if 'action.style' is specified
			const style = getString(action, 'style');
			if (style){
				return {
					...state,
					messages: reject(cloneDeep(state.messages), { style })
				}
			}
			
			return {
				...state,
				messages: cloneDeep(initialState.messages)
			}

		default:
			return state;
	}
};

// Selectors
export const selectors = {
	getMessages: (state, style = '') => (
		style ? 
			filter(getArray(state, [reducerStoreKey, 'messages']), { style }) : 
			getArray(state, [reducerStoreKey, 'messages'])
	),
	getMessage: (state, GUIID) => find(state[reducerStoreKey].messages, { GUIID })
};

export default reducer;