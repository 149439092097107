/**
 * Data object representing the search payments list item
 * @note This is usually used in data table as a row. Params don't have default values since this data object is used to
 * represent data that actually exists.
 */
export class PaymentsListItemDataObject {
	/**
	 * Creates a new instance of the PaymentsListItemDataObject class.
	 *
	 * @param {string} id - The ID of the constructor.
	 * @param {Date} date - The date of the constructor.
	 * @param {number} appointmentsNumber - The number of appointments for the constructor.
	 * @param {number} amount - The amount for the constructor.
	 * @param {string} note - The note for the constructor.
	 * @param {string} patientId - The ID of the patient for the constructor.
	 * @param {string} patientFirstName - The first name of the patient for the constructor.
	 * @param {string} patientLastName - The last name of the patient for the constructor.
	 * @param {string} officeLocation - The office location for the constructor.
	 */
	constructor(
		id, date, appointmentsNumber, amount, note, patientId, patientFirstName, patientLastName, officeLocation,
	) {
		this.id = id;
		this.date = date;
		this.appointmentsNumber = appointmentsNumber;
		this.amount = amount;
		this.note = note;
		this.patientId = patientId;
		this.patientFirstName = patientFirstName;
		this.patientLastName = patientLastName;
		this.officeLocation = officeLocation;
	}
}

/**
 * Data object representing the payments item
 * @note This is usually used when creating or updating data table item using a popup or a dialog. Params have default
 * values since this data object can be used for creating an item. This is why 'id' has a default value of null which
 * represents a new item that is being created.
 */
export class PaymentDataObject {
	/**
	 * @param {?string} [id=null] 
	 * @param {?string} [patientId=null]
	 * @param {?string} [therapyId=null]
	 * @param {Date} [date=null] 
	 * @param {number} [appointmentsNumber=1] 
	 * @param {?number} [amount=null] 
	 * @param {string} [note=''] 
	 * @param {?string} [officeLocationId=null]
	 */
	constructor(
		id = null, patientId = null, therapyId = null, date = null, appointmentsNumber = 1, amount = null, 
		note = '', officeLocationId = null,
	) {
		this.id = id;
		this.patientId = patientId;
		this.therapyId = therapyId;
		this.date = date;
		this.appointmentsNumber = appointmentsNumber;
		this.amount = amount;
		this.note = note;
		this.officeLocationId = officeLocationId;
	}
}