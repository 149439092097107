import {cloneDeep, forOwn, set} from "lodash";
import {STANDARD_DATE_TIME_FORMAT} from "../../../const/datetime";
import {format} from "date-fns";
import {getBooleanFromTinyInt, getTinyIntFormBoolean, isset, resetObjectValues} from "../../../helpers/data";

/**
 * Get filter input data from raw filter data
 * @description This function will convert raw IO data object filed values into values that can be used in the filter
 * component. Boolean field values with be converted tinyint values.
 * 
 * @param {Object} rawData - Raw filter data usually received from IO.
 * @param {string[]} [booleanFields=[]] - Array of field names that will be considered as boolean values. These values
 * will be converted to tinyint values because select input components cannot handle boolean values.
 * @return {*}
 */
export const filterInput = (rawData, booleanFields = []) => {
	let result = cloneDeep(rawData);

	forOwn(result, (value, key) => {
		// Handle null or undefined values
		if (value === null || !isset(value)) return value;
		// Convert boolean values to tinyint
		// @note This is done because select input cannot work with boolean values
		else if (booleanFields.includes(key)) set(result, key, getTinyIntFormBoolean(value));
	});

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
};



/**
 * Get filter output data from input data
 * @description This function will convert data object field values into values suitable for IO. Undefined and null 
 * values will be removed, Date values will be converted to strings and boolean fields with tinyint values will be 
 * converted to boolean values.
 * 
 * @param {object} data - Filter input data.
 * @param {string[]} [booleanFields=[]] - Array of field names that will be considered as boolean values. These will be 
 * converted to boolean values from tinyint values because select input components use tinyint values.
 * @param {string} [dateFormat] - Date format used to convert Date values into strings.
 * @return {*}
 */
export const filterOutput = (
	data, booleanFields = [], dateFormat = STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S
) => {
	let result = cloneDeep(data);

	forOwn(result, (value, key) => {
		// Remove null or undefined values
		if (value === null || !isset(value)) delete result[key];
		// Convert Date object to appropriate date string
		else if (value instanceof Date) set(result, key, format(value, dateFormat));
		// Convert tinyint to boolean values
		// @note This is done because select input cannot work with boolean values
		else if (booleanFields.includes(key)) set(result, key, getBooleanFromTinyInt(value));
	});

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
};

/**
 * Class that can be used for any simple static search filed component prop if that prop depends on current search data
 * @description This class consist of a single function that is passed through the constructor. The function will
 * receive current search data as an only argument and it should return the actual prop value.
 */
export class DynamicPropFunction {
	/**
	 * Class constructor
	 * @param {function(currentSearchData: Object):any} func
	 */
	constructor(func) { this.func = func; }
}