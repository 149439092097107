import {actionCreators} from "Core/store/reducers";
import {reducerStoreKey} from "./reducer";
import {ioJsonDeleteItemAction, ioJsonFetchAction, ioJsonSaveAction} from "Core/store/actions/io";
import {isSuccessful} from "Core/helpers/io";
import * as listItemDataMap from "./dataMap/listItem";
import {getArray, isset} from "Core/helpers/data";
import {hideLoading, hideLoadingFunction, showDialogLoading, showLoading} from "Core/helpers/loading";
import {therapy_type_root_levels} from "Config/app";
import * as itemDataMap from "./dataMap/item";
import {get} from "lodash";

/**
 * Reset therapy types list Redux state to initial state
 * @return {(function(*))|*}
 */
export const resetTherapyTypesAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].reset());
};

/**
 * Fetch therapy type list
 * @note If level is not defined, root level will be fetched.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {number} [level] - Therapy type level (type, subtype, sub-subtype) starting from root level.
 * @param {?string} [parentId=null] - ID of the parent therapy type if level is greater than root level.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy='name'] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const fetchTherapyTypeAction = (
	abortCallback, level = therapy_type_root_levels, parentId = null, pageNo = 1, perPage, sortBy = 'name', sortDir
) => dispatch => {
	return ioJsonFetchAction(
		abortCallback,
		'defaultAuthorizedApi',
		'member/therapy-type/page-search',
		'',
		{
			level, 
			parentTherapyTypeId: parentId
		},
		null,
		pageNo,
		perPage,
		sortBy ? sortBy : null, 
		sortDir,
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => {
			if (isSuccessful(responseData)) {
				return ({...responseData, data: getArray(responseData, 'data').map(i => listItemDataMap.input(i))});
			}
			return undefined;
		});
}

/**
 * Load therapy type list into Redux store
 * @note This action will not send an IO request if level is greater than root and parent ID is not specified, but it 
 * will clear the Redux store data in that case. If level is not defined, root level will be loaded.
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {number} [level] - Therapy type level (type, subtype, sub-subtype) starting from root level.
 * @param {?string} [parentId=null] - ID of the parent therapy type if level is greater than root level. 
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @param {boolean} [loadingOverlay=true] - Flag that specifies if loading overlay will be rendered.
 * @return {function(*=): Promise<IoJsonFetchResponseObject|null>}
 */
export const loadTherapyTypeListAction = (
	abortCallback, level = therapy_type_root_levels, parentId = null, pageNo = 1, perPage, sortBy, sortDir, 
	loadingOverlay = true
) => dispatch => {
	if (level > therapy_type_root_levels && !parentId) {
		dispatch(actionCreators[reducerStoreKey].setTherapyTypeListData(level, null));
		return null;
	}
	
	const loading = (loadingOverlay === true ? showLoading(`#therapy-type-list-data-table-${level}`) : null);
	return fetchTherapyTypeAction(abortCallback, level, parentId, pageNo, perPage, sortBy, sortDir)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators[reducerStoreKey].setTherapyTypeListData(level, responseData));
			if (loading !== null) hideLoading(loading);
			return responseData;
		});
};

/**
 * Clear therapy type list form Redux store
 * @note If level is not defined, root level will be cleared.
 * 
 * @param {number} [level] - Therapy type level (type, subtype, sub-subtype) starting from root level.
 * @return {(function(*): void)|*}
 */
export const clearTherapyTypeListAction = (level = therapy_type_root_levels) => dispatch => {
	dispatch(actionCreators[reducerStoreKey].clearTherapyTypeListData(level));
}

/**
 * Select a therapy type item from the data table so that its subitems can be loaded
 * @note This only woks for the levels less than max level.
 *
 * @param {number} level - Therapy type level (type, subtype, sub-subtype) starting from root level.
 * @param {TherapyTypesListItemDataObject} listItem - Selected therapy type list item.
 * @return {(function(*): void)|*}
 */
export const selectTherapyTypeItemAction = (level, listItem) => dispatch => {
	dispatch(actionCreators[reducerStoreKey].selectTherapyTypeItem(level, listItem));
	// Clear selected therapy types for child therapy type levels
	dispatch(actionCreators[reducerStoreKey].clearSelectTherapyTypeItems(level + 1));
};

/**
 * Clear selected therapy types and all selected subtypes
 *
 * @param {number} level - Therapy type level (type, subtype, sub-subtype) starting from root level.
 * @return {(function(*): void)|*}
 */
export const clearSelectTherapyTypeItemAction = level => dispatch => {
	dispatch(actionCreators[reducerStoreKey].clearSelectTherapyTypeItems(level));
}

/**
 * Save (create or update) therapy type item
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {number} level - Therapy type level (type, subtype, sub-subtype) starting from root level.
 * @param {?string} [parentTherapyTypeId=null] - ID of the parent therapy type if level is greater than root level.
 * @param {TherapyTypesItemDataObject} item - Therapy type item to save.
 * @return {function(*=): Promise<TherapyTypesItemDataObject|undefined>} Promise that will resolve with the saved item 
 * received from IO or undefined if saving failed.
 */
export const saveTherapyTypeItemAction = (abortCallback, level, parentTherapyTypeId = null, item) => dispatch => {
	const isNew = (item.id === null);
	const loading = showDialogLoading('therapy-type-dialog');
	
	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		`member/therapy-type/${isNew ? 'create' : 'update'}`,
		{
			id: isNew ? null : item.id,
			data: {...itemDataMap.output(item), level, parentTherapyTypeId}
		},
		undefined,
		true,
		hideLoadingFunction(loading)
	)(dispatch)
		// Get mapped data from response data
		.then(res => isset(res) ? itemDataMap.input(get(res, 'data')) : undefined);
};

/**
 * Delete therapy type item action
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - ID of the therapy type item that will be deleted.
 * @return {function(*): Promise<IoJsonFetchResponseObject>}
 */
export const deleteTherapyTypeItemItemAction = (abortCallback, id) => dispatch => {
	const loading = showLoading('#therapy-type-delete-dialog .dialog-content-component .buttons');
	return ioJsonDeleteItemAction(
		// @note abortCallback is set to undefined because delete actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'member/therapy-type/delete-by-ids',
		id,
		{},
		hideLoadingFunction(loading)
	)(dispatch);
};