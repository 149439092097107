import {cloneDeep} from "lodash";
import {getString, resetObjectValues} from "Core/helpers/data";
import {TherapistsItemDataObject} from "../dataObjects";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {TherapistsItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new TherapistsItemDataObject(
		getString(result, 'uniqueID'),
		getString(result, 'firstName'),
		getString(result, 'lastName'),
		getString(result, 'telephone'),
		getString(result, 'mobilePhone'),
		getString(result, 'email'),
		getString(result, 'address'),
		getString(result, 'backgroundCalendarColor'),
		getString(result, 'fontCalendarColor'),
	);

	return result;
}

/**
 * Get output data from input data
 * @param {TherapistsItemDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep(inputData);

	result = {
		uniqueID: result.id,
		firstName: result.firstName,
		lastName: result.lastName,
		telephone: result.telephone,
		mobilePhone: result.mobilePhone,
		email: result.email,
		address: result.address,
		fontCalendarColor: result.fontCalendarColor,
		backgroundCalendarColor: result.backgroundCalendarColor,
	};

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}