import {hideLoadingFunction, showDialogLoading} from 'Core/helpers/loading';
import {ioJsonAction} from 'Core/store/actions/io';
import {isset} from 'Core/helpers/data';
import * as paymentDataMap from "DataMap/payment";
import {get} from 'lodash';

/**
 * Create a payment
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {PaymentDataObject} payment
 * @param {boolean} [refund] - Flag that specifies if output should be mapped for refund.
 * @return {function(*): Promise<PaymentDataObject|undefined>}
 */
export const createPaymentAction = (abortCallback, payment, refund) => dispatch => {
	const loading = showDialogLoading('create-payment-dialog');
	return ioJsonAction(
		undefined,
		'defaultAuthorizedApi',
		'member/payment/create',
		{
			id: null,
			data: paymentDataMap.output(payment, refund),
			requestSavedData: true,
		},
		hideLoadingFunction(loading),
	)(dispatch)
		.then(res => isset(res) ? paymentDataMap.input(get(res, 'data')) : undefined);
}
