import React from "react";
import PageDataComponent from "Core/components/PageDataComponent";
import {connect} from "react-redux";
import {getPageActions} from "Core/helpers/redux";
import {selectors} from "Core/store/reducers";
import * as appConfig from "../../config";
import * as pageConfig from "./config";
import * as actions from "./actions";
import {getMenuSidebarShrankFromStorage} from "Layout/elements/MainSidebar/helpers";
import {reducerStoreKey} from "./reducer";
import {areAllObjectPropsEmpty} from "Core/helpers/data";
import * as filterDataMap from "./dataMap/filter";
import {scrollToSelector} from "Core/helpers/dom";
import {BUTTON_STYLE} from "Core/components/display/Button";
import Label from "Core/components/display/Label";
import SimpleStaticSearch, {
	SIMPLE_STATIC_SEARCH_LAYOUT,
	SimpleStaticSearchOptionObject
} from "Core/components/advanced/SimpleStaticSearch";
import DataTable from "Core/components/advanced/DataTable";
import {PAGINATION_TYPE} from "Core/components/action/Pagination";
import Spinner from "Core/components/display/Spinner";
import Icon from "Core/components/display/Icon";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	mainSidebarShrank: getMenuSidebarShrankFromStorage(selectors.mainSidebar.shrank(state)),
	mainListData: selectors[reducerStoreKey].getSearchSalesListData(state),
	mainList: selectors[reducerStoreKey].getSearchSalesList(state),
	mainListPagination: selectors[reducerStoreKey].getSearchSalesListPagination(state),
	mainListSort: selectors[reducerStoreKey].getSearchSalesListSort(state),
	mainListFilter: selectors[reducerStoreKey].getSearchSalesListFilter(state),
});

class SearchSalesPage extends PageDataComponent {
	constructor(props) {
		super(props, {
			data: {
				/**
				 * Flag showing if filter is loading
				 */
				filterLoading: false,
			},
		}, {
			domPrefix: 'search-sales-page',
			translationPath: pageConfig.translationPath,
			routerPath: pageConfig.routerPath,
			checkLogin: false,
			disableLoad: true,
			renderTitle: false,
		}, 'page_title');

		// Refs
		this.mainListFilterRef = null;

		// Data methods
		this.reloadMainList = this.reloadMainList.bind(this);
		this.loadMainListPage = this.loadMainListPage.bind(this);
		this.sortMainList = this.sortMainList.bind(this);
		this.filterMainList = this.filterMainList.bind(this);
		this.removeMainListFilter = this.removeMainListFilter.bind(this);
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called on component mount and should be used to load any data required by the page
	 * @return {Promise<any>}
	 * @throws {AsyncMountError}
	 */
	// loadPageData() {
	// 	const {mainListData} = this.props;
	//
	// 	// If main list data was already loaded (user already searched for something)
	// 	if (mainListData !== null) {
	// 		// Reload main list with currently applied filter, sort and pagination
	// 		return this.reloadMainList()
	// 			.then(res => { if (!isset(res)) throw new AsyncMountError(); });
	// 	}
	// }

	/**
	 * Reload main list using current options (page, sort, ...)
	 * @return {Promise<*>}
	 */
	reloadMainList() {
		const {loadSearchSalesListAction, mainListPagination, mainListSort, mainListFilter} = this.props;
		const {pageNo, perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = filterDataMap.output(mainListFilter);

		return this.executeAbortableAction(loadSearchSalesListAction, oFilter, pageNo, perPage, sortBy, sortDir)
			.then(res => {
				this.mainListFilterRef?.reload();
				return res;
			});
	}

	/**
	 * Reload main list using current options (page, sort, ...) if any
	 * @param {number} [pageNo=1] - Page number to load (starts with 1).
	 * @return {Promise<*>}
	 */
	loadMainListPage(pageNo = 1) {
		const {loadSearchSalesListAction, mainListPagination, mainListSort, mainListFilter} = this.props;
		const {perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = filterDataMap.output(mainListFilter);

		return this.executeAbortableAction(loadSearchSalesListAction, oFilter, pageNo, perPage, sortBy, sortDir);
	}

	/**
	 * Sort main list
	 * @param {string} sortBy - Name of the sort column.
	 * @param {string} sortDir - Direction of the sort.
	 * @return {Promise<*>}
	 */
	sortMainList(sortBy, sortDir) {
		const {loadSearchSalesListAction, mainListPagination, mainListFilter} = this.props;
		const {pageNo, perPage} = mainListPagination;
		const oFilter = filterDataMap.output(mainListFilter);

		return this.executeAbortableAction(loadSearchSalesListAction, oFilter, pageNo, perPage, sortBy, sortDir);
	}

	/**
	 * Filter main list
	 * @param {Object} filter - Filter object where keys are filter field names and values are filter values.
	 * @return {Promise<*>}
	 */
	filterMainList(filter) {
		const {
			loadSearchSalesListAction, setSearchSalesFilterAction, mainListPagination, mainListSort,
		} = this.props;
		const {perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = filterDataMap.output(filter);
		
		// Set filter so that the change will be detected after IO
		setSearchSalesFilterAction(oFilter);

		return this.setValue('filterLoading', true)
			.then(() => this.executeAbortableAction(loadSearchSalesListAction, oFilter, 1, perPage, sortBy, sortDir))
			.then(() => this.setValue('filterLoading', false))
			.then(() => {
				if (!areAllObjectPropsEmpty(oFilter, true, false)) {
					scrollToSelector('#main-page-table', false, 80);
				}
			});
	}

	/**
	 * Remove main list filter
	 * @return {Promise<*>}
	 */
	removeMainListFilter() {
		return this.filterMainList(null);
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {
			mainListData, mainList, mainListPagination, mainListSort, mainListFilter, mainSidebarShrank,
			toggleMainSidebarSizeAction
		} = this.props;
		
		return this.renderLayout((
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')}`}>
				{
					this.hasTranslation('page_short_description') && this.t('page_short_description') ?
						<div className="simple-page-description">
							<Label content={this.t('page_short_description')} supportHtml={true} />
						</div>
						: null
				}

				<SimpleStaticSearch
					styleName="compact"
					className="main-search rows-7"
					collapsable={true}
					layout={SIMPLE_STATIC_SEARCH_LAYOUT.STACKED}
					buttonProps={{
						displayStyle: BUTTON_STYLE.DEFAULT
					}}
					options={[
						new SimpleStaticSearchOptionObject(
							'name',
							this.t('nameField'),
						),
					]}
					value={mainListFilter}
					title={(<Label icon="search" content={this.t('page_title')} />)}
					applied={mainListData !== null}
					enableToolbar={true}
					enableResetButton={false}
					onChange={this.filterMainList}
					onRemove={this.removeMainListFilter}
					ref={node => { this.mainListFilterRef = node; }}
				/>

				{mainListData !== null ?
					<DataTable
						id="main-page-table"
						className="standard"
						responsive={true}
						columns={[
							{
								name: 'id',
								label: this.t('idField'),
								width: 1,
							},
							{
								name: 'name',
								label: this.t('nameField'),
							},
						]}
						data={mainList}
						paginationType={PAGINATION_TYPE.DYNAMIC}
						onSortByColumn={this.sortMainList}
						onPaginationClick={this.loadMainListPage}
						{...mainListPagination}
						{...mainListSort}
					/>
					: this.getValue('filterLoading') ?
						<div className="page-notice-wrapper no-select">
							<div className="page-notice-title-icon icon">
								<Spinner size={60} weight={3} />
							</div>
						</div>
					:
						<div className="page-notice-wrapper no-select">
							<Icon symbol="hand-drawn-up" symbolPrefix="icofont-" className="page-notice-title-icon" />
							<Label
								element="p"
								elementProps={{className: 'page-notice-title bold'}}
								content={this.t('search_notice_title')}
							/>
							<Label
								element="p"
								elementProps={{className: 'page-notice'}}
								content={this.t('search_notice_description')}
							/>
						</div>
				}
			</div>
		), undefined, undefined, {
			app: appConfig,
			mainSidebarShrank,
			toggleMainSidebarSizeAction,
		});
	}
}

export * from "./config";
export default connect(mapStateToProps, getPageActions(actions))(SearchSalesPage);