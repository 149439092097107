import {get} from "lodash";
import {ioRequest} from "Core/io";
import {REQUEST_TYPE} from "Core/io/const";
import {getIOUrl} from "Core/io/helper";
import {hideLoading, showLoading} from "Core/helpers/loading";
import slugify from 'slugify';
import {getAppLocale} from 'Core/helpers/locale';

/**
 * Check if IO response was successful
 * @note Successful response should have 'success: true' field.
 * 
 * @param {any} responseData - Response to check.
 * @return {boolean}
 */
export const isSuccessful = responseData => (get(responseData, 'success') === true);

/**
 * Check if IO response was not successful
 * @note Successful response should have 'success: false' field.
 *
 * @param {any} responseData - Response to check.
 * @return {boolean}
 */
export const isNotSuccessful = responseData => (get(responseData, 'success') === false);

/**
 * Get IO response error code
 * @note This is an error code from an IO request that was received properly (200) but has a 'success: false'.
 *
 * @param {any} responseData - Response to check.
 * @return {string}
 */
export const getErrorCode = responseData => {
	if (!isSuccessful(responseData)) {
		return get(responseData, 'errorCode') ? get(responseData, 'errorCode') : '';
	} else return '';
};

/**
 * Get IO response error message
 * @note This is an error code from an IO request that was received properly (200) but has a 'success: false'.
 *
 * @param {any} responseData - Response to check.
 * @param {string} [defaultMessage=''] - Default message to return if error message from request does not exist or is 
 * empty.
 * @return {string}
 */
export const getErrorMessage = (responseData, defaultMessage = '') => {
	if (!isSuccessful(responseData)) {
		return get(responseData, 'errorMessage') ? get(responseData, 'errorMessage') : defaultMessage;
	} else return '';
};

/**
 * Download a file from IO
 * 
 * @param {string} endpoint - Request's API endpoint.
 * @param {any} [data=null] - Request body.
 * @param {string} [filename='file'] - Name of the downloaded file.
 * @param {string} [loadingSelector] - CSS selector of the DOM element that will have the loading overlay (usually the 
 * element that called this function like a download button).
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {string} [api='defaultAuthorizedApi'] - Request's API (see 'io_base_urls' io config value for available APIs).
 * @param {RequestType} [requestType='JSON'] - Type of the IO request to send.
 * @param {Object} [requestProps={}] - IO request props (@see 'ioRequest' function for reference).
 * @param {boolean} [processRequest=true] - If true, request will be processed by request processors.
 * @param {boolean} [processResponse=true] - If true, response will be processed by response processors. Otherwise, raw
 * fetch promise will be returned.
 * @return {Promise<void>}
 */
export const download = (
	endpoint, data = null, filename = 'file', loadingSelector = '', method = 'post', api = 'defaultAuthorizedApi',
	requestType = REQUEST_TYPE.JSON, requestProps = {}, processRequest = true, processResponse = true,
) => {
	const loading = (!!loadingSelector ? showLoading(loadingSelector) : null);
	return ioRequest({
		processRequest,
		processResponse,
		...requestProps,
		isDownload: true,
		downloadFilename: slugify(filename, {
			replacement: '_',
			locale: getAppLocale().code2,
		}),
		type: requestType,
		url: getIOUrl(api, endpoint),
		data,
		method,
	})
		.finally(() => hideLoading(loading));
};