import {hideLoadingFunction, showDialogLoading} from 'Core/helpers/loading';
import {ioJsonAction} from 'Core/store/actions/io';
import {get} from 'lodash';
import * as dataMap from "DataMap/patient";

/**
 * Create patient
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {PatientDataObject} patient - Patient to create.
 * @param {string} dialogId - ID of the dialog used for the loading overlay.
 * @return {function(*): Promise<Object>}
 */
export const createPatientAction = (abortCallback, patient, dialogId) => dispatch => {
	const loading = (dialogId ? showDialogLoading(dialogId) : null);
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'member/customer/create',
		{
			id: null,
			data: dataMap.output(patient),
			requestSavedData: true,
		},
		loading ? hideLoadingFunction(loading) : undefined,
	)(dispatch)
		// Return the newly created patient raw data from the response
		.then(res => get(res, 'data'));
};

/**
 * Update patient
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {PatientDataObject} patient - Patient to create.
 * @param {string} dialogId - ID of the dialog used for the loading overlay.
 * @return {function(*): Promise<Object>}
 */
export const updatePatientAction = (abortCallback, patient, dialogId) => dispatch => {
	const loading = (dialogId ? showDialogLoading(dialogId) : null);
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'member/customer/update-by-id',
		{
			id: patient.id,
			data: dataMap.output(patient),
			requestSavedData: true,
		},
		loading ? hideLoadingFunction(loading) : undefined,
	)(dispatch)
		// Return the updated patient raw data from the response
		.then(res => get(res, 'data'));
}