/** @typedef {string} FloatPopupPosition */
export const FLOAT_POPUP_POSITION_TOP_LEFT = 'top-left';
export const FLOAT_POPUP_POSITION_TOP_RIGHT = 'top-right';
export const FLOAT_POPUP_POSITION_BOTTOM_LEFT = 'bottom-left';
export const FLOAT_POPUP_POSITION_BOTTOM_RIGHT = 'bottom-right';
/** @enum {FloatPopupPosition} */
export const FLOAT_POPUP_POSITION = {
	TOP_LEFT: FLOAT_POPUP_POSITION_TOP_LEFT,
	TOP_RIGHT: FLOAT_POPUP_POSITION_TOP_RIGHT,
	BOTTOM_LEFT: FLOAT_POPUP_POSITION_BOTTOM_LEFT,
	BOTTOM_RIGHT: FLOAT_POPUP_POSITION_BOTTOM_RIGHT,
};
/** @type {FloatPopupPosition[]} */
export const FLOAT_POPUP_POSITIONS = [
	FLOAT_POPUP_POSITION_TOP_LEFT,
	FLOAT_POPUP_POSITION_TOP_RIGHT,
	FLOAT_POPUP_POSITION_BOTTOM_LEFT,
	FLOAT_POPUP_POSITION_BOTTOM_RIGHT,
];