import React from "react";
import DialogComponent, {DIALOG_TYPE} from "../../DialogComponent";
import PropTypes from "prop-types";
import Label from "../../display/Label";
import {MESSAGE_STYLE} from "../../global/Message/const";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "../../display/Button";
import {getBool, getString} from "Core/helpers/data";

/**
 * Message dialog component
 * @description This component is used to render messages if message has 'asDialog' set to true.
 */
class MessageDialog extends DialogComponent {
	constructor(props) {
		super(props, {
			translationPath: 'MessageDialog',
			alignContent: getString(props, 'alignContent', MessageDialog.defaultProps.alignContent, true),
			dialogType: DIALOG_TYPE.MESSAGE,
		});
	}

	/**
	 * Render dialog action buttons
	 * @note Low-level method to render any dialog action buttons.
	 *
	 * @param {DialogButton[]} buttons - Array of buttons to render.
	 * @param {string} [className=''] - Buttons wrapper additional CSS class.
	 * @return {*} Action buttons JSX to use in the main render method.
	 */
	renderActionButtons(buttons = [], className = '') {
		return (
			buttons.length > 0 ?
				<div className={`message-dialog-buttons ${className}`}>
					{buttons.map((button, index) =>
						<Button
							key={index}
							big={true}
							icon={button.icon}
							displayType={button.type ? button.type : BUTTON_DISPLAY_TYPE.SOLID}
							displayStyle={button.style ? button.style : BUTTON_STYLE.DEFAULT}
							onClick={button.onClick}
							disabled={getBool(button, 'disabled')}
						>
							{button.label ? button.label : null}
						</Button>
					)}
				</div>
				:
				null
		);
	}

	render() {
		// Do not render the dialog if message object is empty or not defined
		if (!this.props.message) return null;
		
		const {allowHtml, title, message} = this.props;
		const {style, content, customClass} = message;
		
		return this.renderDialog(
			(
				style === MESSAGE_STYLE.HELP ? this.renderHelpTitle(title) :
				style === MESSAGE_STYLE.INFO ? this.renderInfoTitle(title) :
				style === MESSAGE_STYLE.ERROR ? this.renderErrorTitle(title) :
				style === MESSAGE_STYLE.SUCCESS ? this.renderSuccessTitle(title) :
				style === MESSAGE_STYLE.WARNING ? this.renderWarningTitle(title) : 
				null
			),
			(
				<div className={`message-dialog-component ${customClass}`}>
					{
						this.hasTranslation('prefix', p => `${p}.${style}`) ?
							<Label 
								content={this.t('prefix', p => `${p}.${style}`)} 
								element="div" 
								elementProps={{className: `prefix`}} 
							/>
							: null
					}
					
					<Label 
						content={content}
						element="div" 
						elementProps={{className: `message-content`}} 
						supportHtml={allowHtml}
					/>

					{
						this.hasTranslation('suffix', p => `${p}.${style}`) ?
							<Label
								content={this.t('suffix', p => `${p}.${style}`)}
								element="div"
								elementProps={{className: `suffix`}}
							/>
							: null
					}
				</div>
			), this.t('Close')
		)
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
MessageDialog.propTypes = {
	// Unique GUI ID of the dialog
	// @note This is automatically sent by the global Dialog component.
	dialogGUIID: PropTypes.string,
	// Dialog options
	// @note This is automatically sent by the global Dialog component.
	dialogOptions: PropTypes.object,
	// Action used to close the dialog
	// @note This is automatically sent by the global Dialog component.
	dialogCloseAction: PropTypes.func,

	// Message title
	title: PropTypes.string,
	// Global message object
	message: PropTypes.object,
	// Set to true to support HTML in messages
	// @warning Be careful when using this flag because it can cause security issues. It uses 'dangerouslySetInnerHTML' 
	// to allow HTML content.
	allowHtml: PropTypes.bool,
	// CSS text-align value that will be used for dialog content
	// @type {'left'|'right'|'center'|'justify'|'initial'|'inherit'}
	alignContent: PropTypes.oneOf(['left', 'right', 'center', 'justify', 'initial', 'inherit']),
	
	// Events
	onClose: PropTypes.func
};

/**
 * Define component default values for own props
 */
MessageDialog.defaultProps = {
	message: null,
	allowHtml: false,
	alignContent: 'center',
};

export default MessageDialog;