import {ioJsonAction} from "Core/store/actions/io";
import {getArray} from "Core/helpers/data";
import * as physicianDataMap from "DataMap/physician";

/**
 * Quick search for physicians
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} query
 * @return {function(*): Promise<PhysicianDataObject[]>}
 */
export const physicianQuickSearchAction = (abortCallback, query) => dispatch => {
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'member/physician/search',
		{searchString: query},
	)(dispatch)
		.then(res => getArray(res, 'data').map(i => physicianDataMap.input(i)));
};