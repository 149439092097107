import {get} from "lodash";
import {getBoolean, getInteger, getObject, getString} from "Core/helpers/data";
import {SORT_ORDER} from "Core/const/global";

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'expertReports';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@expertReports/reset',
	SET_LIST_DATA: '@expertReports/set_list_data',
	SET_LIST_FILTER: '@expertReports/set_list_filter',
	CLEAR_LIST_DATA: '@expertReports/clear_list_data',
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),

	/**
	 * @param {IoJsonFetchResponseObject} listData
	 * @return {{type: string, listData: IoJsonFetchResponseObject}}
	 */
	setPatientRecordExpertReportsListData: listData => ({
		type: REDUCER_TYPES.SET_LIST_DATA,
		listData: listData
	}),
	setPatientRecordExpertReportsListFilter: listFilter => ({
		type: REDUCER_TYPES.SET_LIST_FILTER,
		filter: listFilter
	}),
	clearPatientRecordExpertReportsListData: () => ({type: REDUCER_TYPES.CLEAR_LIST_DATA}),
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	/** @type {IoJsonFetchResponseObject} */
	listData: null,
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET: return { ...initialState };

		case REDUCER_TYPES.SET_LIST_DATA: return {...state, listData: action.listData};
		case REDUCER_TYPES.SET_LIST_FILTER: return {
			...state,
			listData: {...getObject(state.listData), filter: action.filter}
		};
		case REDUCER_TYPES.CLEAR_LIST_DATA: return {...state, listData: initialState.listData};

		default: return state;
	}
};

// Selectors
export const selectors = {
	getPatientRecordExpertReportsListData: state => get(state, [reducerStoreKey, 'listData']),
	getPatientRecordExpertReportsList: state => get(state, [reducerStoreKey, 'listData', 'data']),
	getPatientRecordExpertReportsListPagination: state => ({
		perPage: getInteger(state, [reducerStoreKey, 'listData', 'perPage'], 10),
		pageNo: getInteger(state, [reducerStoreKey, 'listData', 'pageNo'], 1),
		totalRows: getInteger(state, [reducerStoreKey, 'listData', 'totalResults']),
		isLastPage: getBoolean(state, [reducerStoreKey, 'listData', 'isLastPage'])
	}),
	getPatientRecordExpertReportsListSort: state => ({
		sortBy: getString(state, [reducerStoreKey, 'listData', 'sortBy']),
		sortDir: getString(state, [reducerStoreKey, 'listData', 'sortDir'], SORT_ORDER.DESC)
	}),
	getPatientRecordExpertReportsListFilter: state => get(state, [reducerStoreKey, 'listData', 'filter'], null),
};

export default reducer;