import {get, find, filter, reject} from "lodash";

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'overlay';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@overlay/reset',
	ADD_OVERLAY: '@overlay/add_overlay',
	REMOVE_OVERLAY: '@overlay/remove_overlay',
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),
	/**
	 * Add overlay to the list of overlays
	 * @param {OverlayObject} overlay
	 * @return {{type: string, overlay: OverlayObject}}
	 */
	addOverlay: overlay => ({type: REDUCER_TYPES.ADD_OVERLAY, overlay}),
	/**
	 * Remove overlay from the list of overlays
	 * @param {string} GUIID - Overlay GUI ID.
	 * @return {{type: string, GUIID: string}}
	 */
	removeOverlay: GUIID => ({type: REDUCER_TYPES.REMOVE_OVERLAY, GUIID}),
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	/**
	 * List of all overlays
	 * @type {OverlayObject[]}
	 */
	overlays: [],
};

// Helper functions
/**
 * Get the number of overlays for the specific selector
 * 
 * @param {string} selector - CSS selector for target elements.
 * @param {OverlayObject[]} overlays - List of all overlays.
 * @return {number}
 */
const getSelectorCount = (selector, overlays) => filter(overlays, {selector}).length;

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET:
			return {...initialState};
		
		case REDUCER_TYPES.ADD_OVERLAY:
			return {
				...state,
				overlays: [...state.overlays, action.overlay]
			};
			
		case REDUCER_TYPES.REMOVE_OVERLAY:
			if (action.GUIID) {
				const overlay = find(state.overlays, {GUIID: action.GUIID});
				if (overlay) {
					return {
						...state,
						overlays: reject(state.overlays, {GUIID: overlay.GUIID})
					};
				} else {
					console.log('%cTrying to remove an overlay that does not exist.', 'color: orange', overlay);
					return state;
				}
			} else {
				return state;
			}

		default:
			return state;
	}
};

// Selectors
export const selectors = {
	getOverlays: state => reject(get(state, [reducerStoreKey, 'overlays'], []), i => i.count<1),
	getOverlayCountForSelector: (state, selector) => {
		return getSelectorCount(selector, get(state, [reducerStoreKey, 'overlays'], []));
	}
};

export default reducer;