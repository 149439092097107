import style from "./index.module.css";

import React from "react";
import BaseComponent from "../../BaseComponent";
import {connect} from "react-redux";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {get, map} from "lodash";
import {getArray, getBoolean} from "Core/helpers/data";
import {waitingFunction} from "Core/helpers/function";
import {selectors} from "../../../store/reducers";
import Message from "../Message";
import {openDialog} from "Core/helpers/dialog";
import MessageDialog from "Core/components/dialogs/MessageDialog";
import {message_dialog_default_width} from "Config/app";
import {hideMessage} from "Core/helpers/message";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	messages: selectors.message.getMessages(state)
});

class Messages extends BaseComponent {
	constructor(props) {
		super(props, {});

		// CSS transition methods
		this.handleTransitionEnter = this.handleTransitionEnter.bind(this);
		this.handleTransitionExited = this.handleTransitionExited.bind(this);
		
		// Render methods
		this.renderMessage = this.renderMessage.bind(this);
	}

	/**
	 * Handle CSS transition 'onEnter' callback
	 */
	handleTransitionEnter() {
		waitingFunction(() => {
			const componentNode = document.getElementById(`messages-${this.getId()}`);
			if (
				componentNode && componentNode.hasChildNodes() && 
				componentNode.classList.contains('messages-component-empty')
			) {
				componentNode.classList.remove('messages-component-empty', style['empty']);
				componentNode.classList.add('messages-component', style['messages']);
				return true;
			}
			return false;
		}, 10, 100).then();
	}

	/**
	 * Handle CSS transition 'onExited' callback
	 */
	handleTransitionExited() {
		waitingFunction(() => {
			const componentNode = document.getElementById(`messages-${this.getId()}`);
			if (componentNode && !componentNode.hasChildNodes() && componentNode.classList.contains('messages-component')){
				componentNode.classList.remove('messages-component', style['messages']);
				componentNode.classList.add('messages-component-empty', style['empty']);
				return true;
			}
			return false;
		}, 10, 200).then();
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	renderMessage(message) {
		const asDialog = getBoolean(message, 'asDialog');
		
		if (asDialog) {
			// Timeout added to avoid 'Cannot update a component (xxx) while rendering a different component (xxx)' warning
			// @note This warning was added in React v16.3.0, but it is not relevant here, so it should be removed.
			// @link https://reactjs.org/blog/2020/02/26/react-v16.13.0.html#warnings-for-some-updates-during-render
			setTimeout(() => {
				openDialog(message.GUIID, MessageDialog, {
					message,
					onClose: () => {
						if (!getBoolean(message, 'canClose')) return false;
						else hideMessage(message.GUIID);
					},
					
					...get(message, 'dialogProps', {})
				}, {
					id: `message-dialog-${message.GUIID}`,
					className: `message-dialog`,
					closeOnClickOutside: getBoolean(message, 'canClose'),
					closeOnEscape: getBoolean(message, 'canClose'),
					hideCloseBtn: !getBoolean(message, 'canClose'),
					maxWidth: message_dialog_default_width,

					...get(message, 'dialogOptions', {})
				});
			});
			return null;
		} else {
			return (
				<CSSTransition
					key={message.GUIID}
					timeout={{appear: 0, enter: 0, exit: 100}}
					classNames="message-transitions"
					nodeRef={message.nodeRef}
					onEnter={this.handleTransitionEnter}
					onExited={this.handleTransitionExited}
				>
					<Message
						data={message}
						forwardedRef={message.nodeRef}
					/>
				</CSSTransition>
			);
		}
	}
	
	render() {
		/** @type MessageData[] */
		let messages = getArray(this.props, 'messages');
		
		// Add 'nodeRef' props to each message
		// @note This is done so that CSSTransition component will work properly.
		messages = map(messages, message => ({...message, nodeRef: React.createRef()}));
		
		return (
			<TransitionGroup
				id={`messages-${this.getId()}`}
				className={(`messages-component-empty ${style['empty']}`)}
			>
				{messages.map(message => this.renderMessage(message))}
			</TransitionGroup>
		);
	}
}

export default connect(mapStateToProps, null)(Messages);