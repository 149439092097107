import {hideLoadingFunction, showDialogLoading} from "Core/helpers/loading";
import {ioJsonAction, ioJsonSaveItemAction} from "Core/store/actions/io";
import * as dataMap from "DataMap/appointment/anonymous";
import {omit} from "lodash";

/**
 * Create an anonymous appointment
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {AnonymousAppointmentDataObject} anonymousAppointment
 * @return {function(*): Promise<IoJsonResponseObject|undefined>}
 */
export const createAnonymousAppointmentAction = (abortCallback, anonymousAppointment) => dispatch => {
	const loading = showDialogLoading('create-anonymous-appointment-dialog');
	return ioJsonAction(
		undefined,
		'defaultAuthorizedApi',
		'member/anonymous-appointment/schedule',
		dataMap.output(anonymousAppointment),
		hideLoadingFunction(loading),
	)(dispatch);
}

/**
 * Update an existing anonymous appointment
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {AnonymousAppointmentDataObject} anonymousAppointment
 * @return {function(*): Promise<IoJsonResponseObject|undefined>}
 */
export const updateAnonymousAppointmentAction = (abortCallback, anonymousAppointment) => dispatch => {
	const loading = showDialogLoading('update-anonymous-appointment-dialog');
	return ioJsonSaveItemAction(
		abortCallback,
		'defaultAuthorizedApi',
		'member/anonymous-appointment/update-by-id',
		anonymousAppointment.id,
		{data: omit(dataMap.output(anonymousAppointment), ['id'])},
		undefined,
		false,
		hideLoadingFunction(loading),
	)(dispatch);
};

/**
 * Personalize an anonymous appointment
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {AnonymousAppointmentDataObject} anonymousAppointment
 * @param {string} patientId - DB ID of the patient to use for personalization.
 * @param {string} [therapyId] - DB ID of the therapy to include in the personalized appointment.
 * @return {function(*): Promise<IoJsonResponseObject|undefined>}
 */
export const personalizeAnonymousAppointmentAction = (
	abortCallback, anonymousAppointment, patientId, therapyId
) => dispatch => {
	const loading = showDialogLoading('update-anonymous-appointment-dialog');
	return ioJsonSaveItemAction(
		abortCallback,
		'defaultAuthorizedApi',
		'member/appointments/personalize',
		anonymousAppointment.id,
		{data: {
			...omit(dataMap.output(anonymousAppointment), ['id']), 
				customerId: patientId, 
				therapyId: !!therapyId ? therapyId : null,
		}},
		undefined,
		false,
		hideLoadingFunction(loading),
	)(dispatch);
}

/**
 * Delete an anonymous appointment
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - DB ID of the anonymous appointment
 * @return {function(*): Promise<IoJsonResponseObject|undefined>}
 */
export const deleteAnonymousAppointmentAction = (abortCallback, id) => dispatch => {
	const loading = showDialogLoading('update-anonymous-appointment-dialog');
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'member/anonymous-appointment/delete-by-id',
		{id},
		hideLoadingFunction(loading),
	)(dispatch);
};