import * as applicationConfig from "../config";
import Icon from "Core/components/display/Icon";
import {rtrimChar} from "Core/helpers/string";
import {icon_font_symbol_class_prefix} from "Config/app";
import {AclCheckDataObject} from "Core/acl";
import {getArray} from "Core/helpers/data";

/**
 * Rendered icon element used as a page icon
 * @description Page icons can be used in main navigation, page links and buttons or any other place.
 * @type {JSX.Element}
 */
export const iconElement = <Icon 
	symbol="nurse-alt" 
	symbolPrefix={`${'icofont-' ? 'icofont-' : icon_font_symbol_class_prefix}`} 
/>

/**
 * Access control settings
 * @type {AclCheckDataObject}
 */
export const access = new AclCheckDataObject(
	[...getArray(applicationConfig, 'access.permissions'), ],
	[...getArray(applicationConfig, 'access.userTypes'), ],
	undefined
);

/**
 * Page router path relative to the base path of the app
 * @note Use '/' at the beginning (for example '/home').
 * @type {string}
 */
export const routerPath = `${rtrimChar(applicationConfig.routerPath, '/')}/therapistlist`;
/**
 * Page router options used for router matching
 * @type {{[exact]: boolean, [strict]: boolean, [sensitive]: boolean}}
 */
export const routerOptions = {};
/**
 * Page router options used for router matching in main sidebar menu
 * @type {{[exact]: boolean, [strict]: boolean, [sensitive]: boolean}}
 */
export const menuRouterOptions = {};

/**
 * Page translation path
 * @type {string}
 */
export const translationPath = `${applicationConfig.translationPath}.TherapistsPage`;