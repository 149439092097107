/** @typedef {string} NumberLabelIconPosition */
export const NUMBER_LABEL_ICON_POSITION_NONE = '';
export const NUMBER_LABEL_ICON_POSITION_LEFT = 'left';
export const NUMBER_LABEL_ICON_POSITION_RIGHT = 'right';
/** @enum {NumberLabelIconPosition} */
export const NUMBER_LABEL_ICON_POSITION = {
	NONE: NUMBER_LABEL_ICON_POSITION_NONE,
	LEFT: NUMBER_LABEL_ICON_POSITION_LEFT,
	RIGHT: NUMBER_LABEL_ICON_POSITION_RIGHT
};
/** @type {NumberLabelIconPosition[]} */
export const NUMBER_LABEL_ICON_POSITIONS = [
	NUMBER_LABEL_ICON_POSITION_NONE,
	NUMBER_LABEL_ICON_POSITION_LEFT,
	NUMBER_LABEL_ICON_POSITION_RIGHT
];