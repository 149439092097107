import React from 'react';
import BaseComponent, {executeComponentCallback} from 'Core/components/BaseComponent';
import {connect} from 'react-redux';
import SelectInput from 'Core/components/input/SelectInput';
import {getGlobalActions} from 'Core/helpers/redux';
import {fetchTherapistCodebookAction} from '../../../store/actions/codebooks';
import {omit} from 'lodash';
import PropTypes from "prop-types";
import {getArray} from "Core/helpers/data";

/**
 * Get all actions used by this component
 * @type {Object}
 */
const allActions = getGlobalActions({fetchTherapistCodebookAction});

class TherapistSelectInput extends BaseComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			/** @type {CodebookItem[]} */
			options: [],
		}
	}
	
	/** @inheritDoc */
	async asyncComponentDidMount() {
		await super.asyncComponentDidMount();
		
		const {fetchTherapistCodebookAction} = this.props;
		
		// Load options
		await this.executeAbortableActionMount(fetchTherapistCodebookAction)
			.then(options => this.setState({options}))
			// Select option if it is the only one
			.then(() => {
				const {selectOnlyOptionOnLoad} = this.props;
				const options = getArray(this.state, 'options');

				if (selectOnlyOptionOnLoad && options.length === 1) {
					executeComponentCallback(this.props.onChange, options[0].id);
				}
			});
	}
	
	render() {
		const {options} = this.state;
		
		return (
			<SelectInput
				{...omit(this.props, [...Object.keys(allActions)])}
				primaryKey="id"
				options={options}
				getOptionValue={o => o.id}
				getOptionLabel={o => o.name}
			/>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
TherapistSelectInput.propTypes = {
	...SelectInput.propTypes,
};

/**
 * Define component default values for own props
 */
TherapistSelectInput.defaultProps = {
	...SelectInput.defaultProps,

	// Flag that specifies if option will be selected if it is the only one after options are loaded
	selectOnlyOptionOnLoad: PropTypes.bool,
};

export default connect(null, allActions)(TherapistSelectInput);