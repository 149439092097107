export class ExpertReportDataObject {
	/**
	 * @param {?string} [id=null]
	 * @param {?string} [patientId=null]
	 * @param {?string} [therapyId=null]
	 * @param {Date} [date=null]
	 * @param {string} [title='']
	 * @param {string} [content='']
	 */
	constructor(id = null, patientId = null, therapyId = null, date = null, title = '', content = '') {
		this.id = id;
		this.patientId = patientId;
		this.therapyId = therapyId;
		this.date = date;
		this.title = title;
		this.content = content;
	}
}