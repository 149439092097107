import {autosuggestAbstractAction} from "./abstract";

/**
 * Fetch the list of last names that match the specified query string
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} [query=''] - Query string.
 * @return {function(*): Promise<string[]>}
 */
export const lastNameAutosuggestAction = (abortCallback, query = '') => dispatch => {
	return autosuggestAbstractAction(abortCallback, 'member/customer/suggest-last-name', query)(dispatch);
}