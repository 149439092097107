/**
 * Function that will be executed every 'delay' milliseconds until it returns true or a 'limit' is exceeded.
 * 
 * @param {Function} handler - A function to be executed.
 * @param {number} [interval=0] - The time, in milliseconds, the timer should delay in between executions of the 
 * specified function.
 * @param {number} [limit=1] - Maximum number of times 'handler' function will be executed.
 * @param {any} [args] - Additional arguments which are passed through to the function specified.
 * @return {Promise<void>} Promise that will resolve with no value when interval is done either by reaching the limit or
 * when 'handler' function returns true.
 */
export const waitingFunction = (handler, interval = 0, limit = 1, ...args) => {
	return new Promise(resolve => {
		let intervalCount = -1;
		const i = setInterval(() => {
			intervalCount++;
			// Clear the interval after limit is exceeded
			if (intervalCount >= limit) {
				clearInterval(i);
				resolve();
			}
			// Call 'handler' function and clear interval if it returns true.
			else if (handler(...args) === true) {
				clearInterval(i);
				resolve();
			}
		}, interval);
	});
};

/**
 * Function that will be executed every 'delay' milliseconds until it returns true, a 'limit' is exceeded or it is 
 * canceled manually by calling 'clearInterval' on 'intervalHandle'.
 *
 * @param {function(id: number)} callback - Callback function called on each execution with interval ID as its only 
 * argument.
 * @param {Function} handler - A function to be executed.
 * @param {number} [interval=0] - The time, in milliseconds, the timer should delay in between executions of the
 * specified function.
 * @param {number} [limit=1] - Maximum number of times 'handler' function will be executed.
 * @param {any} [args] - Additional arguments which are passed through to the function specified.
 * @return {Promise<void>} Promise that will resolve with no value when interval is done either by reaching the limit, 
 * when 'handler' function returns true or interval is canceled manually using 'interval.id'.
 */
export const waitingFunctionCallback = (callback, handler, interval = 0, limit = 1, ...args) => {
	return new Promise(resolve => {
		let intervalCount = -1;
		const i = setInterval(() => {
			callback(i);
			
			intervalCount++;
			// Clear the interval after limit is exceeded
			if (intervalCount >= limit) {
				clearInterval(i);
				resolve();
			}
			// Call 'handler' function and clear interval if it returns true.
			else if (handler(...args) === true) {
				clearInterval(i);
				resolve();
			}
		}, interval);
	});
};