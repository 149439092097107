export class DiagnosisDataObject {
	/**
	 * @param {?string} [id=null] 
	 * @param {string} [code=''] 
	 * @param {string} [description='']
	 * @param {string} [lang='']
	 */
	constructor(id = null, code = '', description = '', lang = '') {
		this.id = id;
		this.code = code;
		this.description = description;
		this.lang = lang;
	}
}