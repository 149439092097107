export class PhysicianDataObject {
	/**
	 * @param {string|null} [id=null] - Item's ID.
	 * @param {string} [title=''] - Physicians title.
	 * @param {string} [firstName=''] - Physicians first name
	 * @param {string} [lastName=''] - Physicians last name.
	 * @param {string} [telephone=''] - Physicians landline telephone number.
	 * @param {string} [mobilePhone=''] - Physicians mobile phone number.
	 * @param {string} [email=''] - Physicians email.
	 * @param {string} [address=''] - Physicians address.
	 * @param {string} [institution=''] - Physicians institution.
	 */
	constructor(
		id = null, title = '', firstName = '', lastName = '', telephone = '', mobilePhone = '', email = '', address = '', 
		institution = ''
	) {
		this.id = id;
		this.title = title;
		this.firstName = firstName;
		this.lastName = lastName;
		this.telephone = telephone;
		this.mobilePhone = mobilePhone;
		this.email = email;
		this.address = address;
		this.institution = institution;
	}
}