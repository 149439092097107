import {get} from "lodash";
import {getBoolean, getInteger, getObject, getString} from "Core/helpers/data";
import {SORT_ORDER} from "Core/const/global";

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'procurements';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@procurements/reset',
	SET_LIST_DATA: '@procurements/set_list_data',
	SET_LIST_FILTER: '@procurements/set_list_filter',
	CLEAR_LIST_DATA: '@procurements/clear_list_data',
	SET_ITEM: '@procurements/set_item',
	CLEAR_ITEM: '@procurements/clear_item',
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),
	/**
	 * @param {IoJsonFetchResponseObject} procurementsListData
	 * @return {{type: string, listData: IoJsonFetchResponseObject}}
	 */
	
	setProcurementsListData: procurementsListData => ({
		type: REDUCER_TYPES.SET_LIST_DATA, 
		listData: procurementsListData
	}),
	setProcurementsListFilter: procurementsListFilter => ({
   		type: REDUCER_TYPES.SET_LIST_FILTER, 
   		filter: procurementsListFilter
   	}),
	clearProcurementsListData: () => ({type: REDUCER_TYPES.CLEAR_LIST_DATA}),
	
	/**
	 * @param {ProcurementsItemDataObject} procurementsItem
	 * @return {{type: string, item: ProcurementsItemDataObject}}
	 */
	setProcurementsItem: procurementsItem => ({
		type: REDUCER_TYPES.SET_ITEM, 
		item: procurementsItem
	}),
	clearProcurementsItem: () => ({type: REDUCER_TYPES.CLEAR_ITEM}),
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	/** @type {IoJsonFetchResponseObject} */
	listData: null,

	/**
	 * @note Value is null to differentiate between unloaded and new items (new items should be a data object with
	 * default values).
	 * @type {ProcurementsItemDataObject}
	 */
	item: null,
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET: return { ...initialState };

		case REDUCER_TYPES.SET_LIST_DATA: return {...state, listData: action.listData};
		case REDUCER_TYPES.SET_LIST_FILTER: return {
			...state, 
			listData: {...getObject(state.listData), filter: action.filter}
		};
		case REDUCER_TYPES.CLEAR_LIST_DATA: return {...state, listData: initialState.listData};

		case REDUCER_TYPES.SET_ITEM: return {...state, item: action.item};
		case REDUCER_TYPES.CLEAR_ITEM: return {...state, item: initialState.item};

		default: return state;
	}
};

// Selectors
export const selectors = {
	getProcurementsList: state => get(state, [reducerStoreKey, 'listData', 'data']),
	getProcurementsListPagination: state => ({
		perPage: getInteger(state, [reducerStoreKey, 'listData', 'perPage']),
		pageNo: getInteger(state, [reducerStoreKey, 'listData', 'pageNo']),
		totalRows: getInteger(state, [reducerStoreKey, 'listData', 'totalResults']),
		isLastPage: getBoolean(state, [reducerStoreKey, 'listData', 'isLastPage'])
	}),
	getProcurementsListSort: state => ({
		sortBy: getString(state, [reducerStoreKey, 'listData', 'sortBy']),
		sortDir: getString(state, [reducerStoreKey, 'listData', 'sortDir'], SORT_ORDER.ASC)
	}),
	getProcurementsListFilter: state => get(state, [reducerStoreKey, 'listData', 'filter'], null),

	getProcurementsItem: state => get(state, [reducerStoreKey, 'item']),
};

export default reducer;