// Storage types
export const STORAGE_TYPE_COOKIE = 'COOKIE';
export const STORAGE_TYPE_SESSION = 'SESSION_STORAGE';
export const STORAGE_TYPE_LOCAL = 'LOCAL_STORAGE';
export const STORAGE_TYPE_MEMORY = 'MEMORY';
export const STORAGE_TYPE_REDUX = 'REDUX';
export const STORAGE_TYPE = {
	COOKIE: STORAGE_TYPE_COOKIE,
	SESSION: STORAGE_TYPE_SESSION,
	LOCAL: STORAGE_TYPE_LOCAL,
	MEMORY: STORAGE_TYPE_MEMORY,
	REDUX: STORAGE_TYPE_REDUX
};