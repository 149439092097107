import React from 'react';
import DialogDataComponent, {executeComponentCallback} from 'Core/components/DialogDataComponent';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getPageActions} from 'Core/helpers/redux';
import * as actions from './actions';
import FormWrapper, {FormField} from 'Core/components/advanced/FormWrapper';
import TextInput from 'Core/components/input/TextInput';
import DateInput from 'Core/components/input/DateInput';
import SelectInput from 'Core/components/input/SelectInput';
import DataValueValidation from 'Core/validation';
import {focusSelector} from 'Core/helpers/dom';
import {PatientDataObject} from "DataObjects/patient";
import TextareaInput from "Core/components/input/TextareaInput";
import {FORM_FIELD_LABEL_POSITION} from "Core/components/advanced/FormWrapper/FormField";
import RichTextInput from "Core/components/input/RichTextInput";
import {getString} from "Core/helpers/data";

class PatientDialog extends DialogDataComponent {
	constructor(props) {
		super(props, {
			data: props.isNew ? (!!props.defaultData ? props.defaultData : new PatientDataObject()) : null,
			
			recommendationCodebook: [],
			recommendationCodebookLoading: false,
		}, {
			translationPath: 'PatientDialog',
			disableLoad: props.isNew,
			optimizedUpdate: true,
			optimizedUpdateIgnoreProps: ['onClose', 'onSave'],
		});
	}

	/** @inheritDoc */
	async asyncComponentDidMount(override = false) {
		// Call the parent component's 'asyncComponentDidMount' method that handles core functionality
		await super.asyncComponentDidMount(override);

		const {fetchRecommendationCodebookAction} = this.props;

		// Set focus to the first input element
		focusSelector('#patient-dialog-first-input');
		
		// Load recommendation codebook
		await this.setState({recommendationCodebookLoading: true})
			.then(() => this.executeAbortableActionMount(fetchRecommendationCodebookAction))
			.then(recommendationCodebook => this.setState({
				recommendationCodebook,
				recommendationCodebookLoading: false
			}));

		return Promise.resolve();
	}

	/** @inheritDoc */
	validate() {
		const dataValidation = new DataValueValidation();
		const dataToValidate = this.getData();

		dataValidation.addRule('firstName', 'required');
		dataValidation.addRule('lastName', 'required');

		// Run validation
		const validationErrors = dataValidation.run(dataToValidate);

		if (validationErrors) this.setValidationErrors('', validationErrors).then();
		else this.clearValidationErrors().then();
		return !validationErrors;
	}

	/**
	 * Save dialog method
	 * @note This method should be called when dialog's "save" button is clicked. This method does not actually save any
	 * data to the DB or anywhere else. That should be done by the parent component.
	 */
	save() {
		const {isNew, dialogId, dialogGUIID, createPatientAction, updatePatientAction} = this.props;
		const data = this.getDataToReturn();

		// Do the validation
		const isValid = this.validate();

		// If validation is successful
		if (isValid) {
			const action = (isNew ? createPatientAction : updatePatientAction);
			this.executeAbortableAction(action, data, dialogId)
				// Trigger component's onSave event if patient was saved successfully
				.then(rawData => { if (rawData) executeComponentCallback(this.props.onSave, rawData, dialogGUIID); });
		}
	}

	render() {
		const {
			isNew, title, firstNameAutosuggestAction, lastNameAutosuggestAction, middleNameAutosuggestAction
		} = this.props;
		const {recommendationCodebook, recommendationCodebookLoading} = this.state;
		
		return this.renderDialog(
			this.renderTitle(isNew ? this.t('title_create') : (title ? title : this.t('title'))),
			(
				<FormWrapper className="dialog-form">
					<FormField label={this.t('firstNameLabel')} errorMessages={this.getValidationErrors('firstName')}>
						<TextInput
							inputProps={{id: 'patient-dialog-first-input'}}
							name="firstName" 
							value={this.getValue('firstName')} 
							onChange={this.handleInputChange} 
							autosuggestAction={firstNameAutosuggestAction}
						/>
					</FormField>
					
					<FormField label={this.t('lastNameLabel')} errorMessages={this.getValidationErrors('lastName')}>
						<TextInput 
							name="lastName" 
							value={this.getValue('lastName')} 
							onChange={this.handleInputChange}
							autosuggestAction={lastNameAutosuggestAction}
						/>
					</FormField>
					
					<FormField label={this.t('middleNameLabel')} errorMessages={this.getValidationErrors('middleName')}>
						<TextInput 
							name="middleName" 
							value={this.getValue('middleName')} 
							onChange={this.handleInputChange}
							autosuggestAction={middleNameAutosuggestAction}
						/>
					</FormField>
					
					<FormField label={this.t('birthDateLabel')} errorMessages={this.getValidationErrors('birthDate')}>
						<DateInput
							preventTab={true}
							value={this.getValue('birthDate')} 
							onChange={v => this.handleValueChange('birthDate',v)} 
						/>
					</FormField>
					
					<FormField label={this.t('telephoneLabel')} errorMessages={this.getValidationErrors('telephone')}>
						<TextInput name="telephone" value={this.getValue('telephone')} onChange={this.handleInputChange} />
					</FormField>
					
					<FormField label={this.t('mobilePhoneLabel')} errorMessages={this.getValidationErrors('mobilePhone')}>
						<TextInput name="mobilePhone" value={this.getValue('mobilePhone')} onChange={this.handleInputChange}/>
					</FormField>

					<FormField label={this.t('emailLabel')} errorMessages={this.getValidationErrors('email')}>
						<TextInput name="email" value={this.getValue('email')} onChange={this.handleInputChange}/>
					</FormField>
					
					<FormField label={this.t('addressLabel')} errorMessages={this.getValidationErrors('address')}>
						<TextInput name="address" value={this.getValue('address')} onChange={this.handleInputChange} />
					</FormField>
					
					<FormField 
						label={this.t('recommendationNameLabel')}
						errorMessages={this.getValidationErrors('recommendationId')}
					>
						<SelectInput
							primaryKey="id"
							options={recommendationCodebook}
							isLoading={recommendationCodebookLoading}
							value={this.getValue('recommendationId')}
							getOptionLabel={o => o.name}
							getOptionValue={o => o.id}
							onChange={v => this.handleValueChange('recommendationId',v)}
						/>
					</FormField>
					<FormField
						required={false}
						className="multiline-form-field content-field"
						inputClassName="full"
						label={this.t('anamnesisLabel')}
						labelPosition={FORM_FIELD_LABEL_POSITION.ALIGNED}
						errorMessages={this.getValidationErrors('anamnesis')}
					>
						<RichTextInput
							value={this.getValue('anamnesis')}
							bounds={`#${getString(this.props, 'dialogOptions.id')} .dialog-content-component`}
							toolbar={[
								['undo', 'redo'],
								[{size: ["0.875em", false, "1.5em", "2.5em"]}],
								['bold', 'italic', 'underline', 'strike'],
								[{'background': []}, {'color': []}],
								[{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
								[{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
								['clean'],
								['print'],
							]}
							onChange={v => this.handleValueChange('anamnesis', v)}
						/>
					</FormField>
				</FormWrapper>
			)
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
PatientDialog.propTypes = {
	// Unique GUI ID of the dialog
	// @note This is automatically sent by the global Dialog component.
	dialogGUIID: PropTypes.string,
	// Dialog options
	// @note This is automatically sent by the global Dialog component.
	dialogOptions: PropTypes.object,
	// Action used to close the dialog
	// @note This is automatically sent by the global Dialog component.
	dialogCloseAction: PropTypes.func,

	// Flag that specifies if dialog is opened for a new (create dialog) or an existing item (edit dialog). If true
	// dialog is opened for a new item. 
	isNew: PropTypes.bool,
	// Dialog title
	title: PropTypes.string,
	// Patient record data
	// @type {PatientRecordDataObject}
	data: PropTypes.object,
	// Default patient data used to preset values when creating a new patient
	// @note This is used only if 'isNew' is true.
	// @type {PatientDataObject}
	defaultData: PropTypes.object,
	// ID of the dialog
	dialogId: PropTypes.string,

	// Events
	onClose: PropTypes.func,
	// @param {Object} rawData - Create data or updated raw data.
	// @param {string} dialogGUIID
	onSave: PropTypes.func,
};

export default connect(null, getPageActions(actions))(PatientDialog);