import {get} from "lodash";

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'patientQuickSearch';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@patientQuickSearch/reset',
	SET: '@patientQuickSearch/set',
	CLEAR: '@patientQuickSearch/clear',
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),
	/**
	 * @param {PatientSelectOptionDataObject} patient
	 * @return {{patient: {PatientSelectOptionDataObject}, type: string}}
	 */
	set: patient => ({type: REDUCER_TYPES.SET, patient}),
	clear: () => ({type: REDUCER_TYPES.CLEAR}),
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	/** @type {PatientSelectOptionDataObject|null|undefined} */
	patient: null,
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET: return { ...initialState };

		case REDUCER_TYPES.SET: return { ...state, patient: action.patient };
		case REDUCER_TYPES.CLEAR: return { ...state, patient: initialState.patient };

		default: return state;
	}
};

// Selectors
export const selectors = {
	/**
	 * @param {Object} state
	 * @return {PatientSelectOptionDataObject|null|undefined}
	 */
	getSelectedPatient: state => get(state, [reducerStoreKey, 'patient']),
};

export default reducer;