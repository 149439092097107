import patientRecordStyles from '../../index.module.css';
import styles from './index.module.css';
import "./index.css";

import React from 'react';
import BaseComponent, {executeComponentCallback} from 'Core/components/BaseComponent';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getGlobalActions} from 'Core/helpers/redux';
import {selectors} from '../../reducer';
import * as actions from './actions';
import Label from 'Core/components/display/Label';
import PatientTherapySelectInput from "Components/input/PatientTherapySelectInput";
import {get} from 'lodash';
import {getNumber, getString, getStringForDisplay, isset, trimArray} from 'Core/helpers/data';
import NumberLabel from 'Core/components/display/NumberLabel';
import DateLabel from 'Core/components/display/DateLabel';
import {LOCALE_DATE_FORMAT_NAME} from 'Core/const/locale';
import {icon_font_delete_symbol, icon_font_edit_symbol, icon_font_error_symbol} from 'Config/app';
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from 'Core/components/display/Button';
import {ASYNC_SELECT_INPUT_TOOLBAR_POSITION} from 'Core/components/input/SelectAsyncInput/const';
import TherapyDialog from "Components/dialogs/TherapyDialog";
import ConfirmDialog from "Core/components/dialogs/ConfirmDialog";
import {isSuccessful} from "Core/helpers/io";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	patientRecord: selectors.getPatientRecord(state),
	selectedPatientTherapy: selectors.getSelectedPatientTherapy(state),
	patientTherapy: selectors.getPatientTherapy(state),
});

class Therapy extends BaseComponent {
	// Refs
	patientTherapySelectRef = null;
	
	constructor(props) {
		super(props, {
			translationPath: 'Therapy',
			domPrefix: 'therapy-component'
		});
		
		// Data methods
		this.reloadPatientTherapyList = this.reloadPatientTherapyList.bind(this);

		// Dialog methods
		this.openEditTherapyDialog = this.openEditTherapyDialog.bind(this);
		this.openDeleteTherapyDialog = this.openDeleteTherapyDialog.bind(this);
		
		// Action method
		this.selectTherapyById = this.selectTherapyById.bind(this);
		
		// Render methods
		this.renderStringInfo = this.renderStringInfo.bind(this);
		this.renderNumberInfo = this.renderNumberInfo.bind(this);
		this.renderDateInfo = this.renderDateInfo.bind(this);
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		const {selectedPatientTherapy, loadPatientTherapyAction, clearPatientTherapyAction} = this.props;
		const selectedPatientTherapyId = getString(selectedPatientTherapy, 'id');
		const prevSelectedPatientTherapyId = getString(prevProps, 'selectedPatientTherapy.id');
		
		// Load / clear patient therapy when selected patient therapy changes
		if (selectedPatientTherapyId !== prevSelectedPatientTherapyId) {
			if (!!selectedPatientTherapyId) {
				this.executeAbortableAction(loadPatientTherapyAction, selectedPatientTherapyId, '.therapy-section').then();
			} else {
				clearPatientTherapyAction();
			}
		}
	}

	componentWillUnmount() {
		super.componentWillUnmount();
		
		const {clearPatientTherapyAction, clearSelectedPatientTherapyAction} = this.props;

		// Clear patient therapy
		clearPatientTherapyAction();
		// Clear selected patient therapy
		clearSelectedPatientTherapyAction();
	}

	/**
	 * Reload the list of patient therapies used buy the select component
	 * 
	 * @param {boolean} [triggerOnLoad] - Flag that specifies if patient therapy select component onLoad event will be 
	 * triggered after reload.
	 * @return {Promise<*>}
	 */
	reloadPatientTherapyList(triggerOnLoad) {
		if (this.patientTherapySelectRef) return this.patientTherapySelectRef.reload(triggerOnLoad);
		return Promise.resolve();
	}

	/**
	 * Open dialog to edit the therapy
	 */
	openEditTherapyDialog() {
		const {
			patientRecord, patientTherapy, openDialogAction, loadPatientTherapyAction, showSuccessMessageAction
		} = this.props;
		
		const dialogGUIID = openDialogAction('', TherapyDialog, {
			isNew: false,
			data: patientTherapy,
			patientId: getString(patientRecord, 'id'),
			dialogId: 'edit-therapy-dialog',
			onSave: therapyId => {
				showSuccessMessageAction(this.t('save_therapy_success_msg'));
				
				// Reload the list of patient therapies used in select input and update the selected therapy to show changes
				this.reloadPatientTherapyList().then(() => this.selectTherapyById(therapyId));
				// Reload selected patient therapy data used in the info box and as data for edit dialog
				this.executeAbortableAction(loadPatientTherapyAction, therapyId, '.therapy-section').then();
				// Trigger 'onChange' event
				executeComponentCallback(this.props.onChange);
			},
		}, {
			id: 'edit-therapy-dialog',
			className: 'bordered-title',
			closeOnEscape: true,
			closeOnClickOutside: false,
			hideCloseBtn: true,
			maxWidth: 1000
		});
		this.setOption(
			'dialogsToCloseOnUnmount',
			trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
		);
	}

	/**
	 * Open the confirm dialog to delete the patient therapy
	 */
	openDeleteTherapyDialog() {
		const {patientTherapy, openDialogAction, closeDialogAction, deletePatientTherapyAction} = this.props;
		const therapyId = getString(patientTherapy, 'id');
		
		const dialogGUIID = openDialogAction('', ConfirmDialog, {
			message: this.t('deleteConfirmMessage'),
			onYes: () => {
				this.executeAbortableAction(deletePatientTherapyAction, therapyId)
					.then(res => { 
						if (isSuccessful(res)) {
							closeDialogAction(dialogGUIID);

							// Reload the list of patient therapies used in select input and trigger input's onLoad event to 
							// select the first list item or clear the selection if there are no more items
							this.reloadPatientTherapyList(true).then();
						} 
					});
			},
		}, {
			id: 'delete-patient-therapy-dialog',
			closeOnEscape: true,
			closeOnClickOutside: true,
			hideCloseBtn: true,
			maxWidth: 560
		});
		this.setOption(
			'dialogsToCloseOnUnmount',
			trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
		);
	}
	
	/**
	 * Select therapy by therapy ID
	 * @param {string} id - Patient therapy ID.
	 */
	selectTherapyById(id) {
		if (this.patientTherapySelectRef) this.patientTherapySelectRef.selectById(id);
	}

	/**
	 * Render a single patient therapy info grid text field
	 * 
	 * @param {string} field - Name of the patient therapy field (@see PatientTherapyDataObject).
	 * @return {JSX.Element}
	 */
	renderStringInfo(field) {
		const {patientTherapy} = this.props;
		return (
			<div className={styles['infoItem']}>
				<Label 
					element="strong"
					elementProps={{className: styles['label']}}
					content={`${this.t(`${field}Label`, 'patientTherapy')}:`}
				/>
				<Label 
					element="span"
					elementProps={{className: styles['value']}} 
					content={
						getStringForDisplay(get(patientTherapy, field)) ? getStringForDisplay(get(patientTherapy, field)):'—'
					}
				/>
			</div>
		);
	}

	/**
	 * Render a single patient therapy info grid number field
	 *
	 * @param {string} field - Name of the patient therapy field (@see PatientTherapyDataObject).
	 * @param {string} [format] - Output number formant.
	 * @param {boolean} [useAppLocaleCurrency] - Render output as local currency.
	 * @param {boolean} [colorCoded] - Flag that specifies if number will be color-coded (negative value will be red).
	 * @return {JSX.Element}
	 */
	renderNumberInfo(field, format, useAppLocaleCurrency, colorCoded) {
		const {patientTherapy} = this.props;
		const value = getNumber(patientTherapy, field);
		
		return (
			<div 
				className={
					styles['infoItem'] +
					(colorCoded ? (value > 0 ? ' success-color' : value < 0 ? ' error-color' : '') : '')
				}
			>
				<Label
					element="strong"
					elementProps={{className: styles['label']}}
					content={`${this.t(`${field}Label`, 'patientTherapy')}:`}
				/>
				<NumberLabel
					element="span"
					elementProps={{className: styles['value']}}
					number={value}
					defaultOutput="—"
					format={format}
					useAppLocaleCurrency={useAppLocaleCurrency}
				/>
			</div>
		);
	}

	/**
	 * Render a single patient therapy info grid date field
	 *
	 * @param {string} field - Name of the patient therapy field (@see PatientTherapyDataObject).
	 * @return {JSX.Element}
	 */
	renderDateInfo(field) {
		const {patientTherapy} = this.props;
		return (
			<div className={styles['infoItem']}>
				<Label
					element="strong"
					elementProps={{className: styles['label']}}
					content={`${this.t(`${field}Label`, 'patientTherapy')}:`}
				/>
				<DateLabel
					element="span"
					elementProps={{className: styles['value']}}
					inputDate={get(patientTherapy, field)}
					outputFormat={LOCALE_DATE_FORMAT_NAME.LONG}
					defaultOutput="—"
				/>
			</div>
		);
	}
	
	render() {
		const {
			className, patientRecord, selectedPatientTherapy, selectPatientTherapyAction, 
			clearSelectedPatientTherapyAction,
		} = this.props;
		/** @type {PatientTherapyDataObject} */
		const patientTherapy = this.props.patientTherapy;
		
		return (
			<div
				id={this.getDomId()} 
				className={`${this.getOption('domPrefix')} ${styles['wrapper']} 
				${className}`}
			>
				<div className={`section-title ${styles['title']} ${patientRecordStyles['sectionTitle']}`}>
					<Label 
						element="div" 
						elementProps={{className: patientRecordStyles['label']}}
						icon="first-aid"
						iconSymbolPrefix="icofont-"
						content={this.t('title')}
					/>
				</div>

				<div 
					className={
						`section-content ${patientRecordStyles['sectionContent']} ${styles['content']}`
					}
				>
					<PatientTherapySelectInput 
						patientId={patientRecord?.id}
						value={selectedPatientTherapy}
						toolbarButtons={[
							{
								className: 'search-btn',
								position: ASYNC_SELECT_INPUT_TOOLBAR_POSITION.LEFT,
								icon: 'search',
								displayType: BUTTON_DISPLAY_TYPE.TRANSPARENT,
							},
							{
								position: ASYNC_SELECT_INPUT_TOOLBAR_POSITION.RIGHT,
								icon: icon_font_edit_symbol,
								tooltip: `<strong>${this.t('editBtn')}</strong><br />${this.t('editBtnTooltip')}`,
								displayType: BUTTON_DISPLAY_TYPE.NONE,
								onClick: () => this.openEditTherapyDialog(),
								hide: !getString(selectedPatientTherapy, 'id'),
							},
							{
								position: ASYNC_SELECT_INPUT_TOOLBAR_POSITION.RIGHT,
								icon: icon_font_delete_symbol,
								tooltip: `<strong>${this.t('deleteBtn')}</strong><br />${this.t('deleteBtnTooltip')}`,
								displayType: BUTTON_DISPLAY_TYPE.NONE,
								onClick: () => this.openDeleteTherapyDialog(),
								hide: !getString(selectedPatientTherapy, 'id'),
							}
						]}
						onChange={selectPatientTherapyAction}
						onLoad={list => {
							const firstItem = get(list, '[0]');
							// Select the first therapy in the list if the list is not empty
							if (!!firstItem) selectPatientTherapyAction(firstItem);
							// Clear the selection if the list is empty
							else clearSelectedPatientTherapyAction();
						}}
						ref={node => { this.patientTherapySelectRef = node; }}
					/>

					{
						!isset(patientTherapy) ?
							<Label 
								element="div"
								elementProps={{
									className: `${styles['infoError']} error-color`,
									onClick: () => {
										const {loadPatientTherapyAction} = this.props;
										this.executeAbortableAction(
											loadPatientTherapyAction, getString(selectedPatientTherapy, 'id'), '.therapy-section'
										).then();
									},
								}}
								icon={icon_font_error_symbol}
								content={this.t('infoError')}
							/>
						: !!patientTherapy ?
							<div className={`therapy-info ${styles['info']}`}>
								<div className={styles['infoItemBig']}>
									<Label
										element="strong"
										elementProps={{className: styles['label']}}
										content={`${this.t(`symptomsLabel`, 'patientTherapy')}:`}
									/>
									<Label
										element="span"
										elementProps={{className: styles['value']}}
										content={get(patientTherapy, 'symptoms') ? get(patientTherapy, 'symptoms') : '—'}
									/>
								</div>
								{/*<div className={styles['infoItemBig']}>
									<Label
										element="strong"
										elementProps={{className: styles['label']}}
										content={`${this.t(`diagnosisLabel`, 'patientTherapy')}:`}
									/>
									<Label
										element="div"
										elementProps={{className: styles['value']}}
										content={
											isset(get(patientTherapy, 'diagnosis')) ?
												(
													`${patientTherapy.diagnosisCode ? `${patientTherapy.diagnosisCode} ` : ''}` +
													patientTherapy.diagnosis
												) : '—'
										}
									/>
								</div>*/}
								
								<div className={`therapy-info-grid ${styles['infoGrid']}`}>
									{patientTherapy.customPhysician ?
										this.renderStringInfo('customPhysicianName') 
										:
										<div className={styles['infoItem']}>
											<Label
												element="strong"
												elementProps={{className: styles['label']}}
												content={`${this.t('sentByPhysicianLabel', 'patientTherapy')}:`}
											/>
											<Label
												element="span"
												elementProps={{className: styles['value']}}
												content={
													(getString(patientTherapy, 'physician.title') + ' ' +
													getString(patientTherapy, 'physician.firstName') + ' ' +
													getString(patientTherapy, 'physician.lastName')).trim() !== '' ?
														getString(patientTherapy, 'physician.title') + ' ' +
														getString(patientTherapy, 'physician.firstName') + ' ' +
														getString(patientTherapy, 'physician.lastName')
														: '—'
												}
											/>
										</div>
									}
									{this.renderDateInfo('therapyDate')}
									{this.renderStringInfo('medicalRecordNumber')}
									{this.renderStringInfo('medicalRecordCategoryName')}
									{this.renderStringInfo('prescribedTherapiesCount')}
									{this.renderNumberInfo('payedTreatments')}
									{this.renderNumberInfo('finishedTreatments')}
									{this.renderNumberInfo('remainingTreatments', undefined, undefined, true)}
									{this.renderNumberInfo('totalPayed', '0,0.00')}
								</div>
								
								<div className={`therapy-actions`}>
									<Button
										icon={icon_font_edit_symbol}
										label={this.t('editBtn')}
										displayStyle={BUTTON_STYLE.ACTION}
										title={this.t('editBtnShortcut')}
										onClick={() => this.openEditTherapyDialog()}
										hide={!getString(selectedPatientTherapy, 'id')}
									/>
									<Button
										icon={icon_font_delete_symbol}
										label={this.t('deleteBtn')}
										displayStyle={BUTTON_STYLE.ERROR}
										title={this.t('deleteBtnShortcut')}
										onClick={() => this.openDeleteTherapyDialog()}
										hide={!getString(selectedPatientTherapy, 'id')}
									/>
								</div>
							</div>
						: null
					}
				</div>
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
Therapy.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	
	onChange: PropTypes.func, // No arguments
};

/**
 * Define component default values for own props
 */
Therapy.defaultProps = {
	id: '',
	className: '',
};

export default connect(mapStateToProps, getGlobalActions(actions), null, {forwardRef: true})(Therapy);