import React from "react";
import PageComponent from "Core/components/PageComponent";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import {selectors} from "Core/store/reducers";
import {getMenuSidebarShrankFromStorage} from "Layout/elements/MainSidebar/helpers";
import {getGlobalActions} from "Core/helpers/redux";
import Icon from "Core/components/display/Icon";
import Label, {LABEL_ICON_POSITION} from "Core/components/display/Label";
import Button, {BUTTON_STYLE} from "Core/components/display/Button";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	isMobileBreakpoint: selectors.breakpoint.isMobileBreakpoint(state),
	mainSidebarShrank: getMenuSidebarShrankFromStorage(selectors.mainSidebar.shrank(state)),
});

/**
 * Default 404 "Not Found" error page for 'apps' section 
 */
class AppsNotFoundPage extends PageComponent {
	constructor(props) {
		super(props, {
			layout: 'default',
			domPrefix: 'apps-not-found-page',
			translationPath: 'AppsNotFoundPage',
		});
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {app, mainSidebarShrank, toggleMainSidebarSizeAction} = this.props;
		
		this.setBrowserTitle(this.t('page_title'));

		return this.renderLayout((
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')}`}>
				<div className="page-notice-wrapper">
					<Icon symbol="chain-broken" className="page-notice-title-icon" />
					<Label
						element="p"
						elementProps={{className: 'page-notice-title bold'}}
						content={this.t('error_title')}
					/>
					<Label
						element="p"
						elementProps={{className: 'page-notice'}}
						content={this.t('error_message')}
					/>
					<Button
						big={true}
						label={(
							<Label
								content={this.t('close_button')}
								icon="angle-right"
								iconPosition={LABEL_ICON_POSITION.RIGHT}
							/>
						)}
						displayStyle={BUTTON_STYLE.ACTION}
						onClick={() => { this.redirectToAuthorizedHome(); }}
					/>
				</div>
			</div>
		), '', undefined, {app, mainSidebarShrank, toggleMainSidebarSizeAction});
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
AppsNotFoundPage.propTypes = {
	// App config
	app: PropTypes.object,
};

export default withRouter(connect(mapStateToProps, getGlobalActions())(AppsNotFoundPage));