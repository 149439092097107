import {fetchStatisticsAction} from "Components/advanced/Statistics/actions";
import {actionCreators} from "./reducer";
import {STATISTICS_TYPE} from "Components/advanced/Statistics/const";
import {get} from "lodash";
import {isset} from "Core/helpers/data";
import {OFFICE_LOCATION_STATISTICS_TYPES_BY_SCOPE} from "Pages/apps/default/statistics/officeLocationStatistics/const";

/**
 * Load office location statistics using a specific filter
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {OfficeLocationStatisticsScope} scope - Statistics scope (daily, monthly, ...).
 * @param {StatisticsFilterDataObject} filter - Criteria for showing statistics on the page.
 * @param {Object} dataMap - Data map to use for mapping the data.
 * @return {function(*): Promise<
 * 	Object<StatisticsType, {data: StatisticsDataObject, filter: StatisticsFilterDataObject}> | undefined
 * >}
 */
export const loadOfficeLocationStatisticsAction = (abortCallback, scope, filter, dataMap) => dispatch => {
	return Promise.allSettled(OFFICE_LOCATION_STATISTICS_TYPES_BY_SCOPE[scope].map(s =>
		fetchStatisticsAction(abortCallback, s, filter, dataMap)(dispatch)
	))
		.then(multiRes => {
			const yearlyVisits = get(multiRes, '[0].value');
			const yearlyPayments = get(multiRes, '[1].value');
			const filter = (isset(yearlyVisits) ? get(yearlyVisits, 'filter') : get(yearlyPayments, 'filter')) ;
			
			if (!isset(yearlyVisits) && !isset(yearlyPayments)) return undefined;
			
			dispatch(actionCreators.setData(get(yearlyVisits, 'data')));
			dispatch(actionCreators.setData(get(yearlyPayments, 'data')));
			dispatch(actionCreators.setFilter(filter));
			return {
				[STATISTICS_TYPE.OFFICE_LOCATIONS_YEARLY_VISITS]: yearlyVisits,
				[STATISTICS_TYPE.OFFICE_LOCATIONS_YEARLY_PAYMENTS]: yearlyPayments,
			};
		});
}

/**
 * Save office location statistics filter data in Redux store
 *
 * @param {StatisticsFilterDataObject} filter - Office location statistics filter data.
 * @return {(function(*): void)|*}
 */
export const setOfficeLocationStatisticsFilterAction = filter => dispatch => {
	dispatch(actionCreators.setFilter(filter));
}

/**
 * Save office location statistics current scope data in Redux store
 *
 * @param {?OfficeLocationStatisticsScope|''} scope - Office location statistics scope.
 * @return {(function(*): void)|*}
 */
export const setOfficeLocationStatisticsScopeAction = scope => dispatch => {
	dispatch(actionCreators.setScope(scope));
}