/**
 * WARNING: Be careful when importing this helper because it uses Redux store and some other imports that can cause
 * circular dependencies!
 */
import reduxStore from "../store";
import {selectors} from "../store/reducers";
import {memory_storage_var} from "Config/app";
import {getArray} from "Core/helpers/data";
import {get} from "lodash";

/**
 * Check if popup is opened
 * @note This function uses Redux store directly.
 * 
 * @param {string} GUIID - Popup GUI ID.
 * @return {boolean}
 */
export const isPopupOpen = GUIID => !!selectors.popup.getPopup(reduxStore.getState(), GUIID);

/**
 * Get opened popup
 * @param {string} GUIID - Popup GUI ID. 
 * @return {{GUIID: string, component: any, props: Object, options: Object, [instance]: any}}
 */
export const getPopup = GUIID => selectors.popup.getPopup(reduxStore.getState(), GUIID);

/**
 * Get opened popup component instance
 * @param {string} GUIID - Popup GUI ID.
 * @return {Object|null}
 */
export const getPopupInstance = GUIID => get(
	getArray(window, [memory_storage_var, 'popup_instances']).filter(i => i.GUIID = GUIID),
	'[0].instance',
	null
);