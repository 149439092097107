/**
 * Import and define app sections
 * @description The app can have multiple sections. By default, there is a 'site' section and an 'apps' section where 
 * 'site' is a publicly available part of the app usually used for the landing page and 'app' section is where all apps 
 * are defined. The app can have multiple different apps in it where each inner app can have its own pages and style. 
 * Simply put, 'site' section is for the frontend and 'apps' section is for admin pages.
 */
import * as appsSection from "./pages/apps";
import * as siteSection from "./pages/site";
/**
 * @note The order in which section are defined in this array is important because router matching will stop on first 
 * match (React Router 'Switch' component is used). This means that only one match per section will be made.
 */
export const sections = [appsSection, siteSection];