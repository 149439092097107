import styles from "./index.module.css";

import React from "react";
import PageComponent from "Core/components/PageComponent";
import {connect} from "react-redux";
import {get} from "lodash";
import {getGlobalActions} from "Core/helpers/redux";
import {app_auth_page_router_path, icon_font_close_symbol, icon_font_error_symbol} from "Config/app";
import {hideLoading, showPageLoading} from "Core/helpers/loading";
import Label from "Core/components/display/Label";
import auth from "../../../auth";
import {LoadingOverlayObject} from "Core/objects";
import Overlay from "Core/components/global/Overlay";
import AppName from "Core/components/display/AppName";
import Icon from "Core/components/display/Icon";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import {AsyncMountError} from "Core/errors";

/**
 * Main auth page
 * @description Auth page is used to handle all auth related redirects from the auth API. Is usually just redirects to 
 * the appropriate app page or display some auth error.
 * @note Default implementation of the auth is OAuth 2.
 */
class AuthPage extends PageComponent {
	constructor(props) {
		super(props, {
			layout: 'auth',
			routerPath: app_auth_page_router_path,
			domPrefix: 'auth-page',
			translationPath: 'Auth',
			disableLoad: true
		});

		// Render methods
		this.renderAuthorizationError = this.renderAuthorizationError.bind(this);
	}


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called on component mount and should be used to load any data required by the page
	 * @return {any|void}
	 * @throws {AsyncMountError}
	 */
	loadPageData() {
		const {showErrorMessageAction} = this.props;
		const code = this.getUrlParam('code');
		const state = this.getUrlParam('state');
		const storedState = auth.getState();
		
		// Handle creating tokens when authorization code has been received from API through URL param
		if (code) {
			// If state is used and it is incorrect
			if ((state || storedState) && state !== storedState) {
				showErrorMessageAction(this.t('Security code is incorrect', p => `${p}.token.errors`));
			}
			// If state is not used or is correct
			else {
				const loading = showPageLoading(false, false);
				this.executeAbortableAction(auth.refreshTokensWithAuthorizationCode, code)
					// Store newly created tokens
					.then(response => {
						if (response) {
							auth.storeAccessToken(get(response, 'access_token'));
							auth.storeRefreshToken(get(response, 'refresh_token'));
						}
						return response;
					})

					// Call security init API to handle all server-side generated cookies
					.then(response => (response ? this.executeAbortableAction(auth.securityInit) : response))

					// Handle successful token refresh
					.then(() => {
						// Delete stored code verifier and state
						// @description We don't need them anymore, and they can be a security risk.
						auth.deleteCodeVerifier();
						auth.deleteState();

						hideLoading(loading);
						
						// Redirect to app's home page for logged-in user because the user should be logged-in now
						// @note see app config 'app_authorized_home_page_router_path' value.
						this.redirectToAuthorizedHome();
					})

					// Catch errors
					.catch(error => {
						hideLoading(loading);
						showErrorMessageAction(error.message);
						if (error.name === 'AbortError') throw new AsyncMountError();
					});
			}
		}
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render authorization error
	 * @return {JSX.Element|null}
	 */
	renderAuthorizationError() {
		const error = this.getUrlParam('error');
		const errorMessage = (
			error && this.hasTranslation(error, p => `${p}.authorize.errors`) ?
				this.t(`authorize.errors.${error}`) :
				this.t(`authorize.errors.unknown`)
		);

		this.setBrowserTitle('Authorization failed!');

		return this.renderLayout((
			<div className={`${styles['errorWrapper']}`}>
				<div className={`${styles['appName']}`}><AppName /></div>
				<div className={`${styles['notice']}`}>
					<Icon symbol={icon_font_error_symbol} className="page-notice-title-icon error-color" />
					<Label
						element="p"
						elementProps={{className: 'page-notice-title error-color'}}
						content={this.t('Authorization failed!')}
					/>
					<Label
						element="p"
						elementProps={{className: 'page-notice'}}
						content={errorMessage}
					/>
				</div>

				<div className={`${styles['actions']}`}>
					<Button
						big={true}
						displayStyle={BUTTON_STYLE.SUBTLE}
						displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
						icon={icon_font_close_symbol}
						label={this.t('Close', 'general')}
						onClick={() => this.redirectTo('/')}
					/>
				</div>
			</div>
		), undefined, undefined, {
			showHeader: false,
			footerJustifyContent: 'center',
			showRightSection: false,
		});
	}

	render() {
		return (
			this.getUrlParam('error') ? 
				this.renderAuthorizationError() :
				<Overlay
					data={new LoadingOverlayObject('#root', false, false, '3rem', 3, '', true)}
					element={document.getElementById('root')}
				/>
		);
	}
}

export default connect(null, getGlobalActions())(AuthPage);