/**
 * Data object representing the therapy types list item
 * @note This is usually used in data table as a row. Params don't have default values since this data object is used to
 * represent data that actually exists.
 */
export class TherapyTypesListItemDataObject {
	/**
	 * @param {string} id - ID of the therapy type.
	 * @param {string} name - Name of the therapy type.
	 */
	constructor(id, name) {
		this.id = id;
		this.name = name;
	}
}

/**
 * Data object representing the therapy type item
 * @note This is usually used when creating or updating data table item using a popup or a dialog. Params have default
 * values since this data object can be used for creating an item. This is why 'id' has a default value of null which
 * represents a new item that is being created.
 */
export class TherapyTypesItemDataObject {
	/**
	 * @param {?string} [id=null] - ID of the therapy type.
	 * @param {string} [name=''] - Name of the therapy type.
	 */
	constructor(id = null, name = '') {
		this.id = id;
		this.name = name;
	}
}