import styles from './index.module.css';

import React from 'react';
import BaseComponent from 'Core/components/BaseComponent';
import Label from 'Core/components/display/Label';
import PropTypes from 'prop-types';
import NumberLabel from 'Core/components/display/NumberLabel';

class PatientStatistics extends BaseComponent {
	constructor(props) {
		super(props, {
			translationPath: 'PatientStatistics',
		});
	}

	render() {
		const {className} = this.props;
		/** @type {PatientRecordDataObject} */
		const data = this.props.data;

		return (
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')} ${styles['wrapper']} ${className}`}>
				<div className={`section-content ${styles['grid']}`}>
					<div className={styles['gridItem']}>
						<Label element="label" icon="sign-in" content={this.t('payedTreatmentsLabel')} />
						<NumberLabel element="span" number={data?.payedTreatments} defaultOutput="—" />
					</div>
					<div className={styles['gridItem']}>
						<Label element="label" icon="sign-out" content={this.t('finishedTreatmentsLabel')} />
						<NumberLabel element="span" number={data?.finishedTreatments} defaultOutput="—" />
					</div>
					<div 
						className={
							`${styles['gridItem']} ` +
							`${data?.remainingTreatments < 0 ? styles['error'] : ''} ` +
							`${data?.remainingTreatments > 0 ? styles['success'] : ''}`
						}
					>
						<Label 
							element="label"
							icon={(
								data?.remainingTreatments < 0 ? 'exclamation-circle' :
								data?.remainingTreatments > 0 ? 'check-circle' :
								'minus-circle'
							)}
							content={this.t('remainingTreatmentsLabel')} 
						/>
						<NumberLabel element="span" number={data?.remainingTreatments} defaultOutput="—"/>
					</div>
					<div className={styles['gridItem']}>
						<Label element="label" icon="money" iconSymbolPrefix="icofont-" content={this.t('totalPayedLabel')} />
						<NumberLabel 
							element="span" 
							number={data?.totalPayed} 
							defaultOutput="—" 
							format="0,0.00"
							// useAppLocaleCurrency={true}
						/>
					</div>
				</div>
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
PatientStatistics.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	// @type {PatientRecordDataObject}
	data: PropTypes.object,
};

/**
 * Define component default values for own props
 */
PatientStatistics.defaultProps = {
	id: '',
	className: '',
};

export default PatientStatistics;