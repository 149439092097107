/**
 * Check if user is using MacOS
 *
 * @return {boolean} True if user is using MacOS.
 */
export const isMacintosh = () => navigator.platform.indexOf('Mac') > -1;

/**
 * Check if user is using Windows
 *
 * @return {boolean} True if user is using Windows.
 */
export const isWindows = () => navigator.platform.indexOf('Win') > -1;