import React from "react";
import * as ReactDOM from "react-dom";
import DataComponent from "../../DataComponent";
import {connect} from "react-redux";
import {getArray} from "../../../helpers/data";
import {selectors} from "../../../store/reducers";
import FloatPopup from "../FloatPopup";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	floatPopups: selectors.floatPopup.getFloatPopups(state)
});

class FloatPopups extends DataComponent {
	constructor(props) {
		super(props, {}, {
			dataPropAlias: 'floatPopups',
			enableLoadOnDataPropChange: true,
		});
	}

	render() {
		const floatPopups = getArray(this.getData());

		// Add or remove 'float-popups-visible' CSS class to the body element depending on opened float popups
		if (floatPopups.length > 0) document.body.classList.add('float-popups-visible');
		else document.body.classList.remove('float-popups-visible');

		return ReactDOM.createPortal((
			floatPopups.map(floatPopup =>
				<FloatPopup
					key={floatPopup.GUIID}
					GUIID={floatPopup.GUIID}
					innerComponent={floatPopup.component}
					innerComponentProps={floatPopup.props}
					options={floatPopup.options}
				/>
			)
		), document.body);
	}
}

export default connect(mapStateToProps, null)(FloatPopups);