import {actionCreators} from "./reducer";
import {v4} from "uuid";

/**
 * Open popup action
 *
 * @param {React.Component} component - Component to render inside the opened popup.
 * @param {object} props - Props to send to the component rendered inside the opened popup.
 * @param {{id: string, className: string, hideCloseBtn: boolean}} [options={}] - Opened popup options.
 * @param {string} [GUIID] - Popup's unique GUI ID. If not specified, unique GUI ID will be generated and used.
 *	@return Popup's unique GUI ID.
 */
export const openPopupAction = (component, props = {}, options = {}, GUIID) => dispatch => {
	if (!GUIID) GUIID = v4();
	dispatch(actionCreators.openPopup(GUIID, component, props, options));
	document.activeElement.blur();
	return GUIID;
};

/**
 * Close opened popup
 *
 * @param {string} GUIID - Popup unique GUIID.
 */
export const closePopupAction = GUIID => dispatch => { dispatch(actionCreators.closePopup(GUIID)) };

/**
 * Close all opened popups
 */
export const closeAllPopupsAction = () => dispatch => { dispatch(actionCreators.closeAllPopups()) };

/**
 * Set popup component instance
 * @param {string} GUIID - Popup unique GUIID.
 * @param {*} [instance] - Popup component instance.
 * @return {(function(*): void)|*}
 */
export const setPopupInstanceAction = (GUIID, instance) => dispatch => { 
	dispatch(actionCreators.setPopupInstance(GUIID, instance));
};