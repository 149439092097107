import {setQuickSearchPatientAction, clearQuickSearchPatientAction} from 'Components/advanced/PatientQuickSearch';

/**
 * Select patient
 * 
 * @param {PatientSelectOptionDataObject} patient - Patient to select.
 * @return {(function(*): void)}
 */
export const selectPatientAction = patient => dispatch => {
	setQuickSearchPatientAction(patient)(dispatch);
}

/**
 * Clear selected patient
 * 
 * @return {(function(*): void)}
 */
export const clearPatientAction = () => dispatch => {
	// @note Clearing the quick search patient will also clear the patient record (@see 'componentDidUpdate' of the 
	// PatientRecord component)
	clearQuickSearchPatientAction()(dispatch);
};