import PropTypes from "prop-types";
import PopupComponent, {PopupActionDataObject, PopupTabDataObject} from "Core/components/PopupComponent";
import {connect} from "react-redux";
import * as pageConfig from "../../config";
import {getPageActions} from "Core/helpers/redux";
import * as actions from "../../actions";
import {cloneDeep, orderBy, get} from "lodash";
import {
	icon_font_close_symbol, 
	icon_font_create_symbol, 
	icon_font_save_symbol, 
	icon_font_delete_symbol
} from "Config/app";
import {BUTTON_STYLE} from "Core/components/display/Button";
import {getBool, getString, isset} from "Core/helpers/data";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({

});

class ItemPopup extends PopupComponent {
	/**
	 * IMPORTANT! Must be defined in components that extend this abstract component like this:
	 * dirname = __dirname;
	 *
	 * @note This is done in order for automatic tab component loading to work properly.
	 */
	dirname = __dirname;

	constructor(props) {
		super(props, {
			translationPath: `${pageConfig.translationPath}.ItemPopup`,
			domPrefix: 'item-popup',
			hideSingleTab: true,
		});

		this.initialState = {
			/**
			 * List of all popup tabs
			 * @type {PopupTabDataObject[]}
			 */
			tabs: [],

			/**
			 * List of all popup actions
			 * @type {PopupActionDataObject[]}
			 */
			actions: [],

			/**
			 * ID of the currently opened tab
			 * @type {string}
			 */
			currentTabId: '',
		};

		this.state = cloneDeep(this.initialState);

		// Action methods
		this.save = this.save.bind(this);
		this.saveAndClose = this.saveAndClose.bind(this);
		this.delete = this.delete.bind(this);
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }


	// Tab methods ------------------------------------------------------------------------------------------------------
	/**
	 * Update dynamic action buttons that depend on current state and props
	 * @param {boolean} [isNew] - Flag that specifies if this popup is for a new item.
	 * @return {Promise<void>}
	 */
	async dynamicActionButtons({isNew}) {
		// Remove actions before deciding which ones to display
		await this.removeActions(['delete', 'create', 'update', 'update_and_close']);

		// Add save related global actions
		if (!isNew) {
			await this.addAction(new PopupActionDataObject({
				id: 'delete',
				action: this.delete,
				buttonProps: {
					label: 'general.Delete',
					icon: icon_font_delete_symbol,
					displayStyle: BUTTON_STYLE.ERROR
				},
				ordinal: 1
			}));
			await this.addAction(new PopupActionDataObject({
				id: 'update_and_close',
				action: this.saveAndClose,
				buttonProps: {
					label: this.getTranslationPath('update_and_close_action'),
					icon: icon_font_save_symbol,
					displayStyle: BUTTON_STYLE.ACTION
				},
				ordinal: 2,
			}));
		}
		await this.addAction(new PopupActionDataObject({
			id: isNew ? 'create' : 'update',
			action: this.save,
			buttonProps: {
				label: this.getTranslationPath(`${isNew ? 'create_action' : 'update_action'}`),
				icon: isNew ? icon_font_create_symbol : icon_font_save_symbol,
				displayStyle: BUTTON_STYLE.ACTION
			},
			ordinal: 3
		}));
	}

	/**
	 * Initialize popup by specifying initial tabs, actions and current tab
	 * @note If current tab is not set it will default to the first visible and valid tab. Valid tab is tab that has
	 * 'component' property specified (manually or automatically loaded).
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	async init() {
		const isNew = getBool(this.props, 'isNew');

		// Add static actions that don't depend on current state or props
		let actions = [
			new PopupActionDataObject({
				id: 'close',
				action: this.close,
				buttonProps: {
					label: 'general.Close',
					icon: icon_font_close_symbol
				},
				ordinal: 0
			}),
		];
		await this.setActions(actions);
		// Add dynamic actions that depend on current state or props
		await this.dynamicActionButtons({isNew});

		// Add tabs
		await this.setTabs([
			new PopupTabDataObject({
				id: 'MainTab',
			}),
		]).then(this.importTabComponents);
		// Update dynamic tabs that depend on current state or props
		await this.dynamicTabs({isNew});

		return Promise.resolve(this.state);
	}

	/**
	 * Try to automatically load tab components from standard location for tabs that don't have components defined
	 * @note To automatically load tab components the need to be located in a 'tabs' subdirectory either as a component
	 * file (like ./tabs/InfoTab.js) or subdirectory with index file (./tabs/InfoTab/index.js) where directory name or
	 * filename must be the tab ID.
	 *
	 * @return {Promise<any>} Promise that resolves to entire component local state after state is updated.
	 */
	importTabComponents() {
		const tabs = orderBy(this.getSortedTabs(), ['preloadPriority'], ['desc']);
		return Promise.all(tabs.map(tab => {
			if (!isset(tab.component)) return this.handleTabComponentImport(tab, import(`./tabs/${tab.id}`));
			else return Promise.resolve(this.state);
		}));
	}


	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Save item
	 * @note This method will handle both updating and creating a new item.
	 *
	 * @param {Object} allTabsData - Internal tab data object where keys are tab IDs and values are internal tabs data.
	 * @param {MouseEvent} event - Mouse click event for clicked action button DOM element.
	 * @param {'create'|'update'} actionId - ID of the clicked action.
	 * @return {Promise<*>}
	 */
	save(allTabsData, event, actionId) {
		const {
			redirectToItem, createProcurementsItemAction, updateProcurementsItemAction, 
			loadProcurementsListAction, addSuccessMessageAction
		} = this.props;

		return this.getTabRef('MainTab').validateTab()
			.then(valid => {
				if (valid) {
					/** @type {ProcurementsItemDataObject} */
					const item = get(allTabsData, 'MainTab');

					// Create item
					if (actionId === 'create') {
						return this.executeAbortableAction(createProcurementsItemAction, item)
							.then(createdItem => {
								if (createdItem?.id) {
									// Redirect to item URL if item was created successfully and show success message
									redirectToItem(createdItem.id);
                        	addSuccessMessageAction(this.t('create_success_msg'));
								
									// Reload item list (data table) without rendering the loading overlay
									// @note This is done asynchronously on purpose because there is no reason to wait for this 
									// to finish before continuing.
									this.executeAbortableAction(loadProcurementsListAction,
										undefined, undefined, undefined, undefined, undefined, false
									).then();
								}
								return createdItem;
							});
					}
					// Update item
					else if (actionId === 'update') {
						return this.executeAbortableAction(
							updateProcurementsItemAction,
							getString(item, 'id', '', true),
							item
						)
							.then(response => {
								if (response) addSuccessMessageAction(this.t('update_success_msg'));
								return response;
							});
					} else {
						console.error(`Invalid procurements item popup save method: ${actionId}`);
					}
				}
				return Promise.resolve();
			});
	}

	/**
	 * Save item and close the popup if successful
	 *
	 * @param {Object} allTabsData - Internal tab data object where keys are tab IDs and values are internal tabs data.
	 * @param {MouseEvent} event - Mouse click event for clicked action button DOM element.
	 * @return {Promise<*>}
	 */
	saveAndClose(allTabsData, event) {
		return this.save(allTabsData, event, 'update')
			.then(response => {
				if (isset(response)) this.close();
				return response;
			});
	}

	/**
	 * Delete item
	 *
	 * @param {Object} allTabsData - Internal tab data object where keys are tab IDs and values are internal tabs data.
	 * @param {MouseEvent} event - Mouse click event for clicked action button DOM element.
	 * @param {'create'|'update'} actionId - ID of the clicked action.
	 * @return {Promise<*>}
	 */
	delete(allTabsData, event, actionId) {
		const {deleteItemAction, redirectToBase} = this.props;
		const item = get(allTabsData, 'MainTab');
		if (item && item.id) return deleteItemAction(item).then(event => { if (event === 'yes') redirectToBase(); });
		return Promise.resolve();
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
ItemPopup.propTypes = {
	// Flag that specifies if this popup is for creating a new item
	// @note This prop can be dynamically changed.
	isNew: PropTypes.bool,
	// Function that will redirect to item URL
	redirectToItem: PropTypes.func, // Arguments: {string} - Item id to redirect to.
	// Function that will redirect to the base URL of the page
	redirectToBase: PropTypes.func, // Arguments: no arguments
	
	// Actions
	// @param {ManageDocumentsItemDataObject} item - Item to delete. Full item is needed to access the name displayed in
	// delete confirmation message.
	// @return {Promise<void>}
	deleteItemAction: PropTypes.func,

	// Events
	onClose: PropTypes.func,
	onGlobalAction: PropTypes.func,
	onTabAction: PropTypes.func,
};

export default connect(
	mapStateToProps, getPageActions(actions), null, {forwardRef: true}
)(ItemPopup);