import React from "react";
import PageComponent from "Core/components/PageComponent";
import * as appConfig from "../../config";
import * as pageConfig from "./config";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {selectors} from "Core/store/reducers";
import {getPageActions} from "Core/helpers/redux";
import * as actions from "./actions";
import {getMenuSidebarShrankFromStorage} from "Layout/elements/MainSidebar/helpers";
import Statistics from "Components/advanced/Statistics";
import {reducerStoreKey} from "./reducer";
import {
	THERAPISTS_CHART_TYPE_BY_STATISTICS_TYPE,
	THERAPISTS_STATISTICS_SCOPES,
	THERAPISTS_STATISTICS_TYPES_BY_SCOPE
} from "Pages/apps/default/statistics/therapistStatistics/const";
import Label from "Core/components/display/Label";
import * as dataMap from "./dataMap";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {Object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	mainSidebarShrank: getMenuSidebarShrankFromStorage(selectors.mainSidebar.shrank(state)),
	mainListData: selectors[reducerStoreKey].getTherapistStatisticsList(state),
	mainListFilter: selectors[reducerStoreKey].getTherapistStatisticsListFilter(state),
	mainListScope: selectors[reducerStoreKey].getTherapistStatisticsListScope(state),
});

class TherapistStatisticsPage extends PageComponent {
	constructor(props) {
		super(props, {
			domPrefix: 'therapist-statistics-page',
			translationPath: pageConfig.translationPath,
			routerPath: pageConfig.routerPath,
			checkLogin: false,
		}, 'page_title');
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }


	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {
			mainListData, mainListFilter, mainListScope, mainSidebarShrank, toggleMainSidebarSizeAction,
			loadTherapistsStatisticsAction, setTherapistsStatisticsFilterAction, setTherapistsStatisticsScopeAction, 
		} = this.props;
	
		return this.renderLayout((
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')}`}>
				{
					this.hasTranslation('page_short_description') && this.t('page_short_description') ?
						<div className="simple-page-description">
							<Label content={this.t('page_short_description')} supportHtml={true}/>
						</div>
						: null
				}

				<Statistics
					scopes={THERAPISTS_STATISTICS_SCOPES}
					scopesTranslationPath={`${pageConfig.translationPath}.statisticsScope`}
					scope={mainListScope}
					typeScopeMap={THERAPISTS_STATISTICS_TYPES_BY_SCOPE}
					chartTypeMap={THERAPISTS_CHART_TYPE_BY_STATISTICS_TYPE}
					data={mainListData}
					appliedFilter={mainListFilter}
					loadStatisticsFunction={loadTherapistsStatisticsAction}
					setFilterFunction={setTherapistsStatisticsFilterAction}
					setScopeFunction={setTherapistsStatisticsScopeAction}
					dataMap={dataMap}
				/>
			</div>
		), undefined, undefined, {
			app: appConfig,
			mainSidebarShrank,
			toggleMainSidebarSizeAction,
		});
	}
}

export * from "./config";
export default withRouter(connect(mapStateToProps, getPageActions(actions))(TherapistStatisticsPage));