import React from "react";
import {components} from "react-select";
import {getArray, getString} from 'Core/helpers/data';
import {get} from 'lodash';
import {getQueryIndexesForString} from 'Core/helpers/string';

/**
 * Select option replacement component for bolding out queried string
 * @description Use this as an Option component replacement for the SelectInput (react-select) component. You can pass
 * Label component props through 'labelProps' prop of the SelectInput component. These prop can be an instance of
 * SelectDynamicValueFunction class if prop should depend on option data.
 *
 * @param {Object} props - Option props sent by the react-select component to all the options.
 * @return {JSX.Element}
 * @constructor
 */
const HighlightedQueryOptionComponent = props => {
	const userInput = getString(props, 'selectProps.inputValue');
	const getOptionLabelFunction = get(props, 'selectProps.getOptionLabel');
	const label = (getOptionLabelFunction ? getOptionLabelFunction(get(props, 'data')) : getString(props, 'data.label'));
	
	const highlightSubString = (needle, haystack) => {
		const highlightedIndexes = getQueryIndexesForString(needle, haystack);
		return haystack.split('').map((c, idx) => (
			getArray(highlightedIndexes, '[0]').includes(idx) ?
				(<strong key={idx} className="highlight-color">{c}</strong>) :
				(<span key={idx}>{c}</span>)
		));
	};

	return (
		<components.Option {...props}>
			<div>{userInput.length > 0 ? highlightSubString(userInput, label) : label}</div>
		</components.Option>
	);
};

export default HighlightedQueryOptionComponent;