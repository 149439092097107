import {hideLoadingFunction, showDialogLoading} from "Core/helpers/loading";
import {ioJsonAction} from "Core/store/actions/io";

/**
 * Send an expert report via email to one or more recipients
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - DB ID of the expert report.
 * @param {string[]} recipients - List of recipient email addresses.
 * @return {function(*): Promise<IoJsonFetchResponseObject>}
 */
export const sendExpertReportEmailAction = (abortCallback, id, recipients) => dispatch => {
	const loading = showDialogLoading('send-expert-report-email-dialog');
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'member/expert-report/send-expert-report-mail',
		{id, recipients},
		hideLoadingFunction(loading),
	)(dispatch);
};