import {actionCreators} from "./reducer";
import {v4} from "uuid";

/**
 * Open float popup action
 * 
 * @param {string} [GUIID] - Float popup's unique GUI ID. If not specified, unique GUI ID will be generated and used.
 * @param {React.Component} component - Component to render inside the opened float popup.
 * @param {object} props - Props to send to the component rendered inside the opened float popup.
 * @param {FloatPopupOptionsDataObject|{}} [options={}] - Opened float popup options.
 *	@return Float popup's unique GUI ID.
 */
export const openFloatPopupAction = (GUIID, component, props, options = {}) => dispatch => {
	if (!GUIID) GUIID = v4();
	dispatch(actionCreators.openFloatPopup(GUIID, component, props, options));
	document.activeElement.blur();
	return GUIID;
};

/**
 * Close opened float popup
 *
 * @param {string} GUIID - Float popup unique GUIID.
 */
export const closeFloatPopupAction = GUIID => dispatch => { dispatch(actionCreators.closeFloatPopup(GUIID)) };

/**
 * Close all opened float popups
 */
export const closeAllFloatPopupsAction = () => dispatch => { dispatch(actionCreators.closeAllFloatPopups()) };