import styles from "./index.module.css";

import React from "react";
import BaseComponent, {executeComponentCallbackPromise} from "../../BaseComponent";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {find, get, has, cloneDeep, difference, map, reject} from "lodash";
import {icon_font_stack_class, icon_font_stacked_item_class, pagination_default_per_page} from "Config/app";
import {SORT_ORDER} from "Core/const/global";
import {DATA_TABLE_CELL_TYPE, DATA_TABLE_CELL_TYPES} from "./const";
import {getArray, getBoolean, getNumber, getString, isset} from "Core/helpers/data";
import {safeCssName} from "Core/helpers/string";
import {getElementAbsolutePos, horScrollbarVisible, vertScrollbarVisible} from "Core/helpers/dom";
import Label, {LABEL_ICON_POSITION} from "../../display/Label";
import Pagination, {PAGINATION_TYPE, PAGINATION_TYPES, PaginationStatic} from "../../action/Pagination";
import Icon from "../../display/Icon";
import CheckboxInput from "../../input/CheckboxInput";
import DataTableRow from "./DataTableRow";
import {selectors} from "Core/store/reducers";
import {isBreakpointSmallerOrEqual} from "Core/helpers/dom";
import SelectInput from "Core/components/input/SelectInput";
import {ASYNC_SELECT_INPUT_TOOLBAR_POSITION} from "Core/components/input/SelectAsyncInput/const";
import {BUTTON_DISPLAY_TYPE} from "Core/components/display/Button";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	currentBreakpointName: selectors.breakpoint.getCurrentBreakpointName(state),
});

class DataTable extends BaseComponent {
	constructor(props) {
		super(props, { 
			translationPath: 'DataTable',
			domManipulationIntervalTimeout: 50,
		});

		// Initialize initial state
		this.initialState = {
			/**
			 * Selected data table rows
			 * @type {Object[]}
			 */
			selectedRows: [],
			/**
			 * Number of times a single sort column was clicked
			 * @note This is used to reset the sort on third click.
			 * @type {0|1|2}
			 */
			sortClickCount: 0,
		}

		// Set initial component's internal state
		this.state = cloneDeep(this.initialState);
		
		// Action handle methods
		this.handleSortClick = this.handleSortClick.bind(this);
		this.handleSortSelect = this.handleSortSelect.bind(this);
		this.handleRowClick = this.handleRowClick.bind(this);
		this.handlePaginationClick = this.handlePaginationClick.bind(this);

		// Select methods
		this.hasMaxSelection = this.hasMaxSelection.bind(this);
		this.clearSelection = this.clearSelection.bind(this);
		this.isRowSelected = this.isRowSelected.bind(this);
		this.areAllRowsOnPageSelected = this.areAllRowsOnPageSelected.bind(this);
		this.handleRowSelect = this.handleRowSelect.bind(this);
		this.handlePageSelect = this.handlePageSelect.bind(this);
		this.updateSelection = this.updateSelection.bind(this);
		this.addToSelection = this.addToSelection.bind(this);
		this.removeFromSelection = this.removeFromSelection.bind(this);
		
		// Render methods
		this.isSelectable = this.isSelectable.bind(this);
		this.getColSpan = this.getColSpan.bind(this);
		this.renderColumns = this.renderColumns.bind(this);
		this.renderMobileColumn = this.renderMobileColumn.bind(this);
		this.renderRows = this.renderRows.bind(this);
		this.hasPagination = this.hasPagination.bind(this);
		this.renderPagination = this.renderPagination.bind(this);
		this.hasItems = this.hasItems.bind(this);
	}

	/**
	 * Get component's main DOM element
	 * @note Component's main DOM element is usually the wrapper <div> or other wrapper DOM element.
	 * @return {HTMLElement}
	 */
	getDomElement() { return document.getElementById(this.getProp('id', `data-table-${this.getId()}`)); }

	/**
	 * Method called on each DOM manipulation interval if component has a defined DOM element (see 'getDomElement'
	 * method).
	 * @param {HTMLElement|Element|null} element - Component's main DOM element or null if component's main DOM element is
	 * not set.
	 */
	domManipulations(element) {
		if (element) {
			// Position the top row just below the table column names row
			const topRowElement = element.querySelector('.top-row');
			if (topRowElement) {
				topRowElement.childNodes[0].style.top = `${topRowElement.previousSibling.offsetHeight}px`;
			}
			
			// Handle clear selection row top position
			const clearSelectionElement = element.querySelector('.clear-selection');
			if (clearSelectionElement) {
				const top = (
					topRowElement ?
						clearSelectionElement.previousSibling.offsetTop + clearSelectionElement.previousSibling.offsetHeight :
						clearSelectionElement.previousSibling.offsetHeight 
				);
				clearSelectionElement.childNodes[0].style.top = `${top}px`;
			}

			// Handle data table height (see 'limitToAvailableSpace' prop)
			if (this.getProp('limitToAvailableSpace') === true) {
				let maxHeight;
				
				// If table is located inside a popup tab component and there are global action buttons at the bottom
				const popupTabContent = element.closest('.popup-tab-content');
				if (popupTabContent) {
					maxHeight =
						popupTabContent.offsetHeight - getElementAbsolutePos(element, popupTabContent).top -
						getNumber(window.getComputedStyle(popupTabContent, ':after').height) -
						getNumber(window.getComputedStyle(popupTabContent).paddingBottom)
				} else {
					let layoutPageElem = document.querySelector('.layout-page');
					if (!layoutPageElem) layoutPageElem = document.querySelector('.layout-content');
					const layoutFooter = document.querySelector('.layout-footer');
					maxHeight =
						window.innerHeight - getElementAbsolutePos(element).top -
						(layoutPageElem ? getNumber(window.getComputedStyle(layoutPageElem, ':after').height) : 0) -
						(layoutPageElem ? getNumber(window.getComputedStyle(layoutPageElem).paddingBottom) : 0) -
						(layoutFooter ? getNumber(window.getComputedStyle(layoutFooter).height) : 0)
					;
				}
				
				element.style.maxHeight = `${maxHeight}px`;
			} else {
				element.style.maxHeight = '';
			}

			// Handle scrollbar visibility CSS class
			if (vertScrollbarVisible(element)) element.classList.add('has-vert-scrollbar', styles['v-scroll']);
			else element.classList.remove('has-vert-scrollbar', styles['v-scroll']);
			if (horScrollbarVisible(element)) element.classList.add('has-hor-scrollbar', styles['h-scroll']);
			else element.classList.remove('has-hor-scrollbar', styles['h-scroll']);
		}
	}

	// Action handle methods --------------------------------------------------------------------------------------------
	/**
	 * Sort column click handle method
	 * @note Data is sorted by calling an external sort function defined in component props as "onSortByColumn" if it 
	 * exists.
	 *
	 * @param {string} column - Column name to sort data by.
	 * @return {Promise<any>} Promise that resolves to the result of the executed event callback function or null if
	 * function does not exist or no data if sort event callback function was not celled at all.
	 */
	handleSortClick(column) {
		const {sortBy, sortDir, totalRows, paginationType} = this.props;
		const {sortClickCount} = this.state;

		if (paginationType === PAGINATION_TYPE.STATIC || totalRows > 0) {
			// If sorting by another column or sorting for the first time, use ascending order because it's the default 
			// direction
			let newSortDir = SORT_ORDER.ASC;
			
			// If same column is sorted again
			if (sortBy === column) {
				let newSortClickCount = sortClickCount + 1;
				
				// Remove sort if column was sorted three times
				if (newSortClickCount === 2) {
					newSortDir = SORT_ORDER.ASC;
					column = '';
					newSortClickCount = 0;
				}
				// Flip the sort direction
				else {
					newSortDir = (sortDir === SORT_ORDER.ASC) ? SORT_ORDER.DESC : SORT_ORDER.ASC;
				}
				
				// Update the sort click count
				this.setState({sortClickCount: newSortClickCount}).then();
			}
			// If another column is sorted
			else {
				// Reset the sort click count
				this.setState({sortClickCount: 0}).then();
			}

			// Trigger 'onSortByColumn' event
			return executeComponentCallbackPromise(this.props.onSortByColumn, column, newSortDir);
		}
		return Promise.resolve();
	}

	/**
	 * Handle selecting a sort from sort select input
	 * @note Usually used on mobile layout.
	 * 
	 * @param {string} selectOption - Simple select option '[SORT_BY]|[SORT_DIR]'.
	 * @return {Promise<void>|Promise<*>}
	 */
	handleSortSelect(selectOption) {
		const {totalRows, paginationType} = this.props;

		const selectedSortBy = getString(getString(selectOption).split('|'), '[0]');
		const selectedSortDir = getString(getString(selectOption).split('|'), '[1]', SORT_ORDER.ASC, true);

		if (paginationType === PAGINATION_TYPE.STATIC || totalRows > 0) {
			// Trigger 'onSortByColumn' event
			return executeComponentCallbackPromise(this.props.onSortByColumn, selectedSortBy, selectedSortDir);
		}
		return Promise.resolve();
	}

	/**
	 * Row click handle method
	 * @note This method calls an external function defined in props as "onRowClick" if it exists.
	 *
	 * @param {Object} row - Clicked row.
	 * @param {number} index - Index of the clicked row in table.
	 * @return {Promise<any>} Promise that resolves to the result of the executed event callback function or null if 
	 * function does not exist.
	 */
	handleRowClick(row, index) { return executeComponentCallbackPromise(this.props.onRowClick, row, index); }

	/**
	 * Pagination button click handle method
	 * @param {number} pageNo - Page number of the clicked pagination button.
	 * @return {Promise<any>} Promise that resolves to the result of the executed event callback function or null if
	 * function does not exist.
	 */
	handlePaginationClick(pageNo) { return executeComponentCallbackPromise(this.props.onPaginationClick, pageNo); }


	// Select methods ---------------------------------------------------------------------------------------------------
	/**
	 * Check if there is a max. selection limit
	 * @return {boolean}
	 */
	hasMaxSelection() {
		const {maxSelection} = this.props;
		return (isset(maxSelection) && isFinite(maxSelection));
	}
	
	/**
	 * Clear row selection on all pages
	 * @return {Promise<Object>} Promise that resolves to entire component local state after state is updated.
	 */
	clearSelection() { 
		return this.setState({selectedRows: []})
			.then(state => executeComponentCallbackPromise(this.props.onSelect, state.selectedRows));
	}

	/**
	 * Check if row is selected
	 * @param {Object} row - Row data object.
	 * @return {boolean}
	 */
	isRowSelected(row) { 
		const {primaryKeyColumn} = this.props;
		const {selectedRows} = this.state;
		return selectedRows.map(i => get(i, primaryKeyColumn)).includes(get(row, primaryKeyColumn));
	}

	/**
	 * Check if all rows on the current page are selected
	 * @return {boolean} True if all rows on the current page are selected, false otherwise.
	 */
	areAllRowsOnPageSelected() {
		const {primaryKeyColumn, canSelectRow} = this.props;
		const {selectedRows} = this.state;
		const selectedRowIds = selectedRows.map(i => get(i, primaryKeyColumn));
		const pageRowIds = map(
			getArray(this.props, 'data').filter(row => {
				// Do not count rows that return false in "canSelectRow"
				if (!!canSelectRow && typeof canSelectRow === 'function') return canSelectRow(row);
				else return true;
			}), 
			primaryKeyColumn
		);
		return (pageRowIds.length > 0 && difference(pageRowIds, selectedRowIds).length === 0);
	}

	/**
	 * Handle row select checkbox change
	 * @note This method will not select the row if max. number of selected rows (defined by the 'maxSelection' prop) 
	 * will be exceeded by adding it.
	 * 
	 * @param {boolean} checked - CheckboxInput component check value after click.
	 * @param {Object} row - Row data object. 
	 * @return {Promise<Object>} Promise that resolves to entire component local state after state is updated.
	 */
	handleRowSelect(checked, row) {
		return this.setState((state, props) => {
			const {primaryKeyColumn} = props;
			if (checked) {
				const maxSelection = getNumber(props.maxSelection);
				const selectionCount = state.selectedRows.length;
				
				// Add row to selection list only if max. selection will not be exceeded 
				if ((this.hasMaxSelection() && selectionCount < maxSelection) || !this.hasMaxSelection()) {
					return {selectedRows: [...state.selectedRows, row]};
				}
			} else {
				return { 
					selectedRows: reject(state.selectedRows, {[primaryKeyColumn]: get(row, primaryKeyColumn)}) 
				};
			}
		}).then(state => executeComponentCallbackPromise(this.props.onSelect, state.selectedRows));
	}

	/**
	 * Handle page select checkbox change
	 * @note This method will only select the max. number of rows if 'maxSelection' prop is defined and greater than 0.
	 * 
	 * @param {boolean} checked - CheckboxInput component check value after click.
	 * @return {Promise<Object>} Promise that resolves to entire component local state after state is updated.
	 */
	handlePageSelect(checked) {
		return this.setState((state, props) => {
			const {primaryKeyColumn, data, maxSelection, canSelectRow} = props;
			const {selectedRows} = state;
			const pageRows = getArray(data).filter(row => {
				if (!!canSelectRow && typeof canSelectRow === 'function') return canSelectRow(row);
				else return true;
			});
			let updatedSelectedRows = cloneDeep(selectedRows);
			
			// Add all page rows to selected list without exceeding the max. selection limit
			if (checked) {
				let rowsToAdd = [...pageRows];
				if (this.hasMaxSelection()) {
					const numberOfRowsToAdd = getNumber(maxSelection) - selectedRows.length;
					rowsToAdd = pageRows.filter(r => !this.isRowSelected(r)).slice(0, numberOfRowsToAdd);
				}
				rowsToAdd.forEach(pageRow => {
					if (!find(selectedRows, {[primaryKeyColumn]: get(pageRow, primaryKeyColumn)})) {
						updatedSelectedRows = [...updatedSelectedRows, pageRow];
					}
				});
			}
			// Remove all page rows from selected list
			else {
				pageRows.forEach(pageRow => {
					if (find(selectedRows, {[primaryKeyColumn]: get(pageRow, primaryKeyColumn)})) {
						updatedSelectedRows = reject(updatedSelectedRows, {
							[primaryKeyColumn]: get(pageRow, primaryKeyColumn)
						});
					}
				});
			}
			
			return {selectedRows: updatedSelectedRows};
		}).then(state => executeComponentCallbackPromise(this.props.onSelect, state.selectedRows));
	}

	/**
	 * Update selected rows list
	 * @note This method will only select the max. number of rows if 'maxSelection' prop is defined and greater than 0.
	 * 
	 * @param {Object[]} selectedRows - Selected rows.
	 * @return {Promise<Object>} Promise that resolves to entire component local state after state is updated.
	 */
	updateSelection(selectedRows) {
		const maxSelection = getNumber(this.props, 'maxSelection');
		if (this.hasMaxSelection()) return this.setState({selectedRows: selectedRows.slice(0, maxSelection)});
		else return this.setState({selectedRows});
	}

	/**
	 * Add row to selected rows list
	 * @note This method will not add the row to selection if max. number of selected rows (defined by the 'maxSelection'
	 * prop) will be exceeded by adding it.
	 * 
	 * @param {Object} row - Row to add to selection.
	 * @return {Promise<Object>} Promise that resolves to entire component local state after state is updated.
	 */
	addToSelection(row) {
		const maxSelection = getNumber(this.props, 'maxSelection');
		const selectionCount = getArray(this.state, 'selectedRows').length;
		
		if ((this.hasMaxSelection() && selectionCount < maxSelection) || !this.hasMaxSelection()) {
			return this.setState({selectedRows: [...this.state.selectedRows, row]});
		} else {
			return Promise.resolve(this.state);
		}
	}

	/**
	 * Remove row from selected rows list
	 * 
	 * @param {Object|string|number} row - Row object or primary key column value of the row to remove from selection.
	 * @return {Promise<Object>} Promise that resolves to entire component local state after state is updated.
	 */
	removeFromSelection(row) {
		const {primaryKeyColumn} = this.props;
		const rowPrimaryKey = (typeof row === 'string' || typeof row === 'number') ? row : get(row, primaryKeyColumn);
		
		return this.setState({
			selectedRows: reject(this.state.selectedRows, {[primaryKeyColumn]: rowPrimaryKey})
		});
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Check if data table rows are selectable
	 * @return {boolean}
	 */
	isSelectable() { return this.props.selectable; }

	/**
	 * Get the colSpan value that can be used to span the whole table columns
	 * @return {number}
	 */
	getColSpan() {
		return (
			reject( getArray(this.props, 'columns'), {hide: true}).length + 
			(getBoolean(this.props, 'showRowNumber') ? 1 : 0) +
			(this.isSelectable() ? 1 : 0)
		);
	}
	
	/**
	 * Render data table columns (table header)
	 */
	renderColumns() {
		const {sortBy, sortDir} = this.props;
		const columns = getArray(this.props, 'columns');
		
		// Extract single column data used for rendering
		const columnSortName = column => getString(column, 'sortName');
		const isColumnSortable = column => !!columnSortName(column);
		const isActionColumn = column => (getString(column, 'dataType') === DATA_TABLE_CELL_TYPE.ACTION);
		const isActionsColumn = column => (getString(column, 'dataType') === DATA_TABLE_CELL_TYPE.ACTIONS);
		const isColumnHidden = column => getBoolean(column, 'hide');
		
		return (
			columns.map((column, index) =>
				!isColumnHidden(column) ?
					<th
						key={index}
						className={
							`column ${styles['column']}` +
							` column-${safeCssName(column.name)} ` +
							(isColumnSortable(column) ? ` sortable ${styles['sortable']}` : '') +
							(isColumnSortable(column) && sortBy === columnSortName(column) ? ` sort` : '') +
							(isActionColumn(column) ? ` action` : '') +
							(isActionsColumn(column) ? ` actions` : '')
						}
						style={{
							width: (has(column, 'width') ? column.width : ''),
							minWidth: (has(column, 'minWidth') ? column.minWidth : ''),
							whiteSpace: (getBoolean(column, 'widthLessThanLabel') ? 'normal' : 'nowrap')
						}}
						onClick={isColumnSortable(column) ? () => this.handleSortClick(column.sortName) : null}
					>
						<Label
							content={column.label}
							tooltip={column.tooltip}
							icon={column.tooltip ? `question-circle help ${styles['column-help-icon']}` : ''}
							iconPosition={LABEL_ICON_POSITION.RIGHT}
							prefix={(
								isColumnSortable(column) ?
									<span className={icon_font_stack_class}>
										<Icon 
											symbol="sort" 
											className={`sort-icon-main ${styles['sort-icon-main']} ${icon_font_stacked_item_class}`} 
										/>
										{sortBy === columnSortName(column) && sortDir === SORT_ORDER.DESC ?
											<Icon 
												symbol="sort-desc" 
												className={
													`sort-icon-active ${styles['sort-icon-active']} ${icon_font_stacked_item_class}`
												} 
											/> 
												: null
										}
										{sortBy === columnSortName(column) && sortDir === SORT_ORDER.ASC ?
											<Icon 
												symbol="sort-asc" 
												className={
													`sort-icon-active ${styles['sort-icon-active']} ${icon_font_stacked_item_class}`
												} 
											/> 
											: null
										}
									</span>
									: null
							)}
							element="div"
							elementProps={{
								className: `content ${styles['content']}`
							}}
						/>
					</th>
					: null
			)
		);
	}

	/**
	 * Render a mobile table column
	 * @note This is rendered if table is responsive and in mobile mode.
	 * @return {JSX.Element}
	 */
	renderMobileColumn() {
		const {sortBy, sortDir} = this.props;
		const columns = getArray(this.props, 'columns');
		const selectable = this.isSelectable();
		
		// Extract single column data used for rendering
		const columnSortName = column => getString(column, 'sortName');
		const isColumnSortable = column => !!columnSortName(column);
		
		let sortableColumns = [];
		columns.filter(c => isColumnSortable(c)).forEach(c => {
			sortableColumns.push({
				label: `"${c.label}" ${this.t(SORT_ORDER.ASC, 'constants.sort_order')}`, 
				value: `${c.sortName}|${SORT_ORDER.ASC}`,
			});
			sortableColumns.push({
				label: `"${c.label}" ${this.t(SORT_ORDER.DESC, 'constants.sort_order')}`,
				value: `${c.sortName}|${SORT_ORDER.DESC}`,
			});
		});
		
		if (!selectable && sortableColumns.length === 0) return null;
		return (
			<th className={`mobile-column ${styles['mobileColumn']}`}>
				{selectable ?
					<CheckboxInput
						className={`select-checkbox ${styles['select-checkbox']}`}
						size={24}
						checked={this.areAllRowsOnPageSelected()}
						onChange={this.handlePageSelect}
					/>
					: null
				}

				{sortableColumns.length > 0 ?
					<SelectInput
						className={`sort-select ${styles['sort-select']}`}
						isClearable={true}
						isSearchable={false}
						value={`${sortBy}|${sortDir}`}
						options={sortableColumns}
						onChange={this.handleSortSelect}
						placeholder={this.t('Sort by ...')}
						toolbarButtons={[
							{
								className: 'search-btn',
								position: ASYNC_SELECT_INPUT_TOOLBAR_POSITION.LEFT,
								icon: 'sort',
								displayType: BUTTON_DISPLAY_TYPE.TRANSPARENT,
							},
						]}
					/>
					: null
				}
			</th>
		);
	}

	/**
	 * Render data table rows (table body)
	 */
	renderRows() {
		const {
			highlightOnHover, highlightedRows, showRowNumber, perPage, pageNo, onRowClick, primaryKeyColumn, responsive,
			responsiveBreakpointName, currentBreakpointName, showMobileContentColumnLabel, canSelectRow, noDataContent
		} = this.props;
		const isMobile = (responsive && isBreakpointSmallerOrEqual(currentBreakpointName, responsiveBreakpointName));
		const selectable = this.isSelectable();
		const data = getArray(this.props, 'data');
		const columns = getArray(this.props, 'columns');
		
		return (
			<tbody className={
				(highlightOnHover || onRowClick ? ` highlighted-hover ${styles['hover']} ` : '') +
				(onRowClick ? ` clickable ${styles['clickable']} ` : '')
			}>
				{
					data.length > 0 ?
						data.map((row, index) =>
							<DataTableRow
								key={index}
								data={row}
								index={index}
								tableSelectable={selectable}
								selectable={
									selectable && !!canSelectRow && typeof canSelectRow === 'function' ?
										canSelectRow(row) :
										selectable
								}
								isRowSelected={this.isRowSelected(row)}
								columns={columns}
								primaryKeyColumn={primaryKeyColumn}
								highlightedRows={highlightedRows}
								showRowNumber={showRowNumber}
								pageNo={pageNo}
								perPage={perPage}
								isMobile={isMobile}
								showMobileContentColumnLabel={showMobileContentColumnLabel}
								onClick={this.handleRowClick}
								onSelect={this.handleRowSelect}
							/>
						)
						: 
						<tr className={`no-data ${styles['noData']}`}>
							<td 
								colSpan={this.getColSpan()}
								className={`content ${styles['content']}`}
							>{isset(noDataContent) ? noDataContent : this.t('No data', 'general')}</td>
						</tr>
				}
			</tbody>
		);
	}

	/**
	 * Check if data table has pagination
	 * @return {boolean}
	 */
	hasPagination() {
		const {paginationType, totalRows, perPage} = this.props;
		return !(
			!this.hasItems() || perPage < 1 || !paginationType || 
			(paginationType === PAGINATION_TYPE.DYNAMIC && totalRows < 1)
		);
	}

	/**
	 * Render data table pagination
	 */
	renderPagination() {
		const {paginationType, isLastPage, totalRows, pageNo, perPage, paginationOptions} = this.props;
		
		// Don't render pagination if it there is not need for it
		if (!this.hasPagination()) return null;
		
		return (
			<div className={`data-table-pagination ${styles['pagination']}`}>
				{
					paginationType === PAGINATION_TYPE.STATIC ?
						<PaginationStatic
							pageNo={pageNo}
							perPage={perPage}
							isLastPage={isLastPage}
							pageItemsCount={getArray(this.props, 'data').length}
							onPageSelect={this.handlePaginationClick}
							{...paginationOptions}
						/>
					: paginationType === PAGINATION_TYPE.DYNAMIC ?
						<Pagination
							pageNo={pageNo}
							perPage={perPage}
							totalResults={totalRows}
							onPageSelect={this.handlePaginationClick}
							{...paginationOptions}
						/>
					: null
				}
			</div>
		);
	}

	/**
	 * Check if data table has any items
	 * @return {boolean}
	 */
	hasItems() { return (getArray(this.props, 'data').length > 0); }
	
	render() {
		const {
			className, showRowNumber, minWidth, showClearSelection, responsive, currentBreakpointName, 
			responsiveBreakpointName, topRowContent
		} = this.props;
		const selectable = this.isSelectable();
		const {selectedRows} = this.state;
		const isMobile = (responsive && isBreakpointSmallerOrEqual(currentBreakpointName, responsiveBreakpointName));
		
		return (
			<div
				id={this.getProp('id', `data-table-${this.getId()}`)}
				className={
					`data-table-component ${className} ${styles['wrapper']}` + 
					(!this.hasItems() ? ` empty ${styles['empty']} ` : '') +
					(isMobile ? ` mobile-data-table ${styles['mobile']}` : '')
				}
			>
				<table 
					className={
						`data-table ${styles['table']} ` +
						(this.hasPagination() ? 
							` with-pagination ${styles['withPagination']} ` : 
							` without-pagination `
						)
					} 
					style={{minWidth: getNumber(minWidth)}}
				>
					<thead>
						<tr>
							{selectable && !isMobile ?
								<th className={`column select ${styles['column']} ${styles['select-row']}`}>
									<div className={`content ${styles['content']}`}>
										<CheckboxInput
											className={`select-checkbox ${styles['select-checkbox']}`}
											size={18}
											checked={this.areAllRowsOnPageSelected()} 
											onChange={this.handlePageSelect} 
										/>
									</div>
								</th> : null
							}
							
							{showRowNumber && !isMobile ? 
								<th className={`column num-row ${styles['column']} ${styles['num-row']}`}>
									<span className={`content ${styles['content']}`}>#</span>
								</th> : null
							}
							{isMobile ? this.renderMobileColumn() : this.renderColumns()}
						</tr>
						{isset(topRowContent) ?
							<tr className={`top-row ${styles['top-row']}`}>
								<th colSpan={this.getColSpan()}>
									<div className={`content ${styles['content']}`}>{topRowContent}</div>
								</th>
							</tr>
							: null
						}
						{
							selectedRows.length > 0 && showClearSelection ?
								<tr className={`clear-selection ${styles['clear-selection']}`}>
									<th colSpan={this.getColSpan()}>
										<Label
											content={`${this.t('Clear selection')} (${selectedRows.length})`}
											element="div"
											elementProps={{
												className: `content ${styles['content']}`,
												onClick: this.clearSelection
											}}
										/>
									</th>
								</tr>
								: null
						}
					</thead>

					{this.renderRows()}
				</table>
				
				{this.renderPagination()}
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
DataTable.propTypes = {
	// Data table wrapper element id attribute
	id: PropTypes.string,
	// Data table wrapper element class attribute
	className: PropTypes.string,
	// Data table main data (rows)
	data: PropTypes.array,
	// Data table columns
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			// Column name
			name: PropTypes.string,
			// Column label to display as table column name (in thead)
			label: PropTypes.string,
			// Tooltip to display next to column label
			tooltip: PropTypes.string,
			// Column sort name used for sort IO (sort name can be different from column name)
			sortName: PropTypes.string,
			// Table column width in pixels (ex: "100px", 100)
			// @note Use 1 or '1px' for auto width column.
			width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			// Table column min-width in pixels (ex: "100px", 100)
			// @note Use 1 or '1px' for auto width column.
			minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			// Flag that determines if column width can be smaller that the rendered label width
			// @note If true, column might not have the exact with as specified by 'width' property.
			widthLessThanLabel: PropTypes.bool,
			// Flag to stop the click propagation for columns that contain links when table rows are clickable
			stopPropagation: PropTypes.bool,
			// Column data type
			// @type {DataTableCellType}
			dataType: PropTypes.oneOf(DATA_TABLE_CELL_TYPES),
			// Data type specific options
			// @see src/core/components/advanced/DataTable/DataTableCell/dataObjects.js
			dataTypeOptions: PropTypes.object,
			// Flag that determines if column should be hidden/not rendered
			hide: PropTypes.bool,
			// Default value if value is undefined or null
			// @note Empty string values will be rendered as is since that is considered to be a valid value.
			defaultValue: PropTypes.any,
			// Flag that specifies if empty values will be replaced with the 'defaultValue'
			emptyAsDefault: PropTypes.bool,
		})
	),
	// Name of the primary key column
	// @note This is required if rows need to be selected ('selectable' prop is true). Column must exist in 'columns' 
	// prop.
	primaryKeyColumn: PropTypes.string,
	// Flag that determines if rows can be selectable
	// @note CheckboxInput will be displayed as a first table column. If 'primaryKeyColumn' is not set this will be 
	// ignored and rows will not be selectable.
	selectable: PropTypes.bool,
	// Function that must return boolean value that will determine if the row is selectable
	// @type {function(row: Object): boolean}
	canSelectRow: PropTypes.func,
	// Flag that specifies if clear selection button will be shown as the first row in the table body
	showClearSelection: PropTypes.bool,
	// Max. number of selected rows
	maxSelection: PropTypes.number,
	// Flag that determines if rows will be highlighted on mouse hover
	// @note If not defined and rows are clickable ('onRowClick' prop is defined) this will be true by default.
	highlightOnHover: PropTypes.bool,
	// Row highlight specification 
	highlightedRows: PropTypes.arrayOf(
		PropTypes.shape({
			// Class name that will be added to all rows in the 'rows' property
			// @note This class name represents the highlight which means that skin should already have CSS styles for it.
			className: PropTypes.string,
			// Style object that will be added to all rows in the 'rows' property
			style: PropTypes.oneOfType([
				// Style object that cna be used in JSX (camelCase keys)
				// @example {backgroundColor: '#ccc', color: '#333'}
				PropTypes.object, 
				// String CSS style declaration that will be converted to the object
				// @example 'background-color: #ccc; color: #333;'
				PropTypes.string
			]),
			// Rows to get the 'className' property
			// @note This is an array of row objects from 'data' prop.
			rows: PropTypes.oneOfType([
				PropTypes.arrayOf(PropTypes.object), 
				PropTypes.arrayOf(PropTypes.string), 
				PropTypes.arrayOf(PropTypes.number)
			])
		})
	),
	// If true, a new first column will be rendered with row numbers
	// @note Row numbers start from 1.
	showRowNumber: PropTypes.bool,
	// Flag that specifies if table height will be limited to the space available
	// @description If true, data table will have a max. height according to the space available between it and the 
	// bottom of the page.
	limitToAvailableSpace: PropTypes.bool,
	// Minimal data table width
	minWidth: PropTypes.number,
	
	// Pagination
	paginationType: PropTypes.oneOf(PAGINATION_TYPES),
	totalRows: PropTypes.number, // Used ony for PAGINATION_TYPE.DYNAMIC
	isLastPage: PropTypes.bool, // Used ony for PAGINATION_TYPE.STATIC
	pageNo: PropTypes.number,
	perPage: PropTypes.number,
	paginationOptions: PropTypes.object, // Pagination component options/props

	// Sort
	sortBy: PropTypes.string,
	sortDir: PropTypes.string,
	
	// Responsive
	responsive: PropTypes.bool,
	responsiveBreakpointName: PropTypes.string,
	showMobileContentColumnLabel: PropTypes.bool,
	
	// Content rendered as the first row below the column names row in the table head
	// @note Only undefined value will not render the standard top row wrapper. All other values will render the standard
	// placeholder element for the top row content that has certain height including padding.
	topRowContent: PropTypes.any,
	// Content rendered when there is no data in the table
	// @note If undefined, default "No data" message will be rendered.
	noDataContent: PropTypes.any,

	// Events
	onRowClick: PropTypes.func, // Arguments: row, index
	onSortByColumn: PropTypes.func, // Arguments: column, newSortDir
	onPaginationClick: PropTypes.func, // Arguments: pageNo
	onSelect: PropTypes.func, // Arguments: {Object[]} selected rows
};

/**
 * Define component default values for own props
 */
DataTable.defaultProps = {
	className: 'standard',
	showRowNumber: false,
	paginationType: PAGINATION_TYPE.STATIC,
	pageNo: 1,
	perPage: pagination_default_per_page,
	showClearSelection: true,
	responsive: false,
	responsiveBreakpointName: 'bp-s',
	showMobileContentColumnLabel: true,
};

export default connect(mapStateToProps, null, null, {forwardRef: true})(DataTable);
export * from "./const";

// TODO: Implement tfoot as fixed columns that can be used, for example, for column totals.