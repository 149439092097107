import {cloneDeep, get} from "lodash";
import {getDate, getDateString, STANDARD_DATE_TIME_FORMAT} from "Core/helpers/datetime";
import {getString, resetObjectValues} from "Core/helpers/data";
import {PatientDataObject} from "DataObjects/patient";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {PatientDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new PatientDataObject(
		getString(result, 'uniqueID', null),
		getString(result, 'ownerId'),
		getString(result, 'firstName'),
		getString(result, 'middleName'),
		getString(result, 'lastName'),
		getString(result, 'telephone'),
		getString(result, 'mobilePhone'),
		getString(result, 'email'),
		getDate(get(result, 'birthDate'), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		getString(result, 'address'),
		getString(result, 'anamnesis'),
	);

	return result;
}

/**
 * Get output data from input data
 * @param {PatientDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep(inputData);

	result = {
		uniqueID: result.id,
		ownerId: result.ownerId,
		firstName: result.firstName,
		middleName: result.middleName,
		lastName: result.lastName,
		telephone: result.telephone,
		mobilePhone: result.mobilePhone,
		email: result.email,
		birthDate: getDateString(result.birthDate, STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		address: result.address,
		city: result.city,
		recommendationId: result.recommendationId,
		anamnesis: result.anamnesis,
	};

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}