import React from "react";
import DialogDataComponent, {executeComponentCallback} from 'Core/components/DialogDataComponent';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {PhysicianDataObject} from 'DataObjects/physician';
import DataValueValidation from "Core/validation";
import FormWrapper, {FormField} from "Core/components/advanced/FormWrapper";
import TextInput from "Core/components/input/TextInput";
import * as pageConfig from "Pages/apps/default/physicians";
import {getString, isset} from 'Core/helpers/data';
import {createPhysiciansItemAction, updatePhysiciansItemAction} from "Pages/apps/default/physicians/actions";
import {getPageActions} from 'Core/helpers/redux';

/**
 * Get all actions used by this component
 * @type {Object}
 */
const allActions = getPageActions({createPhysiciansItemAction, updatePhysiciansItemAction});

class PhysicianDialog extends DialogDataComponent {
	constructor(props) {
		super(props, {
			data: props.isNew ? new PhysicianDataObject() : null,
		}, {
			translationPath: `${pageConfig.translationPath}.ItemPopup.MainTab`,
			enableLoadOnDataPropChange: true,
			disableLoad: props.isNew,
		});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		return super.componentDidUpdate(prevProps, prevState, snapshot)
			// Update data loading if 'isNew' changes
			// @note This will happen when opening an existing item until the item data is loaded or when creating a new 
			// item and redirecting to it after it has been successfully created.  
			.then(state => {
				if (this.props.isNew !== prevProps.isNew) {
					this.setOption('disableLoad', this.props.isNew);
					if (prevProps.isNew) return this.load(this.getProp('data'));
					else return this.load(new PhysicianDataObject(), undefined, true);
				}
				return Promise.resolve(state);
			});
	}
	
	/**
	 * Default component's data validation method
	 *
	 * @return {boolean} True if component's data validation passed successfully.
	 */
	validate() {
		const dataValidation = new DataValueValidation();
		const dataToValidate = this.getData();

		dataValidation.addRule('firstName', 'required');
		dataValidation.addRule('lastName', 'required');
		dataValidation.addRule('email', 'email');

		// Run validation
		const validationErrors = dataValidation.run(dataToValidate);

		if (validationErrors) this.setValidationErrors('', validationErrors).then();
		else this.clearValidationErrors().then();
		return !validationErrors;
	}
	
	/**
	 * Save dialog method
	 * @note This method should be called when dialog's "save" button is clicked. This method does not actually save any
	 * data to the DB or anywhere else. That should be done by the parent component.
	 */
	async save() {
		const {isNew, dialogGUIID, createPhysiciansItemAction, updatePhysiciansItemAction} = this.props;
		const data = this.getDataToReturn();

		// Do the validation
		const isValid = this.validate();

		// If validation is successful
		if (isValid) {
			const physician = (
				isNew ? 
					await this.executeAbortableAction(createPhysiciansItemAction, data) :
					await this.executeAbortableAction(updatePhysiciansItemAction, data.id, data, false)
			);

			if (isset(physician)) {
				this.close();
				// Trigger component's onSave event if physician was saved successfully
				executeComponentCallback(this.props.onSave, physician, dialogGUIID);
			}
		}
	}

	/**
	 * Method that should return true if component can be rendered or false otherwise
	 * @return {boolean} True if component can be rendered or false otherwise.
	 */
	canRender() {
		const data = this.getData();
		// Data must be set in order for the component to be rendered. New items have an empty data object and existing 
		// items have a fully loaded data object. Data can be null while existing items' data is being loaded in which 
		// case noting should be rendered (loading overlay should be handled by the action that is loading the data). 
		return (data !== null);
	}

	render() {
		// Do not render component if 'canRender' returns false
		if (!this.canRender()) return null;
		const {isNew} = this.props;
		
		/** @type {PhysicianDataObject} */
		const data = this.getData();
		
		return this.renderDialog(
			this.renderTitle(
				isNew ? 
					this.t('new_title') :
					`${getString(this.props.data, 'firstName')} ${getString(this.props.data, 'lastName')}`
			),
			(
				<FormWrapper className="dialog-form">
					<FormField
						label={this.t('titleField')}
						errorMessages={this.getValidationErrors('title')}
					>
						<TextInput
							name="title"
							value={data.title}
							onChange={this.handleInputChange}
						/>
					</FormField>

					<FormField
						required={true}
						label={this.t('firstNameField')}
						errorMessages={this.getValidationErrors('firstName')}
					>
						<TextInput
							name="firstName"
							value={data.firstName}
							onChange={this.handleInputChange}
							autosuggestAction={this.props.firstNameAutosuggestAction}
						/>
					</FormField>

					<FormField
						required={true}
						label={this.t('lastNameField')}
						errorMessages={this.getValidationErrors('lastName')}
					>
						<TextInput
							name="lastName"
							value={data.lastName}
							onChange={this.handleInputChange}
							autosuggestAction={this.props.lastNameAutosuggestAction}
						/>
					</FormField>

					<FormField
						label={this.t('telephoneField')}
						errorMessages={this.getValidationErrors('telephone')}
					>
						<TextInput
							name="telephone"
							value={data.telephone}
							onChange={this.handleInputChange}
						/>
					</FormField>

					<FormField
						label={this.t('mobilePhoneField')}
						errorMessages={this.getValidationErrors('mobilePhone')}
					>
						<TextInput
							name="mobilePhone"
							value={data.mobilePhone}
							onChange={this.handleInputChange}
						/>
					</FormField>

					<FormField
						label={this.t('emailField')}
						errorMessages={this.getValidationErrors('email')}
					>
						<TextInput
							name="email"
							value={data.email}
							onChange={this.handleInputChange}
						/>
					</FormField>

					<FormField
						label={this.t('addressField')}
						errorMessages={this.getValidationErrors('address')}
					>
						<TextInput
							name="address"
							value={data.address}
							onChange={this.handleInputChange}
						/>
					</FormField>

					<FormField
						label={this.t('institutionField')}
						errorMessages={this.getValidationErrors('institution')}
					>
						<TextInput
							name="institution"
							value={data.institution}
							onChange={this.handleInputChange}
						/>
					</FormField>
				</FormWrapper>
			)
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
PhysicianDialog.propTypes = {
	// Flag that specifies if this dialog is for creating a new item
	// @note This prop can be dynamically changed in the popup component.
	isNew: PropTypes.bool,
	// Physician data used when "isNew" prop is false (edit physician)
	// @type {PhysicianDataObject}
	data: PropTypes.object,
	
	// Event triggered if physician was saved successfully and dialog has already been closed
	// @param {PhysicianDataObject} physician, 
	// @param {string} dialogGUIID
	onSave: PropTypes.func,
	// Event triggered when dialog close method is called
	// @note Dialog will not be closed if event callback returns false.
	// @param {string} dialogGUIID
	onClose: PropTypes.func,
};

export default connect(null, allActions)(PhysicianDialog);