/**
 * Data object representing the search appointments list item
 * @note This is usually used in data table as a row. Params don't have default values since this data object is used to
 * represent data that actually exists.
 */
export class AppointmentsListItemDataObject {
	/**
	 * @param {string} id - Appointment's ID.
	 * @param {Date} date - Date of the appointment.
	 * @param {string} startTime - Time when the appointment starts.
	 * @param {string} endTime - Time when the appointment ends.
	 * @param {string} patientId - DB ID of the patient in the appointment.
	 * @param {string} patientFirstName - First name of the patient in the appointment.
	 * @param {string} patientLastName - Last name of the patient in the appointment.
	 * @param {string} officeLocation - Name of the office location where the appointment takes place.
	 * @param {string} officeLocationId - ID of the office location where the appointment takes place.
	 * @param {string} therapist - Therapist for the appointment.
	 * @param {string} therapistId - ID of the therapist for the appointment.
	 * @param {?string} therapyId - ID of the therapy for the appointment.
	 */
	constructor(
		id, date, startTime, endTime, patientId, patientFirstName, patientLastName, officeLocation, officeLocationId, 
		therapist, therapistId, therapyId
	) {
		this.id = id;
		this.date = date;
		this.startTime = startTime;
		this.endTime = endTime;
		this.patientId = patientId;
		this.patientFirstName = patientFirstName;
		this.patientLastName = patientLastName;
		this.officeLocation = officeLocation;
		this.officeLocationId = officeLocationId;
		this.therapist = therapist;
		this.therapistId = therapistId;
		this.therapyId = therapyId;
	}
}

export class AppointmentCreateDataObject {
	/**
	 * Constructs a new object for creating a new appointment
	 *
	 * @param {?string} [patientId=null] - The ID of the patient.
	 * @param {?string} [therapyId=null] - The ID of the therapy.
	 * @param {?Date} [date=null] - The appointment date.
	 * @param {?Date} [timeFrom=null] - The start time of the appointment.
	 * @param {?Date} [timeTo=null] - The end time of the appointment.
	 * @param {?string} [officeLocationId=null] - The ID of the office location.
	 * @param {?string} [therapistId=null] - The ID of the therapist.
	 * @param {?number} [appointmentNumber=1] - The appointment number.
	 * @param {DayOfWeek[]} [daysOfWeek=[]] - An array of days of the week for recurring appointments.
	 * @param {string} [note=''] - The appointment note.
	 */
	constructor(
		patientId = null, therapyId = null, date = null, timeFrom = null, timeTo = null, officeLocationId = null, 
		therapistId = null, appointmentNumber = 1, daysOfWeek = [], note = ''
	) {
		this.patientId = patientId;
		this.therapyId = therapyId;
		this.date = date;
		this.timeFrom = timeFrom;
		this.timeTo = timeTo;
		this.officeLocationId = officeLocationId;
		this.therapistId = therapistId;
		this.appointmentNumber = appointmentNumber;
		this.daysOfWeek = daysOfWeek;
		this.note = note;
	}
}

export class AppointmentUpdateDataObject {
	/**
	 * Constructs a new object for editing an existing appointment 
	 * 
	 * @param {?string} [id=null] - The appointment ID.
	 * @param {?Date} [date=null] - The appointment date.
	 * @param {?Date} [timeFrom=null] - The start time of the appointment.
	 * @param {?Date} [timeTo=null] - The end time of the appointment.
	 * @param {?string} [officeLocationId=null] - The ID of the office location.
	 * @param {?string} [therapistId=null] - The ID of the therapist.
	 * @param {?string} [therapyId=null] - The ID of the therapy.
	 * @param {string} [note=''] - The appointment note.
	 */
	constructor(
		id = null, date = null, timeFrom = null, timeTo = null, officeLocationId = null, therapistId = null, 
		therapyId = null, note = '',
	) {
		this.id = id;
		this.date = date;
		this.timeFrom = timeFrom;
		this.timeTo = timeTo;
		this.officeLocationId = officeLocationId;
		this.therapistId = therapistId;
		this.therapyId = therapyId;
		this.note = note;
	}
}

export class AnonymousAppointmentDataObject {
	/**
	 * @param {?string} [id=null] - DB ID of the appointment where null means it's a new appointment.
	 * @param {?Date} [date=null] - The appointment date.
	 * @param {?Date} [timeFrom=null] - The start time of the appointment.
	 * @param {?Date} [timeTo=null] - The end time of the appointment.
	 * @param {?string} [officeLocationId=null] - The ID of the office location.
	 * @param {?string} [therapistId=null] - The ID of the therapist.
	 * @param {string} [firstName=''] - The patient's first name.
	 * @param {string} [lastName=''] - The patient's last name.
	 * @param {string} [phoneNumber=''] - The patient's phone number.
	 * @param {string} [note=''] - Any anonymous appointment notes.
	 */
	constructor(
		id = null, date = null, timeFrom = null, timeTo = null, officeLocationId = null, therapistId = null,
		firstName = '', lastName = '', phoneNumber = '', note = ''
	) {
		this.id = id;
		this.date = date;
		this.timeFrom = timeFrom;
		this.timeTo = timeTo;
		this.officeLocationId = officeLocationId;
		this.therapistId = therapistId;
		this.firstName = firstName;
		this.lastName = lastName;
		this.phoneNumber = phoneNumber;
		this.note = note;
	}
}