import styles from "./index.module.css";

import React from "react";
import PageComponent from "Core/components/PageComponent";
import {connect} from "react-redux";
import auth from "../../../auth";
import * as pageConfig from "./config";
import * as actions from "./actions";
import {app_login_page_router_path} from "Config/app";
import {getGlobalActions} from "Core/helpers/redux";
import {isSuccessful} from "Core/helpers/io";
import {appHasLogin} from "Core/helpers/login";
import {hideLoading, showPageLoading} from "Core/helpers/loading";
import PasswordReset from "Core/components/advanced/PasswordReset";
import Label from "Core/components/display/Label";
import Icon from "Core/components/display/Icon";
import Button, {BUTTON_STYLE} from "Core/components/display/Button";
import AppName from "Core/components/display/AppName";
import {cloneDeep} from "lodash";
import {getString} from "Core/helpers/data";
import {auth_broadcast_channel, auth_broadcast_message_login} from "Config/auth";
import {AsyncMountError} from "Core/errors";

class CorePasswordResetPage extends PageComponent {
	/**
	 * Auth broadcast channel instance
	 * @type {BroadcastChannel}
	 */
	authBroadcastChannel = null
	
	constructor(props, options = {}, state = {}) {
		super(props, {
			layout: 'auth',
			routerPath: pageConfig.routerPath,
			domPrefix: 'password-reset-page',
			translationPath: pageConfig.translationPath,
			renderTitle: false,

			...cloneDeep(options)
		}, 'form_title');

		// Initiate component's state
		this.state = {
			/**
			 * Flag that specifies if password was successfully reset
			 * @type {boolean} 
			 */
			successful: false,
			
			...cloneDeep(state)
		}

		// Action methods
		this.resetPassword = this.resetPassword.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
		
		// Listen to log in broadcast message and redirect to home page
		this.authBroadcastChannel = new BroadcastChannel(auth_broadcast_channel);
		this.authBroadcastChannel.onmessage = event => {
			if (getString(event, 'data') === auth_broadcast_message_login) this.redirectToAuthorizedHome();
		}
	}

	componentWillUnmount() {
		super.componentWillUnmount();

		// Close user broadcast channel that was open when component mounted
		if (this.authBroadcastChannel) this.authBroadcastChannel.close();
	}


	// ACL methods ------------------------------------------------------------------------------------------------------
	/**
	 * Check if user is logged in by calling an auth ping API
	 * @note This is done on each individual section app page because page components (child components) are mounted
	 * before app and section components (parent components) and we need to display the loading overlay as soon as
	 * possible.
	 *
	 * @return {Promise<void>}
	 * @throws {AsyncMountError}
	 */
	checkLogin() {
		if (appHasLogin()) {
			// If there are no auth tokens present, user is not logged in
			if (!auth.hasTokens()) {
				// Do nothing and return a resolved promise
				return Promise.resolve();
			}

			const loading = (
				this.getOption('checkLoginLoadingFunction') ?
					this.getOption('checkLoginLoadingFunction')() :
					showPageLoading(true, true)
			);

			// Check the login by using the authorized ping request
			return this.executeAbortableAction(auth.authorizedPing, true, undefined, undefined, auth)
				// Redirect to home page (defined in app config) if user is already logged in
				// @note This is added in case someone opens this page directly.
				.then(() => this.redirectToAuthorizedHome())
				.then(() => { if (loading) hideLoading(loading); })
				.catch(error => {
					if (loading) hideLoading(loading);

					// If ping fails even after trying to refresh tokens, delete auth related tokens from storage
					// @note Logout was not called intentionally because we don't know which app specific tokens will be 
					// deleted on logout, so we cannot be sure that all of them should be deleted at this point. What we are 
					// sure is that auth related tokens should be deleted and that's exactly what we are doing.
					if (error.name !== 'AbortError') return auth.deleteAuthTokens();
					else throw new AsyncMountError('Login check aborted!');
				});
		} else {
			// Redirect to home page (defined in app config) if login is in not enabled
			// @note This is added in case someone opens this page directly.
			this.redirectToHome();
		}
	}


	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Reset account password
	 */
	resetPassword(newPassword, confirmPassword) {
		const {resetPasswordAction} = this.props;
		const hash = this.getUrlParam('t');

		return this.executeAbortableAction(resetPasswordAction, hash, newPassword, confirmPassword)
			// Set 'success' flag depending on the password reset action result
			.then(response => this.setState({successful: isSuccessful(response)}));
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {successful} = this.state;

		return this.renderLayout((
			!successful ?
				<PasswordReset
					styleName="card"
					className={`${styles['wrapper']}`}
					showAppName={true}
					appName={<AppName />}
					action={this.resetPassword}
				/>
				:
				<div className={`${styles['successWrapper']}`}>
					<div className={`${styles['appName']}`}><AppName /></div>
					<div className={`${styles['notice']}`}>
						<Icon symbol="check" className="page-notice-title-icon success-color" />
						<Label
							element="p"
							elementProps={{className: 'page-notice-title'}}
							content={this.t('success')}
						/>
						<Label
							element="p"
							elementProps={{className: 'page-notice'}}
							content={this.t('success_desc')}
						/>
					</div>
					
					<div className={`${styles['actions']}`}>
						<Button
							big={true}
							icon="unlock-alt"
							label={this.t('Login', 'Login')}
							displayStyle={BUTTON_STYLE.ACTION}
							onClick={() => this.redirectTo(app_login_page_router_path)}
						/>
					</div>
				</div>
		), undefined, undefined, {
			showHeader: true,
			footerJustifyContent: 'center',
			showRightSection: false,
		});
	}
}

/**
 * Export component itself (not the connected HOC) so it can be easily extended
 * @type {CorePasswordResetPage}
 */
export const CorePasswordResetPageComponent = CorePasswordResetPage;
export default connect(null, getGlobalActions(actions))(CorePasswordResetPage);