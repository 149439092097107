/** @typedef {string} PatientRecordDisplayType */
export const PATIENT_RECORD_DISPLAY_TYPE_SELECTED_THERAPY = 'SELECTED_THERAPY';
export const PATIENT_RECORD_DISPLAY_TYPE_NO_THERAPY = 'NO_THERAPY';
export const PATIENT_RECORD_DISPLAY_TYPE_ALL = 'ALL';
/** @enum {PatientRecordDisplayType} */
export const PATIENT_RECORD_DISPLAY_TYPE = {
	SELECTED_THERAPY: PATIENT_RECORD_DISPLAY_TYPE_SELECTED_THERAPY,
	NO_THERAPY: PATIENT_RECORD_DISPLAY_TYPE_NO_THERAPY,
	ALL: PATIENT_RECORD_DISPLAY_TYPE_ALL,
};
/** @type {PatientRecordDisplayType[]} */
export const PATIENT_RECORD_DISPLAY_TYPES = [
	PATIENT_RECORD_DISPLAY_TYPE_SELECTED_THERAPY,
	PATIENT_RECORD_DISPLAY_TYPE_NO_THERAPY,
	PATIENT_RECORD_DISPLAY_TYPE_ALL,
];


/** @typedef {string} PatientRecordDetailsTab */
export const PATIENT_RECORD_DETAILS_TAB_PRESCRIBED_THERAPIES = 'PRESCRIBED_THERAPIES';
export const PATIENT_RECORD_DETAILS_TAB_VISITS = 'VISITS';
export const PATIENT_RECORD_DETAILS_TAB_APPOINTMENTS = 'APPOINTMENTS';
export const PATIENT_RECORD_DETAILS_TAB_PAYMENTS = 'PAYMENTS';
export const PATIENT_RECORD_DETAILS_TAB_EXPERT_REPORTS = 'EXPERT_REPORTS';
/** @enum {PatientRecordDetailsTab} */
export const PATIENT_RECORD_DETAILS_TAB = {
	PRESCRIBED_THERAPIES: PATIENT_RECORD_DETAILS_TAB_PRESCRIBED_THERAPIES,
	VISITS: PATIENT_RECORD_DETAILS_TAB_VISITS,
	APPOINTMENTS: PATIENT_RECORD_DETAILS_TAB_APPOINTMENTS,
	PAYMENTS: PATIENT_RECORD_DETAILS_TAB_PAYMENTS,
	EXPERT_REPORTS: PATIENT_RECORD_DETAILS_TAB_EXPERT_REPORTS,
};
/** @type {PatientRecordDetailsTab[]} */
export const PATIENT_RECORD_DETAILS_TABS = [
	PATIENT_RECORD_DETAILS_TAB_PRESCRIBED_THERAPIES,
	PATIENT_RECORD_DETAILS_TAB_VISITS,
	PATIENT_RECORD_DETAILS_TAB_APPOINTMENTS,
	PATIENT_RECORD_DETAILS_TAB_PAYMENTS,
	PATIENT_RECORD_DETAILS_TAB_EXPERT_REPORTS
];
/** @type {Object<PatientRecordDetailsTab, {symbol: string, [symbolPrefix]: string}>} */
export const PATIENT_RECORD_DETAILS_TAB_ICON = {
	[PATIENT_RECORD_DETAILS_TAB_PRESCRIBED_THERAPIES]: {symbol: 'first-aid', symbolPrefix: 'icofont-'},
	[PATIENT_RECORD_DETAILS_TAB_VISITS]: {symbol: 'checked', symbolPrefix: 'icofont-'},
	[PATIENT_RECORD_DETAILS_TAB_APPOINTMENTS]: {symbol: 'ui-calendar', symbolPrefix: 'icofont-'},
	[PATIENT_RECORD_DETAILS_TAB_PAYMENTS]: {symbol: 'money', symbolPrefix: 'icofont-'},
	[PATIENT_RECORD_DETAILS_TAB_EXPERT_REPORTS]: {symbol: 'file-document', symbolPrefix: 'icofont-'},
};