import React from "react";
import BaseComponent from "../../BaseComponent";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import numeral from "../../../packages/numeral";
import {cloneDeep} from "lodash";
import {NUMBER_LABEL_ICON_POSITION} from "./const";
import {selectors} from "../../../store/reducers";

import Icon from "../Icon";

import {Tooltip} from "react-tippy";
import 'react-tippy/dist/tippy.css'
import {isFloat} from "./helper";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	appLocale: selectors.i18n.getLocale(state)
});

class NumberLabel extends BaseComponent {
	constructor(props) {
		super(props);

		// Set numeral locale
		numeral.locale(props.useAppLocale ? props.appLocale.locale.toLowerCase() : props.locale?.toLowerCase());

		// Initialize initial state
		this.initialState = {
			/**
			 * Numeral.js object
			 * @type {Function}
			 */
			numeralFunction: numeral
		}

		// Set initial component's internal state
		this.state = cloneDeep(this.initialState);
		
		// Custom component methods
		this.getFormat = this.getFormat.bind(this);
		this.getDefault = this.getDefault.bind(this);
		this.getContent = this.getContent.bind(this);
		
		// Render methods
		this.renderLabelContent = this.renderLabelContent.bind(this);
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		// Set numeral locale
		const {useAppLocale, locale, appLocale} = this.props;

		let numeralFunction = this.state.numeralFunction;
		numeralFunction.locale(useAppLocale ? appLocale.locale.toLowerCase() : locale?.toLowerCase());
		
		if (prevProps.appLocale !== appLocale || prevProps.useAppLocale !== useAppLocale || prevProps.locale !== locale) {
			if (useAppLocale || locale) this.setState({numeralFunction}).then();
		}
	}

	/**
	 * Get number format used for rendering the number label based on specified props
	 * @return {string}
	 */
	getFormat() {
		const {number, useAppLocale, useAppLocaleCurrency, format, locale} = this.props;
		/** @type {LocaleObj} */
		const appLocale = this.props.appLocale;
		const useLocale = (useAppLocale || locale);
		
		let result = (isFloat(number) ? '0.0[0]' : '0');
		// Generate format based on the 'format' prop
		if (format) {
			if (useAppLocale) {
				if (useAppLocaleCurrency && appLocale.numbers.currency.position === 'left') result = '$' + format;
				else if (useAppLocaleCurrency && appLocale.numbers.currency.position === 'right') result = format + '$';
				else result = format;
			}
			else result = format;
		}
		// Generate default format if 'format' prop is not set
		else if (useLocale) {
			result = (isFloat(number) ? '0,0.0[0]' : '0,0');
			if (useAppLocale && useAppLocaleCurrency) {
				result = (isFloat(number) ? '$0,0.0[0]' : '$0,0');
				if (appLocale.numbers.currency.position === 'right') result = (isFloat(number) ? '0,0.0[0]$' : '0,0$');
			}
		}
		return result;
	}

	/**
	 * Get default output string to render based on specified props
	 * @return {string}
	 */
	getDefault() {
		const {defaultNumber, defaultOutput} = this.props;
		const {numeralFunction} = this.state;
		const format = this.getFormat();
		
		try {
			if (!isNaN(Number(defaultNumber))) return numeralFunction(Number(defaultNumber)).format(format);
			return defaultOutput;
		} catch (e) {
			return defaultOutput;
		}
	}

	/**
	 * Get content to render as number label
	 * @note Prefix, suffix and icon will be rendered according to props values.
	 * @return {string}
	 */
	getContent() {
		const {number} = this.props;
		const {numeralFunction} = this.state;
		const format = this.getFormat();
		const defaultOutput = this.getDefault();
		
		try {
			if (number !== null && !isNaN(Number(number))) return numeralFunction(Number(number)).format(format);
			else return defaultOutput;
		} catch (e) {
			return defaultOutput;
		}
	}

	
	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render label content
	 */
	renderLabelContent() {
		const {prefix, suffix, icon, iconPosition, iconSpin} = this.props;
		const content = this.getContent();

		// Get Icon component CSS style based on context
		let iconStyle = {};
		if (icon && content) {
			if (iconPosition === NUMBER_LABEL_ICON_POSITION.LEFT) iconStyle.marginRight = '0.5em';
			else if (iconPosition === NUMBER_LABEL_ICON_POSITION.RIGHT) iconStyle.marginLeft = '0.5em';
			else iconStyle.marginLeft = '';
		}
		
		// Check icon position
		const lIcon = (
			(iconPosition === NUMBER_LABEL_ICON_POSITION.LEFT || iconPosition === NUMBER_LABEL_ICON_POSITION.NONE) && icon
		);
		const rIcon = (iconPosition === NUMBER_LABEL_ICON_POSITION.RIGHT && icon);
		
		return (
			<>
				{prefix ? <span className="label-prefix">{prefix}</span> : null}
				{lIcon ? <Icon symbol={icon} spin={iconSpin} style={iconStyle} className="label-icon left" /> : null}
				{content}
				{rIcon ? <Icon symbol={icon} spin={iconSpin} style={iconStyle} className="label-icon right" /> : null}
				{suffix ? <span className="label-suffix">{suffix}</span> : null}
			</>
		);
	}
	
	render() {
		const {element, elementProps, tooltip, tooltipOptions} = this.props;
		
		// Convert camelCase (in this case lowercase) prop name into PascalCase
		// @note This is done because react component props use camelCase (JSX attributes use camelCase) by convention, but 
		// rendered React JSX component must be capitalized (PascalCase).
		const Element = element;
		
		return (
			Element ?
				tooltip ?
					<Tooltip
						tag="span" title={tooltip} size="small" position="top-center" arrow={true} interactive={false}
						{...tooltipOptions}
					>
						<Element {...elementProps}>
							{this.renderLabelContent()}
						</Element>
					</Tooltip>
					:
					<Element {...elementProps}>{this.renderLabelContent()}</Element>
				:
				<>
					{tooltip ?
						<Tooltip
							tag="span" title={tooltip} size="small" position="top-center" arrow={true} interactive={false}
							{...tooltipOptions}
						>
							{this.renderLabelContent()}
						</Tooltip>
						:
						this.renderLabelContent()
					}
				</>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
NumberLabel.propTypes = {
	// Name of the wrapper HTML element (like 'i', 'span', ...) used to render the label
	// @note If not specified, wrapper will not be rendered.
	element: PropTypes.string,
	// Element attributes of the wrapper HTML element
	// @note If 'element' prop is not set, this value will be ignored. 
	elementProps: PropTypes.object,
	// Number label number
	// @note If type is string, this value must be a string representation of a number without any locale formatting.
	number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	// Default number that will be used if number is invalid (undefined, could not be converted, ...).
	// @note If type is string, this value must be a string representation of a number without any locale formatting.
	defaultNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	// Default output string that will be rendered if number is invalid (undefined, could not be converted, ...).
	// @note This will only be used if 'defaultNumber' prop is not defined.
	defaultOutput: PropTypes.string,
	// Number label number prefix
	// @note Usually used to add currency symbol. 
	prefix: PropTypes.any,
	// Number label number suffix
	// @note Usually used to add some flag symbol like '*' for changes or currency symbol.
	suffix: PropTypes.any,
	// Icon symbol name
	icon: PropTypes.string,
	// Icon position
	iconPosition: PropTypes.oneOf([
		NUMBER_LABEL_ICON_POSITION.NONE, NUMBER_LABEL_ICON_POSITION.RIGHT, NUMBER_LABEL_ICON_POSITION.LEFT
	]),
	// If true, icon should spin.
	// @note Please note that this will work only if font icon set supports it, and it's properly configured.
	iconSpin: PropTypes.bool,
	// Tooltip text for the number label
	tooltip: PropTypes.string,
	// Tooltip options
	tooltipOptions: PropTypes.object,
	// Flag that determines if current app locale number format will be used to render the number label
	useAppLocale: PropTypes.bool,
	// Flag that determines if locale currency will be used if app locale is used
	// @note This will take into account the currency position defined in app locale.
	useAppLocaleCurrency: PropTypes.bool,
	// Locale to use for rendering the number
	// @note If specified, 'useAppLocale' prop will be ignored.
	locale: PropTypes.string,
	// Number format using Numeral.js
	// @note If specified, 'locale' prop will be ignored.
	// @link http://numeraljs.com/
	format: PropTypes.string,
};

/**
 * Define component default values for own props
 */
NumberLabel.defaultProps = {
	element: '',
	elementProps: {},
	defaultOutput: '',
	prefix: '',
	suffix: '',
	icon: '',
	iconPosition: NUMBER_LABEL_ICON_POSITION.LEFT,
	iconSpin: false,
	tooltip: '',
	useAppLocale: true,
	useAppLocaleCurrency: false
};

export default connect(mapStateToProps, null)(NumberLabel);
export * from "./const";
export * from "./helper";