const srCyrlRS = {
	code: 'sr-Cyrl-RS',
	week: {
		dow: 1,
		doy: 7, // The week that contains Jan 1st is the first week of the year.
	},
	direction: 'ltr',
	buttonText: {
		prev: '',
		next: '',
		prevYear: '',
		nextYear: '',
		today: 'Садa',
		year: 'Година',
		month: 'Месец',
		week: 'Недеља',
		day: 'Дан',
		list: 'Планер',
	},
	buttonHints: {
		prev: 'Претходни $0 (Алт + ←)',
		next: 'Следећи $0 (Алт + →)',
		today: 'Овај $0 (Алт + Д)',
		prevYear: 'Претходна $0 (Алт + ←)',
		nextYear: 'Следећа $0 (Алт + →)',
		todayYear: 'Ова $0',
	},
	weekText: 'Сед',
	weekTextLong: 'Седмица',
	closeHint: 'Затвори',
	timeHint: 'Време',
	allDayText: 'Цео дан',
	moreLinkText(n) {
		return '+ још ' + n;
	},
	noEventsText: 'Нема догађаја за приказ',
};

export default srCyrlRS;