import {set} from 'lodash';

// Import individual reducers here
import * as schedule from "./schedule";
import * as mainSidebar from "../../layout/elements/MainSidebar/reducer";
import * as patientQuickSearch from "Components/advanced/PatientQuickSearch/reducer";
import * as patientRecord from "Components/advanced/PatientRecord/reducer";
import * as patientRecordVisits from "Components/advanced/PatientRecord/components/Visits/reducer";
import * as patientRecordAppointments from "Components/advanced/PatientRecord/components/Appointments/reducer";
import * as patientRecordPayments from "Components/advanced/PatientRecord/components/Payments/reducer";
import * as patientRecordExpertReports from "Components/advanced/PatientRecord/components/ExpertReports/reducer";
// Management pages
import * as therapistsPage from "Pages/apps/default/therapists/reducer";
import * as physiciansPage from "Pages/apps/default/physicians/reducer";
import * as medicalRecordCategoriesPage from "Pages/apps/default/medicalRecordCategories/reducer";
import * as recommendationsPage from "Pages/apps/default/recommendations/reducer";
import * as officeLocationPage from "Pages/apps/default/officeLocation/reducer";
import * as diagnosesPage from "Pages/apps/default/diagnoses/reducer";
import * as suppliersPage from "Pages/apps/default/suppliers/reducer";
import * as articleGroupsPage from "Pages/apps/default/articleGroups/reducer";
import * as procurementsPage from "Pages/apps/default/procurements/reducer";
import * as pricesPage from "Pages/apps/default/prices/reducer";
import * as therapyTypesPage from "Pages/apps/default/therapyTypes/reducer";
// Search pages
import * as searchAppointmentsPage from "Pages/apps/default/search/searchAppointments/reducer";
import * as searchVisitsPage from "Pages/apps/default/search/searchVisits/reducer";
import * as searchTherapiesPage from "Pages/apps/default/search/searchTherapies/reducer";
import * as searchPaymentsPage from "Pages/apps/default/search/searchPayments/reducer";
import * as searchPatientsPage from "Pages/apps/default/search/searchPatients/reducer";
import * as searchSalesPage from "Pages/apps/default/search/searchSales/reducer";
// Statistics
import * as officeLocationStatisticsPage from "Pages/apps/default/statistics/officeLocationStatistics/reducer";
import * as therapistStatisticsPage from "Pages/apps/default/statistics/therapistStatistics/reducer";
import * as otherStatisticsPage from "Pages/apps/default/statistics/otherStatistics/reducer";

// All imported reducers must be added to this array
const reducerImports = [
	schedule,
	mainSidebar,
	therapistsPage,
	physiciansPage,
	medicalRecordCategoriesPage,
	recommendationsPage,
	officeLocationPage,
	diagnosesPage,
	suppliersPage,
	articleGroupsPage,
	procurementsPage,
	pricesPage,
	searchAppointmentsPage,
	searchVisitsPage,
	searchTherapiesPage,
	searchPaymentsPage,
	searchPatientsPage,
	searchSalesPage,
	patientRecord,
	patientRecordVisits,
	patientRecordAppointments,
	patientRecordPayments,
	patientRecordExpertReports,
	patientQuickSearch,
	therapyTypesPage,
	officeLocationStatisticsPage,
	therapistStatisticsPage,
	otherStatisticsPage,
];




// ---------------------------------------------------------------------------------------------------------------------
// Don't change anything below this line -------------------------------------------------------------------------------
let importedReducers = {};
let importedSelectors = {};
let importedActionCreators = {};
// Combine all imported reducers. Imported reducers must export 'reducerStoreKey' constant containing unique store key
// associated to that reducer.
reducerImports.forEach(reducerImport => {
	set(importedReducers, reducerImport.reducerStoreKey, reducerImport.default);
	set(importedSelectors, reducerImport.reducerStoreKey, reducerImport.selectors);
	set(importedActionCreators, reducerImport.reducerStoreKey, reducerImport.actionCreators);
});

// Combined selectors and action creators
export { importedReducers as reducers, importedSelectors as selectors, importedActionCreators as actionCreators };