import {cloneDeep, get} from 'lodash';
import {getNumber, getString} from 'Core/helpers/data';
import {PatientTherapySelectOptionDataObject} from "./dataObject";
import {getDate, STANDARD_DATE_TIME_FORMAT} from 'Core/helpers/datetime';

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {PatientTherapySelectOptionDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new PatientTherapySelectOptionDataObject(
		getString(result, 'id'),
		getString(result, 'diagnosis'),
		getString(result, 'diagnosisCode'),
		getDate(get(result, 'therapyDate'), STANDARD_DATE_TIME_FORMAT.ISO_DATE),
		getString(result, 'medicalRecordCategory'),
		getNumber(result, 'medicalRecordNumber'),
		getString(result, 'sentByPhysician')
	);

	return result;
}

// @note There is no output function since list is read only.