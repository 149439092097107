import {cloneDeep, find, get} from "lodash";
import {getArray, getString, isset} from "Core/helpers/data";
import {StatisticsFilterDataObject} from "Components/advanced/Statistics/dataObjects";

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'otherStatistics';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@otherStatistics/reset',
	SET_DATA: '@otherStatistics/set_data',
	CLEAR_DATA: '@otherStatistics/clear_data',
	SET_FILTER: '@otherStatistics/set_filter',
	CLEAR_FILTER: '@otherStatistics/clear_filter',
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),

	/**
	 * @param {StatisticsDataObject} data
	 * @return {{data: StatisticsDataObject, type: string}}
	 */
	setData: data => ({type: REDUCER_TYPES.SET_DATA, data}),
	clearData: () => ({type: REDUCER_TYPES.CLEAR_DATA}),

	/**
	 * @param {?StatisticsFilterDataObject} filter
	 * @return {{filter: ?StatisticsFilterDataObject, type: string}}
	 */
	setFilter: filter => ({type: REDUCER_TYPES.SET_FILTER, filter}),
	clearFilter: () => ({type: REDUCER_TYPES.CLEAR_FILTER}),
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	/** @type {?StatisticsDataObject[]|undefined} */
	dataList: null,

	/** @type {StatisticsFilterDataObject} */
	filter: new StatisticsFilterDataObject(),
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET: return { ...initialState };

		case REDUCER_TYPES.SET_DATA: {
			const dataList = getArray(state, 'dataList');
			const type = getString(action, 'data.type');

			if (!type) return state;

			return (
				isset(find(dataList, {type})) ?
					{...state, dataList: state.dataList.map(i => i.type === type ? cloneDeep(action.data) : i)} :
					{...state, dataList: (dataList.length > 0 ? [...state.dataList, action.data] : [action.data])}
			);
		}
		case REDUCER_TYPES.CLEAR_DATA:
			const dataList = getArray(state, 'dataList');
			const type = getString(action, 'statisticsType');

			// Set data list to initial value if type is empty
			if (type === '') return {...state, dataList: cloneDeep(initialState.dataList)};

			// Do not change anything if data list is currently empyt since there is no statistics type to clear
			if (dataList.length === 0) return state;

			// Remove a specific statistics type from the data list
			return {...state, dataList: state.dataList.filter(i => i.type !== action.statisticsType)};

		case REDUCER_TYPES.SET_FILTER: return {...state, filter: action.filter};
		case REDUCER_TYPES.CLEAR_FILTER: return {...state, filter: cloneDeep(initialState.filter)};

		default: return state;
	}
};

// Selectors
export const selectors = {
	getOtherStatisticsList: state => get(state, [reducerStoreKey, 'dataList']),
	getOtherStatistics: (type, state) => find(getArray(state, [reducerStoreKey, 'dataList']), {type}),
	getOtherStatisticsListFilter: state => get(state, [reducerStoreKey, 'filter'], null),
};

export default reducer;