import {CookieData} from "Core/dataProtection/objects/cookie";
import {user_activity_storage_path} from "Config/app";
import {getStorageValue, setStorageValue, STORAGE_TYPE} from "Core/storage";
import CookieConsent from "Core/dataProtection/cookieConsent";
import {getBoolFromTinyInt, getNumber, getTinyIntFormBool} from "Core/helpers/data";

/**
 * Check if user activity cookie is allowed
 * @return {boolean}
 */
export const isUserActivityCookieAllowed = () => {
	const userActivityCookie = new CookieData('necessary', user_activity_storage_path, STORAGE_TYPE.LOCAL);
	return CookieConsent.isAllowed(userActivityCookie);
}

/**
 * Get user activity data
 * @note This function will check if appropriate cookie is accepted before trying to get the data.
 * 
 * @param {any} [defaultValue=null] - Default value to return when user activity data is not set, or it cannot be 
 * retrieved for any reason.
 * @return {Object|any} User data or 'defaultValue'.
 */
export const getUserActivity = (defaultValue = null) => (
	isUserActivityCookieAllowed() ?
		getStorageValue(user_activity_storage_path, STORAGE_TYPE.LOCAL, true, defaultValue) :
		defaultValue
);

/**
 * Get the timestamp of the last user activity
 * @note User activity time is, by default, updated on events defined in 'user_activity_trigger_events' app config 
 * option. This can be extended by the app to update on any other trigger. This function will check if appropriate 
 * cookie is accepted before trying to get the data.
 * @return {number} Timestamp (link the one returned by Date.now()) or 0 if last user activity time does not exist, or 
 * it cannot be retrieved for any reason.
 */
export const getLastUserActivityTime = () => (
	isUserActivityCookieAllowed() ? getNumber(getUserActivity({}),'lastActivityTime') : 0
);

/**
 * Set last user activity time to the current time
 * @note Last user activity time is set as a timestamp link the one returned by Date.now() function. This function will 
 * check if appropriate cookie is accepted before trying to get the data.
 */
export const updateLastUserActivityTime = () => {
	if (isUserActivityCookieAllowed()) {
		setStorageValue(
			user_activity_storage_path, 
			{...getUserActivity({}), lastActivityTime: Date.now()},
			STORAGE_TYPE.LOCAL,
			undefined,
			true
		);
	}
};

export const setLastUserActivityCheckLoginFlag = flag => {
	if (isUserActivityCookieAllowed()) {
		setStorageValue(
			user_activity_storage_path, 
			{...getUserActivity({}), checkingLogin: getTinyIntFormBool(flag)},
			STORAGE_TYPE.LOCAL,
			undefined,
			true
		);
	}
};

export const isLastUserActivityCheckLogin = () => (
	isUserActivityCookieAllowed() ?
		getBoolFromTinyInt(getUserActivity({}), 'checkingLogin') :
		false
);