/**
 * Cookie data class
 * @description This data class is used to define cookies that will be used for cookie consent. Cookies are defined in
 * 'src/dataProtection/cookies.js'
 */
export class CookieData {
	/**
	 * Constructor
	 * @param {'necessary'|string} group - Cookie purpose group name. Can be anything but there is a predefined
	 * 'necessary' group for necessary cookies.
	 * @param {string} name - Cookie name or storage key name.
	 * @param {string} storage - Cookie storage type.
	 * @param {'/'|string} [domain='/'] - Cookie domain where '/' is the current app's domain.
	 * @param {''|string} [path=''] - Cookie domain path where '' is used for local storage cookies that don't have
	 * domain paths as such.
	 */
	constructor(group, name, storage, domain = '/', path = '') {
		this.group = group;
		this.name = name;
		this.storage = storage;
		this.domain = domain;
		this.path = path;
	}
}