/**
 * Redux helpers
 */
import {isEqual} from 'lodash';
import * as globalActions from "../store/actions/global";
import * as pageActions from "../store/actions/page";

/**
 * Observable Redux store change function
 * @description This function is used to check for store changes by making store subscribe function observable.
 * @see https://github.com/reduxjs/redux/issues/303#issuecomment-125184409
 *
 * @param {object} store - Whole redux store.
 * @param {function(state: any): any} select - Function that receives current state and should return section of the
 * store to check for changes.
 * @param {function(state: any): any} onChange - Callback function that will be called when selected store value change
 * was detected.
 * @return {function(): void} Unsubscribe function to remove listener added by `Store.subscribe()`.
 */
export function observeReduxStore(store, select, onChange) {
	let currentState = select(store.getState());

	function handleChange() {
		let nextState = select(store.getState());
		if (!isEqual(nextState, currentState)) {
			currentState = nextState;
			onChange(currentState);
		}
	}

	let unsubscribe = store.subscribe(handleChange);
	handleChange();
	return unsubscribe;
}

/**
 * Combine multiple action imports
 * @description This function is used to combine multiple imported actions into one object that can be sent to the 
 * react-redux 'connect' function as 'mapDispatchToProps' argument.
 * @return {object}
 */
export const combineActions = (...actions) => Object.assign({}, ...actions);

/**
 * Get global action imports
 * @description This method is used to get all global actions and optionally combine them with additional actions passed
 * as arguments, into one object that can be sent to the react-redux 'connect' function as 'mapDispatchToProps' 
 * argument.
 * 
 * @param additionalActions - Additional actions that will combine with the global actions. 
 * @return {object}
 */
export const getGlobalActions = (...additionalActions) => Object.assign({}, globalActions, ...additionalActions);

/**
 * Get page action imports
 * @description This method is used to get all page actions and optionally combine them with additional actions passed
 * as arguments, into one object that can be sent to the react-redux 'connect' function as 'mapDispatchToProps'
 * argument.
 * @note Global action will also be included.
 *
 * @param additionalActions - Additional actions that will combine with the global actions.
 * @return {object}
 */
export const getPageActions = (...additionalActions) => 
	Object.assign({}, globalActions, pageActions, ...additionalActions);