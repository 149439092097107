/** @typedef {string} InsertValueButtonTypeDialog */ 
export const INSERT_VALUE_BUTTON_TYPE_DIALOG = 'DIALOG';
export const INSERT_VALUE_BUTTON_TYPE_DROPDOWN = 'DROPDOWN';
/** @enum {InsertValueButtonTypeDialog} */
export const INSERT_VALUE_BUTTON_TYPE = {
	DIALOG: INSERT_VALUE_BUTTON_TYPE_DIALOG,
	DROPDOWN: INSERT_VALUE_BUTTON_TYPE_DROPDOWN,
}
/** @type {InsertValueButtonTypeDialog[]} */
export const INSERT_VALUE_BUTTON_TYPES = [
	INSERT_VALUE_BUTTON_TYPE_DIALOG,
	INSERT_VALUE_BUTTON_TYPE_DROPDOWN,
];