import {get} from "lodash";
import {getBoolean, getInteger, getObject, getString} from "Core/helpers/data";
import {SORT_ORDER} from "Core/const/global";

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'searchVisits';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@searchVisits/reset',
	SET_LIST_DATA: '@searchVisits/set_list_data',
	SET_LIST_FILTER: '@searchVisits/set_list_filter',
	CLEAR_LIST_DATA: '@searchVisits/clear_list_data',
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),
	/**
	 * @param {IoJsonFetchResponseObject} searchVisitsListData
	 * @return {{type: string, listData: IoJsonFetchResponseObject}}
	 */
	
	setSearchVisitsListData: searchVisitsListData => ({
		type: REDUCER_TYPES.SET_LIST_DATA, 
		listData: searchVisitsListData
	}),
	setSearchVisitsListFilter: searchVisitsListFilter => ({
		type: REDUCER_TYPES.SET_LIST_FILTER, 
		filter: searchVisitsListFilter
	}),
	clearSearchVisitsListData: () => ({type: REDUCER_TYPES.CLEAR_LIST_DATA}),
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	/** @type {IoJsonFetchResponseObject} */
	listData: null,
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET: return { ...initialState };

		case REDUCER_TYPES.SET_LIST_DATA: return {...state, listData: action.listData};
		case REDUCER_TYPES.SET_LIST_FILTER: return {
			...state, 
			listData: {...getObject(state.listData), filter: action.filter}
		};
		case REDUCER_TYPES.CLEAR_LIST_DATA: return {...state, listData: initialState.listData};

		default: return state;
	}
};

// Selectors
export const selectors = {
	getSearchVisitsListData: state => get(state, [reducerStoreKey, 'listData']),
	getSearchVisitsList: state => get(state, [reducerStoreKey, 'listData', 'data']),
	getSearchVisitsListPagination: state => ({
		perPage: getInteger(state, [reducerStoreKey, 'listData', 'perPage']),
		pageNo: getInteger(state, [reducerStoreKey, 'listData', 'pageNo']),
		totalRows: getInteger(state, [reducerStoreKey, 'listData', 'totalResults']),
		isLastPage: getBoolean(state, [reducerStoreKey, 'listData', 'isLastPage'])
	}),
	getSearchVisitsListSort: state => ({
		sortBy: getString(state, [reducerStoreKey, 'listData', 'sortBy']),
		sortDir: getString(state, [reducerStoreKey, 'listData', 'sortDir'], SORT_ORDER.ASC)
	}),
	getSearchVisitsListFilter: state => get(state, [reducerStoreKey, 'listData', 'filter'], null),
};

export default reducer;