import {hideLoadingFunction, showDialogLoading} from 'Core/helpers/loading';
import {ioJsonAction, ioJsonDeleteItemAction} from 'Core/store/actions/io';
import {getString} from 'Core/helpers/data';
import * as appointmentCreateDataMap from "DataMap/appointment/create";
import * as appointmentUpdateDataMap from "DataMap/appointment/update";

/**
 * Create an appointment
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {AppointmentCreateDataObject} appointment
 * @return {function(*): Promise<string>}
 */
export const createAppointmentAction = (abortCallback, appointment) => dispatch => {
	const loading = showDialogLoading('create-appointment-dialog');
	return ioJsonAction(
		undefined,
		'defaultAuthorizedApi',
		'member/appointments/create',
		{
			id: null,
			data: appointmentCreateDataMap.output(appointment),
			requestSavedData: true,
		},
		hideLoadingFunction(loading),
	)(dispatch)
		// Return appointment ID
		.then(res => getString(res, 'data.uniqueID'));
}

/**
 * Update an appointment
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id
 * @param {AppointmentUpdateDataObject} appointment
 * @return {function(*): Promise<string>}
 */
export const updateAppointmentAction = (abortCallback, id, appointment) => dispatch => {
	const loading = showDialogLoading('edit-appointment-dialog');
	return ioJsonAction(
		undefined,
		'defaultAuthorizedApi',
		'member/appointments/update-by-id',
		{
			id,
			data: appointmentUpdateDataMap.output(appointment),
			requestSavedData: true,
		},
		hideLoadingFunction(loading),
	)(dispatch)
		// Return appointment ID
		.then(res => getString(res, 'data.uniqueID'));
}

/**
 * Delete an appointment
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - DB ID of the appointment
 * @return {function(*): Promise<IoJsonResponseObject|undefined>}
 */
export const deleteAppointmentAction = (abortCallback, id) => dispatch => {
	const loading = showDialogLoading('update-regular-appointment-dialog');
	return ioJsonDeleteItemAction(
		abortCallback,
		'defaultAuthorizedApi',
		'member/appointments/delete-by-ids',
		id,
		undefined,
		hideLoadingFunction(loading),
	)(dispatch);
};