import "./default.style.css";
import "./card.style.css";

import React from "react";
import DataComponent, {executeComponentCallback} from "Core/components/DataComponent";
import PropTypes from "prop-types";
import FormWrapper, {FormField} from "Core/components/advanced/FormWrapper";
import TextInput from "Core/components/input/TextInput";
import {FORM_FIELD_LABEL_POSITION} from "Core/components/advanced/FormWrapper/FormField";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import Label from "Core/components/display/Label";
import DataValueValidation from "Core/validation";
import {getString, isset} from "Core/helpers/data";
import {omit} from "lodash";

class Login extends DataComponent {
	constructor(props) {
		super(props, {
			data: {
				username: '',
				password: ''
			}
		}, {
			translationPath: "Login",
			disableLoad: true,
			optimizedUpdate: true,
		});

		// Custom component methods
		this.login = this.login.bind(this);
	}

	// Validation methods -----------------------------------------------------------------------------------------------
	/**
	 * Default component's data validation method
	 *
	 * @return {boolean} True if component's data validation passed successfully.
	 */
	validate() {
		const {usernameRules, usernameConstraints} = this.props;
		const dataValidation = new DataValueValidation();
		const dataToValidate = this.getData();

		dataValidation.addRule('username', 'required');
		if (Array.isArray(usernameRules) && usernameRules.length) {
			dataValidation.addRule('username', ...usernameRules);
		}
		if (Array.isArray(usernameConstraints) && usernameConstraints.length) {
			dataValidation.addConstraint('username', ...usernameConstraints);
		}
		
		dataValidation.addRule('password', 'required');

		const validationErrors = dataValidation.run(dataToValidate);
		if (validationErrors) this.setValidationErrors('', validationErrors).then();
		return !validationErrors;
	}
	

	// Custom component methods -----------------------------------------------------------------------------------------
	/**
	 * Login method
	 * @note This method will execute a function defined in the 'action' prop.
	 */
	login() {
		const username = this.getValue('username');
		const password = this.getValue('password');
		
		this.clearValidationErrors()
			.then(() => this.clearErrorMessage())
			.then(() => { 
				if (this.validate()) {
					return executeComponentCallback(this.props.loginAction, username, password);
				} else if (!username || !password) {
					return this.setState({error: this.t('Please enter username and password.')});
				}
			});
	}
	
	
	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {
			styleName, className, showAppName, appName, showFormTitle, formTitle, showInputLabel, inputLabelPosition,
			loginButtonProps, registerAction, registerButtonProps, changePasswordAction, changePasswordButtonProps, 
			backAction, backButtonProps, renderToTop, renderToBottom,
		} = this.props;
		const error = this.getErrorMessage();
		
		return (
			<div id={this.getDomId()} className={`login-component ${styleName}-style ${className}`}>
				{renderToTop ? renderToTop : null}
				
				{/* Header --------------------------------------------------------------------------------------------- */}
				<div className="login-header">
					{
						showAppName ?
							<div className="app-name">
								{isset(appName) ? appName : this.t('title', 'App')}
							</div>
							: null
					}

					{
						showFormTitle ?
							<>
								<div className="form-title">
									{isset(formTitle) ? formTitle : this.t('User login')}
									<Label
										element="div"
										elementProps={{className: 'form-desc'}}
										content={this.t('Please enter username and password.')}
										supportHtml={true}
									/>
								</div>
							</>
							: null
					}
				</div>

				
				{/* Content -------------------------------------------------------------------------------------------- */}
				<div className="login-content">
					<FormWrapper>
						<FormField
							label={this.t("Username")}
							labelPosition={showInputLabel ? inputLabelPosition : FORM_FIELD_LABEL_POSITION.NONE}
							errorMessages={this.getValidationErrors('username')}
						>
							<TextInput
								isFast={false}
								name="username"
								value={this.getValue('username')}
								onChange={this.handleInputChange}
								onEnterKey={this.login}
								placeholder={this.t('Enter your username')}
							/>
						</FormField>

						<FormField
							label={this.t("Password")}
							labelPosition={showInputLabel ? inputLabelPosition : FORM_FIELD_LABEL_POSITION.NONE}
							errorMessages={this.getValidationErrors('password')}
						>
							<TextInput
								isFast={false}
								type="password"
								name="password"
								value={this.getValue('password')}
								onChange={this.handleInputChange}
								onEnterKey={this.login}
								placeholder={this.t('Enter your password')}
							/>
						</FormField>

						{
							changePasswordAction ?
								<>
									<Button
										className={
											`change-password-btn ${getString(changePasswordButtonProps, 'className')}`
										}
										label={this.t('Change password')}
										onClick={() => changePasswordAction(
											this.getValue('username'),
											this.getValue('password')
										)}
										{...Login.defaultProps.changePasswordButtonProps}
										{...omit(changePasswordButtonProps, ['className'])}
									/>
									<div className="clearfix" />
								</>
								: null
						}
					</FormWrapper>
				</div>

				
				{/* Actions -------------------------------------------------------------------------------------------- */}
				<div className="login-actions">
					{
						registerAction ?
							<Button
								className={`register-btn ${getString(registerButtonProps, 'className')}`}
								label={this.t('Register')}
								onClick={() => registerAction(
									this.getValue('username'), 
									this.getValue('password'
								))}
								{...Login.defaultProps.registerButtonProps}
								{...omit(registerButtonProps, ['className', 'onClick'])}
							/>
							: null
					}

					<Button
						className={`login-btn ${getString(loginButtonProps, 'className')}`}
						label={this.t('Login')}
						onClick={this.login}
						{...Login.defaultProps.loginButtonProps}
						{...omit(loginButtonProps, ['className', 'onClick'])}
					/>

					{
						backAction ?
							<Button
								className={`back-btn ${getString(backButtonProps, 'className')}`}
								label={this.t('Back', 'general')}
								onClick={backAction}
								{...Login.defaultProps.backButtonProps}
								{...omit(backButtonProps, ['className', 'onClick'])}
							/>
							: null
					}
				</div>

				{renderToBottom ? renderToBottom : null}
				
				{error ? <p className={`component-error error-color`}>{error}</p> : null}
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
Login.propTypes = {
	// Component style name
	// @description Component style name is a name of the style that will be used to determine the CSS used to style the
	// component.
	styleName: PropTypes.string,
	// Login component wrapper element ID class attribute
	id: PropTypes.string,
	// Login component wrapper element class attribute
	className: PropTypes.string,
	
	// Flag that specifies if app name will be rendered
	showAppName: PropTypes.bool,
	// App name to render instead of default app name
	// @note If undefined, default app name will be rendered.
	appName: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	// Flag that specifies if login form title will be rendered
	showFormTitle: PropTypes.bool,
	// Login form title to render instead of default login form title
	// @note If undefined, default form title will be rendered.
	formTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	// Flag that specifies if login form input fields should have labels
	showInputLabel: PropTypes.bool,
	// Login component input fields label position
	// @see FORM_FIELD_LABEL_POSITION const.
	inputLabelPosition: PropTypes.string,
	
	// Array of username validation rules
	usernameRules: PropTypes.array,
	// Array of username validation constraints
	usernameConstraints: PropTypes.array,

	// Login form submit function
	loginAction: PropTypes.func, // Arguments: username, password
	// Login button props
	// @see Button component props.
	loginButtonProps: PropTypes.object,

	// Register button action
	// @note Register button will only render if this action is specified.
	registerAction: PropTypes.func, // Arguments: username, password
	// Register button props
	// @see Button component props.
	registerButtonProps: PropTypes.object,
	
	// Change password action
	// @note Change password button will only render if this action is specified.
	changePasswordAction: PropTypes.func, // Arguments: username, password
	// Change password button props
	// @see Button component props.
	changePasswordButtonProps: PropTypes.object,
	
	// Form back action
	// @note Back button will only render if this action is specified.
	backAction: PropTypes.func, // Arguments: button click event
	// Back button props
	// @see Button component props.
	backButtonProps: PropTypes.object,
	
	// Anything to render at the top of the login component
	renderToTop: PropTypes.element,
	// Anything to render at the bottom of the login component
	// @note Only errors will be rendered below this.
	renderToBottom: PropTypes.element
}

/**
 * Define component default values for own props
 */
Login.defaultProps = {
	styleName: 'default',
	className: '',
	showAppName: false,
	appName: undefined,
	showFormTitle: true,
	formTitle: undefined,
	showInputLabel: true,
	inputLabelPosition: FORM_FIELD_LABEL_POSITION.STACKED,
	loginButtonProps: {
		displayStyle: BUTTON_STYLE.ACTION,
		displayType: BUTTON_DISPLAY_TYPE.SOLID,
		icon: 'unlock-alt',
		big: true
	},
	registerButtonProps: {
		displayStyle: BUTTON_STYLE.ACTION,
		displayType: BUTTON_DISPLAY_TYPE.TRANSPARENT,
		icon: 'user-plus',
		big: true
	},
	changePasswordButtonProps: {
		displayType: BUTTON_DISPLAY_TYPE.NONE,
		displayStyle: BUTTON_STYLE.DEFAULT
	},
	backButtonProps: {
		displayStyle: BUTTON_STYLE.SUBTLE,
		displayType: BUTTON_DISPLAY_TYPE.TRANSPARENT,
		icon: 'chevron-left',
		big: true
	},
	renderToTop: null,
	renderToBottom: null,
};

export default Login;