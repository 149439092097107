import {cloneDeep} from 'lodash';
import {getString} from 'Core/helpers/data';
import {DiagnosisSearchOptionDataObjects} from "./dataObjects";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {DiagnosisSearchOptionDataObjects}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new DiagnosisSearchOptionDataObjects(
		getString(result, 'uniqueID'),
		getString(result, 'code'),
		getString(result, 'description'),
	);

	return result;
}

// @note There is no output function since list is read only.