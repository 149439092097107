import React from "react";
import BaseComponent, {executeComponentCallback} from "Core/components/BaseComponent";
import CreatableSelect from 'react-select/creatable';
import PropTypes from "prop-types";
import {getArray, getString, isset} from "Core/helpers/data";
import {isEmailString} from "Core/helpers/string";
import {map, omit, uniq} from "lodash";
import {INSERT_VALUE_BUTTON_TYPE, INSERT_VALUE_BUTTON_TYPES} from "Core/components/advanced/InsertValueButton/const";
import InsertValueButton from "Core/components/advanced/InsertValueButton";
import {BUTTON_DISPLAY_TYPES, BUTTON_STYLE, BUTTON_STYLES} from "Core/components/display/Button";
import {main_layout_element} from "Config/app";

class EmailInput extends BaseComponent {
	/**
	 * Input element value
	 * @type {string}
	 */
	inputValue = ''; 
	
	constructor(props) {
		super(props, {
			translationPath: 'EmailInput',
			domPrefix: 'email-input-component'
		});

		// Custom component methods
		this.getDataToLoad = this.getDataToLoad.bind(this);
		this.getDataToReturn = this.getDataToReturn.bind(this);
		this.clearInput = this.clearInput.bind(this);
		this.selectItemByValue = this.selectItemByValue.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
		this.onBlur = this.onBlur.bind(this);
		
		// Insert value methods
		this.containsInsertValue = this.containsInsertValue.bind(this);
	}

	// Custom component methods -----------------------------------------------------------------------------------------
	/**
	 * Convert raw value (usually from props) into CreatableSelect value
	 * @param {string|string[]} rawValue - Single string value, string with multiple items separated using 
	 * 'itemSeparator' or an array of strings.
	 * @return {{label, value}|null|{label, value}[]}
	 */
	getDataToLoad(rawValue) {
		const {isMulti, itemSeparator} = this.props;

		if (isMulti) {
			if (typeof rawValue === 'string') {
				return getString(rawValue).split(itemSeparator).map(v => {
					const value = v.trim();
					if (this.containsInsertValue(value)) return {label: value, value};
					else if (value && isEmailString(value)) return {label: value, value};
					return undefined;
				}).filter(i => isset(i));
			} else if(Array.isArray(rawValue)) {
				return rawValue.map(v => {
					const value = v.trim();
					if (this.containsInsertValue(value)) return {label: value, value};
					else if (value && isEmailString(value)) return {label: value, value};
					return undefined;
				}).filter(i => isset(i));
			}
			return [];
		} else {
			if (!rawValue) return null;
			if (this.containsInsertValue(rawValue)) return {label: rawValue, rawValue};
			else if (isEmailString(rawValue)) return {label: rawValue, rawValue};
			else return null;
		}
	}

	/**
	 * Convert CreatableSelect value into the value that can be returned by 'onChange' event
	 * @note This is the same value as 'value' prop.
	 * @param {{label, value}|{label, value}[]|null} selectedValue - CreatableSelect value.
	 * @return {string} Single string value or string with multiple items separated using 'itemSeparator' prop.
	 */
	getDataToReturn(selectedValue) {
		const {isMulti, itemSeparator, returnAsString} = this.props;
		if (!selectedValue) return '';
		
		if (isMulti) {
			let selectedOptions = map(selectedValue, option => option.value);
			// Remove duplicates
			selectedOptions = uniq(selectedOptions);
			return (returnAsString ? selectedOptions.join(itemSeparator) : selectedOptions);
		}
		
		return selectedValue.value;
	}

	/**
	 * Clear input field
	 */
	clearInput() {
		const inputElement = document.getElementById(`${this.getDomId()}-input`);
		if (inputElement) {
			inputElement.blur();
			inputElement.focus();
		}
	}

	/**
	 * Select item by value
	 * @param {string} v - String value to select.
	 */
	selectItemByValue(v) {
		const {isMulti, value} = this.props;
		if (isMulti) this.onChange([...this.getDataToLoad(value), {label: v, value: v}]);
		else this.onChange({label: v, value: v});
	}

	/**
	 * 'react-select' onChange method
	 * @note Overwritten to support simple values.
	 *
	 * @param {Object} selectedValue - 'react-select' selected value.
	 */
	onChange(selectedValue) {
		executeComponentCallback(this.props.onChange, this.getDataToReturn(selectedValue));
	}

	/**
	 * 'react-select' onChange method
	 * @note Overwritten to support Enter key event and better handle focus on Escape key event.
	 *
	 * @param {KeyboardEvent} event - Keydown event.
	 */
	onKeyDown(event) {
		// Trigger standard keydown event
		executeComponentCallback(this.props.onKeyDown, event);
		
		if (event.key === 'Enter' || event.key === ',' || event.key === ' ') {
			// Trigger Enter key event if menu is closed since when it is opened Enter key is used to select a value.
			if (event.key === 'Enter') executeComponentCallback(this.props.onEnterKey, event);

			// Prevent ',' and ' ' (space) keys from being added to the input since they will be used to add an item
			if (event.key === ',' || event.key === ' ') event.preventDefault();
			
			// Add item on Enter, ',' or ' ' (space) key
			if (isEmailString(this.inputValue)) {
				this.selectItemByValue(this.inputValue);
				this.clearInput();
			}
		}
		
		// Stop propagation of the Escape key and blur the element if it is focused
		if (event.key === 'Escape') {
			// Stop propagation of the Escape key because it will be handled manually
			event.stopPropagation();
			event.nativeEvent.stopImmediatePropagation();
			
			// If input is not empty, clear it and retain element focus
			if (this.inputValue) this.clearInput();
			// If input is empty blur the element
			else if (this.element) this.element.blur();
		}
	}
	
	/**
	 * 'react-select' onBlur method
	 * @note Overwritten to support selecting valid email(s) from input value on blur.
	 *
	 * @param {FocusEvent} event - Blur event.
	 */
	onBlur(event) {
		// Add valid item from input value on blur
		if (isEmailString(this.inputValue)) {
			this.selectItemByValue(this.inputValue);
			this.clearInput();
		}
	}

	// Insert value methods ---------------------------------------------------------------------------------------------
	/**
	 * Check if string contains any insert value
	 * @param {string} string - String to check
	 * @return {boolean}
	 */
	containsInsertValue(string) {
		const {insertValueType, insertValueTypeOptions} = this.props;
		switch (insertValueType) {
			case INSERT_VALUE_BUTTON_TYPE.DIALOG:
				/** @type {InsertValueDialogSectionDataObject[]} */
				const sections = getArray(insertValueTypeOptions, 'dialogProps.sections');
				for (let i of sections) if (i.contains(string)) return true;
				return false;

			case INSERT_VALUE_BUTTON_TYPE.DROPDOWN:
				// TODO: dropdown insert value
				return false;

			default:
				return false;
		}
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {
			className, classNamePrefix, value, defaultValue, placeholder, isMulti, formControlStyle, isDisabled,
			isClearable, showInsertValueButton, insertValueButtonProps, insertValueType, insertValueTypeOptions,
			menuRenderInLayout, styles, ...otherProps
		} = this.props;

		// Prepare portal related props
		let portalProps = {};
		if (menuRenderInLayout) {
			const element = this.getDomElement();
			const fontSize = (element ? getComputedStyle(this.getDomElement()).getPropertyValue('font-size') : '');
			portalProps.styles = (
				styles ?
					{...styles, menuPortal: base => ({ ...base, zIndex: 9999, fontSize })} :
					{menuPortal: base => ({ ...base, zIndex: 9999, fontSize })}
			);
			portalProps.menuPortalTarget = document.querySelector(main_layout_element);
			portalProps.menuPlacement = 'auto';
		}
		
		return (
			<>
				{
					!isDisabled && showInsertValueButton ?
						<InsertValueButton
							buttonProps={{
								displayStyle: BUTTON_STYLE.NONE,
								className: 'input-toolbar-button',
								...insertValueButtonProps
							}}
							insertType={insertValueType}
							insertTypeOptions={insertValueTypeOptions}
							parentSelector={`.${this.getDomId()} .select-input__control`}
							onInsert={this.selectItemByValue}
							onDialogClose={() => this.element.focus()}
						/>
						: null
				}

				<CreatableSelect
					menuIsOpen={false}
					components={{DropdownIndicator: null}}
					inputId={`${this.getDomId()}-input`}
					id={this.getDomId()}
					className={
						`select-input-component ` +
						`${this.getOption('domPrefix')} ${className} ${formControlStyle ? 'form-control' : ''} ` +
						`${this.getDomId()}`
					}
					classNamePrefix={`select-input${classNamePrefix ? ' ' + classNamePrefix : ''}`}
					value={this.getDataToLoad(value)}
					defaultValue={this.getDataToLoad(defaultValue)}
					placeholder={placeholder ? placeholder : this.t(`placeholder${isMulti ? '_multi' : ''}`)}
					isDisabled={isDisabled}
					isClearable={isClearable}
					isMulti={isMulti}
					onChange={this.onChange}
					onInputChange={v => this.inputValue = v}
					onKeyDown={this.onKeyDown}
					onBlur={this.onBlur}
					{...portalProps}
					{...omit(otherProps, ['inputId', 'id', 'itemSeparator', 'onChange', 'onKeyDown', 'onBlur'])}
					ref={node => this.element = node}
				/>
			</>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
EmailInput.propTypes = {
	// The id to set on the SelectContainer component
	id: PropTypes.string,
	// Apply a className to the control
	className: PropTypes.string,
	// Apply classNames to inner elements with the given prefix
	classNamePrefix: PropTypes.string,
	// Disable the control
	isDisabled: PropTypes.bool,
	// Allow the user to select multiple values
	isMulti: PropTypes.bool,
	// Item value separator
	// @description This component accepts a string value. If 'isMulti' prop is true value is still a string where items 
	// should be separated by this separator.
	itemSeparator: PropTypes.string,
	// Flag that determines if multiple values (when 'isMulti' prop is true) should be returned as a single string with
	// values separated using 'itemSeparator' prop.
	returnAsString: PropTypes.bool,
	// Flag that determines if input will have a standard form control style
	formControlStyle: PropTypes.bool,
	// Placeholder for the select value
	placeholder: PropTypes.string,
	// Control the current value
	value: PropTypes.any,
	// Set the initial value of the control
	defaultValue: PropTypes.any,
	// Is the select value clearable
	isClearable: PropTypes.bool,
	// Is the select in a state of loading (async)
	isLoading: PropTypes.bool,
	// Flag that determines if menu will be rendered in main layout element (see 'main_layout_element' config value) 
	// using a portal
	menuRenderInLayout: PropTypes.bool,

	// Flag that determines if insert value button component (InsertValueButton) will be rendered inside the input
	showInsertValueButton: PropTypes.bool,
	// Insert value button props
	insertValueButtonProps: PropTypes.shape({
		// Button element 'id' attribute.
		id: PropTypes.string,
		// Button element CSS class attribute.
		className: PropTypes.string,
		// The default behavior of the button. Possible values are: 'submit', 'reset' or 'button'.
		type: PropTypes.string,
		// Button display type ('none', 'solid', 'transparent', ...)
		displayType: PropTypes.oneOf(BUTTON_DISPLAY_TYPES),
		// Button display style ('default', 'success', 'error', ...)
		displayStyle: PropTypes.oneOf(BUTTON_STYLES),
		// If true, bigger button will be rendered.
		big: PropTypes.bool,
		// The name of the button, submitted as a pair with the button’s value as part of the form data.
		name: PropTypes.string,
		// Defines the value associated with the button’s name when it’s submitted with the form data. This value is 
		// passed to the server in params when the form is submitted.
		value: PropTypes.string,
		// This Boolean attribute specifies that the button should have input focus when the page loads. 
		// @note Only one element in a document can have this attribute.
		autofocus: PropTypes.bool,
		// This Boolean attribute prevents the user from interacting with the button: it cannot be pressed or focused.
		disabled: PropTypes.bool,
		// If true, button will not be rendered.
		hide: PropTypes.bool,
		// Button label rendered as a child of the <button> component before any other child elements but after the icon.
		label: PropTypes.string,
		// Set to true to support HTML in 'label' prop.
		// @warning Be careful when using this flag because it can cause security issues. It uses 'dangerouslySetInnerHTML' 
		// to allow HTML content. 
		allowHtmlLabel: PropTypes.bool,
		// Font icon symbol name.
		icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
		// If true, icon will spin (if supported by font icon set used).
		spinIcon: PropTypes.bool,
		// Icon props
		// @see Icon component
		iconProps: PropTypes.object,

		// Events
		onClick: PropTypes.func,
	}),
	// Insert type
	insertValueType: PropTypes.oneOf(INSERT_VALUE_BUTTON_TYPES),
	// Insert type options
	// @note Options depend on 'insertValueType'.
	insertValueTypeOptions: PropTypes.oneOfType([
		// INSERT_VALUE_BUTTON_TYPE_DIALOG
		PropTypes.shape({
			dialogProps: PropTypes.object,
			dialogOptions: PropTypes.shape({
				id: PropTypes.string,
				className: PropTypes.string,
				closeOnEscape: PropTypes.bool,
				closeOnClickOutside: PropTypes.bool,
				hideCloseBtn: PropTypes.bool,
				maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
			}),
		}),
		// INSERT_VALUE_BUTTON_TYPE_DROPDOWN
		PropTypes.shape({
			// TODO: dropdown insert value
		})
	]),

	// Events
	onChange: PropTypes.func, // Arguments: selected option simple value
	onEnterKey: PropTypes.func, // Arguments: keypress event
};

/**
 * Define component default values for own props
 */
EmailInput.defaultProps = {
	id: '',
	className: '',
	classNamePrefix: '',
	isDisabled: false,
	isMulti: false,
	itemSeparator: ',',
	returnAsString: false,
	formControlStyle: true,
	isClearable: false,
	isLoading: false,
	menuRenderInLayout: true,
	showInsertValueButton: false,
};

export default EmailInput;