import {cloneDeep, get} from 'lodash';
import {getString, resetObjectValues} from "Core/helpers/data";
import {VisitDataObject} from 'DataObjects/visit';
import {getDate, getDateString, STANDARD_DATE_TIME_FORMAT} from 'Core/helpers/datetime';

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {VisitDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new VisitDataObject(
		getString(result, 'uniqueID', null),
		getString(result,	'patientId', null),
		getString(result,	'therapyId', null),
		getDate(get(result, 'visitDate'), STANDARD_DATE_TIME_FORMAT.ISO_DATE),
		getDate(get(result, 'timeFrom'), STANDARD_DATE_TIME_FORMAT.TIME_S),
		getDate(get(result, 'timeTo'), STANDARD_DATE_TIME_FORMAT.TIME_S),
		getString(result,	'note'),
		getString(result,	'officeLocationId', null),
		getString(result,	'therapistId', null),
		getString(result,	'appointmentId', null),
	);
	
	return result;
}

/**
 * Get output data from input data
 * @param {VisitDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep({
		uniqueID: inputData.id,
		visitDate: getDateString(inputData.date, STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		timeFrom: getDateString(
			getDate(inputData.timeFrom, STANDARD_DATE_TIME_FORMAT.TIME), 
			STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S
		),
		timeTo: getDateString(
			getDate(inputData.timeTo, STANDARD_DATE_TIME_FORMAT.TIME),
			STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S
		),
		note: inputData.note,
		officeLocationId: inputData.officeLocationId,
		therapistId: inputData.therapistId,
		therapyId: inputData.therapyId === '*' ? null : inputData.therapyId,
		appointmentId: inputData.appointmentId,
		patientId: inputData.patientId,
	});

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}