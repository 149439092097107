import styles from "./index.module.css";
import "./index.css";

import React from "react";
import PageComponent from "Core/components/PageComponent";
import * as appConfig from "../config";
import * as pageConfig from "./config";
import * as actions from "./actions";
import {connect} from "react-redux";
import {selectors} from "Core/store/reducers";
import {getPageActions} from "Core/helpers/redux";
import {getMenuSidebarShrankFromStorage} from "Layout/elements/MainSidebar/helpers";
import {max_therapy_type_levels, therapy_type_root_levels} from "Config/app";
import Label from "Core/components/display/Label";
import TherapyTypeList from "Pages/apps/default/therapyTypes/components/TherapyTypeList";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {Object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps =  state => ({
	mainSidebarShrank: getMenuSidebarShrankFromStorage(selectors.mainSidebar.shrank(state)),
});

class TherapyTypesPage extends PageComponent {
	constructor(props) {
		super(props, {
			layout: 'content_full_height',
			domPrefix: 'therapy-types-page',
			translationPath: pageConfig.translationPath,
			routerPath: pageConfig.routerPath,
			checkLogin: false,
		}, 'page_title');
		
		// Event handlers
		this.handleListItemSelect = this.handleListItemSelect.bind(this);
	}
	
	
	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }


	// Event handlers ---------------------------------------------------------------------------------------------------
	/**
	 * Handle selecting a list item
	 * @param {number} level - Therapy type
	 * @param {TherapyTypesListItemDataObject} listItem - Clicked list item.
	 */
	handleListItemSelect(level, listItem) {
		const {selectTherapyTypeItemAction} = this.props;
		selectTherapyTypeItemAction(level, listItem);
	}

	
	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {mainSidebarShrank, toggleMainSidebarSizeAction} = this.props;
		
		return this.renderLayout((
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')} ${styles['wrapper']}`}>
				{
					this.hasTranslation('page_short_description') && this.t('page_short_description') ?
						<div className="simple-page-description">
							<Label content={this.t('page_short_description')} supportHtml={true} />
						</div>
						: null
				}

				<div className={`therapy-type-lists ${styles['lists']}`}>
					{new Array(max_therapy_type_levels).fill('').map((i, idx) =>
						<TherapyTypeList 
							key={`level-${therapy_type_root_levels + idx}`}
							className={`${styles['list']} level-${therapy_type_root_levels + idx}`} 
							level={therapy_type_root_levels + idx}
							onListItemSelect={this.handleListItemSelect} 
						/>
					)}
				</div>
			</div>
		), undefined, undefined, {
			app: appConfig,
			mainSidebarShrank,
			toggleMainSidebarSizeAction,
		});
	}
}

export * from "./config";
export default connect(mapStateToProps, getPageActions(actions))(TherapyTypesPage);