import {actionCreators} from "../../reducer";
import {ioJsonDeleteAction, ioJsonFetchItemAction} from "Core/store/actions/io";
import {isset} from "Core/helpers/data";
import * as patientTherapyDataMap from 'DataMap/patientTherapy';
import {hideLoading, hideLoadingFunction, showLoading} from "Core/helpers/loading";


/**
 * Select patient therapy option and save it in the Redux store
 *
 * @param {PatientTherapySelectOptionDataObject} selectOption
 * @return {(function(*): void)|*}
 */
export const selectPatientTherapyAction = selectOption => dispatch => {
	dispatch(actionCreators.setSelectedPatientTherapy(selectOption));
};

/**
 * Clear patient therapy option from the Redux store
 * @return {(function(*): void)|*}
 */
export const clearSelectedPatientTherapyAction = () => dispatch => {
	dispatch(actionCreators.clearSelectedPatientTherapy());
};

/**
 * Fetch a patient therapy
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - Patient therapy ID.
 * @return {function(*): Promise<PatientTherapyDataObject|undefined>}
 */
export const fetchPatientTherapyAction = (abortCallback, id) => dispatch => {
	return ioJsonFetchItemAction(
		abortCallback,
		'defaultAuthorizedApi',
		'member/therapies/fetch-by-id',
		id
	)(dispatch)
		.then(res => isset(res) ? patientTherapyDataMap.input(res?.data) : undefined);
};

/**
 * Load a patient therapy into Redux store
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - Patient therapy ID.
 * @param {string} [loadingSelector=''] - Selector for the loading overlay.
 * @return {function(*): Promise<PatientTherapyDataObject|undefined>}
 */
export const loadPatientTherapyAction = (abortCallback, id, loadingSelector = '') => dispatch => {
	const loading = (!!loadingSelector ? showLoading(loadingSelector) : undefined);
	return fetchPatientTherapyAction(abortCallback, id)(dispatch)
		.then(data => {
			dispatch(actionCreators.setPatientTherapy(data));
			if (!!loading) hideLoading(loading);
			return data;
		});
};

/**
 * Load patient therapy from Redux store
 * @return {(function(*): void)|*}
 */
export const clearPatientTherapyAction = () => dispatch => {
	dispatch(actionCreators.clearPatientTherapy());
}

/**
 * Delete patient therapy
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - patient therapy ID.
 * @return {function(*): Promise<IoJsonResponseObject>}
 */
export const deletePatientTherapyAction = (abortCallback, id) => dispatch => {
	const loading = showLoading('#delete-patient-therapy-dialog .dialog-content-component > .buttons');
	return ioJsonDeleteAction(
		abortCallback,
		'defaultAuthorizedApi',
		'member/therapies/delete-by-ids',
		[id],
		undefined,
		hideLoadingFunction(loading),
	)(dispatch);
};