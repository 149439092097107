import "./index.css";

import React from "react";
import {Route, Switch} from "react-router-dom";
import {AppPageRoute} from "Core/App";
import {pages} from "./pages";
import SiteNotFoundPage from "Core/pages/error/siteNotFound";
import {getObject} from "Core/helpers/data";

const SiteSection = () => (
	<div id="site-section">
		<Switch>
			{pages.map((page, idx) => AppPageRoute({
				key: idx,
				page,
				...getObject(page, 'routerOptions')
			}))}
			<Route path="/" component={SiteNotFoundPage} exact={false} />
		</Switch>
	</div>
);

export * from "./config";
export * from "./pages";
export default SiteSection;