import {getStorageValue, STORAGE_TYPE} from "Core/storage";
import {resources_storage_path} from "Config/app";
import {RESOURCE_KEY} from "Core/resources/cookieConsentResource";
import CookieConsent from "Core/dataProtection/cookieConsent/index";

/**
 * Return a cookie consent (CookieConsent) instance to use anywhere in the app
 * @note If cookie consent instance is not stored in memory, new instance will be created and returned. The newly 
 * created instance will not be stored on memory.
 * @return {CookieConsent}
 */
export const getCookieConsentInstance = () => {
	const fromMemory = getStorageValue(resources_storage_path, RESOURCE_KEY, STORAGE_TYPE.MEMORY);
	if (fromMemory && fromMemory instanceof CookieConsent) return fromMemory;
	else return new CookieConsent();
};