import React from "react";
import BaseComponent from "Core/components/BaseComponent";
import PropTypes from "prop-types";
import {get, omit} from "lodash";
import {FORM_FIELD_LABEL_POSITION} from "./const";

import styles from "./index.module.css";
import {icon_font_required_symbol} from "Config/app";
import Label from "../../../display/Label";
import {DEFAULT_ICON_SYMBOLS} from "Core/const/icons";
import {getArray, getObject, getString} from "Core/helpers/data";

class FormField extends BaseComponent {
	/**
	 * Original margin bottom of the wrapper element
	 * @description This component will change the bottom margin of the wrapper element when there are error or other 
	 * messages rendered below the child input element. Margin will be adjusted to accommodate the height of the 
	 * messages.
	 * @type {number}
	 */
	originalElementMarginBottom = 0;
	
	constructor(props) {
		super(props, {
			domPrefix: 'form-field',
			domManipulationIntervalTimeout: 100
		});
		
		// Action methods
		this.handleLabelClick = this.handleLabelClick.bind(this);
		
		// Messages methods
		this.getMessagesByType = this.getMessagesByType.bind(this);
		this.getMessagesByTypeLength = this.getMessagesByTypeLength.bind(this);
		
		// Render methods
		this.renderChildren = this.renderChildren.bind(this);
		this.shouldRenderMessages = this.shouldRenderMessages.bind(this);
		this.renderMessages = this.renderMessages.bind(this);
	}

	componentDidMount() {
		super.componentDidMount();
		
		// Manage wrapper element bottom margin depending on the height of the rendered messages
		this.originalElementMarginBottom = parseFloat(window.getComputedStyle(this.getDomElement()).marginBottom);
	}


	// DOM manipulation interval methods --------------------------------------------------------------------------------
	/**
	 * Method called on each DOM manipulation interval
	 *
	 * @note This is a placeholder method that should be implemented in child classes in order to use the functionality.
	 *
	 * @param {HTMLElement|Element|null} element - Component's main DOM element or null if component's main DOM element 
	 * is not set.
	 */
	domManipulations(element) {
		const {messageMargin} = this.props;
		
		// Manage wrapper element bottom margin depending on the height of the rendered messages
		const messages = element.querySelector(`.${styles['messages']}`);
		if (messages) {
			const messagesComputedStyle = window.getComputedStyle(messages);
			const messagesWidth = 
				messages.offsetHeight + 
				parseFloat(messagesComputedStyle.marginTop) + 
				parseFloat(messagesComputedStyle.marginBottom) +
				(typeof messageMargin !== 'undefined' ? parseFloat(messageMargin) : (this.originalElementMarginBottom / 2))
			;
			
			element.style.marginBottom = (messagesWidth > this.originalElementMarginBottom ? `${messagesWidth}px` : '');
		} else {
			element.style.marginBottom = '';
		}
	}


	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Label click handle method 
	 */
	handleLabelClick(event) {
		// Focus form control when label is clicked
		const formField = event.target.closest('.form-field');
		const formControl = formField.querySelector('.form-control');
		const checkboxController = formField.querySelectorAll('.checkbox-component');
		const toggleController = formField.querySelector('.toggle-input-component');
		const keyValueListComponent = formField.querySelector('.key-value-list-component');
		
		if (keyValueListComponent) {
			const selectAllCheckbox = keyValueListComponent.querySelector('.labels > .select-wrapper .checkbox-component');
			if (selectAllCheckbox) selectAllCheckbox.click();
		} else if (checkboxController) {
			checkboxController.forEach(c => setTimeout(() => c.click()));
		}
		if (toggleController) toggleController.click();
		else if (formControl) {
			// Handle SelectInput
			if (formControl.classList.contains('select-input-component')) {
				formControl.querySelector('.select-input__input').focus();
			}
			// Handle all other inputs
			else {
				formControl.focus();
			}
		}
	}

	
	// Messages methods -------------------------------------------------------------------------------------------------
	/**
	 * Get messages array by message type (info, error, ...)
	 * 
	 * @param {'default'|'info'|'help'|'success'|'warning'|'error'|string} messageType - Props message type.
	 * @return {Array|*[]}
	 */
	getMessagesByType(messageType) {
		if (messageType) return getArray(this.props, `${messageType.toLowerCase()}Messages`);
		else return [];
	}

	/**
	 * Get the length of the messages array for the specific message type (info, error, ...)
	 *
	 * @param {'default'|'info'|'help'|'success'|'warning'|'error'|string} messageType - Props message type.
	 * @return {number}
	 */
	getMessagesByTypeLength(messageType) { return this.getMessagesByType(messageType).length; }
	
	
	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Return child elements (dropdown content) to render
	 */
	renderChildren() {
		const {multipleInputs, children} = this.props;
		
		if (multipleInputs.length) {
			return React.Children.map(children, (child, index) =>
				child ?
					<div 
						style={{
							flexGrow: `${get(multipleInputs, index, '1')}`,
							flexShrink: `${get(multipleInputs, index, '1')}`,
							flexBasis: `calc(100% / ${multipleInputs.length})`
						}}
					>
						{React.cloneElement(child)}
					</div>
					: null
			);
		} else {
			return children;
		}
	}

	/**
	 * Check if messages should be rendered
	 * 
	 * @param {'default'|'info'|'help'|'success'|'warning'|'error'|string} messageType - Props message type.
	 * @return {boolean}
	 */
	shouldRenderMessages(messageType = '') {
		const types = (Array.isArray(messageType) ? messageType : [messageType]);
		for (let i = 0; i < types.length; i++) {
			if (this.getMessagesByTypeLength(types[i])) return true;
		}
		return false;
	}
	
	/**
	 * Render form field messages
	 * @description Form field messages will be rendered below the form filed child element if they are specified in
	 * the props.
	 * 
	 * @param {'info'|'help'|'success'|'warning'|'error'|string|string[]} [messageType=''] - Message type(s) to render.
	 */
	renderMessages(messageType = '') {
		const {showAllMessages, htmlMessages} = this.props;
		const types = (Array.isArray(messageType) ? messageType : [messageType]);
		
		return (
			<>
				{types.map((type, index) =>
					this.getMessagesByType(type) && this.getMessagesByTypeLength(type) > 0 ?
						<Label 
							key={index}
							element="small"
							elementProps={{className: `message ${styles['message']} ${type.toLowerCase()}-color`}}
							icon={DEFAULT_ICON_SYMBOLS[type.toUpperCase()]}
							content={
								showAllMessages ? this.getMessagesByType(type).join(' ') : this.getMessagesByType(type)[0]
							}
							supportHtml={htmlMessages}
						/>
						: null
				)}
			</>
		);
	}
	
	render() {
		const {
			className, labelClassName, inputClassName, label, labelPosition, labelProps, multipleInputs, required, help, 
			renderMessageTypes, errorMessages 
		} = this.props;
		
		return (
			<div 
				id={this.getDomId()} 
				className={
					`form-field label-position-${labelPosition} ${className} ${styles['wrapper']} ` +
					(errorMessages && errorMessages.length > 0 ? ' error ' : '')
				}
			>
				<div className={`form-field-label ${labelClassName}`} onClick={this.handleLabelClick}>
					{
						required ?
							<div className={`form-field-label-wrapper ${styles['labelWrapper']}`}>
								<Label 
									element="span" 
									elementProps={{
										className: `${styles['labelContent']} ${getString(labelProps, 'elementProps.className')}`,
										...omit(getObject(labelProps, 'elementProps'), ['className'])
									}}
									content={label} 
									{...omit(labelProps, ['elementProps', 'content'])} 
								/>
								<Label
									icon={icon_font_required_symbol}
									tooltip={this.t('required', 'validation')}
									tooltipOptions={{className: styles['requiredIcon']}}
									iconClassName="form-field-label-required-icon"
								/>
							</div>
							:
							<Label content={label} {...omit(labelProps, ['content'])} />
					}
					{
						help ? <Label content={" "} help={help} /> : null
					}
				</div>
				<div className={`form-field-input ${inputClassName}`}>
					{
						multipleInputs.length ?
							<div className={`form-field-multiple-inputs ${styles['multipleInputsWrapper']}`}>
								{this.renderChildren()}
							</div> :
							this.renderChildren()
					}
				</div>
				
				{
					this.shouldRenderMessages(renderMessageTypes) ?
						<div className={`form-field-messages ${styles['messages']}`}>
							{this.renderMessages(renderMessageTypes)}
						</div>
						: null
				}
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
FormField.propTypes = {
	// Form field component id attribute
	id: PropTypes.string,
	// Form field component class attribute
	// @note You can use 'multiline-form-field' when 'labelPosition' is FORM_FIELD_LABEL_POSITION_ALIGNED to vertically 
	// align the label to the top of the input instead of it being in the middle by default.
	className: PropTypes.string,
	// Form field label wrapper class attribute
	labelClassName: PropTypes.string,
	// Form field input wrapper class attribute
	// @note This is not the class for an actual <input> element. It is a class of a form field wrapper element.  
	inputClassName: PropTypes.string,
	// Form field wrapper label to render
	label: PropTypes.any,
	// Form field wrapper label position (see FORM_FIELD_LABEL_POSITION const)
	labelPosition: PropTypes.string,
	// Form field wrapper label props (see Label component)
	labelProps: PropTypes.object,
	// Array that, if not empty, enables styles for multiple input elements inside a form field and specifies flex grow 
	// value (flex shrink will be the same as grow) for each child element.
	multipleInputs: PropTypes.arrayOf(PropTypes.number),
	// Flag that specifies if the field is required
	// @description If true, required icon (*) will be rendered next to the label.
	required: PropTypes.bool,
	// Help text
	// @note Help icon will be rendered next to the label.
	help: PropTypes.string,

	// Form filed messages that will be rendered below the child element
	// @note: There are four types of messages that will be rendered one below the other. Each type can contain multiple
	// messages.
	showAllMessages: PropTypes.bool, // If false only the first message of each type will be displayed
	renderMessageTypes: PropTypes.array, // List of message types that will be rendered
	defaultMessages: PropTypes.array,
	infoMessages: PropTypes.array,
	helpMessages: PropTypes.array,
	successMessages: PropTypes.array,
	warningMessages: PropTypes.array,
	errorMessages: PropTypes.array,
	// If set, this margin will be added below the messages
	// @description Form filed bottom margin is calculated by this component. It takes into account the messages 
	// displayed, and it will add the half of the original form field margin (without messages) after the messages. This
	// prop will override that behavior and add a specific margin below the messages.
	messageMargin: PropTypes.number,
	// Flag that determines if messages should support HTML
	// WARNING: Be careful when using this flag because it can cause security issues. It uses 'dangerouslySetInnerHTML' 
	// to allow HTML content.
	htmlMessages: PropTypes.bool,
};

/**
 * Define component default values for own props
 */
FormField.defaultProps = {
	id: '',
	className: '',
	labelClassName: '',
	inputClassName: '',
	label: '',
	labelPosition: FORM_FIELD_LABEL_POSITION.ALIGNED,
	labelProps: {},
	multipleInputs: [],
	required: false,
	help: '',
	showAllMessages: false,
	renderMessageTypes: ['error', 'warning', 'success', 'default', 'info', 'help'],
	defaultMessages: [],
	infoMessages: [],
	helpMessages: [],
	successMessages: [],
	warningMessages: [],
	errorMessages: [],
	htmlMessages: false,
};

export * from "./const";
export default FormField;