import {hideLoadingFunction, showDialogLoading} from 'Core/helpers/loading';
import {ioJsonAction, ioJsonFetchItemAction} from 'Core/store/actions/io';
import {isset} from 'Core/helpers/data';
import * as visitDataMap from "DataMap/visit";
import {get} from 'lodash';

/**
 * Fetch a visit
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - Visit ID.
 * @return {function(*): Promise<VisitDataObject|undefined>}
 */
export const fetchVisitAction = (abortCallback, id) => dispatch => {
	const loading = showDialogLoading('edit-visit-dialog');
	return ioJsonFetchItemAction(
		abortCallback,
		'defaultAuthorizedApi',
		'member/visit/fetch-by-id',
		id,
		undefined,
		hideLoadingFunction(loading),
	)(dispatch)
		.then(res => isset(res) ? visitDataMap.input(get(res, 'data')) : undefined);
}

/**
 * Create a visit
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {VisitDataObject} visit
 * @return {function(*): Promise<VisitDataObject|undefined>}
 */
export const createVisitAction = (abortCallback, visit) => dispatch => {
	const loading = showDialogLoading('create-visit-dialog');
	return ioJsonAction(
		undefined,
		'defaultAuthorizedApi',
		'member/visit/create',
		{
			id: null,
			data: visitDataMap.output(visit),
			requestSavedData: true,
		},
		hideLoadingFunction(loading),
	)(dispatch)
		.then(res => isset(res) ? visitDataMap.input(get(res, 'data')) : undefined);
}

/**
 * Update a visit
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id
 * @param {VisitDataObject} visit
 * @return {function(*): Promise<VisitDataObject|undefined>}
 */
export const updateVisitAction = (abortCallback, id, visit) => dispatch => {
	const loading = showDialogLoading('edit-visit-dialog');
	return ioJsonAction(
		undefined,
		'defaultAuthorizedApi',
		'member/visit/update-by-id',
		{
			id,
			data: visitDataMap.output(visit),
			requestSavedData: true,
		},
		hideLoadingFunction(loading),
	)(dispatch)
		.then(res => isset(res) ? visitDataMap.input(get(res, 'data')) : undefined);
}