import {hideLoadingFunction, showDialogLoading} from 'Core/helpers/loading';
import {ioJsonAction} from 'Core/store/actions/io';
import {getString} from 'Core/helpers/data';
import * as patientTherapyDataMap from 'DataMap/patientTherapy';

/**
 * Create therapy
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {PatientTherapyDataObject} therapy - Therapy to create.
 * @param {string} dialogId - ID of the dialog used for the loading overlay.
 * @return {function(*): Promise<string>} Promise that resolves to the ID of the newly created therapy.
 */
export const createTherapyAction = (abortCallback, therapy, dialogId) => dispatch => {
	const loading = (dialogId ? showDialogLoading(dialogId) : null);
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'member/therapies/create',
		{
			id: null,
			data: patientTherapyDataMap.output(therapy),
			requestSavedData: true,
		},
		loading ? hideLoadingFunction(loading) : undefined,
	)(dispatch)
		.then(responseData => getString(responseData, 'data.uniqueID'));
};

/**
 * Update therapy
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {PatientTherapyDataObject} therapy - Therapy to create.
 * @param {string} dialogId - ID of the dialog used for the loading overlay.
 * @return {function(*): Promise<string>} Promise that resolves to the ID of the updated therapy.
 */
export const updateTherapyAction = (abortCallback, therapy, dialogId) => dispatch => {
	const loading = (dialogId ? showDialogLoading(dialogId) : null);
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'member/therapies/update-by-id',
		{
			id: therapy.id,
			data: patientTherapyDataMap.output(therapy),
			requestSavedData: true,
		},
		loading ? hideLoadingFunction(loading) : undefined,
	)(dispatch)
		.then(responseData => getString(responseData, 'data.uniqueID'));
}