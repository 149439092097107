import {cloneDeep, find, findIndex, get, groupBy} from "lodash";
import {deleteStorageKey, getStorageValue, setStorageValue, STORAGE_TYPE} from "Core/storage";
import {app_id, cookie_consent_enabled, refresh_page_on_cookie_consent} from "Config/app";
import cookies from "../../../dataProtection/cookies";

/**
 * Main class to handle cookie consent
 */
class CookieConsent {
	/**
	 * Flag that specifies if cookie notice should be shown
	 * @type {boolean}
	 */
	showNotice;
	
	/**
	 * List of all cookies with the current state of acceptance
	 * @type {{cookie: CookieData, accepted: boolean}[]}
	 */
	cookies = [];

	/**
	 * CookieConsent class constructor
	 * @note Cookies are defined in 'src/dataProtection/cookies.js'
	 */
	constructor() {
		// Data methods
		this.load = this.load.bind(this);
		this.loadSettings = this.loadSettings.bind(this);
		this.loadCookiesSettings = this.loadCookiesSettings.bind(this);
		this.loadCookieSettings = this.loadCookieSettings.bind(this);
		this.isCookieAccepted = this.isCookieAccepted.bind(this);
		this.loadGroupedCookieSettings = this.loadGroupedCookieSettings.bind(this);
		this.isGroupAccepted = this.isGroupAccepted.bind(this);
		
		// Action methods
		this.openCookieNotice = this.openCookieNotice.bind(this);
		this.closeCookieNotice = this.closeCookieNotice.bind(this);
		this.acceptAll = this.acceptAll.bind(this);
		this.rejectAll = this.rejectAll.bind(this);
		this.save = this.save.bind(this);

		// Load settings
		this.load();
		
		// Save settings to storage
		setStorageValue(
			`${app_id}_cookieConsentSettings`,
			JSON.stringify({showNotice: this.showNotice, cookies: this.cookies}),
			STORAGE_TYPE.LOCAL
		);

		// Set Redux store flag for showing cookie consent
		if (cookie_consent_enabled) {
			if (getStorageValue('show_cookie_notice', STORAGE_TYPE.REDUX) !== this.showNotice) {
				setStorageValue('show_cookie_notice', this.showNotice, STORAGE_TYPE.REDUX);
			}
		}
		else deleteStorageKey('show_cookie_notice', STORAGE_TYPE.REDUX);
	}


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Load cookie consent settings data
	 * @description Cookie consent data is loaded from local storage or initialized with default content statuses if it 
	 * does not exist in local storage. If new cookies are added and are not found in local storage, they will also be 
	 * initialized with default content statuses. Default consent status us false for all non-necessary cookies.
	 */
	load() {
		// Try to load already saved consent settings from storage
		try {
			const settings = JSON.parse(getStorageValue(`${app_id}_cookieConsentSettings`, STORAGE_TYPE.LOCAL));
			this.showNotice = settings.showNotice;
			this.cookies = cookies.map(cookie => {
				// Try to find settings cookie in loaded consent settings
				/** @type {{cookie: CookieData, accepted: boolean}} */
				const loadedSettingsItem = find(settings.cookies, {cookie});

				// If cookie does not exist in settings (new cookie), set 'showNotice' to true so that user will be prompted
				// to accept cookies again. This is done when new cookies are added so that users can choose to accept them.
				if (!loadedSettingsItem) this.showNotice = true;

				// Initialize cookie using loaded or default data if data was not loaded
				return {
					accepted: (cookie.group === 'necessary' || loadedSettingsItem.accepted),
					cookie: (loadedSettingsItem ? cloneDeep(loadedSettingsItem.cookie) : cookie)
				}
			});
		}
		// If there are no saved consent settings
		catch (e) {
			// Show notice by default
			this.showNotice = true;

			// Initialize cookies by setting consent to false for all non-necessary cookies
			this.cookies = cookies.map(cookie => ({accepted: (cookie.group === 'necessary'), cookie}));
		}
	}
	
	/**
	 * Load and return cookie consent settings
	 * @note Cookie consent settings are loaded from local storage or initialized with default content statuses.
	 * @return {{showNotice: boolean, cookies: {cookie: CookieData, accepted: boolean}[]}}
	 */
	loadSettings() {
		this.load();
		return {showNotice: this.showNotice, cookies: this.cookies};
	}

	/**
	 * Load consent settings for all cookies
	 * @note Cookie consent settings cookies are loaded from local storage or initialized with default content statuses.
	 * @return {{cookie: CookieData, accepted: boolean}[]}
	 */
	loadCookiesSettings() { return this.loadSettings().cookies; }

	/**
	 * Load consent settings for a specific cookie
	 * @note Cookie consent setting for a cookie is loaded from local storage or initialized with default content status.
	 * 
	 * @param {CookieData} cookie - Cookie to search for.
	 * @return {{cookie: CookieData, accepted: boolean}}
	 */
	loadCookieSettings(cookie) { return find(this.loadCookiesSettings(), {cookie}); }

	/**
	 * Check if specific cookie is accepted by the user
	 * @param {CookieData} cookie - Cookie to search for.
	 * @return {boolean}
	 */
	isCookieAccepted(cookie) { return (get(this.loadCookieSettings(cookie), 'accepted') === true); }
	
	/**
	 * Load grouped cookie consent settings
	 * @description Cookie consent settings are grouped by cookie group names and returned as an object where group names
	 * are keys.
	 * @note Grouped cookie consent settings are loaded from local storage or initialized with default content statuses.
	 * @return {Object<string, {cookie: CookieData, accepted: boolean}[]>}
	 */
	loadGroupedCookieSettings() {
		this.load();
		return groupBy(this.cookies, v => v.cookie.group);
	}

	/**
	 * Check if all cookies in a specific group are accepted
	 * @param {string} groupName - Cookie group name.
	 * @return {boolean}
	 */
	isGroupAccepted(groupName) {
		/** @type {{cookie: CookieData, accepted: boolean}[]} */
		const group = get(this.loadGroupedCookieSettings(), groupName);
		return (group && findIndex(group, {accepted: false}) === -1);
	}

	/**
	 * Use this method to check if you can use a specific cookie
	 * @description Check if cookie can be used in the app.
	 * @note This is different from 'isCookieAccepted' function because it checks if cookie consent is enable. If it is
	 * not it will always return true because, in that case, cookie is safe use.
	 *
	 * @param {CookieData} cookie - Cookie to check.
	 * @return {boolean} If true, it is safe (user gave consent) to use the cookie anywhere inside the app.
	 */
	static isAllowed(cookie) {
		if (!cookie_consent_enabled) return true;
		return new CookieConsent().isCookieAccepted(cookie);
	}


	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Open cookie notice
	 * @description This will render cookie notice component on the page if cookie consent is enabled in app config.
	 */
	openCookieNotice() {
		this.load();
		this.showNotice = true;
		setStorageValue(
			`${app_id}_cookieConsentSettings`,
			JSON.stringify({showNotice: this.showNotice, cookies: this.cookies}),
			STORAGE_TYPE.LOCAL
		);
		setStorageValue('show_cookie_notice', this.showNotice, STORAGE_TYPE.REDUX);
	}

	/**
	 * Close cookie notice
	 */
	closeCookieNotice() {
		this.load();
		this.showNotice = false;
		setStorageValue(
			`${app_id}_cookieConsentSettings`,
			JSON.stringify({showNotice: this.showNotice, cookies: this.cookies}),
			STORAGE_TYPE.LOCAL
		);
		setStorageValue('show_cookie_notice', this.showNotice, STORAGE_TYPE.REDUX);
	}
	
	/**
	 * Accept all cookies
	 */
	acceptAll() {
		this.load();
		this.showNotice = false;
		this.cookies = this.cookies.map(item => ({...item, accepted: true}));
		this.save();
	}

	/**
	 * Reject all unnecessary cookies
	 */
	rejectAll() {
		this.load();
		this.showNotice = false;
		this.cookies = this.cookies.map(item => ({...item, accepted: (item.cookie.group === 'necessary')}));
		this.save();
	}
	
	/**
	 * Save cookie settings to storage
	 * @param {{showNotice: boolean, cookies: {cookie: CookieData, accepted: boolean}[]}|null} [settings=null]
	 */
	save(settings = null) {
		// Save current settings
		if (settings === null) {
			setStorageValue(
				`${app_id}_cookieConsentSettings`,
				JSON.stringify({showNotice: this.showNotice, cookies: this.cookies}),
				STORAGE_TYPE.LOCAL
			);
			setStorageValue('show_cookie_notice', this.showNotice, STORAGE_TYPE.REDUX);

			// Delete revoked cookies
			this.cookies.forEach(cookieSettings => {
				if (!cookieSettings.accepted) deleteStorageKey(cookieSettings.cookie.name, cookieSettings.cookie.storage);
			});
		}
		// Save custom settings
		else {
			setStorageValue(
				`${app_id}_cookieConsentSettings`,
				JSON.stringify(settings),
				STORAGE_TYPE.LOCAL
			);
			setStorageValue('show_cookie_notice', settings.showNotice, STORAGE_TYPE.REDUX);

			// Delete revoked cookies
			settings.cookies.forEach(cookieSettings => {
				if (!cookieSettings.accepted) deleteStorageKey(cookieSettings.cookie.name, cookieSettings.cookie.storage);
			});
		}

		// Refresh page (reload app)
		if (refresh_page_on_cookie_consent) window.location.reload();
	}
}

export default CookieConsent;