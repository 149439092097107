import React from "react";
import BaseComponent from 'Core/components/BaseComponent';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getPageActions} from 'Core/helpers/redux';
import {
	fetchPrescribedTherapiesItemAction as fetchPrescribedTherapiesAction
} from "Pages/apps/default/search/searchTherapies/actions";
import * as searchTherapiesPageConfig from "Pages/apps/default/search/searchTherapies/config";
import {PAGINATION_TYPE} from 'Core/components/action/Pagination';
import DataTable from 'Core/components/advanced/DataTable';
import {selectors} from '../../reducer';
import {hideLoading, showLoading} from 'Core/helpers/loading';
import {getArray, isset} from 'Core/helpers/data';
import {get} from 'lodash';

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	patientRecord: selectors.getPatientRecord(state),
	therapyId: get(selectors.getPatientTherapy(state), 'id', null),
});

class PrescribedTherapies extends BaseComponent {
	constructor(props) {
		super(props, {
			translationPath: `${searchTherapiesPageConfig.translationPath}.ItemPopup.MainTab`,
		});

		// Initiate component's state
		this.state = {
			/** @type {PatientPrescribedTherapyDataObject[]} */
			prescribedTherapies: null,
		}
		
		// Data methods
		this.reload = this.reload.bind(this);
	}

	/** @inheritDoc */
	async asyncComponentDidMount() {
		await super.asyncComponentDidMount();

		// Load the list of prescribed therapies
		await this.reload(true);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const {therapyId} = this.props;

		// Reload the list if 'therapyId' prop changes
		if (therapyId !== prevProps.therapyId) {
			if (!!therapyId) this.reload(true).then();
			// Clear the list if selected therapy is cleared
			else this.setState({prescribedTherapies: null}).then();
		}
	}

	/**
	 * Reload the prescribed therapies
	 * 
	 * @param {boolean} [onMount] - Flag that specifies if this method is called as a part of the mount cycle.
	 * @return {Promise<Object>|Promise<*>}
	 */
	reload(onMount) {
		const {therapyId, fetchPrescribedTherapiesAction} = this.props;
		const executeAbortableAction = (onMount ? this.executeAbortableActionMount : this.executeAbortableAction);
		
		if (!onMount || therapyId !== null) {
			const loading = showLoading('#prescribed-therapies-table');
			return executeAbortableAction(fetchPrescribedTherapiesAction, therapyId)
				.then(res => {
					if (isset(res)) {
						this.setState({prescribedTherapies: getArray(res, 'data')}).then(() => hideLoading(loading));
					} else {
						hideLoading(loading);
					}
				});
		}
		
		return Promise.resolve();
	}
	
	render() {
		const {active} = this.props;
		const {prescribedTherapies} = this.state;
		
		return (
			<div className={!active ? 'no-display' : ''}>
				<DataTable
					id="prescribed-therapies-table"
					responsive={true}
					responsiveBreakpointName="bp-xl"
					highlightOnHover={true}
					columns={[
						{
							name: 'prescribedTherapyType',
							label: this.t('prescribedTherapyTypeField'),
							emptyAsDefault: true,
							defaultValue: '—',
						},
						{
							name: 'prescribedTherapySubtype',
							label: this.t('prescribedTherapySubtypeField'),
							emptyAsDefault: true,
							defaultValue: '—',
						},
						{
							name: 'prescribedTherapySubSubtype',
							label: this.t('prescribedTherapySubSubtypeField'),
							emptyAsDefault: true,
							defaultValue: '—',
						},
						{
							name: 'duration',
							label: this.t('durationField'),
							emptyAsDefault: true,
							defaultValue: '—',
							width: 1,
						},
						{
							name: 'additionalInfo',
							label: this.t('additionalInfoField'),
							emptyAsDefault: true,
							defaultValue: '—',
						}
					]}
					data={prescribedTherapies}
					paginationType={PAGINATION_TYPE.NONE}
				/>
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
PrescribedTherapies.propTypes = {
	active: PropTypes.bool,
};

/**
 * Define component default values for own props
 */
PrescribedTherapies.defaultProps = {
	
};

export default connect(
	mapStateToProps, getPageActions({fetchPrescribedTherapiesAction}), null, {forwardRef: true}
)(PrescribedTherapies);