import "./index.css";

import React from "react";
import DataComponent, {executeComponentCallback} from "Core/components/DataComponent";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getPageActions} from "Core/helpers/redux"; 
import * as actions from "./actions";
import {AsyncMountError} from "Core/errors";
import {getArray, getNumber, getString, isset} from "Core/helpers/data";
import {forOwn, get, find} from "lodash";
import * as filterDataMap from "./dataMap/filter";
import SimpleStaticSearch, {
	SIMPLE_STATIC_SEARCH_DISPLAY_TYPE,
	SIMPLE_STATIC_SEARCH_LAYOUT,
	SimpleStaticSearchOptionObject
} from "Core/components/advanced/SimpleStaticSearch";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import {STANDARD_DATE_TIME_FORMAT} from "Core/const/datetime";
import Label from "Core/components/display/Label";
import Spinner from "Core/components/display/Spinner";
import Icon from "Core/components/display/Icon";
import {capitalize} from "Core/helpers/string";
import NumberLabel from "Core/components/display/NumberLabel";
import {ResponsiveLine} from "@nivo/line";
import {ResponsivePie} from "@nivo/pie";
import {StatisticsFilterDataObject} from "Components/advanced/Statistics/dataObjects";
import {STATISTICS_CHART_TYPE, STATISTICS_TYPE_VALUE_FORMAT} from "Components/advanced/Statistics/const";
import {formatDate, getDate} from "Core/helpers/datetime";
import DateLabel from "Core/components/display/DateLabel";
import {formatNumber, getAppNumberLocaleCode} from "Core/helpers/number";
import {LOCALE_DATE_FORMAT_NAME} from "Core/const/locale";
import {selectors} from "Core/store/reducers";
import {isAfter} from "date-fns";
import {isBreakpointSmallerOrEqual} from "Core/helpers/dom";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	isMobileBreakpoint: selectors.breakpoint.isMobileBreakpoint(state),
	breakpointName: selectors.breakpoint.getCurrentBreakpointName(state),
});

class Statistics extends DataComponent {
	constructor(props) {
		super(props, {
			data: {
				/**
				 * Currently selected search filter
				 * @type {StatisticsFilterDataObject}
				 */
				filter: new StatisticsFilterDataObject(),
				/**
				 * Flag showing if filter is loading
				 * @type {boolean}
				 */
				filterLoading: false,
			},
			
			/**
			 * Medical record category codebook loaded from IO
			 * @note If undefined, codebook is not loaded yet. If null, codebook is being loaded.
			 * @type {CodebookItem[]|undefined|null}
			 */
			medicalRecordCategoryCodebook: undefined,
			/**
			 * Therapy type codebook loaded from IO
			 * @note If undefined, codebook is not loaded yet. If null, codebook is being loaded.
			 * @type {CodebookItem[]|undefined|null}
			 */
			therapyTypeCodebook: undefined,
			/**
			 * Therapy subtype codebook loaded from IO
			 * @note If undefined, codebook is not loaded yet. If null, codebook is being loaded.
			 * @type {CodebookItem[]|undefined|null}
			 */
			therapySubtypeCodebook: undefined,
			/**
			 * Therapy sub-subtype codebook loaded from IO
			 * @note If undefined, codebook is not loaded yet. If null, codebook is being loaded.
			 * @type {CodebookItem[]|undefined|null}
			 */
			therapySubSubtypeCodebook: undefined,
		}, {
			domPrefix: 'statistics-component',
			translationPath: 'Statistics',
			disableLoad: true,
		});

		// Refs
		this.mainDataFilterRef = null;

		// Data methods
		this.loadPageCodebook = this.loadPageCodebook.bind(this);
		this.clearTherapySubtypes = this.clearTherapySubtypes.bind(this);
		this.clearTherapySubSubtypes = this.clearTherapySubSubtypes.bind(this);
		this.reloadMainData = this.reloadMainData.bind(this);
		this.filterMainData = this.filterMainData.bind(this);
		
		// Action methods
		this.setScope = this.setScope.bind(this);
	}
	
	componentDidMount() {
		super.componentDidMount();

		const {data, appliedFilter} = this.props;
		
		try {
			// If main data was already loaded (user already retrieved some statistics)
			if (data !== null) {
				// Reload main data with currently applied filter
				this.reloadMainData().then(res => { if (!isset(res)) throw new AsyncMountError(); });
			}

			// Load all codebooks used in the applied filter
			// @description Filter select components that use codebooks load them on menu open, so they need to be 
			// preloaded for all applied filter fields in order to select them properly (usually when opening the page).
			const codebooks = {
				medicalRecordCategory: 'medicalRecordCategoryId',
				therapyType: 'therapyTypeId',
				therapySubtype: 'therapySubTypeId',
				therapySubSubtype: 'therapySubSubTypeId'
			};

			forOwn(codebooks, (filterField, codebook) => {
				if (!!get(appliedFilter, filterField) && !isset(get(this.state, `${codebook}Codebook`))) {
					let actionParams = [];
					switch (codebook) {
						case 'therapySubtype': actionParams = [get(appliedFilter, 'therapyTypeId', null)]; break;
						case 'therapySubSubtype': actionParams = [get(appliedFilter, 'therapySubTypeId', null)]; break;
						// no default
					}
					this.loadPageCodebook(codebook, ...actionParams).then();
				}
			});
		} catch (e) {
			console.error(e);
			throw new AsyncMountError();
		}
	}


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Load a specific page codebook data into local state
	 * @note Page codebook actions are define in 'store/page/actions/codebooks' directory.
	 *
	 * @param {string} codebookName - Name of the codebook to load (for example: 'therapist').
	 * @param {any} [actionParams] - Any codebook action parameters (see the codebook definitions for reference).
	 * @return {Promise<CodebookItem[]>}
	 */
	loadPageCodebook(codebookName, ...actionParams) {
		const loadCodebookAction = get(this.props, `fetch${capitalize(codebookName)}CodebookAction`);

		if (loadCodebookAction) {
			this.setState({[`${codebookName}Codebook`]: null})
				.then(() => this.executeAbortableAction(loadCodebookAction, ...actionParams))
				.then(codebook => this.setState({[`${codebookName}Codebook`]: codebook}).then(() => codebook));
		}
		return Promise.resolve([]);
	}

	/**
	 * Clear therapy subtypes
	 *
	 * @description Clears both the loaded codebook and the filter value.
	 * @return Promise<Object>
	 */
	clearTherapySubtypes() {
		return this.mainDataFilterRef.clearField('therapySubTypeId', null)
			.then(() => this.setState({therapySubtypeCodebook: undefined}));
	}

	/**
	 * Clear therapy sub-subtypes
	 *
	 * @description Clears both the loaded codebook and the filter value.
	 * @return Promise<Object>
	 */
	clearTherapySubSubtypes() {
		return this.mainDataFilterRef.clearField('therapySubSubTypeId', null)
			.then(() => this.setState({therapySubSubtypeCodebook: undefined}));
	}

	/**
	 * Reload main data using currently applied filter
	 * @param {string} [scopeToApply] - Statistics scope (daily, monthly, ...) to apply on reload.
	 * @return {Promise<*>}
	 */
	reloadMainData(scopeToApply) {
		const {loadStatisticsFunction, appliedFilter, dataMap} = this.props;
		const oFilter = filterDataMap.output(appliedFilter);
		const scope = this.getProp('scope');
		const scopeToUse = isset(scopeToApply) ? scopeToApply : scope;

		return this.executeAbortableAction(loadStatisticsFunction, scopeToUse, oFilter, dataMap)
			.then(res => {
				if (isset(scopeToApply)) this.setScope(scopeToApply);
				this.mainDataFilterRef?.reload();
				return res;
			});
	}

	/**
	 * Filter main data
	 * @param {Object} filter - Filter object where keys are filter field names and values are filter values.
	 * @param {string} [scopeToApply] - Statistics scope (daily, monthly, ...) to apply on filter.
	 * @return {Promise<*>}
	 */
	filterMainData(filter, scopeToApply) {
		const {loadStatisticsFunction, setFilterFunction, dataMap, showErrorMessageAction} = this.props;
		const oFilter = filterDataMap.output(filter);
		const scope = this.getProp('scope');
		const scopeToUse = isset(scopeToApply) ? scopeToApply : scope;
		
		// Validate if both dates are specified
		if (!get(filter, 'dateFrom') || !get(filter, 'dateTo')) {
			showErrorMessageAction(this.t('intervalValidationError'));
			return Promise.resolve();
		}
		// Validate if from date is not after to date
		else if (isAfter(filter.dateFrom, filter.dateTo)) {
			showErrorMessageAction(this.t('dateFromAfterToValidationError'));
			return Promise.resolve();
		}
		else {
			// Set filter so that the change will be detected after IO
			setFilterFunction(filter);

			return this.setValue('filterLoading', true)
				.then(() => { if (isset(scopeToApply)) this.setScope(scopeToApply); })
				.then(() => this.executeAbortableAction(loadStatisticsFunction, scopeToUse, oFilter, dataMap))
				.then(() => this.setValue('filterLoading', false));
		}
	}


	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Set current scope
	 * @note Scope will not be set if it is the same as the current one.
	 * 
	 * @param {string} scopeToSet - Statistics scope (daily, monthly, ...) to set.
	 */
	setScope(scopeToSet) {
		const {scope, setScopeFunction} = this.props;
		
		if (scopeToSet !== scope) {
			setScopeFunction(scopeToSet);
			executeComponentCallback(this.props.onScopeChange, scopeToSet);
		}
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {
			className, scopes, scopesTranslationPath, typeScopeMap, appliedFilter, totalValueLabel, isMobileBreakpoint,
			chartTypeMap, breakpointName, colorScheme, 
		} = this.props;
		/** @type ?StatisticsDataObject[]|undefined */
		const data = this.getProp('data');
		const scope = this.getProp('scope');
		const scopeStatisticsTypes = getArray(typeScopeMap, scope);
		/** @type StatisticsDataObject[] */
		const dataArray = getArray(data).filter(i => scopeStatisticsTypes.includes(i.type));
		const scopesArray = getArray(scopes);
		const {
			medicalRecordCategoryCodebook, therapyTypeCodebook, therapySubtypeCodebook, therapySubSubtypeCodebook,
		} = this.state;
		
		return (
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')} ${className}`}>
				<SimpleStaticSearch
					styleName="compact"
					className="main-search rows-3"
					collapsable={true}
					layout={SIMPLE_STATIC_SEARCH_LAYOUT.STACKED}
					submitOnEnter={false}
					options={[
						new SimpleStaticSearchOptionObject(
							'dateFrom',
							this.t('dateFromField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.DATE,
							{
								valueFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S,
								defaultValue: get(appliedFilter, 'dateFrom', this.getProp('initialDateFrom')),
							},
							undefined,
							undefined,
							this.getProp('initialDateFrom', null),
						),
						new SimpleStaticSearchOptionObject(
							'dateTo',
							this.t('dateToField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.DATE,
							{
								valueFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S,
								defaultValue: get(appliedFilter, 'dateTo', this.getProp('initialDateTo')),
							},
							undefined,
							undefined,
							this.getProp('initialDateTo', null),
						),
						new SimpleStaticSearchOptionObject(
							'medicalRecordCategoryId',
							this.t('medicalRecordCategoryField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT,
							{
								isClearable: true,
								options: [
									{label: this.t('allMedicalRecordCategoriesLabel'), value: '*'},
									...getArray(medicalRecordCategoryCodebook).map(i => ({label: i.name, value: i.id}))
								],
								isLoading: (medicalRecordCategoryCodebook === null),
								onMenuOpen: () => {
									if (!isset(medicalRecordCategoryCodebook)) {
										this.loadPageCodebook('medicalRecordCategory').then();
									}
								},
								placeholder: this.t('regardlessMedicalRecordCategoryLabel'),
							},
							undefined,
							undefined,
							'*'
						),
						new SimpleStaticSearchOptionObject(
							'therapyTypeId',
							this.t('therapyTypeField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT,
							{
								isClearable: true,
								options: [
									{label: this.t('regardlessTherapyTypeLabel'), value: '*'},
									...getArray(therapyTypeCodebook).map(i => ({label: i.name, value: i.id}))
								],
								isLoading: (therapyTypeCodebook === null),
								onMenuOpen: () => {
									if (!isset(therapyTypeCodebook)) this.loadPageCodebook('therapyType').then()
								},
								onChange: v => {
									this.mainDataFilterRef.handleValueChange('therapyTypeId', v);
									this.clearTherapySubtypes()
										.then(() => this.clearTherapySubSubtypes())
										.then(() => { if (v) this.loadPageCodebook('therapySubtype', v).then(); });
								},
								placeholder: this.t('unsetTherapyTypeLabel'),
							},
							undefined,
							undefined,
							'*'
						),
						new SimpleStaticSearchOptionObject(
							'therapySubTypeId',
							this.t('therapySubTypeField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT,
							{
								isClearable: true,
								options: [
									{label: this.t('regardlessTherapySubTypeLabel'), value: '*'},
									...getArray(therapySubtypeCodebook).map(i => ({label: i.name, value: i.id}))
								],
								isLoading: (therapySubtypeCodebook === null),
								onChange: v => {
									this.mainDataFilterRef.handleValueChange('therapySubTypeId', v);
									this.clearTherapySubSubtypes()
										.then(() => { if (v) this.loadPageCodebook('therapySubSubtype', v).then(); });
								},
								isDisabled: !isset(therapySubtypeCodebook),
								placeholder: this.t('unsetTherapySubTypeLabel'),
							},
							undefined,
							undefined,
							'*'
						),
						new SimpleStaticSearchOptionObject(
							'therapySubSubTypeId',
							this.t('therapySubSubTypeField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT,
							{
								isClearable: true,
								options: [
									{label: this.t('regardlessTherapySubSubTypeLabel'), value: '*'},
									...getArray(therapySubSubtypeCodebook).map(i => ({label: i.name, value: i.id}))
								],
								isLoading: (therapySubSubtypeCodebook === null),
								isDisabled: !isset(therapySubSubtypeCodebook),
								placeholder: this.t('unsetTherapySubSubTypeLabel'),
							},
							undefined,
							undefined,
							'*'
						),
					]}
					value={appliedFilter}
					title={(<Label icon="search" content={this.t('search_title')} />)}
					applied={data !== null}
					enableToolbar={true}
					enableResetButton={false}
					enableClearButton={true}
					enableSearchButton={false}
					onChange={this.filterMainData}
					ref={node => { this.mainDataFilterRef = node; }}
				/>

				{scopesArray.length > 0 ?
					<div className="statistics-submit-section-wrapper">
						<Label element="span" content={this.t('statisticsScopeTitle')} />
						{scopesArray.map(s =>
							<Button
								key={s}
								big={true}
								label={this.t(s, getString(scopesTranslationPath))}
								displayType={scope === s ? BUTTON_DISPLAY_TYPE.SOLID : BUTTON_DISPLAY_TYPE.TRANSPARENT}
								displayStyle={scope === s ? BUTTON_STYLE.ACTION : BUTTON_STYLE.SUBTLE}
								onClick={() => {
									if (this.mainDataFilterRef) {
										this.filterMainData(this.mainDataFilterRef.getDataToReturn(), s).then();
									}
								}}
							/>
						)}
					</div>
					: null
				}

				{data !== null ?
					<div className={`statistics-result-wrapper`}>
						{dataArray.map(i =>
							<div 
								key={i.type} 
								className={`statistic-type-result chart-type-${getString(chartTypeMap, i.type)}`}
							>
								<h2>{this.tt('title', `statisticsType.${i.type}`)}</h2>

								{getString(chartTypeMap, i.type) === STATISTICS_CHART_TYPE.LINE ?
									<div className={`statistic-type-values card-grid xs per-row-6`}>
										<div className={`statistic-type-value card statistic-card-small xs total`}>
											<div className="card-content">
												<div className="description">
													<Label content={this.tt('total', `statisticsType.${i.type}`)}/>
												</div>
												<div className="value">
													<NumberLabel
														number={getNumber(find(getArray(i,'values'),{label:totalValueLabel}),'value')}
														format={getString(STATISTICS_TYPE_VALUE_FORMAT, i.type, '0')}
													/>
												</div>
											</div>
										</div>

										{getArray(i, 'values').filter(i => i.label !== totalValueLabel).map(v =>
											<div key={v.label} className={`statistic-type-value card statistic-card-small xs`}>
												<div className="card-content">
													<div className="description"><Label content={v.label}/></div>
													<div className="value">
														<NumberLabel
															number={v.value}
															format={getString(STATISTICS_TYPE_VALUE_FORMAT, i.type, '0')}
														/>
													</div>
												</div>
											</div>
										)}
									</div>
									: null
								}

								<div
									className={
										`statistic-type-chart ${getArray(i, 'chart').length === 0 ? 'empty' : ''}`
									}
									style={
										getString(chartTypeMap, i.type) === STATISTICS_CHART_TYPE.LINE ? 
											{
												height: (40 + (getArray(i, 'chart').length * 20) + 40),
												minHeight: 300,
											}
										: getString(chartTypeMap, i.type) === STATISTICS_CHART_TYPE.PIE ?
											{
												height: (
													isBreakpointSmallerOrEqual(breakpointName, 'bp-xs') ?
														`calc(100vw - var(--padding-section) + 20px ` + 
														`+ ${getArray(i, 'chart').length * 20}px)` 
														: 
														''
												),
												aspectRatio: (
													getArray(i, 'chart').length === 0 || 
													isBreakpointSmallerOrEqual(breakpointName, 'bp-xs') ? 
														'' : '10/8'
												)
											}
										: 
											{
												height: '50vh',
												minHeight: 500,
											}
									}
								>
									{getArray(i, 'chart').length > 0 ?
										getString(chartTypeMap, i.type) === STATISTICS_CHART_TYPE.PIE ?
											<ResponsivePie
												colors={{scheme: colorScheme}}
												data={i.chart}
												tooltip={({datum}) => (
													<div className="point-tooltip">
														<span className="point-tooltip-label">
															<Icon symbol="circle" style={{color: getString(datum, 'color')}}/>
															<Label content={getString(datum, 'label')}/>:
														</span>
														<span className="point-tooltip-value">
															<Label content={getString(datum, 'formattedValue')} />
														</span>
													</div>
												)}
												margin={{
													top: (
														isBreakpointSmallerOrEqual(breakpointName, 'bp-xs') ?
															-((getArray(i, 'chart').length * 20) - 10) :
															20
													),
													right: (isBreakpointSmallerOrEqual(breakpointName, 'bp-xs') ? 20 : 240),
													bottom: 20,
													left: 20,
												}}
												innerRadius={0.6}
												enableArcLabels={true}
												arcLabelsTextColor={{from: 'color', modifiers: [['darker', '2.5']]}}
												enableArcLinkLabels={false}
												layers={[
													'arcs', 'arcLabels', 'legends',
													({centerX, centerY}) => {
														return (
															<text
																x={centerX}
																y={centerY}
																textAnchor="middle"
																dominantBaseline="central"
																style={{
																	fontSize: '1.25em', 
																	fontWeight: 600, 
																	fill: 'var(--text-color-main)'
																}}
															>
																<NumberLabel
																	number={getNumber(find(i.values, {label: totalValueLabel}), 'value')}
																/>
															</text>
														)
													} 
												]}
												valueFormat={v => `${formatNumber(
													v, 
													getString(STATISTICS_TYPE_VALUE_FORMAT, i.type, '0'),
													getAppNumberLocaleCode())}%`
												}
												legends={
													isBreakpointSmallerOrEqual(breakpointName, 'bp-xs') ?
														[
															{
																toggleSerie: true,
																anchor: 'bottom-left',
																direction: 'column',
																justify: false,
																itemsSpacing: 0,
																itemDirection: 'left-to-right',
																itemWidth: 480,
																itemHeight: 20,
																itemOpacity: 0.75,
																itemTextColor: 'var(--text-color-main)',
																symbolSize: 10,
																symbolShape: 'circle',
																symbolBorderColor: 'rgba(0, 0, 0, .5)',
																effects: [
																	{
																		on: 'hover',
																		style: {
																			itemBackground: 'rgba(0, 0, 0, .05)',
																			itemOpacity: 1
																		}
																	}
																]
															}
														] 
													: 
														[
															{
																toggleSerie: true,
																anchor: 'right',
																direction: 'column',
																justify: false,
																translateX: 240,
																itemsSpacing: 0,
																itemDirection: 'left-to-right',
																itemWidth: 220,
																itemHeight: 20,
																itemOpacity: 0.75,
																itemTextColor: 'var(--text-color-main)',
																symbolSize: 10,
																symbolShape: 'circle',
																symbolBorderColor: 'rgba(0, 0, 0, .5)',
																effects: [
																	{
																		on: 'hover',
																		style: {
																			itemBackground: 'rgba(0, 0, 0, .05)',
																			itemOpacity: 1
																		}
																	}
																]
															}
														]
												}
												theme={{
													legends: { 
														hidden: { 
															text: {fill: 'var(--text-color-faded)'},
															symbol: {fill: 'var(--text-color-faded)'},
														} 
													},
												}}
											/>
										: getString(chartTypeMap, i.type) === STATISTICS_CHART_TYPE.LINE ?
											<ResponsiveLine
												colors={{scheme: colorScheme}}
												data={i.chart}
												sliceTooltip={({slice}) => (
													<div className="point-tooltip">
														<DateLabel
															element="div"
															elementProps={{className: 'point-tooltip-title'}}
															inputDate={get(getArray(slice, 'points'), '[0].data.x')}
															outputFormat={
																this.hasTranslation(
																	'yAxisTooltipFormat', p => `${p}.statisticsType.${i.type}`
																) ?
																	this.tt('yAxisTooltipFormat', `statisticsType.${i.type}`) :
																	undefined
															}
														/>
														{getArray(slice, 'points').map(point =>
															<div key={point.id}>
																<span className="point-tooltip-label">
																	<Icon symbol="circle" style={{color: getString(point, 'color')}}/>
																	<Label content={getString(point, 'serieId')}/>:
																</span>
																<span className="point-tooltip-value">
																	<NumberLabel 
																		number={getNumber(point, 'data.y')}
																		format={getString(STATISTICS_TYPE_VALUE_FORMAT, i.type, '0')}
																	/>
																</span>
															</div>
														)}
													</div>
												)}
												margin={{top: 40, right: isMobileBreakpoint ? 40 : 240, bottom: 40, left: 70}}
												xScale={{
													type: 'point',
													min: getDate(
														getString(appliedFilter, 'dateFrom'), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S
													),
													max: getDate(
														getString(appliedFilter, 'dateTo'), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S
													),
												}}
												axisBottom={{
													format: v => formatDate(
														v,
														this.hasTranslation('yAxisFormat', p => `${p}.statisticsType.${i.type}`) ?
															this.tt('yAxisFormat', `statisticsType.${i.type}`) :
															LOCALE_DATE_FORMAT_NAME.SHORT,
													)
												}}
												yScale={{
													type: 'linear',
													min: 0,
													max: 'auto',
													format: e => formatNumber(
														e,
														getString(STATISTICS_TYPE_VALUE_FORMAT, i.type, '0'),
														getAppNumberLocaleCode(),
													),
												}}
												axisLeft={{
													format: e => (
														Number.isInteger(e) ? 
															formatNumber(
																e, 
																getString(STATISTICS_TYPE_VALUE_FORMAT, i.type, '0'),
																getAppNumberLocaleCode(),
															) : 
															''
													),
												}}
												pointSize={10}
												pointLabelYOffset={-12}
												useMesh={true}
												enableTouchCrosshair={true}
												enableSlices="x"
												legends={isMobileBreakpoint ? [] : [
													{
														toggleSerie: true,
														anchor: 'bottom-right',
														direction: 'column',
														justify: false,
														translateX: 240,
														translateY: 10,
														itemsSpacing: 0,
														itemDirection: 'left-to-right',
														itemWidth: 220,
														itemHeight: 20,
														itemOpacity: 0.75,
														itemTextColor: 'var(--text-color-main)',
														symbolSize: 10,
														symbolShape: 'circle',
														symbolBorderColor: 'rgba(0, 0, 0, .5)',
														effects: [
															{
																on: 'hover',
																style: {
																itemBackground: 'rgba(0, 0, 0, .05)',
																itemOpacity: 1
															}
														}
													],
												}]}
												theme={{
													legends: {
														hidden: {
															text: {fill: 'var(--text-color-faded)'},
															symbol: {fill: 'var(--text-color-faded)'},
														}
													},
													grid: {
														line: {stroke: 'var(--transparent-contrast-subtle)'}
													},
													axis: {
														ticks: {
															text: {fill: 'var(--text-color-main)'}
														}
													}
												}}
											/>
											: null
										: getString(chartTypeMap, i.type) === STATISTICS_CHART_TYPE.PIE ?
											<div className="noStatistics">
												<Label content={this.t('noStatisticsForCriteria')} />
											</div>
										: null
									}
								</div>
							</div>
						)}
					</div>
					: this.getValue('filterLoading') ?
						<div className="page-notice-wrapper no-select">
							<div className="page-notice-title-icon icon">
								<Spinner size={60} weight={3} />
							</div>
						</div>
					:
					<div className="page-notice-wrapper no-select">
						<Icon symbol="hand-drawn-up" symbolPrefix="icofont-" className="page-notice-title-icon" />
						<Label
							element="p"
							elementProps={{className: 'page-notice-title bold'}}
							content={this.t('search_notice_title')}
						/>
						<Label
							element="p"
							elementProps={{className: 'page-notice'}}
							content={this.t('search_notice_description')}
						/>
					</div>
				}
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
Statistics.propTypes = {
	className: PropTypes.string,
	scopes: PropTypes.arrayOf(PropTypes.string),
	scopesTranslationPath: PropTypes.string,
	scope: PropTypes.string,
	typeScopeMap: PropTypes.object,
	chartTypeMap: PropTypes.object,
	data: PropTypes.arrayOf(PropTypes.object),
	appliedFilter: PropTypes.object,
	initialDateFrom: PropTypes.object,
	initialDateTo: PropTypes.object,
	totalValueLabel: PropTypes.string,
	colorScheme: PropTypes.string,
	
	// Function used to fetch statistics and load them into Redux store
	loadStatisticsFunction: PropTypes.func.isRequired,
	// Function used to save the filter into Redux store
	setFilterFunction: PropTypes.func.isRequired,
	// Function used to save current statistics scope into Redux store
	setScopeFunction: PropTypes.func.isRequired,

	dataMap: PropTypes.object,

	onScopeChange: PropTypes.func,
};

/**
 * Define component default values for own props
 */
Statistics.defaultProps = {
	className: '',
	scopesTranslationPath: '',
	scope: '',
	data: null,
	initialDateFrom: null,
	initialDateTo: null,
	totalValueLabel: 'RESERVED_TOTAL_NUMBER',
	colorScheme: 'paired',
};

export default connect(mapStateToProps, getPageActions(actions))(Statistics);