/**
 * I18n resource
 * This resource will load translation and start a translation engine.
 * 
 * NOTE: Resources must default export a function that return a Promise resolved with an object where key is the 
 * RESOURCE_KEY and value is loaded resource data. Resources can be used to get any resource but are most useful for
 * loading global app resources needed by the app to work properly.
 */

import {find} from "lodash";
import I18n from 'Core/i18n';
import {getStorageValue, setStorageValue} from "Core/storage";
import Country from "Core/country";
import {locale_cookie_group, locale_storage_path, locale_storage_type} from "Config/app";
import CookieConsent from "Core/dataProtection/cookieConsent";
import {CookieData} from "Core/dataProtection/objects/cookie";

export const RESOURCE_KEY = 'i18n';

/**
 * Resource function
 * @return {Promise<Object<string, any>>} Promise that will resolve with an object where key is the RESOURCE_KEY and
 * value is loaded resource data.
 */
function resourceFunction() {
	// Get locale cookie settings
	const localeCookie = new CookieData(locale_cookie_group, locale_storage_path, locale_storage_type);
	
	// Try to get saved locale
	const savedLocale = (
		CookieConsent.isAllowed(localeCookie) ?
			find(Country.getAppLocaleList(), {locale: getStorageValue(locale_storage_path, locale_storage_type)}):
			undefined
	);
	const locale = (savedLocale ? savedLocale : Country.getAppDefaultLocale());
	
	const i18n = new I18n();
	i18n.setLocale(locale);
	return i18n.loadTranslation(locale)
		.then(translation => {
			i18n.startEngine();
			// Save current locale code
			if (CookieConsent.isAllowed(localeCookie)) {
				setStorageValue(locale_storage_path, locale.locale, locale_storage_type);
			}
			return {[RESOURCE_KEY]: translation};
		});
}

export default resourceFunction;