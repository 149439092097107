export class DiagnosisSearchOptionDataObjects {
	/**
	 * @param {string} id
	 * @param {string} code
	 * @param {string} description
	 */
	constructor(id, code, description) {
		this.id = id;
		this.code = code;
		this.description = description;
	}
}