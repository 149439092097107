import {actionCreators} from "Core/store/reducers";
import {reducerStoreKey} from "./reducer";
import {ioJsonDeleteItemAction, ioJsonFetchAction} from "Core/store/actions/io";
import {isSuccessful} from "Core/helpers/io";
import {get, omit} from "lodash";
import * as itemFilterDataMap from "./dataMap/filter";
import * as listItemDataMap from "./dataMap/listItem";
import {getArray} from "Core/helpers/data";
import {hideLoading, hideLoadingFunction, showLoading} from "Core/helpers/loading";

/**
 * Reset patient record expert reports list Redux state to initial state
 * @return {(function(*))|*}
 */
export const resetPatientRecordExpertReportsListAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].reset());
};

/**
 * Fetch patient record expert reports list
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} patientId - ID of the patient.
 * @param {?string} [therapyId=null] - ID of the therapy.
 * @param {Object} [filter] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const fetchPatientRecordExpertReportsListAction = (
	abortCallback, patientId, therapyId = null, filter = null, pageNo = 1, perPage, sortBy, sortDir
) => dispatch => {
	return ioJsonFetchAction(
		abortCallback,
		'defaultAuthorizedApi',
		'member/expert-report/search',
		'',
		{...filter, customerId: patientId, therapyId},
		null,
		pageNo,
		perPage,
		sortBy ? sortBy : null,
		sortDir
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => {
			if (isSuccessful(responseData)) {
				return ({
					...responseData,
					filter: omit(itemFilterDataMap.input(get(responseData, 'filter')), ['customerId', 'therapyId']),
					data: getArray(responseData, 'data').map(i => listItemDataMap.input(i))
				});
			}
			return undefined;
		});
};

/**
 * Load patient record expert reports list into Redux store
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} patientId - ID of the patient.
 * @param {?string} [therapyId=null] - ID of the therapy.
 * @param {Object} [filter] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @param {boolean} [loadingOverlay=true] - Flag that specifies if loading overlay will be rendered.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const loadPatientRecordExpertReportsListAction = (
	abortCallback, patientId, therapyId = null, filter = null, pageNo = 1, perPage, sortBy, sortDir,
	loadingOverlay = true
) => dispatch => {
	const loading = (loadingOverlay === true ? showLoading("#patient-record-expert-reports-table") : null);
	return fetchPatientRecordExpertReportsListAction(
		abortCallback, patientId, therapyId, filter, pageNo, perPage, sortBy, sortDir
	)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators[reducerStoreKey].setPatientRecordExpertReportsListData(responseData));
			if (loading !== null) hideLoading(loading);
			return responseData;
		});
};

/**
 * Set patient record expert reports list filter in Redux store
 *
 * @param {Object} filter - Filter to set.
 * @return {(function(*): void)|*}
 */
export const setPatientRecordExpertReportsFilterAction = filter => dispatch => {
	dispatch(actionCreators[reducerStoreKey].setPatientRecordExpertReportsListFilter(filter));
};

/**
 * Clear patient record expert reports list form Redux store
 *
 * @return {(function(*): void)|*}
 */
export const clearPatientRecordExpertReportsListAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].clearPatientRecordExpertReportsListData());
}

/**
 * Delete expert reports item action
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string|number} id - ID of the expert report item that will be deleted.
 * @return {function(*): Promise<IoJsonFetchResponseObject>}
 */
export const deleteExpertReportsItemAction = (abortCallback, id) => dispatch => {
	const loading = showLoading('#delete-expert-report-dialog .dialog-content-component .buttons');
	return ioJsonDeleteItemAction(
		// @note abortCallback is set to undefined because delete actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'member/expert-report/delete-by-ids',
		id,
		{},
		hideLoadingFunction(loading)
	)(dispatch);
}
