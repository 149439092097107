import {cloneDeep} from "lodash";
import {getString, resetObjectValues} from "Core/helpers/data";
import {MedicalRecordCategoriesItemDataObject} from "../dataObjects";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {MedicalRecordCategoriesItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new MedicalRecordCategoriesItemDataObject(
		getString(result, 'uniqueID'),
		getString(result, 'categoryName'),
		getString(result, 'backgroundColor'),
		getString(result, 'foregroundColor'),
	);

	return result;
}

/**
 * Get output data from input data
 * @param {MedicalRecordCategoriesItemDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep({
		uniqueID: inputData.id,
		categoryName: inputData.categoryName,
		backgroundColor: inputData.backgroundCalendarColor,
		foregroundColor: inputData.fontCalendarColor,
	});

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}