/**
 * Cookie consent resource
 *
 * NOTE: Resources must default export a function that return a Promise resolved with an object where key is the
 * RESOURCE_KEY and value is loaded resource data. Resources can be used to get any resource but are most useful for
 * loading global app resources needed by the app to work properly.
 */
import CookieConsent from "Core/dataProtection/cookieConsent";
import {cookie_consent_enabled} from "Config/app";

export const RESOURCE_KEY = 'cookieConsent';

/**
 * Resource function
 * @return {Promise<Object<string, any>>} Promise that will resolve with an object where key is the RESOURCE_KEY and
 * value is loaded resource data.
 */
function resourceFunction() {
	if (cookie_consent_enabled) {
		const cookieConsent = new CookieConsent();
		return Promise.resolve({[RESOURCE_KEY]: cookieConsent});
	} else {
		return Promise.resolve({[RESOURCE_KEY]: null});
	}
}

export default resourceFunction;