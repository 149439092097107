import React from "react";
import BaseComponent from "../../BaseComponent";
import PropTypes from "prop-types";

class FormWrapper extends BaseComponent {
	constructor(props) {
		super(props, {
			domPrefix: 'form'
		});
	}

	render() {
		const {className, children} = this.props;
		
		return (
			<div id={this.getDomId()} className={`form-wrapper ${className}`}>
				{children}
			</div>
		)
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
FormWrapper.propTypes = {
	// Form component id attribute
	id: PropTypes.string,
	// Form component class attribute
	className: PropTypes.string,
};

/**
 * Define component default values for own props
 */
FormWrapper.defaultProps = {
	id: '',
	className: '',
};

export default FormWrapper;
export {default as FormField} from "./FormField";