import "./index.css";

import React from 'react';
import DialogDataComponent, {executeComponentCallback} from 'Core/components/DialogDataComponent';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getPageActions} from 'Core/helpers/redux';
import * as actions from './actions';
import {PatientTherapyDataObject} from "DataObjects/patientTherapy";
import FormWrapper, {FormField} from 'Core/components/advanced/FormWrapper';
import DateInput from 'Core/components/input/DateInput';
import SelectInput from 'Core/components/input/SelectInput';
import DataValueValidation from 'Core/validation';
import NumberInput from "Core/components/input/NumberInput";
import PhysicianQuickSearch from "Components/input/PhysicianQuickSearch";
import TextareaInput from "Core/components/input/TextareaInput";
import {NUMBER_INPUT_USE_APP_LOCALE} from "Core/components/input/NumberInput/const";
import DiagnosisQuickSearch from "Components/input/DiagnosisQuickSearch";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from 'Core/components/display/Button';
import {icon_font_create_symbol, icon_font_delete_symbol} from "Config/app";
import PhysicianDialog from "Components/dialogs/PhysicianDialog";
import {getArray, getBoolean, getString, removeArrayItem, trimArray, updateArrayItemProps} from 'Core/helpers/data';
import {ASYNC_SELECT_INPUT_TOOLBAR_POSITION} from 'Core/components/input/SelectAsyncInput/const';
import ToggleInput from "Core/components/input/ToggleInput";
import TextInput from "Core/components/input/TextInput";
import Separator from "Core/components/display/Separator";
import {PAGINATION_TYPE} from "Core/components/action/Pagination";
import DataTable, {DATA_TABLE_CELL_TYPE_ACTION, DATA_TABLE_CELL_TYPE_ANY} from "Core/components/advanced/DataTable";
import {
	DataTableCellActionTypeOptionsDataObject,
	DataTableCellAnyTypeOptionsDataObject
} from "Core/components/advanced/DataTable/DataTableCell/dataObjects";
import PrescribedTherapyDialog from "Components/dialogs/PrescribedTherapyDialog";
import {cloneDeep} from "lodash";
import ConfirmDialog from "Core/components/dialogs/ConfirmDialog";

class TherapyDialog extends DialogDataComponent {
	constructor(props) {
		super(props, {
			data: props.isNew ? new PatientTherapyDataObject(null, props.patientId) : null,
			
			medicalRecordCategoryList: [],
			medicalRecordCategoryListLoading: false,
		}, {
			translationPath: 'TherapyDialog',
			disableLoad: props.isNew,
			optimizedUpdate: true,
			optimizedUpdateIgnoreProps: ['onClose', 'onSave'],
			
			// Custom component options
			prescriberTherapiesTranslationPath: 'AppsSection.DefaultApp.SearchTherapiesPage.ItemPopup.MainTab',
		});
		
		// Dialog methods
		this.openCreatePhysicianDialog = this.openCreatePhysicianDialog.bind(this);
		this.openCreatePrescribedTherapyDialog = this.openCreatePrescribedTherapyDialog.bind(this);
	}

	/** @inheritDoc */
	async asyncComponentDidMount(override = false) {
		// Call the parent component's 'asyncComponentDidMount' method that handles core functionality
		await super.asyncComponentDidMount(override);
		
		const {fetchMedicalRecordCategoryCodebookAction} = this.props;

		// Set focus to the first input element
		document.querySelector('.medical-record-category-id-label').click();
		
		// Load codebooks
		await this.setState({medicalRecordCategoryListLoading: true})
			.then(() => this.executeAbortableActionMount(fetchMedicalRecordCategoryCodebookAction))
			.then(medicalRecordCategoryList => this.setState({
				medicalRecordCategoryList,
				medicalRecordCategoryListLoading: false
			}));
	}

	/** @inheritDoc */
	validate() {
		const dataValidation = new DataValueValidation();
		const dataToValidate = this.getData();

		dataValidation.addRule('therapyDate', 'required');
		dataValidation.addRule('medicalRecordCategoryId', 'required');
		dataValidation.addRule('medicalRecordNumber', 'integer');

		// Run validation
		const validationErrors = dataValidation.run(dataToValidate);

		if (validationErrors) this.setValidationErrors('', validationErrors).then();
		else this.clearValidationErrors().then();
		return !validationErrors;
	}

	/**
	 * Save dialog method
	 * @note This method should be called when dialog's "save" button is clicked. This method does not actually save any
	 * data to the DB or anywhere else. That should be done by the parent component.
	 */
	save() {
		const {isNew, dialogId, dialogGUIID, createTherapyAction, updateTherapyAction} = this.props;
		const data = this.getDataToReturn();

		// Do the validation
		const isValid = this.validate();

		// If validation is successful
		if (isValid) {
			const action = (isNew ? createTherapyAction : updateTherapyAction);
			this.executeAbortableAction(action, data, dialogId)
				.then(therapyId => {
					if (!!therapyId) {
						this.close();
						// Trigger component's onSave event if therapy was saved successfully
						executeComponentCallback(this.props.onSave, therapyId, dialogGUIID);
					}
				});
		}
	}

	/**
	 * Open a dialog to create a new physician
	 */
	openCreatePhysicianDialog() {
		const {openDialogAction} = this.props;
		
		const dialogGUIID = openDialogAction('', PhysicianDialog, {
			isNew: true,
			onSave: physician => this.setValue('physician', physician),
		}, {
			id: 'create-physician-dialog',
			className: 'bordered-title',
			closeOnEscape: true,
			closeOnClickOutside: false,
			hideCloseBtn: true,
			maxWidth: 600
		});
		this.setOption(
			'dialogsToCloseOnUnmount',
			trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
		);
	}

	/**
	 * Open a dialog to prescribe a new therapy
	 */
	openCreatePrescribedTherapyDialog() {
		const {openDialogAction, closeDialogAction} = this.props;

		const dialogGUIID = openDialogAction('', PrescribedTherapyDialog, {
			isNew: true,
			onSave: prescribedTherapy => {
				let prescribedTherapies = cloneDeep(this.getValue('prescribedTherapies'));
				
				if (Array.isArray(prescribedTherapies)) prescribedTherapies.push(prescribedTherapy);
				else prescribedTherapies = [prescribedTherapy];
				
				this.setValue('prescribedTherapies', prescribedTherapies)
					.then(() => closeDialogAction(dialogGUIID));
			},
		}, {
			id: 'create-prescribed-therapies-dialog',
			className: 'bordered-title',
			closeOnEscape: true,
			closeOnClickOutside: false,
			hideCloseBtn: true,
			maxWidth: 800
		});
		this.setOption(
			'dialogsToCloseOnUnmount',
			trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
		);
	}
	
	render() {
		const {isNew, title} = this.props;
		const {medicalRecordCategoryList, medicalRecordCategoryListLoading} = this.state;
		
		return this.renderDialog(
			this.renderTitle(isNew ? this.t('title_create') : (title ? title : this.t('title'))),
			(
				<FormWrapper className="dialog-form">
					<FormField
						required={true}
						label={this.t('therapyDateLabel')} 
						errorMessages={this.getValidationErrors('therapyDate')}
					>
						<DateInput
							preventTab={true}
							value={this.getValue('therapyDate')}
							onChange={v => this.handleValueChange('therapyDate', v)}
						/>
					</FormField>
					
					<FormField
						required={true}
						label={this.t('medicalRecordCategoryIdLabel')}
						labelClassName="medical-record-category-id-label"
						errorMessages={this.getValidationErrors('medicalRecordCategoryId')}
					>
						<SelectInput
							primaryKey="id"
							options={medicalRecordCategoryList}
							isLoading={medicalRecordCategoryListLoading}
							value={this.getValue('medicalRecordCategoryId')}
							getOptionLabel={o => o.name}
							getOptionValue={o => o.id}
							onChange={o => this.handleValueChange('medicalRecordCategoryId', o)}
						/>
					</FormField>
					
					<FormField
						label={this.t('medicalRecordNumberLabel')} 
						errorMessages={this.getValidationErrors('medicalRecordNumber')}
						infoMessages={[this.t('medicalRecordNumberInfo')]}
					>
						<NumberInput
							intOnly={true}
							value={this.getValue('medicalRecordNumber')}
							useAppLocaleCode={NUMBER_INPUT_USE_APP_LOCALE.NONE}
							onChange={v => this.handleValueChange('medicalRecordNumber', v)}
						/>
					</FormField>

					<FormField
						label={this.t('prescribedTherapiesCountLabel')}
						errorMessages={this.getValidationErrors('prescribedTherapiesCount')}
					>
						<TextInput
							name="prescribedTherapiesCount"
							value={this.getValue('prescribedTherapiesCount')}
							onChange={this.handleInputChange}
						/>
					</FormField>
					
					<FormField 
						label={this.t('symptomsLabel')}
						className="multiline-form-field"
						errorMessages={this.getValidationErrors('symptoms')}
					>
						<TextareaInput
							rows={3}
							name="symptoms" 
							value={this.getValue('symptoms')} 
							onChange={this.handleInputChange} 
						/>
					</FormField>
					
					<FormField label={this.t('diagnosisIdLabel')} errorMessages={this.getValidationErrors('diagnosisId')}>
						<DiagnosisQuickSearch
							minLength={2}
							value={this.getValue('diagnosisData')}
							onChange={v => this.handleValueChange('diagnosisData', v)
								.then(() => this.handleValueChange('diagnosis', getString(v, 'description')))
							}
						/>
					</FormField>
					<FormField 
						className="multiline-form-field"
						label={this.t('diagnosisLabel')} 
						errorMessages={this.getValidationErrors('diagnosis')}
					>
						<TextareaInput
							rows={3}
							name="diagnosis"
							value={this.getValue('diagnosis')}
							onChange={this.handleInputChange}
						/>
					</FormField>
					
					<FormField label={this.t('physicianLabel')} errorMessages={this.getValidationErrors('physician')}>
						{!this.getValue('customPhysician') ?
							<PhysicianQuickSearch
								isClearable={true}
								className="separate-toolbar-right"
								value={this.getValue('physician')}
								onChange={v => this.handleValueChange('physician', v)}
								toolbarButtons={[
									{
										position: ASYNC_SELECT_INPUT_TOOLBAR_POSITION.RIGHT,
										icon: icon_font_create_symbol,
										label: this.t('create_new', 'AppsSection.DefaultApp.PhysiciansPage'),
										displayType: BUTTON_DISPLAY_TYPE.TRANSPARENT,
										displayStyle: BUTTON_STYLE.ACTION,
										onClick: () => this.openCreatePhysicianDialog(),
									}
								]}
							/>
							:
							<TextInput
								name="customPhysicianName"
								value={this.getValue('customPhysicianName')}
								onChange={this.handleInputChange}
							/>
						}
					</FormField>
					
					<FormField label={this.t('customPhysicianLabel')}>
						<ToggleInput
							name="customPhysician"
							checked={this.getValue('customPhysician')}
							onChange={this.handleInputChange}
						/>
					</FormField>
					
					<div className="prescribed-therapies-section">
						<Separator
							className="prescribed-therapies-section-title"
							content={this.t('tabTitle', this.getOption('prescriberTherapiesTranslationPath'))}
							noBorder={true}
							noMargin={true}
						/>
						<div className="toolbar standard for-table">
							<Button 
								displayType={BUTTON_DISPLAY_TYPE.NONE}
								displayStyle={BUTTON_STYLE.ACTION}
								icon={icon_font_create_symbol} 
								label={this.t('create', this.getOption('prescriberTherapiesTranslationPath'))}
								onClick={() => this.openCreatePrescribedTherapyDialog()}
							/>
							<div className="separator" />
						</div>
						<DataTable
							className="prescribed-therapies-table standard"
							responsive={true}
							responsiveBreakpointName="bp-m"
							highlightOnHover={true}
							columns={[
								{
									name: 'prescribedTherapyType',
									label: this.t(
										'prescribedTherapyTypeField', this.getOption('prescriberTherapiesTranslationPath')
									),
									emptyAsDefault: true,
									defaultValue: '—',
									widthLessThanLabel: true,
								},
								{
									name: 'prescribedTherapySubtype',
									label: this.t(
										'prescribedTherapySubtypeField', this.getOption('prescriberTherapiesTranslationPath')
									),
									emptyAsDefault: true,
									defaultValue: '—',
									widthLessThanLabel: true,
								},
								{
									name: 'prescribedTherapySubSubtype',
									label: this.t(
										'prescribedTherapySubSubtypeField', this.getOption('prescriberTherapiesTranslationPath')
									),
									emptyAsDefault: true,
									defaultValue: '—',
									widthLessThanLabel: true,
								},
								{
									name: 'duration',
									label: this.t('durationField', this.getOption('prescriberTherapiesTranslationPath')),
									emptyAsDefault: true,
									defaultValue: '—',
									width: 1,
								},
								{
									name: 'additionalInfo',
									label: this.t('additionalInfoField', this.getOption('prescriberTherapiesTranslationPath')),
									emptyAsDefault: true,
									defaultValue: '—',
									widthLessThanLabel: true,
								},
								{
									label: this.t('activeField', this.getOption('prescriberTherapiesTranslationPath')),
									dataType: DATA_TABLE_CELL_TYPE_ANY,
									dataTypeOptions: new DataTableCellAnyTypeOptionsDataObject({
										content: row => (
											<ToggleInput
												checked={getBoolean(row, 'active')}
												onChange={e => {
													const checked = getBoolean(e, 'target.checked');
													const dataList = getArray(this.getValue('prescribedTherapies'));
													
													if (dataList.length > 0) {
														this.setValue('prescribedTherapies', updateArrayItemProps(
															dataList, row, {active: checked}
														)).then();
													}
												}}
											/>
										),
									}),
									width: 1
								},
								{
									dataType: DATA_TABLE_CELL_TYPE_ACTION,
									dataTypeOptions: new DataTableCellActionTypeOptionsDataObject({
										mobileFlex: 1,
										key: 'delete',
										icon: icon_font_delete_symbol,
										tooltip: this.t('deletePrescribedTherapiesTooltip'),
										onClick: row => {
											const {openDialogAction, closeDialogAction} = this.props;
											const dataList = getArray(this.getValue('prescribedTherapies'));

											const dialogGUIID = openDialogAction('', ConfirmDialog, {
												message: this.t('deletePrescribedTherapyDeleteConfirmMessage'),
												onYes: () => {
													this.setValue('prescribedTherapies', removeArrayItem(dataList, row))
														.then(() => closeDialogAction(dialogGUIID));
												},
											}, {
												id: 'delete-prescribed-therapy-dialog',
												closeOnEscape: true,
												closeOnClickOutside: true,
												hideCloseBtn: true,
												maxWidth: 560
											});
											this.setOption(
												'dialogsToCloseOnUnmount',
												trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
											);
										},
									}),
								},
							]}
							data={getArray(this.getValue('prescribedTherapies'))}
							paginationType={PAGINATION_TYPE.NONE}
						/>
					</div>
				</FormWrapper>
			)
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
TherapyDialog.propTypes = {
	// Unique GUI ID of the dialog
	// @note This is automatically sent by the global Dialog component.
	dialogGUIID: PropTypes.string,
	// Dialog options
	// @note This is automatically sent by the global Dialog component.
	dialogOptions: PropTypes.object,
	// Action used to close the dialog
	// @note This is automatically sent by the global Dialog component.
	dialogCloseAction: PropTypes.func,

	// Flag that specifies if dialog is opened for a new (create dialog) or an existing item (edit dialog). If true
	// dialog is opened for a new item. 
	isNew: PropTypes.bool,
	// Dialog title
	title: PropTypes.string,
	// Therapy data
	// @type {PatientTherapyDataObject}
	data: PropTypes.object,
	// ID of the dialog
	dialogId: PropTypes.string,
	// ID of the patient
	patientId: PropTypes.string.isRequired,

	// Events
	onClose: PropTypes.func,
	// @param {string} therapyId - ID of the newly created or updated therapy.
	// @param {string} dialogGUIID
	onSave: PropTypes.func,
};

export default connect(null, getPageActions(actions))(TherapyDialog);