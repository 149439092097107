import styles from "./index.module.css";

import React from "react";
import DataComponent from 'Core/components/DataComponent';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {selectors} from './reducer';
import {selectors as patientRecordSelectors} from '../../reducer';
import {getPageActions} from 'Core/helpers/redux';
import * as actions from './actions';
import {PAGINATION_TYPE} from 'Core/components/action/Pagination';
import DataTable, {DATA_TABLE_CELL_TYPE} from 'Core/components/advanced/DataTable';
import {areAllObjectPropsEmpty, getArray, getNumber, getString, isset, trimArray} from 'Core/helpers/data';
import {get} from 'lodash';
import * as filterDataMap from './dataMap/filter';
import {scrollToSelector} from 'Core/helpers/dom';
import SimpleStaticSearch, {
	SIMPLE_STATIC_SEARCH_DISPLAY_TYPE,
	SIMPLE_STATIC_SEARCH_LAYOUT,
	SimpleStaticSearchOptionObject
} from 'Core/components/advanced/SimpleStaticSearch';
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from 'Core/components/display/Button';
import {STANDARD_DATE_TIME_FORMAT} from 'Core/const/datetime';
import {PATIENT_RECORD_DISPLAY_TYPE, PATIENT_RECORD_DISPLAY_TYPES} from "Components/advanced/PatientRecord/const";
import {loadPatientRecordAction} from "Components/advanced/PatientRecord/actions";
import {loadPatientTherapyAction} from "Components/advanced/PatientRecord/components/Therapy/actions";
import ConfirmDialog from "Core/components/dialogs/ConfirmDialog";
import {isSuccessful} from "Core/helpers/io";
import {Tooltip} from "react-tippy";
import {icon_font_create_symbol, icon_font_delete_symbol, icon_font_edit_symbol} from "Config/app";
import {
	DataTableCellAnyTypeOptionsDataObject,
	DataTableCellDateTypeOptionsDataObject
} from "Core/components/advanced/DataTable/DataTableCell/dataObjects";
import {getAppLocale, getAppLocaleDateFormat} from "Core/helpers/locale";
import {LOCALE_DATE_FORMAT_NAME as LOCALE_DATE_FORMAT} from "Core/const/locale";
import ExpertReportDialog from "Components/dialogs/ExpertReportDialog";
import DownloadButton from "Core/components/action/DownloadButton";
import {getIOUrl} from "Core/io/helper";
import ExpertReportSendEmailDialog from "Components/dialogs/ExpertReportSendEmailDialog";
import slugify from "slugify";
import {getDateString} from "Core/helpers/datetime";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	patientId: get(patientRecordSelectors.getPatientRecord(state), 'id', null),
	patientName: (
		getString(patientRecordSelectors.getPatientRecord(state), 'firstName') + 
		(
			!!getString(patientRecordSelectors.getPatientRecord(state), 'lastName') ?
				` ${getString(patientRecordSelectors.getPatientRecord(state), 'lastName')}` :
				''
		)
	),
	therapyId: get(patientRecordSelectors.getPatientTherapy(state), 'id', null),

	mainList: selectors.getPatientRecordExpertReportsList(state),
	mainListPagination: selectors.getPatientRecordExpertReportsListPagination(state),
	mainListSort: selectors.getPatientRecordExpertReportsListSort(state),
	mainListFilter: selectors.getPatientRecordExpertReportsListFilter(state),
});

class ExpertReports extends DataComponent {
	constructor(props) {
		super(props, {
			data: {
				/**
				 * Flag showing if filter is loading
				 */
				filterLoading: false,
			},
		}, {
			translationPath: 'ExpertReports',
			disableLoad: true,
		});

		// Refs
		this.mainListFilterRef = null;

		// Data methods
		this.calculateTherapyId = this.calculateTherapyId.bind(this);
		this.reloadMainList = this.reloadMainList.bind(this);
		this.loadMainListPage = this.loadMainListPage.bind(this);
		this.sortMainList = this.sortMainList.bind(this);
		this.filterMainList = this.filterMainList.bind(this);
		this.removeMainListFilter = this.removeMainListFilter.bind(this);
		this.isMainListFilterEmpty = this.isMainListFilterEmpty.bind(this);

		// Dialog methods
		this.openItemDialog = this.openItemDialog.bind(this);
		this.openSendMailDialog = this.openSendMailDialog.bind(this);

		// Action methods
		this.deleteMainListItem = this.deleteMainListItem.bind(this);

		// Render methods
		this.renderActions = this.renderActions.bind(this);
	}

	async asyncComponentDidMount(override = false) {
		await super.asyncComponentDidMount(override);

		const {active} = this.props;

		// Initial data load
		if (active) await this.filterMainList(null);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const {resetPatientRecordExpertReportsListAction} = this.props;

		return super.componentDidUpdate(prevProps, prevState, snapshot)
			// Reload data when activated, selected therapy or patient record display type changes 
			.then(() => {
				const {displayType} = this.props;
				const therapyId = this.calculateTherapyId();
				const prevTherapyId = this.calculateTherapyId(prevProps.therapyId);

				if (
					this.props.active && (
						this.props.active !== prevProps.active ||
						displayType !== prevProps.displayType ||
						therapyId !== prevTherapyId
					)
				) {
					// Clear the main list from Redux store if selected therapy is cleared
					if (
						displayType === PATIENT_RECORD_DISPLAY_TYPE.SELECTED_THERAPY &&
						therapyId !== prevTherapyId && !therapyId
					) {
						resetPatientRecordExpertReportsListAction();
						return Promise.resolve();
					}

					return this.loadMainListPage();
				}
				return Promise.resolve();
			});
	}

	componentWillUnmount() {
		super.componentWillUnmount();

		const {resetPatientRecordExpertReportsListAction} = this.props;
		resetPatientRecordExpertReportsListAction();
	}

	
	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Calculate therapy type ID based on the display type
	 *
	 * @param {string} [therapyId] - Reference therapy type ID. If not specified, 'therapyId' from props will be used.
	 * @return {string|'*'|null}
	 */
	calculateTherapyId(therapyId) {
		const {displayType} = this.props;
		const id = (isset(therapyId) ? therapyId : this.props.therapyId);

		switch (displayType) {
			case PATIENT_RECORD_DISPLAY_TYPE.SELECTED_THERAPY: return id;
			case PATIENT_RECORD_DISPLAY_TYPE.ALL: return '*';
			case PATIENT_RECORD_DISPLAY_TYPE.NO_THERAPY: return null;
			// no default
		}
	}

	/**
	 * Reload main list using current options (page, sort, ...)
	 * @return {Promise<*>}
	 */
	reloadMainList() {
		const {
			patientId, loadPatientRecordExpertReportsListAction, mainListPagination, mainListSort, mainListFilter
		} = this.props;
		const {pageNo, perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = filterDataMap.output(mainListFilter);
		const therapyId = this.calculateTherapyId();

		return this.executeAbortableAction(
			loadPatientRecordExpertReportsListAction, patientId, therapyId, oFilter, pageNo, perPage, sortBy, sortDir
		)
			.then(res => {
				this.mainListFilterRef?.reload();
				return res;
			});
	}

	/**
	 * Reload main list using current options (page, sort, ...) if any
	 * @param {number} [pageNo=1] - Page number to load (starts with 1).
	 * @return {Promise<*>}
	 */
	loadMainListPage(pageNo = 1) {
		const {
			patientId, loadPatientRecordExpertReportsListAction, mainListPagination, mainListSort, mainListFilter
		} = this.props;
		const {perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = filterDataMap.output(mainListFilter);
		const therapyId = this.calculateTherapyId();

		return this.executeAbortableAction(
			loadPatientRecordExpertReportsListAction, patientId, therapyId, oFilter, pageNo, perPage, sortBy, sortDir
		);
	}

	/**
	 * Sort main list
	 * @param {string} sortBy - Name of the sort column.
	 * @param {string} sortDir - Direction of the sort.
	 * @return {Promise<*>}
	 */
	sortMainList(sortBy, sortDir) {
		const {patientId, loadPatientRecordExpertReportsListAction, mainListPagination, mainListFilter} = this.props;
		const {pageNo, perPage} = mainListPagination;
		const oFilter = filterDataMap.output(mainListFilter);
		const therapyId = this.calculateTherapyId();

		return this.executeAbortableAction(
			loadPatientRecordExpertReportsListAction, patientId, therapyId, oFilter, pageNo, perPage, sortBy, sortDir
		);
	}

	/**
	 * Filter main list
	 * @param {Object} filter - Filter object where keys are filter field names and values are filter values.
	 * @return {Promise<*>}
	 */
	filterMainList(filter) {
		const {
			patientId, loadPatientRecordExpertReportsListAction, setPatientRecordExpertReportsFilterAction,
			mainListPagination, mainListSort,
		} = this.props;
		const {perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = filterDataMap.output(filter);
		const therapyId = this.calculateTherapyId();

		// Set filter so that the change will be detected after IO
		setPatientRecordExpertReportsFilterAction(oFilter);

		return this.setValue('filterLoading', true)
			.then(() => this.executeAbortableAction(
				loadPatientRecordExpertReportsListAction, patientId, therapyId, filter, 1, perPage, sortBy, sortDir
			))
			.then(() => this.setValue('filterLoading', false))
			.then(() => {
				if (!areAllObjectPropsEmpty(oFilter, true, false)) {
					scrollToSelector('#main-page-table', false, 80);
				}
			});
	}

	/**
	 * Remove main list filter
	 * @return {Promise<*>}
	 */
	removeMainListFilter() {
		return this.filterMainList(null);
	}

	/**
	 * Check if main list filter is applied
	 * @return {Boolean}
	 */
	isMainListFilterEmpty() {
		return areAllObjectPropsEmpty(this.getProp('mainListFilter'), true, false);
	}


	// Dialog methods ---------------------------------------------------------------------------------------------------
	/**
	 * Open item dialog
	 * @param {?string} [id=null]
	 */
	openItemDialog(id = null) {
		const {patientId, openDialogAction} = this.props;

		const dialogGUIID = openDialogAction('', ExpertReportDialog, {
			isNew: !id,
			id,
			patientId,
			therapyId: this.calculateTherapyId(),
			onSave: () => this.reloadMainList(),
		}, {
			id: !id ? 'create-expert-report-dialog' : 'edit-expert-report-dialog',
			className: 'bordered-title',
			closeOnEscape: true,
			closeOnClickOutside: false,
			hideCloseBtn: true,
			maxWidth: 1080
		});
		this.setOption(
			'dialogsToCloseOnUnmount',
			trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
		);
	}
	
	/**
	 * Open dialog to send report emails
	 * @param {PatientRecordExpertReportsListItemDataObject} row - Data table row.
	 */
	openSendMailDialog(row) {
		const {openDialogAction} = this.props;

		const dialogGUIID = openDialogAction('', ExpertReportSendEmailDialog, {
			expertReportId: row.id,
			expertReportName: row.title,
		}, {
			id: 'send-expert-report-email-dialog',
			className: 'bordered-title',
			closeOnEscape: true,
			closeOnClickOutside: false,
			hideCloseBtn: false,
			maxWidth: 720
		});
		this.setOption(
			'dialogsToCloseOnUnmount',
			trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
		);
	}

	
	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Delete main list item
	 * @param {PatientRecordExpertReportsListItemDataObject} item - Main list popup item.
	 * @return {Promise<void>}
	 */
	deleteMainListItem(item) {
		const {
			patientId, therapyId, openDialogAction, closeDialogAction, deleteExpertReportsItemAction, 
			addSuccessMessageAction,
			loadPatientRecordAction, loadPatientTherapyAction
		} = this.props;

		return new Promise(resolve => {
			const dialogGUIID = openDialogAction('', ConfirmDialog, {
				message: this.t('confirm_delete'),
				supportHtml: true,
				onYes: () => {
					this.executeAbortableAction(deleteExpertReportsItemAction, item.id)
						.then(response => {
							if (isSuccessful(response)) {
								addSuccessMessageAction(this.t('delete_success_msg'));

								// Reload patient record to update global statistics
								this.executeAbortableAction(loadPatientRecordAction, patientId).then();
								// Reload patient therapy to update statistics
								if (!!therapyId && therapyId !== '*') {
									this.executeAbortableAction(loadPatientTherapyAction, therapyId, '.therapy-section').then();
								}

								// Reload main list data
								// @note This is done asynchronously on purpose because there is no reason to wait for this to 
								// finish before continuing.
								this.reloadMainList()
									// Go to the previous page if there are no table rows after one has been deleted
									.then(() => {
										const mainList = getArray(this.props, 'mainList');
										const pageNo = getNumber(this.props, 'mainListPagination.pageNo', 1);
										if (mainList.length === 0 && pageNo > 1) return this.loadMainListPage(pageNo - 1);
									});
							}
							return Promise.resolve(response);
						})
						.then(() => closeDialogAction(dialogGUIID))
						.finally(() => resolve('yes'));
				},
				onNo: () => {
					closeDialogAction(dialogGUIID);
					resolve('no');
				}
			}, {
				id: 'delete-expert-report-dialog',
				closeOnEscape: true,
				closeOnClickOutside: true,
				hideCloseBtn: true,
				maxWidth: 600
			});
			this.setOption(
				'dialogsToCloseOnUnmount',
				trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
			);
		});
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render data table actions cell
	 * @param {PatientRecordExpertReportsListItemDataObject} row - Data table row.
	 * @return {JSX.Element}
	 */
	renderActions(row) {
		return (
			<div className="actions standard-actions-row">
				<Tooltip
					tag="div"
					title={this.t('delete_tooltip')}
					size="small"
					position="top-center"
					arrow={true}
					interactive={false}
					touchHold={true}
					delay={250}
				>
					<Button
						className="action-btn no-border-radius"
						displayStyle={BUTTON_STYLE.ACTION}
						displayType={BUTTON_DISPLAY_TYPE.NONE}
						icon={icon_font_delete_symbol}
						onClick={() => this.deleteMainListItem(row)}
					/>
				</Tooltip>
				
				<Tooltip
					tag="div"
					title={this.t('download_pdf_tooltip')}
					size="small"
					position="top-center"
					arrow={true}
					interactive={false}
					touchHold={true}
					delay={250}
				>
					<DownloadButton
						className="action-btn no-border-radius"
						displayStyle={BUTTON_STYLE.ACTION}
						displayType={BUTTON_DISPLAY_TYPE.NONE}
						label=""
						icon="file-pdf-o"
						filename={
							slugify(
								this.t('expert_report_filename_prefix') +
								(!!getString(row, 'title') ? ` ${getString(row, 'title')}` : '') +
								(!!getString(this.props, 'patientName') ? ` ${getString(this.props, 'patientName')}` : '') +
								` ${getDateString(new Date(), 'yyyy-MM-dd_HH-mm-ss')}`,
								{
									replacement: '_',
									lower: true,
									locale: getAppLocale().code2,
								}
							)
						}
						data={{id: row.id}}
						url={getIOUrl('defaultAuthorizedApi', 'member/expert-report/get-report-pdf')}
					/>
				</Tooltip>
				<Tooltip
					tag="div"
					title={this.t('send_email_tooltip')}
					size="small"
					position="top-center"
					arrow={true}
					interactive={false}
					touchHold={true}
					delay={250}
				>
					<Button
						className="action-btn no-border-radius"
						displayStyle={BUTTON_STYLE.ACTION}
						displayType={BUTTON_DISPLAY_TYPE.NONE}
						icon="envelope-o"
						onClick={() => this.openSendMailDialog(row)}
					/>
				</Tooltip>

				<Tooltip
					tag="div"
					title={this.t('edit_tooltip')}
					size="small"
					position="top-center"
					arrow={true}
					interactive={false}
					touchHold={true}
					delay={250}
				>
					<Button
						className="action-btn no-border-radius"
						displayStyle={BUTTON_STYLE.ACTION}
						displayType={BUTTON_DISPLAY_TYPE.NONE}
						icon={icon_font_edit_symbol}
						onClick={() => this.openItemDialog(row.id)}
					/>
				</Tooltip>
			</div>
		);
	}

	render() {
		const {active, displayType, mainList, mainListPagination, mainListSort, mainListFilter} = this.props;

		return (
			<div className={`${styles['wrapper']} ${!active ? 'no-display' : ''}`}>
				<SimpleStaticSearch
					styleName="compact"
					className={`main-search rows-1 ${styles['filter']}`}
					collapsable={true}
					layout={SIMPLE_STATIC_SEARCH_LAYOUT.STACKED}
					buttonProps={{
						displayStyle: BUTTON_STYLE.DEFAULT
					}}
					options={[
						new SimpleStaticSearchOptionObject(
							'reportDateFrom',
							this.t('reportDateFromField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.DATE,
							{
								valueFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S,
							}
						),
						new SimpleStaticSearchOptionObject(
							'reportDateTo',
							this.t('reportDateToField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.DATE,
							{
								valueFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S,
							}
						),
					]}
					value={mainListFilter}
					title={(
						<>
							<Button 
								className="no-border-radius"
								icon={icon_font_create_symbol}
								label={this.t(
									displayType === PATIENT_RECORD_DISPLAY_TYPE.SELECTED_THERAPY ? 
										'new_expert_report_btn_single' : 'new_expert_report_btn_all', 
									'PatientRecord'
								)}
								displayStyle={
									displayType === PATIENT_RECORD_DISPLAY_TYPE.SELECTED_THERAPY ?
										BUTTON_STYLE.ACTION : BUTTON_STYLE.ERROR
								}
								displayType={BUTTON_DISPLAY_TYPE.NONE}
								onClick={e => {
									e.stopPropagation();
									e.nativeEvent.stopImmediatePropagation();
									this.openItemDialog();
								}}
							/>
						</>
					)}
					applied={!this.isMainListFilterEmpty()}
					enableToolbar={true}
					enableResetButton={false}
					onChange={this.filterMainList}
					onRemove={this.removeMainListFilter}
					ref={node => { this.mainListFilterRef = node; }}
				/>
				
				<DataTable
					id="patient-record-expert_reports-table"
					className="standard sticky-last-column"
					responsive={true}
					responsiveBreakpointName="bp-xl"
					highlightOnHover={true}
					columns={[
						{
							sortName: 'reportDate',
							name: 'date',
							label: this.t('dateField'),
							dataType: DATA_TABLE_CELL_TYPE.DATE,
							dataTypeOptions: new DataTableCellDateTypeOptionsDataObject({
								outputFormat: getAppLocaleDateFormat(LOCALE_DATE_FORMAT.LONG),
								whiteSpace: 'nowrap'
							}),
							width: 1,
						},
						{
							name: 'title',
							label: this.t('titleField'),
							emptyAsDefault: true,
							defaultValue: '—',
						},

						{
							dataType: DATA_TABLE_CELL_TYPE.ANY,
							dataTypeOptions: new DataTableCellAnyTypeOptionsDataObject({
								standardWrapper: false,
								content: this.renderActions,
							}),
							stopPropagation: true,
							width: 1,
						}
					]}
					data={mainList}
					paginationType={PAGINATION_TYPE.STATIC}
					onSortByColumn={this.sortMainList}
					onPaginationClick={this.loadMainListPage}
					{...mainListPagination}
					{...mainListSort}
				/>
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
ExpertReports.propTypes = {
	active: PropTypes.bool,
	displayType: PropTypes.oneOf(PATIENT_RECORD_DISPLAY_TYPES),
};

export default connect(
	mapStateToProps, getPageActions({
		...actions, loadPatientRecordAction, loadPatientTherapyAction
	}), null, {forwardRef: true}
)(ExpertReports);