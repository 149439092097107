const srLatnRS = {
	code: 'sr-Latn-RS',
	week: {
		dow: 1,
		doy: 7, // The week that contains Jan 1st is the first week of the year.
	},
	direction: 'ltr',
	buttonText: {
		prev: '',
		next: '',
		prevYear: '',
		nextYear: '',
		today: 'Sada',
		year: 'Godina',
		month: 'Mеsеc',
		week: 'Nеdеlja',
		day: 'Dan',
		list: 'Planеr',
	},
	buttonHints: {
		prev: 'Prethodni $0 (Alt + ←)',
		next: 'Sledeći $0 (Alt + →)',
		today: 'Ovaj $0 (Alt + D)',
		prevYear: 'Prethodna $0 (Alt + ←)',
		nextYear: 'Sledeća $0 (Alt + →)',
		todayYear: 'Ova $0',
	},
	weekText: 'Sed',
	weekTextLong: 'Sedmica',
	closeHint: 'Zatvori',
	timeHint: 'Vreme',
	allDayText: 'Cеo dan',
	moreLinkText(n) {
		return '+ još ' + n;
	},
	noEventsText: 'Nеma događaja za prikaz',
};

export default srLatnRS;
