/**
 * Data object representing the therapists list item
 * @note This is usually used in data table as a row. Params don't have default values since this data object is used to
 * represent data that actually exists.
 */
export class TherapistsListItemDataObject {
	/**
	 * @param {string} id - List item's ID.
	 * @param {string} firstName - Therapists first name.
	 * @param {string} lastName - Therapists last name.
	 * @param {string} telephone - Therapists landline phone number.
	 * @param {string} mobilePhone - Therapists mobile phone number.
	 * @param {string} email - Therapists email address.
	 * @param {string} address - Therapists address.
	 * @param {string} backgroundCalendarColor - Therapists event background color in the calendar.
	 * @param {string} fontCalendarColor - Therapists event text color in the calendar.
	 */
	constructor(
		id, firstName, lastName, telephone, mobilePhone, email, address, backgroundCalendarColor, fontCalendarColor
	) {
		this.id = id;
		this.firstName = firstName;
		this.lastName = lastName;
		this.telephone = telephone;
		this.mobilePhone = mobilePhone;
		this.email = email;
		this.address = address;
		this.backgroundCalendarColor = backgroundCalendarColor;
		this.fontCalendarColor = fontCalendarColor;
	}
}

/**
 * Data object representing the therapists item
 * @note This is usually used when creating or updating data table item using a popup or a dialog. Params have default 
 * values since this data object can be used for creating an item. This is why 'id' has a default value of null which 
 * represents a new item that is being created.
 */
export class TherapistsItemDataObject {
	/**
	 * @param {string|null} [id=null] - Item's ID.
	 * @param {string} [firstName=''] - Therapists first name.
	 * @param {string} [lastName=''] - Therapists last name.
	 * @param {string} [telephone=''] - Therapists landline phone number.
	 * @param {string} [mobilePhone=''] - Therapists mobile phone number.
	 * @param {string} [email=''] - Therapists email address.
	 * @param {string} [address=''] - Therapists address.
	 * @param {string} [backgroundCalendarColor=''] - Therapists event background color in the calendar.
	 * @param {string} [fontCalendarColor=''] - Therapists event text color in the calendar.
	 */
	constructor(
		id = null, firstName = '', lastName = '', telephone = '', mobilePhone = '', email = '', address = '',
		backgroundCalendarColor = '', fontCalendarColor = '',
	) {
		this.id = id;
		this.firstName = firstName;
		this.lastName = lastName;
		this.telephone = telephone;
		this.mobilePhone = mobilePhone;
		this.email = email;
		this.address = address;
		this.backgroundCalendarColor = backgroundCalendarColor;
		this.fontCalendarColor = fontCalendarColor;
	}
}