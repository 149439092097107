import {get} from "lodash";
import {getBoolean, getInteger, getObject, getString} from "Core/helpers/data";
import {SORT_ORDER} from "Core/const/global";

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'suppliers';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@suppliers/reset',
	SET_LIST_DATA: '@suppliers/set_list_data',
	SET_LIST_FILTER: '@suppliers/set_list_filter',
	CLEAR_LIST_DATA: '@suppliers/clear_list_data',
	SET_ITEM: '@suppliers/set_item',
	CLEAR_ITEM: '@suppliers/clear_item',
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),
	/**
	 * @param {IoJsonFetchResponseObject} suppliersListData
	 * @return {{type: string, listData: IoJsonFetchResponseObject}}
	 */
	
	setSuppliersListData: suppliersListData => ({
		type: REDUCER_TYPES.SET_LIST_DATA, 
		listData: suppliersListData
	}),
	setSuppliersListFilter: suppliersListFilter => ({
   		type: REDUCER_TYPES.SET_LIST_FILTER, 
   		filter: suppliersListFilter
   	}),
	clearSuppliersListData: () => ({type: REDUCER_TYPES.CLEAR_LIST_DATA}),
	
	/**
	 * @param {SuppliersItemDataObject} suppliersItem
	 * @return {{type: string, item: SuppliersItemDataObject}}
	 */
	setSuppliersItem: suppliersItem => ({
		type: REDUCER_TYPES.SET_ITEM, 
		item: suppliersItem
	}),
	clearSuppliersItem: () => ({type: REDUCER_TYPES.CLEAR_ITEM}),
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	/** @type {IoJsonFetchResponseObject} */
	listData: null,

	/**
	 * @note Value is null to differentiate between unloaded and new items (new items should be a data object with
	 * default values).
	 * @type {SuppliersItemDataObject}
	 */
	item: null,
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET: return { ...initialState };

		case REDUCER_TYPES.SET_LIST_DATA: return {...state, listData: action.listData};
		case REDUCER_TYPES.SET_LIST_FILTER: return {
			...state, 
			listData: {...getObject(state.listData), filter: action.filter}
		};
		case REDUCER_TYPES.CLEAR_LIST_DATA: return {...state, listData: initialState.listData};

		case REDUCER_TYPES.SET_ITEM: return {...state, item: action.item};
		case REDUCER_TYPES.CLEAR_ITEM: return {...state, item: initialState.item};

		default: return state;
	}
};

// Selectors
export const selectors = {
	getSuppliersList: state => get(state, [reducerStoreKey, 'listData', 'data']),
	getSuppliersListPagination: state => ({
		perPage: getInteger(state, [reducerStoreKey, 'listData', 'perPage']),
		pageNo: getInteger(state, [reducerStoreKey, 'listData', 'pageNo']),
		totalRows: getInteger(state, [reducerStoreKey, 'listData', 'totalResults']),
		isLastPage: getBoolean(state, [reducerStoreKey, 'listData', 'isLastPage'])
	}),
	getSuppliersListSort: state => ({
		sortBy: getString(state, [reducerStoreKey, 'listData', 'sortBy']),
		sortDir: getString(state, [reducerStoreKey, 'listData', 'sortDir'], SORT_ORDER.ASC)
	}),
	getSuppliersListFilter: state => get(state, [reducerStoreKey, 'listData', 'filter'], null),

	getSuppliersItem: state => get(state, [reducerStoreKey, 'item']),
};

export default reducer;