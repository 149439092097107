import React from "react";
import Color from "color";

import styles from "./index.module.css";

/**
 * Color preview
 *
 * @param {any} color - Any color.
 * @return {*} Preview function used by 'getPreview' prop of TextInput component.
 */
const preview = color => {
	try {
		const colorObject = (color ? new Color(color) : 'transparent');
		return (
			<div
				className={`color-preview ${styles['preview']}`}
				style={{backgroundColor: colorObject.hex()}}
			/>
		);
	} catch (e) {
		return (
			<div
				className={`color-preview invalid ${styles['preview']} ${styles['invalid']}`}
				style={{backgroundColor: 'transparent'}}
			/>
		);
	}
}

export default preview;