/**
 * Current user resource
 * This resource will load current user data from local storage into Redux store.
 *
 * NOTE: Resources must default export a function that return a Promise resolved with an object where key is the
 * RESOURCE_KEY and value is loaded resource data. Resources can be used to get any resource but are most useful for
 * loading global app resources needed by the app to work properly.
 */
import auth from "../../auth";
import {setStorageValue, STORAGE_TYPE} from "../storage";
import {current_user_storage_field_name} from "../../config";

export const RESOURCE_KEY = 'currentUser';

/**
 * Resource function
 * @return {Promise<Object<string, any>>} Promise that will resolve with an object where key is the RESOURCE_KEY and
 * value is loaded resource data.
 */
function resourceFunction() {
	const currentUser = auth.getCurrentUser();
	if (setStorageValue(current_user_storage_field_name, currentUser, STORAGE_TYPE.REDUX)) {
		return Promise.resolve({[RESOURCE_KEY]: currentUser});
	} else {
		return Promise.resolve({[RESOURCE_KEY]: null});
	}
}

export default resourceFunction;