/**
 * Locale helpers
 * WARNING: Be careful when importing this helper because it uses Redux store and some other imports that can cause
 * circular dependencies!
 */

import {get, find, cloneDeep} from "lodash";
import {
	locale_date_format_path,
	locale_date_locale_path,
	locale_date_plugin_name, locale_datetime_separator_path,
	locale_time_format_path
} from "Config/app";
import {LOCALE_DATE_FORMAT_NAME, LOCALE_TIME_FORMAT_NAME} from "../const/locale";
import localeList from "../../i18n/locale";
import reduxStore from '../store';
import {selectors} from '../store/reducers';


// Locale --------------------------------------------------------------------------------------------------------------
/**
 * Find locale object by locale code (IETF)
 * @param {string} localeCode - IETF locale code, for example 'sr-Latn-RS'.
 * @return {LocaleObj}
 */
export const getLocaleByCode = localeCode => find(localeList, {locale: localeCode});

/**
 * Get locale IETF language tag from locale object
 * @param {LocaleObj} locale - Locale to get the data from.
 * @return {string} For example 'sr-Latn-RS'.
 */
export const getLocaleCode = locale => get(locale, 'locale', '');


// Locale date and time ------------------------------------------------------------------------------------------------
/**
 * Get date related locale settings
 * @param {LocaleObj} locale - Locale to get the data from.
 * @param {string} [plugin] - Locale plugin name to use. Default value is loaded from app config.
 * @return {any|null} Locale to use in the date plugin or null if locale should not be used, or it cannot be found.
 */
export const getDateLocale = (locale, plugin = locale_date_plugin_name) => {
	return get(locale, `plugins.${plugin}.${locale_date_locale_path}`, null);
}

/**
 * Get date related locale settings plugin by locale code (IETF)
 * @param {string} localeCode - Locale IETF code of the locale to get the data from (for example 'sr-Latn-RS').
 * @param {string} [plugin] - Locale plugin name to use. Default value is loaded from app config.
 * @return {Locale|null} Locale to use in the date plugin or null if locale should not be used, or it cannot be found.
 */
export const getDateLocaleByCode = (localeCode, plugin = locale_date_plugin_name) => {
	return get(getLocaleByCode(localeCode), `plugins.${plugin}.${locale_date_locale_path}`, null);
}

/**
 * Get locale date format
 * @see src/i18n/locale.js file for predefined formats.
 *
 * @param {LocaleObj} locale - Locale to get the data from.
 * @param {string} [formatName] - Locale format name (see 'LOCALE_DATE_FORMAT_NAMES' core const).
 * @param {string} [plugin] - Locale plugin name to use. Default value is loaded from app config.
 * @return {string}
 */
export const getLocaleDateFormat = (
	locale, formatName = LOCALE_DATE_FORMAT_NAME.STANDARD, plugin = locale_date_plugin_name
) => get(locale, `plugins.${plugin}.${locale_date_format_path}.${formatName}`, '');

/**
 * Get alternative locale date format
 * @see src/i18n/locale.js file for predefined formats.
 *
 * @param {LocaleObj} locale - Locale to get the data from.
 * @param {string} [formatName] - Locale format name (see 'LOCALE_DATE_FORMAT_NAMES' core const).
 * @param {string} [plugin] - Locale plugin name to use. Default value is loaded from app config.
 * @return {string}
 */
export const getLocaleDateFormatAlt = (
	locale, formatName = LOCALE_DATE_FORMAT_NAME.STANDARD, plugin = locale_date_plugin_name
) => get(locale, `plugins.${plugin}.${locale_date_format_path}Alt.${formatName}`, '');

/**
 * Get locale time format
 * @see src/i18n/locale.js file for predefined formats.
 *
 * @param {LocaleObj} locale - Locale to get the data from.
 * @param {string} formatName - Locale format name (see 'LOCALE_DATE_FORMAT_NAMES' core const).
 * @param {string} [plugin] - Locale plugin name to use. Default value is loaded from app config.
 * @return {string}
 */
export const getLocaleTimeFormat = (
	locale, formatName = LOCALE_TIME_FORMAT_NAME.STANDARD, plugin = locale_date_plugin_name
) => get(locale, `plugins.${plugin}.${locale_time_format_path}.${formatName}`, '');

/**
 * Get short locale date format
 * @see src/i18n/locale.js file for predefined formats.
 * 
 * @param {LocaleObj} locale - Locale to get the data from.
 * @param {string} [plugin] - Locale plugin name to use. Default value is loaded from app config.
 * @return {string}
 */
export const getLocaleShortDateFormat = (locale, plugin) => 
	getLocaleDateFormat(locale, LOCALE_DATE_FORMAT_NAME.SHORT, plugin);

/**
 * Get standard locale date format
 * @see src/i18n/locale.js file for predefined formats.
 *
 * @param {LocaleObj} locale - Locale to get the data from.
 * @param {string} [plugin] - Locale plugin name to use. Default value is loaded from app config.
 * @return {string}
 */
export const getLocaleStandardDateFormat = (locale, plugin) => 
	getLocaleDateFormat(locale, LOCALE_DATE_FORMAT_NAME.STANDARD, plugin);

/**
 * Get long locale date format
 * @see src/i18n/locale.js file for predefined formats.
 *
 * @param {LocaleObj} locale - Locale to get the data from.
 * @param {string} [plugin] - Locale plugin name to use. Default value is loaded from app config.
 * @return {string}
 */
export const getLocaleLongDateFormat = (locale, plugin) => 
	getLocaleDateFormat(locale, LOCALE_DATE_FORMAT_NAME.LONG, plugin);

/**
 * Get short locale time format
 * @see src/i18n/locale.js file for predefined formats.
 *
 * @param {LocaleObj} locale - Locale to get the data from.
 * @param {string} [plugin] - Locale plugin name to use. Default value is loaded from app config.
 * @return {string}
 */
export const getLocaleShortTimeFormat = (locale, plugin) => 
	getLocaleTimeFormat(locale, LOCALE_TIME_FORMAT_NAME.SHORT, plugin);

/**
 * Get standard locale time format
 * @see src/i18n/locale.js file for predefined formats.
 *
 * @param {LocaleObj} locale - Locale to get the data from.
 * @param {string} [plugin] - Locale plugin name to use. Default value is loaded from app config.
 * @return {string}
 */
export const getLocaleStandardTimeFormat = (locale, plugin) => 
	getLocaleTimeFormat(locale, LOCALE_TIME_FORMAT_NAME.STANDARD, plugin);

/**
 * Get long locale time format
 * @see src/i18n/locale.js file for predefined formats.
 *
 * @param {LocaleObj} locale - Locale to get the data from.
 * @param {string} [plugin] - Locale plugin name to use. Default value is loaded from app config.
 * @return {string}
 */
export const getLocaleLongTimeFormat = (locale, plugin) => 
	getLocaleTimeFormat(locale, LOCALE_TIME_FORMAT_NAME.LONG, plugin);

/**
 * Get locale datetime separator
 * @see src/i18n/locale.js file for predefined formats.
 *
 * @param {LocaleObj} locale - Locale to get the data from.
 * @param {string} [plugin] - Locale plugin name to use. Default value is loaded from app config.
 * @return {string}
 */
export const getLocaleDatetimeSeparator = (locale, plugin = locale_date_plugin_name) =>
	get(locale, `plugins.${plugin}.${locale_datetime_separator_path}`, '');

/**
 * Get locale date format
 * @see src/i18n/locale.js file for predefined formats.
 *
 * @param {LocaleObj} locale - Locale to get the data from.
 * @param {string} [formatName] - Locale format name (see 'LOCALE_DATE_FORMAT_NAMES' core const).
 * @param {string} [plugin] - Locale plugin name to use. Default value is loaded from app config.
 * @return {string}
 */

/**
 * Get locale datetime format
 * @description Combines locale date, datetime separator and time to get the result.
 * @see src/i18n/locale.js file for predefined formats.
 *
 * @param {LocaleObj} locale - Locale to get the data from.
 * @param {string} [dateFormatName] - Locale date format name (see 'LOCALE_DATE_FORMAT_NAMES' core const).
 * @param {string} [timeFormatName] - Locale time format name (see 'LOCALE_TIME_FORMAT_NAMES' core const).
 * @param {string} [plugin] - Locale plugin name to use. Default value is loaded from app config.
 * @return {string}
 */
export const getLocaleDatetimeFormat = (
	locale, dateFormatName = LOCALE_DATE_FORMAT_NAME.STANDARD, timeFormatName = LOCALE_TIME_FORMAT_NAME.STANDARD, 
	plugin
) => 
	getLocaleDateFormat(locale, dateFormatName, plugin) + 
	getLocaleDatetimeSeparator(locale, plugin) + 
	getLocaleTimeFormat(locale, timeFormatName, plugin)


// Locale numbers ------------------------------------------------------------------------------------------------------
/**
 * Get number related locale settings
 * @param {LocaleObj} locale - Locale to get the data from.
 * @return {any|null} Locale to use for numbers or null if locale should not be used, or it cannot be found.
 */
export const getNumberLocale = locale => get(locale, 'numbers', null);


// App locale ----------------------------------------------------------------------------------------------------------
/**
 * Get current locale object for the app
 * @return {LocaleObj}
 */
export const getAppLocale = () => cloneDeep(selectors.i18n.getLocale(reduxStore.getState()));

/**
 * Get
 * @param {string|string[]} path - All locale value path string or an array of path segments in the locale object.
 * @param {any} [defaultValue] - Default value if app locale value is undefined.
 * @return {any}
 */
export const getAppLocaleValue = (path, defaultValue) => get(getAppLocale(), path, defaultValue);


// App locale date and time --------------------------------------------------------------------------------------------
/**
 * Get locale IETF language tag from locale object
 * @return {string} For example 'sr-Latn-RS'.
 */
export const getAppLocaleCode = () => getLocaleCode(getAppLocale());

/**
 * Get the locale settings used by the plugin that handles dates
 * @return {any|null} Locale to use in the date plugin or null if locale should not be used, or it cannot be found.
 */
export const getAppDatePluginLocale = () => getDateLocale(getAppLocale());

/**
 * Get locale date format for the app by format name
 * @see src/i18n/locale.js file for predefined formats.
 * 
 * @param {string} [formatName] - Locale format name (see 'LOCALE_DATE_FORMAT_NAMES' core const). If not specified,
 * app locale standard time format will be used.
 * @return {string}
 */
export const getAppLocaleDateFormat = (formatName) => getLocaleDateFormat(getAppLocale(), formatName);

/**
 * Get locale time format for the app by format name
 * @see src/i18n/locale.js file for predefined formats.
 * 
 * @param {string} [formatName] - Locale format name (see 'LOCALE_DATE_FORMAT_NAMES' core const). If not specified, 
 * app locale standard time format will be used.
 * @return {string}
 */
export const getAppLocaleTimeFormat = (formatName) => getLocaleTimeFormat(getAppLocale(), formatName);

/**
 * Get locale datetime separator for the app
 * @see src/i18n/locale.js file for reference.
 * @return {string}
 */
export const getAppLocalDatetimeSeparator = () => getLocaleDatetimeSeparator(getAppLocale());

/**
 * Get locale datetime format for the app by date and time format names
 * @note Datetime format uses locale datetime separator.
 * @see src/i18n/locale.js file for predefined formats and datetime separator.
 * 
 * @param {string} [dateFormatName] - Locale date format name (see 'LOCALE_DATE_FORMAT_NAMES' core const). If not 
 * specified, app locale standard date format will be used.
 * @param {string} [timeFormatName] - Locale time format name (see 'LOCALE_TIME_FORMAT_NAMES' core const). If not 
 * specified, app locale standard time format will be used.
 * @return {string}
 */
export const getAppLocaleDatetimeFormat = (dateFormatName, timeFormatName) => getLocaleDatetimeFormat(
	getAppLocale(), dateFormatName, timeFormatName
);


// App locale numbers --------------------------------------------------------------------------------------------------
/**
 * Get number related locale settings for the app
 * @return {any|null} Locale to use for numbers or null if locale should not be used, or it cannot be found.
 */
export const getAppNumberLocale = () => getNumberLocale(getAppLocale());