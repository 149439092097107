/** @typedef {string} RequestType */
export const REQUEST_TYPE_STANDARD = 'STANDARD';
export const REQUEST_TYPE_JSON = 'JSON';
export const REQUEST_TYPE_FORM_DATA = 'FORM_DATA';
export const REQUEST_TYPE_UPLOAD = 'UPLOAD';
/** @enum {RequestType} */
export const REQUEST_TYPE = {
	STANDARD: REQUEST_TYPE_STANDARD,
	JSON: REQUEST_TYPE_JSON,
	FORM_DATA: REQUEST_TYPE_FORM_DATA,
	UPLOAD: REQUEST_TYPE_UPLOAD,
};
/** {RequestType[]} */
export const REQUEST_TYPES = [
	REQUEST_TYPE_STANDARD, 
	REQUEST_TYPE_JSON,
	REQUEST_TYPE_FORM_DATA,
	REQUEST_TYPE_UPLOAD,
];

/** @typedef {string} ResponseDataType */
export const RESPONSE_DATA_TYPE_ARRAY_BUFFER = 'ARRAY_BUFFER';
export const RESPONSE_DATA_TYPE_BLOB = 'BLOB';
export const RESPONSE_DATA_TYPE_FORM_DATA = 'FORM_DATA';
export const RESPONSE_DATA_TYPE_JSON = 'JSON';
export const RESPONSE_DATA_TYPE_TEXT = 'TEXT';
/** @enum {ResponseDataType} */
export const RESPONSE_DATA_TYPE = {
	ARRAY_BUFFER: RESPONSE_DATA_TYPE_ARRAY_BUFFER,
	BLOB: RESPONSE_DATA_TYPE_BLOB,
	FORM_DATA: RESPONSE_DATA_TYPE_FORM_DATA,
	JSON: RESPONSE_DATA_TYPE_JSON,
	TEXT: RESPONSE_DATA_TYPE_TEXT,
};

/** @typedef {string} LoginType */
export const LOGIN_TYPE_NONE = 'NO_LOGIN';
export const LOGIN_TYPE_OAUTH2_NO_REDIRECT = 'OAUTH2_NO_REDIRECT';
export const LOGIN_TYPE_OAUTH2_REDIRECT = 'OAUTH2_REDIRECT';
/** @enum {LoginType} */
export const LOGIN_TYPE = {
	NONE: LOGIN_TYPE_NONE,
	OAUTH2_NO_REDIRECT: LOGIN_TYPE_OAUTH2_NO_REDIRECT,
	OAUTH2_REDIRECT: LOGIN_TYPE_OAUTH2_REDIRECT,
};
/** @type {LoginType[]} */
export const LOGIN_TYPES = [
	LOGIN_TYPE_NONE,
	LOGIN_TYPE_OAUTH2_NO_REDIRECT,
	LOGIN_TYPE_OAUTH2_REDIRECT
];