import style from "./index.module.css";

import React from "react";
import DataComponent from "Core/components/DataComponent";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {isString} from "lodash";
import {getNumber, getString, isset} from "Core/helpers/data";
import {MESSAGE_STYLE} from "./const";
import * as actions from "./actions";
import Icon from "Core/components/display/Icon";
import {icon_font_close_symbol, messages_default_auto_hide_after} from "Config/app";
import Html from "Core/components/display/Html";

class Message extends DataComponent {
	/**
	 * Auto hide timeout ID
	 * @type {number}
	 */
	hideTimeout;
	
	constructor(props) {
		// Define component options
		const options = {
			
		};
		
		// Define component's initial state
		const initialState = {
			/** @type MessageData */
			data: undefined
		};
		
		super(props, initialState, options);

		// Bind component methods
		this.setHideTimeout = this.setHideTimeout.bind(this);
		this.close = this.close.bind(this);
	}
	
	/** @inheritDoc */
	async asyncComponentDidMount(override = false) {
		await super.asyncComponentDidMount(override);

		const autoHideAfter = getNumber(this.getData(), 'autoHideAfter', messages_default_auto_hide_after);
		if (autoHideAfter > -1) this.hideTimeout = this.setHideTimeout(autoHideAfter);
	}
	
	componentWillUnmount() {
		super.componentWillUnmount();
		
		if (isset(this.hideTimeout)) clearTimeout(this.hideTimeout);
	}

	/**
	 * Method that should return true if component can be rendered or false otherwise
	 * @return {boolean} True if component can be rendered or false otherwise.
	 */
	canRender() {
		const data = this.getData();
		return (getString(data, 'GUIID') !== '');
	}

	/**
	 * Set how long the message will be visible before it is automatically removed
	 * @param {number} seconds - Number of seconds after witch the message will be automatically removed.
	 * @return {number} Timeout ID.
	 */
	setHideTimeout(seconds) {
		const {hideMessageAction} = this.props;
		
		// Set the auto hide timeout
		return setTimeout(() => {
			hideMessageAction(this.getValue('GUIID'));
		}, seconds * 1000);
	}

	/**
	 * Close message
	 */
	close() {
		const {hideMessageAction} = this.props;
		hideMessageAction(this.getValue('GUIID'));
	}
	
	render() {
		// Do not render component if 'canRender' returns false
		if (!this.canRender()) return null;
		
		const {forwardedRef} = this.props;
		
		/** @type MessageData */
		const message = this.getData();
		const messageStyle = this.getValue('style', MESSAGE_STYLE.DEFAULT);
		
		return (
			<div
				id={`message-${message.GUIID}`}
				className={
					'message-component' +
					` ${style['message']}` +
					(
						message.style === MESSAGE_STYLE.CUSTOM ? 
							` ${message.customClass}` : 
							` ${messageStyle} ${style[messageStyle]}`
					) +
					(message.canClose && message.showCloseButton ? ` has-close-btn ${style['has-close-btn']}` : '')
				}
				onClick={(message.canClose && message.closeOnClick ? this.close : null)}
				ref={forwardedRef}
			>
				{
					isString(message.content) ?
						<Html key={message.GUIID} content={message.content} element="span" />
						:
						message.content ? message.content : null
				}
				
				{
					message.canClose && message.showCloseButton ? 
						<Icon 
							symbol={icon_font_close_symbol} 
							className={`close-message-btn ${style['close-message-btn']}`}
							onClick={this.close}
						/>
						: null
				}
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
Message.propTypes = {
	data: PropTypes.shape({
		// Message unique GUI ID.
		GUIID: PropTypes.string,
		// Message style (keyof MESSAGE_STYLE). If not defined, MESSAGE_STYLE.DEFAULT will be used.
		style: PropTypes.string,
		// Message content. Can be a React component or string (HTML is supported).
		content: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
		// Number of seconds for the message to be visible. If -1, message will be visible indefinitely or until its 
		// closed manually. If not defined default value from app config will be used.
		autoHideAfter: PropTypes.number,
		// Custom CSS class used instead of style class if style is MESSAGE_STYLE_CUSTOM. Use this option if you want to 
		// render a custom style message.
		customClass: PropTypes.string,
		// Flag that determines if message can be manually closed
		canClose: PropTypes.bool,
		// Flag that determines if close button will be rendered
		// @note This flag will be ignored if 'canClose' prop is false.
		showCloseButton: PropTypes.bool,
		// Flag that determines if clicking anywhere inside the message will close it
		// @note This flag will be ignored if 'canClose' prop is false.
		closeOnClick: PropTypes.bool
	}),
	// Forwarded ref used by CSSTransition
	// @description CSSTransition 'nodeRef' prop must point to the DOM node (element). Because Message component is a 
	// class component some internal DOM node should be assigned to the forwarded ref passed in this prop. Usually the 
	// first DOM node (wrapper element) is used.
	forwardedRef: PropTypes.any
};

export default connect(null, actions)(Message);
export {default as reducer, reducerStoreKey, selectors, actionCreators} from "./reducer";
export * from "./actions";