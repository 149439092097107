import {cloneDeep, get, set} from 'lodash';
import Cookies from 'js-cookie'
import {memory_storage_var} from '../../config';
import reduxStore from '../store';
import {actionCreators as storageReduxActionCreators, selectors as storageReduxSelectors} from './reducer';
import {STORAGE_TYPE} from "./const";
import {isset} from "../helpers/data";

/**
 * Gets value of the key from storage depending on storage type
 * 
 * @param {string} keyName - Name of the key in storage.
 * @param {string} storageType - Storage type (see STORAGE_TYPE const). Supported storage types are cookie, session 
 * storage and local storage.
 * @param {boolean} [parseJSON=false] - Flag that specifies if storage value will be parsed into JSON object.
 * @param {any} [parseJSONDefaultValue=undefined] - Default values used if parsing JSON fails.
 * @return {any|undefined} Storage key value or undefined if value could not be found.
 */
export const getStorageValue = (keyName, storageType, parseJSON = false, parseJSONDefaultValue = undefined) => {
	let result;
	switch (storageType) {
		case STORAGE_TYPE.COOKIE:
			result = Cookies.get(keyName);
			break;
		case STORAGE_TYPE.LOCAL:
			result = (localStorage.getItem(keyName) !== null ? localStorage.getItem(keyName) : undefined);
			break;
		case STORAGE_TYPE.SESSION:
			result = (sessionStorage.getItem(keyName) !== null ? sessionStorage.getItem(keyName) : undefined);
			break;
		case STORAGE_TYPE.MEMORY:
			result = get(window, [memory_storage_var, keyName]);
			break;
		case STORAGE_TYPE.REDUX:
			result = storageReduxSelectors.getItem(reduxStore.getState(), keyName);
			break;
		default:
			result = undefined;
	}
	
	if (parseJSON) {
		try { return JSON.parse(result); }
		catch (e) { return parseJSONDefaultValue; }
	} else {
		return result;
	}
}

/**
 * Sets value of the key from storage depending on storage type
 *
 * @param {string} keyName - Name of the key in storage.
 * @param {any} keyValue - Value to set.
 * @param {string} storageType - Storage type (see STORAGE_TYPE const). Supported storage types are cookie, session
 * storage and local storage.
 * @param {{
 * 	[expires]: number, 
 * 	[path='/']: string,
 * 	[domain]: string, 
 * 	[secure=false]: boolean, 
 * 	[sameSite='']: string
 *	}} [options={}] - Cookie options (see https://github.com/js-cookie/js-cookie#cookie-attributes)
 *	@param {boolean} [stringifyJSON=false] - Flag that specifies if 'keyValue' is a JSON that will be stringified before
 *	it is saved to the storage. If value cannot be stringified, this function will set it to an empty string and will not
 *	throw an exception.
 * @return {boolean} True if value was set, false otherwise.
 */
export const setStorageValue = (keyName, keyValue, storageType, options = {}, stringifyJSON = false) => {
	let keyValueToSave = cloneDeep(keyValue);
	if (stringifyJSON) {
		try {
			keyValueToSave = JSON.stringify(keyValueToSave);
			if (!isset(keyValueToSave)) keyValueToSave = '';
		} catch (e) {
			keyValueToSave = '';
		}
	}
	
	switch (storageType) {
		case STORAGE_TYPE.COOKIE:
			return (Cookies.set(keyName, options) !== undefined);
		case STORAGE_TYPE.LOCAL:
			try {
				localStorage.setItem(keyName, keyValueToSave);
				return true;
			} catch (e) {
				console.log('%cCould not set local storage item!', 'color: red', e);
				return false;
			}
		case STORAGE_TYPE.SESSION:
			try {
				sessionStorage.setItem(keyName, keyValueToSave);
				return true;
			} catch (e) {
				console.log('%cCould not set session storage item!', 'color: red', e);
				return false;
			}
		case STORAGE_TYPE.MEMORY:
			try {
				set(window, [memory_storage_var, keyName], keyValueToSave);
				return true;
			} catch (e) {
				console.log('%cCould not set memory storage item!', 'color: red', e);
				return false;
			}
		case STORAGE_TYPE.REDUX:
			try {
				reduxStore.dispatch(storageReduxActionCreators.setItem(keyName, keyValueToSave));
				return true;
			} catch (e) {
				console.log('%cCould not set Redux storage item!', 'color: red', e);
				return false;
			}
			
		default:
			return false;
	}
}

/**
 * Delete storage key
 *
 * @param {string} keyName - Name of the key in storage.
 * @param {string} storageType - Storage type (see STORAGE_TYPE const). Supported storage types are cookie, session
 * storage and local storage.
 * @param {{
 * 	[expires]: number, 
 * 	[path='/']: string, 
 * 	[domain]: string, 
 * 	[secure=false]: boolean, 
 * 	[sameSite='']: string
 *	}} [options={}] - Cookie options (see https://github.com/js-cookie/js-cookie#cookie-attributes)
 */
export const deleteStorageKey = (keyName, storageType, options = {}) => {
	switch (storageType) {
		case STORAGE_TYPE.COOKIE: 
			Cookies.remove(keyName, options);
			break;
		case STORAGE_TYPE.LOCAL:
			localStorage.removeItem(keyName);
			break;
		case STORAGE_TYPE.SESSION:
			sessionStorage.removeItem(keyName);
			break;
		case STORAGE_TYPE.MEMORY:
			if (window.hasOwnProperty('memory_storage_var') && window[memory_storage_var].hasOwnProperty(keyName)) {
				delete window[memory_storage_var][keyName];
			}
			break;
		case STORAGE_TYPE.REDUX:
			reduxStore.dispatch(storageReduxActionCreators.removeItem(keyName));
			break;
		// no default
	}
};

export * from "./const";