import React from "react";
import BaseComponent from "Core/components/BaseComponent";
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {getGlobalActions} from 'Core/helpers/redux';
import * as actions from './actions';
import {get, omit} from "lodash";
import SelectAsyncInput from "Core/components/input/SelectAsyncInput";
import {getString} from "Core/helpers/data";
import HighlightedQueryOption from "Core/components/input/SelectInput/options/HighlightedQueryOption";

/**
 * Get all actions used by this component
 * @type {Object}
 */
const allActions = getGlobalActions(actions);

class PhysicianQuickSearch extends BaseComponent {
	constructor(props) {
		super(props, {
			domPrefix: 'physician-quick-search',
		});

		// Data methods
		this.loadOptions = this.loadOptions.bind(this);
	}

	/**
	 * Load options for async select component
	 *
	 * @param {string} query - Query string from input.
	 * @return {Promise<Object[]>}
	 */
	loadOptions(query) {
		const {minLength, physicianQuickSearchAction} = this.props;
		if (query.length >= minLength) return this.executeAbortableAction(physicianQuickSearchAction, query);
		return Promise.resolve([]);
	}
	
	render() {
		return (
			<SelectAsyncInput
				loadOptions={this.loadOptions}
				getOptionLabel={o => {
					const title = getString(o, 'title');
					const firstName = getString(o, 'firstName');
					const lastName = getString(o, 'lastName');

					let label = firstName;
					if (lastName.length > 0) label += `${label.length > 0 ? ' ' : ''}${lastName}`;
					if (title.length > 0) label = `${title}${label.length > 0 ? ' ' : ''}${label}`;

					return label;
				}}
				getOptionValue={o => get(o, 'id')}
				components={{Option: HighlightedQueryOption}}
				{...omit(this.props, ['minLength', 'loadOptions', ...Object.keys(allActions)])}
			/>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
PhysicianQuickSearch.propTypes = {
	...SelectAsyncInput.propTypes,
	
	minLength: PropTypes.number,
};

/**
 * Define component default values for own props
 */
PhysicianQuickSearch.defaultProps = {
	...SelectAsyncInput.defaultProps,
	
	minLength: 1,
};

export default connect(null, allActions, null, {forwardRef: true})(PhysicianQuickSearch);