import {cloneDeep} from "lodash";
import {getString, resetObjectValues} from "Core/helpers/data";
import {DiagnosesItemDataObject} from "../dataObjects";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {DiagnosesItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new DiagnosesItemDataObject(
		getString(result, 'uniqueID'),
		getString(result, 'code'),
		getString(result, 'description')
	);

	return result;
}

/**
 * Get output data from input data
 * @param {DiagnosesItemDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep(inputData);

	result = {
		uniqueID: result.id,
		code: result.diagnosesCode,
		description: result.diagnosesDescription
	};

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}