export class PatientTherapySelectOptionDataObject {
	/**
	 * @param {string} id
	 * @param {string} diagnosis
	 * @param {string} diagnosisCode
	 * @param {Date} therapyDate
	 * @param {string} medicalRecordCategory
	 * @param {number} medicalRecordNumber
	 * @param {string} sentByPhysician
	 */
	constructor(id, diagnosis, diagnosisCode, therapyDate, medicalRecordCategory, medicalRecordNumber, sentByPhysician) {
		this.id = id;
		this.diagnosis = diagnosis;
		this.diagnosisCode = diagnosisCode;
		this.therapyDate = therapyDate;
		this.medicalRecordCategory = medicalRecordCategory;
		this.medicalRecordNumber = medicalRecordNumber;
		this.sentByPhysician = sentByPhysician;
	}
}