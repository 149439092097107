import {cloneDeep} from "lodash";
import {getString, resetObjectValues} from "Core/helpers/data";
import {RecommendationsItemDataObject} from "../dataObjects";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {RecommendationsItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new RecommendationsItemDataObject(
		getString(result, 'uniqueID'),
		getString(result, 'name'),
	);

	return result;
}

/**
 * Get output data from input data
 * @param {RecommendationsItemDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep(inputData);

	result = {
		uniqueID: result.id,
		name: result.recommendationsName,
	};

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}