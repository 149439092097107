import {ioJsonAction} from "Core/store/actions/io";
import {getArray} from "Core/helpers/data";
import * as dataMap from './dataMap';

/**
 * Fetch the list of patient therapies for a specified patient used as Select options
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} patientId - ID of the patient to get the therapies for.
 * @return {function(*): Promise<PatientTherapySelectOptionDataObject>}
 */
export const fetchPatientTherapySelectOptionsAction = (abortCallback, patientId) => dispatch => {
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'member/therapies/get-therapies',
		{id: patientId}
	)(dispatch)
		// Map the result data array
		.then(res => getArray(res, 'data').map(i => dataMap.input(i)));
};