/**
 * Data object representing the medical record categories list item
 * @note This is usually used in data table as a row. Params don't have default values since this data object is used to
 * represent data that actually exists.
 */
export class MedicalRecordCategoriesListItemDataObject {
	/**
	 * @param {string} id - List item's ID.
	 * @param {string} categoryName - List item's medical record category name.
	 */
	constructor(id, categoryName) {
		this.id = id;
		this.categoryName = categoryName;
	}
}

/**
 * Data object representing the medical record categories item
 * @note This is usually used when creating or updating data table item using a popup or a dialog. Params have default 
 * values since this data object can be used for creating an item. This is why 'id' has a default value of null which 
 * represents a new item that is being created.
 */
export class MedicalRecordCategoriesItemDataObject {
	/**
	 * @param {string|null} [id=null] - Medical record category ID.
	 * @param {string} [categoryName=''] - Medical record category name.
	 * @param {string} backgroundCalendarColor - Medical record category event background color in the calendar.
	 * @param {string} fontCalendarColor - Medical record category event text color in the calendar. 
	 */
	constructor(id = null, categoryName = '', backgroundCalendarColor = '', fontCalendarColor = '') {
		this.id = id;
		this.categoryName = categoryName;
		this.backgroundCalendarColor = backgroundCalendarColor;
		this.fontCalendarColor = fontCalendarColor;
	}
}