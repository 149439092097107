import {hideLoading, showDialogLoading} from "Core/helpers/loading";
import {ioJsonAction} from "Core/store/actions/io";
import {isset} from "Core/helpers/data";
import * as appointmentUpdateDataMap from "DataMap/appointment/update";
import {createPaymentAction} from "Components/dialogs/PaymentDialog/actions";
import {PaymentDataObject} from "DataObjects/payment";
import {APPOINTMENT_VISIT_PAYMENT_FAILED} from "Components/dialogs/AppointmentVisitDialog/const";

/**
 * Create a visit based on the appointment and a payment if payment amount is specified
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {AppointmentVisitDataObjects} appointmentVisit
 * @param {string} patientId
 * @return {function(*): Promise<string>}
 */
export const creatAppointmentVisitAction = (abortCallback, appointmentVisit, patientId) => dispatch => {
	const loading = showDialogLoading('appointment-visit-dialog');
	return ioJsonAction(
		undefined,
		'defaultAuthorizedApi',
		'member/appointments/update-by-id',
		{
			id: appointmentVisit.appointment.id,
			data: {
				...appointmentUpdateDataMap.output(appointmentVisit.appointment),
				createVisit: true,
				visitNote: appointmentVisit.note,
			},
			requestSavedData: false,
		},
	)(dispatch)
		// Create payment if payment amount is specified
		.then(res => isset(res) ?
			appointmentVisit.paymentAmount > 0 ?
				createPaymentAction(abortCallback, new PaymentDataObject(
					null,
					patientId,
					appointmentVisit.appointment.therapyId,
					appointmentVisit.appointment.date,
					appointmentVisit.paymentAppointmentsNumber,
					appointmentVisit.paymentAmount,
					appointmentVisit.paymentNote,
					appointmentVisit.appointment.officeLocationId,
				))(dispatch).then(res => isset(res) ? res : APPOINTMENT_VISIT_PAYMENT_FAILED)
				: res
			: res
		)
		// Hide the loading overlay
		.then(res => { hideLoading(loading); return res; });
}