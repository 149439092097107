import React from "react";
import PageDataComponent from "Core/components/PageDataComponent";
import {connect} from "react-redux";
import {getPageActions} from "Core/helpers/redux";
import {selectors} from "Core/store/reducers";
import * as appConfig from "../../config";
import * as pageConfig from "./config";
import * as actions from "./actions";
import {getMenuSidebarShrankFromStorage} from "Layout/elements/MainSidebar/helpers";
import {reducerStoreKey} from "./reducer";
import {areAllObjectPropsEmpty, getArray, getNumber, isset, trimArray} from "Core/helpers/data";
import * as filterDataMap from "./dataMap/filter";
import {scrollToSelector} from "Core/helpers/dom";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import Label from "Core/components/display/Label";
import SimpleStaticSearch, {
	SIMPLE_STATIC_SEARCH_DISPLAY_TYPE,
	SIMPLE_STATIC_SEARCH_LAYOUT,
	SimpleStaticSearchOptionObject
} from "Core/components/advanced/SimpleStaticSearch";
import DataTable, {DATA_TABLE_CELL_TYPE} from "Core/components/advanced/DataTable";
import {PAGINATION_TYPE} from "Core/components/action/Pagination";
import Spinner from "Core/components/display/Spinner";
import Icon from "Core/components/display/Icon";
import {
	DataTableCellAnyTypeOptionsDataObject,
} from "Core/components/advanced/DataTable/DataTableCell/dataObjects";
import {LOCALE_DATE_FORMAT_NAME} from "Core/const/locale";
import {Link} from "react-router-dom";
import {STANDARD_DATE_TIME_FORMAT} from "Core/const/datetime";
import {startOfDay} from "date-fns";
import {forOwn, get} from "lodash";
import {capitalize} from "Core/helpers/string";
import {routerPath as patientRecordRouterPath} from "Pages/apps/default/patientRecord/config";
import {Tooltip} from "react-tippy";
import {icon_font_delete_symbol, icon_font_edit_symbol} from "Config/app";
import VisitDialog from "Components/dialogs/VisitDialog";
import {deleteVisitsItemAction} from "Components/advanced/PatientRecord/components/Visits/actions";
import ConfirmDialog from "Core/components/dialogs/ConfirmDialog";
import {isSuccessful} from "Core/helpers/io";
import DateLabel from "Core/components/display/DateLabel";
import {AsyncMountError} from "Core/errors";

/**
 * Get all actions used by this component
 * @type {Object}
 */
const allActions = getPageActions({...actions, deleteVisitsItemAction});

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	mainSidebarShrank: getMenuSidebarShrankFromStorage(selectors.mainSidebar.shrank(state)),
	mainListData: selectors[reducerStoreKey].getSearchVisitsListData(state),
	mainList: selectors[reducerStoreKey].getSearchVisitsList(state),
	mainListPagination: selectors[reducerStoreKey].getSearchVisitsListPagination(state),
	mainListSort: selectors[reducerStoreKey].getSearchVisitsListSort(state),
	mainListFilter: selectors[reducerStoreKey].getSearchVisitsListFilter(state),
});

class SearchVisitsPage extends PageDataComponent {
	constructor(props) {
		super(props, {
			data: {
				/**
				 * Flag showing if filter is loading
				 */
				filterLoading: false,
			},
			
			/**
			 * Therapist codebook loaded from IO
			 * @note If undefined, codebook is not loaded yet. If null, codebook is being loaded.
			 * @type {CodebookItem[]|undefined|null}
			 */
			therapistCodebook: undefined,
			/**
			 * Office location codebook loaded from IO
			 * @note If undefined, codebook is not loaded yet. If null, codebook is being loaded.
			 * @type {CodebookItem[]|undefined|null}
			 */
			officeLocationCodebook: undefined,
			/**
			 * Medical record category codebook loaded from IO
			 * @note If undefined, codebook is not loaded yet. If null, codebook is being loaded.
			 * @type {CodebookItem[]|undefined|null}
			 */
			medicalRecordCategoryCodebook: undefined,
			/**
			 * Therapy type codebook loaded from IO
			 * @note If undefined, codebook is not loaded yet. If null, codebook is being loaded.
			 * @type {CodebookItem[]|undefined|null}
			 */
			therapyTypeCodebook: undefined,
			/**
			 * Therapy subtype codebook loaded from IO
			 * @note If undefined, codebook is not loaded yet. If null, codebook is being loaded.
			 * @type {CodebookItem[]|undefined|null}
			 */
			therapySubtypeCodebook: undefined,
			/**
			 * Therapy sub-subtype codebook loaded from IO
			 * @note If undefined, codebook is not loaded yet. If null, codebook is being loaded.
			 * @type {CodebookItem[]|undefined|null}
			 */
			therapySubSubtypeCodebook: undefined,
		}, {
			domPrefix: 'search-visits-page',
			translationPath: pageConfig.translationPath,
			routerPath: pageConfig.routerPath,
			checkLogin: false,
			disableLoad: true,
			renderTitle: false,

			// Custom component options
			/**
			 * Date used as an initial/default date from in the main filter
			 * @note Also used when filter is cleared.
			 * @type {Date}
			 */
			initialDateFrom: startOfDay(new Date()),
			/**
			 * Date used as an initial/default date to in the main filter
			 * @note Also used when filter is cleared.
			 * @type {Date}
			 */
			initialDateTo: startOfDay(new Date()),
		}, 'page_title');

		// Refs
		this.mainListFilterRef = null;

		// Data methods
		this.loadPageCodebook = this.loadPageCodebook.bind(this);
		this.clearTherapySubtypes = this.clearTherapySubtypes.bind(this);
		this.clearTherapySubSubtypes = this.clearTherapySubSubtypes.bind(this);
		this.reloadMainList = this.reloadMainList.bind(this);
		this.loadMainListPage = this.loadMainListPage.bind(this);
		this.sortMainList = this.sortMainList.bind(this);
		this.filterMainList = this.filterMainList.bind(this);
		this.removeMainListFilter = this.removeMainListFilter.bind(this);
		
		// Dialog methods
		this.openMainListItemDialog = this.openMainListItemDialog.bind(this);
		
		// Action methods
		this.deleteMainListItem = this.deleteMainListItem.bind(this);

		// Render methods
		this.renderActions = this.renderActions.bind(this);
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called on component mount and should be used to load any data required by the page
	 * @return {Promise<any>}
	 * @throws {AsyncMountError}
	 */
	loadPageData() {
		const {mainListData, mainListFilter} = this.props;

		try {
			// If main list data was already loaded (user already searched for something)
			if (mainListData !== null) {
				// Reload main list with currently applied filter, sort and pagination
				this.reloadMainList().then(res => { if (!isset(res)) throw new AsyncMountError(); });
			}

			// Load all codebooks used in the applied filter
			// @description Filter select components that use codebooks load them on menu open, so they need to be preloaded 
			// for all applied filter fields in order to select them properly (usually when opening the page).
			const codebooks = {
				officeLocation: 'officeLocationId',
				therapist: 'therapistId',
				medicalRecordCategory: 'medicalRecordCategoryId',
				therapyType: 'therapyTypeId',
				therapySubtype: 'therapySubTypeId',
				therapySubSubtype: 'therapySubSubTypeId'
			};

			forOwn(codebooks, (filterField, codebook) => {
				if (!!get(mainListFilter, filterField) && !isset(get(this.state, `${codebook}Codebook`))) {
					let actionParams = [];
					switch (codebook) {
						case 'therapySubtype': actionParams = [get(mainListFilter, 'therapyTypeId', null)]; break;
						case 'therapySubSubtype': actionParams = [get(mainListFilter, 'therapySubTypeId', null)]; break;
						// no default
					}
					this.loadPageCodebook(codebook, ...actionParams).then();
				}
			});
		} catch (e) {
			console.error(e);
			throw new AsyncMountError();
		}
	}

	/**
	 * Load a specific page codebook data into local state
	 * @note Page codebook actions are define in 'store/page/actions/codebooks' directory.
	 *
	 * @param {string} codebookName - Name of the codebook to load (for example: 'therapist').
	 * @param {any} [actionParams] - Any codebook action parameters (see the codebook definitions for reference).
	 * @return {Promise<CodebookItem[]>}
	 */
	loadPageCodebook(codebookName, ...actionParams) {
		const loadCodebookAction = get(this.props, `fetch${capitalize(codebookName)}CodebookAction`);

		if (loadCodebookAction) {
			this.setState({[`${codebookName}Codebook`]: null})
				.then(() => this.executeAbortableAction(loadCodebookAction, ...actionParams))
				.then(codebook => this.setState({[`${codebookName}Codebook`]: codebook}).then(() => codebook));
		}
		return Promise.resolve([]);
	}

	/**
	 * Clear therapy subtypes
	 *
	 * @description Clears both the loaded codebook and the filter value.
	 * @return Promise<Object>
	 */
	clearTherapySubtypes() {
		return this.mainListFilterRef.clearField('therapySubtype')
			.then(() => this.setState({therapySubtypeCodebook: undefined}));
	}

	/**
	 * Clear therapy sub-subtypes
	 *
	 * @description Clears both the loaded codebook and the filter value.
	 * @return Promise<Object>
	 */
	clearTherapySubSubtypes() {
		return this.mainListFilterRef.clearField('therapySubSubtype')
			.then(() => this.setState({therapySubSubtypeCodebook: undefined}));
	}
	
	/**
	 * Reload main list using current options (page, sort, ...)
	 * @return {Promise<*>}
	 */
	reloadMainList() {
		const {loadSearchVisitsListAction, mainListPagination, mainListSort, mainListFilter} = this.props;
		const {pageNo, perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = filterDataMap.output(mainListFilter);

		return this.executeAbortableAction(loadSearchVisitsListAction, oFilter, pageNo, perPage, sortBy, sortDir)
			.then(res => {
				this.mainListFilterRef?.reload();
				return res;
			});
	}

	/**
	 * Reload main list using current options (page, sort, ...) if any
	 * @param {number} [pageNo=1] - Page number to load (starts with 1).
	 * @return {Promise<*>}
	 */
	loadMainListPage(pageNo = 1) {
		const {loadSearchVisitsListAction, mainListPagination, mainListSort, mainListFilter} = this.props;
		const {perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = filterDataMap.output(mainListFilter);

		return this.executeAbortableAction(loadSearchVisitsListAction, oFilter, pageNo, perPage, sortBy, sortDir);
	}

	/**
	 * Sort main list
	 * @param {string} sortBy - Name of the sort column.
	 * @param {string} sortDir - Direction of the sort.
	 * @return {Promise<*>}
	 */
	sortMainList(sortBy, sortDir) {
		const {loadSearchVisitsListAction, mainListPagination, mainListFilter} = this.props;
		const {pageNo, perPage} = mainListPagination;
		const oFilter = filterDataMap.output(mainListFilter);

		return this.executeAbortableAction(loadSearchVisitsListAction, oFilter, pageNo, perPage, sortBy, sortDir);
	}

	/**
	 * Filter main list
	 * @param {Object} filter - Filter object where keys are filter field names and values are filter values.
	 * @return {Promise<*>}
	 */
	filterMainList(filter) {
		const {
			loadSearchVisitsListAction, setSearchVisitsFilterAction, mainListPagination, mainListSort,
		} = this.props;
		const {perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;
		const oFilter = filterDataMap.output(filter);
		
		// Set filter so that the change will be detected after IO
		setSearchVisitsFilterAction(oFilter);

		return this.setValue('filterLoading', true)
			.then(() => this.executeAbortableAction(loadSearchVisitsListAction, oFilter, 1, perPage, sortBy, sortDir))
			.then(() => this.setValue('filterLoading', false))
			.then(() => {
				if (!areAllObjectPropsEmpty(oFilter, true, false)) {
					scrollToSelector('#main-page-table', false, 80);
				}
			});
	}

	/**
	 * Remove main list filter
	 * @return {Promise<*>}
	 */
	removeMainListFilter() {
		const {resetSearchVisitsListAction} = this.props;
		return this.executeAbortableAction(resetSearchVisitsListAction)
			.then(() => this.mainListFilterRef.clearField('dateFrom', this.getOption('initialDateFrom')))
			.then(() => this.mainListFilterRef.clearField('dateTo', this.getOption('initialDateTo')));
	}


	// Dialog methods ---------------------------------------------------------------------------------------------------
	/**
	 * Open main list item dialog
	 * @note Since this is a search page, only the edit dialog is used.
	 * @param {SearchVisitsListItemDataObject} listItem - Main list item. 
	 */
	openMainListItemDialog(listItem) {
		const {openDialogAction} = this.props;

		const dialogGUIID = openDialogAction('', VisitDialog, {
			isNew: false,
			id: listItem.id,
			patientId: listItem.therapyPatientId,
			onSave: () => this.reloadMainList(),
		}, {
			id: 'edit-visit-dialog',
			className: 'bordered-title',
			closeOnEscape: true,
			closeOnClickOutside: false,
			hideCloseBtn: true,
			maxWidth: 800
		});
		this.setOption(
			'dialogsToCloseOnUnmount',
			trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
		);
	}

	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Delete main list item
	 * @param {SearchVisitsListItemDataObject} item - Main list popup item.
	 * @return {Promise<void>}
	 */
	deleteMainListItem(item) {
		const {
			openDialogAction, closeDialogAction, deleteVisitsItemAction, addSuccessMessageAction
		} = this.props;
		
		return new Promise(resolve => {
			const dialogGUIID = openDialogAction('', ConfirmDialog, {
				message: this.t('confirm_delete', 'Visits'),
				supportHtml: true,
				onYes: () => {
					this.executeAbortableAction(deleteVisitsItemAction, item.id)
						.then(response => {
							if (isSuccessful(response)) {
								addSuccessMessageAction(this.t('delete_success_msg', 'Visits'));
								// Reload main list data
								// @note This is done asynchronously on purpose because there is no reason to wait for this to 
								// finish before continuing.
								this.reloadMainList()
									// Go to the previous page if there are no table rows after one has been deleted
									.then(() => {
										const mainList = getArray(this.props, 'mainList');
										const pageNo = getNumber(this.props, 'mainListPagination.pageNo', 1);
										if (mainList.length === 0 && pageNo > 1) return this.loadMainListPage(pageNo - 1);
									});
							}
							return Promise.resolve(response);
						})
						.then(() => closeDialogAction(dialogGUIID))
						.finally(() => resolve('yes'));
				},
				onNo: () => {
					closeDialogAction(dialogGUIID);
					resolve('no');
				}
			}, {
				id: 'item-delete-dialog',
				closeOnEscape: true,
				closeOnClickOutside: true,
				hideCloseBtn: true,
				maxWidth: 500
			});
			this.setOption(
				'dialogsToCloseOnUnmount', 
				trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
			);
		});
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render data table actions cell
	 * @param {SearchVisitsListItemDataObject} row - Data table row.
	 * @return {JSX.Element}
	 */
	renderActions(row) {
		return (
			<div className="actions standard-actions-row">
				<Tooltip
					tag="div"
					title={this.t('delete_tooltip', 'Visits')}
					size="small"
					position="top-center"
					arrow={true}
					interactive={false}
					touchHold={true}
					delay={250}
				>
					<Button
						className="action-btn no-border-radius"
						displayStyle={BUTTON_STYLE.ACTION}
						displayType={BUTTON_DISPLAY_TYPE.NONE}
						icon={icon_font_delete_symbol}
						onClick={() => this.deleteMainListItem(row)}
					/>
				</Tooltip>

				<Tooltip
					tag="div"
					title={this.t('edit_tooltip', 'Visits')}
					size="small"
					position="top-center"
					arrow={true}
					interactive={false}
					touchHold={true}
					delay={250}
				>
					<Button
						className="action-btn no-border-radius"
						displayStyle={BUTTON_STYLE.ACTION}
						displayType={BUTTON_DISPLAY_TYPE.NONE}
						icon={icon_font_edit_symbol}
						onClick={() => this.openMainListItemDialog(row)}
					/>
				</Tooltip>
			</div>
		);
	}
	
	render() {
		const {
			mainListData, mainList, mainListPagination, mainListSort, mainListFilter, mainSidebarShrank,
			toggleMainSidebarSizeAction
		} = this.props;
		const {
			therapistCodebook, officeLocationCodebook, medicalRecordCategoryCodebook, therapyTypeCodebook,
			therapySubtypeCodebook, therapySubSubtypeCodebook
		} = this.state;
		
		return this.renderLayout((
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')}`}>
				{
					this.hasTranslation('page_short_description') && this.t('page_short_description') ?
						<div className="simple-page-description">
							<Label content={this.t('page_short_description')} supportHtml={true} />
						</div>
						: null
				}

				<SimpleStaticSearch
					styleName="compact"
					className="main-search rows-8"
					collapsable={true}
					layout={SIMPLE_STATIC_SEARCH_LAYOUT.STACKED}
					buttonProps={{
						displayStyle: BUTTON_STYLE.DEFAULT
					}}
					options={[
						new SimpleStaticSearchOptionObject(
							'dateFrom',
							this.t('visitDateFromField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.DATE,
							{
								valueFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S,
								defaultValue: get(mainListFilter, 'dateFrom', this.getOption('initialDateFrom')),
							}
						),
						new SimpleStaticSearchOptionObject(
							'dateTo',
							this.t('visitDateToField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.DATE,
							{
								valueFormat: STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S,
								defaultValue: get(mainListFilter, 'dateTo', this.getOption('initialDateTo')),
							}
						),
						new SimpleStaticSearchOptionObject(
							'startTimeFrom',
							this.t('visitStartTimeFromField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.TIME,
							{
								size: 2,
								dayLimit: true
							}
						),
						new SimpleStaticSearchOptionObject(
							'startTimeTo',
							this.t('visitStartTimeToField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.TIME,
							{
								size: 2,
								dayLimit: true
							}
						),
						new SimpleStaticSearchOptionObject(
							'endTimeFrom',
							this.t('visitEndTimeFromField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.TIME,
							{
								size: 2,
								dayLimit: true
							}
						),
						new SimpleStaticSearchOptionObject(
							'endTimeTo',
							this.t('visitEndTimeToField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.TIME,
							{
								size: 2,
								dayLimit: true
							}
						),
						new SimpleStaticSearchOptionObject(
							'officeLocationId',
							this.t('officeLocationNameField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT,
							{
								isClearable: true,
								options: getArray(officeLocationCodebook).map(i => ({label: i.name, value: i.id})),
								onMenuOpen: () => {
									if (!isset(officeLocationCodebook)) this.loadPageCodebook('officeLocation').then();
								}
							}
						),
						new SimpleStaticSearchOptionObject(
							'visitNote',
							this.t('visitNoteField')
						),
						new SimpleStaticSearchOptionObject(
							'patientFirstName',
							this.t('patientFirstNameField')
							
						),
						new SimpleStaticSearchOptionObject(
							'patientLastName',
							this.t('patientLastNameField')
							
						),
						new SimpleStaticSearchOptionObject(
							'therapistId',
							this.t('therapistField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT,
							{
								isClearable: true,
								options: getArray(therapistCodebook).map(i => ({label: i.name, value: i.id})),
								isLoading: (therapistCodebook === null),
								onMenuOpen: () => {
									if (!isset(therapistCodebook)) this.loadPageCodebook('therapist').then();
								}
							}
						),
						new SimpleStaticSearchOptionObject(
							'medicalRecordCategoryId',
							this.t('medicalRecordCategoryField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT,
							{
								isClearable: true,
								options: getArray(medicalRecordCategoryCodebook).map(i => ({label: i.name, value: i.id})),
								onMenuOpen: () => {
									if (!isset(medicalRecordCategoryCodebook)) this.loadPageCodebook('medicalRecordCategory')
										.then();
								}
							}
						),
						new SimpleStaticSearchOptionObject(
							'therapyTypeId',
							this.t('therapyTypeField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT,
							{
								isClearable: true,
								options: getArray(therapyTypeCodebook).map(i => ({label: i.name, value: i.id})),
								onMenuOpen: () => {
									if (!isset(therapyTypeCodebook)) this.loadPageCodebook('therapyType').then();
								},
								onChange: v => {
									this.mainListFilterRef.handleValueChange('therapyTypeId', v);
									this.clearTherapySubtypes()
										.then(() => this.clearTherapySubSubtypes())
										.then(() => {if (v) this.loadPageCodebook('therapySubtype', v).then(); });
								}
							}
						),
						new SimpleStaticSearchOptionObject(
							'therapySubTypeId',
							this.t('therapySubtypeField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT,
							{
								isClearable: true,
								options: getArray(therapySubtypeCodebook).map(i => ({label: i.name, value: i.id})),
								isLoading: (therapySubtypeCodebook === null),
								onChange: v => {
									this.mainListFilterRef.handleValueChange('therapySubTypeId', v);
									this.clearTherapySubSubtypes()
										.then(() => {if (v) this.loadPageCodebook('therapySubSubtype', v).then(); })
								},
								isDisabled: !isset(therapySubtypeCodebook),
							}
						),
						new SimpleStaticSearchOptionObject(
							'therapySubSubTypeId',
							this.t('therapySubSubtypeField'),
							SIMPLE_STATIC_SEARCH_DISPLAY_TYPE.SELECT,
							{
								isClearable: true,
								options: getArray(therapySubSubtypeCodebook).map(i => ({label: i.name, value: i.id})),
								isLoading: (therapySubSubtypeCodebook === null),
								isDisabled: !isset(therapySubSubtypeCodebook),
							}
						),
					]}
					value={mainListFilter}
					title={(<Label icon="search" content={this.t('page_title')} />)}
					applied={mainListData !== null}
					enableToolbar={true}
					enableResetButton={false}
					onChange={this.filterMainList}
					onRemove={this.removeMainListFilter}
					ref={node => { this.mainListFilterRef = node; }}
				/>

				{mainListData !== null ?
					<DataTable
						id="main-page-table"
						className="standard sticky-last-column"
						responsive={true}
						responsiveBreakpointName="bp-l"
						columns={[
							{
								sortName: 'visitDateTime',
								label: this.t('visitDateField'),
								dataType: DATA_TABLE_CELL_TYPE.ANY,
								dataTypeOptions: new DataTableCellAnyTypeOptionsDataObject({
									content: row => (
										<>
											<DateLabel 
												element="span"
												elementProps={{className: 'no-wrap'}}
												inputDate={get(row, 'visitDate')} 
												outputFormat="EEEE" 
											/> <DateLabel
												element="span"
												elementProps={{className: 'no-wrap'}}
												inputDate={get(row, 'visitDate')}
												outputFormat={LOCALE_DATE_FORMAT_NAME.STANDARD}
											/>
										</>
									),
								}),
							},
							{								
								label: this.t('visitTimeField'),
								dataType: DATA_TABLE_CELL_TYPE.ANY,
								dataTypeOptions: new DataTableCellAnyTypeOptionsDataObject({
									content: row => (
										!!row.visitTimeFrom || !!row.visitTimeTo ?
											<Label
												element="span"
												elementProps={{className: 'no-wrap'}}
												content={`${row.visitTimeFrom} ⇒ ${row.visitTimeTo}`}
											/> : '—'
									),
								}),
								width: 1,
								
							},							
							{
								name: 'visitNote',
								label: this.t('visitNoteField'),
								emptyAsDefault: true,
								defaultValue: '—',
								minWidth: 150,
							},
							{
								label: this.t('therapyPatientFullNameField'),
								dataType: DATA_TABLE_CELL_TYPE.ANY,
								dataTypeOptions: new DataTableCellAnyTypeOptionsDataObject({
									content: listItem => (
										<Link to={`${patientRecordRouterPath}/item/${listItem.therapyPatientId}`}>
											<Label 
												content={`${listItem.therapyPatientFirstName} ${listItem.therapyPatientLastName}`}
											/>
										</Link>	
									)
								}),
								minWidth: 150,
								stopPropagation: true
							},
							{
								name: 'officeLocationName',
								label: this.t('officeLocationNameField'),
								minWidth: 150,
								emptyAsDefault: true,
								defaultValue: '—',
							},
							{
								name: 'therapist',
								label: this.t('therapistField'),
								minWidth: 150,
								emptyAsDefault: true,
								defaultValue: '—',
							},

							{
								dataType: DATA_TABLE_CELL_TYPE.ANY,
								dataTypeOptions: new DataTableCellAnyTypeOptionsDataObject({
									standardWrapper: false,
									content: this.renderActions,
								}),
								stopPropagation: true,
								width: 1,
							}
						]}
						data={mainList}
						paginationType={PAGINATION_TYPE.DYNAMIC}
						onSortByColumn={this.sortMainList}
						onPaginationClick={this.loadMainListPage}
						{...mainListPagination}
						{...mainListSort}
					/>
					: this.getValue('filterLoading') ?
						<div className="page-notice-wrapper no-select">
							<div className="page-notice-title-icon icon">
								<Spinner size={60} weight={3} />
							</div>
						</div>
					:
						<div className="page-notice-wrapper no-select">
							<Icon symbol="hand-drawn-up" symbolPrefix="icofont-" className="page-notice-title-icon" />
							<Label
								element="p"
								elementProps={{className: 'page-notice-title bold'}}
								content={this.t('search_notice_title')}
							/>
							<Label
								element="p"
								elementProps={{className: 'page-notice'}}
								content={this.t('search_notice_description')}
							/>
						</div>
				}
			</div>
		), undefined, undefined, {
			app: appConfig,
			mainSidebarShrank,
			toggleMainSidebarSizeAction,
		});
	}
}

export * from "./config";
export default connect(mapStateToProps, allActions)(SearchVisitsPage);