import {actionCreators} from './reducer';
import {setStorageValue} from "Core/storage";
import {locale_cookie_group, locale_storage_path, locale_storage_type} from "Config/app";
import CookieConsent from "Core/dataProtection/cookieConsent";
import {CookieData} from "Core/dataProtection/objects/cookie";
import {hideLoading, showPageLoading} from "Core/helpers/loading";

/**
 * Set locale for the app
 * @description Main language change action for the app.
 * NOTE: This will automatically trigger loading of the locale language file translating the whole app (see I18n class).
 *
 * @param {LocaleObj} locale - Locale to set.
 */
export const setLocaleAction = locale => dispatch => {
	const loading = showPageLoading(true, true);
	
	// @note Timeout is added because setting locale will trigger re-render of many components.
	setTimeout(() => {
		// Get locale cookie settings
		const localeCookie = new CookieData(locale_cookie_group, locale_storage_path, locale_storage_type);

		dispatch(actionCreators.setLocale(locale));

		// Save current locale code in a local storage
		if (CookieConsent.isAllowed(localeCookie)) setStorageValue(locale_storage_path, locale.locale, locale_storage_type);

		setTimeout(() => {
			hideLoading(loading);
		});
	});
};