import styles from './index.module.css';
import './index.css';

import React from 'react';
import DataComponent, {executeComponentCallback} from 'Core/components/DataComponent';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getGlobalActions} from 'Core/helpers/redux';
import * as actions from "./actions";
import {selectors} from 'Core/store/reducers';
import PatientQuickSearch, {PATIENT_QUICK_SEARCH_CREATE_POSITION} from 'Components/advanced/PatientQuickSearch';
import Label from 'Core/components/display/Label';
import PatientDetails from 'Components/advanced/PatientRecord/components/PatientDetails';
import Icon from 'Core/components/display/Icon';
import PatientStatistics from 'Components/advanced/PatientRecord/components/PatientStatistics';
import {
	PATIENT_RECORD_DETAILS_TAB, PATIENT_RECORD_DETAILS_TAB_ICON,
	PATIENT_RECORD_DETAILS_TABS,
	PATIENT_RECORD_DISPLAY_TYPE,
	PATIENT_RECORD_DISPLAY_TYPES
} from 'Components/advanced/PatientRecord/const';
import Therapy from 'Components/advanced/PatientRecord/components/Therapy';
import {reducerStoreKey as patientQuickSearchReducerStoreKey} from 'Components/advanced/PatientQuickSearch/reducer';
import {reducerStoreKey} from './reducer';
import {getString, isset, trimArray} from 'Core/helpers/data';
import * as dataMap from './dataMap';
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from 'Core/components/display/Button';
import TherapyDialog from 'Components/dialogs/TherapyDialog';
import PrescribedTherapies from 'Components/advanced/PatientRecord/components/PrescribedTherapies';
import Visits from 'Components/advanced/PatientRecord/components/Visits';
import VisitDialog from 'Components/dialogs/VisitDialog';
import Appointments from 'Components/advanced/PatientRecord/components/Appointments';
import Payments from 'Components/advanced/PatientRecord/components/Payments';
import AppointmentDialog from "Components/dialogs/AppointmentDialog";
import PaymentDialog from "Components/dialogs/PaymentDialog";
import ExpertReports from "Components/advanced/PatientRecord/components/ExpertReports";
import ExpertReportDialog from "Components/dialogs/ExpertReportDialog";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	isMobileBreakpoint: selectors.breakpoint.isMobileBreakpoint(state),
	selectedPatient: selectors[patientQuickSearchReducerStoreKey].getSelectedPatient(state),
	patientRecord: selectors[reducerStoreKey].getPatientRecord(state),
	selectedPatientTherapy: selectors[reducerStoreKey].getSelectedPatientTherapy(state),
});

class PatientRecord extends DataComponent {
	// Refs
	therapyRef = null;
	prescribedTherapiesRef = null;
	visitsRef = null;
	appointmentsRef = null;
	paymentsRef = null;
	expertReportsRef = null;
	
	constructor(props) {
		super(props, {
			data: {
				displayType: PATIENT_RECORD_DISPLAY_TYPE.SELECTED_THERAPY,
				activeDetailsTab: PATIENT_RECORD_DETAILS_TAB.PRESCRIBED_THERAPIES,
				returnToPrescribedTherapies: false,
			}
		}, {
			translationPath: 'PatientRecord',
			domPrefix: 'patient-record-component',
			disableLoad: true,
		});
		
		// Data methods
		this.calculateTherapyId = this.calculateTherapyId.bind(this);

		// Dialog methods
		this.openCreateTherapyDialog = this.openCreateTherapyDialog.bind(this);
		this.openCreateVisitDialog = this.openCreateVisitDialog.bind(this);
		this.openCreateAppointmentDialog = this.openCreateAppointmentDialog.bind(this);
		this.openCreatePaymentDialog = this.openCreatePaymentDialog.bind(this);
		this.openCreateExpertReportDialog = this.openCreateExpertReportDialog.bind(this);

		// Register keyboard shortcut events
		this.registerEventListener('keydown', e => {
			if (e.altKey) {
				// Prevent the default browser behaviour
				e.preventDefault();

				switch (e.key) {
					// Open the dialog to create a new visit (Alt + 1)
					case '1': document.getElementById('shortcut-alt-1').click(); break;

					// Open the dialog to create a new appointment (Alt + 2)
					case '2': document.getElementById('shortcut-alt-2').click(); break;

					// Open the dialog to log a payment (Alt + 3)
					case '3': document.getElementById('shortcut-alt-3').click(); break;

					// Open the dialog to log a refund (Alt + 4)
					case '4': document.getElementById('shortcut-alt-4').click(); break;

					// Open the dialog to create a new expert report (Alt + 5)
					case '5': document.getElementById('shortcut-alt-5').click(); break;
					
					// no default
				}
			}
		});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		return super.componentDidUpdate(prevProps, prevState, snapshot)
			// Handle selected patient change
			.then(async () => {
				const {selectedPatient, patientRecord, loadPatientRecordAction, clearPatientRecordAction} = this.props;
				const selectedPatientId = getString(selectedPatient, 'id');
				
				if (selectedPatientId !== getString(prevProps, 'selectedPatient.id')) {
					executeComponentCallback(this.props.onPatientChange, selectedPatient);
					
					// Load patient record if selected patient's ID is different then the one in the loaded patient record
					// @note Checking for already loaded patient record is done because it can be loaded by the page using 
					// this component if it detects a new patient ID in the URL (for example opening the page for the first 
					// time with the patient ID in the URL).
					if (selectedPatientId) {
						if (getString(patientRecord, 'id') !== selectedPatientId) {
							await this.executeAbortableAction(loadPatientRecordAction, selectedPatientId);
						}
					}
					// Clear patient record if selected patient ID is empty
					else {
						clearPatientRecordAction();
					}
					
					// Reset local state data
					await this.clearData();
				}
				
				return this.state;
			});
	}

	componentWillUnmount() {
		super.componentWillUnmount();
		
		const {clearPatientRecordAction} = this.props;
		clearPatientRecordAction();
	}

	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }
	

	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Calculate therapy type ID based on the display type
	 *
	 * @param {string} [therapyId] - Reference therapy type ID. If not specified, selected therapy ID will be used.
	 * @return {string|'*'|null}
	 */
	calculateTherapyId(therapyId) {
		const displayType = this.getValue('displayType');
		const id = (therapyId ? therapyId : getString(this.props, 'selectedPatientTherapy.id'));

		switch (displayType) {
			case PATIENT_RECORD_DISPLAY_TYPE.SELECTED_THERAPY: return id;
			default: return null;
		}
	}
	
	
	// Dialog methods ---------------------------------------------------------------------------------------------------
	/**
	 * Open the dialog to create a new therapy for the selected patient
	 */
	openCreateTherapyDialog() {
		const {patientRecord, openDialogAction, showSuccessMessageAction} = this.props;	
		
		const dialogGUIID = openDialogAction('', TherapyDialog, {
			isNew: true,
			patientId: getString(patientRecord, 'id'),
			dialogId: 'create-therapy-dialog',
			onSave: therapyId => {
				showSuccessMessageAction(this.t('create_therapy_success_msg'));
				
				if (this.therapyRef) {
					this.therapyRef.reloadPatientTherapyList()
						.then(() => this.therapyRef.selectTherapyById(therapyId));
				}
			},
		}, {
			id: 'create-therapy-dialog',
			className: 'bordered-title',
			closeOnEscape: true,
			closeOnClickOutside: false,
			hideCloseBtn: true,
			maxWidth: 1000
		});
		this.setOption(
			'dialogsToCloseOnUnmount',
			trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
		);
	}

	/**
	 * Open the dialog to create a new visit for the selected patient
	 */
	openCreateVisitDialog() {
		const {patientRecord, openDialogAction} = this.props;

		const dialogGUIID = openDialogAction('', VisitDialog, {
			isNew: true,
			patientId: getString(patientRecord, 'id'),
			therapyId: this.calculateTherapyId(),
			onSave: () => {
				// Reload visits if visits tab is active
				const activeDetailsTab = this.getValue('activeDetailsTab');
				if (activeDetailsTab === PATIENT_RECORD_DETAILS_TAB.VISITS) {
					if (this.visitsRef) this.visitsRef.reloadMainList();
				}
			},
		}, {
			id: 'create-visit-dialog',
			className: 'bordered-title',
			closeOnEscape: true,
			closeOnClickOutside: false,
			hideCloseBtn: true,
			maxWidth: 800
		});
		this.setOption(
			'dialogsToCloseOnUnmount',
			trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
		);
	}

	/**
	 * Open the dialog to create a new appointment for the selected patient
	 */
	openCreateAppointmentDialog() {
		const {patientRecord, openDialogAction} = this.props;

		const dialogGUIID = openDialogAction('', AppointmentDialog, {
			isNew: true,
			patientId: getString(patientRecord, 'id'),
			therapyId: this.calculateTherapyId(),
			onSave: () => {
				// Reload appointments if appointments tab is active
				const activeDetailsTab = this.getValue('activeDetailsTab');
				if (activeDetailsTab === PATIENT_RECORD_DETAILS_TAB.APPOINTMENTS) {
					if (this.appointmentsRef) this.appointmentsRef.reloadMainList();
				}
			},
		}, {
			id: 'create-appointment-dialog',
			className: 'bordered-title',
			closeOnEscape: true,
			closeOnClickOutside: false,
			hideCloseBtn: true,
			maxWidth: 800
		});
		this.setOption(
			'dialogsToCloseOnUnmount',
			trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
		);	
	}


	/**
	 * Open the dialog to create a new payment or refund for the selected patient
	 * @param {boolean} [refund=false] - Flag that specifies if payment is a refund.
	 */
	openCreatePaymentDialog(refund = false) {
		const {patientRecord, openDialogAction} = this.props;

		const dialogGUIID = openDialogAction('', PaymentDialog, {
			refund,
			patientId: getString(patientRecord, 'id'),
			therapyId: this.calculateTherapyId(),
			onSave: () => {
				// Reload payments if payments tab is active
				const activeDetailsTab = this.getValue('activeDetailsTab');
				if (activeDetailsTab === PATIENT_RECORD_DETAILS_TAB.PAYMENTS) {
					if (this.paymentsRef) this.paymentsRef.reloadMainList();
				}
			},
		}, {
			id: 'create-payment-dialog',
			className: 'bordered-title',
			closeOnEscape: true,
			closeOnClickOutside: false,
			hideCloseBtn: true,
			maxWidth: 800
		});
		this.setOption(
			'dialogsToCloseOnUnmount',
			trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
		);
	}

	/**
	 * Open the dialog to create a new expert report for the selected patient
	 */
	openCreateExpertReportDialog() {
		const {patientRecord, openDialogAction} = this.props;

		const dialogGUIID = openDialogAction('', ExpertReportDialog, {
			isNew: true,
			patientId: getString(patientRecord, 'id'),
			therapyId: this.calculateTherapyId(),
			onSave: () => {
				// Reload expert reports if expert reports tab is active
				const activeDetailsTab = this.getValue('activeDetailsTab');
				if (activeDetailsTab === PATIENT_RECORD_DETAILS_TAB.EXPERT_REPORTS) {
					if (this.expertReportsRef) this.expertReportsRef.reloadMainList();
				}
			},
		}, {
			id: 'create-expert-report-dialog',
			className: 'bordered-title',
			closeOnEscape: true,
			closeOnClickOutside: false,
			hideCloseBtn: true,
			maxWidth: 1080
		});
		this.setOption(
			'dialogsToCloseOnUnmount',
			trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
		);
	}
	

	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {isMobileBreakpoint, patientRecord, selectedPatientTherapy} = this.props;
		const displayType = this.getValue('displayType');
		const activeDetailsTab = this.getValue('activeDetailsTab');
		
		return (
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')} ${styles['wrapper']}`}>
				<PatientQuickSearch
					className="page-title"
					label={this.t('patient_record')}
					autoFocus={!isMobileBreakpoint}
					isClearable={true}
					showCreate={true}
					createButtonPosition={PATIENT_QUICK_SEARCH_CREATE_POSITION.LABEL}
					renderAdditionalButtons={() => (
						<div className="action-button">
							<Button
								className="no-wrap"
								big={true}
								icon="first-aid"
								iconProps={{symbolPrefix: 'icofont-'}}
								label={this.t('create_therapy')}
								displayStyle={BUTTON_STYLE.ACTION}
								displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
								onClick={e => {
									e.stopPropagation();
									e.nativeEvent.stopImmediatePropagation();
									this.openCreateTherapyDialog();
								}}
							/>
						</div>
					)}
					onCreate={rawData => executeComponentCallback(this.props.onPatientCreated, dataMap.input(rawData))}
				/>
				
				<div className={`content-wrapper ${styles['contentWrapper']}`}>
					{
						!isset(patientRecord) ?
							<div className="page-notice-wrapper no-select">
								<Icon symbol="times-circle" className="page-notice-title-icon error-color" />
								<Label
									element="p"
									elementProps={{className: 'page-notice-title bold'}}
									content={this.t('invalid_item_notice_title')}
								/>
								<Label
									element="p"
									elementProps={{className: 'page-notice'}}
									content={this.t('invalid_item_notice_description')}
								/>
							</div>
						: patientRecord === null ?
							<div className="page-notice-wrapper no-select">
								<Icon symbol="hand-drawn-up" symbolPrefix="icofont-" className="page-notice-title-icon" />
								<Label
									element="p"
									elementProps={{className: 'page-notice-title bold'}}
									content={this.t('search_notice_title')}
								/>
								<Label
									element="p"
									elementProps={{className: 'page-notice'}}
									content={this.t('search_notice_description')}
								/>
							</div>
						:
							<div className={`card ${styles['patientRecordWrapper']}`}>
								<div className={`card-content patient-record ${styles['patientRecord']}`}>
									<PatientDetails
										className={`patient-details-section ${styles['patientDetails']}`}
										data={patientRecord}
										onSave={rawData => 
											executeComponentCallback(this.props.onPatientUpdate, dataMap.input(rawData))
										}
										onDelete={patient => executeComponentCallback(this.props.onPatientDelete, patient)}
									/>
									
									<div className={`main-content ${styles['mainContent']}`}>
										<PatientStatistics
											className="patient-statistics-section"
											data={patientRecord}
										/>
	
										<div className="display-type-section tab-section">
											<div className={`section-content`}>
												<div className="popup-tabs no-select">
													{PATIENT_RECORD_DISPLAY_TYPES.map(dt =>
														<div
															key={dt}
															className={`popup-tab${displayType === dt ? ' active' : ''}`}
															onClick={() => this.setValue('displayType', dt)
																.then(() => {
																	const activeTab = this.getValue('activeDetailsTab');
																	const toPrescribedTherapies = this.getValue(
																		'returnToPrescribedTherapies'
																	);
																	
																	if (dt === PATIENT_RECORD_DISPLAY_TYPE.SELECTED_THERAPY) {
																		if (toPrescribedTherapies) {
																			return this.setValue(
																				'activeDetailsTab',
																				PATIENT_RECORD_DETAILS_TAB.PRESCRIBED_THERAPIES
																			);
																		}
																	} else {
																		if (activeTab === 
																				PATIENT_RECORD_DETAILS_TAB.PRESCRIBED_THERAPIES
																		) {
																			return this.setValue(
																				'activeDetailsTab', PATIENT_RECORD_DETAILS_TAB.VISITS
																			)
																				.then(() => this.setValue(
																					'returnToPrescribedTherapies', true
																				));
																		}
																	}
																	return Promise.resolve();
																})
															}
														>
															<Label content={this.tt(dt, 'display_types')} />
														</div>
													)}
												</div>
											</div>
										</div>
	
										<div className="display-type-section-content tab-content-section">
											<div className={`actions-section ${styles['actionsSection']}`}>
												<div
													id="shortcut-alt-1"
													className={
														`card action-card-small no-select ${styles['actionBtn']}` +
														(displayType !== PATIENT_RECORD_DISPLAY_TYPE.SELECTED_THERAPY ? ' error' : '')
													}
													onClick={() => {
														if (!(
															displayType === PATIENT_RECORD_DISPLAY_TYPE.SELECTED_THERAPY &&
															!selectedPatientTherapy
														)) {
															this.openCreateVisitDialog();
														}
													}}
													disabled={(
														displayType === PATIENT_RECORD_DISPLAY_TYPE.SELECTED_THERAPY &&
														!selectedPatientTherapy
													)}
												>
													<div className="card-content">
														<small className="shortcut">
															<Label content={this.t('log_visit_shortcut')}/>
														</small>
														<Icon {...PATIENT_RECORD_DETAILS_TAB_ICON[PATIENT_RECORD_DETAILS_TAB.VISITS]} />
														<Label element="p" content={this.t('log_visit_btn')}/>
														<div className="description">
															<Label content={
																displayType === PATIENT_RECORD_DISPLAY_TYPE.SELECTED_THERAPY ?
																	this.t('log_visit_desc_single') :
																	this.t('log_visit_desc_all')
															} supportHtml={true}/>
														</div>
													</div>
												</div>
												<div
													id="shortcut-alt-2"
													className={
														`card action-card-small no-select ${styles['actionBtn']}` +
														(displayType !== PATIENT_RECORD_DISPLAY_TYPE.SELECTED_THERAPY ? ' error' : '')
													}
													onClick={() => {
														if (!(
															displayType === PATIENT_RECORD_DISPLAY_TYPE.SELECTED_THERAPY &&
															!selectedPatientTherapy
														)) {
															this.openCreateAppointmentDialog();
														}
													}}
													disabled={(
														displayType === PATIENT_RECORD_DISPLAY_TYPE.SELECTED_THERAPY &&
														!selectedPatientTherapy
													)}
												>
													<div className="card-content">
														<small className="shortcut">
															<Label content={this.t('schedule_appointment_shortcut')}/>
														</small>
														<Icon {...PATIENT_RECORD_DETAILS_TAB_ICON[PATIENT_RECORD_DETAILS_TAB.APPOINTMENTS]} />
														<Label element="p" content={this.t('schedule_appointment_btn')}/>
														<div className="description">
															<Label content={
																displayType === PATIENT_RECORD_DISPLAY_TYPE.SELECTED_THERAPY ?
																	this.t('schedule_appointment_desc_single') :
																	this.t('schedule_appointment_desc_all')
															} supportHtml={true}/>
														</div>
													</div>
												</div>
												<div
													id="shortcut-alt-3"
													className={
														`card action-card-small no-select ${styles['actionBtn']}` +
														(displayType !== PATIENT_RECORD_DISPLAY_TYPE.SELECTED_THERAPY ? ' error' : '')
													}
													onClick={() => {
														if (!(
															displayType === PATIENT_RECORD_DISPLAY_TYPE.SELECTED_THERAPY &&
															!selectedPatientTherapy
														)) {
															this.openCreatePaymentDialog();
														}
													}}
													disabled={(
														displayType === PATIENT_RECORD_DISPLAY_TYPE.SELECTED_THERAPY &&
														!selectedPatientTherapy
													)}
												>
													<div className="card-content">
														<small className="shortcut">
															<Label content={this.t('log_payment_shortcut')}/>
														</small>
														<Icon {...PATIENT_RECORD_DETAILS_TAB_ICON[PATIENT_RECORD_DETAILS_TAB.PAYMENTS]} />
														<Label element="p" content={this.t('log_payment_btn')}/>
														<div className="description">
															<Label content={
																displayType === PATIENT_RECORD_DISPLAY_TYPE.SELECTED_THERAPY ?
																	this.t('log_payment_desc_single') :
																	this.t('log_payment_desc_all')
															} supportHtml={true}/>
														</div>
													</div>
												</div>
												<div
													id="shortcut-alt-4"
													className={
														`card action-card-small no-select ${styles['actionBtn']}` +
														(displayType !== PATIENT_RECORD_DISPLAY_TYPE.SELECTED_THERAPY ? ' error' : '')
													}
													onClick={() => {
														if (!(
															displayType === PATIENT_RECORD_DISPLAY_TYPE.SELECTED_THERAPY &&
															!selectedPatientTherapy
														)) {
															this.openCreatePaymentDialog(true);
														}
													}}
													disabled={(
														displayType === PATIENT_RECORD_DISPLAY_TYPE.SELECTED_THERAPY &&
														!selectedPatientTherapy
													)}
												>
													<div className="card-content">
														<small className="shortcut">
															<Label content={this.t('log_refund_shortcut')}/>
														</small>
														<Icon {...PATIENT_RECORD_DETAILS_TAB_ICON[PATIENT_RECORD_DETAILS_TAB.PAYMENTS]} />
														<Label element="p" content={this.t('log_refund_btn')}/>
														<div className="description">
															<Label content={
																displayType === PATIENT_RECORD_DISPLAY_TYPE.SELECTED_THERAPY ?
																	this.t('log_refund_desc_single') :
																	this.t('log_refund_desc_all')
															} supportHtml={true}/>
														</div>
													</div>
												</div>
												<div
													id="shortcut-alt-5"
													className={
														`card action-card-small no-select ${styles['actionBtn']}` +
														(displayType !== PATIENT_RECORD_DISPLAY_TYPE.SELECTED_THERAPY ? ' error' : '')
													}
													onClick={() => {
														if (!(
															displayType === PATIENT_RECORD_DISPLAY_TYPE.SELECTED_THERAPY &&
															!selectedPatientTherapy
														)) {
															this.openCreateExpertReportDialog();
														}
													}}
													disabled={(
														displayType === PATIENT_RECORD_DISPLAY_TYPE.SELECTED_THERAPY &&
														!selectedPatientTherapy
													)}
												>
													<div className="card-content">
														<small className="shortcut">
															<Label content={this.t('new_report_shortcut')}/>
														</small>
														<Icon {...PATIENT_RECORD_DETAILS_TAB_ICON[PATIENT_RECORD_DETAILS_TAB.EXPERT_REPORTS]} />
														<Label element="p" content={this.t('new_report_btn')}/>
														<div className="description">
															<Label content={
																displayType === PATIENT_RECORD_DISPLAY_TYPE.SELECTED_THERAPY ?
																	this.t('new_report_desc_single') :
																	this.t('new_report_desc_all')
															} supportHtml={true}/>
														</div>
													</div>
												</div>
											</div>

											<Therapy
												className={
													'therapy-section' +
													(displayType !== PATIENT_RECORD_DISPLAY_TYPE.SELECTED_THERAPY ? ' no-display' : '')
												}
												onChange={() => {
													if (this.prescribedTherapiesRef) this.prescribedTherapiesRef.reload();
												}}
												ref={node => {
													this.therapyRef = node;
												}}
											/>

											<div
												className={
													"details-section tab-section" +
													(
														displayType === PATIENT_RECORD_DISPLAY_TYPE.SELECTED_THERAPY &&
														!getString(selectedPatientTherapy, 'id') ?
															' no-display' : ''
													)
												}
											>
												<div className={`section-title ${styles['sectionTitle']}`}>
													<Label
														element="div"
														elementProps={{className: styles['detailsSectionTitle']}}
														icon="list"
														content={this.tt(displayType, 'details_section_title')}
													/>
												</div>

												<div className={`section-content ${styles['sectionContent']}`}>
													<div className="popup-tabs no-select">
														{PATIENT_RECORD_DETAILS_TABS.map(dt =>
															dt !== PATIENT_RECORD_DETAILS_TAB.PRESCRIBED_THERAPIES ||
															displayType === PATIENT_RECORD_DISPLAY_TYPE.SELECTED_THERAPY ?
																<div
																	key={dt}
																	className={`popup-tab${activeDetailsTab === dt ? ' active' : ''}`}
																	onClick={() => this.setValue('activeDetailsTab', dt)
																		.then(() => this.setValue('returnToPrescribedTherapies', false))
																	}
																>
																	<Icon {...PATIENT_RECORD_DETAILS_TAB_ICON[dt]} />
																	<Label 
																		element="span" 
																		elementProps={{className: 'tab-label'}} 
																		content={this.tt(dt, 'details_tab')} 
																	/>
																</div>
																: null
														)}
													</div>
												</div>

												
												<div className="tab-content-section">
													<PrescribedTherapies
														active={activeDetailsTab === PATIENT_RECORD_DETAILS_TAB.PRESCRIBED_THERAPIES}
														ref={node => { this.prescribedTherapiesRef = node; }}
													/>

													<Visits
														active={activeDetailsTab === PATIENT_RECORD_DETAILS_TAB.VISITS}
														displayType={displayType}
														ref={node => { this.visitsRef = node; }}
													/>

													<Appointments
														active={activeDetailsTab === PATIENT_RECORD_DETAILS_TAB.APPOINTMENTS}
														displayType={displayType}
														ref={node => { this.appointmentsRef = node; }}
													/>
													
													<Payments
														active={activeDetailsTab === PATIENT_RECORD_DETAILS_TAB.PAYMENTS}
														displayType={displayType}
														ref={node => { this.paymentsRef = node; }}
													/>

													<ExpertReports
														active={activeDetailsTab === PATIENT_RECORD_DETAILS_TAB.EXPERT_REPORTS}
														displayType={displayType}
														ref={node => { this.expertReportsRef = node; }}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
					}
				</div>
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
PatientRecord.propTypes = {
	// Event triggered when selected patient has been changed
	// @note Event will trigger after selected patient was changed in the Redux store.
	// @param {PatientSelectOptionDataObject} selectedPatient
	onPatientChange: PropTypes.func,
	// Event that will be triggered after the patient was successfully created
	// @param {PatientRecordDataObject} newPatientData
	onPatientCreated: PropTypes.func,
	// Event that will be triggered after the patient was successfully updated
	// @param {PatientRecordDataObject} updatedPatientData
	onPatientUpdate: PropTypes.func,
	// Event that will be triggered after the patient was successfully deleted
	// @param {PatientRecordDataObject} patient - Patient data.
	onPatientDelete: PropTypes.func,
};

/**
 * Define component default values for own props
 */
PatientRecord.defaultProps = {
	
};

export * from './components/PatientDetails';
export * from './components/PatientStatistics';
export * from './components/Therapy';
export * as patientRecordActions from './actions';
export default connect(mapStateToProps, getGlobalActions(actions))(PatientRecord);