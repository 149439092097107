import {LOCALE_DATE_FORMAT_NAME, LOCALE_TIME_FORMAT_NAME} from "../../../const/locale";

export const DATETIME_LOCALE_INPUT_FORMAT_SHORT = [
	LOCALE_DATE_FORMAT_NAME.SHORT, ' ', LOCALE_TIME_FORMAT_NAME.SHORT
];
export const DATETIME_LOCALE_INPUT_FORMAT_STANDARD = [
	LOCALE_DATE_FORMAT_NAME.SHORT, ' ', LOCALE_TIME_FORMAT_NAME.STANDARD
];
export const DATETIME_LOCALE_INPUT_FORMAT = {
	SHORT: DATETIME_LOCALE_INPUT_FORMAT_SHORT,
	STANDARD: DATETIME_LOCALE_INPUT_FORMAT_STANDARD
}
export const DATETIME_LOCALE_INPUT_FORMATS = [
	DATETIME_LOCALE_INPUT_FORMAT_SHORT, DATETIME_LOCALE_INPUT_FORMAT_STANDARD
];