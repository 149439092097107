/**
 * Error class used in the async component mount method (asyncComponentDidMount) to specify if async mount failed
 * @description When async component mount method is called, and it fails for some reason, for example when it makes an 
 * IO request that has been aborted, async component mount method should throw this error. If component being mounted 
 * has multiple mount requests (it was mounted multiple times in quick succession) and this error was thrown, another
 * async component mount method wil be called. Otherwise, all other async component mount methods will be skipped.
 */
class AsyncMountError {
	/**
	 * @param {string} [message='Error while executing async component mount method!'] - Error message.
	 */
	constructor(message = 'Error while executing async component mount method!') {
		this.message = message;
	}
}

export default AsyncMountError;