/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * 
 * @type {string}
 */
export const reducerStoreKey = 'i18n';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@i18n/reset',
	SET_LOCALE: '@i18n/set_locale',
	SET_TRANSLATION: '@i18n/set_translation'
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),
	setLocale: locale => ({type: REDUCER_TYPES.SET_LOCALE, locale }),
	setTranslation: translation => ({type: REDUCER_TYPES.SET_TRANSLATION, translation })
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {{[locale]: LocaleObj, [translation]: object}}
 */
export const initialState = {
	locale: undefined,
	translation: undefined,
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET:
			return { ...initialState };
	
		case REDUCER_TYPES.SET_LOCALE:
			return { ...state, locale: action.locale };
	
		case REDUCER_TYPES.SET_TRANSLATION:
			return { ...state, translation: action.translation };
	
		default:
			return state;
	}
};

// Selectors
export const selectors = {
	getLocale: state => state[reducerStoreKey].locale,
	getLocaleCode: state => state[reducerStoreKey].locale?.locale,
	getLocaleFile: state => state[reducerStoreKey].locale?.fileName,
	getLanguageName: state => state[reducerStoreKey].locale?.name,
	getLanguageEnglishName: state => state[reducerStoreKey].locale?.engName,
	getCountryCode: state => state[reducerStoreKey].locale?.countryCode,
	getLanguageCode: (state, long = false) => (
		long ? state[reducerStoreKey].locale?.code3 : state[reducerStoreKey].locale?.code3
	),
	getTranslation: state => state[reducerStoreKey].translation
};

export default reducer;