import {STATISTICS_CHART_TYPE, STATISTICS_TYPE} from "Components/advanced/Statistics/const";

/** @typedef {string} TherapistsStatisticsScope */
export const THERAPISTS_STATISTICS_SCOPE_DAILY = 'DAILY';
export const THERAPISTS_STATISTICS_SCOPE_MONTHLY = 'MONTHLY';
export const THERAPISTS_STATISTICS_SCOPE_YEARLY = 'YEARLY';
export const THERAPISTS_STATISTICS_SCOPE_THERAPISTS = 'THERAPISTS';
/** @enum {TherapistsStatisticsScope} */
export const THERAPISTS_STATISTICS_SCOPE = {
	DAILY: THERAPISTS_STATISTICS_SCOPE_DAILY,
	MONTHLY: THERAPISTS_STATISTICS_SCOPE_MONTHLY,
	YEARLY: THERAPISTS_STATISTICS_SCOPE_YEARLY,
	THERAPISTS: THERAPISTS_STATISTICS_SCOPE_THERAPISTS,
}
/** @type {TherapistsStatisticsScope[]} */
export const THERAPISTS_STATISTICS_SCOPES = [
	THERAPISTS_STATISTICS_SCOPE_DAILY,
	THERAPISTS_STATISTICS_SCOPE_MONTHLY,
	THERAPISTS_STATISTICS_SCOPE_YEARLY,
	THERAPISTS_STATISTICS_SCOPE_THERAPISTS,
];

/**
 * Map of therapist statistics types for each scope
 * @type {Object<TherapistsStatisticsScope, StatisticsType[]>}
 */
export const THERAPISTS_STATISTICS_TYPES_BY_SCOPE = {
	[THERAPISTS_STATISTICS_SCOPE.DAILY]: [
		STATISTICS_TYPE.THERAPISTS_DAILY_VISITS,
		STATISTICS_TYPE.THERAPISTS_DAILY_DIFFERENT_PATIENTS,
	],
	[THERAPISTS_STATISTICS_SCOPE.MONTHLY]: [
		STATISTICS_TYPE.THERAPISTS_MONTHLY_VISITS,
		STATISTICS_TYPE.THERAPISTS_MONTHLY_DIFFERENT_PATIENTS,
	],
	[THERAPISTS_STATISTICS_SCOPE.YEARLY]: [
		STATISTICS_TYPE.THERAPISTS_YEARLY_VISITS,
		STATISTICS_TYPE.THERAPISTS_YEARLY_DIFFERENT_PATIENTS,
	],
	[THERAPISTS_STATISTICS_SCOPE.THERAPISTS]: [
		STATISTICS_TYPE.THERAPISTS_THERAPIST_VISITS,
		STATISTICS_TYPE.THERAPISTS_THERAPIST_PATIENTS,
	],
};

/**
 * Map of therapist statistics chart types for each statistics type
 * @type {Object<StatisticsType, StatisticsChartType>}
 */
export const THERAPISTS_CHART_TYPE_BY_STATISTICS_TYPE = {
	[STATISTICS_TYPE.THERAPISTS_DAILY_VISITS]: STATISTICS_CHART_TYPE.LINE,
	[STATISTICS_TYPE.THERAPISTS_DAILY_DIFFERENT_PATIENTS]: STATISTICS_CHART_TYPE.LINE,
	[STATISTICS_TYPE.THERAPISTS_MONTHLY_VISITS]: STATISTICS_CHART_TYPE.LINE,
	[STATISTICS_TYPE.THERAPISTS_MONTHLY_DIFFERENT_PATIENTS]: STATISTICS_CHART_TYPE.LINE,
	[STATISTICS_TYPE.THERAPISTS_YEARLY_VISITS]: STATISTICS_CHART_TYPE.LINE,
	[STATISTICS_TYPE.THERAPISTS_YEARLY_DIFFERENT_PATIENTS]: STATISTICS_CHART_TYPE.LINE,
	[STATISTICS_TYPE.THERAPISTS_THERAPIST_VISITS]: STATISTICS_CHART_TYPE.PIE,
	[STATISTICS_TYPE.THERAPISTS_THERAPIST_PATIENTS]: STATISTICS_CHART_TYPE.PIE,
};