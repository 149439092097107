import {ioJsonFetchAction} from "Core/store/actions/io";
import {hideLoading, showLoading} from "Core/helpers/loading";
import {getArray} from "Core/helpers/data";
import {get} from "lodash";
import {actionCreators} from "Core/store/reducers";
import * as listItemDataMap from "./dataMap/listItem";
import * as itemFilterDataMap from "./dataMap/filter";
import {reducerStoreKey} from "./reducer";
import {isSuccessful} from "Core/helpers/io";

/**
 * Reset search patients list Redux state to initial state
 * @return {(function(*))|*}
 */
export const resetSearchPatientsListAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].reset());
};

/**
 * Fetch search patients list
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [filter] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const fetchSearchPatientsListAction = (
	abortCallback, filter = null, pageNo = 1, perPage, sortBy, sortDir
) => dispatch => {
	return ioJsonFetchAction(
		abortCallback,
		'defaultAuthorizedApi',
		'member/customer/page-search',
		'',
		filter,
		null,
		pageNo,
		perPage,
		sortBy ? sortBy : null,
		sortDir
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => {
			if (isSuccessful(responseData)) {
				return ({
					...responseData,
					filter: itemFilterDataMap.input(get(responseData, 'filter')),
					data: getArray(responseData, 'data').map(i => listItemDataMap.input(i))
				});
			}
			return undefined;
		});
};

/**
 * Load search patients list into Redux store
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [filter] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @param {boolean} [loadingOverlay=true] - Flag that specifies if loading overlay will be rendered.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const loadSearchPatientsListAction = (
	abortCallback, filter = null, pageNo = 1, perPage, sortBy, sortDir, loadingOverlay = true
) => dispatch => {
	const loading = (loadingOverlay === true ? showLoading("#main-page-table") : null);
	return fetchSearchPatientsListAction(abortCallback, filter, pageNo, perPage, sortBy, sortDir)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators[reducerStoreKey].setSearchPatientsListData(responseData));
			if (loading !== null) hideLoading(loading);
			return responseData;
		});
};

/**
 * Set search patients list filter in Redux store
 * 
 * @param {Object} filter - Filter to set.
 * @return {(function(*): void)|*}
 */
export const setSearchPatientsFilterAction = filter => dispatch => {
	dispatch(actionCreators[reducerStoreKey].setSearchPatientsListFilter(filter));
};

/**
 * Clear search patients list form Redux store
 *
 * @return {(function(*): void)|*}
 */
export const clearSearchPatientsListAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].clearSearchPatientsListData());
}

