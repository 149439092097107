import {cloneDeep} from "lodash";
import {getString} from "Core/helpers/data";
import {PhysiciansListItemDataObject} from "../dataObjects";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {PhysiciansListItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new PhysiciansListItemDataObject(
		getString(result, 'uniqueID'),
		getString(result, 'firstName'),
		getString(result,	'lastName'),
		getString(result,	'institution'),
		getString(result,	'telephone'),
		getString(result,	'mobilePhone'),
		getString(result,	'email')
		
	);

	return result;
}

// @note There is no output function since list is read only.