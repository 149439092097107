import {fetchStatisticsAction} from "Components/advanced/Statistics/actions";
import {actionCreators} from "./reducer";
import {STATISTICS_TYPE} from "Components/advanced/Statistics/const";
import {get} from "lodash";
import {isset} from "Core/helpers/data";
import {THERAPISTS_STATISTICS_TYPES_BY_SCOPE} from "Pages/apps/default/statistics/therapistStatistics/const";

/**
 * Load therapists statistics using a specific filter
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {TherapistsStatisticsScope} scope - Statistics scope (daily, monthly, ...).
 * @param {StatisticsFilterDataObject} filter - Criteria for showing statistics on the page.
 * @param {Object} dataMap - Data map to use for mapping the data.
 * @return {function(*): Promise<
 * 	Object<StatisticsType, {data: StatisticsDataObject, filter: StatisticsFilterDataObject}> | undefined
 * >}
 */
export const loadTherapistsStatisticsAction = (abortCallback, scope, filter, dataMap) => dispatch => {
	return Promise.allSettled(THERAPISTS_STATISTICS_TYPES_BY_SCOPE[scope].map(s =>
		fetchStatisticsAction(abortCallback, s, filter, dataMap)(dispatch)
	))
		.then(multiRes => {
			const yearlyVisits = get(multiRes, '[0].value');
			const yearlyDifferentPatients = get(multiRes, '[1].value');
			const filter = (isset(yearlyVisits) ? get(yearlyVisits, 'filter') : get(yearlyDifferentPatients, 'filter')) ;
			
			if (!isset(yearlyVisits) && !isset(yearlyDifferentPatients)) return undefined;
			
			dispatch(actionCreators.setData(get(yearlyVisits, 'data')));
			dispatch(actionCreators.setData(get(yearlyDifferentPatients, 'data')));
			dispatch(actionCreators.setFilter(filter));
			return {
				[STATISTICS_TYPE.THERAPISTS_YEARLY_VISITS]: yearlyVisits,
				[STATISTICS_TYPE.THERAPISTS_YEARLY_DIFFERENT_PATIENTS]: yearlyDifferentPatients,
			};
		});
}

/**
 * Save therapists statistics filter data in Redux store
 *
 * @param {StatisticsFilterDataObject} filter - Therapists statistics filter data.
 * @return {(function(*): void)|*}
 */
export const setTherapistsStatisticsFilterAction = filter => dispatch => {
	dispatch(actionCreators.setFilter(filter));
}

/**
 * Save therapists statistics current scope data in Redux store
 *
 * @param {?TherapistsStatisticsScope|''} scope - Therapists statistics scope.
 * @return {(function(*): void)|*}
 */
export const setTherapistsStatisticsScopeAction = scope => dispatch => {
	dispatch(actionCreators.setScope(scope));
}