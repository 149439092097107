import "./index.css";

import React from "react";
import DialogDataComponent, {executeComponentCallback} from 'Core/components/DialogDataComponent';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {ExpertReportDataObject} from "DataObjects/expertReport";
import DataValueValidation, {ValidationRuleObject} from "Core/validation";
import FormWrapper, {FormField} from "Core/components/advanced/FormWrapper";
import {getString, isset} from 'Core/helpers/data';
import * as actions from "./actions";
import {getGlobalActions} from 'Core/helpers/redux';
import DateInput from 'Core/components/input/DateInput';
import TextInput from "Core/components/input/TextInput";
import RichTextInput from "Core/components/input/RichTextInput";
import {loadPatientTherapyAction} from "Components/advanced/PatientRecord/components/Therapy/actions";
import {loadPatientRecordAction} from "Components/advanced/PatientRecord/actions";
import {FORM_FIELD_LABEL_POSITION} from "Core/components/advanced/FormWrapper/FormField";

/**
 * Get all actions used by this component
 * @type {Object}
 */
const allActions = getGlobalActions({...actions, loadPatientTherapyAction, loadPatientRecordAction});

class ExpertReportDialog extends DialogDataComponent {
	constructor(props) {
		super(props, {
			data:
				props.isNew ?
					new ExpertReportDataObject(
						null,
						props.patientId,
						props.therapyId,
						new Date(),
					)
					:
					null,
		}, {
			translationPath: 'ExpertReportDialog',
			domPrefix: 'expert-report-dialog',
			enableLoadOnDataPropChange: true,
			disableLoad: true,
		});
	}

	/** @inheritDoc */
	async asyncComponentDidMount(override = false) {
		await super.asyncComponentDidMount(override);

		const {isNew, id, fetchExpertReportAction} = this.props;

		// Load data if on edit dialog and ID is specified
		if (!isNew && !!id) {
			await this.executeAbortableActionMount(fetchExpertReportAction, id)
				// Load expert report into local component's state
				.then(data => this.setData(data));
		}
	}

	/**
	 * Default component's data validation method
	 *
	 * @return {boolean} True if component's data validation passed successfully.
	 */
	validate() {
		const dataValidation = new DataValueValidation();
		/** @type {ExpertReportDataObject} */
		const dataToValidate = this.getData();

		dataValidation.addRule('date', 'required', 'date');
		dataValidation.addRule('content', new ValidationRuleObject('custom', {validate: value => {
			let passed;

			if (typeof value === 'string') passed = !!value.replace(/(<([^>]+)>)/ig, "").length;
			else passed = (typeof value !== 'undefined' && value !== null);
				
			return passed ? {passed} : {passed: false, error: 'required'};
		}}));

		// Run validation
		const validationErrors = dataValidation.run(dataToValidate);

		if (validationErrors) this.setValidationErrors('', validationErrors).then();
		else this.clearValidationErrors().then();
		return !validationErrors;
	}

	/**
	 * Save dialog method
	 * @note This method should be called when dialog's "save" button is clicked. This method does not actually save any
	 * data to the DB or anywhere else. That should be done by the parent component.
	 */
	async save() {
		const {
			isNew, patientId, therapyId, dialogGUIID, createExpertReportAction, updateExpertReportAction, 
			addSuccessMessageAction, loadPatientRecordAction, loadPatientTherapyAction
		} = this.props;
		
		const data = this.getDataToReturn();

		// Do the validation
		const isValid = this.validate();

		// If validation is successful
		if (isValid) {
			const expertReport = (
				isNew ?
					await this.executeAbortableAction(createExpertReportAction, data) :
					await this.executeAbortableAction(updateExpertReportAction, data.id, data, false)
			);
			
			if (isset(expertReport)) {
				// Show success message
				if (isNew) addSuccessMessageAction(this.t('create_success_msg'));
				else addSuccessMessageAction(this.t('update_success_msg'));

				if (isNew) {
					// Reload patient record to update global statistics
					await this.executeAbortableAction(loadPatientRecordAction, patientId);
					// Reload patient therapy to update statistics
					if (!!therapyId && therapyId !== '*') {
						await this.executeAbortableAction(loadPatientTherapyAction, therapyId, '.therapy-section');
					}
				}

				this.close();

				// Trigger component's onSave event if visit was saved successfully
				executeComponentCallback(this.props.onSave, expertReport, dialogGUIID);
			}
		}
	}

	render() {
		const {isNew, therapyId} = this.props;

		/** @type {ExpertReportDataObject} */
		const data = this.getData();

		return this.renderDialog(
			this.renderTitle(isNew ? this.t(!!therapyId ? 'title_create_therapy' : 'title_create') : this.t('title')),
			(
				<FormWrapper className={`dialog-form ${this.getOption('domPrefix')}`}>
					<div className="date-and-title">
						<FormField
							required={true}
							className="date-field"
							label={this.t('dateLabel')}
							labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}
							errorMessages={this.getValidationErrors('date')}
						>
							<DateInput
								preventTab={true}
								value={data?.date || null}
								onChange={v => this.handleValueChange('date', v)}
							/>
						</FormField>

						<FormField
							className="title-field"
							inputClassName="full"
							label={this.t('titleLabel')}
							labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}
							errorMessages={this.getValidationErrors('title')}
						>
							<TextInput
								name="title"
								value={data?.title}
								onChange={this.handleInputChange}
							/>
						</FormField>
					</div>
					
					<FormField
						required={true}
						className="multiline-form-field content-field"
						inputClassName="full"
						label={this.t('contentLabel')}
						labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}
						errorMessages={this.getValidationErrors('content')}
					>
						<RichTextInput
							value={data?.content || ''}
							bounds={`#${getString(this.props, 'dialogOptions.id')} .dialog-content-component`}
							toolbar={[
								['undo', 'redo'],
								[{size: ["0.875em", false, "1.5em", "2.5em"]}],
								['bold', 'italic', 'underline', 'strike'],
								[{'background': []}, {'color': []}],
								[{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
								[{'list': 'ordered'}, {'list': 'bullet' }, {'indent': '-1'}, {'indent': '+1'}],
								['clean'],
								['print'],
							]}
							onChange={v => this.handleValueChange('content', v)}
						/>
					</FormField>
				</FormWrapper>
			),
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
ExpertReportDialog.propTypes = {
	// Flag that specifies if this dialog is for creating a new item
	// @note This prop can be dynamically changed in the popup component.
	isNew: PropTypes.bool,
	// Expert report ID used when "isNew" prop is false (edit expert report)
	id: PropTypes.string,
	// ID of the patient
	patientId: PropTypes.string.isRequired,
	// ID of the therapy
	therapyId: PropTypes.string,

	// Event triggered if expert report was saved successfully and dialog has already been closed
	// @param {ExpertReportDataObject} expert report,
	// @param {string} dialogGUIID
	onSave: PropTypes.func,
	// Event triggered when dialog close method is called
	// @note Dialog will not be closed if event callback returns false.
	// @param {string} dialogGUIID
	onClose: PropTypes.func,
};

export default connect(null, allActions)(ExpertReportDialog);