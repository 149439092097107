export const FORM_FIELD_LABEL_POSITION_NONE = 'none';
export const FORM_FIELD_LABEL_POSITION_ALIGNED = 'aligned';
export const FORM_FIELD_LABEL_POSITION_STACKED = 'stacked';
export const FORM_FIELD_LABEL_POSITION = {
	NONE: FORM_FIELD_LABEL_POSITION_NONE,
	ALIGNED: FORM_FIELD_LABEL_POSITION_ALIGNED,
	STACKED: FORM_FIELD_LABEL_POSITION_STACKED,
}
export const FORM_FIELD_LABEL_POSITIONS = [
	FORM_FIELD_LABEL_POSITION_NONE, FORM_FIELD_LABEL_POSITION_ALIGNED, FORM_FIELD_LABEL_POSITION_STACKED
];