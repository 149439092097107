/**
 * Data object representing the diagnoses list item
 * @note This is usually used in data table as a row. Params don't have default values since this data object is used to
 * represent data that actually exists.
 */
export class DiagnosesListItemDataObject {
	/**
	 * @param {string} id - List item's ID.
	 * @param {string} diagnosesCode - Diagnoses code.
	 * @param {string} diagnosesDescription - Diagnoses description.
	 */
	constructor(id, diagnosesCode, diagnosesDescription) {
		this.id = id;
		this.diagnosesCode = diagnosesCode;
		this.diagnosesDescription = diagnosesDescription;
	}
}

/**
 * Data object representing the diagnoses item
 * @note This is usually used when creating or updating data table item using a popup or a dialog. Params have default 
 * values since this data object can be used for creating an item. This is why 'id' has a default value of null which 
 * represents a new item that is being created.
 */
export class DiagnosesItemDataObject {
	/**
	 * @param {string|null} [id=null] - Item's ID.
	 * @param {string} [diagnosesCode=''] - Diagnoses code.
	 * @param {string} [diagnosesDescription=''] - Diagnoses description.
	 */
	constructor(id = null, diagnosesCode = '', diagnosesDescription = '') {
		this.id = id;
		this.diagnosesCode = diagnosesCode;
		this.diagnosesDescription = diagnosesDescription;
	}
}