import React from "react";
import zxcvbn from "zxcvbn";
import {Tooltip} from "react-tippy";

import styles from "./index.module.css";
import {translate} from "../../../../../i18n";

/**
 * Color preview
 *
 * @param {string} password - Password string.
 * @return {*} Preview function used by 'getPreview' prop of TextInput component.
 */
const preview = password => {
	const strengthData = zxcvbn(password);
	let score = strengthData.score;
	if (!password.length) score = 0;
	else score += 1;
	
	const strengthMap = {
		0: {
			symbol: '',
			color: 'transparent',
			label: translate("Enter password to check it's strength", 'TextInput.PasswordStrengthPreview'),
		},
		1: {
			symbol: '😱',
			color: '#cc0000',
			label: translate('Worst', 'TextInput.PasswordStrengthPreview'),
		},
		2: {
			symbol: '😨',
			color: '#f87600',
			label: translate('Bad', 'TextInput.PasswordStrengthPreview'),
		},
		3: {
			symbol: '🙁',
			color: '#e5c500',
			label: translate('Weak', 'TextInput.PasswordStrengthPreview'),
		},
		4: {
			symbol: '🙂',
			color: '#00c9db',
			label: translate('Good', 'TextInput.PasswordStrengthPreview'),
		},
		5: {
			symbol: '😍',
			color: '#59ff00',
			label: translate('Strong', 'TextInput.PasswordStrengthPreview'),
		}
	};
	
	return (
		<Tooltip
			tag="span" 
			title={
				translate("Password strength", 'TextInput.PasswordStrengthPreview') + ': '
				+ strengthMap[score].label
			}
			size="small" 
			position="top-center" 
			arrow={true}
			interactive={false}
			className={styles['tooltip']}
		>
			<div className={`password-strength-preview ${styles['preview']}`}>
				<span className={`symbol ${styles['symbol']}`}>{strengthMap[score].symbol}</span>
				<div
					className={`bar ${styles['bar']}`}
					style={{background: (score === 0 ? strengthMap[score].color : '')}}
				>
					<div style={{
						width: `${(100 / 5) * (score)}%`,
						background: strengthMap[score].color
					}} />
				</div>
			</div>
		</Tooltip>
	);
}

export default preview;