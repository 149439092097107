const enUS = {
	code: 'en-US',
	week: {
		dow: 0,
		doy: 4, // 4 days need to be within the year to be considered the first week
	},
	direction: 'ltr',
	buttonText: {
		prev: '',
		next: '',
		prevYear: '',
		nextYear: '',
		today: 'today',
		year: 'year',
		month: 'month',
		week: 'week',
		day: 'day',
		list: 'list',
	},
	buttonHints: {
		prev: 'Previous $0 (Alt + ←)',
		next: 'Next $0 (Alt + →)',
		today: 'This $0 (Alt + D)',
		prevYear: 'Previous $0 (Alt + ←)',
		nextYear: 'Next $0 (Alt + →)',
		todayYear: 'This $0',
	},
	weekText: 'W',
	weekTextLong: 'Week',
	closeHint: 'Close',
	timeHint: 'Time',
	allDayText: 'all-day',
	moreLinkText(n) {
		return '+ more ' + n;
	},
	noEventsText: 'No events to display',
};

export default enUS;