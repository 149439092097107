import React from 'react';
import DataComponent, {executeComponentCallback} from 'Core/components/DataComponent';
import {connect} from 'react-redux';
import SelectInput from 'Core/components/input/SelectInput';
import {getGlobalActions} from 'Core/helpers/redux';
import {fetchOfficeLocationCodebookAction} from '../../../store/actions/codebooks';
import {omit} from 'lodash';
import PropTypes from "prop-types";
import {getArray} from "Core/helpers/data";

/**
 * Get all actions used by this component
 * @type {Object}
 */
const allActions = getGlobalActions({fetchOfficeLocationCodebookAction});

class OfficeLocationSelectInput extends DataComponent {
	constructor(props) {
		super(props, {
			data: {
				/** @type {CodebookItem[]} */
				options: [],
				optionsLoading: false,
			}
		}, {
			disableLoad: true,
		});
	}
	
	/** @inheritDoc */
	async asyncComponentDidMount(override = false) {
		// Call the parent component's 'asyncComponentDidMount' method that handles core functionality
		await super.asyncComponentDidMount(override);
		
		const {fetchOfficeLocationCodebookAction} = this.props;
		
		// Load options
		await this.setValue('optionsLoading', true)
			.then(() => this.executeAbortableActionMount(fetchOfficeLocationCodebookAction))
			.then(options => this.setValue('options', options))
			.then(() => this.setValue('optionsLoading', false))
			// Select option if it is the only one
			.then(() => {
				const {selectOnlyOptionOnLoad} = this.props;
				const options = getArray(this.state, 'data.options');
				
				if (selectOnlyOptionOnLoad && options.length === 1) {
					executeComponentCallback(this.props.onChange, options[0].id);
				}
			});
	}
	
	render() {
		return (
			<SelectInput
				primaryKey="id"
				options={this.getValue('options')}
				isLoading={this.getValue('optionsLoading')}
				getOptionValue={o => o.id}
				getOptionLabel={o => o.name}
				{...omit(this.props, ['options', 'isLoading', ...Object.keys(allActions)])}
			/>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
OfficeLocationSelectInput.propTypes = {
	...SelectInput.propTypes,

	// Flag that specifies if option will be selected if it is the only one after options are loaded
	selectOnlyOptionOnLoad: PropTypes.bool,
};

export default connect(null, allActions)(OfficeLocationSelectInput);