import styles from "./index.module.css";
import "./rdt.css";

import React from "react";
import BaseComponent, {executeComponentCallback} from "Core/components/BaseComponent";
import Datetime from "react-datetime";
import PropTypes from "prop-types";
import {getBool, getObject, getString} from "Core/helpers/data";
import {omit} from "lodash";
import {COMMAND_CODES} from "Core/const/global";

class DayTimeInput extends BaseComponent {
	pickerRef = null;
	inputRef = null;
	
	constructor(props) {
		super(props);

		// Event handler methods
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
		
		// Picker menu methods
		this.openPickerMenu = this.openPickerMenu.bind(this);
		this.closePickerMenu = this.closePickerMenu.bind(this);
	}

	/**
	 * Input element key down handler
	 * @param {KeyboardEvent|SyntheticEvent} event - JavaScript key down event.
	 */
	handleKeyDown(event) {
		const value = this.getProp('value');
		const pickerMenuOpen = getBool(this.pickerRef, 'state.open');
		
		// Prevent input of non digit characters except for ':'
		if (COMMAND_CODES.indexOf(event.nativeEvent.code) === -1 && /[^\d:]/.test(event.key)) event.preventDefault();

		if (event.key === 'Escape') {
			// Stop propagation of the escape key press
			// @description This is done to prevent parent elements from catching the escape key press while this input 
			// element is focused because we explicitly want escape key to be handled by it. For example, this is necessary
			// to prevent closing a dialog, that closes on escape key press, when escape key is pressed on a focused input.
			event.stopPropagation();
			event.nativeEvent.stopImmediatePropagation();
			
			if (this.pickerRef) {
				// Close picker menu if it is opened
				if (pickerMenuOpen) this.closePickerMenu();
				// Blur input element if picker menu is not opened
				else if (this.inputRef) this.inputRef.blur();
			}
		}
		
		else if (event.key === 'Enter') {
			if (this.pickerRef) {
				// Close picker menu if it is open
				if (pickerMenuOpen) this.closePickerMenu();

				// Clear Datetime if value is falsy
				// @note Value is falsy if it is empyt or invalid
				if (!value) this.pickerRef.setState({inputValue: '', selectedDate: null});
			}
		}
		
		// Open picker menu on arrow down key is pressed if it is not already open
		else if (event.key === 'ArrowDown') {
			if (!pickerMenuOpen) this.openPickerMenu();
		}
	}

	/**
	 * Handle input's 'blur' event
	 */
	handleBlur() {
		const value = this.getProp('value');
		
		// If prop value is falsy clear the internal Datetime component state 'inputValue' and 'selectedDate' values
		if (this.pickerRef && !value) {
			this.pickerRef.setState({inputValue: '', selectedDate: null});
		}
	}

	
	// Picker menu methods ----------------------------------------------------------------------------------------------
	/**
	 * Programmatically Open picker menu
	 */
	openPickerMenu() {
		const pickerMenuOpen = getBool(this.pickerRef, 'state.open');
		if (!pickerMenuOpen && this.pickerRef) this.pickerRef.setState({open: true});
	}

	/**
	 * Programmatically close picker menu
	 */
	closePickerMenu() {
		const pickerMenuOpen = getBool(this.pickerRef, 'state.open');
		if (pickerMenuOpen && this.pickerRef) this.pickerRef.setState({open: false});
	}
	
	
	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {className, inputProps, timeFormat, placeholder, onChange, ...otherProps} = this.props;
		const size = getString(timeFormat).split(':').length;
		
		// Get input placeholder
		let placeholderToUse = placeholder;
		if (!placeholder) {
			placeholderToUse = [...Array(size).keys()].map(idx => idx === 0 ? '0' : '00').join(':');
		}
		
		return (
			<Datetime
				dateFormat={false}
				className={`${styles['wrapper']} ${className}`}
				inputProps={{
					className: 'form-control',
					placeholder: placeholderToUse,
					onKeyDown: this.handleKeyDown,
					onBlur: this.handleBlur,
					...omit(getObject(inputProps), ['className', 'placeholder', 'onKeyDown', 'onBlur']),
					ref: node => { this.inputRef = node; }
				}}
				timeFormat={getString(timeFormat)}
				onChange={m => executeComponentCallback(this.props.onChange, (typeof m === 'string' ? null : m.toDate()))}
				{...omit(otherProps, ['dateFormat', 'handleKeyDown'])}

				ref={node => { this.pickerRef = node; }}
			/>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
DayTimeInput.propTypes = {
	// For all props see https://github.com/arqex/react-datetime
	
	// Extra class names for the component markup.
	className: PropTypes.string,
	// Defines additional attributes for the input element of the component.
	inputProps: PropTypes.object,
	
	// Represents the selected date by the component, in order to use it as a controlled component. 
	// @note This prop is parsed by moment.js, so it is possible to use a date string or a moment.js date.
	// @type {Date | string | Moment}
	value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	// Represents the selected date for the component to use it as an uncontrolled component. 
	// @note  This prop is parsed by moment.js, so it is possible to use a date string or a moment.js date.
	// @type {Date | string | Moment}
	initialValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	// Define the month/year/decade/time which is viewed on opening the calendar. 
	// @note This prop is parsed by Moment.js, so it is possible to use a date `string` or a `moment` object.
	// @type {Date | string | Moment}
	initialViewDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	// Defines the format for the time. It accepts any moment.js time format.
	// If true the time will be displayed using the defaults for the current locale.
	// If false the timepicker is disabled and the component can be used as datepicker.
	timeFormat: PropTypes.string,
	// Whether to show an input field to edit the date manually.
	input: PropTypes.bool,
	
	// (value: Moment | string) => void
	onChange: PropTypes.func,
};

/**
 * Define component default values for own props
 */
DayTimeInput.defaultProps = {
	className: '',
	inputProps: {},
	timeFormat: 'H:mm',
};

export default DayTimeInput;