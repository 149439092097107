import {cloneDeep, get} from 'lodash';
import {getString, resetObjectValues} from "Core/helpers/data";
import {ExpertReportDataObject} from 'DataObjects/expertReport';
import {getDate, getDateString, STANDARD_DATE_TIME_FORMAT} from 'Core/helpers/datetime';

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {ExpertReportDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new ExpertReportDataObject(
		getString(result, 'uniqueId', null),
		getString(result,	'customerId', null),
		getString(result,	'therapyId', null),
		getDate(get(result, 'reportDate'), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		getString(result,	'title'),
		getString(result,	'report'),
	);

	return result;
}

/**
 * Get output data from input data
 * @param {ExpertReportDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep({
		uniqueId: inputData.id,
		reportDate: getDateString(inputData.date, STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		title: inputData.title,
		report: inputData.content,
		therapyId: inputData.therapyId === '*' ? null : inputData.therapyId,
		customerId: inputData.patientId,
	});

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}