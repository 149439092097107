// Simple static search component layout style -------------------------------------------------------------------------
export const SIMPLE_STATIC_SEARCH_LAYOUT_ALIGNED = 'aligned';
export const SIMPLE_STATIC_SEARCH_LAYOUT_STACKED = 'stacked';
export const SIMPLE_STATIC_SEARCH_LAYOUT = {
	ALIGNED: 'aligned',
	STACKED: 'stacked',
};
export const SIMPLE_STATIC_SEARCH_LAYOUTS = [SIMPLE_STATIC_SEARCH_LAYOUT_ALIGNED, SIMPLE_STATIC_SEARCH_LAYOUT_STACKED];


// Simple static search component search value input display types -----------------------------------------------------
export const SIMPLE_STATIC_SEARCH_DISPLAY_TYPE_TEXT = 'text';
export const SIMPLE_STATIC_SEARCH_DISPLAY_TYPE_SELECT = 'select';
export const SIMPLE_STATIC_SEARCH_DISPLAY_TYPE_SELECT_ASYNC = 'select_async'; // TODO: Implement filter
export const SIMPLE_STATIC_SEARCH_DISPLAY_TYPE_NUMBER = 'number';
export const SIMPLE_STATIC_SEARCH_DISPLAY_TYPE_INT = 'int';
export const SIMPLE_STATIC_SEARCH_DISPLAY_TYPE_FLOAT = 'float';
export const SIMPLE_STATIC_SEARCH_DISPLAY_TYPE_BOOL = 'bool';
export const SIMPLE_STATIC_SEARCH_DISPLAY_TYPE_DATE = 'date';
export const SIMPLE_STATIC_SEARCH_DISPLAY_TYPE_DATE_RANGE = 'date_range';
export const SIMPLE_STATIC_SEARCH_DISPLAY_TYPE_TIME = 'time';
export const SIMPLE_STATIC_SEARCH_DISPLAY_TYPE_TIME_RANGE = 'time_range'; // TODO: Create component and implement filter
export const SIMPLE_STATIC_SEARCH_DISPLAY_TYPE_DATETIME = 'datetime';
export const SIMPLE_STATIC_SEARCH_DISPLAY_TYPE_DATETIME_RANGE = 'datetime_range'; // TODO: Create component and implement filter
export const SIMPLE_STATIC_SEARCH_DISPLAY_TYPE_CUSTOM = 'custom';
export const SIMPLE_STATIC_SEARCH_DISPLAY_TYPE = {
	TEXT: 'text',
	SELECT: 'select',
	SELECT_ASYNC: 'select_async',
	NUMBER: 'number',
	INT: 'int',
	FLOAT: 'float',
	BOOL: 'bool',
	DATE: 'date',
	DATE_RANGE: 'date_range',
	TIME: 'time',
	TIME_RANGE: 'time_range',
	DATETIME: 'datetime',
	DATETIME_RANGE: 'datetime_RANGE',
	CUSTOM: 'custom'
	
};
export const SIMPLE_STATIC_SEARCH_DISPLAY_TYPES = [
	SIMPLE_STATIC_SEARCH_DISPLAY_TYPE_TEXT, 
	SIMPLE_STATIC_SEARCH_DISPLAY_TYPE_SELECT,
	SIMPLE_STATIC_SEARCH_DISPLAY_TYPE_SELECT_ASYNC, 
	SIMPLE_STATIC_SEARCH_DISPLAY_TYPE_NUMBER, 
	SIMPLE_STATIC_SEARCH_DISPLAY_TYPE_INT, 
	SIMPLE_STATIC_SEARCH_DISPLAY_TYPE_FLOAT,
	SIMPLE_STATIC_SEARCH_DISPLAY_TYPE_BOOL, 
	SIMPLE_STATIC_SEARCH_DISPLAY_TYPE_DATE,
	SIMPLE_STATIC_SEARCH_DISPLAY_TYPE_DATE_RANGE, 
	SIMPLE_STATIC_SEARCH_DISPLAY_TYPE_TIME, 
	SIMPLE_STATIC_SEARCH_DISPLAY_TYPE_TIME_RANGE, 
	SIMPLE_STATIC_SEARCH_DISPLAY_TYPE_DATETIME, 
	SIMPLE_STATIC_SEARCH_DISPLAY_TYPE_DATETIME_RANGE,
	SIMPLE_STATIC_SEARCH_DISPLAY_TYPE_CUSTOM
];