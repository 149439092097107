import {CookieData} from "Core/dataProtection/objects/cookie";
import {getStorageValue, STORAGE_TYPE} from "Core/storage";
import CookieConsent from "Core/dataProtection/cookieConsent";
import {sidebar_menu_shrank_cookie_group} from "Config/app";
import {getBoolFromTinyInt, isset} from "Core/helpers/data";

/**
 * Get menu sidebar shrunk flag value form storage
 * @note This function will check cookie access.
 * 
 * @param {'0'|'1'} [defaultValue] - Default shrunk value if data is not in storage.
 * @return {boolean|undefined} Returns boolean if flag is stored in storage. If default value is not set and data is not
 * in storage, undefined will be returned.
 */
export const getMenuSidebarShrankFromStorage = defaultValue => {
	const menuShrankCookie = new CookieData(sidebar_menu_shrank_cookie_group, 'sidebar_menu_shrank', STORAGE_TYPE.LOCAL);
	if (CookieConsent.isAllowed(menuShrankCookie)) {
		const shrankFromStorage = getStorageValue(`sidebar_menu_shrank`, STORAGE_TYPE.LOCAL);
		if (isset(shrankFromStorage)) return getBoolFromTinyInt(shrankFromStorage);
		else if (defaultValue) return getBoolFromTinyInt(defaultValue);
	}
	return (defaultValue ? getBoolFromTinyInt(defaultValue) : undefined);
};