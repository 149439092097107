import {FLOAT_POPUP_POSITION} from "./const";

export class FloatPopupOptionsDataObject {
	/**
	 * @param {string} [id=''] - Float popup element id attribute. 
	 * @param {string} [className=''] - Float popup element class attribute.
	 * @param {string|Element} [title=null] - Float popup title.
	 * @param {FloatPopupPosition} [position='bottom-right'] - Position on the screen where float popup will appear when
	 * opened.
	 * @param {string|number} [maxWidth=320] - Float popup max width. Width will be determined by the content.
	 * @param {string|number} [maxHeight=480] - Float popup max height. Height will be determined by the content.
	 * @param {boolean} [hideCloseBtn=false] - Flag that determines if close button will be hidden.
	 */
	constructor(
		id = '', className = '', title = null, position = FLOAT_POPUP_POSITION.BOTTOM_RIGHT, maxWidth = 320, 
		maxHeight = 480, hideCloseBtn = false
	) {
		this.id = id;
		this.className = className;
		this.title = title;
		this.position = position;
		this.maxWidth = maxWidth;
		this.maxHeight = maxHeight;
		this.hideCloseBtn = hideCloseBtn;
	}
}