import {hideLoadingFunction, showLoading} from "Core/helpers/loading";
import {ioJsonAction} from "Core/store/actions/io";
import {getDateString, STANDARD_DATE_TIME_FORMAT} from "Core/helpers/datetime";
import {getArray, isset} from "Core/helpers/data";
import * as eventDataMap from "./dataMap/event";
import * as anonymousAppointmentDataMap from "DataMap/appointment/anonymous";
import * as appointmentDataMap from "DataMap/appointment/update";
import * as patientDataMap from "DataMap/patient";
import {APPOINTMENT_PATIENT_TYPE} from "Const/patient";
import {showErrorMessage} from "Core/helpers/message";
import {translate} from "Core/i18n";
import {get} from "lodash";

/**
 * Fetch calendar events for the specified stare and end date
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Date} startDate - Start date.
 * @param {Date} endDate - End date.
 * @return {function(*): Promise<CalendarItemDataObject[]|undefined>}
 */
export const fetchCalendarDataAction = (abortCallback, startDate, endDate) => dispatch => {
	const loading = showLoading('.schedule-calendar-component');
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'member/appointments/find-scheduled',
		{
			startDate: getDateString(startDate, STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
			endDate: getDateString(endDate, STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		},
		hideLoadingFunction(loading),
	)(dispatch)
		.then(res => isset(res) ? getArray(res, 'data').map(e => eventDataMap.input(e)) : undefined);
};

/**
 * Fetch appointment data for a specified patient types
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {AppointmentPatientType} patientType - Appointment patient type.
 * @param {string} id - DB ID of the appointment.
 * @param {string} [loadingSelector='.schedule-calendar-component'] - Selector for the loading overlay.
 * @return {function(*): Promise<{
 * 	patientType: AppointmentPatientType,
 * 	patientData: ?PatientDataObject,
 * 	appointmentData: AppointmentUpdateDataObject|AnonymousAppointmentDataObject
 * }|undefined>}
 */
export const fetchAppointmentAction = (
	abortCallback, patientType, id, loadingSelector = '.schedule-calendar-component'
) => dispatch => {
	switch (patientType) {
		case APPOINTMENT_PATIENT_TYPE.EXISTING_PATIENT:
			return _fetchRegularAppointmentAction(abortCallback, id, loadingSelector)(dispatch);
		case APPOINTMENT_PATIENT_TYPE.ANONYMOUS_PATIENT: 
			return _fetchAnonymousAppointmentAction(abortCallback, id, loadingSelector)(dispatch);
		default:
			showErrorMessage(translate('fetch_appointment_patient_type_error'));
			return Promise.resolve(undefined);
	}
};

/**
 * Fetch appointment data for already existing patient
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - DB ID of the appointment.
 * @param {string} [loadingSelector=''] - Selector for the loading overlay.
 * @return {function(*): Promise<{
 * 	patientType: AppointmentPatientType, 
 * 	patientData: PatientDataObject, 
 * 	appointmentData: AppointmentUpdateDataObject
 * }|undefined>}
 */
export const _fetchRegularAppointmentAction = (abortCallback, id, loadingSelector = '') => dispatch => {
	const loading = (!!loadingSelector ? showLoading(loadingSelector) : undefined);
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'member/appointments/fetch-by-id',
		{id},
		!!loading ? hideLoadingFunction(loading) : undefined,
	)(dispatch)
		.then(res => isset(res) ? {
			patientType: APPOINTMENT_PATIENT_TYPE.EXISTING_PATIENT,
			patientData: patientDataMap.input(get(res, 'data.customerData')), 
			appointmentData: appointmentDataMap.input(get(res, 'data')),
		} : undefined);
};

/**
 * Fetch appointment data for anonymous patient
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - DB ID of the appointment.
 * @param {string} [loadingSelector=''] - Selector for the loading overlay.
 * @return {function(*): Promise<{
 * 	patientType: AppointmentPatientType,
 * 	patientData: null,
 * 	appointmentData: AnonymousAppointmentDataObject
 * }|undefined>}
 */
export const _fetchAnonymousAppointmentAction = (abortCallback, id, loadingSelector = '') => dispatch => {
	const loading = (!!loadingSelector ? showLoading(loadingSelector) : undefined);
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'member/anonymous-appointment/fetch-by-id',
		{id},
		!!loading ? hideLoadingFunction(loading) : undefined,
	)(dispatch)
		.then(res => isset(res) ? {
			patientType: APPOINTMENT_PATIENT_TYPE.ANONYMOUS_PATIENT,
			patientData: null,
			appointmentData: anonymousAppointmentDataMap.input(get(res, 'data')),
		} : undefined);
};