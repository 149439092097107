import {ioJsonManualAction} from "Core/store/actions/io";
import {getArray} from "Core/helpers/data";

/**
 * Fetch results for use as autosuggest options (AsyncSelect)
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} endpoint - API endpoint of the codebook.
 * @param {string} [query] - Autosuggest query strong.
 * @param {string} [api='defaultAuthorizedApi'] - API to use (key of the 'io_base_urls' io config object).
 * @return {function(*): Promise<string[]>}
 */
export const autosuggestAbstractAction = (
	abortCallback, endpoint, query = '', api = 'defaultAuthorizedApi'
) => dispatch => {
	return ioJsonManualAction(abortCallback, api, endpoint, {searchString: query})(dispatch)
		.then(res => getArray(res, 'data'))
		// Do not display any errors, just return an empty list of suggestions
		.catch(() => []);
};