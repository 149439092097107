/**
 * WARNING: Be careful when importing this helper because it uses Redux store and some other imports that can cause
 * circular dependencies!
 */

import reduxStore from '../store';
import {get} from "lodash";
import {actionCreators} from '../../core/store/reducers';
import {LoadingOverlayObject} from "../objects";


// Loading overlay -----------------------------------------------------------------------------------------------------
/**
 * Add loading overlay to the list of overlays
 * @param {LoadingOverlayObject} loading - Loading overlay.
 * @return {string} Overlay GUI ID.
 */
export const addLoading = loading => {
	reduxStore.dispatch(actionCreators.overlay.addOverlay(loading));
	return get(loading, 'GUIID');
};
/**
 * Add a loading overlay to all HTML elements found by the selector
 *
 * @param {string} selector - CSS selector for target elements.
 * @param {boolean} [transparent=true] - If true, loading background will be transparent. Transparency should be defined
 * by the skin in '/skin/css/common.css' file.
 * @param {boolean} [blur=false] - If true, overlay background will be blurred. Blur should be defined by the skin in
 * '/skin/css/common.css' file.
 * @param {string|number} [size='1em'] - Loading spinner size in px or as size string with units.
 * @param {string|number} [weight=2] - Loading spinner glyph weight in px or as size string with units.
 * @param {string} [className=''] - Additional overlay element class name.
 * @param {boolean} [disableTabKey=false] - Flag that specifies if keyboard Tab key will be disabled while the overlay 
 * is visible.
 * @return {string} Overlay GUI ID.
 */
export const showLoading = (
	selector, transparent = true, blur = false, size = '1em', weight = 2, className = '', disableTabKey = false
) => addLoading(new LoadingOverlayObject(selector, transparent, blur, size, weight, className, disableTabKey));
/**
 * Add a loading overlay to all HTML elements found by the selector and disable Tab key while the overlay is visible
 *
 * @param {string} selector - CSS selector for target elements.
 * @param {boolean} [transparent=true] - If true, loading background will be transparent. Transparency should be defined
 * by the skin in '/skin/css/common.css' file.
 * @param {boolean} [blur=false] - If true, overlay background will be blurred. Blur should be defined by the skin in
 * '/skin/css/common.css' file.
 * @param {string|number} [size='1em'] - Loading spinner size in px or as size string with units.
 * @param {string|number} [weight=2] - Loading spinner glyph weight in px or as size string with units.
 * @param {string} [className=''] - Additional overlay element class name.
 * @return {string} Overlay GUI ID.
 */
export const showLoadingNoTab = (
	selector, transparent = true, blur = false, size = '1em', weight = 2, className = ''
) => showLoading(selector, transparent, blur, size, weight, className, true);
/**
 * Remove loading overlay from the list of overlays
 * @param {string} GUIID - Overlay GUI ID.
 */
export const removeLoading = GUIID => { reduxStore.dispatch(actionCreators.overlay.removeOverlay(GUIID)); };
/**
 * Function that will return a function that will remove loading overlay from the list of overlays
 * @param {string} GUIID
 * @return {function(): void}
 */
export const removeLoadingFunction = GUIID => () => { removeLoading(GUIID); }
/**
 * Remove loading overlay from all HTML elements found by the GUIID
 * @param {string} GUIID - Overlay GUI ID.
 */
export const hideLoading = GUIID => { removeLoading(GUIID); }
/**
 * Function that will return a function that will remove loading overlay from all HTML elements found by the GUIID
 * @param {string} GUIID
 * @return {function(): void}
 */
export const hideLoadingFunction = GUIID => removeLoadingFunction(GUIID);



// Layout page loading overlay -----------------------------------------------------------------------------------------
/**
 * Add main layout content element loading overlay to the list of overlays
 * @param {LoadingOverlayObject} loading - Loading overlay.
 * @return {string} Overlay GUI ID.
 */
export const addPageLoading = loading => {
	reduxStore.dispatch(actionCreators.overlay.addOverlay({...loading, selector: '.layout'}));
	return get(loading, 'GUIID');
}
/**
 * Add a loading overlay to main layout HTML elements ('.layout')
 * @note This depend on the layout being properly made with root HTML element with class name 'layout'.
 *
 * @param {boolean} [transparent=true] - If true, overlay background will be transparent. Transparency should be
 * defined by the skin in 'common.css' file.
 * @param {boolean} [blur=false] - If true, overlay background will be blurred. Blur should be defined by the
 * skin in 'common.css' file.
 * @param {string|number} [size='1em'] - Loading spinner size in px or as size string with units.
 * @param {string|number} [weight=2] - Loading spinner glyph weight in px or as size string with units.
 * @param {string} [className=''] - Additional overlay element class name.
 * @param {boolean} [disableTabKey=true] - Flag that specifies if keyboard Tab key will be disabled while the overlay 
 * is visible.
 * @return {string} Overlay GUI ID.
 */
export const showPageLoading = (
	transparent = true, blur = false, size = '3em', weight = 3, className = '', disableTabKey = true
) => {
	return showLoading('.layout', transparent, blur, size, weight, className, disableTabKey);
}


// Layout content loading overlay --------------------------------------------------------------------------------------
/**
 * Add page layout content element loading overlay to the list of overlays
 * @param {OverlayObject} loading - Loading overlay.
 * @return {string} Overlay GUI ID.
 */
export const addContentLoading = loading => {
	reduxStore.dispatch(actionCreators.overlay.addOverlay({...loading, selector: '.layout-content'}));
	return get(loading, 'GUIID');
}
/**
 * Add a loading overlay to main layout content HTML elements ('.layout-content')
 * @note This depend on the layout being properly made with a child HTML element with class name 'layout-content'.
 *
 * @param {boolean} [transparent=true] - If true, overlay background will be transparent. Transparency should be
 * defined by the skin in 'common.css' file.
 * @param {boolean} [blur=false] - If true, overlay background will be blurred. Blur should be defined by the
 * skin in 'common.css' file.
 * @param {string|number} [size='1em'] - Loading spinner size in px or as size string with units.
 * @param {string|number} [weight=2] - Loading spinner glyph weight in px or as size string with units.
 * @param {string} [className=''] - Additional overlay element class name.
 * @param {boolean} [disableTabKey=false] - Flag that specifies if keyboard Tab key will be disabled while the overlay 
 * is visible.
 * @return {string} Overlay GUI ID.
 */
export const showContentLoading = (
	transparent = true, blur = false, size = '3em', weight = 3, className = '', disableTabKey = false
) => showLoading('.layout-content', transparent, blur, size, weight, className, disableTabKey);
/**
 * Add a loading overlay to main layout content HTML elements ('.layout-content') and disable Tab key while the overlay 
 * is visible
 * @note This depend on the layout being properly made with a child HTML element with class name 'layout-content'.
 *
 * @param {boolean} [transparent=true] - If true, overlay background will be transparent. Transparency should be
 * defined by the skin in 'common.css' file.
 * @param {boolean} [blur=false] - If true, overlay background will be blurred. Blur should be defined by the
 * skin in 'common.css' file.
 * @param {string|number} [size='1em'] - Loading spinner size in px or as size string with units.
 * @param {string|number} [weight=2] - Loading spinner glyph weight in px or as size string with units.
 * @param {string} [className=''] - Additional overlay element class name.
 * @return {string} Overlay GUI ID.
 */
export const showContentLoadingNoTab = (
	transparent = true, blur = false, size = '3em', weight = 3, className = ''
) => showContentLoading(transparent, blur, size, weight, className, true);


// Global dialog loading overlay ---------------------------------------------------------------------------------------
/**
 * Add page layout content element loading overlay to the list of overlays
 * @param {OverlayObject} loading - Loading overlay.
 * @param {string} dialogId - Dialog element id.
 * @return {string} Overlay GUI ID.
 */
export const addDialogLoading = (loading, dialogId) => {
	reduxStore.dispatch(actionCreators.overlay.addOverlay({
		...loading, selector: `#${dialogId} .dialog-content-component`
	}));
	return get(loading, 'GUIID');
}
/**
 * Add a loading overlay to main layout content HTML elements ('.layout-content')
 * @note This depend on the layout being properly made with a child HTML element with class name 'layout-content'.
 *
 * @param {string} dialogId - Dialog element id.
 * @param {boolean} [transparent=true] - If true, overlay background will be transparent. Transparency should be
 * defined by the skin in 'common.css' file.
 * @param {boolean} [blur=false] - If true, overlay background will be blurred. Blur should be defined by the
 * skin in 'common.css' file.
 * @param {string|number} [size='1em'] - Loading spinner size in px or as size string with units.
 * @param {string|number} [weight=2] - Loading spinner glyph weight in px or as size string with units.
 * @param {string} [className=''] - Additional overlay element class name.
 * @param {boolean} [disableTabKey=true] - Flag that specifies if keyboard Tab key will be disabled while the overlay
 * is visible.
 * @return {string} Overlay GUI ID.
 */
export const showDialogLoading = (
	dialogId, transparent = true, blur = false, size = '3em', weight = 3, className = '', disableTabKey = true
) => showLoading(`#${dialogId} .dialog-content-component`, transparent, blur, size, weight, className, disableTabKey);


// App loading overlay -------------------------------------------------------------------------------------------------
/**
 * Add apps section element loading overlay to the list of overlays
 * @param {LoadingOverlayObject} loading - Loading overlay.
 * @return {string} Overlay GUI ID.
 */
export const addAppLoading = loading => {
	reduxStore.dispatch(actionCreators.overlay.addOverlay({...loading, selector: '#apps-section'}));
	return get(loading, 'GUIID');
}
/**
 * Add a loading overlay to app section HTML elements ('#apps-section')
 * @note This depend on the layout being properly made with root HTML element with class name 'layout'.
 *
 * @param {boolean} [transparent=true] - If true, overlay background will be transparent. Transparency should be
 * defined by the skin in 'common.css' file.
 * @param {boolean} [blur=false] - If true, overlay background will be blurred. Blur should be defined by the
 * skin in 'common.css' file.
 * @param {string|number} [size='1em'] - Loading spinner size in px or as size string with units.
 * @param {string|number} [weight=2] - Loading spinner glyph weight in px or as size string with units.
 * @param {string} [className=''] - Additional overlay element class name.
 * @param {boolean} [disableTabKey=true] - Flag that specifies if keyboard Tab key will be disabled while the overlay
 * is visible.
 * @return {string} Overlay GUI ID.
 */
export const showAppsLoading = (
	transparent = true, blur = false, size = '3em', weight = 3, className = '', disableTabKey = true
) => showLoading('#apps-section', transparent, blur, size, weight, className, disableTabKey);