import {cloneDeep} from "lodash";
import {getString} from "Core/helpers/data";
import {DiagnosesListItemDataObject} from "../dataObjects";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {DiagnosesListItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new DiagnosesListItemDataObject(
		getString(result, 'uniqueID'),
		getString(result, 'code'),
		getString(result, 'description')
	);

	return result;
}

// @note There is no output function since list is read only.