import "./index.css";

import React from "react";
import DialogDataComponent from "Core/components/DialogDataComponent";
import {PatientPrescribedTherapyDataObject} from "DataObjects/patientTherapy";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import FormWrapper, {FormField} from "Core/components/advanced/FormWrapper";
import {getPageActions} from "Core/helpers/redux";
import {get} from "lodash";
import SelectInput from "Core/components/input/SelectInput";
import {getArray, isset} from "Core/helpers/data";
import {capitalize} from "Core/helpers/string";
import TextInput from "Core/components/input/TextInput";
import ToggleInput from "Core/components/input/ToggleInput";

class PrescribedTherapyDialog extends DialogDataComponent {
	constructor(props) {
		super(props, {
			data: props.isNew ? new PatientPrescribedTherapyDataObject() : null,

			/**
			 * Therapy type codebook loaded from IO
			 * @note If undefined, codebook is not loaded yet. If null, codebook is being loaded.
			 * @type {CodebookItem[]|undefined|null}
			 */
			therapyTypeCodebook: undefined,
			/**
			 * Therapy subtype codebook loaded from IO
			 * @note If undefined, codebook is not loaded yet. If null, codebook is being loaded.
			 * @type {CodebookItem[]|undefined|null}
			 */
			therapySubtypeCodebook: undefined,
			/**
			 * Therapy sub-subtype codebook loaded from IO
			 * @note If undefined, codebook is not loaded yet. If null, codebook is being loaded.
			 * @type {CodebookItem[]|undefined|null}
			 */
			therapySubSubtypeCodebook: undefined,
		}, {
			translationPath: 'PrescribedTherapyDialog',
			disableLoad: props.isNew,
			optimizedUpdate: true,
			optimizedUpdateIgnoreProps: ['onClose', 'onSave'],
		});


		// Data methods
		this.loadCodebook = this.loadCodebook.bind(this);
	}


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Load a specific page codebook data into local state
	 * @note Page codebook actions are define in 'store/page/actions/codebooks' directory.
	 *
	 * @param {string} codebookName - Name of the codebook to load (for example: 'therapist').
	 * @param {any} [actionParams] - Any codebook action parameters (see the codebook definitions for reference).
	 * @return {Promise<CodebookItem[]>}
	 */
	loadCodebook(codebookName, ...actionParams) {
		const loadCodebookAction = get(this.props, `fetch${capitalize(codebookName)}CodebookAction`);

		if (loadCodebookAction) {
			this.setState({[`${codebookName}Codebook`]: null})
				.then(() => this.executeAbortableAction(loadCodebookAction, ...actionParams))
				.then(codebook => this.setState({[`${codebookName}Codebook`]: codebook}).then(() => codebook));
		}
		return Promise.resolve([]);
	}

	/**
	 * Clear therapy subtypes
	 *
	 * @description Clears both the loaded codebook and the value.
	 * @return Promise<Object>
	 */
	clearTherapySubtypes() {
		return this.setState(prevState => ({
			...prevState,
			data: {
				...prevState.data,
				prescribedTherapySubtypeId: '',
				prescribedTherapySubtype: '',
			},
			therapySubtypeCodebook: undefined,
		}));
	}

	/**
	 * Clear therapy sub-subtypes
	 *
	 * @description Clears both the loaded codebook and the value.
	 * @return Promise<Object>
	 */
	clearTherapySubSubtypes() {
		return this.setState(prevState => ({
			...prevState,
			data: {
				...prevState.data,
				prescribedTherapySubSubtypeId: '',
				prescribedTherapySubSubtype: '',
			},
			therapySubSubtypeCodebook: undefined,
		}));
	}
	

	render() {
		const {isNew, title} = this.props;
		const {therapyTypeCodebook, therapySubtypeCodebook, therapySubSubtypeCodebook} = this.state;

		return this.renderDialog(
			this.renderTitle(isNew ? this.t('title_create') : (title ? title : this.t('title'))),
			(
				<FormWrapper className="dialog-form">
					<FormField
						label={this.t('prescribedTherapyTypeField')}
						errorMessages={this.getValidationErrors('prescribedTherapyTypeId')}
					>
						<SelectInput
							simpleValue={false}
							isClearable={true}
							options={getArray(therapyTypeCodebook).map(i => ({label: i.name, value: i.id}))}
							isLoading={therapyTypeCodebook === null}
							onMenuOpen={() => { 
								if (!isset(therapyTypeCodebook)) this.loadCodebook('therapyType').then(); 
							}}
							value={this.getValue('prescribedTherapyTypeId')}
							onChange={v => {
								this.handleValueChange('prescribedTherapyTypeId', !!v ? v.value : null)
									.then(() => this.handleValueChange('prescribedTherapyType', !!v ? v.label : null))
									.then(() => this.clearTherapySubtypes())
									.then(() => this.clearTherapySubSubtypes())
									.then(() => { if (!!v) this.loadCodebook('therapySubtype', v.value).then(); });
								
							}}
						/>
					</FormField>

					<FormField
						label={this.t('prescribedTherapySubtypeField')}
						errorMessages={this.getValidationErrors('prescribedTherapySubtypeId')}
					>
						<SelectInput
							simpleValue={false}
							isClearable={true}
							options={getArray(therapySubtypeCodebook).map(i => ({label: i.name, value: i.id}))}
							isLoading={therapySubtypeCodebook === null}
							value={this.getValue('prescribedTherapySubtypeId')}
							onChange={v => {
								this.handleValueChange('prescribedTherapySubtypeId', !!v ? v.value : null)
									.then(() => this.handleValueChange('prescribedTherapySubtype', !!v ? v.label : null))
									.then(() => this.clearTherapySubSubtypes())
									.then(() => { if (!!v) this.loadCodebook('therapySubSubtype', v.value).then(); });
							}}
							isDisabled={!isset(therapySubtypeCodebook)}
						/>
					</FormField>
					<FormField
						label={this.t('prescribedTherapySubSubtypeField')}
						errorMessages={this.getValidationErrors('prescribedTherapySubSubtypeId')}
					>
						<SelectInput
							simpleValue={false}
							isClearable={true}
							options={getArray(therapySubSubtypeCodebook).map(i => ({label: i.name, value: i.id}))}
							isLoading={therapySubSubtypeCodebook === null}
							value={this.getValue('prescribedTherapySubSubtypeId')}
							onChange={v => {
								this.handleValueChange('prescribedTherapySubSubtypeId', !!v ? v.value : null)
									.then(() => this.handleValueChange('prescribedTherapySubSubtype', !!v ? v.label : null))
							}}
							isDisabled={!isset(therapySubSubtypeCodebook)}
						/>
					</FormField>
					<FormField
						label={this.t('durationField')}
						errorMessages={this.getValidationErrors('duration')}
					>
						<TextInput
							name="duration"
							value={this.getValue('duration')}
							onChange={this.handleInputChange}
						/>
					</FormField>
					<FormField
						label={this.t('additionalInfoField')}
						errorMessages={this.getValidationErrors('additionalInfo')}
					>
						<TextInput
							name="additionalInfo"
							value={this.getValue('additionalInfo')}
							onChange={this.handleInputChange}
						/>
					</FormField>
					<FormField
						label={this.t('activeField')}
						errorMessages={this.getValidationErrors('active')}
					>
						<ToggleInput
							name="active"
							checked={this.getValue('active')}
							onChange={this.handleInputChange}
						/>
					</FormField>
				</FormWrapper>
			)
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
PrescribedTherapyDialog.propTypes = {
	// Unique GUI ID of the dialog
	// @note This is automatically sent by the global Dialog component.
	dialogGUIID: PropTypes.string,
	// Dialog options
	// @note This is automatically sent by the global Dialog component.
	dialogOptions: PropTypes.object,
	// Action used to close the dialog
	// @note This is automatically sent by the global Dialog component.
	dialogCloseAction: PropTypes.func,

	// Flag that specifies if dialog is opened for a new (create dialog) or an existing item (edit dialog). If true
	// dialog is opened for a new item. 
	isNew: PropTypes.bool,
	// Dialog title
	title: PropTypes.string,
	// Therapy data
	// @type {PatientPrescribedTherapyDataObject}
	data: PropTypes.object,

	// Events
	onClose: PropTypes.func,
	// @param {string} therapyId - ID of the newly created or updated therapy.
	// @param {string} dialogGUIID
	onSave: PropTypes.func,
};

export default connect(null, getPageActions())(PrescribedTherapyDialog);