import styles from "./index.module.css";

import React from "react";
import BaseComponent, {executeComponentCallback} from "Core/components/BaseComponent";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import {icon_font_delete_symbol} from "Config/app";
import {getArray, trimArray} from "Core/helpers/data";
import {getPageActions} from "Core/helpers/redux";
import {deleteAppointmentsItemsAction} from "Components/advanced/PatientRecord/components/Appointments/actions";
import ConfirmDialog from "Core/components/dialogs/ConfirmDialog";
import {map} from "lodash";
import {isSuccessful} from "Core/helpers/io";

/**
 * Get all actions used by this component
 * @type {Object}
 */
const allActions = getPageActions({deleteAppointmentsItemsAction});

class AppointmentsMainTableToolbar extends BaseComponent {
	constructor(props) {
		super(props, {
			
		});

		// Initiate component's state
		this.state = {
			/** @type {SearchAppointmentsListItemDataObject[]} */
			selectedMainListItems: [],
		}

		// External methods
		this.updateSelection = this.updateSelection.bind(this);

		// Action methods
		this.deleteMainListItems = this.deleteMainListItems.bind(this);
	}

	/**
	 * Update the internal list of selected main list items
	 * 
	 * @param {SearchAppointmentsListItemDataObject[]} selectedMainListItems
	 * @return {Promise<Object>}
	 */
	updateSelection(selectedMainListItems) {
		return this.setState({selectedMainListItems});
	}

	/**
	 * Delete selected main list items
	 * @param {SearchAppointmentsListItemDataObject[]} items - Main list items.
	 * @return {Promise<void>}
	 */
	deleteMainListItems(items) {
		const {
			openDialogAction, closeDialogAction, deleteAppointmentsItemsAction, addSuccessMessageAction
		} = this.props;

		return new Promise(resolve => {
			const dialogGUIID = openDialogAction('', ConfirmDialog, {
				message: this.t('confirm_multi_delete', 'Appointments', '', {count: items.length}),
				supportHtml: true,
				onYes: () => {
					this.executeAbortableAction(deleteAppointmentsItemsAction, map(items, 'id'))
						.then(response => {
							if (isSuccessful(response)) {
								addSuccessMessageAction(
									this.t('multi_delete_success_msg', 'Appointments', '', {count: items.length})
								);
								executeComponentCallback(this.props.onAfterDelete);
							}
							return Promise.resolve(response);
						})
						.then(() => closeDialogAction(dialogGUIID))
						.finally(() => resolve('yes'));
				},
				onNo: () => {
					closeDialogAction(dialogGUIID);
					resolve('no');
				}
			}, {
				id: 'items-delete-dialog',
				closeOnEscape: true,
				closeOnClickOutside: true,
				hideCloseBtn: true,
				maxWidth: 640
			});
			this.setOption(
				'dialogsToCloseOnUnmount',
				trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
			);
		});
	}
	
	render() {
		const {noStyle} = this.props;
		const selectedMainListItems = getArray(this.state, 'selectedMainListItems');
		
		return (
			<div className={`toolbar ${!noStyle ? 'standard' : ''} ${styles['wrapper']}`}>
				<Button
					displayType={BUTTON_DISPLAY_TYPE.NONE}
					displayStyle={BUTTON_STYLE.ACTION}
					icon={icon_font_delete_symbol}
					label={this.t('delete_selected', 'Appointments')}
					onClick={() => this.deleteMainListItems(selectedMainListItems)}
					disabled={selectedMainListItems.length === 0}
				/>
				<div className="separator" />
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
AppointmentsMainTableToolbar.propTypes = {
	// List of selected main list items for init
	// @note Changes to this value are not being considered. To update selection use the 'updateSelection' method.
	selectedMainListItems: PropTypes.array,
	// Flag that specifies if toolbar will not use standard style
	// @note Use this to integrate the toolbar into custom styled toolbars.
	noStyle: PropTypes.bool,
	
	// Event triggered after main list items are successfully deleted
	onAfterDelete: PropTypes.func, // No arguments
};

/**
 * Define component default values for own props
 */
AppointmentsMainTableToolbar.defaultProps = {
	selectedMainListItems: [],
};

export default connect(null, allActions, null, {forwardRef: true})(AppointmentsMainTableToolbar);