import {getBoolean, getInteger, getString} from "Core/helpers/data";
import {get} from "lodash";
import {SORT_ORDER} from "Core/const/global";
import {max_therapy_type_levels, therapy_type_root_levels} from "Config/app";

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'therapyTypes';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@therapyTypes/reset',

	SET_THERAPY_TYPE_LIST_DATA: '@therapyTypes/set_therapy_type_list_data',
	CLEAR_THERAPY_TYPE_LIST_DATA: '@therapyTypes/clear_therapy_type_list_data',
	
	SELECT_THERAPY_TYPE_ITEM: '@therapyTypes/select_therapy_type_item',
	CLEAR_SELECT_THERAPY_TYPE_ITEMS: '@therapyTypes/clear_select_therapy_type_items',
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),

	setTherapyTypeListData: (level, listData) => ({type: REDUCER_TYPES.SET_THERAPY_TYPE_LIST_DATA, level, listData}),
	clearTherapyTypeListData: level => ({type: REDUCER_TYPES.CLEAR_THERAPY_TYPE_LIST_DATA, level}),

	selectTherapyTypeItem: (level, item) => ({type: REDUCER_TYPES.SELECT_THERAPY_TYPE_ITEM, level, item}),
	clearSelectTherapyTypeItems: fromLevel => ({type: REDUCER_TYPES.CLEAR_SELECT_THERAPY_TYPE_ITEMS, fromLevel}),
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export let initialState = {};
for (let i = therapy_type_root_levels; i < (therapy_type_root_levels + max_therapy_type_levels); i++) {
	// Initialize list data values
	// @type {IoJsonFetchResponseObject}
	initialState[`list${new Array(i).fill('Sub').join('')}Data`] = null;

	// Initialize selected item values
	// @type {TherapyTypesListItemDataObject}
	if (i < (therapy_type_root_levels + max_therapy_type_levels) - 1) {
		initialState[`selected${new Array(i).fill('Sub').join('')}Item`] = null;
	}
}

// Reducer function
const reducer = (state = {...initialState}, action) => {
	const subs = new Array(action.level).fill('Sub').join('');
	
	switch (action.type) {
		case REDUCER_TYPES.RESET: return { ...initialState };

		case REDUCER_TYPES.SET_THERAPY_TYPE_LIST_DATA:
			return {...state, [`list${subs}Data`]: action.listData};
		case REDUCER_TYPES.CLEAR_THERAPY_TYPE_LIST_DATA:
			return {...state, [`list${subs}Data`]: initialState[`list${subs}Data`]};

		case REDUCER_TYPES.SELECT_THERAPY_TYPE_ITEM:
			return {...state, [`selected${subs}Item`]: action.item};
		case REDUCER_TYPES.CLEAR_SELECT_THERAPY_TYPE_ITEMS:
			let selectedUpdates = {};
			for (let i = action.fromLevel; i < max_therapy_type_levels + therapy_type_root_levels; i++) {
				const _subs = new Array(i).fill('Sub').join('');
				selectedUpdates[`selected${_subs}Item`] = initialState[`selected${_subs}Item`];
			}
			return {...state, ...selectedUpdates};

		default: return state;
	}
};

// Selectors
export const selectors = {
	getTherapyTypeList: (state, level = therapy_type_root_levels) => get(
		state, [reducerStoreKey, `list${new Array(level).fill('Sub').join('')}Data`, 'data']
	),
	getTherapyTypeListPagination: (state, level = therapy_type_root_levels) => ({
		perPage: getInteger(
			state, [reducerStoreKey, `list${new Array(level).fill('Sub').join('')}Data`, 'perPage'], 50
		),
		pageNo: getInteger(
			state, [reducerStoreKey, `list${new Array(level).fill('Sub').join('')}Data`, 'pageNo']
		),
		totalRows: getInteger(
			state, [reducerStoreKey, `list${new Array(level).fill('Sub').join('')}Data`, 'totalResults'])
		,
		isLastPage: getBoolean(
			state, [reducerStoreKey, `list${new Array(level).fill('Sub').join('')}Data`, 'isLastPage']
		)
	}),
	getTherapyTypeListSort: (state, level = therapy_type_root_levels) => ({
		sortBy: get(
			state, [reducerStoreKey, `list${new Array(level).fill('Sub').join('')}Data`, 'sortBy']
		),
		sortDir: getString(
			state, [reducerStoreKey, `list${new Array(level).fill('Sub').join('')}Data`, 'sortDir'], SORT_ORDER.ASC
		)
	}),
	
	getSelectedTherapyTypeItem: (state, level = therapy_type_root_levels) => get(
		state, [reducerStoreKey, `selected${new Array(level).fill('Sub').join('')}Item`]
	),
};

export default reducer;