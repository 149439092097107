import {RESPONSE_DATA_TYPE} from "Core/io/const";
import {app_id} from "Config/app";

// URL -----------------------------------------------------------------------------------------------------------------
/**
 * Base URLs for all supported APIs
 * @type {Object<{[string]: string}>} Object where keys are supported API names and values are base URLs for those APIs.
 */
export const io_base_urls = {
	// Default API
	// @required
	defaultApi: process.env.REACT_APP_API_URL_DEFAULT,
	// Authentication API
	// @required
	authApi: process.env.REACT_APP_API_URL_AUTH,
	// Default API for authorized requests
	// @optional
	defaultAuthorizedApi: process.env.REACT_APP_API_URL_DEFAULT_AUTHORIZED,
};

// Request headers -----------------------------------------------------------------------------------------------------
/**
 * Request headers used for standard requests
 * @note Standard request has query string body.
 * @type {string[][]}
 */
export const io_standard_request_headers = [
	['Accept', '*/*'],
	['Content-Type', 'application/x-www-form-urlencoded'],
	['X-Requested-With', 'XMLHttpRequest'],
];
/**
 * Request headers used for JSON requests
 * @note Standard JSON request has JSON string body.
 * @type {string[][]}
 */
export const io_json_request_headers = [
	['Accept', '*/*'],
	['Content-Type', 'application/json; charset=utf-8'],
];
/**
 * Request headers used for FormData requests
 * @note Form data request has FormData object as body.
 * @type {string[][]}
 */
export const io_form_data_request_headers = [
	['Accept', '*/*'],
];
/**
 * Request headers used for file upload requests
 * @note File upload request has FormData body.
 * @type {string[][]}
 */
export const io_upload_request_headers = [
	['Accept', '*/*'],
];
/**
 * Request headers used for file download requests
 * @note Headers will be appended to the request headers of the base request (standard, json, ...).
 * @type {string[][]}
 */
export const io_download_request_headers = [
	
];

// Request options -----------------------------------------------------------------------------------------------------
/**
 * Default options for requests
 * @description This is used as default options for all IO request functions except the low-level 'ioRequest' function.
 * @see https://javascript.info/fetch-api
 * @type {Object<string, string>} Object with any options that will be sent in th request.
 */
export const io_default_request_options = {
	credentials: process.env.REACT_APP_IO_DEFAULT_REQUEST_OPTIONS_CREDENTIALS
}
/**
 * Local storage key used to store the pause requests counter
 * @description Request with the pause flag set to true will be paused while pause requests counter is greater than 0.
 * @note Requests are paused in all browser tabs.
 * @type {string}
 */
export const io_pause_requests_storage_field_name = `${app_id}_pauseRequests`;
/**
 * Interval for checking if paused requests should resume execution
 * @description Requests that are using the pause flag and are in paused state will use this value to determine the
 * interval for checking if pause flag value has changed.
 * @type {number} Interval in milliseconds.
 */
export const io_pause_requests_check_interval = 100;

// Response ------------------------------------------------------------------------------------------------------------
/**
 * Default response data type
 * @description This is used as default response type for all IO request functions except the low-level 'ioRequest'
 * function.
 * @type {string}
 */
export const io_default_response_data_type = RESPONSE_DATA_TYPE.JSON;

// Processors ----------------------------------------------------------------------------------------------------------
/**
 * Enabled request processors
 * @note Processors will run in the order in which they are defined in this array!
 * @type {string[]} Array of request processor names. Processor names are request processor file name prefixes
 * ('xxxRequestProcessor.js').
 */
export const io_request_processors = ['auth', 'csrfToken', 'userActivity'];
/**
 * Name of the field in locale object that will be used in the country request processor
 * @note Country request processor will add a country code every IO request.
 * @type {string}
 */
export const io_country_request_processor_locale_field = 'countryCodeIso3';
/**
 * Request field name where country code will be added to the request
 * @type {string}
 */
export const io_country_request_processor_request_field = 'countryCode';
/**
 * Flag that determines if country request processor will be used for auth requests
 * @type {boolean}
 */
export const io_use_country_request_processor_for_auth = false;

/**
 * Enabled response processors
 * @note Processors will run in the order in which they are defined in this array!
 * @type {string[]} Array of response processor names. Processor names are response processor file name prefixes
 * ('xxxResponseProcessor.js').
 */
export const io_response_processors = ['error', 'auth', 'data', 'standardJsonError'];
/**
 * Show response status code error messages first
 * @description Flag that specifies if response code error message will be shown instead of a default error message for
 * a given request. This is used by the error response processor to determine the error message that will be shown to
 * the end user when requests fail with a failed status code (when response code is outside 200-299, for example 500).
 * @note Even if this is false, if request does not have an error message defined in translation files it will still
 * display the status code error message.
 * @type {boolean}
 */
export const io_show_response_status_code_error_messages_first = false;

// Security ------------------------------------------------------------------------------------------------------------
/**
 * Cookie name where CSRF token should be located
 * @note This cookie should be created by the server. App will than read the token from the cookie and add it to the
 * header of each request as 'X-Csrf-Token'. This will only work if 'io_request_processors' has 'csrfToken' processor.
 * @type {string}
 */
export const csrf_token_cookie = 'csrf_token';

// Google reCaptcha ----------------------------------------------------------------------------------------------------
/**
 * Flag that specifies if Google reCaptcha v3 will be enabled
 * @note If you need reCaptcha to be loaded and always be shown on every page don't forget to add a 'reCaptchaResource'
 * to main 'App.js'.
 * @type {boolean}
 */
export const google_recaptcha_v3_enabled = false;

/**
 * Google reCaptcha v3 site key
 * @type {string}
 */
export const google_recaptcha_site_key_v3 = '';