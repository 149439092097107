import {cloneDeep, get} from 'lodash';
import {getArray, getNumber, getString} from "Core/helpers/data";
import {
	StatisticsChartSeriesDataObject,
	StatisticsChartSeriesItemDataObject, StatisticsCircularChartDataObject,
	StatisticsDataObject,
	StatisticsValueDataObject
} from "Components/advanced/Statistics/dataObjects";
import {getDate, STANDARD_DATE_TIME_FORMAT} from "Core/helpers/datetime";
import {STATISTICS_TYPE} from "Components/advanced/Statistics/const";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @param {StatisticsType} statisticsType - Statistics type.
 * @return {StatisticsDataObject}
 */
export const input = (rawData, statisticsType) => {
	let result = cloneDeep(rawData);

	result = new StatisticsDataObject(
		getString(result, 'type'),
		getArray(result, 'individualData').map(i => new StatisticsValueDataObject(
			getString(i, 'name'), getNumber(i, 'count')
		)),
		(
			[
				STATISTICS_TYPE.OFFICE_LOCATIONS_LOCATIONS_PATIENTS,
				STATISTICS_TYPE.OFFICE_LOCATIONS_LOCATIONS_PAYMENTS
			].includes(statisticsType) ?
				getArray(result, 'chartData').map(i => new StatisticsCircularChartDataObject(
					getString(i, 'id'), getString(i, 'label'), getNumber(i, 'value'), getString(i, 'color'),
				))
				:
				getArray(result, 'chartData').map(i => new StatisticsChartSeriesDataObject(
					getString(i, 'id'),
					getArray(i, 'data').map(j => new StatisticsChartSeriesItemDataObject(
						(
							[
								STATISTICS_TYPE.OFFICE_LOCATIONS_DAILY_VISITS,
								STATISTICS_TYPE.OFFICE_LOCATIONS_DAILY_PAYMENTS
							].includes(statisticsType) ?
								getDate(getString(j, 'x'), STANDARD_DATE_TIME_FORMAT.ISO_DATE)
								: [
									STATISTICS_TYPE.OFFICE_LOCATIONS_MONTHLY_VISITS,
									STATISTICS_TYPE.OFFICE_LOCATIONS_MONTHLY_PAYMENTS
								].includes(statisticsType) ?
									getDate(getString(j, 'x'), 'yyyy-M')
								: [
									STATISTICS_TYPE.OFFICE_LOCATIONS_YEARLY_VISITS,
									STATISTICS_TYPE.OFFICE_LOCATIONS_YEARLY_PAYMENTS
								].includes(statisticsType) ?
									getDate(getString(j, 'x'), 'yyyy')
								:
									get(j, 'x')
						),
						getNumber(j, 'y'),
						get(j, 'color')
					))
				))
		)
	);

	return result;
}

// @note There is no output function since statistics are read only.