import 'react-quill/dist/quill.snow.css';
import "./index.css";

import React from "react";
import DataComponent, {executeComponentCallback} from "Core/components/DataComponent";
import ReactQuill from 'react-quill';
import {cloneDeep, forOwn, omit, set} from "lodash";
import PropTypes from "prop-types";
import {getUrl} from "Core/helpers/url";
import {waitingFunction} from "Core/helpers/function";
import {getBool, getObject} from "Core/helpers/data";
import {getSkin} from "Core/helpers/skin";
import {Quill} from "react-quill";
import {snakeToCamel} from "Core/helpers/string";

// Text direction to inline
Quill.register(Quill.import("attributors/style/direction"), true);
// Alignment to inline
Quill.register(Quill.import("attributors/style/align"), true);
// Font size to inline
const SizeStyle = Quill.import('attributors/style/size');
SizeStyle.whitelist = ["0.875em", "1.5em", "2.5em"];
Quill.register(SizeStyle, true);
// Text indent to inline
const Parchment = Quill.import("parchment");
class IndentAttributor extends Parchment.Attributor.Style {
	add(node, value) {
		if (value === 0) {
			this.remove(node);
			return true;
		} else {
			return super.add(node, `${value}em`);
		}
	}
}
let IndentStyle = new IndentAttributor("indent", "text-indent", {
	scope: Parchment.Scope.BLOCK,
	whitelist: ["1em", "2em", "3em", "4em", "5em", "6em", "7em", "8em", "9em"]
});
Quill.register(IndentStyle, true);

class RichTextInput extends DataComponent {
	quillRef = null;
	
	constructor(props, initialState = {}, options = {}) {
		const _options = {
			domPrefix: 'rich-text-input-component',
			dataPropAlias: 'value',
			enableLoadOnDataPropChange: true,

			...(options ? cloneDeep(options) : {})
		};

		const _initialState = {
			// Text input's current value
			data: '',

			...(initialState ? cloneDeep(initialState) : {})
		};

		super(props, _initialState, _options);
		
		// Action methods
		this.undo = this.undo.bind(this);
		this.redo = this.redo.bind(this);
		this.print = this.print.bind(this);

		// Dynamic toolbar handler methods
		// @description Handlers must be function references bound to this component (this.someHandlerNameToolbarHandler) 
		// and cannot be anonymous or arrow functions, so we need to go through each handler function, create an internal 
		// method for it and bind it to this component.
		forOwn(getObject(props, 'toolbarHandlers'), (value, key) => {
			this[`${snakeToCamel(key)}ToolbarHandler`] = value;
			this[`${snakeToCamel(key)}ToolbarHandler`] = this[`${snakeToCamel(key)}ToolbarHandler`].bind(this);
		});
	}

	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Undo rich text editor changes
	 */
	undo() {
		if (this.quillRef) this.quillRef.editor.history.undo();
	}

	/**
	 * Redo rich text editor changes
	 */
	redo() {
		if (this.quillRef) this.quillRef.editor.history.redo();
	}

	/**
	 * Print rich text editor content
	 */
	print() {
		// Try getting the DOM element of this component
		const elem = this.getDomElement();
		if (!elem) return;

		// Try getting a rich text editor DOM element that should be printed
		const printElem = elem.querySelector('.ql-container');
		if (!printElem) return;

		// Open a new window for printing
		let printWindow = window.open('', '', 'height=auto, width=auto');
		
		// Remove non-printable child elements from print element
		// @description Quill rich text editor has some hidden clipboard and tooltip elements that need to be removed so 
		// that they don't appear on the printed page.
		if (!!printElem.querySelector('.ql-clipboard')) printElem.querySelector('.ql-clipboard').remove();
		if (!!printElem.querySelector('.ql-tooltip')) printElem.querySelector('.ql-tooltip').remove();
		
		// Write print window HTML
		printWindow.document.write(`<html data-skin-mode="${getSkin()}">`);
		printWindow.document.write('<head>');
		// Specify Quill rich text editor styles used for printing
		printWindow.document.write(`<link rel="stylesheet" href="${getUrl('css/quill.snow.print.css')}" />`);
		// Define a function to set the global 'LOADED' variable of the print window to true when style has been loaded
		printWindow.document.write(`<script>function setLoaded() { window.LOADED = true; }</script>`);
		printWindow.document.write('</head>');
		// Call the function to set the global 'LOADED' variable of the print window to true
		printWindow.document.write(`<body onload="setLoaded()">`);
		printWindow.document.write('<div id="loader-wrapper"><span class="loader"></span></div>');
		printWindow.document.write(printElem.outerHTML);
		printWindow.document.write('</body></html>');
		printWindow.document.close();
		// Wait for 60s for style to be loaded and invoke the browser's print dialog if they do
		// @note This is done because invoking the print dialog before styles are loaded will result in styles not being 
		// applied to the print preview.
		waitingFunction(() => {
			if (getBool(printWindow, 'LOADED')) {
				printWindow.document.getElementById('loader-wrapper').remove();
				printWindow.print();
				return true;
			}
		}, 1, 60000)
			// Close the print window if print dialog has been opened or styles do not load after 60s
			.then(() => printWindow.close());
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	render() {
		const {id, className, formControlStyle, toolbar, ...otherProps} = this.props;
		
		// Get custom toolbar handlers where handler functions are replaced with the bound component methods
		// @see "Dynamic toolbar handler methods" section in the constructor.
		let toolbarHandlers = {};
		forOwn(getObject(this.props, 'toolbarHandlers'), (value, key) => {
			set(toolbarHandlers, key, this[`${snakeToCamel(key)}ToolbarHandler`]);
		});
		
		return (
			<ReactQuill
				id={this.getDomId()}
				className={`${this.getOption('domPrefix')} ${formControlStyle ? 'form-control' : ''} ${className}`}
				modules={{
					toolbar: {
						container: toolbar,
						handlers: {
							undo: this.undo,
							redo: this.redo,
							print: this.print,
							...toolbarHandlers,
						},
					},
					clipboard: {
						matchVisual: false,
					},
				}}
				onChange={value => executeComponentCallback(this.props.onChange, value)}
				{...omit(otherProps, ['onChange'])}
				ref={node => { this.quillRef = node; }}
			/>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
RichTextInput.propTypes = {
	// Rich text component id attribute
	id: PropTypes.string,
	// Rich text component class attribute
	className: PropTypes.string,
	// Rich text component value
	value: PropTypes.string,
	// Flag specifying if rich text component should be read only
	readOnly: PropTypes.bool,
	// Flag that determines if rich text component will have a standard form control style
	formControlStyle: PropTypes.bool,
	
	// Toolbar options
	toolbar: PropTypes.array,
	// Handlers for custom toolbar buttons
	toolbarHandlers: PropTypes.object,
	// DOM Element or a CSS selector for a DOM Element, within which the editor's ui elements (i.e. tooltips, etc.) 
	// should be confined. Currently, it only considers left and right boundaries.
	// @note Default is: document.body
	bounds: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

	// Events
	onChange: PropTypes.func, // Arguments: {string} content
};

/**
 * Define component default values for own props
 */
RichTextInput.defaultProps = {
	id: '',
	className: '',
	value: '',
	readOnly: false,
	formControlStyle: true,
	toolbar: [
		['undo', 'redo'],
		[{size: ["0.875em", false, "1.5em", "2.5em"]}],
		['bold', 'italic', 'underline', 'strike'],
		[{'background': []}, {'color': []}],
		[{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
		[{'list': 'ordered'}, {'list': 'bullet' }, {'list': 'check'}, {'indent': '-1'}, {'indent': '+1'}],
		[{'script': 'sub'}, {'script': 'super' }, 'blockquote'],
		['link', 'image', 'video'],
		['clean'],
		['print'],
	],
	toolbarHandlers: {},
};

export default RichTextInput;