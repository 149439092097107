import {deleteStorageKey, getStorageValue, setStorageValue, STORAGE_TYPE} from "Core/storage";
import {CookieData} from "Core/dataProtection/objects/cookie";
import CookieConsent from "Core/dataProtection/cookieConsent";
import {skin_default_mode, skin_mode_cookie_group} from "Config/app";
import {SKIN_MODE} from "Core/const/global";
import {isFunction} from "lodash";
import {getBoolFromTinyInt, getString} from "Core/helpers/data";

/**
 * Check if operating system uses dark mode
 * @return {boolean}
 */
export const isSystemDarkMode = () => {
	return !!(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);
}

/**
 * Get current app skin mode (dark, light)
 * @return {SkinMode}
 */
export const getSkin = () => {
	// Get system skin mode flag cookie settings
	const systemSkinModeCookie = new CookieData(skin_mode_cookie_group, 'use_system_skin_mode', STORAGE_TYPE.LOCAL);
	// Get skin mode cookie settings
	const skinModeCookie = new CookieData(skin_mode_cookie_group, 'skin_mode', STORAGE_TYPE.LOCAL);
	
	// Try to get saved system skin mode flag
	const savedSystemSkinModeFlag = (
		CookieConsent.isAllowed(systemSkinModeCookie) ?
			getBoolFromTinyInt(getStorageValue('use_system_skin_mode', STORAGE_TYPE.LOCAL)) :
			undefined
	);
	// Try to get saved skin mode
	const savedSkinMode = (
		CookieConsent.isAllowed(skinModeCookie) ?
			getStorageValue('skin_mode', STORAGE_TYPE.LOCAL) :
			undefined
	);

	return (
		savedSystemSkinModeFlag ? (isSystemDarkMode() ? SKIN_MODE.DARK : SKIN_MODE.LIGHT) :
		savedSkinMode ? savedSkinMode : skin_default_mode
	);
};

/**
 * Get if skin mode is set to use the operating system skin
 * @return {boolean}
 */
export const getIsSystemSkinMode = () => {
	// Get system skin mode flag cookie settings
	const systemSkinModeCookie = new CookieData(skin_mode_cookie_group, 'use_system_skin_mode', STORAGE_TYPE.LOCAL);
	
	return (
		CookieConsent.isAllowed(systemSkinModeCookie) ?
			getBoolFromTinyInt(getStorageValue('use_system_skin_mode', STORAGE_TYPE.LOCAL)) :
			false
	);
}

/**
 * Set app skin mode
 * @param {SkinMode} skinMode - Skin mode to set.
 */
export const setSkin = skinMode => {
	// Set skin mode as document body class
	document.documentElement.setAttribute('data-skin-mode', skinMode);
	
	// Save skin mode to cookies if allowed
	const skinModeCookie = new CookieData(skin_mode_cookie_group, 'skin_mode', STORAGE_TYPE.LOCAL);
	if (CookieConsent.isAllowed(skinModeCookie)) {
		setStorageValue('skin_mode', skinMode, STORAGE_TYPE.LOCAL);
	}
	// Delete system skin mode flag cookie
	const systemSkinModeFlagCookie = new CookieData(skin_mode_cookie_group, 'use_system_skin_mode', STORAGE_TYPE.LOCAL);
	if (CookieConsent.isAllowed(systemSkinModeFlagCookie)) {
		deleteStorageKey('use_system_skin_mode', STORAGE_TYPE.LOCAL);
	}
};

/**
 * Set app skin mode to system skin
 */
export const setSystemSkin = () => {
	const systemSkinMode = (isSystemDarkMode() ? SKIN_MODE.DARK : SKIN_MODE.LIGHT);

	// Set system skin mode as document body class
	document.documentElement.setAttribute('data-skin-mode', systemSkinMode);

	// Save system skin mode to cookies if allowed
	const systemSkinModeFlagCookie = new CookieData(skin_mode_cookie_group, 'use_system_skin_mode', STORAGE_TYPE.LOCAL);
	if (CookieConsent.isAllowed(systemSkinModeFlagCookie)) {
		setStorageValue('use_system_skin_mode', '1', STORAGE_TYPE.LOCAL);
	}
	// Set skin mode cookie to the system skin mode
	const skinModeCookie = new CookieData(skin_mode_cookie_group, 'skin_mode', STORAGE_TYPE.LOCAL);
	if (CookieConsent.isAllowed(skinModeCookie)) {
		setStorageValue('skin_mode', systemSkinMode, STORAGE_TYPE.LOCAL);
	}
}

/**
 * Handle skin mode changes
 *
 * @param {function(skinMode: SkinMode)} [callback] - Callback function that will be called when skin mode changes and 
 * receive new skin mode as it's only parameter.
 * @return void
 */
export const onSkinModeChange = callback => {
	const classMutationObserver = new MutationObserver(mutations => {
		for (const mutation of mutations) {
			const oldSkinMode = getString(mutation, 'oldValue');
			const newSkinMode = getString(mutation.target.getAttribute('data-skin-mode'));
			
			if (oldSkinMode && newSkinMode && oldSkinMode !== newSkinMode) {
				const skinChangeEvent = new CustomEvent('onSkinModeChange', {detail: {newSkinMode, oldSkinMode}});
				window.dispatchEvent(skinChangeEvent);
				if (isFunction(callback)) callback(newSkinMode);
			}
		}
	});
	classMutationObserver.observe(document.documentElement, {
		attributes: true,
		attributeFilter: ['data-skin-mode'],
		attributeOldValue: true,
	});
}