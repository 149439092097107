import styles from "../TextInput/index.module.css";
import "./index.css";

import React from "react";
import PropTypes from "prop-types";
import TextInput, {TEXT_INPUT_TOOLBAR_POSITION} from "../TextInput";
import {connect} from "react-redux";
import {getGlobalActions} from "../../../helpers/redux";
import {getString} from "../../../helpers/data";
import {Tooltip} from "react-tippy";
import Icon from "../../display/Icon";
import {icon_font_error_symbol} from "../../../../config";
import {executeComponentCallback} from "../../BaseComponent";
import {
	formatNumber,
	getNumberLocaleCode,
	parseNumber,
	roundNumberToDecimals, 
	safeNumberAdd, 
	safeNumberSub,
	truncNumber
} from "../../../helpers/number";
import {COMMAND_CODES} from "../../../const/global";
import {getNumberLocale} from "../../../helpers/locale";
import {inputInsertValue} from "../../../helpers/dom";
import {NUMBER_INPUT_USE_APP_LOCALE, NUMBER_INPUT_USE_APP_LOCALES} from "./const";
import {selectors} from "../../../store/reducers";
import Button from "../../display/Button";
import {omit} from "lodash";

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	appLocale: selectors.i18n.getLocale(state)
});


/**
 * Number input component
 * @description Text input component that does not update parent component's state on each key press ('fast' component).
 * @note This component uses internal state to handle keypress updates re-rendering only itself until used hits the
 * 'Enter' key or textarea (inputRef) looses focus. This fixes one of the main performance issues in React forms or
 * pages with lost of input fields where each keypress rerender the whole form or page.
 * 
 * This component is based on 'TextInput' component.
 */
class NumberInput extends TextInput {
	constructor(props) {
		super(props, {
			/**
			 * Number input's current value
			 * @type {string}
			 */
			data: '',

			/**
			 * Internal number value
			 * @description This is a number representation of the internal value ('data').
			 * @note This is used to render the proper value when locale changes.
			 */
			numberValue: undefined,
		},  {
			translationPath: 'NumberInput',
			domPrefix: 'number-input-component',
		});

		// Locale methods
		this.getValueLocale = this.getValueLocale.bind(this);
		this.getValueLocaleCode = this.getValueLocaleCode.bind(this);
		this.getRenderLocale = this.getRenderLocale.bind(this);
		this.getRenderLocaleCode = this.getRenderLocaleCode.bind(this);
		this.getRenderDecimalSeparator = this.getRenderDecimalSeparator.bind(this);
		this.getRenderThousandsSeparator = this.getRenderThousandsSeparator.bind(this);

		// Data methods
		this.parseToNumber = this.parseToNumber.bind(this);
		this.parseInternalNumber = this.parseInternalNumber.bind(this);
		this.getRenderNumber = this.getRenderNumber.bind(this);
		this.getMaxNumber = this.getMaxNumber.bind(this);
		this.getMinNumber = this.getMinNumber.bind(this);
		this.getStepNumber = this.getStepNumber.bind(this);
	}


	// I18n -------------------------------------------------------------------------------------------------------------
	/**
	 * Update dynamic translations
	 * @description Dynamic translations are translations that are not called in component's 'render' or
	 * 'componentDidUpdate' methods. Since automatic translation works by updating the component when locale changes,
	 * only values translated in 'render' and 'componentDidUpdate' methods will be automatically translated. All other
	 * translations need to be handled manually using this method.
	 * @note This method will be called after locale change has been handled.
	 *
	 * @param {object} translation - Currently loaded translation object.
	 */
	updateDynamicTranslations(translation) {
		// Update rendered input value to the new locale
		if (typeof this.state.numberValue !== 'undefined') this.setData(this.getDataToLoad(this.state.numberValue));
	}
	

	// Locale methods ---------------------------------------------------------------------------------------------------
	/**
	 * Get value locale object
	 * @description Value locale is used to parse the 'value', 'min' and 'max' props into numbers used by the component.
	 * @note Component will always return a Number or undefined in the 'onChange' trigger no matter what locale is used 
	 * to parse or render the value.
	 * @return {LocaleObj}
	 */
	getValueLocale() {
		const {useAppLocaleCode, valueLocale} = this.props;
		return (
			valueLocale ? 
				valueLocale : (
					useAppLocaleCode === NUMBER_INPUT_USE_APP_LOCALE.VALUE ||
					useAppLocaleCode === NUMBER_INPUT_USE_APP_LOCALE.BOTH ? 
						this.getProp('appLocale') : null
				)
		);
	}

	/**
	 * Get value locale code
	 * @return {string} Lowercase IETF BCP 47 language tag used in the app's locale object as 'locale' property (ex:
	 * 'sr-latn-rs').
	 */
	getValueLocaleCode() { return getNumberLocaleCode(this.getValueLocale()); }

	/**
	 * Get render locale object
	 * @description Render locale is used to render the value inside the input element.
	 * @note Component will always return a Number or undefined in the 'onChange' trigger no matter what locale is used 
	 * to parse or render the value.
	 * @return {LocaleObj}
	 */
	getRenderLocale() {
		const {useAppLocaleCode, renderLocale} = this.props;
		return (
			renderLocale ? 
				renderLocale : (
					useAppLocaleCode === NUMBER_INPUT_USE_APP_LOCALE.RENDER ||
					useAppLocaleCode === NUMBER_INPUT_USE_APP_LOCALE.BOTH ?
						this.getProp('appLocale') : null
				)
		);
	}

	/**
	 * Get render locale code
	 * @return {string} Lowercase IETF BCP 47 language tag used in the app's locale object as 'locale' property (ex:
	 * 'sr-latn-rs').
	 */
	getRenderLocaleCode() { return getNumberLocaleCode(this.getRenderLocale()); }

	/**
	 * Get the decimal separator that should be used in the input element
	 * @note This depends on render locale. If locale is not specified '.' will be returned.
	 */
	getRenderDecimalSeparator() {
		return getString(getNumberLocale(this.getRenderLocale()), 'delimiters.decimal', '.');
	}

	/**
	 * Get the thousands separator that should be used in the input element
	 * @note This depends on render locale. If locale is not specified ',' will be returned.
	 */
	getRenderThousandsSeparator() {
		return getString(getNumberLocale(this.getRenderLocale()), 'delimiters.thousands', ',');
	}


	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Parse any supported value to a number value
	 * @note Number and string values are supported where number will be return as-is and string will be parsed using
	 * specified locale code and decimal precision.
	 *
	 * @param {number|string} value
	 * @param {string} [localeCode] - Locale code to use when trying to parse string values into numbers. If not 
	 * specified default locale will be english which is a default way of JavaScript deals with numbers.
	 * @param {number} [decimalPrecision] - Decimal precision used to round the number. If not specified, number will not
	 * be changed.
	 * @return {number|undefined} Number value or undefined.
	 */
	parseToNumber(value, localeCode, decimalPrecision) {
		// Only string and number value are supported
		if (typeof value !== 'number' && typeof value !== 'string') return undefined;

		let result;

		// Handle number values
		if (typeof value === 'number') result = value;
		// Handle string values
		else {
			const numberValue = parseNumber(value, localeCode);
			result = (!isNaN(numberValue) ? numberValue : undefined);
		}

		// Round the number to specified number of decimals
		if (typeof result !== 'undefined' && typeof decimalPrecision !== 'undefined') {
			result = roundNumberToDecimals(result, decimalPrecision);
		}

		return result;
	}

	/**
	 * Get number value that can be used internally by this component
	 * @note This method uses locale related and other props to get the number.
	 * 
	 * @param {any} value - Any value but only valid number strings and numbers will provide a result number.
	 * @return {number|undefined}
	 */
	parseInternalNumber(value) {
		const {intOnly} = this.props;

		// Try to get the number value form any value
		// @note Only number and string values are supported. Number strings will be parsed using 'valueLocale'.
		let numberValue = this.parseToNumber(value, this.getValueLocaleCode());

		// If 'intOnly' prop is true, decimal value will be removed (no additional rounding will occur)
		if (typeof numberValue === 'number' && intOnly) numberValue = truncNumber(numberValue);

		// Return the number value or undefined
		return numberValue;
	}
	
	/**
	 * Get number value from rendered value
	 * @description Rendered value is the value that can be displayed inside the input element which means it is 
	 * localized and formatted according to render related props.
	 *
	 * @param {any} value - Any value but only valid number strings and numbers will provide a result number.
	 * @return {number|undefined}
	 */
	getRenderNumber(value) {
		const {intOnly} = this.props;

		// Try to get the number value form any value
		// @note Only number and string values are supported. Number strings will be parsed using 'renderLocale'.
		let numberValue = this.parseToNumber(value, this.getRenderLocaleCode());

		// If 'intOnly' prop is true, decimal value will be removed (no rounding will occur)
		if (typeof numberValue === 'number' && intOnly) numberValue = truncNumber(numberValue);

		// Return the number value or undefined
		return numberValue;
	}

	/**
	 * Get max number value
	 * @note Same locale and decimal precision will be used as for the value because it is a value, maximal value.
	 * @return {number|undefined}
	 */
	getMaxNumber() { return this.parseInternalNumber(this.getProp('max')); }

	/**
	 * Get min number value
	 * @note Same locale and decimal precision will be used as for the value because it is a value, minimal value.
	 * @return {number|undefined}
	 */
	getMinNumber() { return this.parseInternalNumber(this.getProp('min')); }

	/**
	 * Get step number value used to inc/dec the value when pressing arrow keys
	 * @note Same locale will be used as for the value because it is a value, step value. Decimal precision does not 
	 * apply.
	 * @return {number|undefined}
	 */
	getStepNumber() { return this.parseToNumber(this.getProp('step'), this.getValueLocaleCode()); }

	/**
	 * Load data into local component's state and call component's update method if data is different than current data
	 * @note This method will not mutate the passed data.
	 *
	 * @param {any} data - Data to load into local component's state. If data is the same as current local component's
	 * state data it will not be loaded and component's update method will not be called.
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been loaded.
	 */
	defaultLoad(data) {
		return super.defaultLoad(data)
			// Autocorrect data
			// @note This is done only if 'autocorrectValue' or 'autocorrectToMinMax' is true.
			.then(() => {
				if (this.getProp('autocorrectValue') || this.getProp('autocorrectToMinMax')) {
					let autocorrectedValue;
					
					// Autocorrect string value to a number value
					if (this.getProp('autocorrectValue')) autocorrectedValue = this.getDataToReturn();

					// Autocorrect min/max to value
					if (this.getProp('autocorrectToMinMax')) {
						// Handle max and min values
						const value = this.getDataToReturn();
						const maxValue = this.getMaxNumber();
						const minValue = this.getMinNumber();
						if (typeof value !== 'undefined') {
							if (typeof maxValue !== 'undefined' && value > maxValue) autocorrectedValue = maxValue;
							else if (typeof minValue !== 'undefined' && value < minValue) autocorrectedValue = minValue;
						}
					}

					// Trigger 'onChange' event to correct the value received through props
					if (autocorrectedValue) executeComponentCallback(this.props.onChange, autocorrectedValue);
				}
			})
			// Update internal number value
			// @note Internal number value is used to render the proper value when locale changes
			.then(() => this.setState({numberValue: this.parseInternalNumber(data)}));
	}

	/**
	 * Get data to load into local component's state
	 * @description Create and return data that can be loaded directly into local component's state based on the raw
	 * external data (usually sent through props). In some sense this is a method that maps external data into format
	 * that component can use in its local state. This method should return data in the same format as 'getData' method.
	 * @note This method will not mutate the passed data.
	 *
	 * @param {any} rawData - External data that will be used to create local component's state compatible data.
	 * @return {string} Local component's state compatible data.
	 */
	getDataToLoad(rawData) {
		// Insert values will be loaded as is
		if (typeof rawData === 'string' && this.containsInsertValue(rawData)) return rawData;
		// Data will be loaded as a number string in the proper locale, rounded or truncated and formatted based on props
		else {
			return formatNumber(
				this.parseInternalNumber(rawData), this.getProp('renderFormat'), this.getRenderLocaleCode()
			);
		}
	}

	/**
	 * Get data that will be returned to the parent component
	 * @description Create and return data that will be returned to paren component (usually through onChange event)
	 * based on the local component's raw state data
	 * @note This method will not mutate the passed data.
	 *
	 * @return {number} Data that will be returned to the parent component.
	 */
	getDataToReturn() {
		const data = this.state.data;
		if (typeof data === 'string' && this.containsInsertValue(data)) return data;
		else return this.getRenderNumber(this.state.data); 
	}

	// Data change handling methods -------------------------------------------------------------------------------------
	/**
	 * Input element key down handler
	 * @param {KeyboardEvent|SyntheticEvent} event - JavaScript key down event.
	 * @return {Promise<object>} Promise that is resolved with entire component's local state after it has been updated.
	 */
	handleKeyDown(event) {
		const {value, readOnly, intOnly} = this.props;
		const stateValue = this.state.value;
		const step = (this.getStepNumber() ? this.getStepNumber() : 1);

		if (!readOnly) {
			// Stop propagation of the escape key press
			// @description This is done to prevent parent elements from catching the escape key press while this input 
			// element is focused because we explicitly want escape key to be handled by it. For example, this is 
			// necessary to prevent closing a dialog, that closes on escape key press, when escape key is pressed on a 
			// focused input.
			if(event.key === 'Escape') {
				event.stopPropagation();
				event.nativeEvent.stopImmediatePropagation();

				// If current value is changes but component didn't update (user typed something but didn't press enter or 
				// moved to another element)
				if (stateValue !== value) {
					// Reset value to the original one (props value)
					return this.setData(this.getDataToLoad(value));
				}
				// If input have not changed 
				else {
					// Blur the input element
					// @note This will in turn re-enable propagation so that dialogs can close next time escape key is 
					// pressed.
					this.inputRef.blur();
					return Promise.resolve({...this.state});
				}
			}

			// Increase/decrease value on arrow key down
			else if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
				event.preventDefault();

				// Get update (inc/dec) number value
				let updateValue = (event.shiftKey ? step * 10 : step);

				// Get current number value
				let currentNumberValue = this.getRenderNumber(this.getData());
				if (typeof currentNumberValue === 'undefined') currentNumberValue = 0;

				// Get updated value
				const updatedValue = (
					event.key === 'ArrowUp' ? 
						safeNumberAdd(currentNumberValue, updateValue) :
						safeNumberSub(currentNumberValue, updateValue)
				);
				
				// Update the value
				this.setData(this.getDataToLoad(updatedValue));
			}

			// Input restrictions and advanced sanitization
			else {
				let valueUpdated = false;
				const decimalSeparator = (intOnly ? '' : this.getRenderDecimalSeparator());
				const thousandsSeparator = this.getRenderThousandsSeparator();
				const charRegex = new RegExp(`[^\\d-+e\\${decimalSeparator}\\${thousandsSeparator}]`, 'i');

				// Restrict character input to support characters only based on render locale
				if (
					!event.ctrlKey && !event.altKey && COMMAND_CODES.indexOf(event.nativeEvent.code) === -1 &&
					charRegex.test(event.key))
				{
					event.preventDefault();
				}
				// Advanced input sanitization and character restriction
				else {
					// Convert thousands separator into decimal separator
					// @description This is done because only decimal separator can be input. Thousands separator will be added 
					// automatically and cannot be added manually. Only one decimal separator is allowed. If there is already a 
					// decimal separator in the input, nothing will be added.
					if (event.key === thousandsSeparator) {
						event.preventDefault();
						if (event.target.selectionStart > 0 && event.target.value.indexOf(decimalSeparator) === -1) {
							inputInsertValue(event.target, decimalSeparator);
							valueUpdated = true;
						}
					}

					// Handle decimal separator 
					else if (event.key === decimalSeparator) {
						if (
							// Allow only one decimal separator
							event.target.value.indexOf(decimalSeparator) !== -1 ||
							// Do not allow decimal separator at the beginning
							!event.target.value.length || !event.target.selectionStart
						) {
							event.preventDefault();
						}
					}

					// Handle exponent character ('E' or 'e' character)
					else if (event.key.toLowerCase() === 'e') {
						if (
							// Allow only one exponent character
							/[e]/i.test(event.target.value) ||
							// Do not allow exponent character at the beginning
							!event.target.value.length || !event.target.selectionStart
							// 
						) {
							event.preventDefault();
						}
						// Convert exponent character to lowercase
						else if (event.key === 'E') {
							event.preventDefault();
							inputInsertValue(event.target, 'e');
							valueUpdated = true;
						}
					}
				}

				// Update internal value if input element value has been changed 
				if (valueUpdated) this.setData(event.target.value);
			}
		}
	}

	/**
	 * Trigger component's onChange event with component's main data as param
	 *
	 * @param {Event|SyntheticEvent} [event] - JavaScript change event.
	 * @return {any|null} Any return value from 'onChange' event function or null.
	 */
	async update(event) {
		// Persist event in order for it to work asynchronously (in promise then for example)
		if (event) event.persist();

		// Handle max and min values
		const value = this.getDataToReturn();
		const maxValue = this.getMaxNumber();
		const minValue = this.getMinNumber();
		if (typeof value !== 'undefined') {
			if (typeof maxValue !== 'undefined' && value > maxValue) {
				await this.setData(this.getProp('autocorrectToMinMax') ? this.getDataToLoad(maxValue) : '');
			} else if (typeof minValue !== 'undefined' && value < minValue) {
				await this.setData(this.getProp('autocorrectToMinMax') ? this.getDataToLoad(minValue) : '');
			}
		}

		// Update internal value
		// @note This is done to update input element value with the proper value
		await this.setData(this.getDataToLoad(this.getDataToReturn()));
		
		// Update internal number value
		// @note Internal number value is used to render the proper value when locale changes 
		await this.setState({numberValue: this.getDataToReturn()});
		
		// Update preview
		return this.updatePreview()
			// Trigger onChange event
			.then(() => executeComponentCallback(this.props.onChange, this.getDataToReturn()));
	}


	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render input DOM element
	 * @return {JSX.Element}
	 */
	renderInput() {
		const {
			className, name, readOnly, disabled, formControlStyle, isFast, list, max, min, invalidValueAsPlaceholder,
			autoComplete, inputToolbarButtons, showInsertValueButton, showClearButton
		} = this.props;
		const inputToolbarButtonsL = inputToolbarButtons.filter(b => b.position === TEXT_INPUT_TOOLBAR_POSITION.LEFT);
		const inputToolbarButtonsR = inputToolbarButtons.filter(b => b.position === TEXT_INPUT_TOOLBAR_POSITION.RIGHT);

		// Get values
		const propValue = this.getProp('value');
		const value = this.getData();
		const numberValue = this.getRenderNumber(value);
		const isValueInvalid = (!value && propValue && typeof this.parseInternalNumber(propValue) === 'undefined');
		const hasInsertValue = (typeof value === 'string' && this.containsInsertValue(value));

		// Show error if input value received through props is invalid, too big or too small
		let errors = this.getProp('errors');
		if (!hasInsertValue && value !== '') {
			if (isValueInvalid) errors = errors.concat([`${this.t('Invalid value')}: ${propValue}`]);
			else if (numberValue > max) errors = errors.concat([`${this.t('Value too large', 'validation')}`]);
			else if (numberValue < min) errors = errors.concat([`${this.t('Value too small', 'validation')}`]);
		}
		
		// Get input placeholder
		let placeholder = this.getProp('placeholder');
		if (!hasInsertValue && value !== '') {
			if (invalidValueAsPlaceholder && isValueInvalid) placeholder = propValue;
		}

		// @note Input type is hardcoded to 'tel' to avoid all the unfinished and idiotic browser implementations of the 
		// input type 'number' while retaining the num-pad input functionality on mobile devices. Browser implementation
		// (if it can be called that!) of input type 'number' makes it impossible to handle localization in any reliable 
		// way so, for now, 'tel' is the only solution.
		const type = 'tel';

		return (
			<div className={`text-input-wrapper ${styles['inputWrapper']}`}>
				<div className={`input-toolbar ${styles['input-toolbar']} position-left ${styles[`position-left`]}`}>
					{inputToolbarButtonsL.map(btnProps =>
						<Button
							{...omit(btnProps, ['className', 'onClick'])}
							className={
								`${getString(btnProps, 'className')}` +
								` input-toolbar-button ${styles['input-toolbar-button']}`
							}
							onClick={btnProps?.onClick ? e => btnProps.onClick(e, this) : undefined}
						/>
					)}
					{!readOnly && !disabled && showInsertValueButton ? this.renderInsertButton() : null}
				</div>

				{
					hasInsertValue ?
						<input type="text"
							id={this.getDomId()}
							className={
								`${this.getOption('domPrefix')} ${formControlStyle ? 'form-control' : ''} ${className} ` +
								`${styles['input']} insert-value-input`
							}
							name={name}
							value={value}
							onInput={!readOnly && !isFast ? super.handleInputChange.bind(this) : null}
							onChange={!readOnly ? super.handleInputChange.bind(this) : null}
							onBlur={!readOnly && isFast ? super.handleInputBlur.bind(this) : null}
							onFocus={super.handleInputFocus.bind(this)}
							onKeyPress={!readOnly ? super.handleKeyPress.bind(this) : null}
							onKeyDown={!readOnly ? super.handleKeyDown.bind(this) : null}
							onKeyUp={!readOnly ? super.handleInputKeyUp.bind(this) : null}
							onPaste={!readOnly ? super.handleInputPaste.bind(this) : null}
							onClick={super.handleInputClick.bind(this)}
							disabled={disabled}
							placeholder={placeholder}
							readOnly={readOnly}
							autoComplete={autoComplete}
							list={list}
							ref={node => this.inputRef = node}
						/>
						:
						<input type={type}
							id={this.getDomId()}
							className={
								`${this.getOption('domPrefix')} ${formControlStyle ? 'form-control' : ''} ${className} ` +
								`${styles['input']}`
							}
							name={name}
							value={value}
							onInput={!readOnly && !isFast ? this.handleInputChange : null}
							onChange={!readOnly ? this.handleInputChange : null}
							onBlur={!readOnly && isFast ? this.handleInputBlur : null}
							onFocus={this.handleInputFocus}
							onKeyPress={!readOnly ? this.handleKeyPress : null}
							onKeyDown={!readOnly ? this.handleKeyDown : null}
							onKeyUp={!readOnly ? this.handleInputKeyUp : null}
							onPaste={!readOnly ? this.handleInputPaste : null}
							onClick={this.handleInputClick}
							disabled={disabled}
							placeholder={placeholder}
							readOnly={readOnly}
							autoComplete={autoComplete}
							list={list}
							ref={node => this.inputRef = node}
						/>
				}
				
				{
					errors.length ?
						<Tooltip
							className={styles['errorIcon']}
							tag="span"
							title={errors.join("<br />")}
							size="small"
							position="top-center"
							arrow={true}
							interactive={false}
							supportHtml={true}
						>
							<Icon symbol={icon_font_error_symbol} />
						</Tooltip>
						: null
				}

				<div
					className={`input-toolbar ${styles['input-toolbar']} position-right ${styles[`position-right`]}`}
				>
					{inputToolbarButtonsR.map(btnProps =>
						<Button
							{...omit(btnProps, ['className', 'onClick'])}
							className={
								`${getString(btnProps, 'className')}` +
								` input-toolbar-button ${styles['input-toolbar-button']}`
							}
							onClick={btnProps?.onClick ? e => btnProps.onClick(e, this) : undefined}
						/>
					)}
					{!readOnly && !disabled && showClearButton ? this.renderClearButton() : null}
				</div>
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
NumberInput.propTypes = {
	/* Number input specific props ----------------------------------------------------------------------------------- */
	// The id of the <datalist> element that contains the optional pre-defined autocomplete options
	list: PropTypes.any,
	// The maximum value to accept for this input
	max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	// The minimum value to accept for this input
	min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	// Flag that determines if input value will be automatically changed to min value if value is smaller than min and 
	// changed to max value if value is larger than max. If false and value is out of min-max range it will be  cleared 
	// (set to undefined). This works independently from 'autocorrectValue'.
	// @note This will trigger 'onChange' event and number value or undefined will be returned to the parent component 
	// which will practically change the value in the parent component.
	autocorrectToMinMax: PropTypes.bool,
	// A stepping interval to use when using up and down arrows to adjust the value
	step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	// Flag that specifies if only integer values are supported
	// @note Float values will not be rounder. Decimal value will just be removed. If you want to round decimal values 
	// use the 'renderFormat' prop.
	intOnly: PropTypes.bool,
	// Flag that specifies if invalid value will be rendered inside input placeholder
	// @note Number out of min-max bound are not considered invalid values. Only non-numeric values are invalid.
	invalidValueAsPlaceholder: PropTypes.bool,
	// Flag that specifies if value received through props will be automatically parsed to the proper value
	// @note This will trigger 'onChange' event and number value or undefined will be returned to the parent component 
	// which will practically change the value in the parent component. This will not autocorrect min and max values. Use
	// 'autocorrectToMinMax' prop to do that.
	autocorrectValue: PropTypes.bool,
	
	/* Locale props -------------------------------------------------------------------------------------------------- */
	// This will determines if current app locale will be used as default locale for parsing/rendering the value
	// @note Parsing and rendering locales can be overridden by 'valueLocale' and 'renderLocale' props.
	useAppLocaleCode: PropTypes.oneOf(NUMBER_INPUT_USE_APP_LOCALES),
	// Set locale used to parse the string value and min and max value strings
	// @note This will override the 'useAppLocaleCode' prop for parsing the value.
	valueLocale: PropTypes.object,
	// Set the locale used to render number strings
	// @note This will override the 'useAppLocaleCode' prop for rendering the value.
	renderLocale: PropTypes.object,
	// Set render number format
	// @note Use numeral js format pattern. If not specified pattern will be automatically generated based on the number
	// value (integer or float) where float values will have the minimal number of decimal places required to render it.
	renderFormat: PropTypes.string,

	/* Text input props ---------------------------------------------------------------------------------------------- */
	...TextInput.propTypes,
	
	/* Events -------------------------------------------------------------------------------------------------------- */
	// Value change event
	// @description This event will be triggers when input value changes. Changes are optimized for performance and won't
	// trigger on every key press.
	onChange: PropTypes.func, // Arguments: {Number|undefined} - New number value.
}

/**
 * Define component default values for own props
 */
NumberInput.defaultProps = {
	/* Number input specific props ----------------------------------------------------------------------------------- */
	autocorrectToMinMax: false,
	step: 1,
	intOnly: false,
	invalidValueAsPlaceholder: true,
	autocorrectValue: false,

	/* Locale props -------------------------------------------------------------------------------------------------- */
	useAppLocaleCode: NUMBER_INPUT_USE_APP_LOCALE.RENDER,
	renderFormat: '',
	
	/* Text input props ---------------------------------------------------------------------------------------------- */
	...TextInput.defaultProps,
};

export * from "../TextInput/const";
export default connect(mapStateToProps, getGlobalActions(), null, {forwardRef: true})(NumberInput);