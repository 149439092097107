import {getString} from 'Core/helpers/data';
import {find, uniq} from 'lodash';

/**
 * Escape special characters for use in a regular expression
 *
 * @param {string} strToEscape - String to escape regular expression special characters.
 * @return {*} String with escaped regular expression special characters.
 */
export const escapeRegExp = (strToEscape) => {
	return strToEscape.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
};

/**
 * Trim specific character from the beginning and the end of a string
 *
 * @param {string} string - String to trim.
 * @param {String} trimChar - Character used for trimming.
 * @return {string}
 */
export const trimChar = (string, trimChar) => {
	trimChar = escapeRegExp(trimChar);
	const regExp = new RegExp(`^[${trimChar}]+|[${trimChar}]+$`, "g");
	return string.replace(regExp, "");
};

/**
 * Trim specific character from the beginning of a string
 * @note Left 'trimChar' function.
 *
 * @param {string} string - String to trim.
 * @param {String} trimChar - Character used for trimming.
 * @return {string}
 */
export const ltrimChar = (string, trimChar) => {
	trimChar = escapeRegExp(trimChar);
	const regExp = new RegExp(`^[${trimChar}]+`, "g");
	return string.replace(regExp, "");
}

/**
 * Trim specific character from the end of a string
 * @note Right 'trimChar' function.
 *
 * @param {string} string - String to trim.
 * @param {String} trimChar - Character used for trimming.
 * @return {string}
 */
export const rtrimChar = (string, trimChar) => {
	trimChar = escapeRegExp(trimChar);
	const regExp = new RegExp(`[${trimChar}]+$`, "g");
	return string.replace(regExp, "");
}

/**
 * Trim a substring from the beginning and the end of a string
 * 
 * @param {string} string - String to trim.
 * @param {string} trimSubstring - Substring used for trimming.
 * @return {string}
 */
export const trimString = (string, trimSubstring) => {
	trimSubstring = escapeRegExp(trimSubstring);
	const regExp = new RegExp(`^(?:${trimSubstring})+|(?:${trimSubstring})+$`, 'g');
	return string.replace(regExp, "");
};

/**
 * Trim a substring from the beginning
 * @note Left 'trimString' function.
 *
 * @param {string} string - String to trim.
 * @param {string} trimSubstring - Substring used for trimming.
 * @return {string}
 */
export const ltrimString = (string, trimSubstring) => {
	trimSubstring = escapeRegExp(trimSubstring);
	const regExp = new RegExp(`^(?:${trimSubstring})+`);
	return string.replace(regExp, "");
};

/**
 * Trim a substring from the end of a string
 * @note Right 'trimString' function.
 *
 * @param {string} string - String to trim.
 * @param {string} trimSubstring - Substring used for trimming.
 * @return {string}
 */
export const rtrimString = (string, trimSubstring) => {
	trimSubstring = escapeRegExp(trimSubstring);
	const regExp = new RegExp(`(?:${trimSubstring})+$`);
	return string.replace(regExp, "");
};

/**
 * Pad string from the start with specified pad character
 * 
 * @param {string} string - String to pad.
 * @param {number} [length=0] - Target length of the result string.
 * @param {string} [padChar=' '] - Character to use as padding.
 * @return {string}
 */
export const lPadString = (string, length = 0, padChar = ' ') => {
	if (typeof string === 'string') {
		if (string.length < length) return new Array((length - string.length) + 1).join(padChar) + string;
		else return string;
	} else return new Array(length).join(padChar);
};

/**
 * Pad string from the end with specified pad character
 *
 * @param {string} string - String to pad.
 * @param {number} [length=0] - Target length of the result string.
 * @param {string} [padChar=' '] - Character to use as padding.
 * @return {string}
 */
export const rPadString = (string, length, padChar = '') => {
	if (typeof string === 'string') {
		if (string.length < length) return string + new Array((length - string.length) + 1).join(padChar);
		else return string;
	} else return new Array(padChar.length + 1).join(padChar);
};

/**
 * Decode query string
 * @note Revers of 'decodeURLParams' function.
 * @description Extract query string from the specified string and convert it into a key/value object where keys are 
 * query params.
 *
 * @param {string} search - URL or any other string containing query string.
 * @returns {*} Object where keys are query params and values are query param values.
 */
export const decodeURLParams = search => {
	const hashes = search.slice(search.indexOf("?") + 1).split("&");
	return hashes.reduce((params, hash) => {
		const split = hash.indexOf("=");
		if (split < 0) return Object.assign(params, {[hash]: null});
		return Object.assign(params, { [hash.slice(0, split)]: decodeURIComponent(hash.slice(split + 1)) });
	}, {});
};

/**
 * Encodes (stringifies) params object into query string
 * @note Revers of 'decodeURLParams' function.
 * @param {Object} params - Object where keys are param names and values are param values. 
 * @return {string}
 */
export const encodeURLParams = params => {
	return Object.keys(params)
		.map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
		.join('&');
};

/**
 * Convert bytes to "human-readable" file sizes (KB, MB, GB, ...)
 *
 * @param {number} bytes - Number of bytes.
 * @param {number} decimals - Number of decimal points to round to.
 * @return {string} "human-readable" file sizes (KB, MB, GB, ...).
 */
export const formatBytes = (bytes, decimals = 2) => {
	if (bytes === 0) return '0 Bytes';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

/**
 * Convert snake case (with - or _) into camelcase
 * @example
 * 	snakeToCamel('my-value'); // 'myValue'
 * 	snakeToCamel('my_value'); // 'myValue'
 *
 * @param {string} str - Snake case string.
 * @return {string} Camelcase string.
 */
export const snakeToCamel = str => str.replace(
	/([-_][a-z])/g,
	(group) => group.toUpperCase()
		.replace('-', '')
		.replace('_', '')
);

/**
 * Convert camelcase into snake case (with - or _)
 * @example
 * 	snakeToCamel('myValue', '-'); // 'my-value'
 * 	snakeToCamel('MyValue'); // 'my_value'
 *
 * @param {string} str - Snake case string.
 * @param {string} [snakeChar='_'] - Snake case separator character.
 * @return {string} Snake case string.
 */
export const camelToSnake = (str, snakeChar = '_') => str.replace(
	/[A-Z]/g,
	(letter, index) => (index === 0 ? letter.toLowerCase() : `${snakeChar}${letter.toLowerCase()}`)
);

/**
 * Capitalize the first letter of a given string
 * @example
 * 	capitalize('testString'); // 'TestString'
 * 
 * @param {string} str - String to capitalize.
 * @return {string} String with capitalized first letter.
 */
export const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);

/**
 * Convert any string into CSS safe name (like id or class name)
 * 
 * @param {string} s - String to convert
 * @return {string}
 */
export const safeCssName = (s = '') => 
	encodeURIComponent(s.toLowerCase()).replace(/%[0-9A-F]{2}/gi,'');

/**
 * Convert css string (like "width: 100px; height: auto;") into JS object
 *
 * @param {string} cssStyleString - String containing css values.
 * @param {boolean} [toCamelCase=true] - Use this if 'cssStyleString' is in snake-case, and you want the end result to 
 * have object keys in camelCase.
 * @return {Object} JS object representation of the css string
 */
export const cssStyleStringToObject = (cssStyleString, toCamelCase = true) => {
	let result = {};

	if (cssStyleString) {
		const regex = /([\w-]*)\s*:\s*([^;]*)/g;
		let match;
		let properties = {};
		// eslint-disable-next-line no-cond-assign
		while(match = regex.exec(cssStyleString)) {
			properties[(toCamelCase ? snakeToCamel(match[1]) : match[1])] = match[2].trim();
		}
		result = properties;
	}

	return result;
};

/**
 * JavaScript 'lastIndexOf' function using regular expression as search value
 *
 * @param {RegExp} regex - Regular expression used as search value.
 * @param {number} [fromIndex] - An integer representing the index at which to start the search. By default, it is set 
 * to the length of teh given string. If less than 0 it will be set to 0.
 * @param {string} string - Source string to perform the search on.
 * @return {number} The index of the last occurrence of search value defined by regular expression, or -1 if not found.
 */
export const regexLastIndexOf = (regex, fromIndex, string) => {
	regex = (regex.global) ? 
		regex : 
		new RegExp(regex.source, "g" + (regex.ignoreCase ? "i" : "") + (regex.multiline ? "m" : ""))
	;
	if(typeof (fromIndex) == "undefined") {
		fromIndex = string.length;
	} else if(fromIndex < 0) {
		fromIndex = 0;
	}
	const stringToWorkWith = string.substring(0, fromIndex + 1);
	let lastIndexOf = -1;
	let nextStop = 0;
	let result;
	while((result = regex.exec(stringToWorkWith)) != null) {
		lastIndexOf = result.index;
		regex.lastIndex = ++nextStop;
	}
	return lastIndexOf;
}

/**
 * JavaScript 'indexOf' function using regular expression as search value
 *
 * @param {RegExp} regex - Regular expression used as search value.
 * @param {number} [fromIndex=0] - An integer representing the index at which to start the search.
 * @param {string} string - Source string to perform the search on.
 * @return {number} The index of the first occurrence of search value defined by regular expression, or -1 if not found.
 */
export const regexIndexOf = (regex, fromIndex = 0, string) => {
	const indexOf = string.substring(fromIndex || 0).search(regex);
	return (indexOf >= 0) ? (indexOf + (fromIndex || 0)) : indexOf;
}

/**
 * JavaScript equivalent to PHP's 'nl2br' function
 * @link https://locutus.io/php/strings/nl2br/
 *
 * @param {string|null} string - The input string.
 * @param {boolean} [isXhtml] - Whether to use XHTML compatible line breaks or not.
 * @return {string} Returns the altered string.
 */
export const nl2br = (string, isXhtml) => {
	// Some latest browsers when string is null return and unexpected null value
	if (typeof string === 'undefined' || string === null) return '';
	
	// Adjust comment to avoid issue on locutus.io display
	// eslint-disable-next-line no-useless-concat
	const breakTag = (isXhtml || typeof isXhtml === 'undefined') ? '<br ' + '/>' : '<br>';
	
	return (string + '').replace(/(\r\n|\n\r|\r|\n)/g, breakTag + '$1');
}

/**
 * Splice string 
 * @description Like standard javaScript Array.splice but for strings.
 * @note This function does not mutate the original string.
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/splice
 * 
 * @param {string} str - String to splice.
 * @param {number} start
 * @param {number} [deleteCount]
 * @param [items]
 * @return {string} Spliced string.
 */
export const spliceStr = (str, start, deleteCount, ...items) => {
	let ar = str.split('');
	ar.splice(start, deleteCount, ...items);
	return ar.join('');
}

/**
 * Check if a given string is in the valid email format
 * @note This function does not check if the email address actually exists. It only checks the string format. 
 * @param {string} string - String to check.
 * @return {boolean}
 */
export const isEmailString = string => {
	const checkRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return checkRegex.test(String(string).toLowerCase());
};

/**
 * Generate random password
 * 
 * @param {number} [length=12] - Password size.
 * @param {string} [chars] - Characters to use.
 * @return {string}
 */
export const generatePassword = (
	length = 12,
	chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ"
) => {
	let result = "";
	for (let i = 0; i <= length; i++) {
		const randomNumber = Math.floor(Math.random() * chars.length);
		result += chars.substring(randomNumber, randomNumber + 1);
	}
	return result;
}

/**
 * Check if a given string has at least one non-latin character
 * 
 * @param {string} string - String to check for non-latin characters.
 * @return {boolean}
 */
export const hasNonLatinChars = string => {
	/* eslint-disable no-control-regex */
	const regex = /[^\u0000-\u00ff]/;
	
	if (!string.length) return false;
	// If first character is non-latin return true to increase performance since regula expression does not need ot run
	if (string.charCodeAt(0) > 255) return true;
	return regex.test(string);
};

/**
 * Get character indexes of a query string for a given string
 * @note This function is usually used to highlight characters matching a query inside a given string.
 *
 * @example
 * 	getQueryIndexesForString('es', 'test') => [[1, 2], [{index: 1, length: 2}]]
 * 	getQueryIndexesForString('es ing', 'test string') => [[1, 2, 8, 9, 10], [{index: 1, length: 2}, {index: 8, length: 3}]]
 *
 * @param {string} [needle] - Query string.
 * @param {string} haystack - Given string.
 * @return {[number[], {index: number, length: number}[]]}
 */
export const getQueryIndexesForString = (needle, haystack) => {
	// Return an empty array if needle is empty
	if (getString(needle).length === 0) return [];

	let result = [];
	const words = haystack.split(' ');
	/** @type {{index: number, length: number}[]} */
	let foundNeedles = [];

	let prevWordEndIndex = 0;
	words.forEach((word, idx) => {
		const needleWords = needle.split(' ');
		prevWordEndIndex += (idx > 0 ? words[idx - 1].length : 0);

		word.split('').forEach((c, cIdx) => {
			const globalCIdx = (idx > 0 ? prevWordEndIndex : 0) + cIdx + idx;

			for (let i = 0; i < needleWords.length; i++) {
				const needleWordIndex = word.toLowerCase().indexOf(needleWords[i].toLowerCase());
				if (needleWordIndex !== -1) {
					const needleIndex = (idx > 0 ? prevWordEndIndex : 0) + needleWordIndex + idx;
					const needleLength = needleWords[i].length;

					if (!find(foundNeedles, {index: needleIndex, length: needleLength})) {
						foundNeedles.push({index: needleIndex, length: needleLength});
					}
				}
			}

			/**
			 * Check if a word character should be highlighted
			 * @param {number} characterIndex - Current character index in the word.
			 * @param {{index: number, length: number}[]} indexes - Found needle indexes.
			 * @return {boolean}
			 */
			const isHighlighted = (characterIndex, indexes) => {
				for (let i = 0; i < indexes.length; i++) {
					if (characterIndex >= indexes[i].index && characterIndex < (indexes[i].index + indexes[i].length)) {
						return true;
					}
				}
				return false;
			};

			if (isHighlighted(globalCIdx, foundNeedles)) result.push(globalCIdx);
		});
	});

	return [uniq(result), foundNeedles];
};