import {get, find, findIndex, sortBy} from "lodash";
import {responsive_breakpoints, responsive_mobile_breakpoint_name} from "../../../config";

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'breakpoint';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@breakpoint/reset',
	SET: '@breakpoint/set',
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),
	set: breakpoint => ({type: REDUCER_TYPES.SET, breakpoint})
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	current: '',
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET:
			return {...initialState};
			
		case REDUCER_TYPES.SET:
			return {
				...state,
				current: action.breakpoint
			};
			
		default:
			return state;
	}
};

// Selectors
export const selectors = {
	getCurrentBreakpointName: state => get(state, [reducerStoreKey, 'current']),
	getCurrentBreakpoint: state => find(
		responsive_breakpoints, {name: get(state, [reducerStoreKey, 'current'])}
	),
	isMobileBreakpoint: state => {
		const sortedResponsiveBreakpoints = sortBy(responsive_breakpoints, ['maxWidth']);
		const currentBpName = get(state, [reducerStoreKey, 'current']);
		const currentBpIndex = findIndex(sortedResponsiveBreakpoints, {name: currentBpName});
		const mobileBpIndex = findIndex(sortedResponsiveBreakpoints, {name: responsive_mobile_breakpoint_name});
		return (currentBpIndex !== -1 && mobileBpIndex !== -1 ? currentBpIndex <= mobileBpIndex : false);
	}
};

export default reducer;