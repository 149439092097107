import * as country from "../country/countries";
import {FLAG_SHAPE, SKIN_MODE} from "Core/const/global";
import {STORAGE_TYPE} from "Core/storage/const";

// App client id -------------------------------------------------------------------------------------------------------
/**
 * Hardcoded app ID used to identify this app
 * @note This can be used for any purpose but mostly for teh API to identifying the app.
 * @type {string}
 */
export const app_id = 'WEB-LEAD-IT-MEDICAL-OFFICE';


// Home page -----------------------------------------------------------------------------------------------------------
/**
 * Router path of the app's home page
 * @type {string}
 */
export const app_home_page_router_path = process.env.REACT_APP_HOME_PAGE_ROUTER_PATH;
/**
 * Router path of the app's home page when user is logged in
 * @type {string}
 */
export const app_authorized_home_page_router_path = process.env.REACT_APP_AUTHORIZED_HOME_PAGE_ROUTER_PATH;


// Core pages ----------------------------------------------------------------------------------------------------------
/**
 * Router path of the app's login page
 * @type {string}
 */
export const app_login_page_router_path = '/login';
/**
 * Router path of the app's password reset page
 * @type {string}
 */
export const app_password_reset_page_router_path = '/password/reset';
/**
 * Router path of the app's password reset request (forgot password) page
 * @type {string}
 */
export const app_password_reset_request_page_router_path = '/password/forgot';
/**
 * Router path of the app's auth page
 * @type {string}
 */
export const app_auth_page_router_path = '/auth';



// Title config --------------------------------------------------------------------------------------------------------
/**
 * App title translation path
 * @note This will be used as a browser page title on app pages if title is empty (when page title and
 * 'browser_title_prefix' are both empty).
 * @type {string}
 */
export const app_title = 'App.title';
/**
 * Translation path of the prefix that will be added to all browser page titles
 * @type {string}
 */
export const browser_title_prefix = 'App.title_prefix';
/**
 * Prefix separator that will be added between browser title and between browser title prefix
 * @note Prefix will not be added if browser title (without prefix) is empty.
 * @type {string}
 */
export const browser_title_prefix_separator = ' - ';


// Country config ------------------------------------------------------------------------------------------------------
/**
 * Default application country
 * @description This is used to specify the initial application country that will affect the default and available
 * locales (languages) and possibly other things. Each country can access the application through a unique URL defined
 * by a regular expression.
 * @type {CountryObj}
 */
export const default_country = country.serbia;


// Locale config -------------------------------------------------------------------------------------------------------
/**
 * Storage type used for storing current locale
 * @see STORAGE_TYPE const for available values.
 * @type {string}
 */
export const locale_storage_type = STORAGE_TYPE.LOCAL;
/**
 * Path of the locale value in storage 
 * @note Storage type is defined in 'locale_storage_type' config option.
 * @type {string}
 */
export const locale_storage_path = `${app_id}_locale`;

/**
 * Name of the plugin used to handle dates in the app, specified in the locale object under 'plugins'.
 * @note This can be any name but be sure to use it in the locale ('src/i18n/locale.js') file.
 * @type {string}
 */
export const locale_date_plugin_name = 'dateFns';

/**
 * Locale object path where the plugin used to handle dates defines its locale, relative to plugin's path
 * @description Every plugin works in its own way and has a different way of handling locales so app's locale object 
 * has a special value defined in this field that will be used to define the location of the locale used by the plugin.
 * Plugin locale can be a simple string value like 'en-US' or a custom locale object like 'date-fns' uses, depending on 
 * the plugin used.
 * @see 'src/i18n/locale.js' for reference.
 * 
 * @type {string}
 */
export const locale_date_locale_path = `locale`;

/**
 * Locale object path where the plugin used to handle dates defines standard date formats, relative to plugin's path
 * @description There are three standard formats used by the app: 'short', 'standard' and 'long'. Any date format 
 * pattern supported by the plugin can be used.
 * @see 'src/i18n/locale.js' for reference.
 * @type {string}
 */
export const locale_date_format_path = `dateFormat`;

/**
 * Locale object path where the plugin used to handle dates defines standard time formats, relative to plugin's path
 * @description There are three standard formats used by the app: 'short', 'standard' and 'long'. Any time format 
 * pattern supported by the plugin can be used.
 * @see 'src/i18n/locale.js' for reference.
 * @type {string}
 */
export const locale_time_format_path = `timeFormat`;

/**
 * Locale object path where the plugin used to handle dates defines standard datetime separator, relative to plugin's 
 * path
 * @see 'src/i18n/locale.js' for reference.
 * @type {string}
 */
export const locale_datetime_separator_path = 'datetimeSeparator';


// Skin config ---------------------------------------------------------------------------------------------------------
/**
 * Default app skin mode
 * @note This config value will be ignored if system skin mode is in use.
 * @type {string}
 * @see SKIN_MODE
 */
export const skin_default_mode = SKIN_MODE.LIGHT;


// Layout config -------------------------------------------------------------------------------------------------------
/**
 * Default layout name
 * @type {string} Directory name of the layout (in /src/layout/ directory).
 */
export const default_layout = 'default';

/**
 * Default default responsive breakpoint used for resolutions larger than the max. defined breakpoint
 * @note Breakpoints are defined in 'responsive_breakpoints' config value.
 * @type {string}
 */
export const responsive_default_breakpoints_name = 'bp-xxl'; // Desktop displays

/**
 * Define responsive breakpoints
 * @type {Array<{name: string, maxWidth: number}>}
 */
export const responsive_breakpoints = [
	{name: 'bp-xxs', maxWidth: 320}, // Mobile, portrait
	{name: 'bp-xs', maxWidth: 480}, // Mobile, portrait
	{name: 'bp-s', maxWidth: 640}, // Mobile, landscape
	{name: 'bp-m', maxWidth: 768}, // Tablet, portrait
	{name: 'bp-l', maxWidth: 1024}, // Tablet, landscape
	{name: 'bp-xl', maxWidth: 1280}, // HD-ready (720p)
	{name: 'bp-xxl', maxWidth: 1336} // Laptop displays
];

/**
 * Define responsive breakpoint used as a default mobile friendly layout
 * @description Mobile friendly layout will usually, for example, hide sidebars.
 * @note List of all breakpoints should be defined in 'responsive_breakpoints' config value.
 * @type {string}
 */
export const responsive_mobile_breakpoint_name = 'bp-l';


// Storage config ------------------------------------------------------------------------------------------------------ 
/**
 * Name of the variable used for storing data in memory storage
 * @note Memory storage uses global JavaScript variables (windows[memory_storage_var]) to save storage values.
 * @type {string}
 */
export const memory_storage_var = 'MEMORY_STORAGE';
/**
 * Name of the Redux store state field used for storing data in Redux storage
 * @note Redux storage uses Redux store to save storage values.
 * @type {string}
 */
export const redux_storage_var =  'STORAGE';


// Icon font -----------------------------------------------------------------------------------------------------------
/**
 * CSS class or classes prefix used by icon font to display symbols
 * @note Prefix can contain multiple CSS classes if needed. For example 'fa fa-' for FontAwesome.
 * @type {string}
 */
export const icon_font_symbol_class_prefix = 'fa fa-';
/**
 * Icon font CSS class or classes used for spinning the icon symbol if icon font supports it.
 * @type {string}
 */
export const icon_font_spin_class = 'fa-spin';

/**
 * Icon font CSS class used for stacking icons.
 * @type {string}
 */
export const icon_font_stack_class = 'fa-stack';
/**
 * Icon font CSS class used to define the stacked icon.
 * @type {string}
 */
export const icon_font_stacked_item_class = 'fa-stack-1x';

/**
 * Icon font symbol name for loading icon.
 * @note This is used for all loading spinners in the app.
 * @type {string}
 */
export const icon_font_loading_symbol = 'spinner';

// Other icon font symbols
export const icon_font_close_symbol = 'times';
export const icon_font_info_symbol = 'info';
export const icon_font_help_symbol = 'question';
export const icon_font_help_circle_symbol = 'question-circle';
export const icon_font_required_symbol = 'asterisk';
export const icon_font_success_symbol = 'check';
export const icon_font_warning_symbol = 'exclamation';
export const icon_font_error_symbol = 'exclamation-triangle';
export const icon_font_create_symbol = 'plus';
export const icon_font_save_symbol = 'save';
export const icon_font_edit_symbol = 'pencil';
export const icon_font_delete_symbol = 'trash';
export const icon_font_restore_symbol = 'undo';


// Flag icons ----------------------------------------------------------------------------------------------------------
/**
 * Default flag shape
 * @see 'FLAG_SHAPE' core global constant in '/src/core/const/global.js' file.
 * @type {string}
 */
export const flag_icon_default_shape = FLAG_SHAPE.DEFAULT;
/**
 * CSS class or classes prefix used by flag package or icon set to display flags
 * @note Prefix can contain multiple CSS classes if needed. For example 'flag-icon flag-icon-'.
 * @type {string}
 */
export const flag_icon_class_prefix = 'fi fi-';
/**
 * CSS class or classes used for squared flags if flag package or icon set supports it.
 * @type {string}
 */
export const flag_icon_squared_class = 'fis';


// Global messages -----------------------------------------------------------------------------------------------------
/**
 * Default show duration for all global messages
 * @note Individual messages can override this value.
 * @type {number} Number of seconds for a global message to stay visible. If -1, message will be visible indefinitely or
 * until its closed manually.
 */
export const messages_default_auto_hide_after = 10;
/**
 * Flag that determines if all messages will be rendered as dialogs by default
 * @note Individual messages can override this.
 * @type {boolean}
 */
export const message_as_dialog = false;
/**
 * Message dialog default width
 * @type {number|string} Number in px or string (like '90%').
 */
export const message_dialog_default_width = 800;


// Pagination ----------------------------------------------------------------------------------------------------------
/**
 * Default maximal number of page buttons to be rendered inside pagination including the current page
 * @type {number}
 */
export const pagination_default_max_page_buttons = 5;

/**
 * Default number of items per page used for pagination in any pagination or data related component or IO request
 * @type {number}
 */
export const pagination_default_per_page = 20;


// Security ------------------------------------------------------------------------------------------------------------
/**
 * Minimal password strength for new passwords
 * @description Set a number value from 1 to 5 where 1 is the lowest password strength.
 * @note Password strength is calculated using the Dropbox 'zxcvbn' library.
 * @link https://github.com/dropbox/zxcvbn
 * @type {number}
 */
export const minimal_password_strength = 3;


// Data protection and privacy -----------------------------------------------------------------------------------------
/**
 * Flag that determines if cookie consent module will be used
 * @type {boolean}
 */
export const cookie_consent_enabled = false;
/**
 * Reload the app (refresh the page) on any cookie consent change or user action
 * @type {boolean}
 */
export const refresh_page_on_cookie_consent = false;
/**
 * Cookie group for locale cookie(s)
 * @type {string}
 */
export const locale_cookie_group = 'preferences';
/**
 * Cookie group for skin mode cookie
 * @type {string}
 */
export const skin_mode_cookie_group = 'preferences';
/**
 * Cookie group for sidebar menu shrunk cookie
 * @type {string}
 */
export const sidebar_menu_shrank_cookie_group = 'preferences';

/**
 * Storage field name used for sidebar menu group opened statuses
 * @note Statuses are stored in session storage, so that opened groups will be preserved for each browser tab. Statuses
 * will be cleared on logout.
 * @type {string}
 */
export const sidebar_menu_groups_statuses_storage_field_name = 'sidebar_menu_groups_statuses';


// Resources -----------------------------------------------------------------------------------------------------------
/**
 * Path of the loaded resources in memory storage
 * @type {string}
 */
export const resources_storage_path = 'resources';


// User activity -------------------------------------------------------------------------------------------------------
/**
 * Events used to update last user activity time
 * @type {(string|'iorequest'|'authorizedping')[]}
 */
export const user_activity_trigger_events = [
	'mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart', 'iorequest', 'authorizedping'
];

/**
 * Path of the user activity cookie in local storage
 * @type {string}
 */
export const user_activity_storage_path = `${app_id}_userActivity`;


// GUI -----------------------------------------------------------------------------------------------------------------
/**
 * Specify the main layout element
 * @type {string}
 */
export const main_layout_element = '.layout';
/**
 * Specify the element that will be used as the app main scrollable wrapper
 * @type {Window|string} If string, it will be treated as an element selector (for example: '#root').
 */
export const main_scroll_element = '.layout-content';

/**
 * Data type of the standard page ID detected form the URL
 * @note This applies to pages made by extending the abstract page components (PageComponent, PageDataComponent)
 * @type {'integer'|'string'}
 */
export const page_url_item_id_type = 'string';


// Custom app settings -------------------------------------------------------------------------------------------------
/**
 * Min time shown in calendar on home page
 * @note 'slotMinTime' prop of FullCalendar component.
 * @type {string}
 */
export const calendar_min_time = '07:00:00';
/**
 * Min hours number
 * @type {number}
 */
export const calendar_min_time_hours = 7;
/**
 * Max time shown in calendar on home page
 * @note 'slotMinTime' prop of FullCalendar component.
 * @type {string}
 */
export const calendar_max_time = '24:00:00';
/**
 * Max hours number
 * @type {number}
 */
export const calendar_max_time_hours = 23;

/**
 * Max number of therapy type levels the app will work with
 * @see {TherapyTypesPage}
 * @type {number}
 */
export const max_therapy_type_levels = 3;
/**
 * Therapy type level number fo the root therapy type
 * @see {TherapyTypesPage} 
 * @type {number}
 */
export const therapy_type_root_levels = 0;