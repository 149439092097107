import {cloneDeep} from "lodash";
import {getString} from "Core/helpers/data";
import {TherapyTypesListItemDataObject} from "../dataObjects";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {TherapyTypesListItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData)

	result = new TherapyTypesListItemDataObject(
		getString(result, 'id'),
		getString(result, 'name')
	);
	
	return result;
}