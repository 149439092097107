/**
 * Data object representing the search sales list item
 * @note This is usually used in data table as a row. Params don't have default values since this data object is used to
 * represent data that actually exists.
 */
export class SearchSalesListItemDataObject {
	/**
	 * @param {string} id - List item's ID.
	 * @param {string} name - List item's name.
	 */
	constructor(id, name) {
		this.id = id;
		this.name = name;
	}
}

