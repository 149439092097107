import {cloneDeep} from "lodash";
import {getString} from "Core/helpers/data";
import {MedicalRecordCategoriesListItemDataObject} from "../dataObjects";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {MedicalRecordCategoriesListItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new MedicalRecordCategoriesListItemDataObject(
		getString(result, 'uniqueID'),
		getString(result, 'categoryName'),
	);

	return result;
}

// @note There is no output function since list is read only.