import {actionCreators} from 'Core/store/reducers';
import {reducerStoreKey} from './reducer';

/**
 * Save currently selected patient from quick search into Redux store
 * 
 * @param {PatientSelectOptionDataObject} patient - Currently selected patient.
 * @return {(function(*): void)|*}
 */
export const setQuickSearchPatientAction = patient => dispatch => {
	dispatch(actionCreators[reducerStoreKey].set(patient));
};

/**
 * Clear patient quick search Redux store value for currently selected patient
 * @return {(function(*): void)|*}
 */
export const clearQuickSearchPatientAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].clear());
}