/** @typedef {string} StatisticsType */
export const STATISTICS_TYPE_OFFICE_LOCATIONS_DAILY_VISITS = 'OFFICELOCATIONS_DAILY_VISITS';
export const STATISTICS_TYPE_OFFICE_LOCATIONS_DAILY_PAYMENTS = 'OFFICELOCATIONS_DAILY_PAYMENTS';
export const STATISTICS_TYPE_OFFICE_LOCATIONS_MONTHLY_VISITS = 'OFFICELOCATIONS_MONTHLY_VISITS';
export const STATISTICS_TYPE_OFFICE_LOCATIONS_MONTHLY_PAYMENTS = 'OFFICELOCATIONS_MONTHLY_PAYMENTS';
export const STATISTICS_TYPE_OFFICE_LOCATIONS_YEARLY_VISITS = 'OFFICELOCATIONS_YEARLY_VISITS';
export const STATISTICS_TYPE_OFFICE_LOCATIONS_YEARLY_PAYMENTS = 'OFFICELOCATIONS_YEARLY_PAYMENTS';
export const STATISTICS_TYPE_OFFICE_LOCATIONS_LOCATIONS_PATIENTS = 'OFFICELOCATIONS_LOCATIONS_PATIENTS';
export const STATISTICS_TYPE_OFFICE_LOCATIONS_LOCATIONS_PAYMENTS = 'OFFICELOCATIONS_LOCATIONS_PAYMENTS';
export const STATISTICS_TYPE_THERAPISTS_DAILY_VISITS = 'THERAPISTS_DAILY_VISITS';
export const STATISTICS_TYPE_THERAPISTS_DAILY_DIFFERENT_PATIENTS = 'THERAPISTS_DAILY_DIFFERENTPATIENTS';
export const STATISTICS_TYPE_THERAPISTS_MONTHLY_VISITS = 'THERAPISTS_MONTHLY_VISITS';
export const STATISTICS_TYPE_THERAPISTS_MONTHLY_DIFFERENT_PATIENTS = 'THERAPISTS_MONTHLY_DIFFERENTPATIENTS';
export const STATISTICS_TYPE_THERAPISTS_YEARLY_VISITS = 'THERAPISTS_YEARLY_VISITS';
export const STATISTICS_TYPE_THERAPISTS_YEARLY_DIFFERENT_PATIENTS = 'THERAPISTS_YEARLY_DIFFERENTPATIENTS';
export const STATISTICS_TYPE_THERAPISTS_THERAPIST_VISITS = 'THERAPISTS_THERAPIST_VISITS';
export const STATISTICS_TYPE_THERAPISTS_THERAPIST_PATIENTS = 'THERAPISTS_THERAPIST_PATIENTS';
export const STATISTICS_TYPE_OTHER_RECOMMENDATION_PATIENTS = 'OTHER_RECOMMENDATION_PATIENTS';
/** @enum {StatisticsType} */
export const STATISTICS_TYPE = {
	OFFICE_LOCATIONS_DAILY_VISITS: STATISTICS_TYPE_OFFICE_LOCATIONS_DAILY_VISITS,
	OFFICE_LOCATIONS_DAILY_PAYMENTS: STATISTICS_TYPE_OFFICE_LOCATIONS_DAILY_PAYMENTS,
	OFFICE_LOCATIONS_MONTHLY_VISITS: STATISTICS_TYPE_OFFICE_LOCATIONS_MONTHLY_VISITS,
	OFFICE_LOCATIONS_MONTHLY_PAYMENTS: STATISTICS_TYPE_OFFICE_LOCATIONS_MONTHLY_PAYMENTS,
	OFFICE_LOCATIONS_YEARLY_VISITS: STATISTICS_TYPE_OFFICE_LOCATIONS_YEARLY_VISITS,
	OFFICE_LOCATIONS_YEARLY_PAYMENTS: STATISTICS_TYPE_OFFICE_LOCATIONS_YEARLY_PAYMENTS,
	OFFICE_LOCATIONS_LOCATIONS_PATIENTS: STATISTICS_TYPE_OFFICE_LOCATIONS_LOCATIONS_PATIENTS,
	OFFICE_LOCATIONS_LOCATIONS_PAYMENTS: STATISTICS_TYPE_OFFICE_LOCATIONS_LOCATIONS_PAYMENTS,
	THERAPISTS_DAILY_VISITS: STATISTICS_TYPE_THERAPISTS_DAILY_VISITS,
	THERAPISTS_DAILY_DIFFERENT_PATIENTS: STATISTICS_TYPE_THERAPISTS_DAILY_DIFFERENT_PATIENTS,
	THERAPISTS_MONTHLY_VISITS: STATISTICS_TYPE_THERAPISTS_MONTHLY_VISITS,
	THERAPISTS_MONTHLY_DIFFERENT_PATIENTS: STATISTICS_TYPE_THERAPISTS_MONTHLY_DIFFERENT_PATIENTS,
	THERAPISTS_YEARLY_VISITS: STATISTICS_TYPE_THERAPISTS_YEARLY_VISITS,
	THERAPISTS_YEARLY_DIFFERENT_PATIENTS: STATISTICS_TYPE_THERAPISTS_YEARLY_DIFFERENT_PATIENTS,
	THERAPISTS_THERAPIST_VISITS: STATISTICS_TYPE_THERAPISTS_THERAPIST_VISITS,
	THERAPISTS_THERAPIST_PATIENTS: STATISTICS_TYPE_THERAPISTS_THERAPIST_PATIENTS,
	OTHER_RECOMMENDATION_PATIENTS: STATISTICS_TYPE_OTHER_RECOMMENDATION_PATIENTS,
};
/** @type {StatisticsType[]} */
export const STATISTICS_TYPES = [
	STATISTICS_TYPE_OFFICE_LOCATIONS_DAILY_VISITS,
	STATISTICS_TYPE_OFFICE_LOCATIONS_DAILY_PAYMENTS,
	STATISTICS_TYPE_OFFICE_LOCATIONS_MONTHLY_VISITS,
	STATISTICS_TYPE_OFFICE_LOCATIONS_MONTHLY_PAYMENTS,
	STATISTICS_TYPE_OFFICE_LOCATIONS_YEARLY_VISITS,
	STATISTICS_TYPE_OFFICE_LOCATIONS_YEARLY_PAYMENTS,
	STATISTICS_TYPE_OFFICE_LOCATIONS_LOCATIONS_PATIENTS,
	STATISTICS_TYPE_OFFICE_LOCATIONS_LOCATIONS_PAYMENTS,
	STATISTICS_TYPE_THERAPISTS_DAILY_VISITS,
	STATISTICS_TYPE_THERAPISTS_DAILY_DIFFERENT_PATIENTS,
	STATISTICS_TYPE_THERAPISTS_MONTHLY_VISITS,
	STATISTICS_TYPE_THERAPISTS_MONTHLY_DIFFERENT_PATIENTS,
	STATISTICS_TYPE_THERAPISTS_YEARLY_VISITS,
	STATISTICS_TYPE_THERAPISTS_YEARLY_DIFFERENT_PATIENTS,
	STATISTICS_TYPE_THERAPISTS_THERAPIST_VISITS,
	STATISTICS_TYPE_THERAPISTS_THERAPIST_PATIENTS,
	STATISTICS_TYPE_OTHER_RECOMMENDATION_PATIENTS,
];

/**
 * Format of the number for statistics values for each statistics type
 * @description Some statistics values are integers (like visits) and some are decimal (like payments). This map is a 
 * way to specify this for each statistics type.
 * @type {Object<StatisticsType, string>}
 */
export const STATISTICS_TYPE_VALUE_FORMAT = {
	[STATISTICS_TYPE_OFFICE_LOCATIONS_DAILY_VISITS]: '0',
	[STATISTICS_TYPE_OFFICE_LOCATIONS_DAILY_PAYMENTS]: '0.00',
	[STATISTICS_TYPE_OFFICE_LOCATIONS_MONTHLY_VISITS]: '0',
	[STATISTICS_TYPE_OFFICE_LOCATIONS_MONTHLY_PAYMENTS]: '0.00',
	[STATISTICS_TYPE_OFFICE_LOCATIONS_YEARLY_VISITS]: '0',
	[STATISTICS_TYPE_OFFICE_LOCATIONS_YEARLY_PAYMENTS]: '0.00',
	[STATISTICS_TYPE_OFFICE_LOCATIONS_LOCATIONS_PATIENTS]: '0',
	[STATISTICS_TYPE_OFFICE_LOCATIONS_LOCATIONS_PAYMENTS]: '0',
	[STATISTICS_TYPE_THERAPISTS_DAILY_VISITS]: '0',
	[STATISTICS_TYPE_THERAPISTS_DAILY_DIFFERENT_PATIENTS]: '0',
	[STATISTICS_TYPE_THERAPISTS_MONTHLY_VISITS]: '0',
	[STATISTICS_TYPE_THERAPISTS_MONTHLY_DIFFERENT_PATIENTS]: '0',
	[STATISTICS_TYPE_THERAPISTS_YEARLY_VISITS]: '0',
	[STATISTICS_TYPE_THERAPISTS_YEARLY_DIFFERENT_PATIENTS]: '0',
	[STATISTICS_TYPE_THERAPISTS_THERAPIST_VISITS]: '0',
	[STATISTICS_TYPE_THERAPISTS_THERAPIST_PATIENTS]: '0',
	[STATISTICS_TYPE_OTHER_RECOMMENDATION_PATIENTS]: '0',
};

/** @typedef {string} StatisticsScope */
export const STATISTICS_SCOPE_DAILY = 'DAILY';
export const STATISTICS_SCOPE_MONTHLY = 'MONTHLY';
export const STATISTICS_SCOPE_YEARLY = 'YEARLY';
/** @enum {StatisticsScope} */
export const STATISTICS_SCOPE = {
	DAILY: STATISTICS_SCOPE_DAILY,
	MONTHLY: STATISTICS_SCOPE_MONTHLY,
	YEARLY: STATISTICS_SCOPE_YEARLY,
}
/** @type {StatisticsScope[]} */
export const STATISTICS_SCOPES = [
	STATISTICS_SCOPE_DAILY,
	STATISTICS_SCOPE_MONTHLY,
	STATISTICS_SCOPE_YEARLY,
];

/** @typedef {string} StatisticsChartType */
export const STATISTICS_CHART_TYPE_LINE = 'LINE';
export const STATISTICS_CHART_TYPE_BAR = 'BAR';
export const STATISTICS_CHART_TYPE_PIE = 'PIE';
/** @enum {StatisticsChartType} */
export const STATISTICS_CHART_TYPE = {
	LINE: STATISTICS_CHART_TYPE_LINE,
	BAR: STATISTICS_CHART_TYPE_BAR,
	PIE: STATISTICS_CHART_TYPE_PIE,
};
/** @type {StatisticsChartType[]} */
export const STATISTICS_CHART_TYPES = [
	STATISTICS_CHART_TYPE_LINE,
	STATISTICS_CHART_TYPE_BAR,
	STATISTICS_CHART_TYPE_PIE,
];