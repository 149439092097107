import styles from "../../index.module.css";

import React from "react";
import BaseComponent, {executeComponentCallback} from "Core/components/BaseComponent";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {selectors} from "Core/store/reducers";
import {reducerStoreKey} from "../../reducer";
import {getPageActions} from "Core/helpers/redux";
import * as actions from "../../actions";
import * as pageConfig from "../../config";
import {Tooltip} from "react-tippy";
import Button, {BUTTON_DISPLAY_TYPE, BUTTON_STYLE} from "Core/components/display/Button";
import {
	icon_font_create_symbol,
	icon_font_delete_symbol,
	icon_font_edit_symbol,
	max_therapy_type_levels,
	therapy_type_root_levels
} from "Config/app";
import {getArray, getNumber, getString, isset, trimArray} from "Core/helpers/data";
import DataTable, {DATA_TABLE_CELL_TYPE} from "Core/components/advanced/DataTable";
import {PAGINATION_TYPE} from "Core/components/action/Pagination";
import {DataTableCellAnyTypeOptionsDataObject} from "Core/components/advanced/DataTable/DataTableCell/dataObjects";
import Label from "Core/components/display/Label";
import {isEqual} from "lodash";
import TherapyTypeDialog from "Pages/apps/default/therapyTypes/dialogs/TherapyTypeDialog";
import * as itemDataMap from "../../dataMap/item";
import Icon from "Core/components/display/Icon";
import ConfirmDialog from "Core/components/dialogs/ConfirmDialog";
import {isSuccessful} from "Core/helpers/io";

/**
 * Get all actions used by this component
 * @type {Object}
 */
const allActions = getPageActions({...actions});

/**
 * Redux 'mapStateToProps' function
 *
 * @param {Object} state - Redux entire store state.
 * @param {Object} ownProps - Components own props.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = (state, ownProps) => ({
	mainList: selectors[reducerStoreKey].getTherapyTypeList(state, getNumber(ownProps, 'level')),
	mainListPagination: selectors[reducerStoreKey].getTherapyTypeListPagination(state, getNumber(ownProps, 'level')),
	mainListSort: selectors[reducerStoreKey].getTherapyTypeListSort(state, getNumber(ownProps, 'level')),
	selectedMainListItem: selectors[reducerStoreKey].getSelectedTherapyTypeItem(state, getNumber(ownProps, 'level')),
	selectedParentMainListItem: (
		getNumber(ownProps, 'level') >= therapy_type_root_levels + 1 ?
			selectors[reducerStoreKey].getSelectedTherapyTypeItem(state, getNumber(ownProps, 'level') - 1) :
			null
	),
});

class TherapyTypeList extends BaseComponent {
	constructor(props) {
		super(props, {
			domPrefix: 'therapy-type-list',
			translationPath: pageConfig.translationPath,
		});

		// Data methods
		this.reloadMainList = this.reloadMainList.bind(this);
		this.loadMainListPage = this.loadMainListPage.bind(this);
		this.sortMainList = this.sortMainList.bind(this);

		// Dialog methods
		this.openMainListItemDialog = this.openMainListItemDialog.bind(this);
		
		// Action methods
		this.deleteMainListItem = this.deleteMainListItem.bind(this);
		
		// Event handlers
		this.handleMainListItemClick = this.handleMainListItemClick.bind(this);
		
		// Render methods
		this.renderActions = this.renderActions.bind(this);
	}
	
	/** @inheritDoc */
	async asyncComponentDidMount() {
		await super.asyncComponentDidMount();
		
		const {level} = this.props;
		
		// Load top level therapy type list
		if (level === therapy_type_root_levels) this.loadMainListPage().then();
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		const parentId = getString(this.props, 'selectedParentMainListItem.id');
		const prevParentId = getString(prevProps, 'selectedParentMainListItem.id');
		
		// Load sublist when selected list item changes
		if (parentId !== prevParentId) this.loadMainListPage().then();
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() { return this.getOption('domPrefix'); }

	
	// Data methods -----------------------------------------------------------------------------------------------------
	/**
	 * Reload main list using current options (page, sort, ...)
	 * @return {Promise<*>}
	 */
	reloadMainList() {
		const {
			level, loadTherapyTypeListAction, clearSelectTherapyTypeItemAction, mainListPagination, mainListSort
		} = this.props;
		const parentId = getString(this.props, 'selectedParentMainListItem.id');
		const {pageNo, perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;

		return this.executeAbortableAction(loadTherapyTypeListAction, level, parentId, pageNo, perPage, sortBy, sortDir)
			// Clear selected list items for the reloaded level and all levels after it
			.then(() => clearSelectTherapyTypeItemAction(level));
	}

	/**
	 * Reload main list using current options (page, sort, ...) if any
	 * @param {number} [pageNo=1] - Page number to load (starts with 1).
	 * @return {Promise<*>}
	 */
	loadMainListPage(pageNo = 1) {
		const {level, loadTherapyTypeListAction, mainListPagination, mainListSort} = this.props;
		const parentId = getString(this.props, 'selectedParentMainListItem.id');
		const {perPage} = mainListPagination;
		const {sortBy, sortDir} = mainListSort;

		return this.executeAbortableAction(loadTherapyTypeListAction, level, parentId, pageNo, perPage, sortBy, sortDir);
	}

	/**
	 * Sort main list
	 * @param {string} sortBy - Name of the sort column.
	 * @param {string} sortDir - Direction of the sort.
	 * @return {Promise<*>}
	 */
	sortMainList(sortBy, sortDir) {
		const {level, loadTherapyTypeListAction, mainListPagination} = this.props;
		const parentId = getString(this.props, 'selectedParentMainListItem.id');
		const {pageNo, perPage} = mainListPagination;

		return this.executeAbortableAction(loadTherapyTypeListAction, level, parentId, pageNo, perPage, sortBy, sortDir);
	}


	// Dialog methods ---------------------------------------------------------------------------------------------------
	/**
	 * Open main list item dialog
	 * @param {?TherapyTypesListItemDataObject} [listItem=null] - List item to open dialog for. If null, create dialog 
	 * will be opened.
	 */
	openMainListItemDialog(listItem = null) {
		const {
			level, saveTherapyTypeItemAction, openDialogAction, closeDialogAction, addSuccessMessageAction
		} = this.props;
		const parentId = getString(this.props, 'selectedParentMainListItem.id');
		
		// TherapyTypeDialog
		const dialogGUIID = openDialogAction('', TherapyTypeDialog, {
			isNew: (listItem === null),
			level,
			data: (listItem !== null ? itemDataMap.inputListItem(listItem) : undefined),
			onSave: itemToSave => {
				this.executeAbortableAction(saveTherapyTypeItemAction, level, parentId, itemToSave)
					.then(savedItem => {
						addSuccessMessageAction(this.t('save_therapy_type_success_msg'))
						
						closeDialogAction(dialogGUIID);

						// Reload main list data
						// @note This is done asynchronously on purpose because there is no reason to wait for this to 
						// finish before continuing.
						this.reloadMainList()
							// Select saved therapy type
							.then(() => this.handleMainListItemClick(itemDataMap.outputListItem(savedItem)));
					});
			},
		}, {
			id: 'therapy-type-dialog',
			className: 'bordered-title',
			closeOnEscape: true,
			closeOnClickOutside: false,
			hideCloseBtn: true,
			maxWidth: 800
		});
		this.setOption(
			'dialogsToCloseOnUnmount',
			trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
		);
	}
	

	// Action methods ---------------------------------------------------------------------------------------------------
	/**
	 * Delete main list item
	 * @param {TherapyTypesItemDataObject} item - Main list dialog item.
	 * @return {Promise<void>}
	 */
	deleteMainListItem(item) {
		const {
			level, selectedMainListItem, deleteTherapyTypeItemItemAction, clearSelectTherapyTypeItemAction, 
			openDialogAction, closeDialogAction, addSuccessMessageAction
		} = this.props;
		
		return new Promise(resolve => {
			const dialogGUIID = openDialogAction('', ConfirmDialog, {
				message: this.t(`confirm_delete${level}`, '', '', {name: item.name}),
				supportHtml: true,
				onYes: () => {
					this.executeAbortableAction(deleteTherapyTypeItemItemAction, item.id)
						.then(response => {
							if (isSuccessful(response)) {
								addSuccessMessageAction(this.t('delete_success_msg'));
								
								// Reload main list data
								// @note This is done asynchronously on purpose because there is no reason to wait for this to 
								// finish before continuing.
								this.reloadMainList()
									// Go to the previous page if there are no table rows after one has been deleted
									.then(() => {
										const mainList = getArray(this.props, 'mainList');
										const pageNo = getNumber(this.props, 'mainListPagination.pageNo', 1);
										if (mainList.length === 0 && pageNo > 1) return this.loadMainListPage(pageNo - 1);
									})
									// Unselect the deleted item and all its subitems if the deleted item is currently selected
									.then(() => {
										if (isEqual(item, selectedMainListItem)) clearSelectTherapyTypeItemAction(level);
									});
							}
							return Promise.resolve(response);
						})
						.then(() => closeDialogAction(dialogGUIID))
						.finally(() => resolve('yes'));
				},
				onNo: () => {
					closeDialogAction(dialogGUIID);
					resolve('no');
				}
			}, {
				id: 'therapy-type-delete-dialog',
				closeOnEscape: true,
				closeOnClickOutside: true,
				hideCloseBtn: true,
				maxWidth: 650
			});
			this.setOption(
				'dialogsToCloseOnUnmount',
				trimArray([...this.getOption('dialogsToCloseOnUnmount'), dialogGUIID], 'left')
			);
		});
	}


	// Event handlers ---------------------------------------------------------------------------------------------------
	/**
	 * Handle clicking on main list item
	 * @param {TherapyTypesListItemDataObject} listItem - Clicked list item.
	 */
	handleMainListItemClick(listItem) {
		const {level, selectedMainListItem} = this.props;
		
		// Trigger 'onListItemSelect' for levels less than the max level since max level does not have subitems
		if (level < (max_therapy_type_levels + therapy_type_root_levels) - 1 && !isEqual(listItem, selectedMainListItem)){
			executeComponentCallback(this.props.onListItemSelect, level, listItem);
		}
	}
	
	
	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Render data table actions cell
	 * @para m {} row - Data table row.
	 * @return {JSX.Element}
	 */
	renderActions(row) {
		return (
			<div className="actions">
				<Tooltip
					tag="div"
					title={this.t('delete_tooltip')}
					size="small"
					position="top-center"
					arrow={true}
					interactive={false}
					touchHold={true}
					delay={250}
				>
					<Button
						className="action-btn no-border-radius"
						displayStyle={BUTTON_STYLE.ACTION}
						displayType={BUTTON_DISPLAY_TYPE.NONE}
						icon={icon_font_delete_symbol}
						onClick={() => this.deleteMainListItem(row)}
					/>
				</Tooltip>

				<Tooltip
					tag="div"
					title={this.t('edit_tooltip')}
					size="small"
					position="top-center"
					arrow={true}
					interactive={false}
					touchHold={true}
					delay={250}
				>
					<Button
						className="action-btn no-border-radius"
						displayStyle={BUTTON_STYLE.ACTION}
						displayType={BUTTON_DISPLAY_TYPE.NONE}
						icon={icon_font_edit_symbol}
						onClick={() => this.openMainListItemDialog(row)}
					/>
				</Tooltip>
			</div>
		);
	}
	
	render() {
		const {
			className, level, mainList, mainListPagination, mainListSort, selectedMainListItem, selectedParentMainListItem
		} = this.props;
		
		return (
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')} ${className}`}>
				<div className={`toolbar standard for-table ${styles['listToolbar']}`}>
					<span className={`label ${styles['toolbarLabel']}`}>
						<Label content={this.t(`nameField${level}`)} />
					</span>
					<Tooltip
						tag="div"
						title={this.t('Reload data', 'general')}
						size="small"
						position="top-center"
						arrow={true}
						interactive={false}
						touchHold={true}
						delay={250}
					>
						<Button
							icon="refresh"
							displayType={BUTTON_DISPLAY_TYPE.TRANSPARENT}
							displayStyle={BUTTON_STYLE.SUBTLE}
							onClick={() => this.reloadMainList()}
						/>
					</Tooltip>
					{level === therapy_type_root_levels || !!selectedParentMainListItem ?
						<Button
							icon={icon_font_create_symbol}
							tooltip={this.t('create_new')}
							displayType={BUTTON_DISPLAY_TYPE.NONE}
							displayStyle={BUTTON_STYLE.ACTION}
							onClick={() => this.openMainListItemDialog()}
						/>
						: null
					}
				</div>
				<DataTable
					id={`therapy-type-list-data-table-${level}`}
					className="standard therapy-type-list-data-table"
					highlightOnHover={true}
					columns={[
						{
							name: 'name',
							label: this.t(`nameField${level}`),
						},
						
						{
							dataType: DATA_TABLE_CELL_TYPE.ANY,
							dataTypeOptions: new DataTableCellAnyTypeOptionsDataObject({
								standardWrapper: false,
								content: this.renderActions,
							}),
							stopPropagation: true,
							width: 1,
						} 
					]}
					onRowClick={listItem => this.handleMainListItemClick(listItem)}
					data={mainList}
					highlightedRows={[
						{
							className: 'highlighted',
							rows: isset(selectedMainListItem) ? [selectedMainListItem] : [],
						}
					]}
					paginationType={PAGINATION_TYPE.DYNAMIC}
					onSortByColumn={this.sortMainList}
					onPaginationClick={this.loadMainListPage}
					{...mainListPagination}
					{...mainListSort}
					noDataContent={(level > therapy_type_root_levels && !selectedParentMainListItem ?
						<Label icon="hand-pointer-o" content={this.t(`noDataLevel${level}`)} /> :
						undefined
					)}
					topRowContent={(level > therapy_type_root_levels && !!selectedParentMainListItem ?
						<div className="action-color center-content-vertical">
							{new Array(level - therapy_type_root_levels).fill('angle-right').map((i, idx) =>
								<Icon key={idx} symbol={i} />
							)}
							<>
								&nbsp;
								<Label
									content={`${getString(selectedParentMainListItem, 'name')}`}
									help={this.t(`parent${level}`)}
								/>
							</>
						</div>
						: undefined
					)}
				/>
			</div>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
TherapyTypeList.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	level: PropTypes.number,
	
	// Event triggered when list item is selected
	// Arguments: {number} level, {TherapyTypesListItemDataObject} listItem
	onListItemSelect: PropTypes.func,
};

/**
 * Define component default values for own props
 */
TherapyTypeList.defaultProps = {
	id: '',
	className: '',
	level: therapy_type_root_levels,
};

export default connect(mapStateToProps, allActions, null, {forwardRef: true})(TherapyTypeList);