/** @typedef {string} AppointmentPatientType */
export const APPOINTMENT_PATIENT_TYPE_PATIENT = 'EXISTING_PATIENT';
export const APPOINTMENT_PATIENT_TYPE_ANONYMOUS_PATIENT = 'ANONYMOUS_PATIENT';
/** @enum {AppointmentPatientType} */
export const APPOINTMENT_PATIENT_TYPE = {
	EXISTING_PATIENT: APPOINTMENT_PATIENT_TYPE_PATIENT,
	ANONYMOUS_PATIENT: APPOINTMENT_PATIENT_TYPE_ANONYMOUS_PATIENT,
};
/** @type {AppointmentPatientType[]} */
export const APPOINTMENT_PATIENT_TYPES = [
	APPOINTMENT_PATIENT_TYPE_PATIENT,
	APPOINTMENT_PATIENT_TYPE_ANONYMOUS_PATIENT,
];