import {cloneDeep, get} from 'lodash';

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'patientRecord';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@patientRecord/reset',
	
	SET: '@patientRecord/set',
	CLEAR: '@patientRecord/clear',

	SET_SELECTED_PATIENT_THERAPY: '@patientRecord/set_selected_patient_therapy',
	CLEAR_SELECTED_PATIENT_THERAPY: '@patientRecord/clear_selected_patient_therapy',
	SET_PATIENT_THERAPY: '@patientRecord/set_patient_therapy',
	CLEAR_PATIENT_THERAPY: '@patientRecord/clear_patient_therapy',
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),
	
	set: patientRecordData => ({type: REDUCER_TYPES.SET, patientRecordData}),
	clear: () => ({type: REDUCER_TYPES.CLEAR}),

	/**
	 * @param {PatientTherapySelectOptionDataObject} selectedPatientTherapyOption
	 * @return {{selectedPatientTherapyOption: PatientTherapySelectOptionDataObject, type: string}}
	 */
	setSelectedPatientTherapy: selectedPatientTherapyOption => ({
		type: REDUCER_TYPES.SET_SELECTED_PATIENT_THERAPY, selectedPatientTherapyOption
	}),
	clearSelectedPatientTherapy: () => ({type: REDUCER_TYPES.CLEAR_SELECTED_PATIENT_THERAPY}),
	/**
	 * @param {PatientTherapyDataObject} [patientTherapy]
	 * @return {{type: string, patientTherapy: PatientTherapyDataObject}}
	 */
	setPatientTherapy: patientTherapy => ({type: REDUCER_TYPES.SET_PATIENT_THERAPY, patientTherapy}),
	clearPatientTherapy: () => ({type: REDUCER_TYPES.CLEAR_PATIENT_THERAPY}),
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	/** @type {PatientRecordDataObject} */
	patientRecord: null,

	/** @type {PatientTherapySelectOptionDataObject} */
	selectedPatientTherapy: null,
	/** @type {PatientTherapyDataObject} */
	patientTherapy: null,
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET: return {...initialState};
		
		case REDUCER_TYPES.SET: return {...state, patientRecord: action.patientRecordData};
		case REDUCER_TYPES.CLEAR: return {...state, patientRecord: cloneDeep(initialState.patientRecord)};
		
		case REDUCER_TYPES.SET_SELECTED_PATIENT_THERAPY: 
			return {...state, selectedPatientTherapy: action.selectedPatientTherapyOption};
		case REDUCER_TYPES.CLEAR_SELECTED_PATIENT_THERAPY:
			return {...state, selectedPatientTherapy: cloneDeep(initialState.selectedPatientTherapy)};
		case REDUCER_TYPES.SET_PATIENT_THERAPY: return {...state, patientTherapy: action.patientTherapy};
		case REDUCER_TYPES.CLEAR_PATIENT_THERAPY: 
			return {...state, patientTherapy: cloneDeep(initialState.patientTherapy)};

		default: return state;
	}
};

// Selectors
export const selectors = {
	getPatientRecord: state => get(state, [reducerStoreKey, 'patientRecord']),
	
	getSelectedPatientTherapy: state => get(state, [reducerStoreKey, 'selectedPatientTherapy'], null),
	getPatientTherapy: state => get(state, [reducerStoreKey, 'patientTherapy']),
};

export default reducer;