import {cloneDeep} from "lodash";
import {PrescribedTherapiesItemDataObject} from "../dataObjects";
import {getBoolean, getString} from "Core/helpers/data";

/** Get input data from raw data
* @param {Object} rawData - Raw data received from IO.
* @return {PrescribedTherapiesItemDataObject}
*/
export const input = rawData => {
	let result = cloneDeep(rawData);
	
	result = new PrescribedTherapiesItemDataObject (
		getString(result, 'id'),
		getString(result, 'prescribedTherapyType'),
		getString(result, 'prescribedTherapyTypeId'),
		getString(result, 'prescribedTherapySubtype'),
		getString(result, 'prescribedTherapySubtypeId'),
		getString(result, 'prescribedTherapySubSubtype'),
		getString(result, 'prescribedTherapySubSubtypeId'),
		getString(result, 'duration'),
		getString(result, 'additionalInfo'),
		getBoolean(result, 'active')
	);
	
	return result;
}