import React from "react";
import DialogDataComponent, {executeComponentCallback} from 'Core/components/DialogDataComponent';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import * as actions from "./actions";
import {getGlobalActions} from 'Core/helpers/redux';
import DataValueValidation, {VALIDATION_FIELD_TYPE, ValidationConstraintObject} from "Core/validation";
import FormWrapper, {FormField} from "Core/components/advanced/FormWrapper";
import {getNumber, getString, isset} from 'Core/helpers/data';
import TextareaInput from "Core/components/input/TextareaInput";
import {AppointmentVisitDataObjects} from "Components/dialogs/AppointmentVisitDialog/dataObjects";
import Separator from "Core/components/display/Separator";
import NumberInput from "Core/components/input/NumberInput";
import {FORM_FIELD_LABEL_POSITION} from "Core/components/advanced/FormWrapper/FormField";
import {isMacintosh} from "Core/helpers/system";
import {BUTTON_STYLE} from "Core/components/display/Button";
import {icon_font_close_symbol} from "Config/app";
import {APPOINTMENT_VISIT_PAYMENT_FAILED} from "Components/dialogs/AppointmentVisitDialog/const";

/**
 * Get all actions used by this component
 * @type {Object}
 */
const allActions = getGlobalActions({...actions});

class AppointmentVisitDialog extends DialogDataComponent {
	constructor(props) {
		super(props, {
			data: new AppointmentVisitDataObjects(props.appointment),
		}, {
			translationPath: 'AppointmentVisitDialog',
			disableLoad: true,
		});
	}

	// Validation and error methods -------------------------------------------------------------------------------------
	/**
	 * Default component's data validation method
	 *
	 * @return {boolean} True if component's data validation passed successfully.
	 */
	validate() {
		const dataValidation = new DataValueValidation();
		/** @type {AppointmentVisitDataObjects} */
		const dataToValidate = this.getData();
		
		dataValidation.addConstraint('paymentNote', new ValidationConstraintObject('max',255,VALIDATION_FIELD_TYPE.TEXT));

		// Run validation
		const validationErrors = dataValidation.run(dataToValidate);

		if (validationErrors) this.setValidationErrors('', validationErrors).then();
		else this.clearValidationErrors().then();
		return !validationErrors;
	}


	/**
	 * Save dialog method
	 * @note This method should be called when dialog's "save" button is clicked. This method does not actually save any
	 * data to the DB or anywhere else. That should be done by the parent component.
	 */
	async save() {
		const {patientId, dialogGUIID, creatAppointmentVisitAction, addSuccessMessageAction} = this.props;
		/** @type {AppointmentVisitDataObjects} */
		const data = this.getDataToReturn();

		// Do the validation
		const isValid = this.validate();

		// If validation is successful
		if (isValid) {
			const saveResponse = await this.executeAbortableAction(creatAppointmentVisitAction, data, patientId);
			
			if (saveResponse === APPOINTMENT_VISIT_PAYMENT_FAILED || isset(saveResponse)) {
				addSuccessMessageAction(this.t('create_visit_success_msg'));
				if (data.paymentAmount > 0 && saveResponse !== APPOINTMENT_VISIT_PAYMENT_FAILED) {
					addSuccessMessageAction(this.t('create_visit_payment_success_msg'));
				}
				
				this.close();

				// Trigger component's 'onSave' event
				executeComponentCallback(this.props.onSave, dialogGUIID);
			}
		}
	}

	/**
	 * Render dialog action buttons used by data dialogs
	 * @note Every dialog that is used to manipulate some data should use these action buttons on the bottom of the main
	 * render method. Standard buttons include create, save, delete, restore and close and they will be rendered
	 * depending on dialog type and available events (isNew, isRestore, onDelete, ...).
	 *
	 * @param {string|null} [createLabel] - Label used for create button. Default value will be loaded from translation file.
	 * @param {string|null} [createIcon] - Optional icon used for create button.
	 * @param {string|null} [deleteLabel] - Label used for delete button. Default value will be loaded from translation file.
	 * @param {string|null} [deleteIcon] - Optional icon used for delete button.
	 * @param {string|null} [saveLabel] - Label used for save button. Default value will be loaded from translation file.
	 * @param {string|null} [saveIcon] - Optional icon used for save button.
	 * @param {string|null} [closeLabel] - Label used for close button. Default value will be loaded from translation file.
	 * @param {string|null} [closeIcon] - Optional icon used for close button.
	 * @param {string|null} [restoreLabel] - Label used for restore button. Default value will be loaded from translation
	 * file.
	 * @param {string|null} [restoreIcon] - Optional icon used for restore button.
	 * @param {boolean} [hideClose=false] - If true, close button will not be rendered. This is useful for confirm
	 * dialogs.
	 * @return {*} Action buttons JSX to use in the main render method.
	 */
	renderDataActionButtons({
		createLabel = undefined, createIcon = undefined, deleteLabel = undefined, deleteIcon = undefined,
		saveLabel = undefined, saveIcon = undefined, closeLabel = undefined, closeIcon = undefined,
		restoreLabel = undefined, restoreIcon = undefined
	} = {}, hideClose = false) {
		let buttons = [];

		// Close/cancel button
		if (isMacintosh() && !hideClose) buttons.push({
			style: BUTTON_STYLE.DEFAULT,
			label: (closeLabel ? closeLabel : this.t('Close', 'general')),
			icon: (isset(closeIcon) && closeLabel !== null ? closeIcon : icon_font_close_symbol),
			onClick: this.close,
		});

		// Save buttons (create, restore and save)
		buttons.push({
			style: BUTTON_STYLE.SUCCESS,
			label: (createLabel ? createLabel : this.t('create_btn')),
			icon: (typeof createIcon === 'undefined' ? 'checked' : createIcon),
			iconProps: {symbolPrefix: typeof createIcon === 'undefined' ? 'icofont-' : undefined},
			onClick: this.save,
		});

		// Close/cancel button
		if (!isMacintosh() && !hideClose) buttons.push({
			style: BUTTON_STYLE.DEFAULT,
			label: (closeLabel ? closeLabel : this.t('Close', 'general')),
			icon: (isset(closeIcon) && closeLabel !== null ? closeIcon : icon_font_close_symbol),
			onClick: this.close,
		});

		return this.renderActionButtons(buttons);
	}

	render() {
		/** @type {AppointmentVisitDataObjects} */
		const data = this.getData();

		return this.renderDialog(
			this.renderTitle(this.t('title')),
			(
				<FormWrapper className="dialog-form">
					<FormField
						label={this.t('noteLabel')}
						labelPosition={FORM_FIELD_LABEL_POSITION.STACKED}
						errorMessages={this.getValidationErrors('note')}
					>
						<TextareaInput
							rows={3}
							name="note"
							value={getString(data, 'note')}
							onChange={this.handleInputChange}
							autoFocus={true}
						/>
					</FormField>
					
					<Separator icon="money" iconSymbolPrefix="icofont-" content={this.t('paymentSectionTitle')} />
					<FormField
						label={this.t('paymentAmountLabel')}
						errorMessages={this.getValidationErrors('paymentAmount')}
					>
						<NumberInput
							value={getNumber(data, 'paymentAmount', null, true)}
							onChange={v => this.handleValueChange('paymentAmount', v)}
						/>
					</FormField>
					<FormField
						label={this.t('paymentAppointmentsNumberLabel')}
						errorMessages={this.getValidationErrors('paymentAppointmentsNumber')}
					>
						<NumberInput
							intOnly={true}
							value={getNumber(data, 'paymentAppointmentsNumber', 1)}
							onChange={v => this.handleValueChange('paymentAppointmentsNumber', v)}
						/>
					</FormField>
					<FormField
						label={this.t('paymentNoteLabel')}
						className="multiline-form-field"
						errorMessages={this.getValidationErrors('paymentNote')}
					>
						<TextareaInput
							maxLength={255}
							rows={3}
							name="paymentNote"
							value={getString(data, 'paymentNote')}
							onChange={this.handleInputChange}
						/>
					</FormField>
				</FormWrapper>
			)
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
AppointmentVisitDialog.propTypes = {
	// DB ID of the patient
	patientId: PropTypes.string.isRequired,
	// Appointment data to use for creating a visit
	// @type {AppointmentUpdateDataObject}
	appointment: PropTypes.object.isRequired,
	
	// Event triggered if appointment was saved successfully and dialog has already been closed
	// @param {string} dialogGUIID
	onSave: PropTypes.func,
	// Event triggered when dialog close method is called
	// @note Dialog will not be closed if event callback returns false.
	// @param {string} dialogGUIID
	onClose: PropTypes.func,
};

export default connect(null, allActions)(AppointmentVisitDialog);