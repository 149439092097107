/**
 * Data object representing the search payments list item
 * @note This is usually used in data table as a row. Params don't have default values since this data object is used to
 * represent data that actually exists.
 */
export class SearchPaymentsListItemDataObject {
	/**
	 * @param {string} id - List item's ID.
	 * @param {number} appointmentsNumber - Patient's number of appointments.
	 * @param {number} amount - Payment amount.
	 * @param {string} note - Note for the payment
	 * @param {string} therapyPatientId - ID of the paying patient.
	 * @param {string} therapyPatientFirstName - First name of the patient paying.
	 * @param {string} therapyPatientLastName - Last name of the patient paying.
	 * @param {string} officeLocationId - ID of the office location where the payment took place.
	 * @param {string} officeLocationName - Name of the office location where the payment took place.
	 * @param {Date} paymentDate - Date of payment.
	 * 
	 */
	constructor(id, appointmentsNumber, amount, note, therapyPatientId, therapyPatientFirstName, therapyPatientLastName,
		officeLocationId, officeLocationName, paymentDate) {
		this.id = id;
		this.appointmentsNumber = appointmentsNumber;
		this.amount = amount;
		this.paymentNote = note;
		this.paymentPatientId = therapyPatientId;
		this.paymentPatientFirstName = therapyPatientFirstName;
		this.paymentPatientLastName = therapyPatientLastName;
		this.officeLocationId = officeLocationId;
		this.officeLocationName = officeLocationName;
		this.paymentDate = paymentDate;
	}
}

