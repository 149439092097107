/** @typedef {string} LabelIconPosition */
export const LABEL_ICON_POSITION_NONE = '';
export const LABEL_ICON_POSITION_LEFT = 'left';
export const LABEL_ICON_POSITION_RIGHT = 'right';
/** @enum {LabelIconPosition} */
export const LABEL_ICON_POSITION = {
	NONE: LABEL_ICON_POSITION_NONE,
	LEFT: LABEL_ICON_POSITION_LEFT,
	RIGHT: LABEL_ICON_POSITION_RIGHT,
};
/** @type {LabelIconPosition[]} */
export const LABEL_ICON_POSITIONS = [
	LABEL_ICON_POSITION_NONE,
	LABEL_ICON_POSITION_LEFT,
	LABEL_ICON_POSITION_RIGHT,
]
