import React from "react";
import BaseComponent from "Core/components/BaseComponent";
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {getGlobalActions} from 'Core/helpers/redux';
import * as actions from './actions';
import {omit} from "lodash";
import SelectAsyncInput from "Core/components/input/SelectAsyncInput";
import HighlightedQueryOption from "Core/components/input/SelectInput/options/HighlightedQueryOption";

/**
 * Get all actions used by this component
 * @type {Object}
 */
const allActions = getGlobalActions(actions);

class DiagnosisQuickSearch extends BaseComponent {
	constructor(props) {
		super(props, {
			domPrefix: 'diagnosis-quick-search',
		});

		// Data methods
		this.loadOptions = this.loadOptions.bind(this);
	}

	/**
	 * Load options for async select component
	 *
	 * @param {string} query - Query string from input.
	 * @return {Promise<Object[]>}
	 */
	loadOptions(query) {
		const {minLength, diagnosisQuickSearchAction} = this.props;
		if (!!query.trim() && query.length >= minLength) {
			return this.executeAbortableAction(diagnosisQuickSearchAction, query);
		}
		return Promise.resolve([]);
	}
	
	render() {
		return (
			<SelectAsyncInput
				loadOptions={this.loadOptions}
				getOptionLabel={o => `${o.code} ${o.description}`}
				getOptionValue={o => o.id}
				components={{Option: HighlightedQueryOption}}
				{...omit(this.props, ['minLength', 'loadOptions', ...Object.keys(allActions)])}
			/>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
DiagnosisQuickSearch.propTypes = {
	...SelectAsyncInput.propTypes,
	
	minLength: PropTypes.number,
};

/**
 * Define component default values for own props
 */
DiagnosisQuickSearch.defaultProps = {
	...SelectAsyncInput.defaultProps,
	
	minLength: 1,
};

export default connect(null, allActions, null, {forwardRef: true})(DiagnosisQuickSearch);