import {cloneDeep, get} from "lodash";
import {getArray, getNumber, getString} from "Core/helpers/data";
import {SearchTherapiesListItemDataObject} from "../dataObjects";
import {getDate, STANDARD_DATE_TIME_FORMAT} from "Core/helpers/datetime";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {SearchTherapiesListItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new SearchTherapiesListItemDataObject(
		getString(result, 'id'),
		getString(result, 'diagnosis'),
		getDate(get(result, 'therapyDate'), STANDARD_DATE_TIME_FORMAT.ISO_DATE),
		getString(result, 'medicalRecordCategory'),
		getNumber(result, 'medicalRecordNumber'),
		getString(result, 'sentByPhysician'),
		getNumber(result, 'numberOfTherapies'),
		getString(result, 'customerFullName'),		
		getArray(result, 'prescribedTherapyDataList'),
		getString(result, 'customerId')
	);

	return result;
}

// @note There is no output function since list is read only.