import style from "./index.module.css";

import React, {useState} from "react";
import PropTypes from "prop-types";
import {BUTTON_DISPLAY_TYPE, BUTTON_DISPLAY_TYPES, BUTTON_STYLE, BUTTON_STYLES} from "./const";
import Icon from 'Core/components/display/Icon';
import Html from 'Core/components/display/Html';
import {Tooltip} from "react-tippy";
import {getNumber} from 'Core/helpers/data';

/**
 * HTML button component
 * @note This component uses a default <button> element, and it can accept any attribute that <button> can.
 */
function Button({
	id,
	className = '',
	type = 'button',
	displayType = BUTTON_DISPLAY_TYPE.SOLID,
	displayStyle = BUTTON_STYLE.DEFAULT,
	big = false,
	name,
	value,
	autofocus = false,
	disabled = false,
	hide = false,
	tooltip = '',
	label = '',
	allowHtmlLabel = false,
	icon = '',
	iconProps = {},
	spinIcon = false,
	timer = 0,
	onClick,
	children,
	...otherProps
}) {
	const [counter, setCounter] = useState(getNumber(timer));
	setTimeout(() => { if (counter > 0) setCounter(counter - 1); }, 1000);
	
	/**
	 * Function to render the actual button
	 * @return {JSX.Element}
	 */
	function renderButton() {
		return (
			<button
				id={id}
				className={
					`button-component ${className} ${big ? 'big ' + style['big'] : ''} ${style['button']}` +
					` ${displayType} ${style[displayType]} ${displayStyle} ${style[displayStyle]}` +
					` ${!children && !label ? `icon-only ${style['icon-only']}` : ''}`
				}
				type={type}
				name={name}
				value={value}
				autoFocus={autofocus}
				disabled={disabled || counter > 0}
				onClick={e => {
					e.preventDefault();
					if (!disabled && onClick && counter === 0) onClick(e);
				}}
				{...otherProps}
			>
				{icon ? <Icon
					symbol={icon}
					spin={spinIcon}
					className={`button-component-icon ${style['icon']}`}
					{...iconProps}
				/> : null }{
				label ?
					(allowHtmlLabel ? <Html content={label} element="span" /> : label)
					: null
			} {children} {counter > 0 ? `(${counter})` : ''}
			</button>
		);
	}
	
	return (
		!hide ?
			tooltip ? 
				<Tooltip tag="span" title={tooltip} size="small" position="top-center" arrow={true} interactive={false}>
					{renderButton()}
				</Tooltip>
				: 
				renderButton()
			:
			null
	);
}

/**
 * Define component's own props that can be passed to it by parent components
 */
Button.propTypes = {
	// Button element 'id' attribute.
	id: PropTypes.string,
	// Button element CSS class attribute.
	className: PropTypes.string,
	// The default behavior of the button. Possible values are: 'submit', 'reset' or 'button'.
	type: PropTypes.string,
	// Button display type ('none', 'solid', 'transparent', ...)
	displayType: PropTypes.oneOf(BUTTON_DISPLAY_TYPES),
	// Button display style ('default', 'success', 'error', ...)
	displayStyle: PropTypes.oneOf(BUTTON_STYLES),
	// If true, bigger button will be rendered.
	big: PropTypes.bool,
	// The name of the button, submitted as a pair with the button’s value as part of the form data.
	name: PropTypes.string,
	// Defines the value associated with the button’s name when it’s submitted with the form data. This value is passed
	// to the server in params when the form is submitted.
	value: PropTypes.string,
	// This Boolean attribute specifies that the button should have input focus when the page loads. 
	// @note Only one element in a document can have this attribute.
	autofocus: PropTypes.bool,
	// This Boolean attribute prevents the user from interacting with the button: it cannot be pressed or focused.
	disabled: PropTypes.bool,
	// If true, button will not be rendered.
	hide: PropTypes.bool,
	// Button tooltip
	// @note This will add a wrapper tooltip element around the button.
	tooltip: PropTypes.string,
	// Button label rendered as a child of the <button> component before any other child elements but after the icon.
	label: PropTypes.any,
	// Set to true to support HTML in 'label' prop.
	// @warning Be careful when using this flag because it can cause security issues. It uses 'dangerouslySetInnerHTML' 
	// to allow HTML content. 
	allowHtmlLabel: PropTypes.bool,
	// Font icon symbol name.
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	// If true, icon will spin (if supported by font icon set used).
	spinIcon: PropTypes.bool,
	// Icon props
	// @see Icon component
	iconProps: PropTypes.shape({
		// Icon element CSS class
		className: PropTypes.string,
		// Name of the HTML element (like 'i', 'span', ...) used to render the icon.
		element: PropTypes.string,
		// Name of the HTML element (like 'i', 'span', ...) used to render the icon stack.
		// @note Icons are stacked if 'symbol' prop is an array.
		stackElem: PropTypes.string,
		// Font icon symbol prefix
		symbolPrefix: PropTypes.string,
		// Font icon class used for spinning the icon (if supported by the icon font used)
		spinClassName: PropTypes.string,
		// Font icon class used for stacking icons
		stackClassName: PropTypes.string,
		// Font icon class used to define the stacked icon
		stackedItemClassName: PropTypes.string,
	}),
	// Number of seconds to disable the button until user can clink on it
	// @note Counter will be rendered inside the button after the label and children.
	timer: PropTypes.number,
	
	// Events
	onClick: PropTypes.func,
};

export default Button;
export * from "./const";