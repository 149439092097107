import {rawRequest} from './helper';
import {REQUEST_TYPE, RESPONSE_DATA_TYPE} from './const';
import {
	io_request_processors,
	io_response_processors,
	io_default_request_options,
	io_default_response_data_type,
	auth_default_auto_token
} from 'Config/index';
import {addErrorMessage} from "Core/helpers/message";
import {translate} from "Core/i18n";

/**
 * Low-level function for making an IO request
 * @note Use of 'autoToken' and 'token' when 'processRequest' is true will depend on enabled request processors. This 
 * means that if there are no enabled request processors that handle tokens, token will not be added to the request.
 *
 * @param {string} [type=''] - Internal request type (see imported REQUEST_TYPE const). This is used to auto-generate
 * request headers, body and possibly other request values based on app config.
 * @param {string} url - Request url.
 * @param {string} [api=''] - Request's API (see 'io_base_urls' io config value for available APIs). Raw requests don't
 * have this value.
 * @param {string} [endpoint=''] - Request's API endpoint (see 'io_base_urls' io config value for available APIs). Raw
 * requests don't have this value.
 * @param {any} data - Request body.
 * @param {string} method - Request method ('get', 'post', ...).
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage and added to the request. If 
 * 'processRequest' is true, token will be added to the request only if auth request processor is enabled.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false. If 'processRequest' is true, token
 * will be added to the request only if auth request processor is enabled.
 * @param {object} [options={}] - Other request options that will be sent.
 * @param {Headers} [headers=null] - Request headers. If not specified, null or empty default headers from IO config 
 * will be used.
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config 
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will 
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {boolean} [processRequest=false] - If true, request will be processed by request processors.
 * @param {boolean} [processResponse=false] - If true, response will be processed by response processors. Otherwise, raw
 * fetch promise will be returned.
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [canPause=true] - If true this request could be paused using a global request pause flag.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note 
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token 
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate 
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use 
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the 
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and 
 * error will be logged in console if download fails.
 * @return {Promise<any|Response>}
 */
export const ioRequest = async ({
	type = '', url, api = '', endpoint = '', data, method, autoToken = auth_default_auto_token, 
	token = '', options = {}, headers = null, responseType, processRequest = false, processResponse = false, 
	abortCallback = () => {}, canPause = true, useCaptcha = false, captchaAction = '', isDownload = false, 
	downloadFilename = 'file', handleDownloadError = false
}) => {
	// IMPORTANT: This is required in order for dynamic import to load properly when app is built for production. 
	// Dynamic import cannot resolve imported values like these so there needs to be a local const getting the value 
	// before it can be used in the dynamic import.
	let requestProcessors = (processRequest ? io_request_processors : []);
	const responseProcessors = (processResponse ? io_response_processors : []);
	
	// Add captcha request processor if needed
	if (useCaptcha && requestProcessors.indexOf('captcha') === -1) requestProcessors.push('captcha');
	
	return rawRequest({
		type, 
		isDownload, 
		url, 
		api, 
		endpoint, 
		data, 
		method, 
		autoToken, 
		token, 
		options, 
		headers,
		responseType: (isDownload ? RESPONSE_DATA_TYPE.BLOB : responseType), 
		canPause, 
		captchaAction, 
		requestProcessors, 
		responseProcessors, 
		abortCallback
	})
		// Start the download if file can ge reached
		.then(data => {
			if (isDownload && data) {
				// Create a dummy download link with the downloaded file
				let dummyLink = document.createElement('a');
				const url = window.URL.createObjectURL(data);
				dummyLink.href = url;
				dummyLink.download = downloadFilename;
				document.body.append(dummyLink);

				// Simulate a click on the download link which should start the download
				dummyLink.click();
				dummyLink.remove();

				// Clear object URL from memory to prevent memory leaks
				window.URL.revokeObjectURL(url);
			}
			return data;
		})
		.catch(e => {
			if (handleDownloadError) {
				addErrorMessage(translate('Download error!', 'errors.io'));
				console.error(`Error downloading file '${url}': `, e);
			} else {
				throw e;
			}
		});
};

/**
 * Make a standard IO request
 * @note This function makes a request using all request and response processors.
 *
 * @param {string} url - Request url.
 * @param {string} [api=''] - Request's API (see 'io_base_urls' io config value for available APIs). Raw requests don't
 * have this value.
 * @param {string} [endpoint=''] - Request's API endpoint (see 'io_base_urls' io config value for available APIs). Raw
 * requests don't have this value.
 * @param {object} data - Data object used to create request body.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {object} [options] - Other request options that will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {Headers} [headers=null] - Request headers. If not specified, null or empty default headers from IO config
 * will be used.
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [canPause=true] - If true this request could be paused using a global request pause flag.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @return {Promise<*>} Promise that will resolve with the response data. Promise can be rejected either by the 'fetch'
 * function or the 'handleResponse' function in which case rejection reason will be an object with the following
 * properties {{response: Response, request: Object}}.
 */
export const ioStandardRequest = ({
	url, api = '', endpoint = '', data, method = 'post', autoToken = auth_default_auto_token, 
	token = '', options = io_default_request_options, headers = null, responseType = io_default_response_data_type, 
	abortCallback = () => {}, canPause = true, useCaptcha = false, captchaAction = '', isDownload = false,
	downloadFilename = 'file', handleDownloadError = false
}) => ioRequest({
	type: REQUEST_TYPE.STANDARD,
	method,
	url,
	api,
	endpoint,
	data,
	autoToken,
	token,
	options,
	headers,
	responseType,
	processRequest: true,
	processResponse: true,
	abortCallback,
	canPause,
	useCaptcha,
	captchaAction,
	isDownload,
	downloadFilename,
	handleDownloadError
});

/**
 * Make a JSON IO request
 * @note This function makes a request using all request and response processors.
 *
 * @param {string} url - Request url.
 * @param {string} [api=''] - Request's API (see 'io_base_urls' io config value for available APIs). Raw requests don't
 * have this value.
 * @param {string} [endpoint=''] - Request's API endpoint (see 'io_base_urls' io config value for available APIs). Raw
 * requests don't have this value.
 * @param {object} data - Data object used to create request body.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {object} [options] - Other request options that will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {Headers} [headers=null] - Request headers. If not specified, null or empty default headers from IO config
 * will be used.
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [canPause=true] - If true this request could be paused using a global request pause flag.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @return {Promise<*>} Promise that will resolve with the response data. Promise can be rejected either by the 'fetch'
 * function or the 'handleResponse' function in which case rejection reason will be an object with the following
 * properties {{response: Response, request: Object}}.
 */
export const ioJsonRequest = ({
	url, api = '', endpoint = '', data, method = 'post', autoToken = auth_default_auto_token, 
	token = '', options = io_default_request_options, headers = null, responseType = io_default_response_data_type, 
	abortCallback = () => {}, canPause = true, useCaptcha = false, captchaAction = '', isDownload = false,
	downloadFilename = 'file', handleDownloadError = false
}) => ioRequest({
	type: REQUEST_TYPE.JSON,
	method,
	url,
	api,
	endpoint,
	data,
	autoToken,
	token,
	options,
	headers,
	responseType,
	processRequest: true,
	processResponse: true,
	abortCallback,
	canPause,
	useCaptcha,
	captchaAction,
	isDownload,
	downloadFilename,
	handleDownloadError
});

/**
 * Make a FormData IO request
 * @note This function makes a request using all request and response processors.
 *
 * @param {string} url - Request url.
 * @param {string} [api=''] - Request's API (see 'io_base_urls' io config value for available APIs). Raw requests don't
 * have this value.
 * @param {string} [endpoint=''] - Request's API endpoint (see 'io_base_urls' io config value for available APIs). Raw
 * requests don't have this value.
 * @param {object} data - Data object used to create request body.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {object} [options] - Other request options that will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {Headers} [headers=null] - Request headers. If not specified, null or empty default headers from IO config
 * will be used.
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [canPause=true] - If true this request could be paused using a global request pause flag.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @return {Promise<*>} Promise that will resolve with the response data. Promise can be rejected either by the 'fetch'
 * function or the 'handleResponse' function in which case rejection reason will be an object with the following
 * properties {{response: Response, request: Object}}.
 */
export const ioFormDataRequest = ({
	url, api = '', endpoint = '', data, method = 'post', autoToken = auth_default_auto_token, 
	token = '', options = io_default_request_options, headers = null, responseType = io_default_response_data_type, 
	abortCallback = () => {}, canPause = true, useCaptcha = false, captchaAction = '', isDownload = false,
	downloadFilename = 'file', handleDownloadError = false
}) => ioRequest({
	type: REQUEST_TYPE.FORM_DATA,
	method,
	url,
	api,
	endpoint,
	data,
	autoToken,
	token,
	options,
	headers,
	responseType,
	processRequest: true,
	processResponse: true,
	abortCallback,
	canPause,
	useCaptcha,
	captchaAction,
	isDownload,
	downloadFilename,
	handleDownloadError
});

/**
 * Make an upload IO request
 * @description This function makes a request with all predefined and added options and values (like tokens).
 * @note Upload request always uses 'post' request method.
 *
 * @param {string} url - Request url.
 * @param {string} [api=''] - Request's API (see 'io_base_urls' io config value for available APIs). Raw requests don't
 * have this value.
 * @param {string} [endpoint=''] - Request's API endpoint (see 'io_base_urls' io config value for available APIs). Raw
 * requests don't have this value.
 * @param {object} data - Data object used to create request body.
 * @param {string} [method='post'] - Request method ('get', 'post', ...).
 * @param {boolean} [autoToken] - If true, auth token will be retrieved from storage.
 * @param {string} [token=''] - Custom auth token used only if 'autoToken' is false.
 * @param {object} [options] - Other request options that will be sent. Default value is retrieved form IO config
 * ("/src/config/io.js").
 * @param {Headers} [headers=null] - Request headers. If not specified, null or empty default headers from IO config
 * will be used.
 * @param {ResponseDataType} [responseType] - Response data type. Default value is retrieved form IO config
 * ("/src/config/io.js"). If response type was not specified, and it cannot be retrieved from the config, response will
 * be return as Blob. If 'isDownload' is true, response type will be set to BLOB and this value will be ignored.
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {boolean} [canPause=true] - If true this request could be paused using a global request pause flag.
 * @param {boolean} [useCaptcha=false] - Flag that specifies if captcha token will be added to the request. Please note
 * that captcha validation should be done by the backend and not frontend. Frontend will only send the captcha token
 * that backend should validate.
 * @param {string} [captchaAction=''] - Some captcha implementations can accept an actions name to differentiate
 * between different captcha requests for analytic purposes. Use this argument to set the action name.
 * @param {boolean} [isDownload=false] - Flag that specifies if this is a download request. Download requests use
 * 'io_download_request_headers' io config to append download specific headers to request headers. When download request
 * is returned, an invisible link will be created on the page and click event will be triggered on in to start the
 * file download by the browser. Dummy link will be automatically deleted once it is not needed anymore.
 * @param {string} [downloadFilename='file'] - Filename of the downloaded file.
 * @param {boolean} [handleDownloadError=false] - Flag that specifies if default error message will be rendered and
 * error will be logged in console if download fails.
 * @return {Promise<*>} Promise that will resolve with the response data. Promise can be rejected either by the 'fetch'
 * function or the 'handleResponse' function in which case rejection reason will be an object with the following
 * properties {{response: Response, request: Object}}.
 */
export const ioUploadRequest = ({
	url, api = '', endpoint = '', data, method = 'post', autoToken = auth_default_auto_token,
	token = '', options = io_default_request_options, headers = null, responseType = io_default_response_data_type, 
	abortCallback = () => {}, canPause = true, useCaptcha = false, captchaAction = '', isDownload = false, 
	downloadFilename = 'file', handleDownloadError = false
}) => ioRequest({
	type: REQUEST_TYPE.UPLOAD,
	method,
	url,
	api,
	endpoint,
	data,
	autoToken,
	token,
	options,
	headers,
	responseType,
	processRequest: true,
	processResponse: true,
	abortCallback,
	canPause,
	useCaptcha,
	captchaAction,
	isDownload,
	downloadFilename,
	handleDownloadError
});