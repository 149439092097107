import {cloneDeep} from "lodash";
import {getString} from "Core/helpers/data";
import {TherapistsListItemDataObject} from "../dataObjects";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {TherapistsListItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);
	
	result = new TherapistsListItemDataObject(
		getString(result, 'uniqueID'),
		getString(result, 'firstName'),
		getString(result, 'lastName'),
		getString(result, 'telephone'),
		getString(result, 'mobilePhone'),
		getString(result, 'email'),
		getString(result, 'address'),
		getString(result, 'backgroundCalendarColor'),
		getString(result, 'fontCalendarColor'),
	);

	return result;
}

// @note There is no output function since list is read only.