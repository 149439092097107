import {cloneDeep, get} from "lodash";
import {getString} from "Core/helpers/data";
import {SearchPatientsListItemDataObject} from "../dataObjects";
import {getDate, STANDARD_DATE_TIME_FORMAT} from "Core/helpers/datetime";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {SearchPatientsListItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new SearchPatientsListItemDataObject(
		getString(result, 'id'),
		getString(result, 'firstName'),
		getString(result, 'middleName'),
		getString(result, 'lastName'),
		getString(result, 'address'),
		getString(result, 'telephone'),
		getString(result, 'mobilePhone'),
		getString(result, 'email'),
		getDate(get(result, 'birthDate'), STANDARD_DATE_TIME_FORMAT.ISO_DATE),
		getString(result, 'recommendationId'),
		getString(result, 'recommendationName'),
	);

	return result;
}

// @note There is no output function since list is read only.