/**
 * Abstract IO error class used to create other, more specific IO error classes
 */
class AbstractIoError extends Error {
	/**
	 * Constructor
	 * @param {string} message - The status message corresponding to the status code.
	 * @param {string} url - Request's full url.
	 * @param {string} api - Request's API (see 'io_base_urls' io config value for available APIs).
	 * @param {string} endpoint - Request's API endpoint (see 'io_base_urls' io config value for available APIs).
	 * @param {Object} request - Request object containing at least 'method', 'headers' and 'body' properties.
	 * @param {string} responseType - Response data type (see RESPONSE_DATA_TYPE const).
	 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as 
	 * an argument.
	 */
	constructor(message, url, api, endpoint, request, responseType, abortCallback = (abortController) => {}) {
		super(message);

		this.url = url;
		this.api = api;
		this.endpoint = endpoint;
		this.request = request;
		this.responseType = responseType;
		this.abortCallback = abortCallback;
	}
}

export default AbstractIoError;