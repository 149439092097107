import styles from "./index.module.css";
import "./index.css";

import React from "react";
import {connect} from "react-redux";
import BaseComponent, {executeComponentCallback} from "../../BaseComponent";
import PropTypes from "prop-types";
import * as actions from "./actions";
import {cloneDeep, isEqual} from "lodash";
import {getCssSizeString} from "../../../helpers/dom";
import {FLOAT_POPUP_POSITIONS} from "./const";
import {FloatPopupOptionsDataObject} from "./dataObjects";
import Button, {BUTTON_DISPLAY_TYPE} from "../../display/Button";
import {icon_font_close_symbol} from "../../../../config";

class FloatPopup extends BaseComponent {
	constructor(props) {
		super(props, props.options);

		// Float popup methods
		this.close = this.close.bind(this);
	}

	/**
	 * Close the float popup
	 */
	close(){
		const { GUIID, closeFloatPopupAction, innerComponentProps } = this.props;

		// Trigger component's onClose event and close the float popup if allowed
		// @note Float popup will not be closed if event callback returns false.
		if (executeComponentCallback(innerComponentProps?.onClose, GUIID) !== false) closeFloatPopupAction(GUIID);
	}

	render() {
		const {GUIID, innerComponent, innerComponentProps} = this.props;
		const hasInnerComponent = (typeof innerComponent !== 'undefined' && innerComponent);
		
		// Do not render float popup if inner component is not specified
		if (!hasInnerComponent) return null;
		
		// Options
		const id = this.getOption('id', `floatPopup-${GUIID}`);
		const className = this.getOption('className', FloatPopup.defaultProps.options.className);
		const title = this.getOption('title', FloatPopup.defaultProps.options.title);
		const position = this.getOption('position', FloatPopup.defaultProps.options.position);
		const maxWidth = getCssSizeString(this.getOption('maxWidth', FloatPopup.defaultProps.options.maxWidth));
		const maxHeight = getCssSizeString(this.getOption('maxHeight', FloatPopup.defaultProps.options.maxHeight));
		const hideCloseBtn = this.getOption('hideCloseBtn', FloatPopup.defaultProps.options.hideCloseBtn);

		return (
			<div
				id={id}
				className={
					`float-popup-component ${className} position-${position} ` +
					`${styles[`position-${position}`]} ${styles['wrapper']} `
				}
				style={{maxWidth, maxHeight}}
			>
				<div className={`float-popup-header ${styles['header']}`}>
					{title ? <div className={`float-popup-title ${styles['title']}`}>{title}</div> : null}
					{
						hideCloseBtn !== true ?
							<Button 
								className={`float-popup-close ${styles['close']}`}
								displayType={BUTTON_DISPLAY_TYPE.NONE}
								icon={icon_font_close_symbol}
								onClick={this.close}
							/>
							: null
					}
				</div>
				<div className={`float-popup-content`}>
					<FloatPopupInnerComponent
						innerComponent={innerComponent}
						floatPopupGUIID={GUIID}
						floatPopupOptions={this.getOptions()}
						floatPopupCloseAction={this.close}
						{...innerComponentProps}
					/>
				</div>
			</div>
		);
	}
}

/**
 * Float popups inner component
 * @description This component will be rendered inside the float popup.
 * @note This component is created because float popups inner component is loaded dynamically. If dynamic components are 
 * used in render methods they will unmount and mount again on every local state change which creates problems. This is 
 * why dynamic components should be rendered using some locale state which this component will do.
 */
class FloatPopupInnerComponent extends BaseComponent {
	constructor(props) {
		super(props, {});

		// Define component's initial state
		this.initialState = {
			InnerComponent: this.renderInnerComponent(props)
		};

		// Set initial component's internal state
		this.state = cloneDeep(this.initialState);

		// Render methods
		this.renderInnerComponent = this.renderInnerComponent.bind(this);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		// Update inner component in local state if inner component changes
		if (!isEqual(prevProps.innerComponent, this.props.innerComponent)) {
			this.setState({InnerComponent: this.renderInnerComponent(this.props)}).then();
		}
	}

	renderInnerComponent(props) {
		const propToUse = (typeof props !== 'undefined' ? props : this.props);
		const {innerComponent, ...otherProps} = propToUse;
		return React.createElement(innerComponent, {...otherProps});
	}

	render() {
		return this.state.InnerComponent;
	}
}

/**
 * Define component own props that can be passed to it by parent components
 */
FloatPopup.propTypes = {
	// Unique float popup ID used to identify the float popup in the multiple float popups environment
	GUIID: PropTypes.string,
	// Component that will be shown inside the float popup
	innerComponent: PropTypes.any,
	// Props to pass tho the component that will be shown inside the float popup
	innerComponentProps: PropTypes.object,
	// Float popup options
	// @note These options are primarily used by the FloatPopups component when rendering each individual float popup.
	// @type {FloatPopupOptionsDataObject}
	options: PropTypes.shape({
		// Float popup's 'id' attribute
		id: PropTypes.string,
		// Float popup's 'class' attribute
		className: PropTypes.string,
		// Float popup's title
		title: PropTypes.string,
		// Position on the screen where float popup will appear when opened
		position: PropTypes.oneOf(FLOAT_POPUP_POSITIONS),
		// Float popup's max width
		maxWidth: PropTypes.oneOfType([
			// String with or without unit suffix ('px' or '%'). If value is without unit suffix it will be interpreted as
			// a pixel value.
			PropTypes.string,
			// Number of pixels
			PropTypes.number
		]),
		// Float popup's max height
		maxHeight: PropTypes.oneOfType([
			// String with or without unit suffix ('px' or '%'). If value is without unit suffix it will be interpreted as
			// a pixel value.
			PropTypes.string,
			// Number of pixels
			PropTypes.number
		]),
		// Flag that determines if float popup's close button will be hidden
		hideCloseBtn: PropTypes.bool,
	}),
};

/**
 * Define component default values for own props
 */
FloatPopup.defaultProps = {
	options: new FloatPopupOptionsDataObject(),
};

export default connect(null, actions)(FloatPopup);
export {default as reducer, reducerStoreKey, selectors, actionCreators} from "./reducer";
export * from "./actions";