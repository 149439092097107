import React from 'react';
import BaseComponent, {executeComponentCallback} from 'Core/components/BaseComponent';
import {connect} from 'react-redux';
import SelectInput from "Core/components/input/SelectInput";
import PropTypes from 'prop-types';
import {getGlobalActions} from 'Core/helpers/redux';
import * as actions from './actions';
import {find, omit} from "lodash";
import PatientTherapyOption from "Components/input/PatientTherapySelectInput/PatientTherapyOption";
import PatientTherapySingleValue from 'Components/input/PatientTherapySelectInput/PatientTherapySingleValue';
import {getArray, getString} from 'Core/helpers/data';

/**
 * Get all actions used by this component
 * @type {Object}
 */
const allActions = getGlobalActions(actions);

class PatientTherapySelectInput extends BaseComponent {
	constructor(props) {
		super(props, {
			domPrefix: 'patient-therapy-select-input'
		});

		// Initiate component's state
		this.state = {
			patientTherapyList: [],
			patientTherapyListLoading: false,
		};

		// Data methods
		this.reload = this.reload.bind(this);
		
		// Action methods
		this.selectById = this.selectById.bind(this);
	}

	/** @inheritDoc */
	async asyncComponentDidMount() {
		await super.asyncComponentDidMount();
		
		// Load the list of patient therapies into local state
		await this.reload(true, true);
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		const {patientId} = this.props;
		
		// Reload the list and trigger 'onLoad' if 'patientId' prop changes
		if (patientId !== prevProps.patientId) {
			if (!!patientId) this.reload(true).then();
			else executeComponentCallback(this.props.onLoad, []);
		}
	}

	/**
	 * Reload the patient therapy list
	 * 
	 * @param {boolean} [triggerOnLoad] - Flag that specifies if 'onLoad' event will be triggered after loading the list.
	 * @param {boolean} [onMount] - Flag that specifies if this method is called as a part of the mount cycle.
	 * @return {Promise<any>}
	 */
	reload(triggerOnLoad, onMount) {
		const {patientId, fetchPatientTherapySelectOptionsAction} = this.props;
		const executeAbortableAction = (onMount ? this.executeAbortableActionMount : this.executeAbortableAction);
		
		return this.setState({patientTherapyListLoading: true})
			.then(() => executeAbortableAction(fetchPatientTherapySelectOptionsAction, patientId))
			.then(patientTherapyList => this.setState({patientTherapyList, patientTherapyListLoading: false}))
			.then(state => {
				if (triggerOnLoad) return executeComponentCallback(this.props.onLoad, state.patientTherapyList);
				else return Promise.resolve();
			})
			// Select option if it is the only one
			.then(() => {
				const {selectOnlyOptionOnLoad} = this.props;
				const patientTherapyList = getArray(this.state, 'patientTherapyList');
				
				if (selectOnlyOptionOnLoad && patientTherapyList.length === 1) {
					executeComponentCallback(this.props.onChange, patientTherapyList[0].id);
				}
			});
	}

	/**
	 * Trigger onChange event with the select option found by the specified patient therapy ID
	 * @param {string} id - Patient therapy ID.
	 */
	selectById(id) {
		const {patientTherapyList} = this.state;
		const patientTherapy = find(patientTherapyList, {id}, null);
		
		executeComponentCallback(this.props.onChange, patientTherapy);
	}
	
	render() {
		const {patientTherapyList, patientTherapyListLoading} = this.state;
		
		return (
			<SelectInput
				className={this.getOption('domPrefix')}
				primaryKey="id"
				simpleValue={false}
				getOptionLabel={o => 
					`${o.diagnosis} ${o.medicalRecordCategory} ${o.sentByPhysician} ${getString(o, 'diagnosisCode')}`
				}
				options={patientTherapyList}
				isLoading={patientTherapyListLoading}
				components={{Option: PatientTherapyOption, SingleValue: PatientTherapySingleValue}}
				{...omit(this.props, ['patientId', 'onLoad', ...Object.keys(allActions)])}
			/>
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
PatientTherapySelectInput.propTypes = {
	...SelectInput.propTypes,
	
	patientId: PropTypes.string.isRequired,
	// Flag that specifies if option will be selected if it is the only one after options are loaded
	selectOnlyOptionOnLoad: PropTypes.bool,
	
	// Event triggered after options are loaded
	// @note It will trigger even if options failed to load.
	// @param {PatientTherapySelectOptionDataObject[]}
	onLoad: PropTypes.func,
};

export default connect(null, allActions, null, {forwardRef: true})(PatientTherapySelectInput);