import {get} from "lodash";

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'schedule';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: '@schedule/reset',
	SET_DATE: '@schedule/set_date',
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),
	setDate: date => ({type: REDUCER_TYPES.SET_DATE, date}),
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	date: null,
};

// Reducer function
const schedule = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET: return { ...initialState };

		case REDUCER_TYPES.SET_DATE: return {...state, date: !!action.date ? new Date(action.date) : null};

		default: return state;
	}
};

// Selectors
export const selectors = {
	getDate: state => get(state, [reducerStoreKey, 'date'], null),
};

export default schedule;