import {cloneDeep, get} from 'lodash';
import {getString, resetObjectValues} from "Core/helpers/data";
import {getDate, getDateString, STANDARD_DATE_TIME_FORMAT} from 'Core/helpers/datetime';
import {AnonymousAppointmentDataObject} from "DataObjects/appointment";

/**
 * Get input data from list item data
 * @param {Object} rawData - List item data.
 * @return {AnonymousAppointmentDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new AnonymousAppointmentDataObject(
		getString(result,	'uniqueId'),
		getDate(get(result, 'appointmentDate'), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S), 
		getDate(get(result, 'timeFrom'), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		getDate(get(result, 'timeTo'), STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		getString(result,	'officeLocationId'),
		getString(result,	'therapistId'),
		getString(result,	'customerFirstname'),
		getString(result,	'customerLastname'),
		getString(result,	'phoneNumber'),
		getString(result,	'note'),
	);

	return result;
}

/**
 * Get output data from input data
 * @param {AnonymousAppointmentDataObject} inputData - Input data.
 * @return {Object}
 */
export const output = inputData => {
	let result = cloneDeep({
		appointmentDate: getDateString(inputData.date, STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S),
		timeFrom: getDateString(
			getDate(inputData.timeFrom, STANDARD_DATE_TIME_FORMAT.TIME), 
			STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S
		),
		timeTo: getDateString(
			getDate(inputData.timeTo, STANDARD_DATE_TIME_FORMAT.TIME), 
			STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S
		),
		officeLocationId: inputData.officeLocationId,
		therapistId: inputData.therapistId,
		customerFirstname: inputData.firstName,
		customerLastname: inputData.lastName,
		phoneNumber: inputData.phoneNumber,
		note: inputData.note,
	});

	// Set proper empty values expected by the API
	result = resetObjectValues(result);

	return result;
}