import {ioJsonAction} from 'Core/store/actions/io';
import {getArray} from 'Core/helpers/data';
import * as dataMap from './dataMap';

/**
 * Search for patients with a query string
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} query - Query string for searching for a patient.
 * @return {function(*): Promise<PatientSelectOptionDataObject[]|undefined>}
 */
export const patientQuickSearchAction = (abortCallback, query) => dispatch => {
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'member/customer/search',
		{searchString: query},
	)(dispatch)
		// Map the result data array
		.then(res => getArray(res, 'data').map(i => dataMap.input(i)));
};