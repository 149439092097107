/**
 * Data object representing the recommendations list item
 * @note This is usually used in data table as a row. Params don't have default values since this data object is used to
 * represent data that actually exists.
 */
export class RecommendationsListItemDataObject {
	/**
	 * @param {string} id - List item's ID.
	 * @param {string} recommendationsName - Recommendations list item's name.
	 */
	constructor(id, recommendationsName) {
		this.id = id;
		this.recommendationsName = recommendationsName;
	}
}

/**
 * Data object representing the recommendations item
 * @note This is usually used when creating or updating data table item using a popup or a dialog. Params have default 
 * values since this data object can be used for creating an item. This is why 'id' has a default value of null which 
 * represents a new item that is being created.
 */
export class RecommendationsItemDataObject {
	/**
	 * @param {string|null} [id=null] - Item's ID.
	 * @param {string} [recommendationsName=''] - Recommendations item's name.
	 */
	constructor(id = null, recommendationsName = '') {
		this.id = id;
		this.recommendationsName = recommendationsName;
	}
}