import {cloneDeep} from "lodash";
import {getString} from "Core/helpers/data";
import {SuppliersListItemDataObject} from "../dataObjects";

/**
 * Get input data from raw data
 * @param {Object} rawData - Raw data received from IO.
 * @return {SuppliersListItemDataObject}
 */
export const input = rawData => {
	let result = cloneDeep(rawData);

	result = new SuppliersListItemDataObject(
		getString(result, 'id'),
		getString(result, 'name'),
	);

	return result;
}

// @note There is no output function since list is read only.