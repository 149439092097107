import {get, cloneDeep, isEqual, has, isPlainObject, isEmpty, compact} from 'lodash';
import reduxStore from '../store';
import {reducerStoreKey, selectors, actionCreators} from './reducer';
import {getString} from "../helpers/data";

export const getTranslation = () => cloneDeep(selectors.getTranslation(reduxStore.getState()));
export const getLocale = () => cloneDeep(selectors.getLocale(reduxStore.getState()));

/**
 * Observable Redux store change function
 * @description This function is used to check for store changes by making store subscribe function observable.
 * @see https://github.com/reduxjs/redux/issues/303#issuecomment-125184409
 *
 * @param {object} store - Whole redux store.
 * @param {function(state: any): any} select - Function that receives current state and should return section of the 
 * store to check for changes.
 * @param {function(state: any): any} onChange - Callback function that will be called when selected store value change 
 * was detected.
 * @return {function} Function that will unsubscribe from Redux store when it is called.
 */
function observeReduxStore(store, select, onChange) {
	let currentState = select(store.getState());

	function handleChange() {
		let nextState = select(store.getState());
		if (!isEqual(nextState, currentState)) {
			currentState = nextState;
			onChange(currentState);
		}
	}

	let unsubscribe = store.subscribe(handleChange);
	handleChange();
	return unsubscribe;
}

/**
 * Internationalization (i18n) main class that uses Redux store to save local and translation data. It will observe 
 * locale changes in Redux store and dynamically load appropriate translation file into Redux store.
 */
export default class I18n {
	/**
	 * Engine reference used to unsubscribe from Redux state
	 * @type function
	 */
	engine;

	// Events
	/** @type {function(loadedTranslation: object): void} */
	onTranslationChange;
	/** @type {function(locale: LocaleObj): void} */
	onLocaleChange;

	/**
	 * Class constructor
	 *
	 * @param {boolean} [startEngine=false] - If true, translation engine will be started which will autoload 
	 * translation files into Redux store when locale (also from Redux store) changes.
	 * @param {function(loadedTranslation: object): void} [translationChangeCallback] - Callback function called on 
	 * translation change.
	 * @param {function(locale: LocaleObj): void} [localeChangeCallback] - Callback function called on locale change.
	 */
	constructor(startEngine = false, translationChangeCallback, localeChangeCallback) {
		// Engine methods
		this.startEngine = this.startEngine.bind(this);
		this.stopEngine = this.stopEngine.bind(this);

		// Translation methods
		this.getLocale = this.getLocale.bind(this);
		this.setLocale = this.setLocale.bind(this);
		this.getTranslation = this.getTranslation.bind(this);
		this.setTranslation = this.setTranslation.bind(this);
		this.loadTranslation = this.loadTranslation.bind(this);

		// Initialize translation engine if 'initEngine' argument is true
		if (startEngine) this.startEngine();
	}

	/**
	 * Start the translation engine
	 * @description Translation engine, when started, will automatically and dynamically load the appropriate translation
	 * file into Redux store every time Redux store local changes.
	 */
	startEngine() {
		// Create observable function to check for locale change in Redux store (subscribe to local change) and load the
		// appropriate language file (translation) into Redux store.
		this.engine = observeReduxStore(
			reduxStore,
			reduxState => get(reduxState, [reducerStoreKey, 'locale']),
			locale => { this.loadTranslation(locale).then(); }
		);
	}

	/**
	 * Stop translation engine
	 */
	stopEngine() {
		// Unsubscribe from Redux store
		if (this.engine) this.engine();
	}

	/**
	 * Get current locale data from Redux store
	 *
	 * @return Current locale data from Redux store.
	 */
	getLocale() { return getLocale(); }

	/**
	 * Set current locale to Redux store
	 *
	 * @param {LocaleObj} locale - Locale data.
	 */
	setLocale(locale) {
		// Set locale in Redux store
		reduxStore.dispatch(actionCreators.setLocale(locale));

		// Trigger 'onLocaleChange' event if the callback function was specified
		if (this.onLocaleChange) this.onLocaleChange(cloneDeep(locale));
	}

	/**
	 * Get current translation data from Redux store
	 *
	 * @return {object} Current translation data from Redux store.
	 */
	getTranslation() { return getTranslation(); }

	/**
	 * Set current translation data to Redux store
	 *
	 * @param {object} translation - Translation data.
	 */
	setTranslation(translation) {
		// Set translation in Redux store
		reduxStore.dispatch(actionCreators.setTranslation(translation));

		// Trigger 'onTranslationChange' event if the callback function was specified
		if (this.onTranslationChange) this.onTranslationChange(cloneDeep(translation));
	}

	/**
	 * App wide locale load method
	 * @description This method will load translation into Redux store based on locale change.
	 * @note This method dynamically imports the required translation file.
	 *
	 * @param {LocaleObj} locale - Locale to load.
	 * @return {Promise<object>} Loaded translation or current translation from Redux store. This promise will always 
	 * resolve even if error occurs during the loading. It that case promise will resolve with current translation from 
	 * Redux store even if it is undefined.
	 */
	loadTranslation(locale) {
		return import(
			/* webpackChunkName: "i18n-translation-[request]" */
			`../../i18n/translations/${locale.fileName}`
			)
			.then(({default: loadedTranslation}) => {
				this.setTranslation(loadedTranslation);
				return loadedTranslation;
			})
			.catch(error => {
				console.log('%cError while trying to load locale!', 'color: red', locale);
				console.log(error);
				return new Promise(resolve => resolve(this.getTranslation()));
			});
	}

	/**
	 * Check if translation string exists
	 * 
	 * @param {string} string - String to translate.
	 * @param {string|string[]}[path=''] - Translation path.
	 * @return {boolean} True if translation string exists for the given translation path.
	 */
	static hasTranslation(string, path = '') {
		const pathArray = compact(Array.isArray(path) ? [...path] : [...path.split('.')]);
		return has(getTranslation(), [...pathArray, string]);
	}

	/**
	 * Check if translation path exists
	 *
	 * @param {string|string[]}path - Translation path.
	 * @return {boolean} True if translation path exists.
	 */
	static hasTranslationPath(path) {
		return (Array.isArray(path) ? has(getTranslation(), compact(path)) : has(getTranslation(), path));
	}
	
	/**
	 * Main translate method
	 * @note Method uses currently loaded translation data from Redux store to find translation for the specified string.
	 *
	 * @param {string} string - String to translate.
	 * @param {string|string[]}[path=''] - Translation path.
	 * @param {Object} [variables={}] - Dynamic variables used in translation (format: %{variable}).
	 * @return {string} Translated string or original string if translation could not be found.
	 */
	static translate(string, path = '', variables = {}) {
		let result = (
			string ? get(get(getTranslation(), (Array.isArray(path) ? compact(path) : path)), string, string) : string
		);
		
		// Replace any dynamic values
		if (isPlainObject(variables) && !isEmpty(variables)) {
			const regexPattern = /%{(.+?)}/;
			const regex = new RegExp(regexPattern, 'gim');

			result = result.replace(regex, match => {
				// Remove "%{" from the beginning and "}" from the end of matched item
				let variable = match.replace(/^%{|}$/gm, '');

				// Trim matched item
				variable = variable.trim();
				
				// Replace matched value with variable value
				return getString(variables, variable, match);
			});
			
		}
		
		return result; 
	}

	/**
	 * Translate path
	 * @description This method will use the specified translation path (in translation file) to find the appropriate 
	 * translation.
	 * @note Method uses currently loaded translation data from Redux store to find translation for the specified string.
	 *
	 * @param {string|string[]} path - Translation path.
	 * @param {Object} [variables={}] - Dynamic variables used in translation (format: %{variable}).
	 * @return {string} Translated string or original string if translation could not be found.
	 */
	static translatePath(path, variables = {}) {
		let result = get(getTranslation(), path, (Array.isArray(path) ? compact(path[path.length - 1]) : path));

		// Replace any dynamic values
		if (isPlainObject(variables) && !isEmpty(variables)) {
			const regexPattern = /%{(.+?)}/;
			const regex = new RegExp(regexPattern, 'gim');

			result = result.replace(regex, match => {
				// Remove "%{" from the beginning and "}" from the end of matched item
				let variable = match.replace(/^%{|}$/gm, '');

				// Trim matched item
				variable = variable.trim();

				// Replace matched value with variable value
				return getString(variables, variable, match);
			});

		}
		
		return result;
	}
}

/**
 * Check if translation string exists
 *
 * @param {string} string - String to translate.
 * @param {string|string[]}[path=''] - Translation path.
 * @return {boolean} True if translation string exists for the given translation path.
 */
export const hasTranslation = (string, path = '') => I18n.hasTranslation(string, path);

/**
 * Check if translation path exists
 *
 * @param {string|string[]}path - Translation path.
 * @return {boolean} True if translation path exists.
 */
export const hasTranslationPath = path => I18n.hasTranslationPath(path);

/**
 * Main translate function
 * @note Function uses currently loaded translation data from Redux store to find translation for the specified string.
 *
 * @param {string} string - String to translate. If translation cannot be found original string will be returned.
 * @param {string|string[]} [path] - Translation path.
 * @param {Object} [variables={}] - Dynamic variables used in translation (format: %{variable}).
 * @return {string} Translated string or original string if translation could not be found.
 */
export const translate = (string, path, variables) => I18n.translate(string, path, variables);

/**
 * Main translate function that translates path
 * @note Function uses currently loaded translation data from Redux store to find translation for the specified string.
 *
 * @param {string|string[]} path - Translation path.
 * @param {Object} [variables={}] - Dynamic variables used in translation (format: %{variable}).
 * @return {string} Translated string or original string if translation could not be found.
 */
export const translatePath = (path, variables) => I18n.translatePath(path, variables);

/**
 * Effect hook translation function
 * @description Function that can be used in 'useEffect' hook to observe translation changes and update (re-render)
 * function components automatically when change is detected. Translation usually changes after language (locale)
 * changes. This function ensures that language change will re-render the component showing properly translate text.
 *
 * @example
 * 	function MyComponent() {
 *			// Define component's translation path.
 *			// NOTE: This is not necessary but is a good practice when component does not use some global translation path.
 *			const translationPath = 'App';
 *		
 *			// Use 'i18nEffect' function in React 'useEffect' function to update (re-render) component when translation 
 *			// changes.
 *			useEffect(i18nEffect.bind(null, useState(getTranslation())[1]), []);
 *		
 *			return (
 *				<div>{translate('Translate me!', translationPath)}</div>
 *			);
 *		}
 *
 * @param {function(prevState: any): any} updateTranslation - Hook function used to update the translation in 
 * component's state. This is used to trigger component re-render when translation changes.
 * @return {function} Unsubscribe function to stop observing translation changes. This function will be automatically
 * called on function component unmount because 'useEffect' hook return function is automatically called when component
 * is removed from the DOM (unmount).
 */
export const i18nEffect = (updateTranslation) => {
	return observeReduxStore(
		reduxStore,
		reduxState => get(reduxState, [reducerStoreKey, 'translation']),
		translation => { updateTranslation(translation) }
	);
};

/**
 * Translation observer function
 * @description Function that observes translation changes and calls a callback function when change is detected.
 * Translation usually changes after language (locale) changes.
 * @note Please don't forget to unsubscribe once tracking translation changes is not needed anymore.
 *
 * @param {function(translation: object): void} translationChangeCallback - Callback function that will be called when
 * translation change is detected.
 * @return {function} Unsubscribe function to stop observing translation changes. Please don't forget to call this
 * function once tracking translation changes is not needed anymore.
 */
export const i18nObserve = (translationChangeCallback) => {
	return observeReduxStore(
		reduxStore,
		reduxState => get(reduxState, [reducerStoreKey, 'translation']),
		translation => { translationChangeCallback(translation) }
	);
};

/**
 * Translation observer function used to update (re-render) class components
 * @description Function that observes translation changes and updates (re-renders) class components automatically when
 * change is detected. Translation usually changes after language (locale) changes. This function ensures that language
 * change will re-render the component showing properly translate text.
 *
 * @example
 *		class MyComponent extends React.Component {
 *			// Define component's translation path.
 *			// NOTE: This is not necessary but is a good practice when component does not use some global translation path.
 *			translationPath = 'App';
 *			
 *			// Define unsubscribe function value so that you can assign it and call it in your component
 *			i18n: () => {};
 *			
 *			componentDidMount() {
 *				// Initialize translate observer that will update (re-render) component every type translation changes.
 *				this.i18n = i18nComponentUpdater(this);
 *			}
 *			
 *			componentWillUnmount() {
 *				// Unsubscribe from translation observer.
 *				this.i18n();
 *			}
 *			
 *			render() {
 *				return (
 *					<div>{translate('Translate me!', this.translationPath)}</div>
 *				);
 *			}
 *		}
 *
 *
 * @param {React.Component} component - Component to update when translation change was detected.
 * @return {function} Unsubscribe function to stop observing translation changes. Please don't forget to call this
 * function in 'componentWillUnmount' method of the 'component'.
 */
export const i18nComponentUpdater = (component) => {
	return i18nObserve(translation => {
		// If component has a 'handleLocaleChange' method call it
		if (typeof get(component, 'handleLocaleChange') === 'function') component.handleLocaleChange(translation);
		// If component does not have a 'handleLocaleChange' method force update it instead
		else component.forceUpdate();
	});
};

// Type definitions
/**
 * @typedef {object} LocaleObj
 * @property {string} locale - Locale code used by the app (Uses IETF language tag) consisting of (ex: 'sr-Latn-BA', 
 * 'sr-Cyrl-RS', 'en-US'):
 * 	- lowercase, two letter ISO 639-1 code (ex: 'sr'),
 * 	- [optional] Title Case ISO 15924 script name (ex: 'Latn'),
 * 	- [optional] UPPERCASE two-letter ISO 3166-1 alpha-2 country code (ex: 'RS')
 * 	
 * @property {string} name - Language name (ex: 'Srpski (latinica)').
 * 
 * @property {string} engName - Language English name (ex: 'Serbian (romanized)').
 * 
 * @property {string} fileName - Language file name, usually `${locale}.json` (ex: 'sr-Latn.json').
 * 
 * @property {string} code2 - ISO 639-1 two-letter lowercase language code (ex: 'sr') 
 * https://www.loc.gov/standards/iso639-2/php/code_list.php
 * 
 * @property {string} code3 - ISO 639-2 three-letter lowercase language code (ex: 'srp') 
 * https://www.loc.gov/standards/iso639-2/php/code_list.php
 * 
 * @property {string} countryCode - ISO 3166-1 alpha-2 two-letter uppercase country code (ex: 'RS')
 * https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
 * 
 * @property {{short: string, standard: string, long: string, full: string}} [dateFormat]
 * @property {{short: string, standard: string, long: string, full: string}} [dateTimeFormat]
 * @property {{short: string, standard: string, long: string, full: string}} [timeFormat]
 * @property {{
 * 	[currency]: string, 
 * 	[delimiters]: {
 * 		thousands: string, 
 * 		decimal: string
 * 	}, 
 * 	[abbreviations]: {
 * 	   thousand: string,
			million: string,
			billion: string,
			trillion: string
 * 	}
 *	}} numbers
 */