import {hideLoadingFunction, showLoading} from "Core/helpers/loading";
import {ioJsonAction} from "Core/store/actions/io";
import {isSuccessful} from "Core/helpers/io";
import * as filterDataMap from "./dataMap/filter";
import {clearMessagesAction} from "Core/components/global/Message";

/**
 * Fetch statistics for a specific statistics type
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {StatisticsType} type - Type of statistics to get.
 * @param {StatisticsFilterDataObject} filter - Filter that defines which statistics will be fetched.
 * @param {Object} dataMap - Data map to use for mapping the data.
 * @param {string} [loadingSelector=''] - Selector for the loading overlay.
 * @return {function(*): Promise<{data: StatisticsDataObject, filter: StatisticsFilterDataObject}|undefined>}
 */
export const fetchStatisticsAction = (
	abortCallback, type, filter, dataMap, loadingSelector = '.statistics-result-wrapper'
) => dispatch => {
	clearMessagesAction()(dispatch);
	const loading = (!!loadingSelector ? showLoading(loadingSelector) : undefined);
	
	return ioJsonAction(
		abortCallback,
		'defaultAuthorizedApi',
		'member/statistics/fetch-statistics',
		{...filter, type},
		!!loading ? hideLoadingFunction(loading) : undefined,
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => {
			if (isSuccessful(responseData)) {
				return ({
					filter: filterDataMap.input(filter),
					data: {...dataMap.input(responseData.data, type), type},
				});
			}
			return undefined;
		});
};