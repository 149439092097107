/** @typedef {string} CalendarView */
export const CALENDAR_VIEW_DAY_GRID_MONTH = 'day_grid_month';
export const CALENDAR_VIEW_TIME_GRID_WEEK = 'time_grid_week';
export const CALENDAR_VIEW_TIME_GRID_DAY = 'time_grid_day';
export const CALENDAR_VIEW_LIST_MONTH = 'list_month';
export const CALENDAR_VIEW_LIST_WEEK = 'list_week';
export const CALENDAR_VIEW_LIST_DAY = 'list_day';
/** @enum {CalendarView} */
export const CALENDAR_VIEW = {
	DAY_GRID_MONTH: CALENDAR_VIEW_DAY_GRID_MONTH,
	TIME_GRID_WEEK: CALENDAR_VIEW_TIME_GRID_WEEK,
	TIME_GRID_DAY: CALENDAR_VIEW_TIME_GRID_DAY,
	LIST_MONTH: CALENDAR_VIEW_LIST_MONTH,
	LIST_WEEK: CALENDAR_VIEW_LIST_WEEK,
	LIST_DAY: CALENDAR_VIEW_LIST_DAY,
};
/** @type {CalendarView[]} */
export const CALENDAR_VIEWS = [
	CALENDAR_VIEW_DAY_GRID_MONTH,
	CALENDAR_VIEW_TIME_GRID_WEEK,
	CALENDAR_VIEW_TIME_GRID_DAY,
	CALENDAR_VIEW_LIST_MONTH,
];