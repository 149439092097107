import React from "react";
import ReactDOMServer from 'react-dom/server';
import BaseComponent from "../../BaseComponent";
import PropTypes from "prop-types";

class Html extends BaseComponent {
	render() {
		const {id, className, content, element, elementProps} = this.props;

		// If content is React element (JSX) it will be converted to static html
		let htmlString = (React.isValidElement(content) ? ReactDOMServer.renderToStaticMarkup(content) : content);

		const props = {
			id,
			className: `html-component ${className}`,
			dangerouslySetInnerHTML: {__html: htmlString},
			...elementProps
		};

		return React.createElement(element, props);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
Html.propTypes = {
	// Component wrapper id attribute
	id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	// Component wrapper class attribute
	className: PropTypes.string,
	// HTML content to render
	content: PropTypes.any,
	// DOM element tag name to use as HTML content wrapper
	element: PropTypes.string,
	// DOM element props
	elementProps: PropTypes.object
};

/**
 * Define component default values for own props
 */
Html.defaultProps = {
	id: '',
	className: '',
	content: '',
	element: 'span',
	elementProps: {},
};

export default Html;