import {actionCreators} from 'Core/store/reducers';
import {reducerStoreKey} from "../reducers/schedule";

/**
 * Set current date for schedule calendar
 * 
 * @param {Date} date - Date to set.
 * @return {(function(*): void)|*}
 */
export const setScheduleDateAction = date => dispatch => {
	dispatch(actionCreators[reducerStoreKey].setDate(date));
}