/**
 * Data object representing the search visits list item
 * @note This is usually used in data table as a row. Params don't have default values since this data object is used to
 * represent data that actually exists.
 */
export class SearchVisitsListItemDataObject {
	/**
	 * @param {string} id - List item's ID.
	 * @param {Date} visitDate - Date of visit.
	 * @param {string} visitTimeFrom - Time when the visit starts.
	 * @param {string} visitTimeTo - Time when the visit ends.
	 * @param {string} visitNote - Note of the visit.
	 * @param {string} therapyPatientId - Patients therapy ID.
	 * @param {string} therapyPatientFirstName - First name of the patient visiting.
	 * @param {string} therapyPatientLastName - Last name of the patient visiting.
	 * @param {string} officeLocationName - Name of the office location where the visit takes place.
	 * @param {string} therapist - Therapist for the visit.
	 * 
	 */
	constructor(id, visitDate, visitTimeFrom, visitTimeTo, visitNote, therapyPatientId, therapyPatientFirstName,
		therapyPatientLastName, officeLocationName, therapist) {
		this.id = id;
		this.visitDate = visitDate;
		this.visitTimeFrom = visitTimeFrom;
		this.visitTimeTo = visitTimeTo;
		this.visitNote = visitNote;
		this.therapyPatientId = therapyPatientId;
		this.therapyPatientFirstName = therapyPatientFirstName;
		this.therapyPatientLastName = therapyPatientLastName;
		this.officeLocationName = officeLocationName;
		this.therapist = therapist;
	}
}

