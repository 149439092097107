/**
 * Skin resource
 * This resource will handle the skin.
 *
 * NOTE: Resources must default export a function that return a Promise resolved with an object where key is the
 * RESOURCE_KEY and value is loaded resource data. Resources can be used to get any resource but are most useful for
 * loading global app resources needed by the app to work properly.
 */

import {skin_default_mode, skin_mode_cookie_group} from "Config/app";
import {SKIN_MODE} from "Core/const/global";
import {getStorageValue, setStorageValue, STORAGE_TYPE} from "Core/storage";
import CookieConsent from "Core/dataProtection/cookieConsent";
import {CookieData} from "Core/dataProtection/objects/cookie";
import {onSkinModeChange} from "Core/helpers/skin";
import {getBoolFromTinyInt} from "Core/helpers/data";

export const RESOURCE_KEY = 'skin';

function isSystemDarkMode() {
	return !!(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);
}

/**
 * Resource function
 * @return {Promise<Object<string, any>>} Promise that will resolve with an object where key is the RESOURCE_KEY and
 * value is loaded resource data.
 */
function resourceFunction() {
	// Get skin mode cookie settings
	const skinModeCookie = new CookieData(skin_mode_cookie_group, 'skin_mode', STORAGE_TYPE.LOCAL);
	// Get system skin mode flag cookie settings
	const systemSkinModeCookie = new CookieData(skin_mode_cookie_group, 'use_system_skin_mode', STORAGE_TYPE.LOCAL);

	// Try to get saved system skin mode flag
	const savedSystemSkinModeFlag = (
		CookieConsent.isAllowed(systemSkinModeCookie) ?
			getBoolFromTinyInt(getStorageValue('use_system_skin_mode', STORAGE_TYPE.LOCAL)) :
			undefined
	);
	// Try to get saved skin mode
	const savedSkinMode = (
		CookieConsent.isAllowed(skinModeCookie) ? 
			getStorageValue('skin_mode', STORAGE_TYPE.LOCAL) : 
			undefined
	);
	
	let skinMode;
	
	// Use system skin mode if system skin mode cookie is true
	if (savedSystemSkinModeFlag) {
		skinMode = (isSystemDarkMode() ? SKIN_MODE.DARK : SKIN_MODE.LIGHT);
		// Set saved skin mode to system skin mode if it is different then the system skin mode
		if (savedSkinMode !== skinMode) {
			if (CookieConsent.isAllowed(skinModeCookie)) setStorageValue('skin_mode', skinMode, STORAGE_TYPE.LOCAL);
		}
	}
	// Use skin mode saved in cookies
	else if (savedSkinMode) skinMode = savedSkinMode;
	// Use default skin mode
	else skinMode = skin_default_mode;

	document.documentElement.setAttribute('data-skin-mode', skinMode);
	
	// Create event for skin mode change
	// @note Event name is 'onSkinModeChange' and it gets 'newSkinMode' and 'oldSkinMode' in the custom event 'detail'.
	onSkinModeChange();
	
	return Promise.resolve({
		[RESOURCE_KEY]: {
			skinMode: skinMode,
		}
	});
}

export default resourceFunction;