/** @typedef {string} DayOfWeek */
export const DAY_OF_WEEK_MONDAY = 'MONDAY';
export const DAY_OF_WEEK_TUESDAY = 'TUESDAY';
export const DAY_OF_WEEK_WEDNESDAY = 'WEDNESDAY';
export const DAY_OF_WEEK_THURSDAY = 'THURSDAY';
export const DAY_OF_WEEK_FRIDAY = 'FRIDAY';
export const DAY_OF_WEEK_SATURDAY = 'SATURDAY';
export const DAY_OF_WEEK_SUNDAY = 'SUNDAY';
/** @enum {DayOfWeek} */
export const DAY_OF_WEEK = {
	MONDAY: DAY_OF_WEEK_MONDAY,
	TUESDAY: DAY_OF_WEEK_TUESDAY,
	WEDNESDAY: DAY_OF_WEEK_WEDNESDAY,
	THURSDAY: DAY_OF_WEEK_THURSDAY,
	FRIDAY: DAY_OF_WEEK_FRIDAY,
	SATURDAY: DAY_OF_WEEK_SATURDAY,
	SUNDAY: DAY_OF_WEEK_SUNDAY,
};
/** @type {DayOfWeek[]} */
export const DAYS_OF_WEEK = [
	DAY_OF_WEEK_MONDAY,
	DAY_OF_WEEK_TUESDAY,
	DAY_OF_WEEK_WEDNESDAY,
	DAY_OF_WEEK_THURSDAY,
	DAY_OF_WEEK_FRIDAY,
	DAY_OF_WEEK_SATURDAY,
	DAY_OF_WEEK_SUNDAY,
];