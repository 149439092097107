export class VisitDataObject {
	/**
	 * @param {?string} [id=null]
	 * @param {?string} [patientId=null]
	 * @param {?string} [therapyId=null]
	 * @param {?Date} [date=null]
	 * @param {?Date} [timeFrom=null]
	 * @param {?Date} [timeTo=null]
	 * @param {string} [note='']
	 * @param {?string} [officeLocationId=null]
	 * @param {?string} [therapistId=null]
	 * @param {?string} [appointmentId=null]
	 */
	constructor(
		id = null, patientId = null, therapyId = null, date = null, timeFrom = null, timeTo = null, note = '', 
		officeLocationId = null, therapistId = null, appointmentId = null,
	) {
		this.id = id;
		this.patientId = patientId;
		this.therapyId = therapyId;
		this.date = date;
		this.timeFrom = timeFrom;
		this.timeTo = timeTo;
		this.note = note;
		this.officeLocationId = officeLocationId;
		this.therapistId = therapistId;
		this.appointmentId = appointmentId;
	}
}