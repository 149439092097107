import CoreACL from "../core/acl";

/**
 * App access-control list (ACL) class
 * @note This class must extend CoreACL class, but it does not have to implement any changes if no custom functionality
 * is needed.
 */
class ACL extends CoreACL {
	
}

export default ACL;