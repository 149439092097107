import {find, reject, get, cloneDeep} from 'lodash';

/**
 * Unique Redux store key associated to this reducer
 * IMPORTANT: All reducers must export this value!
 * @type {string}
 */
export const reducerStoreKey = 'dialog';

// Define reducer types handled by this reducers
export const REDUCER_TYPES = {
	RESET: `@dialog/reset`,
	ADD_DIALOG: `@dialog/add`,
	REMOVE_DIALOG: `@dialog/remove`,
	CLEAR_DIALOGS: `@dialog/clear`,
};

// Define action creators for all reducer types
export const actionCreators = {
	reset: () => ({type: REDUCER_TYPES.RESET}),
	openDialog: (GUIID, component, props, options) => ({type: REDUCER_TYPES.ADD_DIALOG, GUIID, component, props, options}),
	closeDialog: GUIID => ({type: REDUCER_TYPES.REMOVE_DIALOG, GUIID}),
	closeAllDialogs: () => ({type: REDUCER_TYPES.CLEAR_DIALOGS})
};

/**
 * Initial reducer state
 * IMPORTANT: All reducers must export initial state object!
 * @type {Object<string, any>}
 */
export const initialState = {
	openedDialogs: []
};

// Reducer function
const reducer = (state = {...initialState}, action) => {
	switch (action.type) {
		case REDUCER_TYPES.RESET:
			return { ...initialState };
			
		case REDUCER_TYPES.ADD_DIALOG:
			// Do not add dialog if it is already opened.
			if (find(state.openedDialogs, { GUIID: action.GUIID })) return state;
			
			let updatedState = cloneDeep(state);
			updatedState.openedDialogs.push({
				GUIID: action.GUIID,
				component: action.component,
				props: action.props,
				options: action.options,
			});
			
			return updatedState;
			
		case REDUCER_TYPES.REMOVE_DIALOG:
			return {
				...state,
				openedDialogs: reject(cloneDeep(state.openedDialogs), { GUIID: action.GUIID })
			};
			
		case REDUCER_TYPES.CLEAR_DIALOGS:
			return {
				...state,
				openedDialogs: initialState.openedDialogs
			}
			
		default:
			return state;
	}
};

// Selectors
export const selectors = {
	getDialogs: state => get(state, [reducerStoreKey, 'openedDialogs'], initialState.openedDialogs),
	getDialog: (state, GUIID) => find(state[reducerStoreKey].openedDialogs, { GUIID })
};

export default reducer;