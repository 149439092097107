/**
 * Abstract component used to create other components
 * Features:
 * 	- Managing component options through get and set methods.
 * 	- Managing event listeners (registered event listeners will be automatically removed when component unmounts).
 * 	- I18n support.
 * 	- Standard DOM manipulation interval.
 * 	- 'Click outside' utility.
 * 	- Standardized 'canRender' method.
 * 	- Standardized unique unchangeable ID value.
 */

import React from 'react';
import {v4} from 'uuid';
import {
	get,
	set,
	cloneDeep,
	isEmpty,
	find,
	findIndex,
	reject,
	isArray,
	isObject,
	isFunction,
	isPlainObject,
	isEqual,
	omit,
	pick
} from 'lodash';
import move from 'lodash-move';
import I18n, {i18nComponentUpdater} from 'Core/i18n';
import {rtrimChar} from "Core/helpers/string";
import {hideLoading, showLoading} from "Core/helpers/loading";
import {getArray, isset} from "Core/helpers/data";
import {AsyncMountError} from "Core/errors";

export default class BaseComponent extends React.Component {
	/**
	 * Component's ID
	 * @note This does not have a particular purpose and can be used in any context as a unique component's id that does 
	 * not change during component's lifecycle.
	 * @type {string}
	 */
	id = `c-${v4()}`;
	
	/**
	 * Define I18n unsubscribe function so that you can assign it and call it in your component.
	 * @type {Function}
	 */
	i18nUnsubscribe;
	
	/**
	 * Component's options object
	 * @type {BaseComponentOptions}
	 * @private
	 */
	_options;
	
	/**
	 * List of all event listeners registered in this component
	 * @description Registered event listeners will be automatically added after component mounts and removed just before
	 * component unmount.
	 * @type {ComponentEventListener[]} - "type" is a key of WindowEventMap
	 */
	registeredEventListeners = [];
	
	/**
	 * DOM manipulation interval
	 * @description Interval that should run continuously while the component is mounted that runs DOM manipulation
	 * scripts that requite running in an interval (for example to ensure some DOM elements actually exist).
	 */
	domManipulationInterval;

	/**
	 * 
	 * @type {{id: string, abortController: AbortController}[]}
	 */
	abortControllers = [];

	/**
	 * Array of loading overlay GUI ID
	 * @type {string[]}
	 */
	loadingOverlays = []

	/**
	 * Count the number of calls to 'componentDidMount' method calls
	 * @note This can be useful when using async calls in 'componentDidMount' on React 18 and above since components need
	 * to be resilient to mounting and destroyed multiple times.
	 * @type {number}
	 */
	mountCount = 0;

	/**
	 * Class constructor
	 *
	 * @param {object} props - Component props.
	 * @param {BaseComponentOptions} [options={}] - Component options from child class that will override the default 
	 * options.
	 */
	constructor(props, options = {}) {
		super(props);

		/**
		 * Component's options object
		 * @note This can contain any number of option fields used by the component. Child classes can override default
		 * options by sending 'options' argument to this constructor via 'super' function.
		 * @type {BaseComponentOptions}
		 * @private
		 */
		this._options = {
			/**
			 * Path inside the translation JSON file where component translations are defined
			 * @type {string}
			 */
			translationPath: undefined,

			/**
			 * Prefix used for component's main DOM element
			 * @note This is used in methods like 'getDomId'.
			 */
			domPrefix: 'base-component',

			/**
			 * Timeout in ms (milliseconds) for DOM manipulation interval. If less than zero DOM manipulation interval will
			 * be disabled.
			 * @type {number}
			 */
			domManipulationIntervalTimeout: 0,

			/**
			 * Flag that determines if set component will skip updates if both props and state are equal
			 * @type {boolean}
			 */
			optimizedUpdate: false,
			/**
			 * List of prop names that will be ignored during optimization if 'optimizedUpdate' is true
			 * @note Use '*' as an array item to specify all props.
			 * @type {string[]}
			 */
			optimizedUpdateIgnoreProps: [],
			/**
			 * List of state values that will be included in optimization if 'optimizedUpdate' is true
			 * @note Use '*' as an array item to specify all state fields.
			 * @type {string[]}
			 */
			optimizedUpdateIncludeState: ['*'],

			/**
			 * Flag that specifies if component will update when app skin has been changes (for example from light to dark)
			 * @type {boolean}
			 */
			updateOnSkinChange: false,

			/**
			 * List of dialog GUI IDs of the dialogs that should be closed when page component unmounts
			 * @type {string[]}
			 */
			dialogsToCloseOnUnmount: [],
			
			...cloneDeep(options)
		};
		
		// Core methods
		this.asyncComponentDidMount = this.asyncComponentDidMount.bind(this);

		// Component property methods
		this.getId = this.getId.bind(this);
		this.getDomId = this.getDomId.bind(this);
		this.getDomElement = this.getDomElement.bind(this);

		// Component option methods
		this.getOptions = this.getOptions.bind(this);
		this.getOption = this.getOption.bind(this);
		this.setOption = this.setOption.bind(this);

		// Standard methods
		this.setState = this.setState.bind(this);
		this.setSubState = this.setSubState.bind(this);
		this.setStateArrayItem = this.setStateArrayItem.bind(this);
		this.updateStateArrayItem = this.updateStateArrayItem.bind(this);
		this.setStateArrayItemIndex = this.setStateArrayItemIndex.bind(this);
		this.updateStateArrayItemIndex = this.updateStateArrayItemIndex.bind(this);
		this.setStateArrayItemValue = this.setStateArrayItemValue.bind(this);
		this.addStateArrayItem = this.addStateArrayItem.bind(this);
		this.removeStateArrayItem = this.removeStateArrayItem.bind(this);
		this.clonedState = this.clonedState.bind(this);

		// Props methods
		this.getProp = this.getProp.bind(this);

		// Registered event listener methods
		this.registerEventListener = this.registerEventListener.bind(this);
		this.getRegisteredEventListeners = this.getRegisteredEventListeners.bind(this);

		// I18n
		this.handleLocaleChange = this.handleLocaleChange.bind(this);
		this.updateDynamicTranslations = this.updateDynamicTranslations.bind(this);
		this.hasTranslation = this.hasTranslation.bind(this);
		this.hasTranslationPath = this.hasTranslationPath.bind(this);
		this.subTranslate = this.subTranslate.bind(this);
		this.translate = this.translate.bind(this);
		this.t = this.t.bind(this);
		this.tt = this.tt.bind(this);
		this.translatePath = this.translatePath.bind(this);
		this.translateSubPath = this.translateSubPath.bind(this);
		this.getTranslationPath = this.getTranslationPath.bind(this);

		// Render methods
		this.canRender = this.canRender.bind(this);
		
		// 'Click outside' utility
		// @important 'wrapperRef' must be assigned to the HTML element that will represent a wrapper and will be used to 
		// detect if user clicked outside it. This ref should be assigned in the render function of component that extends
		// base component.
		this.wrapperRef = null;
		this.setWrapperRef = this.setWrapperRef.bind(this);
		this._hasClickedOutside = this._hasClickedOutside.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		
		// DOM manipulation interval methods
		this._handleDomManipulationInterval = this._handleDomManipulationInterval.bind(this);
		this.domManipulations = this.domManipulations.bind(this);
		
		// Action and abort controller methods (fetch abort)
		this.handleAbort = this.handleAbort.bind(this);
		this.abortAction = this.abortAction.bind(this);
		this.executeAbortableAction = this.executeAbortableAction.bind(this);
		this.executeAbortableActionMount = this.executeAbortableActionMount.bind(this);
		
		// Loading overlays
		this.showLoading = this.showLoading.bind(this);
		this.hideLoading = this.hideLoading.bind(this);
		
		// Skin mode methods
		this.handleSkinModeChange = this.handleSkinModeChange.bind(this);
	}

	/**
	 * Replacement for default 'componentDidMount' method that will return a promise
	 * @note This method should be used instead of the default 'componentDidMount' when you need to have async calls in 
	 * your 'componentDidMount'. 
	 * @important Please do not forget to decrease the value of this.mountCount once async calls finish.
	 * @return {Promise<number|void>} Promise that will resolve with the updated mount count that will be set in the 
	 * 'componentDidMount' method or undefined for default functionality where 'componentDidMount' will just reset the 
	 * mount count to zero.
	 * @throws {AsyncMountError} Promise can reject with the AsyncMountError in which case another
	 * 'asyncComponentDidMount' will be called if mount count is greater than zero.
	 */
	asyncComponentDidMount() {
		// Implement this method in child component
		
		return Promise.resolve();
	}

	/**
	 * Default 'componentDidMount' method
	 * @note We recommend you use the 'asyncComponentDidMount' method in your components instead of this method.
	 */
	componentDidMount() {
		this.mountCount += 1;

		// Add registered event listeners to the window object
		const registeredEventListeners = this.getRegisteredEventListeners();
		registeredEventListeners.forEach(registeredEventListener => {
			window.addEventListener(registeredEventListener.type, registeredEventListener.listener);
		});

		// Register event listener for 'click outside' utility
		document.addEventListener('mousedown', this._hasClickedOutside);

		// Initialize translate observer that will update (re-render) component every type translation changes.
		this.i18nUnsubscribe = i18nComponentUpdater(this);
		
		// Initialize DOM manipulation interval if 'domManipulationIntervalTimeout' options is greater than zero.
		if (this.getOption('domManipulationIntervalTimeout', 0) > 0) {
			this.domManipulationInterval = setInterval(
				this._handleDomManipulationInterval, this.getOption('domManipulationIntervalTimeout')
			);
		}
		
		// Handle component updates on app skin changes if 'updateOnSkinChange' is true
		if (this.getOption('updateOnSkinChange')) {
			window.addEventListener('onSkinModeChange', this.handleSkinModeChange);
		}

		/**
		 * Function used for async portion of the component mounting 
		 * @private
		 */
		const _asyncMount = () => {
			this.asyncComponentDidMount()
				// Update the mount count
				// @description If async mount was successful, mount count will be reset to zero. This is done because once 
				// async mount was successful, by default, there should be no reason to do it again. However, this can be
				// overridden by resolving with a new mount count number.
				.then(mountCount => { this.mountCount = (isset(mountCount) ? mountCount : 0); })
				// If async mount was not successful (some IO request was aborted for example) decrease the mount count by 
				// one. This will allow another async mount to be executed if there has been multiple mount requests.
				.catch(() => { this.mountCount--; })
				// Handle calling the async mount method again if component mounted more times while the first async mount 
				// was executing
				.finally(() => { if (this.mountCount > 0) _asyncMount(); });
		}
		// Call the async mount method for the first time
		if (this.mountCount === 1) _asyncMount();
	}
	
	shouldComponentUpdate(nextProps, nextState, nextContext) {
		// If 'optimizedUpdate' option is true do not update component if both props and state are the same
		if (this.getOption('optimizedUpdate') === true) {
			// Allow component update if any prop that is not in the optimization ignore list changes
			const optimizedUpdateIgnoreProps = this.getOption('optimizedUpdateIgnoreProps');
			const doNotOptimizeProps = optimizedUpdateIgnoreProps.includes('*');
			if (!doNotOptimizeProps) {
				for (let i in omit(this.props, optimizedUpdateIgnoreProps)) {
					if (this.props.hasOwnProperty(i)) {
						if (!nextProps.hasOwnProperty(i)) return true;
						else if (!isEqual(this.props[i], nextProps[i])) return true;
					}
				}
			}

			// Allow component update if any state value that is in the optimization list changes
			const optimizedUpdateIncludeState = this.getOption('optimizedUpdateIncludeState');
			const doNotOptimizeState = !optimizedUpdateIncludeState.length;
			if (!doNotOptimizeState) {
				const stateToCheck = (
					optimizedUpdateIncludeState.includes('*') ?
						this.state :
						pick(this.state, this.getOption('optimizedUpdateIncludeState'))
				);
				for (let i in stateToCheck) {
					if (this.state.hasOwnProperty(i)) {
						if (!nextState.hasOwnProperty(i)) return true;
						else if (!isEqual(this.state[i], nextState[i])) return true;
					}
				}
			}
			
			return false;
		}
		
		return true;
	}

	componentWillUnmount() {
		// Remove registered event listeners from the window object
		const registeredEventListeners = this.getRegisteredEventListeners();
		registeredEventListeners.forEach(registeredEventListener => {
			window.removeEventListener(registeredEventListener.type, registeredEventListener.listener);
		});

		// Remove registered event listener for 'click outside' utility
		document.removeEventListener('click', this._hasClickedOutside);

		// Unsubscribe from translation observer.
		this.i18nUnsubscribe();
		
		// Clear DOM manipulation interval
		if (this.domManipulationInterval) clearInterval(this.domManipulationInterval);
		
		// Abort all fetch requests
		this.abortControllers.forEach(item => item.abortController.abort());
		this.abortControllers = [];
		
		// Hide all loading overlays
		this.loadingOverlays.forEach(item => hideLoading(item));
		this.loadingOverlays = [];

		// Remove event listener for skin mode change event
		if (this.getOption('updateOnSkinChange')) {
			window.removeEventListener('onSkinModeChange', this.handleSkinModeChange);
		}

		// Close all dialogs specified in 'dialogsToCloseOnUnmount' component option
		getArray(this.getOption('dialogsToCloseOnUnmount')).forEach(dialogGUIID => {
			const closeDialogAction = this.getProp('closeDialogAction');
			if (dialogGUIID && typeof closeDialogAction === 'function') return closeDialogAction(dialogGUIID);
		});
	}


	// Component property methods ---------------------------------------------------------------------------------------
	/**
	 * Get component's ID
	 * @note ID does not have a particular purpose and can be used in any context as a unique component's id that does
	 * not change during component's lifecycle.
	 * 
	 * @return {string}
	 */
	getId() { return this.id; }

	/**
	 * Get component's ID that can be used as DOM element id attribute value
	 * @return {string}
	 */
	getDomId() {
		const idProp = this.getProp('id');
		let domPrefix = this.getOption('domPrefix');
		if (domPrefix) domPrefix = `${domPrefix}-`;
		return (idProp ? idProp : domPrefix + this.getId());
	}

	/**
	 * Get component's main DOM element
	 * @note Component's main DOM element is usually the wrapper <div> or other wrapper DOM element.
	 * 
	 * @note By default, this method will try to find the element based on component's main ID (see 'getId' method).
	 * 
	 * @return {HTMLElement}
	 */
	getDomElement() { return document.getElementById(this.getDomId()); }
	
	
	// Component option methods -----------------------------------------------------------------------------------------
	/**
	 * Get component's options
	 *
	 * @return {object} Component's options object.
	 */
	getOptions() { return this._options; }

	/**
	 * Get component's option value
	 *
	 * @param {string|string[]} path - Option value path string or an array of path segments in the options object.
	 * @param {any} [defaultValue] - Default value to return if option is not defined.
	 * @return {any} Component's option value.
	 */
	getOption(path, defaultValue) {
		return get(this._options, path, defaultValue);
	}

	/**
	 * Set component's option value
	 *
	 * @param {string|string[]} path - Option value path string or an array of path segments in the options object.
	 * @param {any} value - Value to set.
	 */
	setOption(path, value) {set(this._options, path, value)}


	// Standard methods -------------------------------------------------------------------------------------------------
	/**
	 * @inheritDoc
	 * @return {Promise<Object>} Promise that resolves to entire component local state after state is updated.
	 */
	setState(updater, callback = null) {
		return new Promise(resolve => {
			super.setState(updater, () => {
				try {
					if (callback) callback(this.state);
					resolve(this.state);
				} catch (e) {
					resolve(this.state);
				}
			})
		});
	}

	/**
	 * Set state for a sub-state
	 * @description Use this method to set state to a sub-state portion of the main component's state. It works the same
	 * as 'setState' except the 'updater' is relative to the sub-state defined using the 'path' param.
	 * 
	 * @param {string|string[]} [path=''] - Path to the sub-state. If empty or undefined, this method will work exactly 
	 * the same as 'setState'.
	 * @param {Function|Object} updater - Similar to standard 'setState' updater param but if it is used as a function it
	 * receives three arguments (subState, state and props) instead of standard two (state and props).
	 * @param {Function} [callback] - Standard 'setState' callback.
	 * @return {Promise<Object>} Promise that resolves to entire component local state after state is updated.
	 */
	setSubState(path = '', updater, callback) {
		return this.setState((_currentState, _currentProps) => {
			const _currentSubState = cloneDeep((path ? get(_currentState, path) : _currentState));
			const _subStateUpdater = cloneDeep(
				typeof updater === 'object' ?
					updater : 
					updater(_currentSubState, _currentState, _currentProps)
			);
			
			let _updatedState = cloneDeep(_currentState);
			if (path) {
				if (isArray(_currentSubState)) set(_updatedState, path, _subStateUpdater);
				else if (isObject(_currentSubState)) {
					set(_updatedState, path, {..._currentSubState, ..._subStateUpdater});
				}
			} else {
				if (isArray(_updatedState)) _updatedState = _subStateUpdater;
				else if (isObject(_updatedState)) _updatedState = { ..._updatedState, ..._subStateUpdater };
			}
			
			return _updatedState;
		}, callback);
	}

	/**
	 * Set state for an array state field
	 *
	 * @param {string|string[]} [path=''] - Path to the array state field. If empty or undefined, whole state will be 
	 * used (state should be an array in that case).
	 * @param {Object} [predicate=null] - Selector used to find the item in the array (for example: { id: '123' }). If 
	 * undefined, null or item could not be found, new item will be created. If item is created because it could not be 
	 * found, 'predicate' will be included in the newly created item but 'updater' can override it.
	 * @param {Function|Object} updater - Similar to standard 'setState' updater param but if it is used as a function it
	 * receives four arguments (arrayItem, arrayItems, state and props) instead of standard two (state and props). If 
	 * item does not exist 'arrayItem' will be null.
	 * @param {Function} [callback] - Standard 'setState' callback.
	 * @return {Promise<Object>} Promise that resolves to entire component local state after state is updated.
	 */
	setStateArrayItem(path = '', predicate = null, updater, callback) {
		return this.setSubState(path, (_currentItems, _currentState, _currentProps) => {
			const _itemIndex = (predicate ? findIndex(_currentItems, predicate) : -1);
			
			// Update existing item
			let _updatedItems = (isArray(_currentItems) ? cloneDeep(_currentItems) : []);
			if (_itemIndex !== -1) {
				const _currentItem = _currentItems[_itemIndex];
				const _itemUpdater = (
					typeof updater === 'object' ?
						updater : 
						updater(_currentItem, _currentItems, _currentState, _currentProps)
				);
				set(_updatedItems, _itemIndex, {...cloneDeep(_currentItem), ...cloneDeep(_itemUpdater)})
			}
			// Create item because it does ont exist
			else {
				let _itemUpdater = (
					typeof updater === 'object' ?
						updater :
						updater(null, _currentItems, _currentState, _currentProps)
				);

				// Add predicate to the item if predicate was defined
				// @note Item updater will always override the predicate if it has the filed used in the predicate.
				if (!isEmpty(predicate)) _itemUpdater = {...cloneDeep(predicate), ..._itemUpdater};
				_updatedItems.push(cloneDeep(_itemUpdater));
			}
			
			return _updatedItems;
		}, callback);
	}

	/**
	 * Update an item in an array state field
	 * @note This method should be use only for existing items.
	 *
	 * @param {string|string[]} [path=''] - Path to the array state field. If empty or undefined, whole state will be
	 * used (state should be an array in that case).
	 * @param {Object} predicate - Selector used to find the item in the array (for example: { id: '123' }).
	 * @param {Function|Object} updater - Similar to standard 'setState' updater param but if it is used as a function it
	 * receives four arguments (arrayItem, arrayItems, state and props) instead of standard two (state and props). If
	 * item does not exist 'arrayItem' will be null.
	 * @param {Function} [callback] - Standard 'setState' callback.
	 * @return {Promise<Object>} Promise that resolves to entire component local state after state is updated.
	 */
	updateStateArrayItem(path = '', predicate, updater, callback) {
		return this.setSubState(path, (_currentItems, _currentState, _currentProps) => {
			const _itemIndex = (predicate ? findIndex(_currentItems, predicate) : -1);

			// Update existing item
			let _updatedItems = (isArray(_currentItems) ? cloneDeep(_currentItems) : []);
			if (_itemIndex !== -1) {
				const _currentItem = cloneDeep(_currentItems[_itemIndex]);
				const _itemUpdater = cloneDeep(
					typeof updater === 'object' ?
						updater :
						updater(_currentItem, _currentItems, _currentState, _currentProps)
				);
				set(_updatedItems, _itemIndex, {..._currentItem, ..._itemUpdater});
			}

			return _updatedItems;
		}, callback);
	}

	/**
	 * Set existing item's index in the array state field or insert a new one if the item does not exist
	 *
	 * @param {string|string[]} path - Path to the array state field.
	 * @param {any} item - Item to insert or update its index.
	 * @param {number} index - New index of the array state item.
	 * @param {Function} [callback] - Standard 'setState' callback.
	 * @return {Promise<Object>} Promise that resolves to entire component local state after state is updated.
	 */
	setStateArrayItemIndex(path, item, index, callback) {
		if (!path) {
			console.warn('BaseComponent: Missing path while trying insert state array item!');
			return Promise.resolve(this.state);
		}
		if (!item) {
			console.warn('BaseComponent: Missing item while trying insert state array item!');
			return Promise.resolve(this.state);
		}

		return this.setSubState(path, (_currentItems, _currentState, _currentProps) => {
			const _itemIndex = findIndex(_currentItems, item);

			// Update item index if it already exists
			if (_itemIndex !== -1) return move(_currentItems, _itemIndex, index);

			// Update item index if it already exists
			return [
				..._currentItems.slice(0, index),
				item,
				..._currentItems.slice(index)
			];
		}, callback);
	}

	/**
	 * Update item's index in the array state field
	 * @note This method should be use only for existing items.
	 *
	 * @param {string|string[]} path - Path to the array state field.
	 * @param {Object} predicate - Selector used to find the item in the array (for example: { id: '123' }).
	 * @param {number} index - New index of the array state item.
	 * @param {Function} [callback] - Standard 'setState' callback.
	 * @return {Promise<Object>} Promise that resolves to entire component local state after state is updated.
	 */
	updateStateArrayItemIndex(path, predicate = null, index, callback) {
		if (!path) {
			console.warn('BaseComponent: Missing path while trying update state array item index!');
			return Promise.resolve(this.state);
		}

		return this.setSubState(path, (_currentItems, _currentState, _currentProps) => {
			const _itemIndex = (predicate ? findIndex(_currentItems, predicate) : -1);
			if (_itemIndex !== -1) return move(_currentItems, _itemIndex, index);
			console.warn(
				'BaseComponent: State array item does not exists while trying update index!',
				{predicate, index}
			);
		}, callback);
	}

	/**
	 * Update an item's value in an array state field
	 * @note This method should be use only for existing items.
	 *
	 * @param {string|string[]} [path=''] - Path to the array state field. If empty or undefined, whole state will be
	 * used (state should be an array in that case).
	 * @param {Object} predicate - Selector used to find the item in the array (for example: { id: '123' }).
	 * @param {string} field - Field to update.
	 * @param {any} value - Any value.
	 * @param {Function} [callback] - Standard 'setState' callback.
	 * @return {Promise<Object>} Promise that resolves to entire component local state after state is updated.
	 */
	setStateArrayItemValue(path = '', predicate, field, value, callback) {
		return this.setSubState(path, (_currentItems, _currentState, _currentProps) => {
			const _itemIndex = (predicate ? findIndex(_currentItems, predicate) : -1);

			// Update existing item
			let _updatedItems = (isArray(_currentItems) ? cloneDeep(_currentItems) : []);
			if (_itemIndex !== -1) set(_updatedItems, [_itemIndex, field], value);
			
			return _updatedItems;
		}, callback);
	} 
	
	/**
	 * Add an item to an array state field
	 *
	 * @param {string|string[]} [path=''] - Path to the array state field. If empty or undefined, whole state will be
	 * used (state should be an array in that case).
	 * @param {Function|Object} item - Similar to standard 'setState' updater param but if it is used as a function it
	 * receives four arguments (arrayItem, arrayItems, state and props) instead of standard two (state and props) where 
	 * 'arrayItem' will always be null.
	 * @param {Function} [callback] - Standard 'setState' callback.
	 * @return {Promise<Object>} Promise that resolves to entire component local state after state is updated.
	 */
	addStateArrayItem(path = '', item, callback) { return this.setStateArrayItem(path, null, item, callback); }
	
	/**
	 * Remove an item from an array state field
	 *
	 * @param {string|string[]} [path=''] - Path to the array state field. If empty or undefined, whole state will be
	 * used (state should be an array in that case).
	 * @param {Object} predicate - Selector used to find the item in the array (for example: { id: '123' }).
	 * @param {Function} [callback] - Standard 'setState' callback.
	 * @return {Promise<Object>} Promise that resolves to entire component local state after state is updated.
	 */
	removeStateArrayItem(path = '', predicate, callback) {
		return this.setSubState(path, _currentItems => reject(cloneDeep(_currentItems), predicate), callback);
	}

	/**
	 * Returns a cloned local state
	 * @note This method is useful for using in promise 'then' to return a cloned local state.
	 * @return {any}
	 */
	clonedState() { return cloneDeep(this.state); }

	
	// Props methods ----------------------------------------------------------------------------------------------------
	/**
	 * Get the value of component's prop
	 *
	 * @param {string|string[]} path - Path of the prop inside the props object.
	 * @param {any} [defaultValue] - Default value if prop was not found or is undefined.
	 * @return {any} Value of the component's prop field.
	 */
	getProp(path, defaultValue) { return get(this.props, path, defaultValue); }
	

	// Registered event listener methods --------------------------------------------------------------------------------
	/**
	 * Register an event listener inside the component
	 * @note Registered event listeners will be added after component mounts and removed just before component unmount.
	 *
	 * @param {string} type - Standard event listener type (keyof WindowEventMap).
	 * @param {EventListener|Function} listener - Function called when event is triggered.
	 */
	registerEventListener(type, listener) { this.registeredEventListeners.push({id: v4(), type, listener}); }

	/**
	 * Get the list of component's registered event listeners
	 *
	 * @return {ComponentEventListener[]} List of component's registered event listeners.
	 */
	getRegisteredEventListeners() {
		return (Array.isArray(this.registeredEventListeners) ? this.registeredEventListeners : []);
	}


	// I18n -------------------------------------------------------------------------------------------------------------
	/**
	 * Handle locale change
	 * @note This method will be called every time locale changes.
	 * 
	 * @param {object} translation - Newly loaded translation object.
	 * @return {Promise<Object>} - Promise that resolves to a newly loaded translation object.
	 */
	handleLocaleChange(translation) {
		// Force-update the component so that translation methods will work properly
		return new Promise(resolve => { this.forceUpdate(() => { resolve(translation); }) })
			// Update dynamic translations
			.then(translation => this.updateDynamicTranslations(translation));
	}

	/**
	 * Update dynamic translations
	 * @description Dynamic translations are translations that are not called in component's 'render' or 
	 * 'componentDidUpdate' methods. Since automatic translation works by updating the component when locale changes,
	 * only values translated in 'render' and 'componentDidUpdate' methods will be automatically translated. All other
	 * translations need to be handled manually using this method.
	 * @note This method will be called after locale change has been handled.
	 * 
	 * @param {object} translation - Currently loaded translation object.
	 */
	updateDynamicTranslations(translation) {
		// Implement by child class
	}

	/**
	 * Check if translation string exists
	 *
	 * @param {string} string - String to translate.
	 * @param {string|string[]|function}[path=''] - Translation path.
	 * @return {boolean} True if translation string exists for the given translation path.
	 */
	hasTranslation(string, path) {
		let translationPath;
		if (isFunction(path)) translationPath = `${path(this.getOption('translationPath', ''))}`;
		else if (path) translationPath = path;
		else translationPath = this.getOption('translationPath', '');
		
		return I18n.hasTranslation(string, translationPath);
	}
	
	/**
	 * Check if translation path exists
	 *
	 * @param {string|string[]}path - Translation path.
	 * @return {boolean} True if translation path exists.
	 */
	hasTranslationPath(path) { return I18n.hasTranslationPath(path); }
	
	/**
	 * Component's main translate method
	 *
	 * @param {string} string - String to translate.
	 * @param {string|string[]|function} [path] - Translation path.
	 * @param {'c'|'u'|'l'|''} [transform=''] - Result string transformation where:
	 * 	- 'c' is Capitalize
	 * 	- 'u' is UPPERCASE
	 * 	- 'l' is lowercase
	 * @param {Object} [variables={}] - Dynamic variables used in translation (format: %{variable}).	
	 * @return {string} Translated string or original string if translation could not be found.
	 */
	translate(string, path, transform = '', variables = {}) {
		let translationPath;
		if (isFunction(path)) translationPath = `${path(this.getOption('translationPath', ''))}`;
		else if (path) translationPath = path;
		else translationPath = this.getOption('translationPath', '');
		
		let result = I18n.translate(string, translationPath, variables);
		
		switch (transform) {
			case 'c': return result.charAt(0).toUpperCase() + result.slice(1)
			case 'u': return result.toUpperCase();
			case 'l': return result.toLowerCase();
			default: return result;
		}
	}

	/**
	 * Component's translation method that uses path relative to components 'translationPath' option
	 *
	 * @param {string} string - String to translate.
	 * @param {string|string[]|function} [subPath] - Translation path relative to 'translationPath' option.
	 * @param {'c'|'u'|'l'|''} [transform=''] - Result string transformation where:
	 * 	- 'c' is Capitalize
	 * 	- 'u' is UPPERCASE
	 * 	- 'l' is lowercase
	 * @param {Object} [variables={}] - Dynamic variables used in translation (format: %{variable}).
	 * @return {string} Translated string or original string if translation could not be found.
	 */
	subTranslate(string, subPath, transform = '', variables = {}) {
		let translationPath;
		let translationBasePath = this.getOption('translationPath', '');
		if (translationBasePath) translationBasePath = `${rtrimChar(translationBasePath, '.')}.`;
		
		if (subPath) translationPath = translationBasePath + subPath;
		else translationPath = translationBasePath;

		let result = I18n.translate(string, translationPath, variables);
		
		switch (transform) {
			case 'c': return result.charAt(0).toUpperCase() + result.slice(1)
			case 'u': return result.toUpperCase();
			case 'l': return result.toLowerCase();
			default: return result;
		}
	}

	/**
	 * Alias method of 'translate'
	 * @see translate
	 */
	t = this.translate;

	/**
	 * Alias method of 'subTranslate'
	 * @see subTranslate
	 */
	tt = this.subTranslate;

	/**
	 * Translate raw path
	 * @description This method will use the specified translation path (in translation file) to find the appropriate
	 * translation. 
	 *
	 * @param {string|string[]} path - Translation path.
	 * @param {'c'|'u'|'l'|''} [transform=''] - Result string transformation where:
	 * 	- 'c' is Capitalize
	 * 	- 'u' is UPPERCASE
	 * 	- 'l' is lowercase
	 * @param {Object} [variables={}] - Dynamic variables used in translation (format: %{variable}).	
	 * @return {string} Translated string or original string if translation could not be found.
	 */
	translatePath(path, transform = '', variables) {
		let result = I18n.translatePath(path, variables);

		switch (transform) {
			case 'c': return result.charAt(0).toUpperCase() + result.slice(1)
			case 'u': return result.toUpperCase();
			case 'l': return result.toLowerCase();
			default: return result;
		}
	}

	/**
	 * Translate path relative to components 'translationPath' option
	 *
	 * @param {string|string[]|function} [subPath] - Translation path relative to 'translationPath' option.
	 * @param {'c'|'u'|'l'|''} [transform=''] - Result string transformation where:
	 * 	- 'c' is Capitalize
	 * 	- 'u' is UPPERCASE
	 * 	- 'l' is lowercase
	 * @param {Object} [variables={}] - Dynamic variables used in translation (format: %{variable}).
	 * @return {string} Translated string or original string if translation could not be found.
	 */
	translateSubPath(subPath, transform = '', variables = {}) {
		const translationPath = this.getTranslationPath('subPath');
		let result = I18n.translatePath(translationPath, variables);
		
		switch (transform) {
			case 'c': return result.charAt(0).toUpperCase() + result.slice(1)
			case 'u': return result.toUpperCase();
			case 'l': return result.toLowerCase();
			default: return result;
		}
	}

	/**
	 * Get translation path base on the components 'translationPath' option
	 * @note Component's base translation path is defined in the 'translationPath' option.
	 * 
	 * @param {string} [subPath=''] Translation path relative to 'translationPath' option.
	 * @return {string}
	 */
	getTranslationPath(subPath = '') {
		let result;
		let translationBasePath = this.getOption('translationPath', '');
		if (translationBasePath) translationBasePath = `${rtrimChar(translationBasePath, '.')}.`;

		if (subPath) result = translationBasePath + subPath;
		else result = translationBasePath;
		
		return result;
	}
	
	
	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Method that should return true if component can be rendered or false otherwise
	 * @return {boolean} True if component can be rendered or false otherwise.
	 */
	canRender() { return true; }


	// 'Click outside' utility ------------------------------------------------------------------------------------------
	/**
	 * Set the wrapper ref
	 * @note Wrapper ref is used of 'click outside' functionality.
	 */
	setWrapperRef(node) { this.wrapperRef = node; }
	
	/**
	 * Method called by document click event handler that checks if user has clicked outside the component
	 * 
	 * @param {Event} event - Click event.
	 * @private
	 */
	_hasClickedOutside(event) {
		if (this.wrapperRef && event.target !== this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			this.handleClickOutside(event);
		}
	}

	/**
	 * Method called if user has clicked outside the component
	 * @note This is an abstract method and should not be called directory. Use it in classes that extend this class.
	 * @param {Event} event - Click event.
	 */
	handleClickOutside(event) {
		// Implement this method in the component that extends this abstract component
	}


	// DOM manipulation interval methods --------------------------------------------------------------------------------
	/**
	 * Method that calls the public 'domManipulations' method used for DOM manipulation scripts
	 * @note If component does not have a DOM element specified (see 'getDomElement' method), null will be sent to 
	 * 'domManipulations' method instead of component's DOM element.
	 * @private
	 */
	_handleDomManipulationInterval() {
		try {
			const componentElem = this.getDomElement();
			if (componentElem) this.domManipulations(componentElem);
			else this.domManipulations(null);
		} catch (e) {
			// Do nothing
			// @note Try / catch is added to avoid console logging errors for DOM manipulations because this method can be 
			// called every fet milliseconds (see 'domManipulationIntervalTimeout' option).
		}
	}

	/**
	 * Method called on each DOM manipulation interval
	 * 
	 * @note This is a placeholder method that should be implemented in child classes in order to use the functionality.
	 *
	 * @param {HTMLElement|Element|null} element - Component's main DOM element or null if component's main DOM element 
	 * is not set.
	 */
	domManipulations(element) {
		// Implement in child class
	}


	// Abort controller methods (fetch abort) ---------------------------------------------------------------------------
	/**
	 * Abort callback handler
	 * @description This function, if 'executeAbortableAction' method is used, automatically adds the abort controller of
	 * the executed action to the list of abort controllers managed by this component. By default, all the actions that 
	 * have abort controllers in this list will be aborted when component unmounts (componentWillUnmount).
	 * @note You probably don't need to call this method directly.
	 * @see https://developer.mozilla.org/en-US/docs/Web/API/AbortController
	 * 
	 * @param {AbortController} abortController - Fetch abort controller.
	 * @param {string} id - ID of the fetch request that can be used to abort it at any time (see 'abortAction' method).
	 */
	handleAbort(abortController, id) { this.abortControllers.push({id, abortController}); }
	
	/**
	 * Abort a single action at any time
	 * @param {string} id - ID of the action to abort.
	 */
	abortAction(id) {
		// Try to find the abort controller by the action ID
		const abortControllerItem = find(this.abortControllers, {id});
		
		if (abortControllerItem) {
			// Abort the action
			abortControllerItem.abortController.abort();
			// Remove the action from the abort list because it has already been aborted
			this.abortControllers = reject(this.abortControllers, {id});
		}
	}
	

	/**
	 * Executes an abortable action
	 * @description This method will execute any action by adding an abort callback function as it's first argument. 
	 * Abort callback function (handleAbort) will add the action's abort controller to the list of abort controllers 
	 * managed by this component. By default, all the actions that have abort controllers in this list will be aborted 
	 * when component unmounts (componentWillUnmount). When calling an action while the same action (action with the same
	 * ID) is running, currently running one will be aborted before the new one starts executing.
	 * @note It is recommended to use this method when calling actions and creating actions so that the first argument is
	 * always the abort callback function.
	 *
	 * @param {function|{id: string, action: function}} action - Action function to execute or an object containing the 
	 * action ID (that can be used to abort the action at any time using the 'abortAction' method) and action function to
	 * execute.
	 * @param {any} params - Action params.
	 * @return {Promise<any>} Returns an action promise or an empty resolved promise if action is not a function.
	 */
	executeAbortableAction(action, ...params) {
		let actionId = v4();
		let actionFunction = action;
		
		// If 'action' argument is an object get both action id and function from it 
		if (isPlainObject(action)) {
			actionId = get(action, 'id');
			actionFunction = get(action, 'action');
		}
		
		if (isFunction(actionFunction)) {
			// Abort action if it already exists (same action ID) before calling executing it
			this.abortAction(actionId);
			
			try {
				return actionFunction(abortController => this.handleAbort(abortController, actionId), ...params)
					// Remove abort controller form the list because action was executed
					.then(data => {
						this.abortControllers = reject(this.abortControllers, {actionId});
						return data;
					})
					.catch(error => {
						this.abortControllers = reject(this.abortControllers, {actionId});
						throw error;
					});
			} catch (e) {
				this.abortControllers = reject(this.abortControllers, {actionId});
				console.warn(`Only actions that return promises should be executed using 'executeAbortableAction' method.`);
			}
		} else {
			console.warn(`Trying to execute non-existing abortable action.`);
		}
		return Promise.resolve();
	}

	/**
	 * Similar to 'executeAbortableAction' method except it will throw a AsyncMountError if action returns undefined
	 * @note This method is meant to be used in the async part of component did mount ('asyncComponentDidMount' method).
	 *
	 * @param {function|{id: string, action: function}} action - Action function to execute or an object containing the
	 * action ID (that can be used to abort the action at any time using the 'abortAction' method) and action function to
	 * execute.
	 * @param {any} params - Action params.
	 * @return {Promise<any>} Returns an action promise or an empty resolved promise if action is not a function.
	 * @throws {AsyncMountError} Promise can reject with the AsyncMountError in which case another
	 * 'asyncComponentDidMount' will be called if mount count is greater than zero. 
	 */
	executeAbortableActionMount(action, ...params) {
		return this.executeAbortableAction(action, ...params)
			.then(res => { 
				if (!isset(res)) throw new AsyncMountError();
				else return res;
			});
	}

	
	// Loading overlays -------------------------------------------------------------------------------------------------
	/**
	 * Add a loading overlay to all HTML elements found by the selector
	 *
	 * @param {string} [selector] - CSS selector for target elements. If not specified or empty components DOM id will be 
	 * used.
	 * @param {boolean} [transparent] - If true, loading background will be transparent. Transparency should be defined 
	 * by the skin in '/skin/css/common.css' file.
	 * @param {boolean} [blur] - If true, overlay background will be blurred. Blur should be defined by the skin in
	 * '/skin/css/common.css' file.
	 * @param {string|number} [size] - Loading spinner size in px or as size string with units.
	 * @param {string|number} [weight] - Loading spinner glyph weight in px or as size string with units.
	 * @param {string} [className] - Additional overlay element class name.
	 * @param {boolean} [disableTabKey] - Flag that specifies if keyboard Tab key will be disabled while the overlay is 
	 * visible.
	 * @return {string} Overlay GUI ID.
	 */
	showLoading(selector, transparent, blur, size, weight, className, disableTabKey) {
		const loading = showLoading(
			selector ? selector : `#${this.getDomId()}`, transparent, blur, size, weight, className, disableTabKey
		);
		this.loadingOverlays.push(loading);
		return loading;
	}

	/**
	 * Remove loading overlay from all HTML elements found by the GUIID
	 * @param {string} GUIID - Overlay GUI ID.
	 */
	hideLoading(GUIID) {
		hideLoading(GUIID);
		this.loadingOverlays = reject(this.loadingOverlays, item => item === GUIID);
	}

	
	// Skin mode methods ------------------------------------------------------------------------------------------------
	/**
	 * Method that will be called on every skin mode change
	 * @param {CustomEvent} e - Custom 'onSkinModeChange' event with 'newSkinMode' and 'oldSkinMode' in the 'detail'.
	 * @return {Promise<any>}
	 */
	handleSkinModeChange(e) {
		// Force update component when skin mode changes
		return new Promise(resolve => this.forceUpdate(() => resolve()));
	}
}

// Helpers
/**
 * Executes a component callback function if it exists
 *
 * @param {Function|null|undefined} callback - A component callback function to execute if it exists.
 * @param {any} params - Component callback function parameters.
 * @return {any|null} If callback function was called result from that function will be returned. Otherwise, null will 
 * be returned.
 */
export const executeComponentCallback = (callback, ...params) => {
	if(typeof callback === 'function') return callback(...params);
	return null;
};

/**
 * Executes a component callback function if it exists
 *
 * @param {Function|null|undefined} callback - A component callback function to execute if it exists.
 * @param {any} params - Component callback function parameters.
 * @return {Promise<any>}
 */
export const executeComponentCallbackPromise = (callback, ...params) => {
	return Promise.resolve((typeof callback === 'function' ? callback(...params) : null));
};

// Type definitions
/**
 * @typedef {Object} BaseComponentOptions
 * @property {string} [translationPath] - Path inside the translation JSON file where component translations are 
 * defined.
 * @property {string} [domPrefix='base-component'] - Prefix used for component's main DOM element. This is used in
 * methods like 'getDomId'.
 * @property {number} [domManipulationIntervalTimeout=0] - Timeout in ms (milliseconds) for DOM manipulation interval.
 * If less than zero DOM manipulation interval will be disabled.
 * @property {boolean} [optimizedUpdate=false] - Flag that determines if set component will skip updates if both props 
 * and state are equal.
 * @property {string[]} [optimizedUpdateIgnoreProps] - List of prop names that will be ignored during optimization if
 * 'optimizedUpdate' is true. Use '*' array item for all props.
 * @property {string[]} [optimizedUpdateIncludeState] - List of state values that will be included in optimization if
 * 'optimizedUpdate' is true. Use '*' array item for all state fields.
 * @property {boolean} [updateOnSkinChange=false] - Flag that specifies if component will update when app skin has been 
 * changes (for example from light to dark).
 * @property {string[]} [dialogsToCloseOnUnmount=[]] - List of dialog GUI IDs of the dialogs that should be closed when
 * page component unmounts.
 */

/**
 * @typedef {{id: string, type: string, listener: EventListener }} ComponentEventListener
 * 	* id - Unique component's event listener identifier.
 * 	* type - Standard event listener type (keyof WindowEventMap).
 * 	* listener - Function called when event is triggered.
 */