import {
	ioJsonDeleteItemAction,
	ioJsonFetchAction,
	ioJsonFetchItemAction,
	ioJsonSaveAction
} from "Core/store/actions/io";
import {hideLoading, hideLoadingFunction, showLoading} from "Core/helpers/loading";
import {getArray, isset} from "Core/helpers/data";
import {get} from "lodash";
import {actionCreators} from "Core/store/reducers";
import * as listItemDataMap from "./dataMap/listItem";
import * as itemFilterDataMap from "./dataMap/filter";
import * as itemDataMap from "./dataMap/item";
import {reducerStoreKey} from "./reducer";
import {isSuccessful} from "Core/helpers/io";

/**
 * Reset physicians list Redux state to initial state
 * @return {(function(*))|*}
 */
export const resetPhysiciansListAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].reset());
};

/**
 * Fetch physicians list
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [filter] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const fetchPhysiciansListAction = (
	abortCallback, filter = null, pageNo = 1, perPage, sortBy, sortDir
) => dispatch => {
	return ioJsonFetchAction(
		abortCallback,
		'defaultAuthorizedApi',
		'member/physician/page-search',
		'',
		filter,
		null,
		pageNo,
		perPage,
		sortBy ? sortBy : null,
		sortDir
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => {
			if (isSuccessful(responseData)) {
				return ({
					...responseData,
					filter: itemFilterDataMap.input(get(responseData, 'filter')),
					data: getArray(responseData, 'data').map(i => listItemDataMap.input(i))
				});
			}
			return undefined;
		});
};

/**
 * Load physicians list into Redux store
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {Object} [filter] - Fetch filter.
 * @param {number} [pageNo] - Number of the page to load (pagination). Starts from 1.
 * @param {number} [perPage] - Number of items per page to load (pagination). Used system default if not specified.
 * @param {string} [sortBy] - Sort field name. Sort fields are defined by the API.
 * @param {SortOrder} [sortDir] - Sort direction.
 * @param {boolean} [loadingOverlay=true] - Flag that specifies if loading overlay will be rendered.
 * @return {function(*=): Promise<IoJsonFetchResponseObject>}
 */
export const loadPhysiciansListAction = (
	abortCallback, filter = null, pageNo = 1, perPage, sortBy, sortDir, loadingOverlay = true
) => dispatch => {
	const loading = (loadingOverlay === true ? showLoading("#main-page-table") : null);
	return fetchPhysiciansListAction(abortCallback, filter, pageNo, perPage, sortBy, sortDir)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators[reducerStoreKey].setPhysiciansListData(responseData));
			if (loading !== null) hideLoading(loading);
			return responseData;
		});
};

/**
 * Set physicians list filter in Redux store
 * 
 * @param {Object} filter - Filter to set.
 * @return {(function(*): void)|*}
 */
export const setPhysiciansFilterAction = filter => dispatch => {
	dispatch(actionCreators[reducerStoreKey].setPhysiciansListFilter(filter));
};

/**
 * Clear physicians list form Redux store
 *
 * @return {(function(*): void)|*}
 */
export const clearPhysiciansListAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].clearPhysiciansListData());
}

/**
 * Fetch physicians item
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string|number} id - DB ID of the physicians item to fetch.
 * @return {function(*=): Promise<PhysiciansItemDataObject>}
 */
export const fetchPhysiciansItemAction = (abortCallback, id) => dispatch => {
	return ioJsonFetchItemAction(
		abortCallback,
		'defaultAuthorizedApi',
		'member/physician/fetch-by-id',
		id,
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => itemDataMap.input(responseData?.data));
};

/**
 * Load physicians item into Redux store
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - DB ID of the physicians item to load.
 * @return {function(*=): Promise<PhysiciansItemDataObject>}
 */
export const loadPhysiciansItemAction = (abortCallback, id) => dispatch => {
	const loading = showLoading('#item-popup');
	return fetchPhysiciansItemAction(abortCallback, id)(dispatch)
		// Load data into Redux store
		.then(responseData => {
			if (responseData) dispatch(actionCreators[reducerStoreKey].setPhysiciansItem(responseData));
			hideLoading(loading);
			return responseData;
		});
}

/**
 * Clear physicians item from Redux store
 * @return {(function(*=): void)|*}
 */
export const clearPhysiciansItemAction = () => dispatch => {
	dispatch(actionCreators[reducerStoreKey].clearPhysiciansItem());
};

/**
 * Create physicians item
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {PhysiciansItemDataObject} item - Physicians item to save.
 * @return {function(*=): Promise<PhysiciansItemDataObject>} Promise that will resolve with the created item 
 * received from IO or undefined if creation failed.
 */
export const createPhysiciansItemAction = (abortCallback, item) => dispatch => {
	const loading = showLoading('#item-popup');
	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'member/physician/create',
		{
			id: '',
			data: itemDataMap.output(item)
		},
		undefined,
		true,
		hideLoadingFunction(loading)
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => isset(responseData) ? itemDataMap.input(responseData.data) : undefined);
};

/**
 * Update physicians item
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - DB ID of the physicians item to update.
 * @param {PhysiciansItemDataObject} item - Physicians item to save.
 * @param {boolean} [loadIntoRedux=true] - Flag that specifies if updated data will be loaded into Redux store.
 * @return {function(*=): Promise<PhysiciansItemDataObject>} Promise that will resolve with the updated item 
 * received from IO or undefined if updating failed.
 */
export const updatePhysiciansItemAction = (abortCallback, id, item, loadIntoRedux = true) => dispatch => {
	const loading = showLoading('#item-popup');
	
	return ioJsonSaveAction(
		// @note abortCallback is set to undefined because save actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'member/physician/update-by-id',
		{
			id,
			data: itemDataMap.output(item)
		},
		undefined,
		true,
		hideLoadingFunction(loading)
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => isset(responseData) ? itemDataMap.input(responseData.data) : undefined)
		// Set current item to Redux store so that changes will be detected once item has been updated
		.then(updatedItem => {
			if (loadIntoRedux && isset(updatedItem)) dispatch(actionCreators[reducerStoreKey].setPhysiciansItem(item));
			return updatedItem;
		})
		// Save the updated item to Redux store received from response (since 'requestSavedData' is set to true)
		.then(updItem => { 
			if (loadIntoRedux && isset(updItem)) dispatch(actionCreators[reducerStoreKey].setPhysiciansItem(updItem));
			return updItem; 
		});
};

/**
 * Delete physicians item action
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string|number} id - ID of the physicians item that will be deleted.
 * @return {function(*): Promise<IoJsonFetchResponseObject>}
 */
export const deletePhysiciansItemAction = (abortCallback, id) => dispatch => {
	const loading = showLoading('#item-delete-dialog .dialog-content-component .buttons');
	return ioJsonDeleteItemAction(
		// @note abortCallback is set to undefined because delete actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'member/physician/delete-by-ids',
		id,
		{},
		hideLoadingFunction(loading)
	)(dispatch);
};