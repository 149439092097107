import {format, isValid, parse, toDate} from "date-fns";
import {lPadString} from "./string";
import {getString} from "./data";
import {
	getAppDatePluginLocale, 
	getAppLocalDatetimeSeparator,
	getAppLocaleDateFormat,
	getAppLocaleTimeFormat
} from "./locale";
import {LOCALE_DATE_FORMAT_NAME, LOCALE_TIME_FORMAT_NAME} from "../const/locale";
import {STANDARD_DATE_TIME_FORMAT} from "../const/datetime";

/**
 * Get an instance of Date object from specified value
 *
 * @param {Date|number|string} date - Value to try to convert into Date object.
 * @param {string} [format] - If 'date' is string this will be used as a format to parse it into the Date object.
 * @param {any} [locale] - Locale to use.
 * @return {Date} Date object or null if date could not be parsed.
 */
export const getDate = (date, format, locale) => {
	try {
		const result = (typeof date === 'string' ? parse(date, format, new Date(), {locale}) : toDate(date));
		return (isValid(result) ? result : null);
	} catch (e) {
		return null;
	}
};

/**
 * Get a date string from a Date object
 * 
 * @param {Date} date - Date object to convert to a string.
 * @param {string} outputFormat - Format to use for the result string.
 * @param {any} [locale] - Locale to use.
 * @return {string}
 */
export const getDateString = (date, outputFormat, locale) => {
	try {
		const result = format(date, outputFormat, {locale});
		return result ? result : '';
	} catch (e) {
		return '';
	}
};

/**
 * Compare two time strings
 * 
 * @param {string} timeStr1 - First time string to compare.
 * @param {string} timeStr2 - Second time string to compare.
 * @return {number|boolean} 
 * 	* -1 if first time string is before second time string
 * 	* 0 if time stings are equal
 * 	* 1 if first time string is after second time string
 * 	* false if time strings could not be compared (invalid input arguments 'timeStr1' or 'timeStr2')
 */
export const timeStrCompare = (timeStr1, timeStr2) => {
	try {
		const sectionCount = 3;
		
		const timeString1 = getTimeString(timeStr1, sectionCount, false, false, '00:00:00');
		const timeString2 = getTimeString(timeStr2, sectionCount, false, false, '00:00:00');

		const timeString1Sections = timeString1.split(':').map(i => parseInt(i));
		const timeString2Sections = timeString2.split(':').map(i => parseInt(i));
		
		const compareSection = sectionIndex => {
			if (sectionCount === sectionCount - 1) return 0;
			if (timeString1Sections[sectionIndex] < timeString2Sections[sectionIndex]) return -1;
			else if (timeString1Sections[sectionIndex] > timeString2Sections[sectionIndex]) return 1;
			else return compareSection(sectionIndex + 1);
		}
		
		return compareSection(0);
	} catch (e) {
		return false;
	}
}

/**
 * Get time string (ex: '15:45:00')
 * @note This function supports string and Date object inputs. Strings will be validated and Date objects will be used
 * to extract only the time portion.
 *
 * @param {any} value - Any value. Supported values are string and Date object. Other types will return 'defaultValue'.
 * @param {number} [outputSize=3] - Number of parts output time string should have (parts are hours, minutes and
 * seconds). Max. value is 3.
 * @param {boolean} [hoursFirst=true] - Flag that determines if first part of the time string is in hours. If
 * 'outputSize' is 3 this will be irrelevant (hour will be the first part).
 * @param {boolean} [dayLimit=false] - Flag that determines if hours should be limited to one day (max. 23) If
 * 'hoursFirst' prop is false this will be ignored.
 * @param {string|function} [defaultValue=''] - Value returned if 'path' could not be found or is undefined.
 * @return {string} Time string (ex: '15:45:00') or an empty string if time string could not be created.
 */
export const getTimeString = (value, outputSize = 3, hoursFirst = true, dayLimit = false, defaultValue = '') => {
	const def = (typeof defaultValue === 'function' ? defaultValue() : defaultValue);

	// Try to extract time string from a string value
	if (typeof value === 'string') {
		// Initial rough value check
		if (value && /^(\d+|\d+:[0-5]?[0-9]|\d+:[0-5]?[0-9]:[0-5]?[0-9])$/.test(value)) {
			const valueSections = value.split(':');
			const value1 = valueSections[0];

			// Singe section output
			if (outputSize === 1) {
				// If result should be in hours
				if (hoursFirst) {
					// If result should be in hours limited to one day (max. 23)
					if (dayLimit) return (/^([0-1]?[0-9]|2[0-3])$/.test(value1) ? value1 : def);
					// If result should be in hours (no limits)
					else return (/^\d+$/.test(value1) ? value1 : def);
				}
				// If result should be in minutes or seconds
				else return (/^[0-5]?[0-9]$/.test(value1) ? value1 : def);
			}

			// Two section output
			else if (outputSize === 2) {
				const value2 = getString(valueSections, '[1]', '00');
				const combined2 =
					`${lPadString(value1, 2, '0')}:${lPadString(value2, 2, '0')}`;

				// If result should be in hours and minutes
				if (hoursFirst) {
					// If result hours should be limited to one day (max. 23)
					if (dayLimit) return (/^([0-1]?[0-9]|2[0-3]):[0-5]?[0-9]$/.test(combined2) ? combined2 : def);
					// If result should be in hours (no limits)
					else return (/^\d+:[0-5]?[0-9]$/.test(combined2) ? combined2 : def);
				}
				// If result should be in minutes and seconds
				else return (/^[0-5]?[0-9]:[0-5]?[0-9]$/.test(combined2) ? combined2 : def);
			}

			// Three section output
			else if (outputSize === 3) {
				const value2 = getString(valueSections, '[1]', '00');
				const value3 = getString(valueSections, '[2]', '00');
				const combined3 =
					`${lPadString(value1, 2, '0')}:${lPadString(value2, 2, '0')}:` +
					`${lPadString(value3, 2, '0')}`
				;

				// If result hours should be limited to one day (max. 23)
				if (dayLimit) return (/^([0-1]?[0-9]|2[0-3]):[0-5]?[0-9]:[0-5]?[0-9]$/.test(combined3) ? combined3 : def);
				// If result should be in hours (no limits)
				else return (/^\d+:[0-5]?[0-9]:[0-5]?[0-9]$/.test(combined3) ? combined3 : def);
			}

			return def;
		}

		return def;
	}
	// Extract time string from Date object value
	else if (value instanceof Date) {
		switch (outputSize) {
			case 1: return format(value, (hoursFirst ? 'HH' : 'mm'));
			case 2: return format(value, (hoursFirst ? 'HH:mm' : 'mm:ss'));
			default: return format(value, 'HH:mm:ss');
		}
	}

	return def;
};


// App datetime --------------------------------------------------------------------------------------------------------
/**
 * Get a date string from a Date object using the current app's locale date format name
 * 
 * @param {Date} date - Date object to convert to a string.
 * @param {string} [outputFormatName] - Locale date format name (see 'LOCALE_DATE_FORMAT_NAMES' core const).
 * @return {string} Date string depending on 'outputFormatName'.
 */
export const getAppDateString = (date, outputFormatName = LOCALE_DATE_FORMAT_NAME.STANDARD) => getDateString(
	date, getAppLocaleDateFormat(outputFormatName), getAppDatePluginLocale()
);

/**
 * Get a date string from Date object using the current app locale and any Unicode Technical Standard #35 pattern
 * @link https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
 * @note In addition to the standard Unicode Technical Standard #35 pattern, any plugin specific patterns can also be 
 * used. For example if the app uses 'date-fns' plugin there are a few additions (see 
 * https://date-fns.org/v2.16.1/docs/format)
 * 
 * @param {Date} date - Date object to convert to a string.
 * @param {string} [format] - Format of the string is based on Unicode Technical Standard #35 (see @link above). If not
 * specified, app locale standard date format will be used.
 * @return {string}
 */
export const formatDate = (date, format) => (
	format ? getDateString(date, format, getAppDatePluginLocale()) : getAppDateString(date)
);

/**
 * Get a time string from a Date object using the current app's locale time format name
 * 
 * @param {Date} date - Date object to extract time and convert it to a string.
 * @param {string} [outputFormatName] - Locale time format name (see 'LOCALE_TIME_FORMAT_NAMES' core const).
 * @return {string} Time string depending on 'outputFormatName'.
 */
export const getAppTimeString = (date, outputFormatName = LOCALE_TIME_FORMAT_NAME.STANDARD) => getDateString(
	date, getAppLocaleTimeFormat(outputFormatName), getAppDatePluginLocale()
);

/**
 * Get a time string from Date object using the current app locale and any Unicode Technical Standard #35 pattern
 * @link https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
 * @note In addition to the standard Unicode Technical Standard #35 pattern, any plugin specific patterns can also be
 * used. For example if the app uses 'date-fns' plugin there are a few additions (see
 * https://date-fns.org/v2.16.1/docs/format)
 *
 * @param {Date} date - Date object to convert to a time string.
 * @param {string} [format] - Format of the string is based on Unicode Technical Standard #35 (see @link above). If not
 * specified, app locale standard time format will be used.
 * @return {string}
 */
export const formatTime = (date, format) => (
	format ? getDateString(date, format, getAppDatePluginLocale()) : getAppTimeString(date)
);

/**
 * Get a datetime string from a Date object using the current app's locale date and time format names and a datetime 
 * separator string
 * 
 * @param {Date} date - Date object to convert to a datetime string.
 * @param {string} [dateOutputFormatName] - Locale date format name (see 'LOCALE_DATE_FORMAT_NAMES' core const).
 * @param {string} [timeOutputFormatName] - Locale time format name (see 'LOCALE_DATE_FORMAT_NAMES' core const).
 * @param {string} [datetimeOutputSeparator] - Datetime separator. If not specified, app locale datetime separator will
 * be used.
 * @return {string}
 */
export const getAppDatetimeString = (
	date, dateOutputFormatName = LOCALE_DATE_FORMAT_NAME.STANDARD,
	timeOutputFormatName = LOCALE_TIME_FORMAT_NAME.STANDARD, datetimeOutputSeparator
) => {
	const _dateFormat = getAppLocaleDateFormat(dateOutputFormatName);
	const _timeFormat = getAppLocaleTimeFormat(timeOutputFormatName);
	const _datetimeSeparator = (datetimeOutputSeparator ? datetimeOutputSeparator : getAppLocalDatetimeSeparator());
	return getDateString(date, (_dateFormat + _datetimeSeparator + _timeFormat), getAppDatePluginLocale());
}

/**
 * Get a datetime string from Date object using the current app locale and any Unicode Technical Standard #35 pattern
 * @link https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
 * @note In addition to the standard Unicode Technical Standard #35 pattern, any plugin specific patterns can also be
 * used. For example if the app uses 'date-fns' plugin there are a few additions (see
 * https://date-fns.org/v2.16.1/docs/format)
 *
 * @param {Date} date - Date object to convert to a datetime string.
 * @param {string} [dateFormat] - Format of the date string based on Unicode Technical Standard #35 (see @link above). 
 * If not specified, app locale standard date format will be used.
 * @param {string} [timeFormat] - Format of the time string based on Unicode Technical Standard #35 (see @link above).
 * If not specified, app locale standard time format will be used.
 * @param {string} [datetimeSeparator] - Datetime separator. If not specified, default app's datetime separator will be 
 * used.
 * @return {string}
 */
export const formatDatetime = (date, dateFormat, timeFormat, datetimeSeparator) => {
	const _dateFormat = (dateFormat ? dateFormat : getAppLocaleDateFormat());
	const _timeFormat = (timeFormat ? timeFormat : getAppLocaleTimeFormat());
	const _datetimeSeparator = (datetimeSeparator ? datetimeSeparator : getAppLocalDatetimeSeparator());
	return getDateString(date, (_dateFormat + _datetimeSeparator + _timeFormat), getAppDatePluginLocale());
};

/**
 * Convert any date string into a date string using the current app's locale date format
 * 
 * @param {string} dateString - Date string to convert.
 * @param {string} [dateStringFormat="yyyy-MM-dd'T'HH:mm:ss"] - Format of the date string.
 * @param {any} [dateStringLocale] - Locale to use.
 * @param {string} [outputFormatName] - Locale date format name (see 'LOCALE_DATE_FORMAT_NAMES' core const).
 */
export const stringToAppDateString = (
	dateString, 
	dateStringFormat = STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S, 
	dateStringLocale, 
	outputFormatName = LOCALE_DATE_FORMAT_NAME.SHORT
) => getAppDateString(getDate(dateString, dateStringFormat, dateStringLocale), outputFormatName);

/**
 * Convert any date string into a datetime string using the current app's locale datetime format
 *
 * @param {string} dateString - Date string to convert.
 * @param {string} [dateStringFormat="yyyy-MM-dd'T'HH:mm:ss"] - Format of the date string.
 * @param {any} [dateStringLocale] - Locale to use.
 * @param {string} [dateOutputFormatName] - Locale date format name (see 'LOCALE_DATE_FORMAT_NAMES' core const).
 * @param {string} [timeOutputFormatName] - Locale time format name (see 'LOCALE_DATE_FORMAT_NAMES' core const).
 * @param {string} [datetimeOutputSeparator] - Datetime separator. If not specified, app locale datetime separator will
 * be used.
 */
export const stringToAppDatetimeString = (
	dateString,
	dateStringFormat = STANDARD_DATE_TIME_FORMAT.ISO_DATE_TIME_S,
	dateStringLocale,
	dateOutputFormatName = LOCALE_DATE_FORMAT_NAME.SHORT,
	timeOutputFormatName = LOCALE_TIME_FORMAT_NAME.SHORT, 
	datetimeOutputSeparator
) => getAppDatetimeString(
	getDate(dateString, dateStringFormat, dateStringLocale), 
	dateOutputFormatName, 
	timeOutputFormatName, 
	datetimeOutputSeparator
);


// Export all datetime core constants
export * from "../const/datetime";
export {DATETIME_ROUND_TOS} from '../components/input/DateInput/const';
export {DATETIME_ROUND_TO} from '../components/input/DateInput/const';
export {DATETIME_ROUND_TO_END} from '../components/input/DateInput/const';
export {DATETIME_ROUND_TO_START} from '../components/input/DateInput/const';