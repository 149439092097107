// Constants
export const MESSAGE_STYLE_DEFAULT = 'default';
export const MESSAGE_STYLE_INFO = 'info';
export const MESSAGE_STYLE_HELP = 'help';
export const MESSAGE_STYLE_SUCCESS = 'success';
export const MESSAGE_STYLE_WARNING = 'warning';
export const MESSAGE_STYLE_ERROR = 'error';
export const MESSAGE_STYLE_CUSTOM = 'custom';
export const MESSAGE_STYLE = {
	DEFAULT: MESSAGE_STYLE_DEFAULT,
	INFO: MESSAGE_STYLE_INFO,
	HELP: MESSAGE_STYLE_HELP,
	SUCCESS: MESSAGE_STYLE_SUCCESS,
	WARNING: MESSAGE_STYLE_WARNING,
	ERROR: MESSAGE_STYLE_ERROR,
	CUSTOM: MESSAGE_STYLE_CUSTOM
};


// Types
/**
 * Message data object
 * 
 * @typedef {Object} MessageData
 * @property {string} GUIID - Message unique GUI ID.
 * @property {string} style - Message style (keyof MESSAGE_STYLE).
 * @property {React.Component|string} content - Message content. Can be a React component or string (HTML is supported).
 * @property {number} autoHideAfter - Number of seconds for the message to be visible. If -1, message will be visible
 * indefinitely or until its closed manually.
 * @property {string} customClass - Custom CSS class used instead of style class if style is MESSAGE_STYLE_CUSTOM. Use 
 * this option if you want to render a custom style message.
 * @property {boolean} canClose - Flag that determines if message can be manually closed.
 * @property {boolean} showCloseButton - Flag that determines if close button will be rendered.
 * @property {boolean} closeOnClick - Flag that determines if clicking anywhere inside the message will close it. This
 * flag will be ignored if 'canClose' prop is false.
 * @property {boolean} [asDialog=false] - Flag that determines if message will be rendered as a dialog.
 * @property {Object} [dialogOptions] - Message dialog options. This will be ignored if 'asDialog' is false.
 * @property {Object} [dialogProps] - Message dialog props. This will be ignored if 'asDialog' is false.
 * @property {any} nodeRef -
 */