/** @typedef {string} PatientQuickSearchCreatePosition */
export const PATIENT_QUICK_SEARCH_CREATE_POSITION_LABEL = 'LABEL';
export const PATIENT_QUICK_SEARCH_CREATE_POSITION_SELECT = 'SELECT';
/** @enum {PatientQuickSearchCreatePosition} */
export const PATIENT_QUICK_SEARCH_CREATE_POSITION = {
	LABEL: PATIENT_QUICK_SEARCH_CREATE_POSITION_LABEL,
	SELECT: PATIENT_QUICK_SEARCH_CREATE_POSITION_SELECT,
};
/** @type {PatientQuickSearchCreatePosition[]} */
export const PATIENT_QUICK_SEARCH_CREATE_POSITIONS = [
	PATIENT_QUICK_SEARCH_CREATE_POSITION_LABEL,
	PATIENT_QUICK_SEARCH_CREATE_POSITION_SELECT,
];