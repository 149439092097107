export const NUMBER_INPUT_USE_APP_LOCALE_NONE = 'NONE';
export const NUMBER_INPUT_USE_APP_LOCALE_VALUE = 'VALUE';
export const NUMBER_INPUT_USE_APP_LOCALE_RENDER = 'RENDER';
export const NUMBER_INPUT_USE_APP_LOCALE_BOTH = 'BOTH';
export const NUMBER_INPUT_USE_APP_LOCALE = {
	NONE: NUMBER_INPUT_USE_APP_LOCALE_NONE,
	VALUE: NUMBER_INPUT_USE_APP_LOCALE_VALUE,
	RENDER: NUMBER_INPUT_USE_APP_LOCALE_RENDER,
	BOTH: NUMBER_INPUT_USE_APP_LOCALE_BOTH
};
export const NUMBER_INPUT_USE_APP_LOCALES = [
	NUMBER_INPUT_USE_APP_LOCALE_NONE, NUMBER_INPUT_USE_APP_LOCALE_VALUE, NUMBER_INPUT_USE_APP_LOCALE_RENDER, 
	NUMBER_INPUT_USE_APP_LOCALE_BOTH 
];
