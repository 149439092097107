import "./index.css";

import * as srLatnRS from './i18n/sr-Latn-RS';
import * as srCyrlRS from './i18n/sr-Cyrl-RS';
import * as enUS from './i18n/en-US';

import React from "react";
import ReactDOM from "react-dom";
import BaseComponent, {executeComponentCallback} from "Core/components/BaseComponent";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {selectors} from "Core/store/reducers";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import {get, set, map, find, omit} from 'lodash';
import {getArray, getString, isset} from "Core/helpers/data";
import {CALENDAR_VIEW, CALENDAR_VIEWS} from './const';
import {camelToSnake, snakeToCamel} from "Core/helpers/string";
import {getLocaleCode} from "Core/helpers/locale";
import {isEqual, startOfDay} from "date-fns";
import SelectCalendar from "Components/advanced/SelectCalendar";
import {getPageActions} from "Core/helpers/redux";
import {reducerStoreKey as scheduleReducerStoreKey} from "../../../store/reducers/schedule";
import {waitingFunction} from "Core/helpers/function";

// Get all supported locales
const locales = [srLatnRS, srCyrlRS, enUS];

/**
 * Redux 'mapStateToProps' function
 *
 * @param {object} state - Redux entire store state.
 * @return {Object<string, any>} Mapped props that can be used in component.
 */
const mapStateToProps = state => ({
	/** @type {LocaleObj} */
	appLocale: selectors.i18n.getLocale(state),
});

class ScheduleCalendar extends BaseComponent {
	resizeObserver = null;
	startDate = null;
	endDate = null;
	viewType = null;
	
	constructor(props) {
		super(props, {
			translationPath: 'ScheduleCalendar',
			domPrefix: 'schedule-calendar-component',
		});

		// Initiate component's state
		this.state = {
			selectCalendarOpened: false,
		};
		
		// Refs
		this.calendarRef = React.createRef();

		// Observers
		this.resizeObserver = new ResizeObserver(() => { this.updateSize(); });
		
		// Calendar methods
		this.updateSize = this.updateSize.bind(this);
		this.handleCalendarTitleClick = this.handleCalendarTitleClick.bind(this);
		this.handleWindowClick = this.handleWindowClick.bind(this);
		this.handleExternalDateSelect = this.handleExternalDateSelect.bind(this);
		this.reloadCalendar = this.reloadCalendar.bind(this);
		this.prevPage = this.prevPage.bind(this);
		this.nextPage = this.nextPage.bind(this);
		
		// Render methods
		this.getRenderLocale = this.getRenderLocale.bind(this);
		this.renderSelectCalendar = this.renderSelectCalendar.bind(this);
		
		// Register a click even on window to manage clicking outside select calendar
		this.registerEventListener('click', this.handleWindowClick);
		this.registerEventListener('onDateSelect', this.handleExternalDateSelect);
		this.registerEventListener('onReloadScheduleCalendar', this.reloadCalendar);
		this.registerEventListener('onCalendarNavigatePrev', this.prevPage);
		this.registerEventListener('onCalendarNavigateNext', this.nextPage);
	}

	componentDidMount() {
		super.componentDidMount();

		this.resizeObserver.observe(this.getDomElement());

		const titleElem = this.getDomElement().querySelector('.fc-header-toolbar .fc-toolbar-title');
		if (titleElem) titleElem.addEventListener('click', this.handleCalendarTitleClick);
	}
	
	/** @inheritDoc */
	async asyncComponentDidMount() {
		await super.asyncComponentDidMount();
		
		const {setScheduleDateAction} = this.props;
		const calendarRef = get(this.calendarRef, 'current.calendar');
		
		// Set or clear initial selected date depending on calendar view type
		if (calendarRef) {
			const viewType = camelToSnake(getString(calendarRef, 'view.type'));
			
			// Select today as the initial selected date if calendar is in day like view
			if ([CALENDAR_VIEW.TIME_GRID_DAY, CALENDAR_VIEW.LIST_DAY].includes(viewType)) {
				setScheduleDateAction(startOfDay(new Date()));
			}
			// Clear initial selected date (set to null) if calendar is not in day like view
			else {
				setScheduleDateAction(null);	
			}
		} else {
			setScheduleDateAction(null);
		}
	}

	componentWillUnmount() {
		super.componentWillUnmount();

		this.resizeObserver?.unobserve(this.getDomElement());

		const titleElem = this.getDomElement().querySelector('.fc-header-toolbar .fc-toolbar-title');
		if (titleElem) titleElem.removeEventListener('click', this.handleCalendarTitleClick);

		const {setScheduleDateAction} = this.props;
		setScheduleDateAction(startOfDay(new Date()));
	}
	
	
	// Calendar methods -------------------------------------------------------------------------------------------------
	/**
	 * Update full calendar component size
	 */
	updateSize() {
		setTimeout(() => {
			const calendarRef = get(this.calendarRef, 'current');
			if (calendarRef) calendarRef.getApi().updateSize();
		});
	}

	/**
	 * Handle clicking the calendar title
	 * @param {MouseEvent} e - Mouse click event.
	 */
	handleCalendarTitleClick(e) {
		this.setState(prevState => ({selectCalendarOpened: !prevState.selectCalendarOpened})).then();
	}

	/**
	 * Handle window click event
	 * @note Used for handling select calendar click outside.
	 * @param {MouseEvent} e - Mouse click event.
	 */
	handleWindowClick(e) {
		if (
			!e.target.closest('.fc-toolbar-chunk') &&
			!e.target.classList.contains('react-calendar__tile') &&
			!e.target.closest('.react-calendar__tile')
		) {
			const {selectCalendarOpened} = this.state;
			if (selectCalendarOpened) this.setState({selectCalendarOpened: false}).then();
		}
	}

	/**
	 * Handle date select event triggered by other components
	 * @note This method handles a custom window event 'onDateSelect'.
	 * @param {CustomEvent} e - Custom 'onDateSelect' window event.
	 */
	handleExternalDateSelect(e) {
		const {setScheduleDateAction} = this.props;
		const date = new Date(e.detail.date);

		const calendarRef = get(this.calendarRef, 'current.calendar');
		if (calendarRef) {
			const viewType = camelToSnake(getString(calendarRef, 'view.type'));
			
			this.setState({selectCalendarOpened: false}).then();
			
			// Navigate to externally selected date if on day like view
			if ([CALENDAR_VIEW.TIME_GRID_DAY, CALENDAR_VIEW.LIST_DAY].includes(viewType)) calendarRef.gotoDate(date);
			// Change view to day like view and navigate to externally selected date if not on day like view
			else calendarRef.changeView(snakeToCamel(CALENDAR_VIEW.TIME_GRID_DAY), date);
			
			// Trigger 'onChange' event to load the date even if current tade is the same
			// @note Calendar will not trigger 'onChange' event if dates or view are not changed.
			if (isEqual(this.startDate, date)) this.reloadCalendar();
		}

		setScheduleDateAction(date);
	}

	/**
	 * Reload calendar data with current start date, end date and view type
	 */
	reloadCalendar() {
		executeComponentCallback(this.props.onChange, this.startDate, this.endDate);
	}
	
	/**
	 * Moves the calendar one step forward (by a month or week for example)
	 */
	prevPage() {
		const calendarRef = get(this.calendarRef, 'current.calendar');
		if (calendarRef) calendarRef.prev();
	}

	/**
	 * Moves the calendar one step back (by a month or week for example)
	 */
	nextPage() {
		const calendarRef = get(this.calendarRef, 'current.calendar');
		if (calendarRef) calendarRef.next();
	}

	
	// Render methods ---------------------------------------------------------------------------------------------------
	/**
	 * Get locale used for rendering the date input element
	 * @return {string} Locale code (IETF).
	 */
	getRenderLocale() {
		const {useAppLocale, renderLocale, appLocale} = this.props;

		let result = '';
		if (renderLocale) result = renderLocale;
		else if (useAppLocale) result = getLocaleCode(appLocale);
		return result;
	}
	
	/**
	 * Render calendar component used for selecting a day
	 * @return {JSX.Element|null}
	 */
	renderSelectCalendar() {
		const {selectCalendarOpened} = this.state;
		
		return (
			selectCalendarOpened ?
				<SelectCalendar
					reducerStoreKey={scheduleReducerStoreKey} 
					reducerStoreGetMethod="getDate"	
					showCloseButton={true}
					onClose={() => this.setState({selectCalendarOpened: false})}
				/>
			: null
		);
	}
	
	render() {
		const {
			views, defaultView, timeZone, editable, selectable, minSelectTime, maxSelectTime, selectableWeekDays, height, 
			stickyHeaderDates, data, sources, eventContent, calendarProps, loadingCallback, onItemClick, onItemChange, 
			onItemDrop, onItemResize, eventMinHeight,
		} = this.props;
		/** @type {LocaleObj} */
		const appLocale = this.getProp('appLocale');
		const allCalendarLocales = map(locales, 'default');
		const currentCalendarLocale = find(allCalendarLocales, {code: appLocale.locale});
		
		// Generate selection constraints if selectable and constraints were defined in props
		let selectConstraint;
		if (selectable) {
			if (minSelectTime || maxSelectTime || selectableWeekDays) selectConstraint = {};
			if (minSelectTime) set(selectConstraint, 'startTime', minSelectTime);
			if (maxSelectTime) set(selectConstraint, 'endTime', maxSelectTime);
			if (selectableWeekDays) set(selectConstraint, 'daysOfWeek', selectableWeekDays.split(''));
		}
		
		// Get title wrapper element to portal-render the calendar used to select the date
		const titleElem = this.getDomElement()?.querySelector('.fc-header-toolbar .fc-toolbar-title').parentElement;
		
		return (
			<div id={this.getDomId()} className={`${this.getOption('domPrefix')}`}>
				<FullCalendar
					plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
					allDaySlot={false}
					locales={allCalendarLocales}
					locale={isset(currentCalendarLocale) ? appLocale.locale : 'en'}
					buttonText={get(currentCalendarLocale, 'buttonText')}
					buttonHints={get(currentCalendarLocale, 'buttonHints')}
					allDayText={get(currentCalendarLocale, 'allDayText')}
					firstDay={get(currentCalendarLocale, 'week.dow')}
					headerToolbar={{
						left: 'prev,next today',
						center: 'title',
						right: getArray(views).map(i => snakeToCamel(i)).join(','),
					}}
					eventTimeFormat={{
						hour: 'numeric',
						minute: '2-digit',
						meridiem: false,
						hour12: false,
					}}
					slotLabelFormat={{
						hour: '2-digit',
						minute: '2-digit',
						meridiem: false,
						hour12: false,
					}}
					events={sources ? undefined : data}
					eventSources={sources}
					loading={isLoading => { if (loadingCallback) loadingCallback(isLoading); }}
					initialView={defaultView ? snakeToCamel(defaultView) : undefined}
					timeZone={timeZone}
					editable={editable}
					selectable={selectable}
					selectConstraint={selectConstraint}
					height={height}
					eventMinHeight={eventMinHeight}
					stickyHeaderDates={stickyHeaderDates}

					eventClick={onItemClick ? onItemClick : undefined}
					eventChange={onItemChange ? onItemChange : undefined}
					eventDrop={onItemDrop ? onItemDrop : undefined}
					eventResize={onItemResize ? onItemResize : undefined}

					eventContent={eventContent}
					
					datesSet={dateInfo => {
						waitingFunction(() => {
							if (get(this.calendarRef, 'current.calendar')) return true;
						}, 10, 6000)
							.then(() => {
								const calendarRef = get(this.calendarRef, 'current.calendar');
								if (calendarRef) {
									const prevStartDate = !!this.startDate ? startOfDay(this.startDate) : null;
									const prevEndDate = !!this.endDate ? startOfDay(this.endDate) : null;
									const prevViewType = this.viewType;
									const currStartDate = dateInfo.start;
									const currEndDate = dateInfo.end;
									const currViewType = dateInfo.view.type;

									if (
										!isEqual(prevStartDate, currStartDate) || !isEqual(prevEndDate, currEndDate) ||
										prevViewType !== currViewType
									) {
										this.startDate = new Date(currStartDate);
										this.endDate = new Date(currEndDate);
										this.viewType = currViewType;
										this.reloadCalendar();
										executeComponentCallback(this.props.calendarProps?.datesSet, dateInfo);

										const {setScheduleDateAction} = this.props;
										setScheduleDateAction(this.startDate);
									} else {
										executeComponentCallback(this.props.calendarProps?.datesSet, dateInfo);
									}
								}
							});
					}}
					
					{...omit(calendarProps, ['datesSet'])}

					ref={this.calendarRef}
				/>
				{!!titleElem ? ReactDOM.createPortal(this.renderSelectCalendar(), titleElem) : null}
			</div>
		)
	}

}

/**
 * Define component's own props that can be passed to it by parent components
 */
ScheduleCalendar.propTypes = {
	views: PropTypes.array,
	defaultView: PropTypes.oneOf(CALENDAR_VIEWS),
	timeZone: PropTypes.string,
	editable: PropTypes.bool,
	selectable: PropTypes.bool,
	minSelectTime: PropTypes.string,
	maxSelectTime: PropTypes.string,
	selectableWeekDays: PropTypes.string,
	stickyHeaderDates: PropTypes.bool,
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	eventMinHeight: PropTypes.number,
	// @type {CalendarItemDataObject[]}
	data: PropTypes.arrayOf(PropTypes.object),
	sources: PropTypes.arrayOf(PropTypes.object),
	eventContent: PropTypes.any,
	calendarProps: PropTypes.object,

	loadingCallback: PropTypes.func,

	// Locale code (IETF) to use for rendering the date input element
	// @note If specified, 'useAppLocale' prop will be ignored.
	renderLocale: PropTypes.string,
	// Flag that determines if current app locale will be used for both input and output
	useAppLocale: PropTypes.bool,
	
	// @param {event: EventObject, el: Element, jsEvent: MouseEvent, view: ViewObject}
	// @link https://fullcalendar.io/docs/eventClick
	onItemClick: PropTypes.func,
	// @param {event: EventObject, relatedEvents: EventObject[], oldEvent: EventObject, revert: function} changeInfo
	// @link https://fullcalendar.io/docs/eventChange
	onItemChange: PropTypes.func,
	// @param {Object} eventDropInfo
	// @link https://fullcalendar.io/docs/eventDrop
	onItemDrop: PropTypes.func,
	// @param {Object} eventResizeInfo
	// @link https://fullcalendar.io/docs/eventResize
	onItemResize: PropTypes.func,
	// @param {Date} newStartDate, {Date} newEndDate
	onChange: PropTypes.func,
};

/**
 * Define component default values for own props
 */
ScheduleCalendar.defaultProps = {
	views: [],
	defaultView: CALENDAR_VIEW.DAY_GRID_MONTH,
	timeZone: 'local',
	editable: false,
	selectable: false,
	stickyHeaderDates: true,
	height: 'auto',
	eventMinHeight: 26,
	calendarProps: {},
	useAppLocale: true,
};

export * from './const';
export default connect(mapStateToProps, getPageActions(), null, {forwardRef: true})(ScheduleCalendar);