import {ioJsonDeleteAction, ioJsonFetchItemAction} from 'Core/store/actions/io';
import {isset} from 'Core/helpers/data';
import * as dataMap from './dataMap';
import {hideLoading, hideLoadingFunction, showLoading, showPageLoading} from 'Core/helpers/loading';
import {actionCreators} from './reducer';

/**
 * Fetch patient details
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - Patient's ID.
 * @return {function(*): Promise<PatientRecordDataObject|undefined>}
 */
export const fetchPatientRecordAction = (abortCallback, id) => dispatch => {
	return ioJsonFetchItemAction(
		abortCallback,
		'defaultAuthorizedApi',
		'member/customer/fetch-by-id',
		id
	)(dispatch)
		// Get mapped data from response data
		.then(responseData => isset(responseData) ? dataMap.input(responseData.data) : undefined);
}

/**
 * Load patient details into Redux store
 * 
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - Patient's ID.
 * @return {function(*): Promise<PatientRecordDataObject|undefined>}
 */
export const loadPatientRecordAction = (abortCallback, id) => dispatch => {
	const loading = showPageLoading();
	return fetchPatientRecordAction(abortCallback, id)(dispatch)
		.then(patientRecord => {
			dispatch(actionCreators.set(patientRecord));
			hideLoading(loading);
			return patientRecord;
		});
};

/**
 * Update patient record in Redux store
 * 
 * @param {PatientRecordDataObject} patientRecord
 * @return {(function(*): void)|*}
 */
export const setPatientRecordAction = patientRecord => dispatch => {
	dispatch(actionCreators.set(patientRecord));
}

/**
 * Clear patient details from Redux store
 * @return {(function(*): void)|*}
 */
export const clearPatientRecordAction = () => dispatch => {
	dispatch(actionCreators.clear());
}

/**
 * Delete patient
 *
 * @param {function} [abortCallback=(abortController)=>{}] - Callback function that will receive AbortController as an
 * argument.
 * @param {string} id - ID of the patient.
 * @param {string} dialogId - ID of the dialog used for the loading overlay.
 * @return {function(*): Promise<IoJsonResponseObject|undefined>}
 */
export const deletePatientAction = (abortCallback, id, dialogId) => dispatch => {
	const loading = (dialogId ? showLoading(`#${dialogId}`) : null);
	return ioJsonDeleteAction(
		// @note abortCallback is set to undefined because delete actions should not be cancelable.
		undefined,
		'defaultAuthorizedApi',
		'member/customer/delete-by-ids',
		[id],
		undefined,
		loading ? hideLoadingFunction(loading) : undefined,
	)(dispatch);
};

