import React from "react";
import * as ReactDOM from "react-dom";
import DataComponent from "../../DataComponent";
import PropTypes from "prop-types";

import styles from "./index.module.css";
import {getBool} from 'Core/helpers/data';

class Overlay extends DataComponent {
	/**
	 * DOM element which will display the overlay
	 * @note This is stored here because element prop could change before unmount losing the original element reference. 
	 * @type {HTMLElement}
	 */
	element = null;
	
	constructor(props) {
		super(props, {}, {
			domPrefix: 'overlay-',
			domManipulationIntervalTimeout: 1
		});

		// Load element from props into local variable to preserve it for unmount
		this.element = props.element;

		// Register window events
		if (getBool(props, 'data.disableTabKey')) {
			this.registerEventListener('keydown', e => {
				if (e.key === 'Tab') e.preventDefault();
			});
		}
	}
	
	/**
	 * Method called on each DOM manipulation interval
	 *
	 * @param {HTMLElement|Element|null} element - Component's main DOM element or null if component's main DOM element
	 * is not set.
	 */
	domManipulations(element) {
		element.style.top = `${element.parentElement.scrollTop}px`;
		element.style.left = `${element.parentElement.scrollLeft}px`;
	}

	/**
	 * Method that should return true if component can be rendered or false otherwise
	 * @return {boolean} True if component can be rendered or false otherwise.
	 */
	canRender() { return !!this.element; }

	render() {
		// Do not render component if 'canRender' returns false
		if (!this.canRender()) return null;

		const content = this.getValue('content');
		const blur = this.getValue('blur');
		
		// Set parent element's CSS class required for the overlay to render properly
		// @note This will also be handled by a timeout in the App.js
		this.element.classList.add('has-overlay-component');
		if (blur) this.element.classList.add('has-overlay-component-blur');
		
		return ReactDOM.createPortal((
			<div
				id={this.getDomId()}
				className={
					`overlay-component ${blur ? ' overlay-component-blur' : ''} ` +
					`${styles['overlay']} ${this.getValue('transparent') ? styles['transparent'] : styles['opaque']} ` +
					this.getValue('className')
				}
			>
				{
					typeof content === 'string' ?
						<div dangerouslySetInnerHTML={{__html: this.getValue('content')}} /> :
						this.getValue('content')
				}
			</div>), this.element
		);
	}
}

/**
 * Define component's own props that can be passed to it by parent components
 */
Overlay.propTypes = {
	// Overlay data (OverlayObject)
	data: PropTypes.object.isRequired,
	// DOM element which will display the overlay 
	element: PropTypes.any.isRequired,
};

// Helper functions
export const getOverlayTargetElement = overlay => document.querySelector(overlay.selector);

export default Overlay;